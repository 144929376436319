import { ElementNodeBuilder } from 'Editor/services/Model';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement } from '..';

export class NoteViewBuilder extends BaseViewBuilder implements Editor.Visualizer.IViewbuilder {
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.NoteElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // element_reference
    element_reference: this.GENERIC_ATTRIBUTE_MAPPER.element_reference,
    // type
    type: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('type')) {
          builder.addProperty('note_type', node.getAttribute('type'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.note_type) {
          node.setAttribute('type', json.properties?.note_type);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('type');
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  build(json: Editor.Data.Node.Data) {
    const node = DOMElementFactory.buildElement(ELEMENTS.NoteElement.TAG);

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    node.setAttribute('contenteditable', 'false');

    // get note serial
    if (json.properties?.element_reference) {
      if (json.properties.note_type === 'footnote') {
        const noteSerial = this.Data.notes.getFootnoteSerial(json.properties.element_reference);
        if (noteSerial !== undefined) {
          node.setAttribute('number', `${noteSerial}`);
        }
      } else {
        const noteSerial = this.Data.notes.getEndnoteSerial(json.properties.element_reference);
        if (noteSerial !== undefined) {
          node.setAttribute('number', `${noteSerial}`);
        }
      }
    }

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }

  shouldRenderChildren() {
    return false;
  }
}
