import { Mixin } from 'mixwith';
import { ElementNodeBuilder } from 'Editor/services/Model';

export default Mixin(
  (superclass) =>
    class extends superclass {
      // eslint-disable-next-line
      parseNote(representation) {
        const builder = new ElementNodeBuilder();
        builder.addElementType('note');
        if (representation.attributes.parent_id) {
          builder.addParentId(representation.attributes.parent_id.nodeValue);
        }
        if (representation.attributes.id) {
          builder.addId(representation.attributes.id.nodeValue);
        }
        if (representation.attributes.element_reference) {
          builder.addProperty(
            'element_reference',
            representation.attributes.element_reference.nodeValue,
          );
        }
        const noteType = representation.attributes.type.nodeValue;
        builder.addProperty('note_type', noteType);
        return builder.getNode();
      }
    },
);
