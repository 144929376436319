import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { RadioButton, RadioButtonGroup, SectionHeading } from 'dodoc-design-system';

import { LANGUAGES } from 'Intl/Intl';
import { openAndUpdateModal, openModal } from '_common/modals/ModalsSlice';
import { useGetCurrentUserQuery, useGetTenantSettingsQuery } from '_common/services/api/authority';
import { useEditProfileMutation } from '../UserSettingsApi';

import { UserAvatar } from '_common/components';
import { parseTimezoneLabel } from '_common/modals/EditTimezoneModal/TimezoneSelect';
import { Setting } from 'Settings/components';

import styles from './UserProfile.module.scss';

const UserProfile = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const locale = useSelector((state) => state.intl.locale);
  const [editProfile] = useEditProfileMutation();
  const { data: authUser } = useGetCurrentUserQuery();
  const userProfile = authUser?.profile;

  const { data: tenantSettings } = useGetTenantSettingsQuery();

  const disabledSettings = useMemo(() => {
    const settings: {
      [x in Exclude<
        typeof tenantSettings,
        undefined
      >['not_editable_profile_fields'][number]]: boolean;
    } = {
      company: false,
      department: false,
      language: false,
      timezone: false,
      date_format: false,
      time_format: false,
      first_name: false,
      last_name: false,
    };

    tenantSettings?.not_editable_profile_fields?.forEach((setting) => {
      settings[setting] = true;
    });

    return settings;
  }, [tenantSettings]);

  if (!userProfile) {
    return null;
  }

  const handleOpenGenericSettingModal = ({
    header,
    label,
    value,
    inputPlaceholder,
    action,
    actionValues,
  }: {
    header: string;
    label: string;
    value: string;
    inputPlaceholder: string;
    action: string;
    actionValues: { setting: string };
  }) => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: { header, label, value, inputPlaceholder, action, actionValues },
      }),
    );
  };

  const openLanguageSettingModal = () => {
    dispatch(openModal('LanguageSettingModal'));
  };

  const openChangeNameModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ChangeNameModal',
        data: {
          disabledFields: {
            firstName: disabledSettings.first_name,
            lastName: disabledSettings.last_name,
          },
        },
      }),
    );
  };

  const openChangeEmailModal = () => {
    dispatch(openModal('ChangeEmailModal'));
  };

  const openEditTimezoneModal = () => {
    dispatch(openModal('EditTimezoneModal'));
  };

  const openEditCompany = () => {
    handleOpenGenericSettingModal({
      header: intl.formatMessage({ id: 'settings.company.header' }),
      label: intl.formatMessage({ id: 'settings.user.company' }),
      value: userProfile.company,
      inputPlaceholder: intl.formatMessage({ id: 'COMPANY_PLACEHOLDER' }),
      action: 'editProfile',
      actionValues: { setting: 'company' },
    });
  };

  const openEditDepartment = () => {
    handleOpenGenericSettingModal({
      header: intl.formatMessage({ id: 'settings.department.header' }),
      label: intl.formatMessage({ id: 'settings.user.department' }),
      value: userProfile.department,
      inputPlaceholder: intl.formatMessage({ id: 'DEPARTMENT_PLACEHOLDER' }),
      action: 'editProfile',
      actionValues: { setting: 'department' },
    });
  };

  const openChangeAvatarModal = () => {
    dispatch(openModal('ChangeAvatarModal'));
  };

  return (
    <>
      <div className={styles.container}>
        <div>
          <SectionHeading
            sticky
            title={intl.formatMessage({ id: 'settings.user.profile' })}
            testId="profile-heading"
          />
          {/* Avatar */}
          <Setting
            style={{ alignItems: 'flex-start', height: 'auto' }}
            label={intl.formatMessage({ id: 'settings.user.avatar' })}
            value={<UserAvatar userId={userProfile.id} size="extraLarge" />}
            onEdit={openChangeAvatarModal}
            onEditLabel="global.edit"
            testId="avatar"
          />
          {/* Email */}
          <Setting
            label={intl.formatMessage({ id: 'settings.user.email' })}
            value={userProfile.email}
            onEdit={openChangeEmailModal}
            onEditLabel="global.edit"
            testId="email"
          />
          {/* Username */}
          <Setting
            label={intl.formatMessage({ id: 'settings.user.username' })}
            value={userProfile.username}
            testId="username"
          />
          {/* Name TODO: fix prego */}
          <Setting
            label={intl.formatMessage({ id: 'settings.user.name' })}
            value={`${userProfile.first_name} ${userProfile.last_name}`}
            onEdit={openChangeNameModal}
            onEditLabel="global.edit"
            editable={!(disabledSettings.last_name && disabledSettings.first_name)}
            testId="name"
          />
        </div>

        <div>
          <SectionHeading
            sticky
            margin="3rem 0 0 0"
            title={intl.formatMessage({ id: 'settings.user.company' })}
            testId="company-heading"
          />
          {/* Company */}
          <Setting
            label={intl.formatMessage({ id: 'settings.user.company' })}
            value={userProfile.company}
            onEdit={openEditCompany}
            onEditLabel="global.edit"
            testId="company"
            editable={!disabledSettings.company}
          />
          {/* Department */}
          <Setting
            label={intl.formatMessage({ id: 'settings.user.department' })}
            value={userProfile.department}
            onEdit={openEditDepartment}
            onEditLabel="global.edit"
            testId="department"
            editable={!disabledSettings.department}
          />
        </div>

        <div>
          <SectionHeading
            sticky
            margin="3rem 0 0 0"
            title={intl.formatMessage({ id: 'settings.user.preferences' })}
            testId="preferences-heading"
          />
          {/* Languages */}
          <Setting
            label={intl.formatMessage({ id: 'settings.user.language' })}
            value={
              Object?.values(LANGUAGES)?.find(
                (language) => language.code === locale || language.code === locale.split('-')[0],
              )?.name
            }
            onEdit={openLanguageSettingModal}
            onEditLabel="global.edit"
            testId="language"
            editable={!disabledSettings.language}
          />
          {/* Timezone */}
          <Setting
            label={intl.formatMessage({ id: 'settings.user.timezone' })}
            value={parseTimezoneLabel(userProfile.timezone)}
            onEdit={openEditTimezoneModal}
            onEditLabel="global.edit"
            testId="timezone"
            editable={!disabledSettings.timezone}
          />
          {/* Date format */}
          <Setting
            style={{ height: 'auto' }}
            labelStyles={{ marginTop: '5px' }}
            label={intl.formatMessage({ id: 'settings.user.dateFormat' })}
            value={
              <RadioButtonGroup>
                <RadioButton
                  checked={userProfile.date_format === 'dm'}
                  onChange={() => editProfile({ date_format: 'dm' })}
                  size="small"
                  testId="dd-mm-yyyy-date-format-radio-button"
                  disabled={disabledSettings.date_format}
                >
                  DD/MM/YYYY
                </RadioButton>
                <RadioButton
                  checked={userProfile.date_format === 'md'}
                  onChange={() => editProfile({ date_format: 'md' })}
                  size="small"
                  testId="mm-dd-yyyy-date-format-radio-button"
                  disabled={disabledSettings.date_format}
                >
                  MM/DD/YYYY
                </RadioButton>
              </RadioButtonGroup>
            }
            testId="date-format"
          />
          {/* Time format */}
          <Setting
            style={{ height: 'auto' }}
            labelStyles={{ marginTop: '5px' }}
            label={intl.formatMessage({ id: 'settings.user.timeFormat' })}
            value={
              <RadioButtonGroup>
                <RadioButton
                  checked={userProfile.time_format === '12h'}
                  onChange={() => editProfile({ time_format: '12h' })}
                  size="small"
                  testId="12-hour-time-format-radio-button"
                  disabled={disabledSettings.time_format}
                >
                  {intl.formatMessage({ id: 'settings.user.hour' }, { value: 12 })}
                </RadioButton>
                <RadioButton
                  checked={userProfile.time_format === '24h'}
                  onChange={() => editProfile({ time_format: '24h' })}
                  size="small"
                  testId="24-hour-time-format-radio-button"
                  disabled={disabledSettings.time_format}
                >
                  {intl.formatMessage({ id: 'settings.user.hour' }, { value: 24 })}
                </RadioButton>
              </RadioButtonGroup>
            }
            testId="time-format"
          />
        </div>
      </div>
    </>
  );
};

export default UserProfile;
