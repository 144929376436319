import { TypedEmitter } from 'tiny-typed-emitter';

import type { ToastElement } from '_common/components/ToastSystem/Toast';

interface AppEvents {
  NOTIFY: (toast: ToastElement) => void;
  NOTIFY_RESET: (toastId: ToastElement['id']) => void;
  NOTIFY_CLEAR_ALL: () => void;
}

const appEvents = new TypedEmitter<AppEvents>();

export default appEvents;
