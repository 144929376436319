import { ELEMENTS } from 'Editor/services/consts';
import { uniq } from 'lodash';
import { BaseTypedEmitter, RealtimeObject } from '_common/services/Realtime';
import { Structure, StructureData, DEFAULT_CAPTION, CaptionData } from '../../models';
import BaseController from '../BaseController';

export class Caption extends BaseTypedEmitter<{
  UPDATED: (label: string, data: CaptionData) => void;
}> {
  protected label: string;
  protected data: CaptionData;

  constructor(label: string, data: CaptionData) {
    super();
    this.label = label;
    this.data = data;
  }

  get numbering() {
    return this.data.nf;
  }

  get separator() {
    return this.data.s;
  }

  get chapter() {
    return this.data.c;
  }

  get labelStyle() {
    return this.data.ls || ELEMENTS.ParagraphElement.BASE_STYLES.FIGURE_CAPTION;
  }

  get position() {
    return this.data.p;
  }

  updateData(data: CaptionData) {
    this.data = {
      ...this.data,
      ...data,
    };
    this.emit('UPDATED', this.label, this.data);
  }
}
export class CaptionsController extends BaseController {
  private structure?: Structure;
  private captions: { [label: string]: Caption } = {};

  constructor(Data: Editor.Data.State) {
    super(Data);
    this.handleCaptionAdded = this.handleCaptionAdded.bind(this);
    this.handleCaptionLoad = this.handleCaptionLoad.bind(this);
    this.handleCaptionRemoved = this.handleCaptionRemoved.bind(this);
  }

  start(documentId: string): void {
    this.structure = this.Data.models?.get(
      this.Data?.models.TYPE_NAME.STRUCTURE,
      `DS${documentId}`,
    );

    this.structure?.on('LOADED', this.handleCaptionLoad);
    this.structure?.on('LOAD_CAPTIONS', this.handleCaptionLoad);
    this.structure?.on('ADDED_CAPTION', this.handleCaptionAdded);
    this.structure?.on('UPDATED_CAPTION', this.handleCaptionAdded);
    this.structure?.on('REMOVED_CAPTION', this.handleCaptionRemoved);
    if (this.structure?.loaded) {
      this.handleCaptionLoad();
    }
  }

  stop(): void {}

  destroy(): void {}

  private handleCaptionLoad() {
    let cpt = this.structure?.get([this.structure?.KEYS.CAPTIONS]) || {};
    let newLabels = Object.keys(cpt);
    let oldLabels = Object.keys(this.captions);
    let labels = uniq([...newLabels, ...oldLabels]);
    for (let index = 0; index < labels.length; index++) {
      let label = labels[index];
      if (newLabels.includes(label)) {
        if (this.captions[label]) {
          this.captions[label].updateData(cpt[label]);
        } else {
          this.captions[label] = new Caption(label as string, cpt[label]);
        }
      } else if (oldLabels.includes(label)) {
        delete this.captions[label];
      }
    }
  }

  private handleCaptionAdded(label: string, data: CaptionData) {
    if (this.captions[label]) {
      this.captions[label].updateData(data);
    } else {
      this.captions[label] = new Caption(label, data);
    }
  }

  private handleCaptionRemoved(label: string) {
    if (this.captions[label]) {
      delete this.captions[label];
    }
  }

  async createCaption(
    label: string,
    data?: Partial<CaptionData>,
  ): Promise<RealtimeObject<StructureData> | undefined> {
    const d = {
      ...DEFAULT_CAPTION,
      ...data,
    };
    return this.structure?.set([this.structure?.KEYS.CAPTIONS, label], d);
  }

  async updateCaption(
    label: string,
    data: Partial<CaptionData>,
  ): Promise<RealtimeObject<StructureData> | undefined> {
    const d = {
      ...DEFAULT_CAPTION,
      ...data,
    };
    return this.structure?.set([this.structure?.KEYS.CAPTIONS, label], d);
  }

  async deleteCaption(label: string): Promise<RealtimeObject<StructureData> | undefined> {
    return this.structure?.delete([this.structure?.KEYS.CAPTIONS, label]);
  }

  availableCaptions(): string[] {
    return Object.keys(this.captions);
  }

  caption(label: string): Caption {
    return this.captions[label];
  }
}
