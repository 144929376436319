import { getExportPDF } from 'PDF/redux/PDFGeneralSlice';
import useInterval from './useInterval';
import { useDispatch, useSelector } from './redux';
import { InstanceService } from '_common/services';

const useExportPDFExplorer = () => {
  const dispatch = useDispatch();
  const exporting = useSelector((state) => state.pdf.general.exporting);
  const elementId = Object.keys(useSelector((state) => state.table.selected))[0];
  const object = useSelector((state) => state.app.data[elementId]);

  const controller = new AbortController();

  useInterval(
    async () => {
      if (exporting && elementId) {
        const { data } = await new InstanceService({ controller }).checkJobStatus({
          jobs: [exporting],
        });
        // @ts-expect-error job api spec is missing
        if (data[exporting].status === 'finished') {
          dispatch(getExportPDF({ id: elementId }))
            .unwrap()
            .then((data) => {
              const url = window.URL.createObjectURL(data);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${object.name}`);
              document.body.appendChild(link);
              link.click();
              link.remove();
            });
        }
      }
    },
    exporting ? 3000 : null,
  );
};

export default useExportPDFExplorer;
