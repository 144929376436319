import { v4 as uuid } from 'uuid';
import { AnnotationFlags } from '../../models';

export class AnnotationBuilder {
  private data: PDF.Annotation;
  constructor(base: PDF.AnnotationCreationData) {
    // TODO: check dates
    const date = new Date();
    // @ts-expect-error
    this.data = {
      subtype: base.subtype,
      id: uuid(),
      annotationFlags: AnnotationFlags.Print,
      pageNumber: base.pageNumber,
      color: {
        ...(base.color || {}),
      },
      border: {
        style: 'S',
        width: 0, // no border
        ...(base.border || {}),
      },
      creationDate: date,
      modificationDate: date,
      content: {
        content: base.content ?? [],
        dir: 'ltr',
      },
      rect: this.getRelativeRectFromDOMRect(base.boundingRect, base.pageRect, base.viewportScale),
      priority: 'Medium',
    };
    if (this.data.subtype === 'Task') {
      if (base.subtype === 'Task' && base.task) {
        this.data.status = base.task.status || 'td';
        this.data.dueDate = base.task.dueDate || '';
        this.data.assignee = base.task.assignee || '';
        this.data.watchers = base.task.watchers || [];
      } else {
        this.data.status = 'td';
        this.data.dueDate = '';
        this.data.assignee = '';
        this.data.watchers = [];
      }
    }
  }

  protected getRelativeRectFromDOMRect(
    boundingRect: PDF.Annotation.Rect | DOMRect,
    pageRect: PDF.Annotation.Rect | DOMRect,
    viewportScale: number,
  ) {
    const rect: PDF.Annotation.Rect = {
      left: 0,
      bottom: 0,
      right: 0,
      top: 0,
      width: 0,
      height: 0,
    };

    if (boundingRect && pageRect && viewportScale) {
      rect.left = (boundingRect.left - pageRect.left) / viewportScale;
      rect.right = (boundingRect.right - pageRect.left) / viewportScale;
      rect.top = (pageRect.bottom - boundingRect.top) / viewportScale;
      rect.bottom = (pageRect.bottom - boundingRect.bottom) / viewportScale;
      rect.width = Math.abs(rect.right - rect.left);
      rect.height = Math.abs(rect.top - rect.bottom);
    }

    return rect;
  }

  setBorder(border: Partial<PDF.Annotation.BorderStyle>) {
    if (!this.data.border) {
      this.data.border = {
        style: 'S',
        width: 0, // no border
      };
    }
    if (border?.style) {
      this.data.border.style = border.style;
    }

    if (border?.width) {
      this.data.border.width = border.width;
    }
    return this;
  }

  setColor(color: PDF.Annotation.Color) {
    if (!this.data.color) {
      this.data.color = {
        stroke: '#FFD100',
      };
    }
    if (color?.fill) {
      this.data.color.fill = color.fill;
    }
    if (color?.stroke) {
      this.data.color.stroke = color.stroke;
    }
    return this;
  }

  setAuthor(authorId: PDF.Annotation['authorId']) {
    this.data.authorId = authorId;
    return this;
  }

  setQuadPoints(quadPoints: PDF.Annotation.QuadPoint[]) {
    if (
      this.data.subtype === 'Highlight' ||
      this.data.subtype === 'StrikeOut' ||
      this.data.subtype === 'Underline' ||
      this.data.subtype === 'Task'
    ) {
      this.data.quadPoints = quadPoints;
    }
    return this;
  }

  setInkLists(inkLists: PDF.Annotation.Ink['inkLists']) {
    if (this.data.subtype === 'Ink') {
      this.data.inkLists = inkLists;
    }
    return this;
  }

  setAnnotationFlags(flags: number) {
    this.data.annotationFlags = flags;
    return this;
  }

  setContent(content: PDF.Annotation.ContentsType['content']) {
    if (!this.data.content) {
      this.data.content = {
        content: [],
        dir: 'ltr',
      };
    }
    this.data.content.content = content;
    return this;
  }

  setLineCoordinates(coordinates: PDF.Annotation.LineCoordinates) {
    if (this.data.subtype === 'Line') {
      this.data.lineCoordinates = coordinates;
    }
    return this;
  }

  setLineEndings(endings: PDF.Annotation.LineEndings) {
    if (this.data.subtype === 'Line') {
      this.data.lineEndings = endings;
    }
    return this;
  }

  setRect(
    pageRect: PDF.Annotation.Rect,
    boundingRect: PDF.Annotation.Rect,
    scale: number,
    strokeWidth: number = 0,
  ) {
    const bottom = pageRect.height / scale - boundingRect.bottom;
    const top = bottom + boundingRect.height;

    const rect: PDF.Annotation.Rect = {
      left: 0,
      bottom: 0,
      right: 0,
      top: 0,
      width: 0,
      height: 0,
    };

    if (boundingRect && pageRect && scale) {
      rect.left = boundingRect.left - strokeWidth / 2;
      rect.right = boundingRect.right - strokeWidth / 2;
      rect.top = top - strokeWidth / 2;
      rect.bottom = bottom - strokeWidth / 2;
      rect.width = Math.abs(rect.right - rect.left) + strokeWidth;
      rect.height = Math.abs(rect.top - rect.bottom) + strokeWidth;
      this.data.rect = rect;
    }

    return this;
  }

  validate() {
    if (!this.data.authorId) {
      throw new Error('An author id must be provided!');
    }
    if (!this.data.rect) {
      throw new Error('A rect must be provided!');
    }
    if (this.data.subtype === 'Ink') {
      if (!this.data.inkLists) {
        throw new Error(`InkLists must be provided for type ${this.data.subtype}!`);
      }
    }
    return this;
  }

  get() {
    this.validate();
    return this.data;
  }
}
