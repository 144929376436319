import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Divider } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import {
  DueDateSystem,
  FormattedDate,
  FormattedTime,
  TagSystem,
  UserPresentation,
} from '_common/components';

import styles from './General.module.scss';
import Section from './Section/Section';
import Label from './Label/Label';
import EditableField, { EditableFieldProps } from './EditabledField/EditableField';
import { editDescription, renameObject } from 'App/redux/appSlice';

type GeneralProps = {
  objectId: ObjectId;
};
type SectionId = 'title' | 'description';

const General = ({ objectId }: GeneralProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const object = useSelector((state) => state.app.data[objectId]);
  const [sections, setSections] = useState<{ [x in SectionId]: { isEditing: boolean } }>({
    title: { isEditing: false },
    description: { isEditing: false },
  });

  const handleSaveName: EditableFieldProps['onSave'] = (newValue) => {
    if (newValue) {
      dispatch(renameObject({ objectId: object.id, objectType: object.type, newName: newValue }));
    }
  };
  const handleSaveDescription: EditableFieldProps['onSave'] = (newValue) => {
    dispatch(
      editDescription({
        objectId: object.id,
        objectType: object.type,
        newDescription: newValue ?? '',
      }),
    );
  };

  const handleSectionIsEditing: EditableFieldProps<SectionId>['onEditing'] = ({
    id,
    isEditing,
  }) => {
    const newSections = { ...sections };
    (Object.keys(sections) as Array<keyof typeof sections>).forEach((sectionId) => {
      if (isEditing) {
        if (sectionId === id) {
          sections[sectionId].isEditing = true;
        } else {
          sections[sectionId].isEditing = false;
        }
      }
    });
    setSections(newSections);
  };

  return (
    <div className={styles.root}>
      <Section>
        <Label messageId="settings.general.title" />
        <EditableField
          id="title"
          testId="document-details-general-title"
          type="input"
          isEditing={sections.title.isEditing}
          value={object.name}
          placeholder={intl.formatMessage({ id: 'NEW_DOCUMENT_PLACEHOLDER' })}
          onSave={handleSaveName}
          onEditing={handleSectionIsEditing}
          maxChars={146}
          errorLabels={{
            maxChars: intl.formatMessage(
              {
                id: 'DOCUMENT_TITLE_CANNOT_HAVE_MORE_THAN_X_CHARACTERS',
              },
              {
                maxChars: 146,
              },
            ),
            specialChars: intl.formatMessage(
              {
                id: 'CANNOT_HAVE_THE_FOLLOWING_CHARACTERS',
              },
              {
                specialChars: '<>/\\:?*”',
              },
            ),
          }}
        />
      </Section>
      <Divider margin="0" />
      <Section>
        <Label messageId="global.description" />
        <EditableField
          id="description"
          testId="document-details-general-description"
          type="textarea"
          isEditing={sections.description.isEditing}
          value={object.description}
          placeholder={intl.formatMessage({ id: 'NEW_DOCUMENT_DESCRIPTION_PLACEHOLDER' })}
          onSave={handleSaveDescription}
          onEditing={handleSectionIsEditing}
          maxChars={1500}
          errorLabels={{
            maxChars: intl.formatMessage(
              {
                id: 'DESCRIPTIONS_CANNOT_HAVE_MORE_THAN_X_CHARACTERS',
              },
              {
                maxChars: 1500,
              },
            ),
          }}
        />
      </Section>
      <Divider margin="0" />
      <Section>
        <Label messageId="global.owner" />
        <UserPresentation userId={object.owner} displayEmail />
      </Section>
      <Divider margin="0" />
      <Section>
        <Label messageId="CREATION_DATE" />
        <div className={styles.dateValues}>
          <FormattedDate date={object.time.creation} type="extended" />
          &nbsp;
          <FormattedTime time={object.time.creation} type="meridiem" />
        </div>
      </Section>
      <Divider margin="0" />
      <Section>
        <div className={styles.dueDateLabels}>
          <Label messageId="storage.browserSidebar.dueDate.dueDate" />
          <Label messageId="REMINDER" />
        </div>
        <DueDateSystem objectId={objectId} size="large" canEdit />
      </Section>
      <Divider margin="0" />
      <Section>
        <Label messageId="settings.general.tags" />
        <TagSystem
          objectId={objectId}
          size="large"
          width="31rem"
          placeholder={intl.formatMessage({ id: 'SELECT_TAG' })}
          menuPlacement="top"
          testId="document-details"
        />
      </Section>
    </div>
  );
};

export default General;
