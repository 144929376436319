import { Logger } from '_common/services';
import ReduxInterface from '../ReduxInterface/ReduxInteface';
import { CommandFactory } from './Commands';
import { Descendant } from 'slate';
import { ClipboardManager } from '../EditionManager/Clipboard';
import { NormalManipulator, SuggestionManipulator } from './Manipulators';

// interface EditionEvents {
//   //? edition events
// }

export default class EditionManager {
  private debug: boolean = true;

  private context: Editor.Edition.Context;

  constructor() {
    this.keyDown = this.keyDown.bind(this);
    this.keyUp = this.keyUp.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.cut = this.cut.bind(this);
    this.copy = this.copy.bind(this);
    this.paste = this.paste.bind(this);
    this.drop = this.drop.bind(this);
    this.input = this.input.bind(this);
    this.compositionEnd = this.compositionEnd.bind(this);

    this.context = {
      plataformInfo: ReduxInterface.getPlatformInfo(),
      editionMode: 'DISABLED',
    };

    this.context.commandFactory = new CommandFactory(this.context);
  }

  private setupEventListeners() {
    if (this.context.eventsManager) {
      this.context.eventsManager.addListener('KEY_DOWN', this.keyDown);
      this.context.eventsManager.addListener('KEY_UP', this.keyUp);
      this.context.eventsManager.addListener('KEY_PRESS', this.keyPress);
      this.context.eventsManager.addListener('MOUSE_DOWN', this.mouseDown);
      this.context.eventsManager.addListener('MOUSE_UP', this.mouseUp);
      this.context.eventsManager.addListener('MOUSE_OVER', this.mouseOver);
      this.context.eventsManager.addListener('PASTE', this.paste);
      this.context.eventsManager.addListener('CUT', this.cut);
      this.context.eventsManager.addListener('COPY', this.copy);
      this.context.eventsManager.addListener('DROP', this.drop);
      this.context.eventsManager.addListener('INPUT', this.input);
      this.context.eventsManager.addListener('COMPOSITION_END', this.compositionEnd);
    }
  }

  private removeEventListeners() {
    if (this.context.eventsManager) {
      this.context.eventsManager.removeListener('KEY_DOWN', this.keyDown);
      this.context.eventsManager.removeListener('KEY_UP', this.keyUp);
      this.context.eventsManager.removeListener('KEY_PRESS', this.keyPress);
      this.context.eventsManager.removeListener('MOUSE_DOWN', this.mouseDown);
      this.context.eventsManager.removeListener('MOUSE_UP', this.mouseUp);
      this.context.eventsManager.removeListener('MOUSE_OVER', this.mouseOver);
      this.context.eventsManager.removeListener('PASTE', this.paste);
      this.context.eventsManager.removeListener('CUT', this.cut);
      this.context.eventsManager.removeListener('COPY', this.copy);
      this.context.eventsManager.removeListener('DROP', this.drop);
      this.context.eventsManager.removeListener('INPUT', this.input);
      this.context.eventsManager.removeListener('COMPOSITION_END', this.compositionEnd);
    }
  }

  start(editorContext: Editor.Context) {
    this.context.documentContainer = editorContext.documentContainer;
    this.context.navigationManager = editorContext.navigationManager;
    this.context.stylesHandler = editorContext.stylesHandler;
    this.context.documentParser = editorContext.documentParser;
    this.context.eventsManager = editorContext.eventsManager;
    this.context.DataManager = editorContext.DataManager;
    this.context.VisualizerManager = editorContext.visualizerManager;
    this.context.selection = editorContext.selection;

    // contentManipulator: new NormalManipulator(),

    // TODO: move clipboard to new edition
    if (
      editorContext.documentContainer &&
      editorContext.stylesHandler &&
      editorContext.DataManager &&
      editorContext.visualizerManager
    ) {
      this.context.clipboard = new ClipboardManager(
        editorContext.documentContainer,
        editorContext.stylesHandler,
        editorContext.DataManager,
        editorContext.visualizerManager,
      );
    }

    //? this.selectionManager = editorContext.selectionManager;
    //? this.changeTracker = editorContext.changeTracker;

    //? edition events controller ???

    this.setupEventListeners();

    if (this.debug) {
      Logger.info('EditionManager version 2 started!');
    }
  }

  destroy() {
    this.removeEventListeners();
    if (this.context.clipboard) {
      this.context.clipboard.destroy();
    }
  }

  setEditionMode(mode: Editor.Edition.Mode) {
    switch (mode) {
      case 'NORMAL':
        this.context.editionMode = 'NORMAL';
        // set content manipulator
        this.context.contentManipulator = new NormalManipulator(this.context);
        break;
      case 'SUGGESTIONS':
        this.context.editionMode = 'SUGGESTIONS';
        // set content manipulator
        this.context.contentManipulator = new SuggestionManipulator(this.context);
        break;
      case 'DISABLED':
        this.context.editionMode = 'DISABLED';
        // remove content manipulator
        delete this.context.contentManipulator;
        break;
    }
  }

  /**
   * @deprecated temporary function for retro compatibility remove after refactor and use setEditionMode directly
   */
  disableEdition() {
    this.setEditionMode('DISABLED');
  }

  /**
   * @deprecated temporary function for retro compatibility remove after refactor and use setEditionMode directly
   */
  enableSuggestionMode() {
    this.setEditionMode('SUGGESTIONS');
  }

  /**
   * @deprecated temporary function for retro compatibility remove after refactor and use setEditionMode directly
   */
  enableNormalMode() {
    this.setEditionMode('NORMAL');
  }

  // #################################################
  //                  event handlers
  // #################################################
  private keyDown(event: KeyboardEvent) {
    if (this.debug) {
      Logger.trace('EditionManager keyDown', event);
    }

    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }

    if (!commandFactory.isKeyDefaultAllowed(event)) {
      event.preventDefault();
      event.stopPropagation();

      // this if is here just because firefox in linux is an idiot sometimes
      const lastKeyDown = this.context.lastKeyDown;
      if (
        !lastKeyDown ||
        (lastKeyDown !== 'Dead' && lastKeyDown !== 'Undifined' && lastKeyDown !== 'Unidentified')
      ) {
        let command = commandFactory.getKeyDownCommand(event);

        if (command) {
          // let action = new ActionContext(event);

          command.exec();
        }
      }

      const platform = this.context.plataformInfo;
      if (platform.os.linux && platform.browser.firefox) {
        this.context.lastKeyDown = event.key;
      }
    }
  }

  private keyUp(event: KeyboardEvent) {
    // nothing to do here
  }

  private keyPress(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  private mouseUp(eventData: Parameters<Editor.Events.EventTypes['MOUSE_UP']>[0]) {
    logger.warn('Not yet implemented!!');
  }

  private mouseOver(eventData: Parameters<Editor.Events.EventTypes['MOUSE_OVER']>[0]) {
    eventData.event.preventDefault();
  }

  private mouseDown(event: MouseEvent) {
    logger.warn('Not yet implemented!!');
  }

  private cut(event: ClipboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    logger.warn('Not yet implemented!!');
  }

  private copy(event: ClipboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    logger.warn('Not yet implemented!!');
  }

  private paste(event: ClipboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    logger.warn('Not yet implemented!!');
  }

  private drop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    logger.warn('Not yet implemented!!');
  }

  private input(event: Event) {
    logger.warn('Not yet implemented!!');
  }

  private compositionEnd(event: CompositionEvent) {
    logger.warn('Not yet implemented!!');
  }

  // #################################################
  //         content manager event handlers
  // #################################################
  insertInlineElement(inlineElement: string | Node) {
    logger.warn('Not yet implemented!!');
  }

  //TODO create deleteOptions type
  handleRemoveSelection(deleteOptions = {}) {
    logger.warn('Not yet implemented!!');
  }

  //@ts-expect-error evaluate if function is needed
  handleSaveChanges(actionContext) {
    // TODO: evaluate if this function is needed
    logger.warn('Not yet implemented!!');
  }

  // #################################################
  //              insert element handlers
  // #################################################
  insertTable(rows: number, columns: number) {
    logger.warn('Not yet implemented!!');
  }

  insertPageBreak() {
    logger.warn('Not yet implemented!!');
  }

  insertSectionBreak(type: string) {
    logger.warn('Not yet implemented!!');
  }

  insertColumnBreak() {
    logger.warn('Not yet implemented!!');
  }

  insertTableOfContents() {
    logger.warn('Not yet implemented!!');
  }

  insertListOfFigures() {
    logger.warn('Not yet implemented!!');
  }

  insertListOfTables() {
    logger.warn('Not yet implemented!!');
  }

  insertKeywordsElement() {
    logger.warn('Not yet implemented!!');
  }

  insertAuthorsElement() {
    logger.warn('Not yet implemented!!');
  }

  insertReferenceSectionElement() {
    logger.warn('Not yet implemented!!');
  }

  // #################################################
  //              fields Handling
  //            captions / cross ref
  // #################################################

  insertCaptionElement(options = {}) {
    logger.warn('Not yet implemented!!');
  }

  editCaptionElement(options = {}) {
    logger.warn('Not yet implemented!!');
  }

  insertCrossReferenceElement(options: Editor.Data.CrossReferences.PresentationTextOptionsType) {
    logger.warn('Not yet implemented!!');
  }

  editCrossReferenceElement(options: Editor.Data.CrossReferences.PresentationTextOptionsType) {
    logger.warn('Not yet implemented!!');
  }

  updateCrossReferenceElement() {
    logger.warn('Not yet implemented!!');
  }

  // #################################################
  //              Tables Operations Handling
  // #################################################
  handleDeleteCells(
    shiftCells: Editor.Edition.TableOperationArgsType['shiftCells'] = 'SHIFT_LEFT',
  ) {
    logger.warn('Not yet implemented!!');
  }

  handleDeleteRows() {
    logger.warn('Not yet implemented!!');
  }

  handleDeleteRowAtIndex(index: number[]) {
    logger.warn('Not yet implemented!!');
  }

  handleDeleteColumns() {
    logger.warn('Not yet implemented!!');
  }

  handleDeleteColumnAtIndex(index: number[]) {
    logger.warn('Not yet implemented!!');
  }

  handleDeleteTable() {
    logger.warn('Not yet implemented!!');
  }

  handleInsertRow(before: boolean = false) {
    logger.warn('Not yet implemented!!');
  }

  handleInsertRowAtIndex(index: number[]) {
    logger.warn('Not yet implemented!!');
  }

  handleInsertColumn(before = false) {
    logger.warn('Not yet implemented!!');
  }

  handleInsertColumnAtIndex(index: number[]) {
    logger.warn('Not yet implemented!!');
  }

  handleMergeCells() {
    logger.warn('Not yet implemented!!');
  }

  handleSplitCells(splitColumns: number = 2, splitRows: number = 1, mergeCells: boolean = false) {
    logger.warn('Not yet implemented!!');
  }

  /**
   *
   * @param {*} table
   * @param {*} columnWidths in points (pt)
   */
  handleUpdateColumnWidths(
    table: Editor.Elements.TableElement,
    columnWidths: {
      [index: number]: { current: Editor.Data.Node.TableWidth; delta: number };
    },
  ) {
    logger.warn('Not yet implemented!!');
  }

  /**
   *
   * @param table
   * @param rowHeights in points (pt)
   */
  handleUpdateRowHeigths(table: Editor.Elements.TableElement, rowHeights: number[]) {
    logger.warn('Not yet implemented!!');
  }

  handleUptadeTableSize(
    table: Editor.Elements.TableElement,
    height: number,
    width: Editor.Data.Node.TableWidth,
  ) {
    logger.warn('Not yet implemented!!');
  }

  handleUpdateTableProperties(propertiesData: Editor.Styles.TablePropertiesData) {
    logger.warn('Not yet implemented!!');
  }

  // #################################################
  //              Images Operations Handlers
  // #################################################
  async handleInsertImage(image: File) {
    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }
    let command = commandFactory.getImageCommand('INSERT_IMAGE', image);

    if (command) {
      await command.exec();
    }
  }

  async handleChangeImage(image: File) {
    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }

    let command = commandFactory.getImageCommand('CHANGE_IMAGE', image);

    if (command) {
      await command.exec();
    }
  }

  async handleUpdateImageSize(
    image: Editor.Elements.FigureElement | Editor.Elements.ImageElement,
    height: number,
    width: number,
  ) {
    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }

    let command = commandFactory.getImageCommand('UPDATE_IMAGE_SIZE', image.id, height, width);

    if (command) {
      await command.exec();
    }
  }

  async handleUpdateImageProperties(properties: Editor.Styles.ImageProperties) {
    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }
    let command = commandFactory.getImageCommand(
      'UPDATE_IMAGE_PROPERTIES',
      undefined,
      undefined,
      undefined,
      properties,
    );

    if (command) {
      await command.exec();
    }
  }

  // #################################################
  //                Citations handlers
  // #################################################
  async handleInsertCitation(citationId: string) {
    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }
    let command = commandFactory.getCitationCommand('INSERT_CITATION', citationId);

    if (command) {
      await command.exec();
    }
  }

  // #################################################
  //                Notes handlers
  // #################################################
  startCreatingNote(type: string) {
    logger.warn('Not yet implemented!!');
  }

  handleInsertNote(type: string, id: string) {
    logger.warn('Not yet implemented!!');
  }

  // #################################################
  //              Hyperlink handlers
  // #################################################
  handleInsertHyperlink(
    links: any[],
    url: string,
    showTextToDisplay: boolean = false,
    textToDisplay: string = '',
  ) {
    logger.warn('Not yet implemented!!');
  }

  handleDeleteHyperlink() {
    logger.warn('Not yet implemented!!');
  }

  // #################################################
  //              Comments handlers
  // #################################################
  async handleAddComment(comment: Descendant[]) {
    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }
    let command = commandFactory.getCommentsCommand('ADD_COMMENT', comment);

    if (command) {
      await command.exec();
    }
  }

  async handleAddTemporaryComment() {
    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }
    let command = commandFactory.getCommentsCommand('ADD_TEMPORARY_COMMENT');

    if (command) {
      await command.exec();
    }
  }

  async handleRemoveTemporaryComment() {
    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }
    let command = commandFactory.getCommentsCommand('REMOVE_TEMPORARY_COMMENT');

    if (command) {
      await command.exec();
    }
  }

  // #################################################
  //              Equation handlers
  // #################################################
  async handleInsertEquation(value: any) {
    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }

    let command = commandFactory.getEquationsCommand('INSERT_EQUATION', value);

    if (command) {
      await command.exec();
    }
  }

  async handleEditEquation() {
    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }
    let command = commandFactory.getEquationsCommand('EDIT_EQUATION');

    if (command) {
      await command.exec();
    }
  }

  async handleUpdateEquation(blockId: string, elementId: string, value: any) {
    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }
    let command = commandFactory.getEquationsCommand('UPDATE_EQUATION', value, blockId, elementId);

    if (command) {
      await command.exec();
    }
  }

  // #################################################
  //            copy/paste functions
  // #################################################
  removePasteOptions() {
    logger.warn('Not yet implemented!!');
  }

  updatePasteOptions() {
    logger.warn('Not yet implemented!!');
  }

  async handlePasteOptions(pasteOptions: Editor.Clipboard.PasteOptions) {
    logger.warn('Not yet implemented!!');
  }

  setPasteEndMarker(element: Editor.Elements.PasteMarkerElement) {
    logger.warn('Not yet implemented!!');
  }

  // #################################################
  //              other functions
  // #################################################
}
