import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helper } from 'dodoc-design-system';

import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import { useGetTagsListQuery } from './TagsApi';
import TagList from './TagList';
import TagsHeading from './TagsHeading';

type EmptyViewProps = {
  id: string;
};

const EmptyView = ({ id }: EmptyViewProps) => {
  return (
    <Helper margin="2rem 0 0 0" testId="tenant-settings-page-no-tags-helper">
      <FormattedMessage id={id} />
    </Helper>
  );
};

const Tags = () => {
  const { data: tags } = useGetTagsListQuery();

  const [search, setSearch] = useState('');

  const testId = 'tenant-settings-tags';

  return (
    <>
      <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ margin: '3rem 0 0 0' }}>
        <TagsHeading search={search} onSearchChange={setSearch} testId={testId} />
      </IntlErrorBoundary>

      {tags?.list && tags.list.length > 0 ? (
        <IntlErrorBoundary size="large" margin="10rem 0 0 0">
          <TagList search={search} testId={testId} />
        </IntlErrorBoundary>
      ) : (
        <div data-testid={testId ? `${testId}-emptystate` : undefined}>
          <EmptyView id="EMPTY_LIST_OF_TAGS" />
        </div>
      )}
    </>
  );
};

export default Tags;
