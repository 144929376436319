import { memo, useEffect, useRef, useState } from 'react';
import styles from './Navigation.module.scss';
import { usePDFContext } from 'PDF/PDFContext';
import { AutoSizer } from 'react-virtualized';
import { VariableSizeList as List } from 'react-window';
import Thumbnail from 'PDF/Navigation/Thumbnail';
import { FormattedMessage } from 'react-intl';
import { useSelector } from '_common/hooks';
import {
  NAVIGATION_GAP_SIZE,
  NAVIGATION_MAX_THUMBNAIL_HEIGHT,
  NAVIGATION_THUMBNAIL_WIDTH,
} from 'PDF/consts';

const Navigation = () => {
  const pdfManager = usePDFContext();
  const ref = useRef<any>(null);
  const currentPageNumber = useSelector((state) => state.pdf.general.currentPageNumber);
  const [initialScrollDone, setInitialScrollDone] = useState(false);
  const numPages = pdfManager.getNumPages();

  const scrollToItem = (itemIndex: number) => {
    if (ref.current) {
      ref.current.scrollToItem(itemIndex, 'smart');
    }
  };

  useEffect(() => {
    if (ref.current && initialScrollDone) {
      scrollToItem(currentPageNumber - 1);
    }
  }, [currentPageNumber, initialScrollDone]);

  return (
    <>
      <div className={styles.title}>
        <FormattedMessage id="THUMBNAILS" />
      </div>
      {/* @ts-expect-error */}
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={ref}
            height={height - 64 - 24} // thumbnails title size and footer bar size
            estimatedItemSize={NAVIGATION_MAX_THUMBNAIL_HEIGHT + NAVIGATION_GAP_SIZE * 3}
            itemCount={numPages}
            itemSize={(index) => {
              const pageHeight = pdfManager.getPageHeight(index + 1);
              const pageWidth = pdfManager.getPageWidth(index + 1);

              return (
                (NAVIGATION_THUMBNAIL_WIDTH * pageHeight) / pageWidth + NAVIGATION_GAP_SIZE * 3
              );
            }}
            width={width}
            onItemsRendered={() => {
              if (ref.current && !initialScrollDone) {
                scrollToItem(currentPageNumber - 1);
                setInitialScrollDone(true);
              }
            }}
          >
            {Thumbnail}
          </List>
        )}
      </AutoSizer>
    </>
  );
};

export default memo(Navigation);
