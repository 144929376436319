import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Panel, PanelHeading } from '_common/suite/components';
import { useStructureSync } from './SyncStore';

const Navigation = () => {
  const structure = useStructureSync();
  return (
    <Panel side="left" width="33rem">
      <PanelHeading>
        <FormattedMessage id="THUMBNAILS" />
      </PanelHeading>
      <div style={{ overflow: 'auto' }}>
        {structure.sldIdLst.map((value) => {
          return <p key={value}>slide id: {value}</p>;
        })}
      </div>
    </Panel>
  );
};

export default memo(Navigation);
