import { ReactNode } from 'react';
import { List } from 'dodoc-design-system';
import { AxiosResponse } from 'axios';

import { useDispatch, useSelector } from '_common/hooks';
import IntlErrorBoundary from '../IntlErrorBoundary/IntlErrorBoundary';

import {
  listObjects,
  lazyLoad,
  toggleSelection,
  singleSelection,
  groupSelection,
} from './TableSlice';

import Header, { GenericHeaderDTI } from './Header/GenericHeader';
import Footer from './Footer/Footer';

import styles from './Table.module.scss';

export type TableProps = {
  // Table identity in order to differenciate content from the other tables
  identity: Table.Identity;
  // Object which defines which columns the table contains
  columns: Table.Column[];
  // Component used to render each table row
  RowComponent: MyAny;
  // Define if table rows can be selected
  selectable?: boolean;
  // Define if table header should be rendered (default = true)
  header?: boolean;
  // Function which returns the data intended to be displayed by the table
  fetchObjects: (params: Request.AllListParams) => Promise<AxiosResponse<unknown>>;
  // Function which
  renderEmptyState?: () => ReactNode;
  renderLoadingState?: (loadingState: Table.Loading) => ReactNode;
  renderFooter?: () => ReactNode;
  dataTestId?: {
    root?: string;
    header?: GenericHeaderDTI;
    baseRow?: {
      root?: string;
      selectedRow?: string;
      row?: string;
    };
  };
};

const Table = ({
  identity,
  selectable = true,
  columns,
  fetchObjects,
  renderEmptyState,
  renderLoadingState,
  RowComponent,
  header = true,
  renderFooter = () => <Footer />,
  dataTestId,
}: TableProps) => {
  const getPersistedRequestState = () => {
    const localStorageItem = localStorage.getItem(identity);
    if (!localStorageItem) {
      return {};
    }

    return JSON.parse(localStorageItem); // Holds order and filters
  };

  const dispatch = useDispatch();
  const contentLoading = useSelector((state) => state.table.contentLoading);
  const selectedState = useSelector((state) => state.table.selected);
  const requestState = useSelector((state) => state.table.request); // Holds offset and size
  const identityState = useSelector((state) => state.table.identity[identity]);

  const handleOrderUpdate = (newOrder: Request.OrderParams) => {
    const request = { ...requestState, ...getPersistedRequestState(), ...newOrder, offset: 0 };

    dispatch(
      listObjects({
        identity,
        fetch: fetchObjects,
        request,
        cause: 'ORDER',
      }),
    );
  };

  // #region Rows click handles
  const handleRowClick = (e: MouseEvent, objectId: ObjectId) => {
    if (!selectable) {
      return;
    }

    if (e.ctrlKey) {
      dispatch(
        toggleSelection({
          identity,
          objectId,
        }),
      );
    } else if (e.shiftKey) {
      dispatch(groupSelection({ identity, objectId }));
    } else {
      dispatch(singleSelection({ identity, objectId }));
    }
  };

  const handleCheckBoxClick = (_: never, objectId: ObjectId) => {
    if (!selectable) {
      return;
    }
    dispatch(toggleSelection({ identity, objectId }));
  };

  const handleRowDoubleClick = () => {};

  const clickHandles = { handleRowClick, handleRowDoubleClick, handleCheckBoxClick };
  // #endregion

  const renderRow = (objectId: ObjectId) => {
    return (
      <IntlErrorBoundary size="small" margin="0 0 0 1.5rem" height="inherit">
        <RowComponent
          objectId={objectId}
          selectable={selectable}
          selected={selectedState[objectId]}
          clickHandles={clickHandles}
          dataTestId={dataTestId}
        />
      </IntlErrorBoundary>
    );
  };

  return (
    <div className={styles.root} data-testid={dataTestId?.root}>
      {header && (
        <Header
          selectable={selectable}
          columns={columns}
          currentOrder={{
            order_field: getPersistedRequestState().order_field,
            order_type: getPersistedRequestState().order_type,
          }}
          onOrderChanged={handleOrderUpdate}
          dataTestId={dataTestId?.header}
        />
      )}

      {contentLoading && renderLoadingState && renderLoadingState(contentLoading)}

      {(identityState?.list === undefined || identityState.list.length <= 0) &&
        !contentLoading &&
        renderEmptyState &&
        renderEmptyState()}

      {!contentLoading && (
        <div className={styles.content}>
          <List
            infiniteLoader
            itemSize={48}
            // @ts-expect-error DS List has invalid type
            loadMoreItems={
              !identityState?.hasNextPage || identityState.isNextPageLoading
                ? () => {}
                : () => {
                    const request = {
                      ...requestState,
                      ...getPersistedRequestState(),
                    };

                    dispatch(
                      lazyLoad({
                        identity,
                        fetch: fetchObjects,
                        request,
                      }),
                    );
                  }
            }
            data={identityState?.list?.map((objectId) => renderRow(objectId))}
          />
        </div>
      )}

      {renderFooter()}
    </div>
  );
};

export default Table;
