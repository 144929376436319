import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { ViewModelChildren } from '../../utils';

type PresenterPath = (string | number)[];

export class BaseViewModel<V extends Editor.Visualizer.BaseView = Editor.Visualizer.BaseView> {
  typeName = 'BaseViewModel';

  Data: Editor.Data.API;
  Visualizer: Editor.Visualizer.State;

  id: string;
  children: ViewModelChildren<BaseViewModel>;
  path: PresenterPath;
  parent: BaseViewModel | null;
  view?: V;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State, id?: string) {
    this.Data = Data;
    this.Visualizer = Visualizer;

    this.id = id || EditorDOMUtils.generateRandomNodeId();
    this.children = new ViewModelChildren<BaseViewModel>();
    this.path = [];
    this.parent = null;
  }

  get viewHeight() {
    return this.getRootView()?.clientHeight || 0;
  }

  get isDisposable() {
    return false;
  }

  getRootView(): V | undefined {
    return this.view;
  }

  getChild(index: number) {
    return this.children.getAtIndex(index);
  }

  hasChild(presenter: BaseViewModel) {
    return this.children.includes(presenter);
  }

  indexOfChild(presenter: BaseViewModel) {
    return this.children.indexOf(presenter);
  }

  removeChild(presenter: BaseViewModel) {
    const index = this.indexOfChild(presenter);
    if (index >= 0) {
      this.children.splice(index, 1);
    }
  }

  removeAllChildren() {
    for (let index = 0; index < this.children.length; index++) {
      this.children.getAtIndex(index).dispose();
    }
    const view = this.getRootView();
    while (view?.firstChild) {
      view?.firstChild.removeChild(view?.firstChild as HTMLElement);
    }
  }

  childChangedHeight(
    child: BaseViewModel,
    view: Editor.Visualizer.BaseView | null,
    difference: number,
    scrollDiff: number,
  ) {}

  dispose(shouldRemove: boolean = true, shouldUpdateScroll: boolean = true) {
    this.removeAllChildren();
    this.getRootView()?.remove();
    if (this.parent && shouldRemove) {
      this.parent.removeChild(this);
    }
  }
}
