import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSelection } from './EditorStatusSlice';

type WordCountTarget = 'document' | 'selection';

type WordCountSliceState = {
  wordsQuantity: { [key in WordCountTarget]: number | null };
};

const SLICE_NAME = 'WORD_COUNT';
const initialState: WordCountSliceState = {
  wordsQuantity: {
    document: null,
    selection: null,
  },
};

// #region Slice
const wordCountSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setWordsQuantity: (
      state,
      action: PayloadAction<{
        target: WordCountTarget;
        value: Editor.Data.Proofing.Summary;
      }>,
    ) => {
      const { target, value } = action.payload;
      state.wordsQuantity[target] = value.WORDS;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSelection, (state, action) => {
      if (action.payload.COLLAPSED) {
        state.wordsQuantity.selection = null;
      }
    });
  },
});

export default wordCountSlice.reducer;
// #endregion

// #region Actions
export const { setWordsQuantity } = wordCountSlice.actions;
// #endregion
