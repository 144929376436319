import { useIntl } from 'react-intl';
import { Setting } from 'Settings/components';
import styles from './PersonalSpaceSettings.module.scss';

const PersonalSpaceSettings = () => {
  const intl = useIntl();
  return (
    <div className={styles.root}>
      <Setting
        label={intl.formatMessage({ id: 'global.name' })}
        value={intl.formatMessage({ id: 'spaces.personalSpace' })}
        testId="space-name"
      />
    </div>
  );
};

export default PersonalSpaceSettings;
