import { FormattedMessage } from 'react-intl';
import { Dropdown, Divider, Toggle, usePopper } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';
import { openModal } from '_common/modals/ModalsSlice';
import { setSidebarView } from 'Editor/redux/SidebarSlice';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';

const Help = () => {
  const dispatch = useDispatch();
  const [sendOnboardingStatus] = useOnboardingStatusMutation();

  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-start',
  });

  const handleHelpCenterClicked = () => {
    window.open('https://dodoc.zendesk.com/hc/en-us');
  };

  const handleTalkWithUsClicked = () => {
    dispatch(openModal('SupportFormModal'));
  };

  const handleAboutClicked = () => {
    dispatch(openModal('AboutModal'));
  };

  const handleKeyboardShortcutsClicked = () => {
    dispatch(openModal('KeyboardShortcutsModal'));
  };

  const handleResetOnboarding = () => {
    sendOnboardingStatus({ target: 'editor', step: 'beginning' });
    dispatch(setSidebarView(null));
  };

  const handleQuickStartGuideClicked = () => {
    window.open('https://dodoc.zendesk.com/hc/en-us/articles/360010587919-doDOC-Quick-Start-Guide');
  };

  return (
    <>
      <Toggle
        size="medium"
        margin="0 auto 0 0"
        variant="ghost"
        {...referenceProps}
        isToggled={isOpen}
        testId="topbar-dropdown-support"
      >
        <FormattedMessage id="editor.menu.help.label" />
      </Toggle>
      <Dropdown {...popperProps} testId="topbar-menu-help">
        <Dropdown.Item
          onClick={handleHelpCenterClicked}
          testId="topbar-dropdown-support-help-center"
        >
          <FormattedMessage id="editor.menu.help.helpCenter" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={handleTalkWithUsClicked}
          testId="topbar-dropdown-support-talk-with-us"
        >
          <FormattedMessage id="editor.menu.help.talkWithUs" />
        </Dropdown.Item>
        <Dropdown.Item onClick={handleAboutClicked} testId="topbar-dropdown-about-dodoc">
          <FormattedMessage id="ABOUT_DODOC" />
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item
          onClick={handleKeyboardShortcutsClicked}
          testId="topbar-dropdown-support-keyboard-shortcuts"
        >
          <FormattedMessage id="KEYBOARD_SHORTCUTS" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={handleResetOnboarding}
          testId="topbar-dropdown-support-reset-onboarding"
        >
          <FormattedMessage id="RESET_ONBOARDING" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={handleQuickStartGuideClicked}
          testId="topbar-dropdown-support-quick-start-guide"
        >
          <FormattedMessage id="QUICK_START_GUIDE" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default Help;
