import { JsonRange } from 'Editor/services/_Common/Selection';
import { Command } from '../Command';
import { Logger } from '_common/services';

export class ArrowUpCommand extends Command {
  private event: KeyboardEvent;

  constructor(context: Editor.Edition.ICommandArgs, event: KeyboardEvent) {
    super(context);
    this.event = event;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (this.debug) {
      Logger.trace('ArrowRightCommand exec', this);
    }

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.selection?.modifiers
    ) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    // TODO fix page selection if needed

    let shiftKey = this.event.shiftKey;
    let ctrlKey = this.event.ctrlKey;

    if (!shiftKey && !ctrlKey) {
      this.context.selection.modifiers.modify(jsonRange, 'move', 'line', 'backward');
    } else if (shiftKey) {
      this.context.selection.modifiers.modify(jsonRange, 'expand', 'line', 'backward');
    }

    // apply new selection
    if (this.context.DataManager?.selection) {
      // TEMP: flag last selection
      this.context.DataManager.selection.history.flag('debounce');
      this.context.DataManager.selection.setUserSelection([jsonRange.serializeToRangeData()]);
    }

    return this;
  }
}
