import ReactDOM from 'react-dom';
import { IntlProvider } from 'Intl/Intl';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './PlaceholderElement.module.scss';
import EditorManager from 'Editor/services/EditorManager';
import { Icon, Tooltip } from 'dodoc-design-system';
import { EditorService, Logger } from '_common/services';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ReduxInterface } from 'Editor/services';
import { ELEMENTS, DISPLAY_TYPES } from 'Editor/services/consts';
import { BaseBlockElement } from '../..';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import { EditorDOMElements } from 'Editor/services/_Common/DOM';

type PlaceholderBreakContentArgs = {
  icon: IconTypes[32];
  translation: string;
};

const PlaceholderContent = ({ icon, translation }: PlaceholderBreakContentArgs) => {
  const intl = useIntl();

  return (
    <Tooltip
      content={intl.formatMessage({ id: 'CONTENT_CANNOT_BE_EDITED' })}
      testId="placeholder-cannot-be-edited-tooltip"
    >
      <div className={styles.contentWrapper}>
        <div className={styles.iconWrapper}>
          <Icon icon={icon} size={32} />
        </div>
        <div className={styles.info}>
          <FormattedMessage id={translation} />
        </div>
      </div>
    </Tooltip>
  );
};

export class PlaceholderElement extends BaseBlockElement {
  private container?: HTMLElement;
  private pending: ((value?: unknown) => void)[] = [];
  private loaded: boolean;
  private imageBase64?: string;
  private image?: HTMLElement;
  private imageWrap: Editor.Styles.ImageWrapProperty = 'inline';
  private timeoutImageWrapping?: NodeJS.Timeout;

  constructor(Visualizer?: Editor.Visualizer.State, Data?: Editor.Data.API) {
    super(Visualizer, Data);
    this.validateOnlyChildState = this.validateOnlyChildState.bind(this);
    this.removeOnlyChildState = this.removeOnlyChildState.bind(this);
    this.fetchImage = this.fetchImage.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);
    this.loaded = false;
    this.pending = [];
  }

  connectedCallback() {
    if (!this.preRendered) {
      this.preRender();
    }

    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  preRender(pageWidth?: number, isAtNodeStart: boolean = false): void {
    this.buildElement(pageWidth, isAtNodeStart);

    super.preRender();
  }

  get isPlaceholder() {
    return true;
  }

  get isSelectable() {
    return false;
  }

  get displayType() {
    // return INLINE / BLOCK
    return DISPLAY_TYPES.INLINE;
  }

  get isEditable() {
    return false;
  }

  get isDeletable() {
    return true;
  }

  get isSplitable() {
    return false;
  }

  get isContentWrapper() {
    return false;
  }

  get width(): number {
    return this.dataset.w ? +this.dataset.w : 0;
  }

  get height(): number {
    return this.dataset.h ? +this.dataset.h : 0;
  }

  get float(): Editor.Data.Node.PlaceholderProperties['f'] {
    return this.dataset.f === 'true';
  }

  get behindDoc(): Editor.Data.Node.PlaceholderProperties['bd'] {
    return this.dataset.bd === 'true';
  }

  get alingX(): Editor.Data.Node.PlaceholderProperties['ax'] | null {
    return this.dataset.ax != null
      ? (this.dataset.ax as Editor.Data.Node.PlaceholderProperties['ax'])
      : null;
  }

  get alingY(): Editor.Data.Node.PlaceholderProperties['ay'] | null {
    return this.dataset.ay != null
      ? (this.dataset.ay as Editor.Data.Node.PlaceholderProperties['ay'])
      : null;
  }

  get offsetX(): Editor.Data.Node.PlaceholderProperties['ox'] | null {
    if (this.dataset.ox != null) {
      return +this.dataset.ox;
    }
    return null;
  }

  get offsetY(): Editor.Data.Node.PlaceholderProperties['oy'] | null {
    if (this.dataset.oy != null) {
      return +this.dataset.oy;
    }
    return null;
  }

  get relativeX(): Editor.Data.Node.PlaceholderProperties['rfx'] | null {
    if (this.dataset.rfx != null) {
      return this.dataset.rfx as Editor.Data.Node.PlaceholderProperties['rfx'];
    }
    return null;
  }

  get relativeY(): Editor.Data.Node.PlaceholderProperties['rfy'] | null {
    if (this.dataset.rfy != null) {
      return this.dataset.rfy as Editor.Data.Node.PlaceholderProperties['rfy'];
    }
    return null;
  }

  get sourceId(): Editor.Data.Node.PlaceholderProperties['es'] | null {
    if (this.dataset.es != null) {
      return this.dataset.es;
    }
    return null;
  }

  get wrapping(): Editor.Data.Node.PlaceholderProperties['wr'] {
    return this.dataset.wr != null ? JSON.parse(this.dataset.wr) : {};
  }

  private getProperIcon(): IconTypes[32] {
    switch (this.dataset.type) {
      case 'fc':
      case 'so':
      case 'dw':
      case 'dg':
        return 'PlaceholderGraphicElement';
      default:
        return 'PlaceholderGraphicElement';
    }
  }

  private getProperTranslation() {
    switch (this.dataset.type) {
      case 'uk':
        return 'UNKNOWN';
      case 'dw':
        return 'DRAWING';
      case 'so':
        return 'SMART_OBJECT';
      case 'fc':
        return 'FLOWCHART';
      default:
        return 'FLOWCHART';
    }
  }

  removeOnlyChildState() {
    this.removeAttribute('onlychild');
  }

  validateOnlyChildState() {
    if (
      this.isInline &&
      EditorDOMElements.BLOCK_TEXT_ELEMENTS.includes((this.parentNode as HTMLElement)?.tagName) &&
      this.parentNode?.childNodes.length === 1
    ) {
      this.setAttribute('onlychild', 'true');
    } else {
      this.removeAttribute('onlychild');
    }
  }

  shceduleHandleImageWrapping() {
    if (this.timeoutImageWrapping) {
      clearTimeout(this.timeoutImageWrapping);
      delete this.timeoutImageWrapping;
    }

    this.timeoutImageWrapping = setTimeout(() => this.handleImageWrapping(), 0);
  }

  buildElement(pageWidth?: number, isAtNodeStart: boolean = false) {
    if (this.sourceId) {
      // smartobject
      this.fetchImage();
      this.handleImageSize();

      this.handleImageWrapping(pageWidth, isAtNodeStart);
    } else {
      // placeholder
      this.innerHTML = '';
      this.setAttribute('spellcheck', 'false');

      this.container = DOMElementFactory.buildElement('div');
      this.container.setAttribute('class', styles.container);

      // this.appendChild(document.createTextNode('\u00A0'));
      this.appendChild(document.createTextNode('\u202F'));
      this.appendChild(this.container);
      this.appendChild(document.createTextNode('\u202F'));

      const locale = ReduxInterface.getLocale();

      ReactDOM.unmountComponentAtNode(this.container);
      ReactDOM.render(
        <IntlProvider locale={locale}>
          <PlaceholderContent
            icon={this.getProperIcon()}
            translation={this.getProperTranslation()}
          ></PlaceholderContent>
        </IntlProvider>,
        this.container,
      );
      setTimeout(this.validateOnlyChildState, 0);
    }
  }

  setImageSource(sourceId: string) {
    this.dataset.es = sourceId;
    this.fetchImage();
  }

  private buildImage() {
    const image = DOMElementFactory.buildElement('img');
    image.setAttribute('parent_id', this.id);
    return image;
  }

  fetchImage() {
    this.loaded = false;
    const docId = this.Data?.document.getDocumentId();

    if (docId && this.sourceId) {
      new EditorService()
        .getImage(docId, this.sourceId, { responseType: 'blob' })
        .then((response) => {
          this.imageLoaded(response.data);
        })
        .catch((error) => {
          Logger.captureException(error);
        });
    }
  }

  triggerImageLoaded() {
    this.loaded = true;
    while (this.pending?.length) {
      this.pending.pop()?.();
    }
  }

  private imageLoaded(blob: Blob) {
    this.triggerImageLoaded();
    if (this.hasAttribute('localfile')) {
      this.removeAttribute('localfile');
      while (this.firstChild) {
        this.firstChild.remove();
      }
      this.image = undefined;
    }

    const url = URL.createObjectURL(blob);
    if (!this.image) {
      this.image = this.buildImage();
      this.image.setAttribute('src', url);
      this.appendChild(this.image);
    } else {
      this.image.removeAttribute('placeholder');
      this.image.setAttribute('src', url);
      this.image.setAttribute('parent_id', this.id);
    }

    this.image.onload = () => {
      EditorManager.getInstance().updatePasteOptions();
    };

    // get base64 data for eventual copy
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result != null) {
        this.imageBase64 = reader.result as string;
      }
    };
    reader.readAsDataURL(blob);
  }

  handleImageSize() {
    if (this.dataset.w != null) {
      this.style.width = `${EditorDOMUtils.convertUnitTo(this.dataset.w, 'pt', 'px', 0)}px`;
    }

    if (this.dataset.h != null) {
      this.style.height = `${EditorDOMUtils.convertUnitTo(this.dataset.h, 'pt', 'px', 0)}px`;
    }
  }

  handleImageWrapping(pageWidth?: number, isAtNodeStart: boolean = false) {
    // remove existing styles;
    this.style.position = '';
    this.style.top = '';
    this.style.left = '';
    this.style.zIndex = '';
    this.style.float = '';
    this.style.marginLeft = '';
    this.style.marginBottom = '';
    this.style.marginRight = '';
    this.style.marginTop = '';
    this.style.display = '';

    if (this.float) {
      if (!pageWidth) {
        let level0 = EditorDOMUtils.findFirstLevelChildNode(
          EditorDOMUtils.getContentContainer(this),
          this,
        ) as HTMLElement;
        if (level0?.id) {
          pageWidth = this.Data?.sections.getPageWidthForBlockId(level0.id);
        }
      }

      let behindDoc = this.behindDoc;
      let wrapping = this.wrapping;

      switch (wrapping?.t) {
        case 'n': {
          // TODO validate relative offsets

          // position absolute
          this.style.position = 'absolute';
          if (this.offsetY != null && (this.relativeY === 'p' || this.relativeY === 'c')) {
            this.style.top = `${EditorDOMUtils.convertUnitTo(this.offsetY, 'pt', 'px', 0)}px`;
          }
          if (this.relativeX === 'p' || this.relativeX === 'c' || this.relativeX === 'm') {
            if (this.alingX != null) {
              let left = 0;
              switch (this.alingX) {
                case 'r':
                  if (pageWidth != null) {
                    left = pageWidth - this.width;
                  }
                  break;
                case 'c':
                  if (pageWidth != null) {
                    left = pageWidth / 2 - this.width / 2;
                  }
                  break;
                case 'l':
                default:
                  left = 0;
                  break;
              }
              this.style.left = `${EditorDOMUtils.convertUnitTo(left, 'pt', 'px', 0)}px`;
            } else if (this.offsetX != null) {
              this.style.left = `${EditorDOMUtils.convertUnitTo(this.offsetX, 'pt', 'px', 0)}px`;
            }
          }
          if (behindDoc) {
            // behind text
            this.imageWrap = 'behindText';
            this.style.zIndex = `${-1}`;
          } else {
            // in front of text
            this.imageWrap = 'inFrontText';
            this.style.zIndex = `${1}`;
          }
          break;
        }
        case 't':
        case 'ti':
        case 's': {
          // wrap text / float
          if (
            (this.offsetX != null && pageWidth != null && this.offsetX > pageWidth / 2) ||
            this.alingX === 'r'
          ) {
            // float right
            this.imageWrap = 'right';
            this.style.float = 'right';

            if (wrapping.ld) {
              this.style.marginLeft = `${EditorDOMUtils.convertUnitTo(
                wrapping.ld,
                'pt',
                'px',
                0,
              )}px`;
            }

            if (wrapping.bd) {
              this.style.marginBottom = `${EditorDOMUtils.convertUnitTo(
                wrapping.bd,
                'pt',
                'px',
                0,
              )}px`;
            }

            if (
              this.offsetX &&
              pageWidth != null &&
              (this.relativeX === 'p' || this.relativeX === 'c')
            ) {
              const marginRight = pageWidth - this.width - this.offsetX;
              this.style.marginRight = `${EditorDOMUtils.convertUnitTo(
                marginRight,
                'pt',
                'px',
                0,
              )}px`;
            }
          } else {
            // float left
            this.imageWrap = 'left';
            this.style.float = 'left';

            if (wrapping.rd) {
              this.style.marginRight = `${EditorDOMUtils.convertUnitTo(
                wrapping.rd,
                'pt',
                'px',
                0,
              )}px`;
            }

            if (wrapping.bd) {
              this.style.marginBottom = `${EditorDOMUtils.convertUnitTo(
                wrapping.bd,
                'pt',
                'px',
                0,
              )}px`;
            }

            if (this.offsetX && (this.relativeX === 'p' || this.relativeX === 'c')) {
              this.style.marginLeft = `${EditorDOMUtils.convertUnitTo(
                this.offsetX,
                'pt',
                'px',
                0,
              )}px`;
            }
          }

          if (wrapping.td && !isAtNodeStart) {
            this.style.marginTop = `${EditorDOMUtils.convertUnitTo(wrapping.td, 'pt', 'px', 0)}px`;
          }
          break;
        }
        case 'tb': {
          // top and bottom
          this.imageWrap = 'topAndBottom';
          this.style.display = 'block';
          if (wrapping.td) {
            this.style.marginTop = `${EditorDOMUtils.convertUnitTo(wrapping.td, 'pt', 'px', 0)}px`;
          }

          if (wrapping.bd) {
            this.style.marginBottom = `${EditorDOMUtils.convertUnitTo(
              wrapping.bd,
              'pt',
              'px',
              0,
            )}px`;
          }

          if (this.alingX != null) {
            switch (this.alingX) {
              case 'r':
                this.style.marginLeft = 'auto';
                break;
              case 'l':
                this.style.marginLeft = '0px';
                break;
              case 'c':
              default:
                this.style.marginLeft = 'auto';
                this.style.marginRight = 'auto';
                break;
            }
          } else {
            this.style.marginLeft = 'auto';
            this.style.marginRight = 'auto';
          }

          break;
        }
      }
    } else {
      this.imageWrap = 'inline';
    }
  }
}

if (!window.customElements.get(ELEMENTS.PlaceholderElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.PlaceholderElement.IDENTIFIER, PlaceholderElement);
}
