import NumberingUtils from 'Editor/services/_Common/NumberingUtils';
import { ListLevelDefinition, NodeNumberingData } from '../../models';
import ListStylesManager from '../Styles/ListStylesManager';
import ListsManager from './ListsManager';

export default class NumberingApplier {
  private listStyles?: ListStylesManager;
  private lists?: ListsManager;

  constructor(listStyles?: ListStylesManager, lists?: ListsManager) {
    this.listStyles = listStyles;
    this.lists = lists;
  }

  hasDefinitionForLevel(listId: string, level: string) {
    const list = this.lists?.list(listId);
    if (
      list &&
      level &&
      list.styleOverrides[level] &&
      list.style &&
      this.listStyles?.style(list.style)?.level(level)
    ) {
      return true;
    }
    return false;
  }

  levelDefinition(listId: string, level: string) {
    const list = this.lists?.list(listId);
    if (!list || !list.style) {
      return;
    }
    let listStyle = this.listStyles?.style(list.style);
    if (!listStyle?.level(level)) {
      return;
    }
    let levelDef: ListLevelDefinition = JSON.parse(JSON.stringify(listStyle?.level(level)));
    if (this.hasDefinitionForLevel(listId, level)) {
      const styleOverrides = list.styleOverrides[level];
      for (let index = 0; index < styleOverrides.length; index++) {
        const override = styleOverrides[index];
        if (override.sto != null) {
          levelDef.st = override.sto;
        } else {
          levelDef = override;
        }
      }
      return levelDef;
    }
    return levelDef;
  }

  applyStyle(nodeNumbering: NodeNumberingData | null = null) {
    let previous = '';
    if (!nodeNumbering || nodeNumbering.listId === null) {
      return previous;
    }
    const itemLevel = this.levelDefinition(nodeNumbering.listId, `${nodeNumbering.level}`);
    if (!itemLevel) {
      return previous;
    }
    if (itemLevel?.nf === NumberingUtils.TYPE.BULLET) {
      const bullet = itemLevel.t ? String.fromCharCode(+itemLevel.t) : '';
      previous += `${itemLevel.tB || ''}${bullet}${itemLevel.tA || ''}`;
    } else {
      const repLevels = itemLevel.rl;
      for (let index = 0; index <= nodeNumbering.level; index++) {
        //@ts-expect-error
        if (repLevels && (repLevels.includes(`${index}`) || repLevels.includes(index))) {
          let previousLevel;
          if (index) {
            previousLevel = this.levelDefinition(nodeNumbering.listId, `${index - 1}`);
          }
          const thisLevel = this.levelDefinition(nodeNumbering.listId, `${index}`);
          let charBefore = '';
          if (thisLevel?.tB && thisLevel.tB.length) {
            charBefore = thisLevel.tB;
          } else if (previousLevel && (!previousLevel.tA || !previousLevel.tA.length)) {
            charBefore = '.';
          } else {
            charBefore = '';
          }
          const charAfter = thisLevel?.tA || '';
          previous += `${charBefore}${NumberingUtils.represent(
            thisLevel?.nf,
            nodeNumbering.allLevels[index] || 1,
          )}${charAfter}`;
        }
      }
    }
    return previous;
  }

  /* styleLevelPreview(level) {
    let previous = '';
    const info = {
      level,
    };
    if (!info || info.level === undefined) {
      return previous;
    }
    const itemLevel = this.properLevelDefinition(info.level);
    if (itemLevel.nf === NumberingUtils.TYPE.BULLET) {
      const bullet = itemLevel.t ? String.fromCharCode(itemLevel.t) : '';
      previous += `${itemLevel.tB || ''}${bullet}${itemLevel.tA || ''}`;
    } else {
      const repLevels = itemLevel.rl;
      for (let index = 0; index <= info.level; index++) {
        if (repLevels && (repLevels.includes(`${index}`) || repLevels.includes(index))) {
          const previousLevel = index ? this.properLevelDefinition(index - 1) : null;
          const thisLevel = this.properLevelDefinition(index);
          let Rindex;
          if (info.allLevels?.[index]) {
            Rindex = parseInt(thisLevel.st, 10) + parseInt(info.allLevels[index], 10);
          } else {
            Rindex = parseInt(thisLevel.st, 10) + 0;
          }
          let charBefore = '';
          if (thisLevel.tB && thisLevel.tB.length) {
            charBefore = thisLevel.tB;
          } else if (previousLevel && (!previousLevel.tA || !previousLevel.tA.length)) {
            charBefore = '.';
          } else {
            charBefore = '';
          }
          const charAfter = thisLevel.tA || '';
          previous += `${charBefore}${NumberingUtils.represent(thisLevel.nf, Rindex)}${charAfter}`;
        }
      }
    }
    return previous;
  } */
}
