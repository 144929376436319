import { Descendant, Node } from 'slate';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';

import { dayjs } from 'utils';
import { publicProfilesApi } from '_common/services/api/publicProfilesApi';

export type TextBoxFont =
  | 'Arial'
  | 'Arial Black'
  | 'Calibri'
  | 'Comic Sans MS'
  | 'Courier New'
  | 'Georgia'
  | 'Impact'
  | 'Palatino'
  | 'Tahoma'
  | 'Times New Roman'
  | 'Verdana';

export type TextBoxColor = '#000000' | '#FF0000' | '#008000' | '#0000FF';

export type TextBoxFontSize =
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '14'
  | '18'
  | '24'
  | '30'
  | '36'
  | '48'
  | '60'
  | '72'
  | '96';

export type TextBoxAlign = 'left' | 'center' | 'right' | 'justify';

export type CreationDate =
  | 'last_week'
  | 'last_month'
  | '3_month_ago'
  | '6_month_ago'
  | 'specific_date_range';

export type FilterValuePayload<
  F extends keyof PDFAnnotationsSliceState['filters'],
  V extends PDFAnnotationsSliceState['filters'][F],
> = { filter: F; value: V };

export type TaskFilterValuePayload<
  F extends keyof PDFAnnotationsSliceState['tasksFilters'],
  V extends PDFAnnotationsSliceState['tasksFilters'][F],
> = { filter: F; value: V };

export type TextboxStylePayload<P extends keyof TextBoxStyle, V extends TextBoxStyle[P]> = {
  property: P;
  value: V;
};

type TextBoxStyle = {
  fontfamily: TextBoxFont;
  fontsize: TextBoxFontSize;
  align: TextBoxAlign;
  color: TextBoxColor;
  bold: boolean;
  italic: boolean;
  underline: boolean;
};

type PDFAnnotationsSliceState = {
  creating?: PDF.TaskCreationData;
  editing: PDF.Annotation['id'] | null;
  selected: PDF.Annotation['id'] | null;
  ids: PDF.Annotation['id'][];
  byId: Record<PDF.Annotation['id'], PDF.Annotation>;
  byPage: Record<PDF.Annotation['pageNumber'], PDF.Annotation['id'][]>;
  creation: {
    roiColor: 'yellow' | 'red' | 'green' | 'blue';
    roiType: 'Highlight' | 'Underline' | 'StrikeOut';
    stroke: 'none' | 'black' | 'yellow' | 'red' | 'green' | 'blue';
    fill: 'none' | 'black' | 'yellow' | 'red' | 'green' | 'blue';
    strokeWidth: number;
    shape: PDF.Annotation.ShapeType;
    start: PDF.Annotation.Point | null;
    end: PDF.Annotation.Point | null;
    pageNum: number | null;
  };
  filters: {
    type: ('comment' | 'freehand' | 'highlights_notes' | 'shapes' | 'textbox')[];
    status: ('Cancelled' | 'Completed')[];
    priority: PDF.Annotation.Priority[];
    users: { label: string; value: string }[];
    creationDate: CreationDate | null;
    specificDateValues: { startDate: ISODate | null; endDate: ISODate | null };
  };
  tasksFilters: {
    status: PDF.Annotation.Task['status'][];
    deleted: boolean;
    assignedUser: UserId | null;
  };
  tasksListMode: boolean;
  textbox: {
    //Properties can be changed one by one and affects creation styles
    preset: TextBoxStyle;
    //Responsible to display selected TextBox styles
    selected: TextBoxStyle;
  };
  searchBy?: string;
  tasksSearchBy?: string;
  beingTransformed: PDF.Annotation['id'] | null;
  loaded: boolean;
  initiatedWithTasks: boolean;
  clustered: Record<number, Record<string, string> | null> | null;
};

export const DEFAULT_TEXTBOX_STYLES: TextBoxStyle = {
  fontfamily: 'Arial',
  color: '#000000',
  fontsize: '14',
  bold: false,
  italic: false,
  underline: false,
  align: 'left',
};

export const DEFAULT_CARD_STYLES_PANEL: Partial<TextBoxStyle> = {
  align: 'left',
  color: '#000000',
  fontfamily: 'Arial',
  fontsize: '12',
};

const SLICE_NAME = 'PDF';
const initialState: PDFAnnotationsSliceState = {
  editing: null,
  selected: null,
  ids: [],
  byId: {},
  byPage: {},
  creation: {
    roiColor: 'yellow',
    roiType: 'Highlight',
    stroke: 'black',
    fill: 'none',
    strokeWidth: 1,
    shape: 'Line',
    start: null,
    end: null,
    pageNum: null,
  },
  filters: {
    type: [],
    status: [],
    priority: [],
    users: [],
    creationDate: null,
    specificDateValues: {
      startDate: null,
      endDate: null,
    },
  },
  tasksFilters: {
    status: [],
    deleted: false,
    assignedUser: null,
  },
  tasksListMode: false,
  textbox: {
    preset: DEFAULT_TEXTBOX_STYLES,
    selected: DEFAULT_TEXTBOX_STYLES,
  },
  beingTransformed: null,
  loaded: false,
  initiatedWithTasks: false,
  clustered: null,
};

// #region Selectors
const getNodeMentionContent = (node: Descendant, state: RootState) => {
  //@ts-expect-error Descendant type
  if (node?.type === 'mention') {
    //@ts-expect-error Descendant type
    const { data } = publicProfilesApi.endpoints.getPublicProfile.select(node.userId)(state);
    return data?.name ?? '';
  }
  return null;
};

const getNodeContent = (node: Descendant, state: RootState) => {
  if (node) {
    let content = Node.string(node) ?? '';

    //@ts-expect-error Descendant type
    node.children.forEach((child) => {
      if (child.type === 'mention') {
        content += ` @${getNodeMentionContent(child, state)}`;
      }
    });

    return content;
  }
  return null;
};

const getAnnotations = (state: RootState) => state.pdf.annotations.byId;
const getAnnotationsFilters = (state: RootState) => state.filters.reviewerCommentPanel;
const getAnnotationsList = (state: RootState) => state.pdf.annotations.ids;
const getAnnotationsSearchBy = (state: RootState) => state.pdf.annotations.searchBy;
const getTasksSearchBy = (state: RootState) => state.pdf.annotations.tasksSearchBy;
const getState = (state: RootState) => state;
const isTask = (annotation: PDF.Annotation): annotation is PDF.Annotation.Task => {
  return annotation.subtype === 'Task';
};
const getAnnotationType = (
  group: PDFAnnotationsSliceState['filters']['type'][number],
): PDF.Annotation.Type[] => {
  switch (group) {
    case 'comment': {
      return ['Note'];
    }
    case 'freehand': {
      return ['Ink'];
    }
    case 'highlights_notes': {
      return ['Highlight', 'Underline', 'StrikeOut'];
    }
    case 'shapes': {
      return ['Line', 'Square', 'Circle', 'Polygon', 'PolyLine'];
    }
    case 'textbox': {
      return ['FreeText'];
    }
    default: {
      return [];
    }
  }
};

export const annotationBelongsToGroupType = (
  group: PDFAnnotationsSliceState['filters']['type'][number],
  annotation: PDF.Annotation,
) => {
  if (group && annotation) {
    return getAnnotationType(group).includes(annotation.subtype);
  }
};

export const selectPDFTasks = createSelector([getAnnotations], (annotations) =>
  Object.values(annotations).filter(isTask),
);

export const selectPDFTaskById = createSelector(
  [getAnnotations, (_: any, taskId: PDF.Annotation['id']) => taskId],
  (annotations, taskId) => {
    const annotation = annotations[taskId];
    if (annotation && isTask(annotation)) {
      return annotation;
    }

    return null;
  },
);

export const selectSearchByTasks = createSelector(
  [selectPDFTasks, getTasksSearchBy, getState],
  (tasks, searchBy, state) => {
    const filterAnnotation = (task: PDF.Annotation.Task, value: string) => {
      if (task?.content?.content) {
        const content = task.content.content
          .map((node) => {
            return getNodeContent(node, state);
          })
          .join(' ');
        return content.toLowerCase().includes(value.toLowerCase());
      }

      return false;
    };

    if (searchBy) {
      return tasks.filter((task) => filterAnnotation(task, searchBy));
    }

    return tasks;
  },
);

export const selectFilteredPDFTasks = createSelector(
  [
    (state: RootState) => state.filters.reviewerTaskPanel,
    selectSearchByTasks,
    (_: any, pageNumber?: PDF.Annotation['pageNumber']) => pageNumber,
  ],
  (filters, list, pageNumber) => {
    const filterValues = {
      reviewerTaskStatus: filters.reviewerTaskStatus?.map((filterValue) => filterValue.value),
      assignedUser: filters.assignedUser,
    };
    const filterAnnotation = (task: PDF.Annotation.Task) => {
      if (
        !task ||
        (!filterValues.reviewerTaskStatus?.includes('dlt') && task.state === 'Cancelled')
      ) {
        return false;
      }

      //State
      if (
        filterValues.reviewerTaskStatus?.includes('dlt') &&
        task.state !== 'Cancelled' &&
        filterValues.reviewerTaskStatus &&
        filterValues.reviewerTaskStatus.length < 1
      ) {
        return false;
      }

      //Status
      if (
        filterValues.reviewerTaskStatus &&
        filterValues.reviewerTaskStatus.length > 0 &&
        !filterValues.reviewerTaskStatus.includes(task.status) &&
        task.state !== 'Cancelled'
      ) {
        return false;
      }

      //Assigned User
      if (filterValues.assignedUser != null && filterValues.assignedUser.value !== task.assignee) {
        return false;
      }

      //Parameter page number
      if (pageNumber) {
        return task.pageNumber === pageNumber;
      }

      return true;
    };

    return list.filter(filterAnnotation);
  },
);

export const selectTotalTasksFilters = createSelector(
  [(state: RootState) => state.filters.reviewerTaskPanel],
  (tasksFilters) => {
    const filtersNames = ['reviewerTaskStatus', 'assignedUser'];
    //@ts-expect-error
    const results = filtersNames.map((name) => tasksFilters[name]);
    let count = 0;
    for (let i = 0; i < results.length; i++) {
      if (results && results[i] && Array.isArray(results[i]) && results[i]?.length > 0) {
        count = count + results[i]?.length;
      }
      if (
        results &&
        results[i] != null &&
        !Array.isArray(results[i]) &&
        (typeof results[i] === 'string' || typeof results[i] === 'object')
      ) {
        count = count + 1;
      }

      if (results && results[i] === true) {
        count = count + 1;
      }
    }
    return count;
  },
);

export const selectAllPDFAnnotations = createSelector([getAnnotations], (annotations) =>
  Object.values(annotations).filter((annotation) => annotation.subtype !== 'Task'),
);

export const selectPDFAnnotations = createSelector([getAnnotations], (annotations) =>
  Object.values(annotations).filter(
    (annotation) =>
      annotation.subtype !== 'Task' &&
      annotation?.state !== 'Completed' &&
      annotation?.state !== 'Cancelled',
  ),
);

export const selectAnnotationsAuthors = createSelector(
  [getAnnotations, getAnnotationsList],
  (annotations, list) => {
    const users: string | any[] = [];
    const addUser = (annotation: PDF.Annotation) => {
      for (let i = 0; i < users.length; i++) {
        const u = users[i];
        if (u.id === annotation.authorId || u.id === annotation.title) {
          return;
        }
      }
      const user: { id: string; imported?: boolean } = { id: '' };
      if (annotation?.title) {
        //Imported user
        user.id = annotation.title;
        user.imported = true;
      } else if (annotation?.authorId) {
        user.id = annotation.authorId;
      } else {
        return;
      }
      users.push(user);
    };

    for (let i = 0; i < list.length; i++) {
      let annotation = annotations[list[i]];
      addUser(annotation);
    }
    return users;
  },
);

export const selectSearchByAnnotations = createSelector(
  [getAnnotations, getAnnotationsList, getAnnotationsSearchBy, getState],
  (annotations, list, searchBy, state) => {
    const filterAnnotation = (annotationId: PDF.Annotation['id'], value: string) => {
      const annotation = annotations[annotationId];

      if (annotation.content?.content) {
        const content = annotation.content.content
          .map((node) => {
            return getNodeContent(node, state);
          })
          .join(' ');
        return content.toLowerCase().includes(value.toLowerCase());
      }

      return false;
    };

    if (searchBy) {
      return list.filter((id) => filterAnnotation(id, searchBy));
    }

    return list;
  },
);

export const selectFilteredPDFAnnotations = createSelector(
  [
    getAnnotations,
    getAnnotationsFilters,
    selectSearchByAnnotations,
    (_: any, pageNumber?: PDF.Annotation['pageNumber']) => pageNumber,
  ],
  (annotations, filters, list, pageNumber) => {
    const filterValues = {
      commentStatus: filters.commentStatus?.map((filterValue) => filterValue.value),
      commentType: filters.commentType?.map((filterValue) => filterValue.value),
      cardPriority: filters.cardPriority?.map((filterValue) => filterValue.value),
      author: filters.author?.map((filterValue) => filterValue.value),
      reviewerCreationDate: filters.reviewerCreationDate?.map((filterValue) => filterValue),
    };
    const filterAnnotation = (id: string | number) => {
      const annotation = annotations[id];

      if (!annotation || isTask(annotation)) {
        return false;
      }

      //Status
      if (
        (!filterValues?.commentStatus &&
          (annotation.state === 'Completed' || annotation.state === 'Cancelled')) ||
        (filterValues.commentStatus &&
          filterValues.commentStatus.length > 0 &&
          //@ts-expect-error
          !filterValues.commentStatus.includes(annotation.state))
      ) {
        return false;
      }

      //Type
      if (
        filterValues.commentType &&
        filterValues.commentType.length > 0 &&
        annotations[id] &&
        filterValues.commentType.filter((type: any) =>
          annotationBelongsToGroupType(type, annotations[id]),
        ).length < 1
      ) {
        return false;
      }

      //Priority
      if (
        filterValues.cardPriority &&
        filterValues.cardPriority.length > 0 &&
        annotations[id] &&
        !filterValues.cardPriority.includes(annotations[id].priority)
      ) {
        return false;
      }

      //Users
      if (
        filterValues.author &&
        filterValues.author.length > 0 &&
        filterValues.author.every((user) => {
          return user !== annotations[id].authorId && user !== annotations[id].title;
        })
      ) {
        return false;
      }

      //Creation date
      if (
        filterValues.reviewerCreationDate &&
        !filterValues.reviewerCreationDate[0].value.includes('specific_date_range')
      ) {
        const creationDate = dayjs(annotations[id].creationDate);
        if (
          filterValues.reviewerCreationDate[0].value.includes('last_week') &&
          dayjs().diff(creationDate, 'week') === 0
        ) {
          return true;
        }
        if (
          filterValues.reviewerCreationDate[0].value.includes('last_month') &&
          dayjs().diff(creationDate, 'month') === 0
        ) {
          return true;
        }
        if (
          filterValues.reviewerCreationDate[0].value.includes('3_month_ago') &&
          dayjs().diff(creationDate, 'month') <= 2
        ) {
          return true;
        }
        if (
          filterValues.reviewerCreationDate[0].value.includes('6_month_ago') &&
          dayjs().diff(creationDate, 'month') <= 5
        ) {
          return true;
        }
        return false;
      }

      //Specific date range
      if (
        filterValues.reviewerCreationDate &&
        filterValues.reviewerCreationDate[0].value?.includes('specific_date_range') &&
        //@ts-expect-error
        filterValues.reviewerCreationDate[0]?.dateValue !== null
      ) {
        const creationDate = dayjs(annotations[id].creationDate);
        let valid = true;
        if (
          filterValues.reviewerCreationDate &&
          //@ts-expect-error
          filterValues.reviewerCreationDate[0]?.dateValue?.startDate
        ) {
          valid =
            valid &&
            creationDate.isSameOrAfter(
              //@ts-expect-error
              dayjs(filterValues.reviewerCreationDate[0]?.dateValue?.startDate),
              'day',
            );
        }
        if (
          filterValues.reviewerCreationDate &&
          //@ts-expect-error
          filterValues.reviewerCreationDate[0]?.dateValue?.endDate
        ) {
          valid =
            valid &&
            creationDate.isSameOrBefore(
              //@ts-expect-error
              dayjs(filterValues.reviewerCreationDate[0]?.dateValue?.endDate),
              'day',
            );
        }
        return valid;
      }

      //Parameter page number
      if (pageNumber) {
        return annotations[id].pageNumber === pageNumber;
      }

      return true;
    };
    const filteredAnnotation = list.filter(filterAnnotation);

    return {
      annotations,
      list: filteredAnnotation,
    };
  },
);

export const selectCurrentAnnotation = createSelector(
  [
    (state: RootState) => state.pdf.annotations.byId,
    (state: RootState) => state.pdf.annotations.selected,
  ],
  (annotations, annotationId) => (annotationId ? annotations[annotationId] : null),
);
// #endregion

// #region Slice
const PDFSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAnnotations: (state, action: PayloadAction<PDF.Annotation[]>) => {
      state.ids = [];
      state.byId = {};
      state.byPage = {};
      action.payload.forEach((annotation) => {
        state.ids.push(annotation.id);
        state.byId[annotation.id] = { ...annotation };
        if (!state.byPage[annotation.pageNumber]) {
          state.byPage[annotation.pageNumber] = [];
        }
        state.byPage[annotation.pageNumber].push(annotation.id);
      });

      state.selected = state.selected && state.ids.includes(state.selected) ? state.selected : null;
      state.loaded = true;
    },
    addAnnotation: (state, action: PayloadAction<PDF.Annotation>) => {
      state.ids.push(action.payload.id);
      state.byId[action.payload.id] = action.payload;
    },
    selectAnnotation: (state, action: PayloadAction<PDFAnnotationsSliceState['selected']>) => {
      state.selected = action.payload;
      state.editing = null;
    },
    clearSelection: (state) => {
      state.selected = null;
      state.editing = null;
    },
    clickOnPDFPage: (
      state,
      action: PayloadAction<{ left: number; bottom: number; pageNum: number }>,
    ) => {
      const { left, bottom } = action.payload;

      const possibleSelections = state.ids.filter((annotationId) => {
        const annotation = state.byId[annotationId];
        if (action.payload.pageNum !== annotation.pageNumber) {
          return false;
        }

        if (annotation && isTextMarkup(annotation)) {
          if (
            annotation.quadPoints.some((points) => {
              return (
                left >= points.topLeft.x &&
                left <= points.topRight.x &&
                bottom <= points.topLeft.y &&
                bottom >= points.bottomLeft.y
              );
            })
          ) {
            return true;
          }
        } else if (annotation.rect) {
          if (annotation.subtype === 'Line') {
            const width = annotation.rect.width;
            const height = annotation.rect.height;
            const length = annotation.border?.width ?? 0;

            /* (Xn,Yn) is the middle line
            /* So we have to add/subtract half of the length to X0,Xend or Y0,Yend to take the line's thickness into consideration
            */
            return (
              left >= annotation.rect.left - (width < height ? length / 2 : 0) &&
              left <= annotation.rect.right + (width < height ? length / 2 : 0) &&
              bottom >= annotation.rect.bottom - (height < width ? length / 2 : 0) &&
              bottom <=
                annotation.rect.bottom + annotation.rect.height + (height < width ? length / 2 : 0)
            );
          } else {
            return (
              left >= annotation.rect.left &&
              left <= annotation.rect.left + annotation.rect.width &&
              bottom >= annotation.rect.bottom &&
              bottom <= annotation.rect.bottom + annotation.rect.height
            );
          }
        }

        return false;
      });

      if (possibleSelections.length > 0) {
        if (state.selected) {
          const currentIndex = possibleSelections.findIndex((id) => id === state.selected);

          state.selected =
            possibleSelections[(currentIndex + 1) % possibleSelections.length] ?? null;
        } else {
          state.selected = possibleSelections[0] ?? null;
        }
      } else {
        state.selected = null;
        state.editing = null;
      }
    },
    setEditingAnnotation: (
      state,
      action: PayloadAction<{ id: PDFAnnotationsSliceState['editing']; isTask: boolean }>,
    ) => {
      state.editing = action.payload.id;
      if (action.payload.id) {
        state.selected = action.payload.id;
      }
    },
    setCreating: (state, action: PayloadAction<PDF.TaskCreationData | undefined>) => {
      state.creating = action.payload;
      state.editing = null;
      state.selected = null;
    },
    setAnnotationsFilter<
      F extends keyof PDFAnnotationsSliceState['filters'],
      V extends PDFAnnotationsSliceState['filters'][F],
    >(
      state: WritableDraft<PDFAnnotationsSliceState>,
      action: PayloadAction<FilterValuePayload<F, V>>,
    ) {
      const { filter, value } = action.payload;
      state.filters[filter] = value;
    },
    setTasksFilter<
      F extends keyof PDFAnnotationsSliceState['tasksFilters'],
      V extends PDFAnnotationsSliceState['tasksFilters'][F],
    >(
      state: WritableDraft<PDFAnnotationsSliceState>,
      action: PayloadAction<TaskFilterValuePayload<F, V>>,
    ) {
      const { filter, value } = action.payload;
      state.tasksFilters[filter] = value;
    },
    setTextboxPresetStyle<
      P extends keyof PDFAnnotationsSliceState['textbox']['preset'],
      V extends PDFAnnotationsSliceState['textbox']['preset'][P],
    >(
      state: WritableDraft<PDFAnnotationsSliceState>,
      action: PayloadAction<TextboxStylePayload<P, V>>,
    ) {
      const { property, value } = action.payload;
      state.textbox.preset[property] = value;
    },
    setTextboxPresetStyles(
      state: WritableDraft<PDFAnnotationsSliceState>,
      action: PayloadAction<Partial<PDFAnnotationsSliceState['textbox']['preset']>>,
    ) {
      state.textbox.preset = { ...DEFAULT_TEXTBOX_STYLES, ...action.payload };
    },
    setTextboxSelectedStyle<
      P extends keyof PDFAnnotationsSliceState['textbox']['selected'],
      V extends PDFAnnotationsSliceState['textbox']['selected'][P],
    >(
      state: WritableDraft<PDFAnnotationsSliceState>,
      action: PayloadAction<TextboxStylePayload<P, V>>,
    ) {
      const { property, value } = action.payload;
      state.textbox.selected[property] = value;
    },
    setTextboxSelectedStyles(
      state,
      action: PayloadAction<Partial<PDFAnnotationsSliceState['textbox']['selected']>>,
    ) {
      state.textbox.selected = { ...DEFAULT_TEXTBOX_STYLES, ...action.payload };
    },
    setClearAllFilters: (state) => {
      state.filters = {
        type: [],
        status: [],
        priority: [],
        users: [],
        creationDate: null,
        specificDateValues: {
          startDate: null,
          endDate: null,
        },
      };
    },
    clearAllTasksFilters: (state) => {
      state.tasksFilters = {
        status: [],
        deleted: false,
        assignedUser: null,
      };
    },
    setAnnotationsSearchBy: (
      state,
      action: PayloadAction<PDFAnnotationsSliceState['searchBy']>,
    ) => {
      state.searchBy = action.payload;
    },
    setTasksSearchBy: (state, action: PayloadAction<PDFAnnotationsSliceState['tasksSearchBy']>) => {
      state.tasksSearchBy = action.payload;
    },
    setCreationSetting: (
      state,
      action: PayloadAction<Partial<PDFAnnotationsSliceState['creation']>>,
    ) => {
      state.creation = { ...state.creation, ...action.payload };
    },
    setAnnotationBeingTransformed: (state, action: PayloadAction<PDF.Annotation['id'] | null>) => {
      state.beingTransformed = action.payload;
    },
    toggleTasksListMode: (state) => {
      state.tasksListMode = !state.tasksListMode;
    },
    setInitiatedWithTasks: (
      state,
      action: PayloadAction<PDFAnnotationsSliceState['initiatedWithTasks']>,
    ) => {
      state.initiatedWithTasks = action.payload;
    },
    setClusteredAnnotations: (
      state,
      action: PayloadAction<Exclude<PDFAnnotationsSliceState['clustered'], null>>,
    ) => {
      const clustered = action.payload;

      const pages = Object.typedKeys(clustered);

      if (pages.length) {
        pages.forEach((pageNum) => {
          state.clustered = {
            ...state.clustered,
            [pageNum]: clustered[pageNum],
          };
        });
      } else if (!state.clustered) {
        state.clustered = {};
      }
    },
  },
});

function isTextMarkup(annotation: PDF.Annotation): annotation is PDF.Annotation.TextMarkup {
  return (
    annotation.subtype === 'Highlight' ||
    annotation.subtype === 'Underline' ||
    annotation.subtype === 'StrikeOut' ||
    (annotation.subtype === 'Task' && !!annotation.quadPoints)
  );
}

export default PDFSlice.reducer;
// #endregion

// #region Actions
export const {
  setAnnotations,
  addAnnotation,
  selectAnnotation,
  clearSelection,
  clickOnPDFPage,
  setEditingAnnotation,
  setCreating,
  setAnnotationsFilter,
  setTasksFilter,
  setClearAllFilters,
  clearAllTasksFilters,
  setAnnotationsSearchBy,
  setTasksSearchBy,
  setTextboxPresetStyle,
  setTextboxPresetStyles,
  setTextboxSelectedStyle,
  setTextboxSelectedStyles,
  setCreationSetting,
  setAnnotationBeingTransformed,
  toggleTasksListMode,
  setInitiatedWithTasks,
  setClusteredAnnotations,
} = PDFSlice.actions;
// #endregion
