import { useIntl } from 'react-intl';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import { navigateToMyFiles } from 'router/history';

import { ErrorView, IntlErrorBoundary, Sidebar } from '_common/components';

import SharedListPage from './pages/SharedListPage/SharedListPage';

import styles from './SharedContainer.module.scss';

const SharedContainer = () => {
  const intl = useIntl();
  const match = useRouteMatch();

  return (
    <div className={styles.root}>
      <Sidebar />
      <Switch>
        <Route exact path={match.path}>
          <DocumentTitle
            title={`doDOC - ${intl.formatMessage({
              id: 'storage.sidebar.shared',
            })}`}
          >
            <IntlErrorBoundary size="large" margin="21rem auto 0 auto">
              <SharedListPage />
            </IntlErrorBoundary>
          </DocumentTitle>
        </Route>
        <Route path="*">
          <ErrorView
            onClick={navigateToMyFiles}
            error={{
              status: 404,
            }}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default SharedContainer;
