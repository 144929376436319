import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Input } from 'dodoc-design-system';

import { useFind } from './FindContext';

export type SearchProps = {
  resetFind: () => void;
  find: ({
    matchCase,
    wholeWord,
    query,
  }: {
    matchCase: boolean;
    wholeWord: boolean;
    query: string;
  }) => void;
};

const Search = ({ resetFind, find }: SearchProps) => {
  const intl = useIntl();

  const { matchCase, wholeWord } = useFind();

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (inputValue === '') {
      resetFind();
    }
  }, [inputValue]);

  useEffect(() => {
    if (inputValue) {
      search();
    }
  }, [matchCase, wholeWord]);

  const search = () => {
    if (inputValue) {
      find({
        matchCase,
        wholeWord,
        query: inputValue,
      });
    }
  };

  return (
    <Input
      placeholder={intl.formatMessage({ id: 'SEARCH_TEXT' })}
      size="medium"
      prefix="SearchGrey"
      width="22.25rem"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onEnterKey={search}
      onPrefixClick={search}
      testId="find-text"
    />
  );
};

export default Search;
