import { useIntl } from 'react-intl';
import { SectionHeading } from 'dodoc-design-system';
import getConfig from 'dodoc.config';

import { Setting } from 'Settings/components';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import NotificationSettings from './NotificationSettings/NotificationSettings';

import styles from './General.module.scss';

const General = () => {
  const intl = useIntl();

  return (
    <>
      <div className={styles.sectionHeading}>
        <SectionHeading
          title={intl.formatMessage({ id: 'GENERAL_SETTINGS' })}
          testId="general-settings-heading"
        />
      </div>
      <IntlErrorBoundary size="large" margin="10rem 0 9rem 0">
        <Setting
          style={{ alignItems: 'flex-start' }}
          labelStyles={{ lineHeight: '3rem', marginLeft: '4rem', width: '35rem', paddingLeft: 0 }}
          valueStyles={{ lineHeight: '3rem', paddingLeft: '5rem' }}
          label={intl.formatMessage({ id: 'settings.general.title' })}
          value={getConfig().tenant || window.location.hostname}
          testId="tenant-title"
        />
      </IntlErrorBoundary>
      <div className={styles.notificationSettingsContainter}>
        <SectionHeading
          title={intl.formatMessage({ id: 'NOTIFICATION_SETTINGS' })}
          sticky
          testId="notification-settings-heading"
        />
      </div>
      <IntlErrorBoundary size="large" margin="10rem 0 0 0">
        <NotificationSettings />
      </IntlErrorBoundary>
    </>
  );
};

export default General;
