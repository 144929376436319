import { FormattedMessage } from 'react-intl';
import { Divider } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import UserAvatar from '_common/components/UserAvatar/UserAvatar';
import { selectCollaborators } from 'App/redux/appSlice';

import MultipleUsersAvatar from './MultipleUsersAvatar/MultipleUsersAvatar';

import styles from './Collaborators.module.scss';

const MAX_COLLABORATORS_DISPLAY = 4;

const Collaborators = () => {
  const elementId = Object.keys(useSelector((state) => state.table.selected))[0];
  const curreUserId = useSelector((state) => state.auth.userId);
  const collaborators = useSelector((state) => selectCollaborators(state, elementId));

  const overflownCollaborators =
    collaborators.length > MAX_COLLABORATORS_DISPLAY + 1
      ? collaborators.splice(
          MAX_COLLABORATORS_DISPLAY,
          collaborators.length - MAX_COLLABORATORS_DISPLAY,
        )
      : [];

  return (
    <div className={styles.root}>
      {collaborators.length === 0 ? (
        <div className={styles.emptyState}>
          <FormattedMessage id="storage.browserSidebar.onlyYou" />
        </div>
      ) : (
        <>
          <UserAvatar userId={curreUserId} showTooltip />
          <Divider vertical />
          <div className={styles.collaborators}>
            {collaborators.map((collaboratorId) => (
              <UserAvatar key={`avatar-${collaboratorId}`} userId={collaboratorId} showTooltip />
            ))}
            {overflownCollaborators.length > 0 && (
              <MultipleUsersAvatar
                usersId={overflownCollaborators}
                testId="information-panel-object-multiple-collaborators"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Collaborators;
