import { Button, Tooltip } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useIEnvision } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

type ExportButtonProps = {
  object: doDOC.SuiteObject;
};

const ExportButton = ({ object }: ExportButtonProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isIEnvision = useIEnvision();

  const handleExport = () => {
    switch (object.type) {
      case 'dopdf':
        dispatch(
          openAndUpdateModal({
            modal: 'ExportPDFModal',
            data: {
              id: object.id,
            },
          }),
        );
        break;
      case 'document':
        dispatch(
          openAndUpdateModal({
            modal: 'ExportDocumentModal',
            data: {
              exportType: 'simple',
            },
          }),
        );
        break;
      default:
        console.warn('TBI export for type', object.type);
    }
  };

  return (
    <Tooltip
      content={intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })}
      placement="left"
      disabled={!isIEnvision}
      testId="export-no-permission-tooltip"
    >
      <Button
        variant="neutral"
        size="medium"
        margin="0 0 0 1rem"
        onClick={handleExport}
        disabled={isIEnvision}
        testId="export-button"
      >
        <FormattedMessage id="global.export" />
      </Button>
    </Tooltip>
  );
};

export default ExportButton;
