import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';
import { usePasswordChecker } from '_common/hooks';

import styles from './PasswordCheck.module.scss';

type PasswordCheckProps = {
  rules: Account['password_validators'];
  password: string;
  setDisabled: (value: boolean) => void;
};

const PasswordCheck = ({ rules = [], password = '', setDisabled }: PasswordCheckProps) => {
  // Hooks
  const [newRules, setNewRules] = useState<PasswordCheckProps['rules']>([]);
  // Custom Hooks
  const verifiedList = usePasswordChecker(rules, password);

  const RULES_TRANSLATIONS: { [key: string]: { label: string } } = {
    min_length: { label: 'AT_LEAST_CHARS' },
    max_length: { label: 'ANO_MORE_THAN_CHARS' },
    min_max_length: { label: 'BETWEEN_CHARS' },
    contains_upper_case: { label: 'A_UPPERCASE_LETTER' },
    contains_lower_case: { label: 'A_LOWERCASE_LETTER' },
    contains_digit: { label: 'A_NUMBER' },
    contains_symbol: { label: 'A_SPECIAL_CHAR' },
    regex: { label: 'CUSTOM_RESTRICTIONS' },
  };

  useEffect(() => {
    let newRules = [];
    let min;
    let max;

    const keys = rules.map((element) => Object.keys(element)[0]);
    const hasDoubleLimit = keys.includes('min_length') && keys.includes('max_length');
    if (hasDoubleLimit) {
      newRules = rules.filter((element) => {
        const currentKey = Object.keys(element)[0];
        if (currentKey === 'min_length') {
          min = element[currentKey];
        } else if (currentKey === 'max_length') {
          max = element[currentKey];
        }

        return currentKey !== 'min_length' && currentKey !== 'max_length';
      });
      newRules.unshift({ min_length: min, max_length: max });
    } else {
      newRules = [...rules];
    }

    setNewRules(newRules);
  }, [rules]);

  useEffect(
    () => setDisabled(!newRules.every((rule) => verifiedList.includes(Object.keys(rule)[0]))),
    [verifiedList, newRules],
  );

  const getValues = (obj: { [key: string]: string | boolean | number }, key: string) => {
    const keys = rules.map((element) => Object.keys(element)[0]);
    if (keys.includes('min_length') && keys.includes('max_length')) {
      return { min: obj.min, max: obj.max };
    }
    if (keys.includes('min_length') || keys.includes('max_length')) {
      return { number: obj[key] };
    }
    return {};
  };

  let customMessage = '';
  const includeCustomRules = rules.some((element) => {
    if (Object.keys(element)[0] === 'regex') {
      customMessage = `"${element.description}"`;
    }
    return Object.keys(element)[0] === 'regex';
  });
  return (
    <div className={styles.popoverContainer} data-testid="password-policy-popover">
      <div className={styles.triangle} />
      <div className={styles.title}>
        <FormattedMessage id="PASSWORD_POLICY" />
      </div>
      {newRules.map((obj) => {
        const key = Object.keys(obj)[0];
        const isRuleVerified = verifiedList.includes(key);
        return (
          <div className={styles.rule} key={key}>
            <Icon size={24} icon={isRuleVerified ? 'Done' : 'NotDone'} />
            <div className={`${styles.ruleName} ${isRuleVerified && styles.verifiedName}`}>
              <FormattedMessage id={RULES_TRANSLATIONS[key]?.label} values={getValues(obj, key)} />
            </div>
          </div>
        );
      })}
      {includeCustomRules && (
        <div className={styles.note}>
          <FormattedMessage id="NOTE_PASSWORD_RESTRICTIONS" />
          {customMessage}
        </div>
      )}
    </div>
  );
};

export default PasswordCheck;
