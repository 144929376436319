import { MouseEventHandler, ReactNode } from 'react';
import { MenuItem } from 'react-contextmenu';
import { Tooltip, KeyboardShortcut } from 'dodoc-design-system';
import { TooltipProps } from 'dodoc-design-system/build/types/Components/Tooltip/Tooltip';

import styles from './ContextMenuItem.module.scss';

type ContextMenuItemProps = {
  disabled?: boolean;
  shortcut?: string;
  tooltip?: Omit<TooltipProps, 'children'>;
  onClick: MouseEventHandler<HTMLDivElement>;
  children: ReactNode;
  dataTestId: string;
};

const ContextMenuItem = ({
  children,
  onClick,
  disabled,
  shortcut,
  tooltip = { disabled: true, content: '', testId: '' },
  dataTestId,
}: ContextMenuItemProps) => (
  <Tooltip {...tooltip} testId={`${dataTestId}-tooltip`}>
    <div data-testid={dataTestId} className={styles.root}>
      {/* @ts-expect-error update react-contextmenu MenuItem component prop types out of date */}
      <MenuItem
        onClick={onClick}
        disabled={disabled}
        className={`${styles.item} ${disabled && styles.disabled}`}
      >
        {children}
        {shortcut && (
          <div className={styles.shortcut}>
            <KeyboardShortcut shortcut={shortcut} testId={`${shortcut}-shortcut`} />
          </div>
        )}
      </MenuItem>
    </div>
  </Tooltip>
);

export default ContextMenuItem;
