import { SelectionDecorator } from './Decorators';
import { InsertInlineCommand } from './GenericCommands';
import {
  ArrowDownCommand,
  ArrowLeftCommand,
  ArrowRightCommand,
  ArrowUpCommand,
  BackspaceCommand,
  DefaultCommand,
  DeleteCommand,
  EnterCommand,
  ProcessCommand,
  TabCommand,
} from './KeyDownCommands';

import {
  InsertImageCommand,
  UpdateImageSizeCommand,
  ChangeImageCommand,
  UpdateImagePropertiesCommand,
} from './ImageCommands';

import {
  AddTemporaryCommentCommand,
  AddCommentCommand,
  RemoveTemporaryCommentCommand,
} from './CommentsCommands';

import {
  EditEquationCommand,
  InsertEquationCommand,
  UpdateEquationCommand,
} from './EquationsCommands';
import { Descendant } from 'slate';

import { InsertCitationCommand } from './CitationsCommands';

type ShortcutKeys<T> = {
  ctrlKey: T;
  shiftKey: string[];
  altKey: string[];
};

const DEFAULT_ALLOWED_KEYS: string[] = [
  'CapsLock',
  'Fn',
  'FnLock',
  'NumLock',
  'End',
  'Home',
  'PageDown',
  'PageUp',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'F10',
  'F11',
  'F12',
  'F13',
  'F14',
  'F15',
  'F16',
  'F17',
  'F18',
  'F19',
  'F20',
  'Control',
];

const ALLOWED_SHORTCUT_KEYS: ShortcutKeys<boolean | string[]> = {
  ctrlKey: true /* { c: 'c', x: 'x', v: 'v', r: 'r', shiftKey: { r: 'r' } } */,
  shiftKey: [],
  altKey: [],
};

const OVERRIDED_SHORTCUT_KEYS: ShortcutKeys<string[]> = {
  ctrlKey: [
    'ArrowLeft',
    'ArrowUp',
    'ArrowRight',
    'ArrowDown',
    'Backspace',
    'Delete',
    'Enter',
    'Tab',
    'V',
    'K',
    'k',
  ],
  shiftKey: [],
  altKey: [],
};

export class CommandFactory {
  private context: Editor.Edition.Context;

  constructor(context: Editor.Edition.Context) {
    this.context = context;
  }

  /**
   * validates if a keydown default action is allowed
   * @param {KeyboardEvent} event
   */
  isKeyDefaultAllowed(event: KeyboardEvent) {
    if (event.ctrlKey || event.metaKey) {
      if (typeof ALLOWED_SHORTCUT_KEYS.ctrlKey === 'boolean') {
        return (
          ALLOWED_SHORTCUT_KEYS.ctrlKey && !OVERRIDED_SHORTCUT_KEYS.ctrlKey.includes(event.key)
        );
      } else {
        return (
          ALLOWED_SHORTCUT_KEYS.ctrlKey.includes(event.key) &&
          !OVERRIDED_SHORTCUT_KEYS.ctrlKey.includes(event.key)
        );
      }
    }
    if (event.shiftKey) {
      return (
        ALLOWED_SHORTCUT_KEYS.shiftKey.includes(event.key) &&
        !OVERRIDED_SHORTCUT_KEYS.shiftKey.includes(event.key)
      );
    }
    if (event.altKey) {
      return (
        ALLOWED_SHORTCUT_KEYS.altKey.includes(event.key) &&
        !OVERRIDED_SHORTCUT_KEYS.altKey.includes(event.key)
      );
    }
    return DEFAULT_ALLOWED_KEYS.includes(event.key);
  }

  /**
   * validates if a keydown event is printable
   * @param {KeyboardEvent} event
   */
  isKeyPrintable(event: KeyboardEvent) {
    return event.key && event.key.length === 1;
  }

  getKeyDownCommand(event: KeyboardEvent): Editor.Edition.ICommand | null {
    let command = null;
    switch (event.key) {
      // Backspace command
      case 'Backspace': {
        if (this.context.editionMode !== 'DISABLED') {
          command = new SelectionDecorator(new BackspaceCommand(this.context, event));
        }
        break;
      }
      // Tab command
      case 'Tab': {
        if (this.context.editionMode !== 'DISABLED') {
          if (!event.ctrlKey && !event.metaKey) {
            command = new SelectionDecorator(new TabCommand(this.context, event));
          } else {
            // shortcus?
          }
        }
        break;
      }
      // Enter command
      case 'Enter':
        if (this.context.editionMode !== 'DISABLED') {
          if (!event.ctrlKey && !event.metaKey) {
            command = new SelectionDecorator(new EnterCommand(this.context, event));
          } else {
            // shortcus?
          }
        }
        break;
      // Delete command
      case 'Delete': {
        if (this.context.editionMode !== 'DISABLED') {
          command = new SelectionDecorator(new DeleteCommand(this.context, event));
        }
        break;
      }
      // ArrowLeft command
      case 'ArrowLeft': {
        command = new SelectionDecorator(new ArrowLeftCommand(this.context, event), {
          scrollIntoSelection: false,
          removePasteOptions: false,
          updateModifiers: false,
        });
        break;
      }
      // ArrowUp command
      case 'ArrowUp': {
        command = new SelectionDecorator(new ArrowUpCommand(this.context, event), {
          scrollIntoSelection: false,
          removePasteOptions: false,
          updateModifiers: false,
        });
        break;
      }
      // ArrowRight command
      case 'ArrowRight': {
        command = new SelectionDecorator(new ArrowRightCommand(this.context, event), {
          scrollIntoSelection: false,
          removePasteOptions: false,
          updateModifiers: false,
        });
        break;
      }
      // ArrowDown command
      case 'ArrowDown': {
        command = new SelectionDecorator(new ArrowDownCommand(this.context, event), {
          scrollIntoSelection: false,
          removePasteOptions: false,
          updateModifiers: false,
        });
        break;
      }
      // Process command
      case 'Process': {
        if (this.context.editionMode !== 'DISABLED') {
          command = new SelectionDecorator(new ProcessCommand(this.context, event));
        }
        break;
      }
      // Default command
      default: {
        if (this.context.editionMode !== 'DISABLED') {
          if (!event.ctrlKey && !event.metaKey) {
            if (this.isKeyPrintable(event) && !event.isComposing) {
              command = new SelectionDecorator(new DefaultCommand(this.context, event));
            }
          } else {
            // TODO: handle shortcuts?
            // ctrl + KeyV
            // ctrl + k
          }
        }
        break;
      }
    }
    return command;
  }

  getCommentsCommand(
    action: Editor.Edition.CommentsActionsType,
    comment?: Descendant[],
  ): Editor.Edition.ICommand | null {
    let command = null;
    switch (action) {
      case 'ADD_COMMENT': {
        if (comment) {
          command = new SelectionDecorator(new AddCommentCommand(this.context, comment));
        }
        break;
      }
      case 'ADD_TEMPORARY_COMMENT': {
        command = new SelectionDecorator(new AddTemporaryCommentCommand(this.context));
        break;
      }
      case 'REMOVE_TEMPORARY_COMMENT':
        command = new SelectionDecorator(new RemoveTemporaryCommentCommand(this.context));
        break;
    }
    return command;
  }

  getInsertInlineCommand() {
    return new SelectionDecorator(new InsertInlineCommand(this.context));
  }

  getImageCommand(
    action: Editor.Edition.ImagesActionsType,
    image?: File | string,
    height?: number,
    width?: number,
    properties?: Editor.Styles.ImageProperties,
  ): Editor.Edition.ICommand | null {
    let command = null;
    switch (action) {
      case 'INSERT_IMAGE': {
        if (image && typeof image !== 'string') {
          command = new SelectionDecorator(new InsertImageCommand(this.context, image));
        }
        break;
      }
      case 'UPDATE_IMAGE_SIZE': {
        if (image && height && width && typeof image === 'string') {
          command = new SelectionDecorator(
            new UpdateImageSizeCommand(this.context, image, height, width),
          );
        }
        break;
      }
      case 'CHANGE_IMAGE': {
        if (image && typeof image !== 'string') {
          command = new SelectionDecorator(new ChangeImageCommand(this.context, image));
        }
        break;
      }

      case 'UPDATE_IMAGE_PROPERTIES': {
        if (properties) {
          command = new SelectionDecorator(
            new UpdateImagePropertiesCommand(this.context, properties),
          );
          break;
        }
      }
    }
    return command;
  }

  getEquationsCommand(
    action: Editor.Edition.EquationsActionsType,
    value?: any,
    blockId?: string,
    elementId?: string,
  ): Editor.Edition.ICommand | null {
    let command = null;
    switch (action) {
      case 'EDIT_EQUATION': {
        command = new SelectionDecorator(new EditEquationCommand(this.context));
        break;
      }
      case 'INSERT_EQUATION': {
        if (value) {
          command = new SelectionDecorator(new InsertEquationCommand(this.context, value));
        }
        break;
      }
      case 'UPDATE_EQUATION': {
        if (blockId && elementId && value) {
          command = new SelectionDecorator(
            new UpdateEquationCommand(this.context, blockId, elementId, value),
          );
        }
        break;
      }
    }
    return command;
  }

  getCitationCommand(
    action: Editor.Edition.CitationActionsType,
    citationId: string,
  ): Editor.Edition.ICommand | null {
    let command = null;
    switch (action) {
      case 'INSERT_CITATION': {
        if (citationId) {
          command = new SelectionDecorator(new InsertCitationCommand(this.context, citationId));
        }
        break;
      }
    }
    return command;
  }
}
