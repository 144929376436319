import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

const SLICE_NAME = 'EDITOR_FONTS';

type FontOption = SelectOption & {
  supported: boolean;
  fontFamily: string;
};

type FontsSliceState = {
  external: FontOption[];
  missing: FontOption[];
  default: FontOption[];
};

const initialState: FontsSliceState = {
  external: [],
  missing: [],
  default: [],
};

const FontsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setFontValidationData: (
      state,
      action: PayloadAction<Pick<FontsSliceState, 'external' | 'missing' | 'default'>>,
    ) => {
      state.external = action.payload.external;
      state.missing = action.payload.missing;
      state.default = action.payload.default;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export const { setFontValidationData } = FontsSlice.actions;

export default FontsSlice.reducer;
