import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import tippy from 'tippy.js';
import { store } from '_common/redux';
import Intl from 'Intl/Intl';
import { FormattedMessage } from 'react-intl';

import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';
import { BaseViewElement } from '../..';

import { DISPLAY_TYPES, ELEMENTS } from 'Editor/services/consts';

import './HyperlinkElement.module.scss';

export class HyperlinkElement extends BaseViewElement {
  private linkTooltipElement?: HTMLElement;
  private linkTooltipInstance?: any;

  static get observedAttributes() {
    return ['href'];
  }

  constructor() {
    super();
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);

    this.handleScroll = this.handleScroll.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('mouseover', this.onMouseOver);
    this.addEventListener('mouseout', this.onMouseOut);
  }

  disconnectedCallback() {
    this.removeEventListener('mouseover', this.onMouseOver);
    this.removeEventListener('mouseout', this.onMouseOut);

    this.destroyLinkTooltip();

    super.disconnectedCallback();
  }

  attributeChangedCallback(attribute: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      switch (attribute) {
        case 'href':
          this.href = newValue;
          break;
        default:
          break;
      }
    }
  }

  get displayType() {
    return DISPLAY_TYPES.INLINE;
  }

  get isEditable() {
    return true;
  }

  get isDeletable() {
    return true;
  }

  get isSplitable() {
    return true;
  }

  get isContentWrapper() {
    return false;
  }

  get isSelectable() {
    return true;
  }

  get href() {
    return this.dataset.href;
  }

  set href(value) {
    if (value != null) {
      this.dataset.href = value;
    }
  }

  select() {
    this.setAttribute('selected', 'true');
  }

  deselect() {
    this.removeAttribute('selected');
  }

  onMouseOver() {
    let shortcut;
    if (ReduxInterface.getPlatformInfo().os.mac) {
      shortcut = '⌘ + Click';
    } else {
      shortcut = 'Ctrl + Click';
    }

    this.linkTooltipElement = document.createElement('div');
    ReactDOM.render(
      <Provider store={store}>
        <Intl>
          <FormattedMessage id="TOOLTIP_LINK_SHORTCUT" values={{ shortcut }} />
        </Intl>
      </Provider>,
      this.linkTooltipElement,
    );

    const fieldElementIsMultiline = this.getClientRects().length > 1;

    this.linkTooltipInstance = tippy(this, {
      content: this.linkTooltipElement,
      placement: fieldElementIsMultiline ? 'right' : 'top',
      trigger: 'manual',
      interactive: true,
      arrow: true,
      multiple: true,
      hideOnClick: false,
      //These options have to be accordingly to the popper version tippy is using (v1)
      popperOptions: {
        modifiers: {
          preventOverflow: { enabled: !fieldElementIsMultiline }, //If the fieldElement is in multilines the tooltip shall not repositioned
        },
      },
    });

    this.linkTooltipInstance.show();
  }

  onMouseOut() {
    this.linkTooltipInstance.hide();
  }

  handleScroll() {
    document.getElementById('EditorRoot')?.removeEventListener('scroll', this.handleScroll);
    if (this.linkTooltipInstance) {
      this.linkTooltipInstance.hide();
    }
  }

  private destroyLinkTooltip() {
    if (this.linkTooltipInstance) {
      this.linkTooltipInstance.hide();
      this.linkTooltipInstance.destroy(true);
      this.linkTooltipInstance = undefined;
    }
  }
}

if (!window.customElements.get(ELEMENTS.HyperlinkElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.HyperlinkElement.IDENTIFIER, HyperlinkElement);
}
