import { useIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { useSelector } from '_common/hooks';
import { navigateToMyFiles } from 'router/history';

import { selectUserIsAdmin } from '_common/services/api/authority';
import { ErrorView, IntlErrorBoundary, Sidebar } from '_common/components';

import StoragePage from './pages/StoragePage/StoragePage';
import RecyclePage from './pages/RecyclePage/RecyclePage';
import SpacesListPage from './pages/SpacesListPage/SpacesListPage';
import PersonalSpacesListPage from './pages/PersonalSpacesListPage/PersonalSpacesListPage';

import styles from './StorageContainer.module.scss';

const StorageContainer = () => {
  const intl = useIntl();
  const match = useRouteMatch();

  const listingPersonalSpaces = useSelector((state) => state.spaces.personals);
  const userIsAdmin = useSelector(selectUserIsAdmin);

  return (
    <div className={styles.root}>
      <Sidebar />
      <Switch>
        <Route exact path={match.path}>
          <DocumentTitle
            title={`doDOC - ${intl.formatMessage({
              id: 'storage.sidebar.myFiles',
            })}`}
          >
            <IntlErrorBoundary size="large" margin="21rem auto 0 auto">
              <StoragePage />
            </IntlErrorBoundary>
          </DocumentTitle>
        </Route>
        <Route exact path={`${match.path}/recycle`}>
          <DocumentTitle
            title={`doDOC - ${intl.formatMessage({
              id: 'global.recycle',
            })}`}
          >
            <IntlErrorBoundary size="large" margin="21rem auto 0 auto">
              <RecyclePage />
            </IntlErrorBoundary>
          </DocumentTitle>
        </Route>
        <Route exact path={`${match.path}/spaces`}>
          <DocumentTitle
            title={`doDOC - ${intl.formatMessage({
              id: 'storage.sidebar.spaces',
            })}`}
          >
            <IntlErrorBoundary size="large" margin="21rem auto 0 auto">
              {userIsAdmin && listingPersonalSpaces ? (
                <PersonalSpacesListPage />
              ) : (
                <SpacesListPage />
              )}
            </IntlErrorBoundary>
          </DocumentTitle>
        </Route>
        <Route exact path={`${match.path}/:type(space|folder)/:id`}>
          <IntlErrorBoundary size="large" margin="21rem auto 0 auto">
            <StoragePage />
          </IntlErrorBoundary>
        </Route>
        <Route path="*">
          <ErrorView
            error={{
              status: 404,
            }}
            onClick={navigateToMyFiles}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default StorageContainer;
