import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { BaseOperation } from './BaseOperation';
import { RealtimeOpsBuilder } from '_common/services/Realtime';

type InsertElementOptions = {
  pathFix?: 'TEXT' | 'AFTER';
};

export class InsertElementOperation extends BaseOperation<Editor.Data.Node.Model> {
  protected path: Editor.Selection.Path;
  private elementData: Editor.Data.Node.Data;
  private options?: InsertElementOptions;

  constructor(
    model: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    elementData: Editor.Data.Node.Data,
    options?: InsertElementOptions,
  ) {
    super(model);
    this.path = path;
    this.elementData = elementData;
    this.options = options;

    this.build();
  }

  private adjustPathToContent(path: Editor.Selection.Path): Editor.Selection.Path {
    let childNodes: Editor.Data.Node.Data[] | undefined = this.elementData.childNodes;

    while (childNodes) {
      let childOffset = 0;
      if (childNodes.length > 0) {
        childOffset = childNodes.length - 1;
      }

      path.push('childNodes');
      path.push(childOffset);

      const lastChild = childNodes[childNodes.length - 1];
      if (NodeUtils.isTextData(lastChild)) {
        path.push('content');
        path.push(lastChild.content.length);
        childNodes = undefined;
      } else {
        childNodes = lastChild.childNodes;
      }
    }

    return path;
  }

  private insertInChildNodes() {
    const pathLenth = this.path.length;
    let pathToParent = this.path.slice(0, pathLenth - 2);
    let childOffset = Number(this.path[pathLenth - 1]);

    const parentdata = this.model.getChildDataByPath(pathToParent);

    if (NodeUtils.isElementData(parentdata) && !isNaN(childOffset)) {
      const previousElement = parentdata.childNodes?.[childOffset - 1];
      const nextElement = parentdata.childNodes?.[childOffset];
      if (
        NodeUtils.isTextData(this.elementData) &&
        (NodeUtils.isTextData(previousElement) || NodeUtils.isTextData(nextElement))
      ) {
        if (NodeUtils.isTextData(previousElement) && NodeUtils.isTextData(nextElement)) {
          // if previous and next are text elements join them
          let path = [
            ...pathToParent,
            'childNodes',
            childOffset - 1,
            'content',
            previousElement.content.length,
          ];
          this.ops.push(RealtimeOpsBuilder.stringInsert(this.elementData.content, path));

          this.resultPath = [
            ...pathToParent,
            'childNodes',
            childOffset - 1,
            'content',
            previousElement.content.length + this.elementData.content.length,
          ];

          path = [
            ...pathToParent,
            'childNodes',
            childOffset - 1,
            'content',
            previousElement.content.length + this.elementData.content.length,
          ];
          this.ops.push(RealtimeOpsBuilder.stringInsert(nextElement.content, path));

          this.ops.push(
            RealtimeOpsBuilder.listDelete(nextElement, [
              ...pathToParent,
              'childNodes',
              childOffset,
            ]),
          );
        } else if (NodeUtils.isTextData(previousElement)) {
          // insert text into previous text
          const path = [
            ...pathToParent,
            'childNodes',
            childOffset - 1,
            'content',
            previousElement.content.length,
          ];
          this.ops.push(RealtimeOpsBuilder.stringInsert(this.elementData.content, path));
          this.resultPath = [
            ...pathToParent,
            'childNodes',
            childOffset - 1,
            'content',
            previousElement.content.length + this.elementData.content.length,
          ];
        } else if (NodeUtils.isTextData(nextElement)) {
          // insert text into next text
          const path = [...pathToParent, 'childNodes', childOffset, 'content', 0];
          this.ops.push(RealtimeOpsBuilder.stringInsert(this.elementData.content, path));
          this.resultPath = [
            ...pathToParent,
            'childNodes',
            childOffset,
            'content',
            0 + this.elementData.content.length,
          ];
        }
      } else {
        // insert text as child element
        if (!NodeUtils.isTextData(this.elementData)) {
          this.elementData.parent_id = parentdata.id;
        }

        this.ops.push(RealtimeOpsBuilder.listInsert(this.elementData, this.path));

        if (this.options?.pathFix === 'TEXT') {
          this.resultPath = this.adjustPathToContent([...pathToParent, 'childNodes', childOffset]);
        } else {
          this.resultPath = [...pathToParent, 'childNodes', childOffset + 1];
        }
      }
    }
  }

  private insertInContent() {
    const pathLenth = this.path.length;
    let pathToParent = this.path.slice(0, pathLenth - 4);
    let childOffset = Number(this.path[pathLenth - 3]);
    let contentOffset = Number(this.path[pathLenth - 1]);

    const parentData = this.model.getChildDataByPath(pathToParent);
    const textData = parentData.childNodes?.[childOffset];

    if (
      NodeUtils.isElementData(parentData) &&
      NodeUtils.isTextData(textData) &&
      !isNaN(childOffset) &&
      !isNaN(contentOffset)
    ) {
      if (NodeUtils.isTextData(this.elementData)) {
        // element to insert is text

        this.ops.push(RealtimeOpsBuilder.stringInsert(this.elementData.content, this.path));
        this.resultPath = [
          ...pathToParent,
          'childNodes',
          childOffset,
          'content',
          contentOffset + this.elementData.content.length,
        ];
      } else {
        // element to insert is not text

        const contentLength = textData.content.length;
        const contentToSplit = textData.content.slice(contentOffset, contentLength);

        if (contentToSplit.length) {
          this.ops.push(RealtimeOpsBuilder.stringDelete(contentToSplit, this.path));
        }

        if (!NodeUtils.isTextData(this.elementData)) {
          this.elementData.parent_id = parentData.id;
        }

        this.ops.push(
          RealtimeOpsBuilder.listInsert(this.elementData, [
            ...pathToParent,
            'childNodes',
            childOffset + 1,
          ]),
        );

        if (this.options?.pathFix === 'TEXT') {
          this.resultPath = this.adjustPathToContent([
            ...pathToParent,
            'childNodes',
            childOffset + 1,
          ]);
        } else {
          this.resultPath = [...pathToParent, 'childNodes', childOffset + 2];
        }

        if (contentToSplit.length) {
          // create text element
          const textData = NodeDataBuilder.build({
            type: 'text',
            content: contentToSplit,
          });

          this.ops.push(
            RealtimeOpsBuilder.listInsert(textData, [
              ...pathToParent,
              'childNodes',
              childOffset + 2,
            ]),
          );
        }
      }
    }
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const pathLenth = this.path.length;

    if (this.path[pathLenth - 2] === 'childNodes') {
      this.insertInChildNodes();
    } else if (this.path[pathLenth - 2] === 'content') {
      this.insertInContent();
    }

    return this;
  }
}
