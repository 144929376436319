import ViewModelValidations from 'Editor/services/VisualizerManager/ViewModels/ViewModelValidations';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { WordPositionIterator } from '../../../Iterators';
import { JsonRange } from '../../../JsonRange';
import { BaseModifier } from '../BaseModifier';

export class MoveWordModifier extends BaseModifier {
  constructor(Data: Editor.Data.API, direction: Editor.Selection.ModifierDirection) {
    super(Data, 'move', 'word', direction);
  }

  private moveWordForward(range: Editor.Selection.EditorRange) {
    this.collapseRange(range);

    const node = range.startContainer;
    const blockNode = EditorDOMUtils.findFirstLevelChildNode(
      EditorDOMUtils.getContentContainer(node),
      node,
    ) as Editor.Visualizer.BaseView;

    const blockViewModel = blockNode.vm;

    const textElement = EditorDOMUtils.getSelectableElementFromBlock(blockNode, node);

    if (blockNode && range.isAtNodeEnd(blockNode)) {
      const nextSibling = EditorDOMUtils.getNextSelectableElement(blockNode, textElement, node);
      if (nextSibling) {
        range.setStart(nextSibling, 0);
        range.setEnd(nextSibling, 0);
      }
    } else if (ViewModelValidations.isBlockViewModel(blockViewModel)) {
      const jsonRange = JsonRange.buildFromDOMRange(range);

      if (jsonRange) {
        const nodeData = this.Data.nodes.getNodeModelById(jsonRange.start.b);

        if (nodeData?.navigationData) {
          const iterator = new WordPositionIterator(nodeData.navigationData, jsonRange.start.p);
          let nextPath = iterator.next();

          if (nextPath) {
            jsonRange.start.p = nextPath;
            jsonRange.end.p = nextPath;

            range.updateFromJsonRange(jsonRange);
          } else {
            throw new Error('Invalid position!');
          }
        }
      }
    }

    // update range modifiers
    const clientRects = range.getClientRects();
    if (clientRects.length > 0) {
      this.Data.selection?.updateModifiers({
        direction: this.direction,
        px: clientRects[0].left,
      });
    }
  }

  private moveWordBackward(range: Editor.Selection.EditorRange) {
    this.collapseRange(range);

    const node = range.startContainer;
    const blockNode = EditorDOMUtils.findFirstLevelChildNode(
      EditorDOMUtils.getContentContainer(node),
      node,
    ) as Editor.Visualizer.BaseView;

    const textElement = EditorDOMUtils.getSelectableElementFromBlock(blockNode, node);

    if (blockNode && range.isAtNodeStart(blockNode)) {
      const previousSibling = EditorDOMUtils.getPreviousSelectableElement(
        blockNode,
        textElement,
        node,
      );
      if (previousSibling) {
        if (
          previousSibling.lastChild &&
          EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(
            previousSibling.lastChild.nodeName,
          )
        ) {
          range.setStart(previousSibling, previousSibling.childNodes.length - 1);
          range.setEnd(previousSibling, previousSibling.childNodes.length - 1);
        } else {
          range.setStart(previousSibling, previousSibling.childNodes.length);
          range.setEnd(previousSibling, previousSibling.childNodes.length);
        }
      }
    } else {
      const jsonRange = JsonRange.buildFromDOMRange(range);

      if (jsonRange) {
        const nodeData = this.Data.nodes.getNodeModelById(jsonRange.start.b);

        if (nodeData?.navigationData) {
          const iterator = new WordPositionIterator(nodeData.navigationData, jsonRange.start.p);
          const previousPosition = iterator.previous();

          if (previousPosition) {
            jsonRange.start.p = previousPosition;
            jsonRange.end.p = previousPosition;

            range.updateFromJsonRange(jsonRange);
          } else {
            throw new Error('Invalid position!');
          }
        }
      }
    }

    // update range modifiers
    const clientRects = range.getClientRects();
    if (clientRects.length > 0) {
      this.Data.selection?.updateModifiers({
        direction: this.direction,
        px: clientRects[0].left,
      });
    }
  }

  visitDoDOCRange(range: Editor.Selection.EditorRange): void {
    switch (this.direction) {
      case 'forward':
        return this.moveWordForward(range);
      case 'backward':
        return this.moveWordBackward(range);
      default:
        return;
    }
  }

  visitJsonRange(range: Editor.Selection.JsonRange): void {
    const editorRange = range.serializeToDOMRange();
    this.visitDoDOCRange(editorRange);
    range.updateFromDOMRange(editorRange);
  }
}
