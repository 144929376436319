import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { InputField, Select, TextArea, Tag } from 'dodoc-design-system';
import styles from './PublishVeevaForm.module.scss';

type BaseValueProps = {
  value: string;
  label: string;
};

type MetadataFieldProps = {
  id: string;
  options: {
    label: string;
    value: string;
  }[];
};

type PublishVeevaFormProps = {
  METADATA: {
    comment: {
      id: string;
    };
    change_type: MetadataFieldProps;
    content_file_type: MetadataFieldProps;
    channel: MetadataFieldProps;
    synapse_library: MetadataFieldProps;
  } | null;
  setComment: (value: string) => void;
  comment: string;
  changeType: BaseValueProps;
  contentFileType: BaseValueProps[];
  channel: BaseValueProps[];
  synapseLibrary: BaseValueProps;
  editMetadata: ({
    field,
    value,
    add,
  }: {
    field: string | undefined;
    value: string;
    add?: boolean;
  }) => void;
};

const PublishVeevaForm = ({
  METADATA,
  setComment,
  comment,
  changeType,
  contentFileType,
  channel,
  synapseLibrary,
  editMetadata,
}: PublishVeevaFormProps) => {
  const intl = useIntl();
  const timeout = useRef<number>();

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value ?? '';
    e.persist();
    setComment(value);
    clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      editMetadata({
        field: METADATA?.comment.id,
        value,
      });
    }, 500);
  };

  const addMultiSelect = (value: { value: string }, field: string | undefined) => {
    editMetadata({ field, value: value.value, add: true });
  };

  const removeMultiSelectd = (value: { value: string }, field: string | undefined) => {
    editMetadata({ field, value: value.value, add: false });
  };

  if (METADATA === null) {
    return null;
  }

  return (
    <>
      <InputField size="large" label="Version comment" testId="version-comment-field">
        <div className={styles.textArea}>
          <TextArea
            value={comment}
            onChange={handleCommentChange}
            placeholder={intl.formatMessage({ id: 'Write your comment for this document version' })}
            size="medium"
            testId="veeva-form-version-comment-textarea"
          />
        </div>
      </InputField>
      <InputField size="large" label="Change Type" testId="change-type-field">
        <Select
          menuPosition="fixed"
          size="large"
          value={changeType.value ? changeType : null}
          onChange={(v) => {
            editMetadata({
              field: METADATA.change_type.id,
              value: v.value,
            });
          }}
          options={METADATA.change_type.options}
          width="100%"
          testId="change-type"
        />
      </InputField>
      <InputField
        size="large"
        label="Content file type"
        feedback={contentFileType.length > 0 ? false : ''}
        testId="content-file-type-field"
      >
        <Select
          menuPosition="fixed"
          size="large"
          value={null}
          onChange={(v) => addMultiSelect(v, METADATA.content_file_type.id)}
          options={METADATA.content_file_type.options}
          width="100%"
          filterOption={(option: { value: string }) =>
            contentFileType.every(({ value }) => value !== option.value)
          }
          testId="contnt-file-type"
        />
      </InputField>
      {contentFileType.length > 0 && (
        <div className={styles.tags}>
          {contentFileType.map((v, i) => (
            <Tag
              key={v.label}
              title={v.label}
              margin="1rem 1rem 0 0"
              onClick={() => removeMultiSelectd(v, METADATA.content_file_type.id)}
              testId={`content-file-type-${i}`}
            >
              {v.label}
            </Tag>
          ))}
        </div>
      )}
      <InputField
        size="large"
        label="Channel"
        feedback={channel.length > 0 ? false : ''}
        testId="channel-field"
      >
        <Select
          menuPosition="fixed"
          size="large"
          value={null}
          onChange={(v) => addMultiSelect(v, METADATA.channel.id)}
          options={METADATA.channel.options}
          width="100%"
          filterOption={(option: { value: string }) =>
            channel.every(({ value }) => value !== option.value)
          }
          testId="channel"
        />
      </InputField>
      {channel.length > 0 && (
        <div className={styles.tags}>
          {channel.map((v, i) => (
            <Tag
              key={v.label}
              title={v.label}
              margin="1rem 1rem 0 0"
              onClick={() => removeMultiSelectd(v, METADATA.channel.id)}
              testId={`channel-${i}`}
            >
              {v.label}
            </Tag>
          ))}
        </div>
      )}
      <InputField size="large" label="Synapse library" testId="synapse-library-field">
        <Select
          menuPosition="fixed"
          size="large"
          value={synapseLibrary.value ? synapseLibrary : null}
          onChange={(v) => {
            editMetadata({
              field: METADATA.synapse_library.id,
              value: v.value,
            });
          }}
          options={METADATA.synapse_library.options}
          width="100%"
          testId="synapse-library"
        />
      </InputField>
    </>
  );
};

export default PublishVeevaForm;
