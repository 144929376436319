import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, Checkbox } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import { useSelector, useDispatch } from '_common/hooks';

import { setAppLoading } from 'App/redux/appSlice';
import { exportPDF, getExportPDF } from 'PDF/redux/PDFGeneralSlice';
import { closeAndResetModal } from '../ModalsSlice';

const ExportPDFModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.ExportPDFModal);
  const id = useSelector((state) => state.modals.ExportPDFModal.id);
  const data = useSelector((state) => state.app.data);

  const [includeTasks, setIncludeTasks] = useState(true);

  const close = () => {
    dispatch(closeAndResetModal('ExportPDFModal'));
  };

  const handleExport = () => {
    dispatch(
      setAppLoading({
        isOpen: true,
        message: 'EXPORTING_DOCUMENT',
      }),
    );
    dispatch(exportPDF({ id, options: { keep_tasks: includeTasks } })).then(() => {
      notify({
        type: 'success',
        title: 'DOPDF_SUCCESSFULLY_EXPORTED',
        message: 'DOPDF_SUCCESSFULLY_EXPORTED_MESSAGE',
        messageValues: {
          name: data[id].name,
        },
        persist: true,
        footerContent: (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="small"
              onClick={() =>
                dispatch(getExportPDF({ id: id }))
                  .unwrap()
                  .then((result) => {
                    const url = window.URL.createObjectURL(result);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${data[id].name}`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  })
              }
              testId="download-manually-button"
            >
              <FormattedMessage id="DOWNLOAD_MANUALLY" />
            </Button>
          </div>
        ),
      });
      dispatch(setAppLoading({ isOpen: false }));
    });
    close();
  };

  return (
    <Modal width="62rem" open={!!isOpen} onClose={close} testId="export-pdf">
      <Modal.Header onClose={close}>
        <FormattedMessage id={'EXPORT'} values={{ name: data[id].name }} />
      </Modal.Header>
      <Modal.Body>
        <div>
          <FormattedMessage id="DO_YOU_WANT_TO_INCLUDE_TASKS" values={{ name: data[id].name }} />
          <Checkbox
            checked={includeTasks ? 'checked' : 'unchecked'}
            onChange={() => setIncludeTasks(!includeTasks)}
            margin="1rem 0 1rem 0"
            testId="export-pdf-include-tasks"
          >
            <FormattedMessage id="YES_INCLUDE_TASKS_AS_COMMENTS" />
          </Checkbox>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="export-pdf-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleExport}
          testId="export-pdf-submit-button"
        >
          <FormattedMessage id="global.export" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportPDFModal;
