import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams, useLocation } from 'react-router';
import { navigateToMyFiles } from 'router/history';
import DocumentTitle from 'react-document-title';

import { useDispatch } from '_common/hooks';
import { setCurrentAppPage } from 'App/redux/appSlice';
import { useGetObjectQuery } from 'App/redux/objectApi';
import { setSelectedTab, TabMenuIdentity } from '_common/components/TabMenu/TabMenuSlice';

import { ErrorView, IntlErrorBoundary } from '_common/components';

import Header from './Header';
import Body from './Body';

import styles from './ObjectSettingsPage.module.scss';

const ObjectSettingsPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id: objectId, type }: { id: ObjectId; type: ObjectType } = useParams();
  const { hash } = useLocation();

  const { data: object, error } = useGetObjectQuery(
    { objectId, objectType: type, open: true },
    { skip: !objectId || !type },
  );

  useEffect(() => {
    dispatch(setCurrentAppPage('/settings/object'));
  }, []);

  useEffect(() => {
    if (hash) {
      let menuId: TabMenuIdentity = 'objectSettingsPage_document';
      const tab = hash.substring(1);

      if (tab) {
        switch (object?.type) {
          case 'space':
            menuId = 'objectSettingsPage_space';
            break;
          case 'dopdf':
          case 'folder':
            menuId = 'objectSettingsPage_folder';
            break;
          case 'document':
            menuId = 'objectSettingsPage_document';
            break;
          case 'file':
            menuId = 'objectSettingsPage_file';
            break;
        }

        dispatch(setSelectedTab({ menuId: menuId, selectedTab: tab }));
      }
    }
  }, [hash, object]);

  if (error) {
    return <ErrorView error={error} onClick={navigateToMyFiles} />;
  }

  // Maybe switch to a loading component
  if (!object) {
    return null;
  }

  return (
    <DocumentTitle
      title={`doDOC - ${
        object.type === 'space' && object.personal
          ? intl.formatMessage({
              id: 'spaces.personalSpace',
            })
          : object.name
      }`}
    >
      <div className={styles.root}>
        <div className={styles.header}>
          <IntlErrorBoundary size="small" margin="0 0 0 7rem">
            <Header />
          </IntlErrorBoundary>
        </div>
        <div className={styles.page}>
          <IntlErrorBoundary size="large" margin="13.5rem 0 0 0">
            <Body />
          </IntlErrorBoundary>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default ObjectSettingsPage;
