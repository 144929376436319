import { useMemo } from 'react';
import { Accordion } from 'dodoc-design-system';
import { useIntl } from 'react-intl';
import SearchUser, { UserOption } from '_common/components/SearchUser/SearchUser';
import { useDispatch, useSelector } from '_common/hooks';
import {
  DateRange,
  FilterIdentity,
  FilterName,
  OptionByType,
  SettingByType,
  TRANSLATIONS,
  TYPES,
} from '../../FilterController';
import { setFilter } from '../../FilterSlice';

const UserHandler = ({
  identity,
  filter,
  filterQuantity,
  options,
  settings,
  testId,
}: {
  identity: FilterIdentity;
  filter: FilterName;
  filterQuantity: number;
  options: OptionByType['user'];
  settings: SettingByType['user'];
  testId: string;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const userSelections = useSelector((state) => {
    const value = state.filters[identity]?.[filter];
    return value as Exclude<typeof value, DateRange>; //Cast to avoid typescript false calls due to DateRange) as
  });

  const selectedUserIds = useMemo(
    () =>
      Array.isArray(userSelections)
        ? userSelections.map((selection) => selection.value)
        : userSelections?.value,
    [userSelections],
  );

  const handlerHasValue = selectedUserIds && selectedUserIds.length > 0;

  //Workaround of SearchUser issues
  //Based on userId only, fetch info to construct a displayable value
  const currentValue = useMemo<UserOption[]>(() => {
    if (!selectedUserIds || selectedUserIds.length === 0) {
      return [];
    }

    //Options of removed and imported users
    let otherUsersOptions: UserOption[] = [];

    if (Array.isArray(selectedUserIds)) {
      selectedUserIds.forEach((selectedUserId) => {
        otherUsersOptions.push({
          name: selectedUserId,
          value: selectedUserId,
          label: selectedUserId,
          type: 'user',
        } as UserOption);
      });
    } else {
      otherUsersOptions.push({
        name: selectedUserIds,
        value: selectedUserIds,
        type: 'user',
      } as UserOption);
    }

    return [...otherUsersOptions];
  }, [selectedUserIds]);

  const handleValueChange = (selectedUser: UserOption | UserOption[]) => {
    if (Array.isArray(selectedUser)) {
      dispatch(
        setFilter({
          identity,
          filter,
          value: selectedUser
            .filter((user) => !!user.value)
            .map((user) => ({ value: user.value ?? '', label: user.name ?? user.label })),
        }),
      );
    } else {
      dispatch(
        setFilter({
          identity,
          filter,
          value: selectedUser
            ? { value: selectedUser.value, label: selectedUser.name ?? selectedUser.label }
            : null,
        }),
      );
    }
  };

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: TRANSLATIONS[filter] })}
      initialCollapsed={!handlerHasValue}
      collapsable={filterQuantity > 1}
      testId={`${testId}-accordion`}
    >
      <SearchUser
        fullWidth
        isMulti={TYPES.multiUser.includes(filter)}
        menuWidth="31rem"
        size="medium"
        value={currentValue}
        options={options as UserOption[]}
        onChange={handleValueChange}
        placeholder={intl.formatMessage({ id: 'PLACEHOLDER_SEARCH_USERS' })}
        testId={testId}
        editor={settings?.editorAvatar}
        searchable
      />
    </Accordion>
  );
};

export default UserHandler;
