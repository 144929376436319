import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialStateType = { element: Permission.Element };

const SLICE_NAME = 'Permissions';
const INITIAL_STATE: InitialStateType = {
  element: 'users',
};

const permissionsSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setElement: (state, action: PayloadAction<InitialStateType['element']>) => {
      state.element = action.payload;
    },
  },
});

export const { setElement } = permissionsSlice.actions;

export default permissionsSlice.reducer;
