import { getExportPDF, setExporting } from 'PDF/redux/PDFGeneralSlice';
import usePDFData from 'PDF/hooks/usePDFData';
import { useEffect } from 'react';
import { useDispatch, useInterval, useSelector } from '_common/hooks';
import { InstanceService } from '_common/services';

const usePDFExport = () => {
  const dispatch = useDispatch();
  const exporting = useSelector((state) => state.pdf.general.exporting);
  const modalIsOpen = useSelector((state) => state.modals.open.PDFExportingDocumentModal);
  const pdf = usePDFData();

  const controller = new AbortController();

  useInterval(
    async () => {
      if (exporting && pdf) {
        const { data } = await new InstanceService({ controller }).checkJobStatus({
          jobs: [exporting],
        });
        // @ts-expect-error job api spec is missing
        if (data[exporting].status === 'finished') {
          dispatch(getExportPDF({ id: pdf.id }))
            .unwrap()
            .then((data) => {
              const url = window.URL.createObjectURL(data);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${pdf.name}`);
              document.body.appendChild(link);
              link.click();
              link.remove();
            });
        }
      }
    },
    exporting ? 3000 : null,
  );

  useEffect(() => {
    if (!modalIsOpen && exporting) {
      controller.abort();
      dispatch(setExporting(null));
    }
  }, [modalIsOpen]);
};

export default usePDFExport;
