import { FilterPopover } from '_common/components';
import { Settings } from '_common/components/Filters/FilterController';

type CommentFiltersProps = {
  categories: Settings;
};

const CommentFilters = ({ categories }: CommentFiltersProps) => {
  return (
    <FilterPopover
      identity="reviewerCommentPanel"
      popperSettings={{ placement: 'bottom-start' }}
      toggleVariant="link"
      variant="reviewer"
      {...categories}
    />
  );
};

export default CommentFilters;
