import Slide from './Slide/Slide';
import Note from './Note/Note';
import styles from './Slides.module.scss';
import { useSelector } from '_common/hooks';
import { useStructureSync } from 'Presentation/SyncStore';

const Slides = () => {
  const structure = useStructureSync();
  const showNotes = useSelector((state) => state.presentation.showNotes);
  if (!structure) {
    return null;
  }
  return (
    <div className={styles.slides} data-testid="slides-container">
      <Slide slideId={structure.sldIdLst[0]} />
      {showNotes && <Note />}
    </div>
  );
};

export default Slides;
