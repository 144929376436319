//TODO: REDO

import { notify } from '_common/components/ToastSystem';
import { Logger } from '_common/services';

let INSTANCE: HistoryManager;

export default class HistoryManager {
  private timeouts: {
    undo: Record<string, NodeJS.Timeout>;
    redo: Record<string, NodeJS.Timeout>;
  } = {
    undo: {},
    redo: {},
  };
  private editorContext?: Editor.Context;

  static getInstance() {
    if (!INSTANCE) {
      INSTANCE = new HistoryManager();
    }
    return INSTANCE;
  }

  constructor() {
    this.timeouts = {
      undo: {},
      redo: {},
    };
    this.onFinishingPatch = this.onFinishingPatch.bind(this);
    this.beforePatchRevert = this.beforePatchRevert.bind(this);
    this.afterPatchRevert = this.afterPatchRevert.bind(this);
    this.beforePatchApply = this.beforePatchApply.bind(this);
    this.afterPatchApply = this.afterPatchApply.bind(this);
  }

  start(editorContext: Editor.Context) {
    this.editorContext = editorContext;
    this.editorContext?.DataManager?.history?.hooks?.onFinishingPatch.register(
      this.onFinishingPatch,
    );
    this.editorContext?.DataManager?.history?.hooks?.beforePatchRevert.register(
      this.beforePatchRevert,
    );
    this.editorContext?.DataManager?.history?.hooks?.afterPatchRevert.register(
      this.afterPatchRevert,
    );
    this.editorContext?.DataManager?.history?.hooks?.beforePatchApply.register(
      this.beforePatchApply,
    );
    this.editorContext?.DataManager?.history?.hooks?.afterPatchApply.register(this.afterPatchApply);
  }

  stop() {}

  private async onFinishingPatch(patch: Realtime.Core.UndoManager.Patch) {
    patch.addSelectionInfo(
      this.editorContext?.DataManager?.selection?.history.getLastWithFlag('debounce'),
      this.editorContext?.DataManager?.selection?.history.getLastWithFlag(),
    );
  }

  private async beforePatchRevert(patch: Realtime.Core.UndoManager.Patch) {
    this.editorContext?.visualizerManager?.selection.stopSelectionTracker();
    if (!this.editorContext?.navigationManager?.isMarkerRendered(patch.selection?.a, false)) {
      return this.editorContext?.navigationManager?.renderDocumentAtMarker(patch.selection?.a);
    } else {
      this.editorContext.navigationManager.scrollIntoSelection(patch.selection?.a, false);
    }
  }

  private async afterPatchRevert(patch: Realtime.Core.UndoManager.Patch) {
    this.editorContext?.navigationManager?.scrollIntoSelection(patch.selection?.b, false, true);
    this.editorContext?.visualizerManager?.selection.triggerSelectionChanged();
    this.editorContext?.visualizerManager?.selection.debounceStartSelectionTracker();
  }

  private async beforePatchApply(patch: Realtime.Core.UndoManager.Patch) {
    this.editorContext?.visualizerManager?.selection.stopSelectionTracker();
    if (!this.editorContext?.navigationManager?.isMarkerRendered(patch.selection?.b, false)) {
      return this.editorContext?.navigationManager?.renderDocumentAtMarker(patch.selection?.b);
    } else {
      this.editorContext.navigationManager.scrollIntoSelection(patch.selection?.b, false);
    }
  }

  private async afterPatchApply(patch: Realtime.Core.UndoManager.Patch) {
    this.editorContext?.navigationManager?.scrollIntoSelection(patch.selection?.a, false, true);
    this.editorContext?.visualizerManager?.selection.triggerSelectionChanged();
    this.editorContext?.visualizerManager?.selection.debounceStartSelectionTracker();
  }

  createPatch() {
    this.editorContext?.DataManager?.models?.undoManager?.createPatch();
  }

  canUndo() {
    return this.editorContext?.DataManager?.history.canUndo() || false;
  }

  canRedo() {
    return this.editorContext?.DataManager?.history.canRedo() || false;
  }

  async undo(options: { showNotifications?: boolean } = {}) {
    const { showNotifications = true } = options;

    if (this.editorContext?.DataManager?.history.canUndo()) {
      try {
        await this.editorContext.DataManager.history.undo();
      } catch (error) {
        Logger.captureException(error);
        this.editorContext?.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    } else if (showNotifications) {
      if (this.timeouts.undo.notifyNoMoreActions) {
        clearTimeout(this.timeouts.undo.notifyNoMoreActions);
      }
      this.timeouts.undo.notifyNoMoreActions = setTimeout(() => {
        notify({
          type: 'warning',
          title: 'UNDO_NOT_AVAILABLE',
          message: 'editor.errors.undo.noActions',
          // removeAll: true,
        });
      }, 250);
    }
  }

  async redo(options: { showNotifications?: boolean } = {}) {
    const { showNotifications = true } = options;

    if (this.editorContext?.DataManager?.history.canRedo()) {
      try {
        await this.editorContext?.DataManager?.history.redo();
      } catch (error) {
        Logger.captureException(error);
        this.editorContext?.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    } else if (showNotifications) {
      if (this.timeouts.redo.notifyNoMoreActions) {
        clearTimeout(this.timeouts.redo.notifyNoMoreActions);
      }
      this.timeouts.redo.notifyNoMoreActions = setTimeout(() => {
        notify({
          type: 'warning',
          title: 'REDO_NOT_AVAILABLE',
          message: 'editor.errors.redo.noActions',
          // removeAll: true,
        });
      }, 250);
    }
  }
}
