import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, InputField, Select, Tooltip } from 'dodoc-design-system';
import { SelectProps } from 'dodoc-design-system/build/types/Components/Selects/Select';

import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';
import { selectUserIsAdmin } from '_common/services/api/authority';
import { addTag, removeTag } from 'App/redux/appSlice';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';
import {
  useGetTagsListQuery,
  useCreateTagMutation,
} from 'Settings/pages/TenantSettingsPage/Tags/TagsApi';

import Tags from './Tags/Tags';
import styles from './TagSystem.module.scss';

type TagSystemProps = {
  objectId: ObjectId;
  testId: string;
  placeholder: string;
} & Pick<SelectProps, 'menuPlacement' | 'size' | 'width'>;

const TagSystem = ({
  objectId,
  testId,
  placeholder,
  menuPlacement,
  size = 'medium',
  width = '100%',
}: TagSystemProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const object = useSelector((state: RootState) => state.app.data[objectId]);
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const [mutCreateTag] = useCreateTagMutation();
  const { status } = useGetElementStatusListQuery(undefined, {
    selectFromResult: ({ data }) => ({ status: data?.entities[object.status] }),
  });

  const { data: metadata } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });
  const [validations, setValidations] = useState({ name: '' });
  const tagsMetadata = Object.entries(metadata)
    .filter((id) => metadata[id[0]]?.name === 'Tags')
    .map(([_, values]) => values)[0];
  const { data: tags, isLoading } = useGetTagsListQuery();
  const counter = object.tags.reduce((acc: any, cur: string) => acc + cur.length + 1, 0);

  const handleAddTag: SelectProps['onChange'] = (option) => {
    if (tagsMetadata) {
      const charactersCounter = counter + option.value.length + 1;
      if (charactersCounter <= tagsMetadata?.char_limit) {
        dispatch(addTag({ objectId: object.id, objectType: object.type, tag: option.value }));
        validations.name = '';
      } else {
        validations.name = intl.formatMessage({
          id: 'CANNOT_ADD_TAG_AS_IT_WILL_EXCEED_THE_CHARACTER_LIMIT',
        });
      }

      if (Object.keys(validations).length > 0) {
        setValidations({ ...validations });
      }
    } else {
      dispatch(addTag({ objectId: object.id, objectType: object.type, tag: option.value }));
    }
  };

  const handleRemoveTag = (value: Tag) => {
    if (tagsMetadata) {
      const charactersCounter = counter - value.length - 1;
      if (charactersCounter <= tagsMetadata?.char_limit) {
        setValidations({ name: '' });
      }
      dispatch(removeTag({ objectId: object.id, objectType: object.type, tag: value }));
    } else {
      dispatch(removeTag({ objectId: object.id, objectType: object.type, tag: value }));
    }
  };

  const handleCreateTag = (tag: Tag) => {
    mutCreateTag(tag);
    if (!object.tags.includes(tag)) {
      handleAddTag({
        value: tag,
        label: tag,
      });
    }
  };

  const checkPermissions = () => {
    return (
      object.user_permissions.includes('owner') ||
      object.user_permissions.includes('edit') ||
      object.user_permissions.includes('admin')
    );
  };

  return (
    <div className={styles.root} data-testid={`${testId}-tags-container`}>
      {checkPermissions() && (
        <div className={styles.inputContainer}>
          <InputField
            feedback={validations.name.length > 0 ? validations.name : false}
            testId={`${testId}-tags-field`}
          >
            <Select
              testId={testId}
              disabled={
                (status && !status?.['allow_edit']) ||
                (tagsMetadata && counter === tagsMetadata?.char_limit)
              }
              showOptionTooltip
              creatable={userIsAdmin}
              size={size}
              width={width}
              value={null}
              onChange={handleAddTag}
              options={
                object.tags &&
                tags?.list
                  .slice()
                  .sort()
                  .filter((tag) => !object.tags.includes(tag))
                  .map((tag) => ({ value: tag, label: tag }))
              }
              placeholder={placeholder}
              noOptionsMessage={({ inputValue }) => {
                if (tags?.list.length === 0) {
                  return intl.formatMessage({ id: 'TENANT_WITHOUT_TAGS' });
                }
                if (tags?.list.length === object.tags.length) {
                  return intl.formatMessage({ id: 'ALL_TAGS_IN_USE' });
                }
                return intl.formatMessage({ id: 'NO_TAGS_FOUNDED' }, { tag: inputValue });
              }}
              onCreateOption={handleCreateTag}
              escapeClearsValue
              isValidNewOption={(input) => input.length > 0}
              isLoading={isLoading}
              menuPlacement={menuPlacement}
              error={!!validations.name}
            />
          </InputField>
        </div>
      )}

      {object.tags && (
        <>
          {tagsMetadata && (
            <div className={styles.counter}>
              <div className={styles.counterText}>{`${counter}/${tagsMetadata?.char_limit}`}</div>
              <Tooltip
                content={intl.formatMessage(
                  {
                    id: 'TAGS_FIELD_HAS_A_CHARACTER_LIMIT_OF_X_CHARACTERS_INCLUDING_ITS_SEPARATORS',
                  },
                  { maxLimit: tagsMetadata?.char_limit },
                )}
                testId={`${testId}-character-limit-tooltip`}
              >
                <Icon icon="InformationBlue" size={16} />
              </Tooltip>
            </div>
          )}
          <Tags
            testId={testId}
            tags={object.tags}
            canEdit={checkPermissions()}
            removeTag={status?.allow_edit && checkPermissions() && handleRemoveTag}
          />
        </>
      )}
    </div>
  );
};

export default TagSystem;
