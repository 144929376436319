import { ChangeEventHandler, UIEventHandler, useMemo, useRef, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Input, EmptyState, ProgressBar } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { getDocumentObject, selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';
import {
  selectManualMetadata,
  selectAutomaticMetadata,
  useGetMetadataListQuery,
} from 'App/redux/MetadataApi';

import MetadataFields from '_common/components/MetadataFields/MetadataFields';
import MetadataOption from './MetadataOption/MetadataOption';

import styles from './Metadata.module.scss';

type MetadataProps = {
  editMetadata?: boolean;
  setEditMetadata: (newValue: boolean) => void;
  setValidations: (newValidation: string) => void;
  validations?: string;
};

const Metadata = ({
  editMetadata,
  setEditMetadata,
  setValidations,
  validations,
}: MetadataProps) => {
  const intl = useIntl();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const { data: metadata, isLoading } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });
  const element = useSelector(getDocumentObject);
  const id = element.id;
  const automaticMetadata = useSelector((state) => selectAutomaticMetadata(state, id));
  const manualMetadata = useSelector((state) => selectManualMetadata(state, id));
  const [activeOption, setActiveOption] = useState('MANUAL_METADATA');
  const [searchValue, setSearchValue] = useState('');
  const optionsRefs = {
    MANUAL_METADATA: useRef<HTMLDivElement>(null),
    AUTOMATIC_METADATA: useRef<HTMLDivElement>(null),
  };
  const OPTIONS = [
    {
      label: 'MANUAL_METADATA',
      metadataOptions: manualMetadata,
    },
    {
      label: 'AUTOMATIC_METADATA',
      metadataOptions: automaticMetadata,
    },
  ];

  const metadataObjects = useMemo(() => {
    if (searchValue === '') {
      return OPTIONS;
    }
    return OPTIONS.map((option) => {
      return {
        label: option.label,
        metadataOptions: option.metadataOptions.filter((metadata) =>
          metadata.label.toLowerCase().includes(searchValue.toLowerCase()),
        ),
      };
    }).filter((option) => option.metadataOptions.length > 0);
  }, [element.metadata, searchValue, metadata]);

  const handleOnOptionClick = (option?: keyof typeof optionsRefs) => {
    if (option && optionsRefs[option].current) {
      optionsRefs[option].current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
  };

  const handleOnScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const container = e.currentTarget;
    if (container.scrollTop === 0) {
      setActiveOption(OPTIONS[0].label);
    } else {
      setActiveOption(OPTIONS[1].label);
    }
  };

  const handleEdit = () => {
    setEditMetadata(true);
  };

  const getRef = (option: string) => {
    if (option === 'MANUAL_METADATA') {
      return optionsRefs['MANUAL_METADATA'];
    }
    if (option === 'AUTOMATIC_METADATA') {
      return optionsRefs['AUTOMATIC_METADATA'];
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <ProgressBar testId="loading-metadata-progressBar" />
        <div className={styles.message}>
          <FormattedMessage id="LOADING_METADATA" />
        </div>
      </div>
    );
  }

  if (editMetadata) {
    const manualMetadataIds = Object.keys(metadata).filter(
      (id) => metadata[id].data_type !== 'dynamic',
    );
    return (
      <>
        <div className={styles.editContainer}>
          <FormattedMessage id="EDIT_MANUAL_METADATA" />
        </div>
        {manualMetadataIds?.map((field) => (
          <MetadataFields
            key={field}
            id={field}
            setValidations={setValidations}
            validations={validations}
            element={element}
            metadataList={metadata}
            type={metadata[field].data_type}
          />
        ))}
      </>
    );
  }

  return (
    <div className={styles.menusContainer}>
      <div className={styles.menus}>
        <div className={styles.input}>
          <Input
            prefix="NavSearchGrey"
            placeholder="Search"
            value={searchValue}
            onChange={handleOnChange}
            size="large"
            testId="metadata-search"
          />
        </div>
        {OPTIONS.map((option) => (
          <div
            key={option.label}
            className={`${styles.option} ${activeOption === option.label && styles.active}`}
            onClick={() => {
              handleOnOptionClick(
                option.label in optionsRefs
                  ? (option.label as keyof typeof optionsRefs)
                  : undefined,
              );
            }}
          >
            <FormattedMessage id={option.label} />
          </div>
        ))}
      </div>
      {metadataObjects.length > 0 ? (
        <div className={styles.list} onScroll={handleOnScroll}>
          {metadataObjects.map((metadata) => (
            <div key={metadata.label} style={{ paddingTop: metadata.label === 'MANUAL_METADATA' ? 0 : '3rem' }}>
              <div
                className={styles.metadataOption}
                ref={getRef(metadata.label)}
                key={metadata.label}
              >
                <FormattedMessage id={metadata.label} />
                {metadata.label === 'MANUAL_METADATA' && (
                  <Button size="small" onClick={handleEdit} disabled={isReadOnlyMode} testId="edit-metadata-button">
                    <FormattedMessage id="global.edit" />
                  </Button>
                )}
              </div>
              {metadata.metadataOptions.map((option) => {
                return <MetadataOption key={option.label} metadata={option} />;
              })}
            </div>
          ))}
        </div>
      ) : (
        <EmptyState
          size="large"
          icon="NoSearchResults"
          title={intl.formatMessage({ id: 'NO_METADATA_FOUND' })}
          testId="no-metadata-found"
        >
          <FormattedMessage id="NO_METADATA_FOUND_MESSAGE" values={{ string: searchValue }} />
        </EmptyState>
      )}
    </div>
  );
};

export default Metadata;
