import { FormattedMessage } from 'react-intl';

import styles from './Title.module.scss';

type VersionTitleProps = {
  versions?: Card.Version.Versions;
  versionIndex?: number;
  currentVersion?: boolean;
  user?: string;
};

const VersionTitle = ({ versions, versionIndex, currentVersion, user }: VersionTitleProps) => {
  const versionNumber = `V.${(versions?.length || 0) - (versionIndex ?? -1)}`;

  return (
    <div className={styles.root}>
      {currentVersion ? (
        <>
          <FormattedMessage id="CURRENT_VERSION" />
          {` (${versionNumber})`}
          {user && <div className={styles.userName}>{user}</div>}
        </>
      ) : (
        versionNumber
      )}
    </div>
  );
};

export default VersionTitle;
