import { usePDFContext } from 'PDF/PDFContext';
import { useSelector } from '_common/hooks';

/**
 * Hook to select an annotation
 * If the annotation is on a cluster, the cluster is focused
 */
const useSelectAnnotation = () => {
  const pdfManager = usePDFContext();
  const clusteredAnnotations = useSelector((state) => state.pdf.annotations.clustered);
  const annotations = useSelector((state) => state.pdf.annotations.byId);

  const selectAnnotation = (annotationId: PDF.Annotation['id']) => {
    if (clusteredAnnotations) {
      const pages = Object.typedKeys(clusteredAnnotations);
      if (pages.length) {
        pages.forEach((pageNum) => {
          const clusterId = clusteredAnnotations?.[pageNum]?.[annotationId];
          if (clusterId) {
            const clusterElement = document.getElementById(
              `annotations-cluster-${pageNum}-${clusterId}`,
            );
            clusterElement?.click();
          }
        });
      }
    }
    pdfManager.selectAnnotation(annotations[annotationId]);
  };

  return { selectAnnotation };
};

export default useSelectAnnotation;
