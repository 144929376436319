import { forwardRef, Ref, useMemo, useRef, useState, MouseEvent, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider, Label } from 'dodoc-design-system';
import { CardHeaderProps } from 'dodoc-design-system/build/types/Components/Card/CardHeader/CardHeader';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';

import { InteractionController } from '_common/components';
import type { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';

import {
  Options,
  Priority,
  UserPresentation,
  LikeToggle,
  ReplyToggle,
  ReplyList,
  ViewRichTextEditor,
  ResolveButton,
} from '_common/suite/components/Card';
import { Card } from '_common/suite/components';
import { containsMention, isContentEmpty, stringToRichText } from 'utils';
import { DEFAULT_CARD_STYLES_PANEL, setEditingAnnotation } from 'PDF/redux/PDFAnnotationsSlice';
import usePDFCollaborators from 'PDF/hooks/usePDFCollaborators';
import { LikeToggleProps } from '_common/suite/components/Card/LikeToggle/LikeToggle';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import AnnotationType from './AnnotationType';

export type ViewAnnotationCardProps = {
  annotation: PDF.Annotation;
  sidebar: boolean;
  testId: string;
};

const STATUS = {
  resolved: { labelId: 'editor.sidebar.review.filter.status.resolved', color: 'green' },
  deleted: { labelId: 'editor.sidebar.review.filter.status.deleted', color: 'red' },
} as const;

const ViewAnnotationCard = forwardRef(
  ({ annotation, sidebar, testId }: ViewAnnotationCardProps, ref: Ref<HTMLDivElement>) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const pdfManager = usePDFContext();
    const collaborators = usePDFCollaborators();
    const {
      canComment,
      canCopyAnnotation,
      canEditAnnotationReply,
      canDeleteAnnotationReply,
      canEditAnnotation,
      canDeleteAnnotation,
      canResolveAnnotation,
    } = usePDFPermissions();

    const annotationsById = useSelector((state) => state.pdf.annotations.byId);
    const isSelected = useSelector((state) => state.pdf.annotations.selected === annotation.id);

    const editorRef = useRef<RichTextEditorHandler>(null);
    const [showReplies, setShowReplies] = useState(false);

    //#region Card data
    const initialValue = useMemo(() => {
      if (annotation.content && annotation.content.content.length > 0) {
        return JSON.stringify(annotation.content.content);
      }
    }, [annotation]);

    const cardState = useMemo<keyof typeof STATUS | undefined>(() => {
      switch (annotation.state) {
        case 'Cancelled':
          return 'deleted';
        case 'Completed':
          return 'resolved';
        default:
          return undefined;
      }
    }, [annotation]);

    const cardHeaderColor = useMemo<CardHeaderProps['background']>(() => {
      switch (annotation.state) {
        case 'Cancelled':
          return 'red';
        case 'Completed':
          return 'green';
        default:
          return undefined;
      }
    }, [annotation]);
    //#endregion

    //#region Onboarding
    const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
    const replyToggleRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
      dispatch(setPulseData({ annotationRepliesToggled: showReplies }));
      if (replyToggleRef?.current) {
        if (!showReplies && !actionsCompleted.pdf_comments_mentionInCommentReply) {
          dispatch(
            setPulseData({
              annotationCardReplyRect: {
                top: replyToggleRef.current.offsetTop,
                left: replyToggleRef.current.offsetLeft,
                height: replyToggleRef.current.offsetHeight,
                width: replyToggleRef.current.offsetWidth,
              },
            }),
          );
        } else {
          dispatch(
            setPulseData({
              annotationCardReplyRect: undefined,
            }),
          );
        }
      }

      return () => {
        dispatch(
          setPulseData({
            annotationCardReplyRect: undefined,
          }),
        );
      };
    }, [showReplies]);
    //#endregion

    //#region Card methods
    const handleToggleReplies = () => {
      setShowReplies(!showReplies);
    };
    const handleScrollToAnnotation = () => {
      if (sidebar) {
        pdfManager.selectAnnotation(annotation);
      }
    };
    //#endregion

    //#region Annotation methods
    const handleEditAnnotation = () => {
      dispatch(
        setEditingAnnotation({
          id: annotation.id,
          isTask: annotation.subtype === 'Task',
        }),
      );
    };

    const handleDeleteAnnotation = async () => {
      try {
        await pdfManager.deleteAnnotation(annotation.pageNumber, annotation.id);
        notify({
          type: 'success',
          title: intl.formatMessage({ id: 'COMMENT_DELETED' }),
          message: intl.formatMessage({ id: 'THE_COMMENT_WAS_SUCCESSFULLY_DELETED' }),
        });
      } catch (e) {
        throw e;
      }
    };

    const handleResolveAnnotation = async () => {
      try {
        await pdfManager.resolveAnnotation(annotation.pageNumber, annotation.id);
        notify({
          type: 'success',
          title: 'notifications.commentResolve.messageSuccess',
          message: 'THE_COMMENT_WAS_SUCCESSFULLY_RESOLVED',
        });
      } catch (error) {
        throw error;
      }
    };

    const handleCopyText = async (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      //@ts-expect-error
      const editor = editorRef?.current.getCurrent();

      const listener = (e: ClipboardEvent) => {
        if (e.clipboardData && editor?.textContent && editor.outerHTML) {
          e.clipboardData.setData('text/plain', editor?.textContent);
          e.clipboardData.setData('text/html', editor?.outerHTML);
          e.clipboardData.setData('dodoc/richText', JSON.stringify(annotation.content?.content));
          e.preventDefault();
        }
      };

      if (editor) {
        try {
          window.getSelection()?.removeAllRanges();
          document.addEventListener('copy', listener);
          document.execCommand('copy');
          document.removeEventListener('copy', listener);
          notify({
            type: 'success',
            title: intl.formatMessage({ id: 'TEXT_COPIED' }),
            message: intl.formatMessage({ id: 'THE_COMMENT_WAS_SUCCESSFULLY_COPIED' }),
          });
          editor.removeEventListener('copy', listener);
        } catch (e) {
          editor.removeEventListener('copy', listener);
          throw e;
        }
      }
    };

    const handleChangePriority = (priority: PDF.Annotation['priority']) => {
      if (annotation.priority !== priority) {
        pdfManager.changeAnnotationPriority(annotation.pageNumber, annotation.id, priority);
      }
    };

    const handleVoteAnnotation: LikeToggleProps['onVote'] = ({ replyId, currentUserLiked }) => {
      if (replyId) {
        pdfManager.voteReplyToAnnotation(
          annotation.pageNumber,
          annotation.id,
          replyId,
          currentUserLiked ? 0 : 1,
        );
      } else {
        pdfManager.voteAnnotation(annotation.pageNumber, annotation.id, currentUserLiked ? 0 : 1);
      }
    };

    const handleReplyToAnnotation = (reply: string) => {
      pdfManager.replyToAnnotation(annotation.pageNumber, annotation.id, JSON.parse(reply));
      if (annotationsById[annotation.id]?.subtype === 'Note') {
        if (containsMention(stringToRichText(reply))) {
          dispatch(completeAction('pdf_comments_mentionInCommentReply'));
        }
      }
    };

    const handleEditReply = async ({
      replyId,
      newContent,
    }: {
      replyId: string;
      newContent: string;
    }) => {
      try {
        await pdfManager.editReplyAnnotation(
          annotation.pageNumber,
          annotation.id,
          replyId,
          stringToRichText(newContent),
        );
      } catch (e) {
        throw e;
      }
    };

    const handleDeleteReply = async (replyId: string) => {
      try {
        await pdfManager.deleteReplyAnnotation(annotation.pageNumber, annotation.id, replyId);
      } catch (e) {
        throw e;
      }
    };

    const canEditReply = (replyId: string) => {
      const reply = annotation.replies?.find((reply) => reply.id === replyId);
      if (reply) {
        return canEditAnnotationReply(reply);
      }
      return false;
    };
    const canDeleteReply = (replyId: string) => {
      const reply = annotation.replies?.find((reply) => reply.id === replyId);
      if (reply) {
        return canDeleteAnnotationReply(reply);
      }
      return false;
    };
    const canVoteReply = () => {
      return canComment;
    };

    //#endregion

    return (
      <InteractionController environment="dopdf">
        <Card
          id={`annotation-card-${annotation.id}`}
          selected={isSelected}
          sidebar={sidebar}
          onClick={handleScrollToAnnotation}
          ref={ref}
          testId={testId}
        >
          <Card.Header background={cardHeaderColor}>
            <Card.Header.Left>
              <UserPresentation
                userId={annotation.authorId}
                fixedName={!annotation.authorId ? annotation.title : undefined}
                //@ts-expect-error PDF.Annotation.Reply.creationDate is typed as Date but is string
                creationDate={annotation.creationDate}
              />
            </Card.Header.Left>
            <Card.Header.Right>
              <Priority
                priority={annotation.priority}
                onChange={handleChangePriority}
                testId={testId}
              />
              {cardState ? (
                <Label
                  size="small"
                  color={STATUS[cardState].color}
                  margin={'0 0 0 0.5rem'}
                  testId={`${testId}-status-label`}
                >
                  <FormattedMessage id={STATUS[cardState].labelId} />
                </Label>
              ) : (
                <Options
                  editOption={{
                    onClick: handleEditAnnotation,
                    disabled: !canEditAnnotation(annotation),
                  }}
                  deleteOption={{
                    onClick: handleDeleteAnnotation,
                    disabled: !canDeleteAnnotation(annotation),
                  }}
                  moreOptions={[
                    {
                      label: { id: 'Copy' },
                      onClick: handleCopyText,
                      disabled: !canCopyAnnotation || isContentEmpty(annotation.content),
                      order: 0,
                      testId: 'copy',
                    },
                  ]}
                  testId={testId}
                />
              )}
            </Card.Header.Right>
          </Card.Header>
          {cardHeaderColor && <Divider margin="0" />}
          <Card.Body>
            <AnnotationType type={annotation.subtype} />
            {!isContentEmpty(annotation.content) && (
              <ViewRichTextEditor
                ref={editorRef}
                initialValue={initialValue}
                overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
                dependencies={[annotation]}
                testId={`${testId}-rich-text-editor`}
              />
            )}
          </Card.Body>
          <Card.Footer>
            <Card.Footer.Left>
              <LikeToggle
                votes={annotation.votes ?? []}
                target="annotation"
                onVote={handleVoteAnnotation}
                testId={testId}
                disabled={!canComment}
              />
              <InteractionController
                environment="dopdf"
                rules={[{ interaction: 'pdf_comment_reply' }]}
              >
                <ReplyToggle
                  repliesCount={annotation.replies?.length ?? 0}
                  isToggled={showReplies}
                  onToggleReplies={handleToggleReplies}
                  canComment={canComment}
                  id={`annotation-${annotation.id}-replyToggle`}
                  ref={replyToggleRef}
                  testId={testId}
                />
              </InteractionController>
            </Card.Footer.Left>
            <Card.Footer.Right>
              <ResolveButton
                onClick={handleResolveAnnotation}
                disabled={!canResolveAnnotation(annotation)}
                testId={testId}
              />
            </Card.Footer.Right>
          </Card.Footer>
          {showReplies && (
            <ReplyList
              replies={annotation.replies ?? []}
              commentId={annotation.id}
              collaborators={collaborators}
              canComment={canComment}
              createReply={handleReplyToAnnotation}
              editReply={handleEditReply}
              voteReply={handleVoteAnnotation}
              deleteReply={handleDeleteReply}
              canEditReply={canEditReply}
              canDeleteReply={canDeleteReply}
              canVoteReply={canVoteReply}
              newReplyInteraction={{
                environment: 'dopdf',
                rules: [{ interaction: 'pdf_comment_reply' }],
                style: { flexDirection: 'column' },
              }}
              testId={`${testId}-replyList`}
            />
          )}
        </Card>
      </InteractionController>
    );
  },
);

export default ViewAnnotationCard;
