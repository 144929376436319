import { Patch } from './Patch';
import { Hook } from './UndoManagerHook';

type StackHooks = {
  onStatusChanged?: Hook<'onRedoStatusChanged' | 'onUndoStatusChanged'>;
};

type StackOptions = {
  limit?: number;
  onStatusChanged?: Hook<'onRedoStatusChanged' | 'onUndoStatusChanged'>;
};

export class Stack {
  private list: Patch[] = [];
  private limit: number;
  private status: Realtime.Core.UndoManager.StackStatus = 'EMPTY';
  private hooks: StackHooks = {};

  constructor(options?: StackOptions) {
    this.limit = options?.limit || 100;
    if (options?.onStatusChanged) {
      this.hooks.onStatusChanged = options.onStatusChanged;
    }
  }

  private evaluateStatus() {
    const newStatus: Realtime.Core.UndoManager.StackStatus = this.isEmpty ? 'EMPTY' : 'NOT_EMPTY';
    if (newStatus !== this.status) {
      this.status = newStatus;
      if (this.hooks.onStatusChanged) {
        this.hooks.onStatusChanged.trigger(this.status);
      }
    }
  }

  get isEmpty() {
    return this.list.length <= 0;
  }

  get length() {
    return this.list.length;
  }

  get(index: number) {
    return this.list[index];
  }

  getLast() {
    var lastIndex = this.list.length - 1;
    if (lastIndex < 0) {
      throw new Error('Stack empty');
    }
    return this.list[lastIndex];
  }

  pop() {
    const element = this.list.pop();
    this.evaluateStatus();
    return element;
  }

  setLast(item: Patch) {
    this.list.push(item);
    const overflow = this.list.length - this.limit;
    if (overflow > 0) {
      this.list.splice(0, overflow);
    }
    this.evaluateStatus();
  }

  transformStack(doc: Realtime.Core.RealtimeObject, ops: Realtime.Core.RealtimeOps) {
    console.log('transforming stack...');
    let workingOps: Realtime.Core.RealtimeOps = ops;
    const newList: Patch[] = [];
    for (var i = this.list.length - 1; i >= 0; --i) {
      var patch = this.list[i];
      workingOps = patch.transformPatch(doc, workingOps);
      if (!patch.isEmpty) {
        newList.push(patch);
      }
    }
    this.list = newList.reverse();
    this.evaluateStatus();
  }

  clear() {
    this.list = [];
    this.evaluateStatus();
  }
}
