/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { merge } from 'lodash';
import BaseController from '../BaseController';
import { DataObject, Hook, RealtimeObject, CompoundObject } from '_common/services/Realtime';
import { Section, Template, NodeModel } from '../../models';

type PageTypes = keyof Editor.Data.Sections.PageInformation;
type CompoundDataType = {
  pi: Editor.Data.Sections.PageInformation;
  dfp: boolean;
  doe: boolean;
};

export class HeadersAndFootersCompoundModel extends CompoundObject<CompoundDataType> {
  protected shouldUseTemplateFlag: boolean = true;
  hooks: {
    createTempData: Hook<(data: any) => string>;
  };

  constructor(id?: string) {
    super(id);
    this.hooks = {
      createTempData: new Hook<(data: any) => string>(),
    };
  }

  getPageHeader(option: 'FIRST_PAGE' | 'VERSO' | 'RECTO') {
    let header = this.data?.pi.op?.h || {
      childNodes: [],
    };
    if (option === 'FIRST_PAGE' && this.data?.dfp === true) {
      /// VERSO -> even page
      header = this.data?.pi.fp?.h || {
        childNodes: [],
      };
    }
    if (option === 'VERSO' && this.data?.doe === true) {
      /// VERSO -> even page
      header = this.data?.pi.ep?.h || {
        childNodes: [],
      };
    }
    return header;
  }

  getPageFooter(option: 'FIRST_PAGE' | 'VERSO' | 'RECTO') {
    let footer = this.data?.pi.op?.f || {
      childNodes: [],
    };
    if (option === 'FIRST_PAGE' && this.data?.dfp === true) {
      /// VERSO -> even page
      footer = this.data?.pi.fp?.f || {
        childNodes: [],
      };
    }
    if (option === 'VERSO' && this.data?.doe === true) {
      /// VERSO -> even page
      footer = this.data?.pi.ep?.f || {
        childNodes: [],
      };
    }
    return footer;
  }

  private async loadBlock(data: any) {
    // cenas e tal
    // identification
    if (this.hooks.createTempData.hasRegistries()) {
      return (await this.hooks.createTempData.trigger(data))[0];
    }
    return undefined;
  }

  private async mapProperty(
    template: Template,
    destination: Editor.Data.Sections.PageInformation,
    origin: Editor.Data.Sections.PageInformation,
    pi: PageTypes,
    hf: 'h' | 'f',
  ) {
    if (origin[pi]?.[hf]) {
      /// VERSO -> even page
      const headerblocks = origin[pi]?.[hf]?.childNodes || [];
      const result: string[] = [];
      let data;
      for (let index = 0; index < headerblocks.length; index++) {
        data = headerblocks[index];
        //@ts-expect-error;
        data.id = `${template.id}_${pi}_${hf}_${index}`;
        this.loadBlock(data);
        //@ts-expect-error;
        result.push(data.id);
      }
      if (!destination[pi]) {
        destination[pi] = {};
      }
      //@ts-expect-error
      destination[pi][hf] = {
        childNodes: [...result],
      };
    }
  }

  private loadTemplateTemporaryBlocks(
    template: Template,
    data: Editor.Data.Sections.PageInformation,
  ) {
    let newData: Editor.Data.Sections.PageInformation = {};
    if (data?.fp) {
      if (data.fp.h) {
        this.mapProperty(template, newData, data, 'fp', 'h');
      }
      if (data.fp.f) {
        this.mapProperty(template, newData, data, 'fp', 'f');
      }
    }
    if (data?.op) {
      if (data.op.h) {
        this.mapProperty(template, newData, data, 'op', 'h');
      }
      if (data.op.f) {
        this.mapProperty(template, newData, data, 'op', 'f');
      }
    }
    if (data?.ep) {
      if (data.ep.h) {
        this.mapProperty(template, newData, data, 'ep', 'h');
      }
      if (data.ep.f) {
        this.mapProperty(template, newData, data, 'ep', 'f');
      }
    }
    return newData;
  }

  protected handleCompoundableUpdate() {
    const compounded = {};
    for (let index = 0; index < this.compoundElements.length; index++) {
      const object = this.compoundElements[index];
      let data: Partial<CompoundDataType> = {};
      if (object.element instanceof Template) {
        data[object.d] = object.element.getWithPath(object.p);
        if (data[object.d] && object.d === 'pi') {
          data['pi'] = this.loadTemplateTemporaryBlocks(
            object.element,
            data[object.d] as Editor.Data.Sections.PageInformation,
          );
        }
      } else if (object.element instanceof RealtimeObject) {
        data[object.d] = object.element.get(object.p);
      } else if (object.element instanceof DataObject) {
        data[object.d] = object.element.getWithPath(object.p);
      }
      if (data) {
        this.joinData(compounded, data);
      }
    }
    this.data = JSON.parse(JSON.stringify(compounded));
    this.emit('UPDATE', this.data);
  }

  protected joinData(destination: any, origin: any) {
    if (origin) {
      merge(destination, origin);
    }
  }

  shouldUseTemplate() {
    if (this.shouldUseTemplateFlag === undefined) {
      let object;
      for (let index = 0; index < this.compoundElements.length; index++) {
        object = this.compoundElements[index];
        if (object.element instanceof Section) {
          if (object.element.get(object.p) !== undefined) {
            this.shouldUseTemplateFlag = false;
          }
        }
      }
    }
    return this.shouldUseTemplateFlag;
  }
}

type CoumpoundMap = Record<string, HeadersAndFootersCompoundModel>;

export class HeadersAndFootersController extends BaseController {
  private template?: Editor.Data.Template;
  private compoundHeaderFooter: CoumpoundMap = {};

  constructor(Data: Editor.Data.State) {
    super(Data);
    this.createTempData = this.createTempData.bind(this);
  }

  start(documentId: string): void {
    this.template = this.Data.models?.get(this.Data?.models.TYPE_NAME.TEMPLATE, documentId);
  }

  private createTempData(data: any) {
    NodeModel.identify(data as Editor.Data.Node.Data, this.Data.context?.document?.id || '');
    const block = this.Data.models?.get(this.Data?.models.TYPE_NAME.NODE, data.id);
    block?.setTemporaryVersion(data);
    return '';
  }

  getSectionHeader(sectionId: string) {
    if (!this.compoundHeaderFooter[sectionId]) {
      this.compoundHeaderFooter[sectionId] = new HeadersAndFootersCompoundModel(sectionId);
      this.compoundHeaderFooter[sectionId].hooks.createTempData.register(this.createTempData);
      this.compoundHeaderFooter[sectionId].compound([
        {
          element: this.template,
          p: ['page', 'pi'],
          d: 'pi',
        },
        {
          element: this.Data.models?.get(this.Data?.models.TYPE_NAME.SECTION, sectionId),
          p: ['p', 'pi'],
          d: 'pi',
        },
        {
          element: this.Data.models?.get(this.Data?.models.TYPE_NAME.SECTION, sectionId),
          p: ['p', 'dfp'],
          d: 'dfp',
        },
        {
          element: this.Data.models?.get(this.Data?.models.TYPE_NAME.SECTION, sectionId),
          p: ['p', 'doe'],
          d: 'doe',
        },
      ]);
    }
    return this.compoundHeaderFooter[sectionId];
  }

  stop(): void {}

  destroy(): void {}
}
