import { ReactNode } from 'react';
import cn from 'classnames';
import { IntlErrorBoundary } from '_common/components';
import styles from './Panel.module.scss';

type PanelProps = {
  children: ReactNode;
  side: 'left' | 'right';
  width: string;
};

const Panel = ({ children, side, width }: PanelProps) => {
  return (
    <div style={{ width }} className={cn(styles.panel, styles[side])}>
      <IntlErrorBoundary size="medium" margin="16.5rem 0 0 0">
        {children}
      </IntlErrorBoundary>
    </div>
  );
};

export default Panel;
