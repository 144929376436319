export const SLIDES_SIZES = {
  '35mm': { width: 800, height: 600 },
  A3: { width: 800, height: 600 },
  A4: { width: 800, height: 600 },
  banner: { width: 800, height: 600 },
  custom: { width: 800, height: 600 },
  ledger: { width: 800, height: 600 },
  letter: { width: 800, height: 600 },
  overhead: { width: 800, height: 600 },
  screen16x10: { width: 800, height: 600 },
  screen16x9: { width: 800, height: 600 },
  screen4x3: { width: 800, height: 600 },
} as const;
