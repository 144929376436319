import { Command } from '../Command';
import { ReduxInterface } from 'Editor/services';

export class RemoveTemporaryCommentCommand extends Command {
  async exec(): Promise<Editor.Edition.ICommand> {
    try {
      const tempCommentReference = ReduxInterface.getEditorState().comments.insert.reference;
      if (tempCommentReference) {
        this.context.DataManager?.comments
          ?.cancelTemporaryComment(tempCommentReference)
          .then(() => {
            const insertingTemp = ReduxInterface.getEditorState().comments.insert.inserting;
            if (insertingTemp) {
              ReduxInterface.cancelTemporaryComment();
            }

            setTimeout(() => {
              this.context.DataManager?.selection?.restore();
            }, 0);
          });
      }
    } catch (error) {
      logger.captureException(error);
    }

    return this;
  }
}
