import { Logger } from '_common/services';
export class SelectionDecorator implements Editor.Edition.ICommand {
  private cmd: Editor.Edition.Command;
  private options: Editor.Edition.DecoratorOptions;

  constructor(command: Editor.Edition.Command, options: Editor.Edition.DecoratorOptions = {}) {
    this.cmd = command;
    this.options = {
      scrollIntoSelection: true,
      removePasteOptions: true,
      updateModifiers: true,
      ...options,
    };
  }

  async exec(...args: any[]): Promise<Editor.Edition.ICommand> {
    const ctx = this.cmd.context;

    // TODO evaluate if this is needed
    // check if is rendered
    if (ctx.navigationManager?.isMarkerRendered()) {
      // TODO evaluate if this is needed
      // stop selectionTracker ?
      ctx.VisualizerManager?.selection.stopSelectionTracker();

      try {
        // TODO evaluate if this is needed
        // scroll into selection
        if (this.options.scrollIntoSelection) {
          ctx.navigationManager?.scrollIntoSelection();
        }

        // TODO evaluate if this is needed
        // remove paste options
        if (this.options.removePasteOptions) {
          ctx.clipboard?.removePasteOptions();
        }

        // execute command
        await this.cmd.exec(...args);

        // TODO evaluate if this is needed
        // trigger selection change
        ctx.VisualizerManager?.selection.triggerSelectionChanged(!!this.options.updateModifiers);
      } catch (error) {
        Logger.captureException(error);
        // TODO restore changes on error ?
      } finally {
        // TODO evaluate if this is needed
        // start selection tracker
        ctx.VisualizerManager?.selection.debounceStartSelectionTracker();

        //? not needed for operations directly in json
        // WARN: TEMP
        // ctx.VisualizerManager?.getWidgetsManager()?.rebuildWidgets();
      }
    }

    return this;
  }
}
