import './PasteMarkerElement.module.scss';
import { ELEMENTS } from 'Editor/services/consts';
import EditorManager from 'Editor/services/EditorManager';
import { BaseViewElement } from '../..';

export class PasteMarkerElement extends BaseViewElement {
  connectedCallback() {
    super.connectedCallback();
    const markerPosition = this.getAttribute('markerposition');

    if (markerPosition === 'end') {
      EditorManager.getInstance().setPasteEndMarker(this);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    const markerPosition = this.getAttribute('markerposition');

    if (markerPosition === 'end') {
      //@ts-ignore
      EditorManager.getInstance().setPasteEndMarker(null);
    }
  }
}

if (!window.customElements.get(ELEMENTS.PasteMarkerElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.PasteMarkerElement.IDENTIFIER, PasteMarkerElement);
}
