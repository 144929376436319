import { InteractionController } from '_common/components';

import Language from './Language/Language';
import Zoom from './Zoom/Zoom';
import Divider from './Divider/Divider';
import Layout from './Layout/Layout';
import WordCount from './WordCount/WordCount';

import { Footer } from '_common/suite/components';

const EditorFooter = () => {
  return (
    <Footer>
      <Footer.Left>
        <InteractionController environment="editor">
          <WordCount />
          <Language />
        </InteractionController>
      </Footer.Left>
      <Footer.Right>
        <InteractionController environment="editor">
          <Layout />
          <Divider />
        </InteractionController>
        <Zoom />
      </Footer.Right>
    </Footer>
  );
};

export default EditorFooter;
