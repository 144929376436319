import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from './redux';
import { getGroupById } from 'App/redux/appSlice';

const usePublicGroup = (groupId: string, skipErrors?: number[]) => {
  const dispatch = useDispatch();

  const [removed, setRemoved] = useState(false);
  const group = useSelector((state) => state.app.data[groupId]);

  useEffect(() => {
    if (groupId && !group) {
      dispatch(getGroupById({ groupId, skipErrors })).then((resp) => {
        setRemoved(resp.meta.requestStatus === 'rejected');
      });
    }
  }, [groupId, group]);

  return { group, removed };
};

export default usePublicGroup;
