import { each } from 'lodash';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import DOMSanitizer from 'Editor/services/DOMUtilities/DOMSanitizer/DOMSanitizer';
import { BaseViewBuilder, BaseViewElement, TableElement } from '..';
import { ViewMapper } from '../../ViewMapper';

export class GenericViewBuilder extends BaseViewBuilder implements Editor.Visualizer.IViewbuilder {
  build(json: Editor.Data.Node.Data, model?: Editor.Data.Node.Model): HTMLElement {
    // generic builder

    const tagForElement = ViewMapper.getTagForElementType(
      json.type as Editor.Elements.ElementTypesType,
    );
    const node = DOMElementFactory.buildElement(tagForElement) as HTMLElement;

    // render attributes

    // check for legacy attributes
    const allowedProperties = DOMSanitizer.allowedAttributesForTag(tagForElement);

    each(json.properties, (value, key: string) => {
      if (this.GENERIC_ATTRIBUTE_MAPPER[key]) {
        this.GENERIC_ATTRIBUTE_MAPPER[key].render(json, node);
      } else if (allowedProperties?.includes(key)) {
        this.GENERIC_ATTRIBUTE_MAPPER.genericAttribute.render(json, node, model?.get(), key);
      }
    });

    this.GENERIC_ATTRIBUTE_MAPPER.id.render(json, node);
    this.GENERIC_ATTRIBUTE_MAPPER.parent_id.render(json, node);
    this.GENERIC_ATTRIBUTE_MAPPER.element_type.render(json, node);
    this.GENERIC_ATTRIBUTE_MAPPER.element_reference.render(json, node);
    this.GENERIC_ATTRIBUTE_MAPPER.status.render(json, node);

    this.GENERIC_ATTRIBUTE_MAPPER.section.render(json, node);

    if (node instanceof BaseViewElement || node instanceof TableElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }

  get attributeMapper() {
    return this.GENERIC_ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren() {
    return true;
  }
}
