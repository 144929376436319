import { ChangeEventHandler, useEffect, useState } from 'react';
import { Button, Modal, SectionHeader, TextArea } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks/redux';
import { setSearchMode, setSearchQuery } from 'Search/redux/SearchPageSlice';
import { closeAndResetModal } from '../ModalsSlice';

import { AdvancedFilterRow } from '_common/components';

import styles from './AdvancedSearchModal.module.scss';

const MODAL = 'AdvancedSearchModal';

const AdvancedSearchModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const initialQuery: string = useSelector((state) => state.modals[MODAL].initialQuery) || '';

  const [query, setQuery] = useState('');

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  if (!isOpen) {
    return null;
  }

  const close = () => {
    //Clean up local states since modal isn't unmounted
    setQuery('');

    dispatch(closeAndResetModal(MODAL));
  };

  const handleAddQuery = (newQuerySection: string, conditionType: string) => {
    setQuery((prevQuery) => {
      if (prevQuery) {
        if (conditionType === 'not') {
          return `${prevQuery} and ${conditionType} ${newQuerySection}`;
        } else {
          return `${prevQuery} ${conditionType} ${newQuerySection}`;
        }
      } else {
        if (conditionType === 'not') {
          return `${conditionType} ${newQuerySection}`;
        } else {
          return newQuerySection;
        }
      }
    });
  };

  const handleManualQueryChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmitQuery = () => {
    if (query) {
      dispatch(setSearchMode('advanced'));
      dispatch(setSearchQuery({ query }));
    }

    close();
  };

  return (
    <Modal width="140rem" open={isOpen} onClose={close} testId="advanced-search">
      <Modal.Header onClose={close}>
        <FormattedMessage id="ADVANCED_SEARCH" />
      </Modal.Header>
      <Modal.Body overflow="visible">
        <SectionHeader margin="0 0 1rem 0">
          <FormattedMessage id="SEARCH_FILTERS" />
        </SectionHeader>
        <AdvancedFilterRow
          type="query"
          onAddQuery={handleAddQuery}
          testId="advanced-search-modal"
        />

        <div className={styles.searchPreview}>
          <SectionHeader>
            <FormattedMessage id="SEARCH_PREVIEW" />
          </SectionHeader>
          <TextArea
            value={query}
            placeholder={intl.formatMessage({ id: 'SEARCH_QUERY_WILL_APPEAR_HERE' })}
            onChange={handleManualQueryChange}
            testId="advanced-search-modal-query-preview"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="advanced-search-modal-cancel">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleSubmitQuery}
          disabled={!query}
          testId="advanced-search-modal-query-search"
        >
          <FormattedMessage id="global.search" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdvancedSearchModal;
