import BaseController from '../BaseController';
const POLLING_INTERVAL = 10000; // ms
export class ProofingController extends BaseController {
  protected timer?: NodeJS.Timeout;

  constructor(Data: Editor.Data.State) {
    super(Data);
    this.getCountWords = this.getCountWords.bind(this);
  }

  start(documentId?: string, data?: Realtime.Core.Document.Data, user?: Realtime.Core.User): void {
    this.getCountWords();
    this.timer = setInterval(this.getCountWords, POLLING_INTERVAL);
  }

  stop(): void {}

  destroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  // subscriptions

  getCountAllElements() {
    return new Promise<Editor.Data.Proofing.FullSummary>((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'COUNT:ALL:ELEMENTS',
        {},
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  getCountSelectionElements(range: Editor.Selection.RangeData[]) {
    return new Promise<Editor.Data.Proofing.FullSummary>((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'COUNT:SELECTED:ELEMENTS',
        { range },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  getCountOutlineElements() {
    return new Promise<{ [index: string]: Editor.Data.Proofing.FullSummary }>((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'COUNT:OUTLINE:ELEMENTS',
        {},
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  getCountOutlineWords() {
    return new Promise<{ [index: string]: Editor.Data.Proofing.Summary }>((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'COUNT:OUTLINE:WORDS',
        {},
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  getCountWords(): Promise<Editor.Data.Proofing.Summary> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'COUNT:ALL:WORDS',
        {},
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            this.Data.events?.emit('UPDATE_WORD_COUNT', response.payload);
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }
}
