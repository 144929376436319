import { v4 as uuid } from 'uuid';
import { SuggestionData } from './Suggestion.types';
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { SuggestionDataPriority } from './Suggestion.types';
import { Reply } from '..';

export class Suggestion extends RealtimeObject<SuggestionData> {
  private _nodesPermissions: any;

  constructor(
    transport: Transport,
    id: Realtime.Core.RealtimeObjectId,
    undoManager?: Realtime.Core.UndoManager,
  ) {
    super(transport, id, 'trackedActions', undoManager);
    this._nodesPermissions = {};
  }

  set nodesPermissions(value: any) {
    this._nodesPermissions = value;
  }

  get nodesPermissions() {
    return this._nodesPermissions;
  }

  get accepted() {
    return this.selectedData()?.status === 'ACCEPTED';
  }

  get rejected() {
    return this.selectedData()?.status === 'REJECTED';
  }

  get votes() {
    return this.get(['votes']);
  }

  get comments() {
    return this.get(['comments']);
  }

  protected getUndoableOps(ops: Realtime.Core.RealtimeOps): Realtime.Core.RealtimeOps {
    return ops.filter((op) => {
      return op.p[0] === 'content';
    });
  }

  private getIndexOfReply(replyId: string) {
    return this.comments.findIndex((reply: Reply) => reply.id === replyId);
  }

  handleLoad(): void {}

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {}

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    this.emit('UPDATED', this.get([]), { op: ops, source });
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {}

  handleOperations(
    operations: Realtime.Core.RealtimeOps,
    source: boolean | Realtime.Core.RealtimeSourceType,
  ) {}

  isAuthor(userId: string) {
    const author = this.get(['author']);
    if (author) {
      return +author === +userId;
    }
    return false;
  }

  async updateSuggestionContent(
    content: SuggestionData['content'],
    options?: Realtime.Core.RealtimeSourceOptions,
  ) {
    if (!this.created) {
      return;
    }
    await this.set(['content'], content, options);
  }

  reply(reply: string, userId: string | null) {
    const id = uuid();
    return this.listInsert(['comments', this.comments.length], {
      id,
      content: reply,
      author: userId,
      time: new Date().toISOString(),
      votes: [],
    });
  }

  editReply(replyId: string, newContent: string) {
    const replyIndex = this.getIndexOfReply(replyId);
    return this.set(['comments', replyIndex, 'content'], newContent);
  }

  deleteReply(replyId: string) {
    const replyIndex = this.getIndexOfReply(replyId);
    return this.listDelete(['comments', replyIndex]);
  }

  changePriority(priority: SuggestionDataPriority) {
    return this.set(['priority'], priority);
  }

  vote(vote: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'TRACKED:ACTION:VOTE',
        {
          trackedActionId: this.id,
          vote,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  voteReply(replyId: string, vote: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'TRACKED:ACTION:REPLY:VOTE',
        {
          trackedActionId: this.id,
          replyId,
          vote,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  accept(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'TRACKED:ACTION:ACCEPT',
        {
          trackedActionId: this.id,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  reject(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'TRACKED:ACTION:REJECT',
        {
          trackedActionId: this.id,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }
}
