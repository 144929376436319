import { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState, ProgressBar } from 'dodoc-design-system';
import { Table } from '_common/components/Table2';

import { useDispatch, useSelector } from '_common/hooks';
import { navigateToEditor, navigateToMyFiles, navigateToObject } from 'router/history';

import { setCurrentAppPage } from 'App/redux/appSlice';
import {
  searchObjects,
  setAdvancedFilters,
  setInfoPanelOpen,
  setLoading,
  updateRequest,
} from 'Search/redux/SearchPageSlice';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import {
  ErrorView,
  FormattedDate,
  FormattedTime,
  InformationPanel,
  Status,
} from '_common/components';

import AdvancedSearchFilters from './AdvancedSearchFilters/AdvancedSearchFilters';
import SearchBreadcrumb from './SearchBreadcrumb/SearchBreadcrumb';

import styles from './SearchPage.module.scss';
import { ColumnProps, TableProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import Cell from '_common/components/Table2/Cells';
import MetadataCell from './Cells/MetadataCell';
import AuthorsCell from './Cells/AuthorsCell';
import ReferencePriorityCell from './Cells/ReferencePriorityCell';
import { setObjectPreview } from '_common/components/ObjectPreview/ObjectPreviewSlice';

export const pageIdentity = 'search';

const SearchPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { data: metadata } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });
  const data = useSelector((state) => state.app.data);
  const error = useSelector((state) => state.app.error);

  const list = useSelector((state) => state.table.identity.search.list);

  const parent = useSelector((state) => state.search.parent);
  const initialParent = useSelector((state) => state.search.initialParent);
  const searchLoading = useSelector((state) => state.search.loading);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const infoPanelOpen = useSelector((state) => state.search.infoPanelOpen);
  const activeTab = useSelector((state) => state.search.activeTab);

  const params = useSelector((state) => state.search.request.params);
  const isNextPageLoading = useSelector((state) => state.search.request.isNextPageLoading);
  const advancedFilters = useSelector((state) => state.search.advancedFilters);
  const advancedFiltersArray = useMemo(() => Object.values(advancedFilters), [advancedFilters]);
  const extraColumnsValues: string[] = [];

  const handleOrderUpdate = (newOrder: Request.OrderParams) => {
    dispatch(
      updateRequest({
        params: { ...params, order_field: newOrder.order_field, order_type: newOrder.order_type },
      }),
    );
  };

  const handleExtraColumns = () => {
    if (searchQuery) {
      ['creation', 'deadline', 'authors.name'].forEach((column) => {
        if (searchQuery.includes(column) && !extraColumnsValues.includes(column)) {
          extraColumnsValues.unshift(column === 'deadline' ? 'due' : column);
        }
      });
      if (metadata) {
        const metadataIds = Object.keys(metadata);
        metadataIds.forEach((metadataId) => {
          const name = `metadata.${metadata[metadataId].name.toLowerCase().replace(/ /, '_')}`;
          if (searchQuery.includes(name) && !extraColumnsValues.includes(name)) {
            extraColumnsValues.unshift(`metadata.${metadataId}`);
          }
        });
      }
      if (advancedFilters) {
        const filter = advancedFiltersArray.filter(
          (filter: { option: string }) => filter.option === 'reference_priority',
        );
        if (filter.length > 0) {
          filter.forEach((element) => {
            const name = `${element.option}.${element.value}.${element.auxValue ? 'is' : 'not'}`;
            if (!extraColumnsValues.includes(name)) {
              extraColumnsValues.unshift(name);
            }
          });
        }
      }
    }
  };

  handleExtraColumns();

  const commonColumns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'storage.browserHeader.name' }),
        width: 280,
        flex: true,
        frozen: true,
        orderable: true,
      },
      {
        id: 'time.modification',
        header: intl.formatMessage({ id: 'storage.browserHeader.dateModified' }),
        width: 160,
        flex: extraColumnsValues.length > 0 ? true : false,
        orderable: true,
        body: (data) => {
          return data['time.modification'];
        },
      },
      {
        id: 'status',
        header: intl.formatMessage({ id: 'storage.browserHeader.status' }),
        width: 160,
        flex: extraColumnsValues.length > 0 ? true : false,
        orderable: true,
      },
      {
        id: 'sharedwith',
        header: intl.formatMessage({ id: 'storage.browserHeader.sharedWith' }),
        width: 160,
        flex: extraColumnsValues.length > 0 ? true : false,
      },
    ];
  }, [extraColumnsValues]);

  const extraColumns = useMemo<ColumnProps[]>(() => {
    if (extraColumnsValues.length > 0) {
      return extraColumnsValues.map((extra: string) => {
        const columnType = extra.split('.');
        switch (columnType[0]) {
          case 'due':
            return {
              id: 'events.due',
              header: intl.formatMessage({ id: 'storage.actionBar.search.rules.filters.deadline' }),
              width: 200,
              flex: true,
              orderable: true,
              body: (data) => {
                return data['events.due'];
              },
            };
          case 'creation':
            return {
              id: 'time.creation',
              header: intl.formatMessage({ id: 'CREATION_DATE' }),
              width: 200,
              flex: true,
              orderable: true,
              body: (data) => {
                return data['time.creation'];
              },
            };
          case 'authors':
            return {
              id: 'authors',
              header: intl.formatMessage({ id: 'EDITOR_DOCUMENT_AUTHORS' }),
              width: 200,
              flex: true,
            };
          case 'metadata':
            const metadataId = columnType[1];
            return {
              id: metadata[metadataId].name.replaceAll(' ', '_').toLowerCase(),
              header: intl.formatMessage({ id: metadata[metadataId].name }),
              width: 200,
              flex: true,
            };
          case 'reference_priority':
            return {
              id: extra,
              header: intl.formatMessage({
                id: columnType[2] === 'is' ? 'REFERENCE_IN_DOCUMENT' : 'REFERENCE_IN_LIBRARY',
              }),
              width: 200,
              body: (data) => {
                return data[extra];
              },
              flex: true,
              orderable: true,
            };
          default:
            return {
              id: 'null',
              header: 'undefined',
              width: 200,
            };
        }
      });
    } else {
      return [
        {
          id: 'null',
          header: 'undefined',
          width: 200,
        },
      ];
    }
  }, [extraColumnsValues]);

  const columns = () => {
    if (extraColumnsValues.length > 0) {
      return commonColumns.concat(extraColumns.slice(0, 7));
    } else {
      return commonColumns;
    }
  };

  const referencePriorityValue = (object: Objekt, objectId: string) => {
    if (extraColumnsValues.filter((extra) => extra.includes('reference_priority')).length > 0) {
      return {
        'reference_priority.low.is': (
          <div data-testid={`${objectId}-low-reference-priority-in-document-column`}>
            <ReferencePriorityCell object={object} value={'low.is'} />
          </div>
        ),
        'reference_priority.medium.is': (
          <div data-testid={`${objectId}-medium-reference-priority-in-document-column`}>
            <ReferencePriorityCell object={object} value={'medium.is'} />
          </div>
        ),
        'reference_priority.high.is': (
          <div data-testid={`${objectId}-high-reference-priority-in-document-column`}>
            <ReferencePriorityCell object={object} value={'high.is'} />
          </div>
        ),
        'reference_priority.low.not': (
          <div data-testid={`${objectId}-low-reference-priority-in-library-column`}>
            <ReferencePriorityCell object={object} value={'low.not'} />
          </div>
        ),
        'reference_priority.medium.not': (
          <div data-testid={`${objectId}-medium-reference-priority-in-library-column`}>
            <ReferencePriorityCell object={object} value={'medium.not'} />
          </div>
        ),
        'reference_priority.high.not': (
          <div data-testid={`${objectId}-high-reference-priority-in-library-column`}>
            <ReferencePriorityCell object={object} value={'high.not'} />
          </div>
        ),
      };
    }
  };

  const metadataValue = (object: Objekt, objectId: string) => {
    if (extraColumnsValues.filter((extra) => extra.includes('metadata')).length > 0) {
      const metadataField = Object.entries(metadata).map(([id, value]) => value);
      return {
        response_type: (
          <div data-testid={`${objectId}-response-type-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Response Type')}
              metadata={metadata}
            />
          </div>
        ),
        category: (
          <div data-testid={`${objectId}-category-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Category')}
              metadata={metadata}
            />
          </div>
        ),
        content_name: (
          <div data-testid={`${objectId}-content-name-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Content name')}
              metadata={metadata}
            />
          </div>
        ),
        scientific_area: (
          <div data-testid={`${objectId}-scientific-area-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Scientific area')}
              metadata={metadata}
            />
          </div>
        ),
        indication: (
          <div data-testid={`${objectId}-indication-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Indication')}
              metadata={metadata}
            />
          </div>
        ),
        'product-molecule-ro#': (
          <div data-testid={`${objectId}-product-molecule-ro#-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Product-Molecule-RO#')}
              metadata={metadata}
            />
          </div>
        ),
        version_comment: (
          <div data-testid={`${objectId}-version-comment-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Version comment')}
              metadata={metadata}
            />
          </div>
        ),
        channel: (
          <div data-testid={`${objectId}-channel-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Channel')}
              metadata={metadata}
            />
          </div>
        ),
        change_type: (
          <div data-testid={`${objectId}-change-type-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Change Type')}
              metadata={metadata}
            />
          </div>
        ),
        library: (
          <div data-testid={`${objectId}-library-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Library')}
              metadata={metadata}
            />
          </div>
        ),
        content_file_type: (
          <div data-testid={`${objectId}-content-file-type-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Content file type')}
              metadata={metadata}
            />
          </div>
        ),
        content_status: (
          <div data-testid={`${objectId}content-status-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Content status')}
              metadata={metadata}
            />
          </div>
        ),
        content_author: (
          <div data-testid={`${objectId}-content-author-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Content author')}
              metadata={metadata}
            />
          </div>
        ),
        veeva_version: (
          <div data-testid={`${objectId}-veeva-version-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Veeva version')}
              metadata={metadata}
            />
          </div>
        ),
        published_date: (
          <div data-testid={`${objectId}-published-date-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Published Date')}
              metadata={metadata}
            />
          </div>
        ),
        content_expiration: (
          <div data-testid={`${objectId}-content-expiration-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Content expiration')}
              metadata={metadata}
            />
          </div>
        ),
        veeva_doc_number: (
          <div data-testid={`${objectId}-veeva-doc-number-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Veeva doc number')}
              metadata={metadata}
            />
          </div>
        ),
        gmr3_document: (
          <div data-testid={`${objectId}-gmr3-document-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'GMR3 document')}
              metadata={metadata}
            />
          </div>
        ),
        target_audience: (
          <div data-testid={`${objectId}-target-audience-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Target audience')}
              metadata={metadata}
            />
          </div>
        ),
        content_reviewer: (
          <div data-testid={`${objectId}-content-reviewer-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Content Reviewer')}
              metadata={metadata}
            />
          </div>
        ),
        assignee: (
          <div data-testid={`${objectId}-assignee-metadata-column`}>
            <MetadataCell
              object={object}
              id={metadataField.filter((value) => value.name === 'Assignee')}
              metadata={metadata}
            />
          </div>
        ),
      };
    }
    return null;
  };

  const value = useMemo(() => {
    return list.map((objectId) => {
      const object = data[objectId];
      return {
        ...metadataValue(object, objectId),
        ...referencePriorityValue(object, objectId),
        id: object.id,
        name: (
          <Cell testId={`${objectId}-name-column`} ellipsis noWidth>
            <Cell.ObjectName object={object} testId={`${objectId}-object-name`} />
          </Cell>
        ),
        'time.modification': (
          <div data-testid={`${objectId}-date-column`}>
            <FormattedDate date={object.time.modification} />{' '}
            <FormattedTime time={object.time.modification} />
          </div>
        ),
        status: (
          <div data-testid={`${objectId}-status-column`}>
            <Status id={object.status} fullWidth testId="status-label" />
          </div>
        ),
        sharedwith: (
          <div data-testid={`${objectId}-share-width-column`}>
            {object.shared_with?.length > 1 ? (
              <FormattedMessage
                values={{ members: object.shared_with?.length }}
                id="spaces.collaborators"
              />
            ) : (
              '-'
            )}
          </div>
        ),
        'time.creation': (
          <div data-testid={`${objectId}-creation-date-column`}>
            <FormattedDate date={object.time.creation} />
            <FormattedTime time={object.time.creation} />
          </div>
        ),
        'events.due': (
          <div data-testid={`${objectId}-deadline-column`}>
            <FormattedDate date={object.events.due} />
          </div>
        ),
        authors: (
          <div data-testid={`${objectId}-authors-column`}>
            <AuthorsCell object={object} />
          </div>
        ),
      };
    });
  }, [list, data, extraColumnsValues]);

  useEffect(() => {
    dispatch(setCurrentAppPage('/search'));
  }, []);

  useEffect(() => {
    const promise = triggerSearch();

    return () => {
      promise?.abort?.();
    };
  }, [searchQuery, activeTab, params.order_field, params.order_type]);

  // ------------------------------------------------------
  // ---------------------- Utils -------------------------
  const triggerSearch = (lazyload = false) => {
    if (lazyload && isNextPageLoading) {
      dispatch(setLoading(false));
      return null;
    }

    let tempQuery: string[] = [];
    if (searchQuery) {
      tempQuery.push(searchQuery);
    }
    if (activeTab === 'shared') {
      tempQuery.push(`share = "True"`);
    } else if (activeTab === 'parent' && initialParent) {
      tempQuery.push(`parent = "${initialParent.id}"`);
    }

    return dispatch(
      searchObjects({
        parent,
        query: tempQuery.join(' and '),
        params: { ...params, offset: lazyload ? params.offset : 0, lazyload },
      }),
    );
  };

  const checkErrorStatus = () => {
    return error.status === 400 || error.status === 403 || error.status === 404;
  };

  // ------------------------------------------------------
  // --------------------- Events -------------------------

  const toggleInfoPanel = () => {
    dispatch(setInfoPanelOpen(!infoPanelOpen));
  };

  const handleRowDoubleClick: TableProps<{ id: string }[]>['onRowDoubleClick'] = ({
    data: object,
    originalEvent,
  }) => {
    const currentObject = data[object.id];
    if (currentObject.status === 'processing' || currentObject.status === 'broken') {
      return;
    }

    const type = currentObject.type;
    switch (type) {
      case 'document':
        navigateToEditor(currentObject.id);
        break;
      case 'file':
        dispatch(setObjectPreview({ id: currentObject.id }));
        originalEvent.preventDefault();
        break;
      case 'folder':
        navigateToObject('folder', currentObject.id);
        break;
      case 'dopdf':
        window.open(`/pdf/${currentObject.id}`, '_blank');
        break;
      case 'presentation':
        window.open(`/presentation/${currentObject.id}`, '_blank');
        break;
      default:
        break;
    }
  };

  // ------------------------------------------------------
  // ---------------------- Render ------------------------
  const handleOnClickRemoveAllFilters = () => {
    dispatch(setAdvancedFilters({}));
  };

  const renderEmptyView = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', flex: 1 }}
        data-testid="search-empty"
      >
        <EmptyState
          size="large"
          icon="NoSearchResults"
          title={intl.formatMessage({ id: 'SEARCH_EMPTY_TITLE' })}
          onClick={handleOnClickRemoveAllFilters}
          footer={<FormattedMessage id="CLEAR_FILTERS" />}
          testId="search-page-empty-state"
        >
          {intl.formatMessage({ id: 'SEARCH_EMPTY_DESCRIPTION' })}
        </EmptyState>
      </div>
    );
  };
  //#endregion

  const renderContent = () => {
    if (searchLoading) {
      return (
        <div className={styles.loading}>
          <ProgressBar testId="search-loading-progressBar" />
          <div className={styles.message}>
            <FormattedMessage id="SEARCHING_FOR_RESULTS" />
          </div>
        </div>
      );
    }

    // check for error view
    if (checkErrorStatus()) {
      return <ErrorView error={error} onClick={() => navigateToMyFiles(false)} />;
    }

    return (
      <div className={styles.content} data-testid="search-content">
        <div className={styles.table} data-testid="search-table">
          <Table
            identity="search"
            columns={columns()}
            value={value}
            renderFooter={() => null}
            isLoading={isNextPageLoading}
            loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
            testId="search"
            renderEmptyState={renderEmptyView}
            onRowDoubleClick={handleRowDoubleClick}
            onOrder={handleOrderUpdate}
            order={{ order_field: params.order_field, order_type: params.order_type }}
          />
        </div>
        <InformationPanel isOpen={infoPanelOpen} onClose={toggleInfoPanel} />
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <SearchBreadcrumb />
      <AdvancedSearchFilters />
      {renderContent()}
    </div>
  );
};

export default SearchPage;
