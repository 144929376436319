export class ActionContext {
  event?: Event;
  actionType?: Editor.Edition.ActionType;
  range: Editor.Selection.JsonRange;
  baseModel?: Editor.Data.Node.Model;
  baseData?: Editor.Data.Node.Data;

  avoidNextNonCollapsedAction: boolean = false;

  // blockNode?: Node | null;
  suggestions: Editor.Edition.SuggestionToUpdate;

  constructor(
    range: Editor.Selection.JsonRange,
    baseModel?: Editor.Data.Node.Model,
    baseData?: Editor.Data.Node.Data,
    event?: Event,
    action?: Editor.Edition.ActionType,
  ) {
    this.baseModel = baseModel;
    this.baseData = baseData;
    this.range = range;
    this.event = event;

    if (action) {
      this.setActionType(action);
    }

    this.suggestions = {};
  }

  setRange(range: Editor.Selection.JsonRange) {
    this.range = range;
    // if (this.baseRange) {
    //   this.blockNode = this.baseRange.blockNode;
    // }
  }

  setModelAndData(baseModel: Editor.Data.Node.Model, baseData?: Editor.Data.Node.Data) {
    this.baseModel = baseModel;
    if (baseData) {
      this.baseData = baseData;
    } else {
      this.baseData = baseModel.selectedData() || undefined;
    }
  }

  setActionType(action: Editor.Edition.ActionType) {
    this.actionType = action;
  }

  addSuggestionToUpdate(
    refId: string,
    insertedContent?: Editor.Data.Suggestions.SuggestionContentData,
    deletedContent?: Editor.Data.Suggestions.SuggestionContentData,
    type?: Editor.Data.Suggestions.SuggestionDataType,
  ) {
    if (!this.suggestions[refId]) {
      this.suggestions[refId] = {
        type: 'INSERT',
      };
    }

    if (insertedContent) {
      this.suggestions[refId].insertedContent = insertedContent;
    }

    if (deletedContent) {
      this.suggestions[refId].deletedContent = deletedContent;
    }

    if (type) {
      this.suggestions[refId].type = type;
    } else {
      if (this.suggestions[refId].insertedContent && this.suggestions[refId].deletedContent) {
        this.suggestions[refId].type = 'REPLACE';
      } else if (this.suggestions[refId].insertedContent) {
        this.suggestions[refId].type = 'INSERT';
      } else if (this.suggestions[refId].deletedContent) {
        this.suggestions[refId].type = 'DELETE';
      }
    }
  }

  getSuggestionData(refId: string) {
    if (this.suggestions[refId]) {
      return this.suggestions[refId];
    } else {
      return undefined;
    }
  }
}
