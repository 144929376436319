import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from '_common/hooks/redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button } from 'dodoc-design-system';
import { parseMeasurement } from 'utils';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { getBulletList, getNumberedList, getOutlineList } from 'Editor/redux/listStylesSlice';

import { selectedMultilevelList } from 'Editor/redux/ToolbarSlice';

import EditorManager from 'Editor/services/EditorManager';
import { INDENT_TYPE } from 'Editor/services/consts';
import styles from './NewListModal.module.scss';

import { BULLET_SYMBOLS } from 'Editor/modals/NewListModal/consts';

import CustomizePane from 'Editor/modals/NewListModal/CustomizePane';
import FormattingPane from 'Editor/modals/NewListModal/FormattingPane/FormattingPane';
import { ColumnProps, TableProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import { AxiosResponse } from 'axios';
import { ThunksTable } from '_common/components/Table2';
import ParagraphStylesCell from './Cells/ParagraphStylesCell';
import { listObjects } from '_common/components/Table/TableSlice';
import Cell from '_common/components/Table2/Cells/Cell';
import NumberingUtils from 'Editor/services/_Common/NumberingUtils';
import Units from 'Editor/services/_Common/Units';
const MODAL = 'NewListModal';

export type ItemName =
  | 'default'
  | 'incl_prev_lvls'
  | 'level'
  | 'numbering_type'
  | 'paragraph_style'
  | 'type'
  | 'start_from'
  | 'char_before'
  | 'char_after'
  | 'indentation_left'
  | 'indentation_right'
  | 'special_indent'
  | 'special_indent_value';

type ListProps = {
  [key: string]: Editor.ListItem[];
};

const NewListModal = () => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const type = useSelector((state) => state.modals[MODAL].type);
  const customize = useSelector((state) => state.modals[MODAL].customize);
  const bulletLists = useSelector(getBulletList);
  const numberedLists = useSelector(getNumberedList);
  const selectedList = useSelector((state) => state.editor.toolbar.selectedList);
  const outlineLists = useSelector(getOutlineList);
  const selectedOutline = useSelector(selectedMultilevelList);
  const paragraphStyles = useSelector((state) => state.editor.styles);

  const NUMBERING_TYPES = [
    { value: NumberingUtils.TYPE.DECIMAL, label: intl.formatMessage({ id: 'DECIMAL' }) },
    {
      value: NumberingUtils.TYPE.LOWER_ALPHA,
      label: intl.formatMessage({ id: 'LOWER_ALPHA' }),
    },
    {
      value: NumberingUtils.TYPE.LOWER_ROMAN,
      label: intl.formatMessage({ id: 'LOWER_ROMAN' }),
    },
    {
      value: NumberingUtils.TYPE.ALPHA_DECIMAL,
      label: intl.formatMessage({ id: 'ALPHA_DECIMAL' }),
    },
    {
      value: NumberingUtils.TYPE.UPPER_ALPHA,
      label: intl.formatMessage({ id: 'UPPER_ALPHA' }),
    },
    {
      value: NumberingUtils.TYPE.UPPER_ROMAN,
      label: intl.formatMessage({ id: 'UPPER_ROMAN' }),
    },
    {
      value: NumberingUtils.TYPE.ORDINAL_NUMBER,
      label: intl.formatMessage({ id: 'ORDINAL_NUMBER' }),
    },
    {
      value: NumberingUtils.TYPE.ORDINAL_WORD,
      label: intl.formatMessage({ id: 'ORDINAL_WORD' }),
    },
    { value: NumberingUtils.TYPE.ONE_LEADING_ZERO, label: '01, 02, 03, ...' },
    { value: NumberingUtils.TYPE.TWO_LEADING_ZERO, label: '001, 002, 003, ...' },
    { value: NumberingUtils.TYPE.THREE_LEADING_ZERO, label: '0001, 0002, 0003, ...' },
    { value: NumberingUtils.TYPE.FOUR_LEADING_ZERO, label: '00001, 00002, 00003, ...' },
  ];

  const SPECIAL_INDENTATIONS = [
    { value: INDENT_TYPE.NONE, label: intl.formatMessage({ id: 'NONE' }) },
    {
      value: INDENT_TYPE.FIRST_LINE,
      label: intl.formatMessage({ id: 'FIRST_LINE' }),
    },
    {
      value: INDENT_TYPE.HANGING,
      label: intl.formatMessage({ id: 'HANGING' }),
    },
  ];

  const LIST_ITEMS: ListProps = {
    bullet: [
      {
        default: true,
        level: 0,
        numbering_type: { value: NumberingUtils.TYPE.BULLET },
        type: BULLET_SYMBOLS[0],
        char_before: '',
        char_after: '',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 1).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        level: 1,
        numbering_type: { value: NumberingUtils.TYPE.BULLET },
        type: BULLET_SYMBOLS[0],
        char_before: '',
        char_after: '',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 2).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        level: 2,
        numbering_type: { value: NumberingUtils.TYPE.BULLET },
        type: BULLET_SYMBOLS[0],
        char_before: '',
        char_after: '',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 3).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        level: 3,
        numbering_type: { value: NumberingUtils.TYPE.BULLET },
        type: BULLET_SYMBOLS[0],
        char_before: '',
        char_after: '',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 4).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        level: 4,
        numbering_type: { value: NumberingUtils.TYPE.BULLET },
        type: BULLET_SYMBOLS[0],
        char_before: '',
        char_after: '',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 5).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        level: 5,
        numbering_type: { value: NumberingUtils.TYPE.BULLET },
        type: BULLET_SYMBOLS[0],
        char_before: '',
        char_after: '',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 6).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        level: 6,
        numbering_type: { value: NumberingUtils.TYPE.BULLET },
        type: BULLET_SYMBOLS[0],
        char_before: '',
        char_after: '',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 7).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        level: 7,
        numbering_type: { value: NumberingUtils.TYPE.BULLET },
        type: BULLET_SYMBOLS[0],
        char_before: '',
        char_after: '',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 8).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        level: 8,
        numbering_type: { value: NumberingUtils.TYPE.BULLET },
        type: BULLET_SYMBOLS[0],
        char_before: '',
        char_after: '',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 9).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
    ],
    numbered: [
      {
        default: true,
        incl_prev_lvls: false,
        level: 0,
        numbering_type: { value: NumberingUtils.TYPE.DECIMAL },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 1).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 1,
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ALPHA },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 2).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 2,
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ROMAN },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 3).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 3,
        numbering_type: { value: NumberingUtils.TYPE.DECIMAL },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 4).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 4,
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ALPHA },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 5).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 5,
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ROMAN },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 6).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 6,
        numbering_type: { value: NumberingUtils.TYPE.DECIMAL },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 7).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 7,
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ALPHA },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 8).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 8,
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ROMAN },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 9).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
    ],
    outline_numbered: [
      {
        default: true,
        incl_prev_lvls: false,
        level: 0,
        paragraph_style: { value: 'dodoc-main-title' },
        numbering_type: { value: NumberingUtils.TYPE.DECIMAL },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 1).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 1,
        paragraph_style: { value: 'dodoc-paragraph' },
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ALPHA },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 2).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 2,
        paragraph_style: null,
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ROMAN },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 3).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 3,
        paragraph_style: null,
        numbering_type: { value: NumberingUtils.TYPE.DECIMAL },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 4).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 4,
        paragraph_style: null,
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ALPHA },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 5).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 5,
        paragraph_style: null,
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ROMAN },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 6).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 6,
        paragraph_style: null,
        numbering_type: { value: NumberingUtils.TYPE.DECIMAL },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 7).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 7,
        paragraph_style: null,
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ALPHA },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 8).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
      {
        default: true,
        incl_prev_lvls: false,
        level: 8,
        paragraph_style: null,
        numbering_type: { value: NumberingUtils.TYPE.LOWER_ROMAN },
        start_from: 1,
        char_before: '',
        char_after: '.',
        indentation_left: `${Units.convert(Units.TYPE.INDENT_LEVEL, Units.TYPE.CM, 9).toFixed(
          2,
        )} cm`,
        indentation_right: '0 cm',
        special_indent: { value: INDENT_TYPE.NONE },
        special_indent_value: '',
      },
    ],
  };

  const getListType = () => {
    if (type === 'outline_numbered') {
      return outlineLists;
    }
    if (type === 'bullet') {
      return bulletLists;
    }
    return numberedLists;
  };

  const getSelectedItem = (listKeys: string[]) => {
    let selected = null;
    if (type === 'outline_numbered' && listKeys.includes(selectedList)) {
      selected = selectedList;
    } else if (listKeys.includes(selectedList)) {
      selected = selectedList;
    }

    return selected || listKeys[0];
  };

  const [currentList, setCurrentList] = useState<ListProps>({});
  const [listKeys, setListKeys] = useState<string[]>([]);

  const [listItems, setListItems] = useState<Editor.ListItem[]>([]);
  const [selectedPreview, setSelectedPreview] = useState<string | null>(null);
  const [selectedLine, setSelectedLine] = useState(0);

  const handleIdentity = () => {
    switch (type) {
      case 'bullet':
        return 'bullet_list';
      case 'numbered':
        return 'numbered_list';
      case 'outline_numbered':
        return 'outline_list';
      default:
        return 'bullet_list';
    }
  };

  const fetchObjects = useCallback(async () => {
    return new Promise<AxiosResponse<unknown>>((resolve) => {
      resolve({
        data: { nodes: listItems },
        status: 200,
        statusText: '',
        headers: { placeholder: '' },
        config: {},
      });
    });
  }, [listItems]);

  useEffect(() => {
    dispatch(
      listObjects({
        identity: handleIdentity(),
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  useEffect(() => {
    if (isOpen) {
      setCurrentList(getListType());
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setListKeys(Object.keys(currentList));
    }
  }, [currentList, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setSelectedPreview(getSelectedItem(listKeys));

      let previousList: Editor.ListItem[] = [];

      if (listKeys.length > 0) {
        const item = currentList[getSelectedItem(listKeys)];
        if (type === 'outline_numbered' && selectedOutline) {
          previousList = LIST_ITEMS[type].map((listItem, index) => {
            const outlineLevel = item?.find((definition) => definition.level === index);
            return outlineLevel || listItem;
          });
        } else {
          previousList = item;
        }
      }

      setListItems(customize ? previousList : LIST_ITEMS[type]);
    }
  }, [listKeys, isOpen]);

  const handleSetListItems = (
    values: { value: string; label: string } | number | boolean | string | null,
    key: ItemName,
  ) => {
    const newList = [...listItems];
    let list = newList;
    if (values !== newList[selectedLine][key]) {
      const value = { ...newList[selectedLine] };
      value.default = false;
      //@ts-expect-error values can be of type string | number | boolean | null | {value: string}
      value[key] = values;
      //@ts-expect-error values can be of type string | number | boolean | null | {value: string}
      if (key === 'special_indent' && values.value === INDENT_TYPE.NONE) {
        value.special_indent_value = null;
      }
      list[selectedLine] = value;
      setListItems(list);
    }
  };

  const columns = useMemo<ColumnProps[]>(() => {
    switch (type) {
      case 'bullet':
        return [
          {
            id: 'level',
            header: intl.formatMessage({ id: 'LEVEL' }),
            width: 88,
            flex: true,
          },
          {
            id: 'bullet_type',
            header: intl.formatMessage({ id: 'BULLET_TYPE' }),
            width: 280,
          },
        ];
      case 'numbered':
        return [
          {
            id: 'level',
            header: intl.formatMessage({ id: 'LEVEL' }),
            width: 88,
            flex: true,
          },
          {
            id: 'numbering_type',
            header: intl.formatMessage({ id: 'NUMBERING_TYPE' }),
            width: 280,
          },
        ];
      case 'outline_numbered':
        return [
          {
            id: 'level',
            header: intl.formatMessage({ id: 'LEVEL' }),
            width: 80,
            flex: true,
          },
          {
            id: 'paragraph_styles',
            header: intl.formatMessage({ id: 'PARAGRAPH_STYLES' }),
            width: 192,
          },
          {
            id: 'numbering_type',
            header: intl.formatMessage({ id: 'NUMBERING_TYPE' }),
            width: 192,
          },
        ];
      default:
        return [];
    }
  }, []);

  const applyStylesToAll = () => {
    const newList = [...listItems];
    const currItem = newList[selectedLine];

    newList.forEach((element) => {
      if (element.level !== currItem.level) {
        element.char_before = currItem.char_before;
        element.char_after = currItem.char_after;
      }
    });
    setListItems(newList);
  };

  const close = () => {
    setCurrentList({});
    setListKeys([]);
    setListItems([]);
    setSelectedPreview(null);
    setSelectedLine(0);
    dispatch(closeAndResetModal(MODAL));
  };

  const parseData = (listItems: Editor.ListItem[]) => {
    const data = listItems.reduce((acc: Editor.ListItem[], item: Editor.ListItem) => {
      const newItem = { ...item };
      let specialVal;
      let leftIndentation;
      let rightIndentation;
      if (item.special_indent_value) {
        specialVal = parseMeasurement(item.special_indent_value, 'pt');
      }

      newItem.start_from = Number(newItem.start_from)
        ? newItem.start_from
        : NumberingUtils.fromRepresentation(newItem.numbering_type?.value, newItem.start_from);
      leftIndentation = parseMeasurement(item.indentation_left, 'pt');
      rightIndentation = parseMeasurement(item.indentation_right, 'pt');
      newItem.indentation_left = leftIndentation != null ? leftIndentation.toString() : '';
      newItem.indentation_right = rightIndentation != null ? rightIndentation.toString() : '';

      newItem.special_indent = item.special_indent;
      newItem.special_indent_value = specialVal != null ? specialVal.toString() : null;
      return [...acc, newItem];
    }, []);

    return data;
  };

  const handleListAction = () => {
    const parsedList = parseData(listItems);
    if (type === 'outline_numbered') {
      if (customize) {
        if (selectedPreview) {
          EditorManager.getInstance().updateListStyle(selectedPreview, parsedList);
        }
      } else {
        EditorManager.getInstance().createListStyle(parsedList);
      }
    } else if (customize) {
      if (selectedPreview) {
        EditorManager.getInstance().updateListStyle(selectedPreview, parsedList);
      }
    } else {
      EditorManager.getInstance().createListStyle(parsedList);
    }
    close();
  };

  const getAllParagraphStyles = () => {
    const allParagraphStyles = paragraphStyles.list.map((element) => {
      return {
        value: paragraphStyles.data[element].id,
        label: paragraphStyles.data[element].n,
      };
    });

    return allParagraphStyles;
  };

  const getLabels = (l: string, v: string) => {
    const list: {
      [key: string]: {
        value: string;
        label: string;
      }[];
    } = {
      numberingTypes: NUMBERING_TYPES,
      paragraphStyles: getAllParagraphStyles(),
      specialIndentations: SPECIAL_INDENTATIONS,
    };

    for (let index = 0; index < list[l].length; index++) {
      const element = list[l][index];
      if (element.value === v) return element;
    }

    return list[0] === undefined ? null : list[0];
  };

  const handleChangeSelectedPreview = (element: string) => {
    if (element !== selectedPreview) {
      let newSelectedList = [];
      const currentList = getListType();
      const item = currentList[element];

      if (type === 'outline_numbered') {
        newSelectedList = LIST_ITEMS[type].map((listItem, index) => {
          const outlineLevel = item.find(
            (definition: { level: number }) => definition.level === index,
          );
          return outlineLevel || listItem;
        });
      } else {
        newSelectedList = item;
      }

      setSelectedPreview(element);
      setListItems(newSelectedList);
      setSelectedLine(0);
    }
  };

  const modalSize = () => {
    if (customize) {
      return '125.125rem';
    } else return '120rem';
  };

  const value = useMemo(() => {
    switch (type) {
      case 'bullet':
        return listItems.map((item) => {
          const itemTypeValue = Number(item.type);
          const itemType = `${item.char_before || ''}${String.fromCharCode(itemTypeValue)}${
            item.char_after || ''
          }`;
          const itemLevel = item.level + 1;
          return {
            id: `${item.level}`,
            level: <Cell testId={`${itemLevel}-level-column`}>{itemLevel}</Cell>,
            bullet_type: (
              <Cell testId={`${itemLevel}-bullet-type-column`}>
                <div style={{ fontFamily: item.font_family || '' }} className={styles.type}>
                  {itemType || (
                    <div className={styles.notDefined}>
                      <FormattedMessage id="global.notDefined" />
                    </div>
                  )}
                </div>
              </Cell>
            ),
          };
        });
      case 'numbered':
        return listItems.map((item) => {
          const itemType = `${item.char_before || ''}${NumberingUtils.represent(
            item.numbering_type,
            item.start_from,
          )}${item.char_after || ''}`;
          const itemLevel = item.level + 1;
          return {
            id: `${item.level}`,
            level: <Cell testId={`${itemLevel}-level-column`}>{itemLevel}</Cell>,
            numbering_type: (
              <Cell testId={`${itemLevel}-numbering-type-column`}>
                <div style={{ fontFamily: item.font_family || '' }} className={styles.type}>
                  {itemType || (
                    <div className={styles.notDefined}>
                      <FormattedMessage id="global.notDefined" />
                    </div>
                  )}
                </div>
              </Cell>
            ),
          };
        });
      case 'outline_numbered':
        return listItems.map((item) => {
          const itemType = `${item.char_before || ''}${NumberingUtils.represent(
            item.numbering_type,
            item.start_from,
          )}${item.char_after || ''}`;
          const itemLevel = item.level + 1;
          return {
            id: `${item.level}`,
            level: <Cell testId={`${itemLevel}-level-column`}>{itemLevel}</Cell>,
            paragraph_styles: (
              <Cell testId={`${itemLevel}-paragraph-styles-column`}>
                <ParagraphStylesCell
                  item={item}
                  listItems={listItems}
                  selectedLine={selectedLine}
                  type={type}
                  setSelectedLine={setSelectedLine}
                  getLabels={getLabels}
                  paragraphStyles={getAllParagraphStyles()}
                  setListItems={handleSetListItems}
                />
              </Cell>
            ),
            numbering_type: (
              <Cell testId={`${itemLevel}-numbering-type-column`}>
                <div style={{ fontFamily: item.font_family || '' }} className={styles.type}>
                  {itemType || (
                    <div className={styles.notDefined}>
                      <FormattedMessage id="global.notDefined" />
                    </div>
                  )}
                </div>
              </Cell>
            ),
          };
        });
      default:
        return listItems.map((item) => {
          return {
            id: `${item.level}`,
          };
        });
    }
  }, [listItems, selectedLine]);

  const handleRowClick: TableProps<{ id: string }[]>['onRowClick'] = (element) => {
    setSelectedLine(element.index);
  };

  if (!isOpen || listItems?.length < 1) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={close} width={modalSize()} testId="list-modal">
      <Modal.Header onClose={close}>
        <FormattedMessage
          id={customize ? `CUSTOMIZE_${type.toUpperCase()}_LIST` : `NEW_${type.toUpperCase()}_LIST`}
        />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.container}>
          {customize && (
            <CustomizePane
              list={getListType()}
              listItems={listItems}
              selectedPreview={selectedPreview}
              handleChangeSelectedPreview={handleChangeSelectedPreview}
            />
          )}
          <ThunksTable
            identity={handleIdentity()}
            fetchObjects={fetchObjects}
            columns={columns}
            value={value}
            testId={handleIdentity()}
            loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
            renderFooter={() => null}
            selectable={false}
            onRowClick={handleRowClick}
            selection={[{ id: `${selectedLine}` }]}
          />
          <FormattingPane
            type={type}
            listItems={listItems}
            setListItems={handleSetListItems}
            selectedLine={selectedLine}
            applyStylesToAll={applyStylesToAll}
            getLabels={getLabels}
            specialIndentations={SPECIAL_INDENTATIONS}
            numberingTypes={NUMBERING_TYPES}
            customize={customize}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="list-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleListAction}
          testId="list-modal-submit-button"
        >
          <FormattedMessage id={customize ? 'MODIFY_LIST' : 'global.create'} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewListModal;
