import { Select, Slider } from 'dodoc-design-system';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from '_common/hooks';

type ZoomControlsProps = {
  zoom: number;
  changeZoom: (newZoom: number) => void;
};

const ZOOM_SELECT_OPTIONS = [
  { label: '10%', value: '0.1' },
  { label: '25%', value: '0.25' },
  { label: '50%', value: '0.5' },
  { label: '75%', value: '0.75' },
  { label: '100%', value: '1' },
  { label: '125%', value: '1.25' },
  { label: '150%', value: '1.5' },
  { label: '200%', value: '2' },
  { label: '400%', value: '4' },
  { label: '800%', value: '8' },
  { label: '1600%', value: '16' },
  { label: '2400%', value: '24' },
  { label: '3200%', value: '32' },
  { label: '6400%', value: '64' },
];

const ZoomControls = ({ zoom, changeZoom }: ZoomControlsProps) => {
  const zoomSelectValue = useMemo(() => {
    return { label: `${(zoom * 100).toFixed(0)}%`, value: `${zoom}` };
  }, [zoom]);

  const [localZoomValue, setLocalZoomValue] = useState(1);
  const [newZoom, setNewZoom] = useState('');
  const debouncedZoom = useDebounce(localZoomValue, 500);

  useEffect(() => {
    setLocalZoomValue(zoom);
  }, [zoom]);

  useEffect(() => {
    changeZoom(debouncedZoom);
  }, [debouncedZoom]);

  return (
    <>
      <Slider
        variant="discrete"
        min={10}
        middle={100}
        max={6400}
        step={5.5555}
        value={Number((zoom * 100).toFixed(0))}
        onChange={(value) => {
          setLocalZoomValue(value / 100);
        }}
        rangeValues={false}
        testId="footer-zoom"
      />
      <div style={{ marginLeft: '2rem' }}>
        <Select
          clearable={false}
          creatable
          size="medium"
          options={ZOOM_SELECT_OPTIONS}
          onChange={({ value }, meta) => {
            if (meta?.action === 'create-option') {
              const zoom = +value / 100;
              changeZoom(+zoom);
            } else {
              changeZoom(+value);
            }
          }}
          value={
            ZOOM_SELECT_OPTIONS.find(
              (defualtValue) => defualtValue.value === zoomSelectValue.value,
            ) || zoomSelectValue
          }
          menuPlacement="top"
          menuPosition="fixed"
          onInputChange={(e: string) => {
            setNewZoom(e);
          }}
          inputValue={newZoom}
          formatCreateLabel={(label) => (label.endsWith('%') ? label : `${label}%`)}
          isValidNewOption={(option) =>
            !!option &&
            ZOOM_SELECT_OPTIONS.every((zoomOption) => `${+zoomOption.value * 100}` !== option)
          }
          customStyles={{
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 1052,
            }),
          }}
          testId="zoom"
        />
      </div>
    </>
  );
};

export default ZoomControls;
