import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';
import UploadManager from 'Editor/services/UploadManager/UploadManager';

export abstract class Command implements Editor.Edition.ICommand {
  protected debug: boolean = true;

  context: Editor.Edition.Context;

  constructor(context: Editor.Edition.Context) {
    this.context = context;
  }

  // protected abstract handleCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean;
  // protected abstract handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean;

  abstract exec(...args: any[]): Promise<Editor.Edition.ICommand>;

  protected handleSuggestionsUpdate(ctx: Editor.Edition.ActionContext) {
    if (!this.context.DataManager?.suggestions) {
      return;
    }
    const refIds = Object.keys(ctx.suggestions);

    for (let i = 0; i < refIds.length; i++) {
      const suggestionData = ctx.getSuggestionData(refIds[i]);
      if (suggestionData) {
        if (this.context.DataManager.suggestions.isSuggestionCreated(refIds[i])) {
          // update suggestion
          this.context.DataManager.suggestions.updateSuggestionContent(refIds[i], {
            inserted: suggestionData.insertedContent || { type: null, value: null },
            deleted: suggestionData.deletedContent || { type: null, value: null },
          });
        } else {
          // create suggestion
          this.context.DataManager.suggestions.addTrackedAction(
            suggestionData.type,
            suggestionData.insertedContent || { type: null, value: null },
            suggestionData.deletedContent || { type: null, value: null },
            [],
            refIds[i],
          );
        }
      }
    }
  }

  protected uploadImage(
    image: File,
    callback: Parameters<typeof ReduxInterface.saveImage>[0]['callback'],
  ) {
    if (this.context.DataManager && UploadManager.acceptImageFileSize(image)) {
      const documentId = this.context.DataManager.document.getDocumentId();

      if (documentId) {
        ReduxInterface.saveImage({
          params: {
            id: documentId,
            image,
          },
          callback,
        });
      }
    } else {
      throw new Error('Invalid image size!');
    }
  }
}
