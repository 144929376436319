import ViewModelValidations from 'Editor/services/VisualizerManager/ViewModels/ViewModelValidations';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { CharacterPositionIterator } from '../../../Iterators';
import { JsonRange } from '../../../JsonRange';
import { BaseModifier } from '../BaseModifier';

export class ExpandCharacterModifier extends BaseModifier {
  protected incrementeOffset?: number;

  constructor(
    Data: Editor.Data.API,
    direction: Editor.Selection.ModifierDirection,
    incrementOffset?: number,
  ) {
    super(Data, 'expand', 'character', direction);

    this.incrementeOffset = incrementOffset;
  }

  private expandCharacterForward(range: Editor.Selection.EditorRange) {
    const modifiersData: Editor.Data.Selection.Modifiers = this.Data.selection?.modifiersData || {};

    modifiersData.direction = this.direction;

    //check modifers data
    if (range.collapsed) {
      modifiersData.expandingDirection = null;
      modifiersData.cellSelection = false;
    } else if (!modifiersData.px) {
      const clientRects = range.getClientRects();
      if (modifiersData.expandingDirection === 'forward') {
        modifiersData.px =
          clientRects.length > 0 ? clientRects[clientRects.length - 1].right : null;
      } else if (modifiersData?.expandingDirection === 'backward') {
        modifiersData.px = clientRects.length > 0 ? clientRects[0].left : null;
      }
    }

    if (modifiersData?.expandingDirection === 'backward') {
      const container = range.startContainer;
      const blockNode = EditorDOMUtils.findFirstLevelChildNode(
        EditorDOMUtils.getContentContainer(container),
        container,
      ) as Editor.Visualizer.BaseView;

      const blockViewModel = blockNode.vm;

      const textElement = EditorDOMUtils.getSelectableElementFromBlock(blockNode, container);

      if (blockNode && range.isAtNodeEnd(blockNode, 'start')) {
        const nextSibling = EditorDOMUtils.getNextSelectableElement(
          blockNode,
          textElement,
          container,
        );
        if (nextSibling) {
          range.setStart(nextSibling, 0);
        }
      } else if (ViewModelValidations.isBlockViewModel(blockViewModel)) {
        const jsonRange = JsonRange.buildFromDOMRange(range);

        if (jsonRange) {
          const nodeData = this.Data.nodes.getNodeModelById(jsonRange.start.b);

          if (nodeData?.navigationData) {
            const iterator = new CharacterPositionIterator(
              nodeData.navigationData,
              jsonRange.start.p,
              this.incrementeOffset,
            );
            let nextPath = iterator.next();

            if (nextPath) {
              jsonRange.start.p = nextPath;

              range.updateFromJsonRange(jsonRange);
            } else {
              throw new Error('Invalid position!');
            }
          }
        }
      }

      // update range modifiers
      const clientRects = range.getClientRects();
      if (clientRects.length > 0) {
        modifiersData.px = clientRects[0].left;
      }
      this.Data.selection?.updateModifiers(modifiersData);
    } else {
      modifiersData.expandingDirection = 'forward';

      const container = range.endContainer;
      const blockNode = EditorDOMUtils.findFirstLevelChildNode(
        EditorDOMUtils.getContentContainer(container),
        container,
      ) as Editor.Visualizer.BaseView;

      const blockViewModel = blockNode.vm;

      const textElement = EditorDOMUtils.getSelectableElementFromBlock(blockNode, container);

      if (blockNode && range.isAtNodeEnd(blockNode, 'end')) {
        const nextSibling = EditorDOMUtils.getNextSelectableElement(
          blockNode,
          textElement,
          container,
        );
        if (nextSibling) {
          range.setEnd(nextSibling, 0);
        }
      } else if (ViewModelValidations.isBlockViewModel(blockViewModel)) {
        const jsonRange = JsonRange.buildFromDOMRange(range);

        if (jsonRange) {
          const nodeData = this.Data.nodes.getNodeModelById(jsonRange.end.b);

          if (nodeData?.navigationData) {
            const iterator = new CharacterPositionIterator(
              nodeData.navigationData,
              jsonRange.end.p,
              this.incrementeOffset,
            );
            let nextPath = iterator.next();

            if (nextPath) {
              jsonRange.end.p = nextPath;
              range.updateFromJsonRange(jsonRange);
            } else {
              throw new Error('Invalid position!');
            }
          }
        }
      }

      // update range modifiers
      const clientRects = range.getClientRects();
      if (clientRects.length > 0) {
        modifiersData.px = clientRects[clientRects.length - 1].right;
      }
      this.Data.selection?.updateModifiers(modifiersData);
    }
  }

  private expandCharacterBackward(range: Editor.Selection.EditorRange) {
    const modifiersData: Editor.Data.Selection.Modifiers = this.Data.selection?.modifiersData || {};

    modifiersData.direction = this.direction;

    //check modifers data
    if (range.collapsed) {
      modifiersData.expandingDirection = null;
      modifiersData.cellSelection = false;
    } else if (!modifiersData?.px) {
      const clientRects = range.getClientRects();
      if (modifiersData?.expandingDirection === 'forward') {
        modifiersData.px =
          clientRects.length > 0 ? clientRects[clientRects.length - 1].right : null;
      } else if (modifiersData?.expandingDirection === 'backward') {
        modifiersData.px = clientRects.length > 0 ? clientRects[0].left : null;
      }
    }

    if (modifiersData?.expandingDirection === 'forward') {
      const container = range.endContainer;

      const blockNode = EditorDOMUtils.findFirstLevelChildNode(
        EditorDOMUtils.getContentContainer(container),
        container,
      ) as Editor.Visualizer.BaseView;

      const blockViewModel = blockNode.vm;

      const textElement = EditorDOMUtils.getSelectableElementFromBlock(blockNode, container);

      if (blockNode && range.isAtNodeStart(blockNode, 'end')) {
        const previousSibling = EditorDOMUtils.getPreviousSelectableElement(
          blockNode,
          textElement,
          container,
        );
        if (previousSibling) {
          range.setEnd(previousSibling, previousSibling.childNodes.length);
        }
      } else if (ViewModelValidations.isBlockViewModel(blockViewModel)) {
        const jsonRange = JsonRange.buildFromDOMRange(range);

        if (jsonRange) {
          const nodeData = this.Data.nodes.getNodeModelById(jsonRange.end.b);

          if (nodeData?.navigationData) {
            const iterator = new CharacterPositionIterator(
              nodeData.navigationData,
              jsonRange.end.p,
            );
            let nextPath = iterator.previous();

            if (nextPath) {
              jsonRange.end.p = nextPath;

              range.updateFromJsonRange(jsonRange);
            } else {
              throw new Error('Invalid position!');
            }
          }
        }
      }

      // update range modifiers
      const clientRects = range.getClientRects();
      if (clientRects.length > 0) {
        modifiersData.px = clientRects[clientRects.length - 1].right;
      }
      this.Data.selection?.updateModifiers(modifiersData);
    } else {
      modifiersData.expandingDirection = 'backward';

      const container = range.startContainer;
      const blockNode = EditorDOMUtils.findFirstLevelChildNode(
        EditorDOMUtils.getContentContainer(container),
        container,
      ) as Editor.Visualizer.BaseView;

      const blockViewModel = blockNode.vm;

      const textElement = EditorDOMUtils.getSelectableElementFromBlock(blockNode, container);

      if (blockNode && range.isAtNodeStart(blockNode, 'start')) {
        const previousSibling = EditorDOMUtils.getPreviousSelectableElement(
          blockNode,
          textElement,
          container,
        );
        if (previousSibling) {
          if (
            previousSibling.lastChild &&
            EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(
              previousSibling.lastChild.nodeName,
            )
          ) {
            range.setStart(previousSibling, previousSibling.childNodes.length - 1);
          } else {
            range.setStart(previousSibling, previousSibling.childNodes.length);
          }
        }
      } else if (ViewModelValidations.isBlockViewModel(blockViewModel)) {
        const jsonRange = JsonRange.buildFromDOMRange(range);

        if (jsonRange) {
          const nodeData = this.Data.nodes.getNodeModelById(jsonRange.start.b);

          if (nodeData?.navigationData) {
            const iterator = new CharacterPositionIterator(
              nodeData.navigationData,
              jsonRange.start.p,
            );
            let nextPath = iterator.previous();
            if (nextPath) {
              jsonRange.start.p = nextPath;

              range.updateFromJsonRange(jsonRange);
            } else {
              throw new Error('Invalid position!');
            }
          }
        }
      }

      // update range modifiers
      const clientRects = range.getClientRects();
      if (clientRects.length > 0) {
        modifiersData.px = clientRects[0].left;
      }
      this.Data.selection?.updateModifiers(modifiersData);
    }
  }

  visitDoDOCRange(range: Editor.Selection.EditorRange): void {
    switch (this.direction) {
      case 'forward':
        return this.expandCharacterForward(range);
      case 'backward':
        return this.expandCharacterBackward(range);
      default:
        return;
    }
  }
  visitJsonRange(range: Editor.Selection.JsonRange): void {
    const editorRange = range.serializeToDOMRange();
    this.visitDoDOCRange(editorRange);
    range.updateFromDOMRange(editorRange);
  }
}
