import { useEffect, useState } from 'react';
import { Pulse } from 'dodoc-design-system';

type Position = {
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
};

type OnboardingPulseWidgetViewProps = {
  pulsePosition?: Position;
  testId?: string;
};

const OnboardingPulseWidgetView = ({ pulsePosition, testId }: OnboardingPulseWidgetViewProps) => {
  const [position, setPosition] = useState<Position>({
    left: `0px`,
    top: `0px`,
    ...pulsePosition,
  });

  useEffect(() => {
    if (pulsePosition) {
      setPosition(pulsePosition);
    }
  }, [pulsePosition]);

  return <Pulse position={position} testId={testId ?? 'pulse'} />;
};

export default OnboardingPulseWidgetView;
