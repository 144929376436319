import { Command } from '../Command';

export class DeleteCommand extends Command {
  private event: KeyboardEvent;

  constructor(context: Editor.Edition.ICommandArgs, event: KeyboardEvent) {
    super(context);
    this.event = event;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    throw new Error('Method not implemented.');
  }
}
