import { Logger } from '_common/services';
import { ContentManipulator } from '../ContentManipulator';
import { InsertElementOperation, InsertTextOperation } from '../../Operations';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export class SuggestionManipulator extends ContentManipulator {
  private insertTextIntoTrackedElement(
    ctx: Editor.Edition.ActionContext,
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    text: string,
    refId: string,
  ): Editor.Edition.IOperationBuilder | null {
    let operation: Editor.Edition.IOperationBuilder | null = null;

    operation = new InsertTextOperation(baseModel, path, text).apply();

    // TODO: create function to get text data

    // add suggestions for update
    ctx.addSuggestionToUpdate(refId, { type: 'text', value: text });

    return operation;
  }

  private insertNewTrackedElement(
    ctx: Editor.Edition.ActionContext,
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    text: string,
    refId?: string,
  ): Editor.Edition.IOperationBuilder | null {
    if (!this.editionContext.DataManager) {
      return null;
    }

    let operation: Editor.Edition.IOperationBuilder | null = null;

    if (!refId) {
      refId = EditorDOMUtils.generateRandomNodeId();
    }

    const trackedBuilder = new NodeDataBuilder(ELEMENTS.TrackInsertElement.ELEMENT_TYPE)
      .addProperty('element_reference', refId)
      .addProperty('author', this.editionContext.DataManager.users.loggedUserId)
      .addChildData(
        NodeDataBuilder.build({
          type: 'text',
          content: text,
        }),
      );

    const data = trackedBuilder.buildData();

    if (data) {
      // insert tracked element
      operation = new InsertElementOperation(baseModel, path, data, { pathFix: 'TEXT' }).apply();
    }

    // add suggestions for update
    ctx.addSuggestionToUpdate(refId, { type: 'text', value: text });

    return operation;
  }

  insertText(
    ctx: Editor.Edition.ActionContext,
    path: Editor.Selection.Path,
    text: string,
  ): boolean {
    if (this.debug) {
      Logger.trace('SuggestionsManipulator insertText', ctx, ctx.baseModel, text);
    }

    // TODO
    // check if path is valid
    // is selection editable
    // is insertion allowed
    // check styles to apply

    if (!this.editionContext.DataManager || !ctx.baseModel || !ctx.baseData) {
      return false;
    }

    const closestTracked = NodeUtils.closestOfTypeByPath(
      ctx.baseData,
      path,
      ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
    );

    let operation;

    if (closestTracked && NodeUtils.isTrackedData(closestTracked.data)) {
      // closest element is a tracked action

      if (this.isUserAuthor(closestTracked.data)) {
        // user is the tracked action author
        if (NodeUtils.isParagraphMarker(closestTracked.data)) {
          // TODO check if previous sibling is tracked element
          // either way this condition shouldn't hapen
          operation = this.insertNewTrackedElement(
            ctx,
            ctx.baseModel,
            closestTracked.path,
            text,
            closestTracked.data.properties.element_reference,
          );
        } else {
          // insert text
          operation = this.insertTextIntoTrackedElement(
            ctx,
            ctx.baseModel,
            path,
            text,
            closestTracked.data.properties.element_reference,
          );
        }
      } else {
        // user is not the tracked action author
        operation = this.insertNewTrackedElement(ctx, ctx.baseModel, path, text);
      }
    } else {
      // const pathLenth = closestTracked.path.length;
      // let pathToParent = closestTracked.path.slice(0, pathLenth - 2);
      // let childOffset = Number(closestTracked.path[pathLenth - 1]);

      // const parentdata = baseModel.getChildDataByPath(pathToParent);

      // if (NodeUtils.isElementData(parentdata) && !isNaN(childOffset)) {
      // }

      // TODO:
      // check if previous paragraph has a marker
      // check previous sibling ?? (check if needed) (selection normalizer should do it)
      // check next sibling ?? (check if needed) (selection normalizer should do it)

      // create track insert with text and insert
      operation = this.insertNewTrackedElement(ctx, ctx.baseModel, path, text);
    }

    // adjust selection
    if (operation) {
      const resultPath = operation.getResultPath();
      if (ctx.range && resultPath) {
        ctx.range.updateRangePositions({
          b: ctx.range.start.b,
          p: resultPath,
        });
      }
    }

    return true;
  }
  insertElement(
    ctx: Editor.Edition.ActionContext,
    path: Editor.Selection.Path,
    newData: Editor.Data.Node.Data,
    options: Editor.Edition.InsertElementOptions = {},
  ): boolean {
    throw new Error('Method not implemented.');
  }
  removeContent(ctx: Editor.Edition.ActionContext): boolean {
    throw new Error('Method not implemented.');
  }

  insertBlock(ctx: Editor.Edition.ActionContext, blockData: Editor.Data.Node.Data): boolean {
    return false;
  }

  removeBlock(ctx: Editor.Edition.ActionContext, blockId: string): boolean {
    return false;
  }
}
