import { useIntl, FormattedMessage } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import styles from './StorageEmptyState.module.scss';

function StorageEmptyState({
  current,
  filters,
}: {
  current: Objekt;
  filters: Request.FilterParams;
}) {
  const intl = useIntl();

  if (filters?.['filter_fields']?.length) {
    return (
      <div className={styles.emptyList}>
        <div className={styles.top}>
          <FormattedMessage id="storage.browserHeader.filtersEmpty" />
        </div>
        <div className={styles.bottom}>
          <FormattedMessage id="storage.browserHeader.filtersEmptySub" />
        </div>
      </div>
    );
  }

  if (current?.type === 'space') {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
        <EmptyState
          size="large"
          icon="EmptyDocument"
          title={intl.formatMessage({ id: 'storage.browserHeader.spaceEmpty' })}
          testId="storage-empty-space"
        >
          {intl.formatMessage({ id: 'storage.browserHeader.folderEmptySub' })}
        </EmptyState>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
      <EmptyState
        size="large"
        icon="EmptyDocument"
        title={intl.formatMessage({ id: 'storage.browserHeader.folderEmpty' })}
        testId="storage-empty-folder"
      >
        {intl.formatMessage({ id: 'storage.browserHeader.folderEmptySub' })}
      </EmptyState>
    </div>
  );
}

export default StorageEmptyState;
