import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { useDispatch } from '_common/hooks';
import { navigateToTenantSettings } from 'router/history';
import { setMenuActiveOption } from '_common/components/Sidebar/SidebarSlice';
import { useGetRoleQuery } from '../TenantSettingsPage/Roles/RolesApi';

import { ActionBar } from '_common/components';
import { PathElement } from '_common/components/ActionBar/ActionBarBreadcrumb/ActionBarBreadcrumb';

const Header = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id: objectId } = useParams<RouterParams['roleSettings']>();

  const { data: role } = useGetRoleQuery(objectId);

  const handleGoTo = (element: PathElement) => {
    if (element.id === 'roles') {
      dispatch(setMenuActiveOption({ menu: 'tenantSettings', activeOption: 'roles' }));
      navigateToTenantSettings();
    }
  };

  if (!role) {
    return <div />;
  }

  return (
    <ActionBar>
      <ActionBar.Breadcrumb
        path={[
          {
            id: 'roles',
            name: intl.formatMessage({ id: 'settings.tenant.header' }),
          },
          {
            id: role.id,
            name: intl.formatMessage({ id: 'settings.roles.header' }, { role: role.name }),
          },
        ]}
        onBreadcrumbElementClick={handleGoTo}
      />
    </ActionBar>
  );
};

export default Header;
