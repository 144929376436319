import { useState, useEffect } from 'react';
import { Logger } from '_common/services';

const usePasswordChecker = (rules: Account['password_validators'], password: string) => {
  const [verifiedRules, setVerifiedRules] = useState<string[]>([]);

  useEffect(() => {
    const arrayOfRules: string[] = [];
    if (password.length !== 0) {
      rules.forEach((element) => {
        const key = (Object.keys(element) as Array<keyof typeof element>)[0];
        const value = element[key];
        if (value) {
          switch (key) {
            case 'min_length':
              if (password.length >= value) {
                if (arrayOfRules.includes('max_length')) {
                  const ind = arrayOfRules.indexOf('max_length');
                  arrayOfRules.splice(ind, 1);
                  arrayOfRules.push('min_max_length');
                } else {
                  arrayOfRules.push(key);
                }
              }
              break;
            case 'max_length':
              if (password.length <= value) {
                if (arrayOfRules.includes('min_length')) {
                  const ind = arrayOfRules.indexOf('max_length');
                  arrayOfRules.splice(ind, 1);
                  arrayOfRules.push('min_max_length');
                } else {
                  arrayOfRules.push(key);
                }
              }
              break;
            case 'contains_upper_case':
              if (password.toLowerCase() !== password) {
                arrayOfRules.push(key);
              }
              break;
            case 'contains_lower_case':
              if (password.toUpperCase() !== password) {
                arrayOfRules.push(key);
              }
              break;
            case 'contains_digit':
              if (new RegExp(/\d/).test(password)) {
                arrayOfRules.push(key);
              }
              break;
            case 'contains_symbol':
              if (new RegExp(/[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/).test(password)) {
                arrayOfRules.push(key);
              }
              break;
            case 'regex':
              if (typeof value === 'string' && new RegExp(value).test(password)) {
                arrayOfRules.push(key);
              }
              break;
            default:
              Logger.captureMessage(`Missing password validation rule: '${key}'`);
          }
        }
      });
    }

    setVerifiedRules(arrayOfRules);
  }, [rules, password]);

  return verifiedRules;
};

export default usePasswordChecker;
