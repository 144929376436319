import { Parser } from '.';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorDOMElements, EditorDOMUtils } from '../../../_Common/DOM';
import {
  BaseBlockElement,
  FigureElement,
  ImageElement,
  TableCellElement,
} from 'Editor/services/VisualizerManager';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { StylesHandler } from 'Editor/services/Styles';
import DOMSanitizer from 'Editor/services/DOMUtilities/DOMSanitizer/DOMSanitizer';

export class DodocParser extends Parser {
  public parsedDocumentStyles: Editor.Clipboard.ParsedDocumentStyles = {};
  public sameDocument: boolean | null = null;
  public parsedListDefenitions: Editor.Clipboard.ParsedListDefenitions = {};
  public newNotes: Editor.Clipboard.NewNotes = {};
  public mappedListId: Editor.Clipboard.MappedListId = {};

  constructor(
    data: string,
    dataManager: Editor.Data.API,
    stylesHandler: StylesHandler,
    visualizerManager: Editor.Visualizer.API,
  ) {
    super(data, dataManager, stylesHandler, visualizerManager);
    this.newNotes = {};
  }

  private async keepOriginalStyles(containerData: HTMLElement) {
    if (containerData) {
      if (this.parsedDocumentStyles) {
        const styleKeys = Object.keys(this.parsedDocumentStyles);
        const length = styleKeys.length;
        for (let i = 0; i < length; i++) {
          const style = this.parsedDocumentStyles[styleKeys[i]];

          const elements = containerData.querySelectorAll(`*[data-style-id="${style.id}"]`);
          if (elements.length > 0) {
            for (let j = 0; j < elements.length; j++) {
              const element = elements[j] as HTMLElement;
              element.dataset.styleId = ELEMENTS.ParagraphElement.ELEMENT_TYPE;

              // Put all document styles as inline inside the paragraph that uses the styles
              const formatElementStyles: Editor.Clipboard.FormatStyleAttributes = {};
              const styleKeys = Object.keys(style.p);
              for (let x = 0; x < styleKeys.length; x++) {
                const st = styleKeys[x] as keyof Editor.Data.Structure.DocumentStyleProperties;
                let value = style.p[st];
                switch (st) {
                  case 'a':
                    if (element.dataset.alignment == null) {
                      element.dataset.alignment = value as string;
                    }
                    break;
                  case 'lh':
                    if (element.dataset.lineHeight == null) {
                      element.dataset.lineHeight = value as string;
                    }
                    break;
                  case 'sb':
                    if (element.dataset.spaceBefore == null) {
                      element.dataset.spaceBefore = value as string;
                    }
                    break;
                  case 'sa':
                    if (element.dataset.spaceAfter == null) {
                      element.dataset.spaceAfter = value as string;
                    }
                    break;
                  case 'bg':
                    if (element.dataset.backgroundColor == null) {
                      element.dataset.backgroundColor = value as string;
                    }
                    break;
                  case 'fontsize': // to work with line height
                    if (element.dataset.fontSize == null) {
                      element.dataset.fontSize = value as string;
                    }
                    formatElementStyles.fontsize = value as number;
                    break;
                  case 'color':
                    const color = value as string;
                    if (element.dataset.color == null) {
                      element.dataset.color = color;
                    }
                    if (
                      color.includes('rgb') ||
                      color.includes('#') ||
                      typeof value === 'boolean' ||
                      value === 'false' ||
                      value === 'true'
                    ) {
                      formatElementStyles.color = color;
                    } else if (typeof color === 'string') {
                      formatElementStyles.color = `#${color}`;
                    }
                    break;
                  case 'fontfamily':
                    if (element.dataset.fontFamily == null) {
                      element.dataset.fontFamily = value as string;
                    }

                    formatElementStyles[st] = value as string;

                    break;
                  case 'italic':
                    if (element.dataset.italic == null) {
                      element.dataset.italic = value as string;
                    }
                    formatElementStyles[st] = value as boolean;
                    break;
                  case 'underline':
                    if (element.dataset.underline == null) {
                      element.dataset.underline = value as string;
                    }
                    formatElementStyles[st] = value as boolean;
                    break;
                  case 'bold':
                    if (element.dataset.bold == null) {
                      element.dataset.bold = value as string;
                    }
                    formatElementStyles[st] = value as boolean;
                    break;
                  case 'v':
                    if (element.dataset.vanish == null) {
                      element.dataset.vanish = value as string;
                    }
                    formatElementStyles.vanish = value as boolean;
                    break;
                  case 'ind':
                    const ind = value as Editor.Data.Structure.DocumentStyleProperties;
                    if (ind?.l != null && element.dataset.leftIndentation == null) {
                      element.dataset.leftIndentation = ind.l as string;
                    }
                    if (ind?.r != null && element.dataset.rightIndentation == null) {
                      element.dataset.rightIndentation = ind.r as string;
                    }
                    break;
                  case 'sp_ind':
                    const spInd = value as Editor.Data.Structure.DocumentStyleProperties;
                    if (spInd?.t != null && element.dataset.specialIndent == null) {
                      element.dataset.specialIndent = spInd.t as string;
                    }
                    if (spInd?.v != null && element.dataset.specialIndentValue == null) {
                      element.dataset.specialIndentValue = String(spInd.v);
                    }
                    break;
                  default:
                    if (
                      StylesUtils.ALLOWED_INLINE_ATTRIBUTES_BY_ELEMENT[element.tagName]?.includes(
                        st as Editor.Styles.Styles,
                      )
                    ) {
                      if (
                        typeof value === 'number' ||
                        typeof value === 'boolean' ||
                        typeof value === 'string'
                      ) {
                        //@ts-expect-error
                        formatElementStyles[st] = value;
                      }
                    }

                    const blockElement = element as BaseBlockElement;
                    if (
                      StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[
                        blockElement.tagName
                      ]?.includes(st as Editor.Styles.Styles) &&
                      !blockElement.hasStyleAttribute?.(st as Editor.Styles.Styles)
                    ) {
                      const attributeValue =
                        typeof value === 'number' ? String(value) : Boolean(value);
                      blockElement.addStyleAttribute(st as Editor.Styles.Styles, attributeValue);
                    }
                    break;
                }
              }
              const formatElement = this.createFormatElement(formatElementStyles);
              if (formatElement) {
                while (element.firstChild) {
                  formatElement.appendChild(element.firstChild);
                }
                element.appendChild(formatElement);
              }
            }
          }
        }
      }

      // handle list styles
      if (this.parsedListDefenitions) {
        const listIds = Object.keys(this.parsedListDefenitions);
        for (let i = 0; i < listIds.length; i++) {
          const id = listIds[i];
          if (!this.dataManager.styles.listStyles.listStyleExists(id)) {
            this.dataManager.styles.listStyles.createListStyle(
              Object.values(this.parsedListDefenitions[id]),
              id,
            );
          } else {
            // TODO: update list defenition??
          }

          // handle paragraphs with list style id
          const elements = containerData.querySelectorAll(`*[cp_list_style="${id}"]`);
          for (let j = 0; j < elements.length; j++) {
            const element = elements[j] as HTMLElement;

            const cpListLevel = element.getAttribute('cp_list_level');
            const listLevel = cpListLevel ? Number(cpListLevel) : null;

            if (element && listLevel && !isNaN(listLevel)) {
              const levelData = this.parsedListDefenitions[id][listLevel];

              // handle indentations from list
              if (levelData.indentation_left != null) {
                element.dataset.leftIndentation = String(
                  EditorDOMUtils.convertUnitTo(levelData.indentation_left),
                );
              }
              if (levelData.indentation_right != null) {
                element.dataset.rightIndentation = String(
                  EditorDOMUtils.convertUnitTo(levelData.indentation_right),
                );
              }
              if (levelData.special_indent != null && levelData.special_indent_value != null) {
                element.dataset.specialIndent = levelData.special_indent;
                element.dataset.specialIndentValue = String(
                  EditorDOMUtils.convertUnitTo(levelData.special_indent_value),
                );
              }
            }
          }
        }
      }
    }
  }

  private async matchDestinationStyles(containerData: HTMLElement) {
    if (containerData) {
      this.mappedListId = {};

      // handle list styles
      if (this.parsedListDefenitions) {
        const listIds = Object.keys(this.parsedListDefenitions);
        for (let i = 0; i < listIds.length; i++) {
          const id: keyof Editor.Clipboard.ParsedListDefenitions = listIds[i];
          if (!this.dataManager.styles.listStyles.listStyleExists(id)) {
            const listStyleId = await this.dataManager.styles.listStyles.createListStyle(
              Object.values(this.parsedListDefenitions[id]),
              id,
            );
            this.mappedListId[id] = { listStyleId };
          } else {
            // TODO: update list defenition??
            this.mappedListId[id] = { listStyleId: id };
          }
        }
      }

      const hasTemplateHeaderRow = this.dataManager.templates.getHeaderRow();
      if (hasTemplateHeaderRow) {
        let tableBodies = Array.from(containerData.querySelectorAll('tbody'));
        for (let tbody of tableBodies) {
          if (tbody.firstChild && tbody.firstChild instanceof HTMLElement) {
            tbody.firstChild.dataset.hr = 'true';
          }
        }
      }

      // handle paragraphs with list style id
      const ids = Object.keys(this.mappedListId);
      for (let i = 0; i < ids.length; i++) {
        const listId = ids[i];

        const elements = containerData.querySelectorAll(`*[cp_list_style="${listId}"]`);

        let newListId;
        let listStyleId = this.mappedListId[listId].listStyleId;

        if (elements.length > 0 && listStyleId) {
          newListId = await this.dataManager.numbering.createNewList(listStyleId);
          this.mappedListId[listId].listId = newListId;
        }

        if (listStyleId && newListId) {
          for (let j = 0; j < elements.length; j++) {
            if (elements[j]) {
              elements[j].setAttribute('cp_list_style', listStyleId);
              elements[j].setAttribute('cp_list_id', newListId);
            }
          }
        }
      }

      // handle document styles
      if (this.parsedDocumentStyles) {
        const styleKeys = Object.keys(this.parsedDocumentStyles);
        const length = styleKeys.length;
        for (let i = 0; i < length; i++) {
          const style = this.parsedDocumentStyles[styleKeys[i]];

          let documentStyle = this.stylesHandler.checkIfStyleNameExist(style.n);

          const elements = containerData.querySelectorAll(`*[data-style-id="${style.id}"]`);

          if (elements.length > 0) {
            if (!documentStyle) {
              let existingStyle = JSON.parse(JSON.stringify(style));

              const id = this.dataManager.styles.generateStyleId();
              if (existingStyle) {
                existingStyle.id = id;

                // check parent / extends style
                if (
                  existingStyle.e &&
                  !this.parsedDocumentStyles[existingStyle.e] &&
                  !this.dataManager.styles.documentStyles.style(existingStyle.e)
                ) {
                  existingStyle.e = 'p';
                }

                this.stylesHandler.createNewDocumentStyle(existingStyle.id, existingStyle);

                const listStyleId = existingStyle?.p?.lst?.lId;
                if (
                  listStyleId &&
                  this.dataManager.styles.listStyles.listStyleExists(listStyleId)
                ) {
                  let listStyle = this.dataManager.styles.listStyles.style(listStyleId);
                  const style = listStyle?.parseFront();
                  const level = existingStyle.p?.lst?.lLv ? +existingStyle.p?.lst?.lLv : undefined;

                  if (level !== undefined && style?.[level]) {
                    const paragraphStyle = style[level].paragraph_style;
                    if (paragraphStyle) {
                      paragraphStyle.value = existingStyle.id;
                      await this.dataManager.styles.listStyles.updateListStyle(listStyleId, style);
                    } else {
                      style[level].paragraph_style = {
                        value: existingStyle.id,
                      };
                      await this.dataManager.styles.listStyles.updateListStyle(listStyleId, style);
                    }
                  }
                }

                for (let j = 0; j < elements.length; j++) {
                  const element = elements[j];
                  if (element && element instanceof HTMLElement) {
                    element.dataset.styleId = existingStyle.id;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  async handleContainerWithPasteOption(
    pasteOption: Editor.Clipboard.PasteOptions,
    containerData: HTMLElement,
  ) {
    switch (pasteOption) {
      case Parser.ORIGINAL_STYLES:
        await this.keepOriginalStyles(containerData);
        break;
      case Parser.MATCH_DESTINATION:
        await this.matchDestinationStyles(containerData);
        break;
      case Parser.PLAIN_TEXT:
        break;
      default:
        break;
    }
  }

  private handleExternalDocumentStyles() {
    this.parsedDocumentStyles = {};

    if (this.html) {
      const styleElements = this.html.querySelectorAll('meta[data-type="style"]');

      for (let i = 0; i < styleElements.length; i++) {
        const element = styleElements[i] as HTMLElement;
        if (element.dataset.info) {
          const newStyle = JSON.parse(element.dataset.info);
          newStyle.p = { ...newStyle.extendedP, ...newStyle.p };
          this.parsedDocumentStyles[newStyle.id] = newStyle;
        }
      }
    }
  }

  private handleExternalListDefinitions() {
    this.parsedListDefenitions = {};

    if (this.html) {
      const listDefinitionElements = this.html.querySelectorAll('meta[data-list-definition]');

      for (let i = 0; i < listDefinitionElements.length; i++) {
        const listDefinitionElement = listDefinitionElements[i] as HTMLElement;
        if (!listDefinitionElement.dataset.listDefinition) {
          continue;
        }
        const definition = JSON.parse(listDefinitionElement.dataset.listDefinition);

        this.parsedListDefenitions[listDefinitionElement.id] = definition;
      }
    }
  }

  private handleTableElements() {
    // Handle [head-id] attributes case (table cell that references other cells in the same table)
    if (this.container) {
      const cells = Array.from(
        this.container.querySelectorAll(
          `${ELEMENTS.TableCellElement.TAG}[rowspan]:not([rowspan="1"]),td[colspan]:not([colspan="1"])`,
        ),
      );
      if (cells.length > 0) {
        let counter = 0;
        cells.forEach((cell) => {
          if (cell instanceof TableCellElement) {
            const tbody = cell.parentNode?.parentNode;
            const cellIndex = cell.cellIndex;
            const row = cell.parentNode as HTMLTableRowElement;
            const rowIndex = row.sectionRowIndex;
            const rowspan = cell.hasAttribute('rowspan') ? Number(cell.getAttribute('rowspan')) : 1;
            const colspan = cell.hasAttribute('colspan') ? Number(cell.getAttribute('colspan')) : 1;

            let previousTd;
            for (let i = rowIndex; i <= rowIndex + rowspan - 1; i++) {
              for (let j = cellIndex; j <= cellIndex + colspan - 1; j++) {
                if (i !== rowIndex || j !== cellIndex) {
                  if (tbody && tbody.childNodes[i]) {
                    if (tbody.childNodes[i].childNodes[j]) {
                      const td = tbody.childNodes[i].childNodes[j] as TableCellElement;
                      previousTd = td;
                      td.setAttribute('head-id', `head-id-${counter}`);
                    } else if (previousTd) {
                      // add missing cells
                      const newTd = previousTd.cloneNode(true) as TableCellElement;
                      newTd.removeAttribute('id');
                      newTd.setAttribute('head-id', `head-id-${counter}`);
                      if (tbody.childNodes[i].childNodes[j - 1])
                        EditorDOMUtils.insertNodeAfter(
                          tbody.childNodes[i] as Element,
                          newTd,
                          tbody.childNodes[i].childNodes[j - 1],
                        );
                    }
                  }
                }
              }
            }
          }
          cell.setAttribute('head-id-reference', `head-id-${counter}`);
          counter += 1;
        });
      }
    }
  }

  private async handleFigureElements() {
    if (this.container && this.html) {
      const images = Array.from(this.container.querySelectorAll(ELEMENTS.ImageElement.TAG));
      const htmlImages = this.html.querySelectorAll(ELEMENTS.ImageElement.TAG);
      const allowedAttrs = DOMSanitizer.allowedAttributesForTag(ELEMENTS.ImageElement.TAG);

      const uploads = [];
      for (let i = 0; i < images.length; i += 1) {
        const image = images[i] as ImageElement;
        if (images.length === htmlImages.length) {
          const imageAttributes = htmlImages[i].getAttributeNames();

          const attributes: Editor.Clipboard.ImageAllowedAttributes = {};
          imageAttributes.forEach((attr) => {
            const htmlImageAttr = htmlImages[i].getAttribute(attr);

            if (allowedAttrs.includes(attr) && htmlImageAttr) {
              //@ts-expect-error
              attributes[attr] = htmlImageAttr;
            }
          });

          if (image && image.sourceId) {
            if (this.sameDocument) {
              // figure comes from the same document
              if (image.firstChild) {
                image.firstChild.remove();
              }

              // image.preRender();
            } else {
              // dodoc figure comes from another document, use base64 to upload to this document
              if (image.firstChild instanceof HTMLElement) {
                const base64 = image.firstChild.getAttribute('src');

                image.setAttribute('uploading', 'true');
                if (base64) {
                  uploads.push(this.uploadImageFromBase64(image, base64, attributes));
                }
              }
            }
          } else {
            if (image.parentNode instanceof FigureElement) {
              image.parentNode.remove();
            } else {
              image.remove();
            }
          }
        }
      }

      await Promise.all(uploads);
    }
  }

  private handleNoteElements() {
    if (this.container) {
      const notes = this.container.querySelectorAll('note-element');
      notes.forEach((note) => {
        if (
          note instanceof HTMLElement &&
          note.dataset.tempId &&
          note.dataset.tempType &&
          note.dataset.tempContent
        ) {
          this.newNotes[note.dataset.tempId] = {
            type: note.dataset.tempType as Notes.NoteType,
            text: note.dataset.tempContent,
          };
        }
      });
    }
  }

  private handleCitations() {
    if (this.container) {
      const citations = this.container.querySelectorAll('citation-element');
      citations.forEach((citation) => {
        if (citation instanceof HTMLElement) {
          delete citation.dataset.tempCitationInfo;
        }
      });
    }
  }

  private handleFieldElements() {
    if (this.container) {
      const fieldElements = this.container.querySelectorAll('field-element[data-ref]');
      for (let i = 0; i < fieldElements.length; i++) {
        const fieldElement = fieldElements[i] as HTMLElement;
        if (fieldElement.dataset.ref) {
          const ref = fieldElement.dataset.ref.split(':');
          if (ref.length === 2) {
            const refId = ref[1];
            const target = this.container.querySelector(`[id="${refId}"]`);
            if (target && target instanceof HTMLElement) {
              target.dataset.tempCrossReferenceId = refId; //[refId];
              fieldElement.dataset.tempCrossReferenceTarget = refId;
              this.newCrossRefs[refId] = { hasReference: true, hasTarget: true };
            }
          }
        }
      }
    }
  }

  private isSameDocument(body: HTMLElement) {
    return body.id === this.documentId;
  }

  async parse() {
    this.debugMessage('DodocParser Parsing...', this.data);
    this.html = document.createElement('html');

    if (typeof this.data === 'string') {
      this.html.innerHTML = this.data;
      this.container = document.createElement('div');
      const body = this.html.querySelector('body');
      if (body) {
        this.container.innerHTML = body.innerHTML;
        // Check if the paste comes from this document or from another
        this.sameDocument = this.isSameDocument(body);

        if (this.sameDocument) {
          this.handleCitations();
        } else {
          this.handleExternalDocumentStyles();
          this.handleExternalListDefinitions();
          this.handleFieldElements();
        }

        this.handleTableElements();

        await this.handleFigureElements();

        this.handleNoteElements();

        if (this.container.childNodes.length > 0) {
          this.isValid = true;

          const nodeIterator = document.createNodeIterator(this.container, NodeFilter.SHOW_ELEMENT);
          let currentNode;

          while ((currentNode = nodeIterator.nextNode() as HTMLElement)) {
            // remove attributes
            let i;
            for (i = 0; i < Parser.ATTRIBUTES_TO_REMOVE.length; i++) {
              currentNode.removeAttribute(Parser.ATTRIBUTES_TO_REMOVE[i]);
            }
          }
        } else {
          this.isValid = false;
        }
        this.debugMessage(
          'Parsed data',
          this.container,
          this.parsedDocumentStyles,
          this.parsedListDefenitions,
        );
      }
    }
  }

  countBlocks(): number {
    let regex = '';

    for (let i = 0; i < EditorDOMElements.BLOCK_ELEMENTS.length; i++) {
      regex += `<${EditorDOMElements.BLOCK_ELEMENTS[i].toLowerCase()}`;
      if (i < EditorDOMElements.BLOCK_ELEMENTS.length - 1) {
        regex += '|';
      }
    }

    const matchResult =
      typeof this.data === 'string' ? this.data.match(new RegExp(regex, 'g')) : [];

    return (matchResult || []).length + 1;
  }
}
