import { useDispatch } from "_common/hooks";
import { openAndUpdateModal } from "_common/modals/ModalsSlice";
import { Button } from "dodoc-design-system"
import { FormattedMessage } from "react-intl"
import { ShortcutsPopover } from "_common/components";

const InsertCaptionShortcut = ({ offsets }: { offsets: Editor.Common.Rect | null }) => {
  const dispatch = useDispatch();

  const handleInsertCaption = () => {
    dispatch(openAndUpdateModal({ modal: 'CaptionsModal', data: { editModal: 'insert' } }));
  };

  return (
    <ShortcutsPopover testId="table" offsets={offsets}>
      <div style={{ padding: '1rem' }}>
        <Button
          size="medium"
          variant="link"
          onClick={handleInsertCaption}
          testId="table-insert-caption"
        >
          <FormattedMessage id="INSERT_CAPTION" />
        </Button>
      </div>
    </ShortcutsPopover>
  )
}

export default InsertCaptionShortcut;