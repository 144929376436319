import { useState } from 'react';

import { useGetReferenceStylesListQuery } from '../ReferenceStylesApi';

import { FilterDisplay, IntlErrorBoundary } from '_common/components';

import { ErrorState } from 'Settings/components';
import RefStyleHeading from './RefStyleHeading';
import RefStyleTable from './RefStyleTable/RefStyleTable';

import styles from './RefStyleSection.module.scss';

export type ReferencesStylesProps = {
  category: string;
  testId: string;
};

const RefStyleSection = ({ category, testId }: ReferencesStylesProps) => {
  const { isLoading, isError } = useGetReferenceStylesListQuery();

  const [search, setSearch] = useState('');

  const renderContent = () => {
    if (isError) {
      return (
        <div data-testid="errorState">
          <ErrorState text="REFERENCES_STYLES_LIST_ERROR" testId="references-style-error-helper" />
        </div>
      );
    }

    return (
      <IntlErrorBoundary size="large" margin="10rem 0 0 0">
        <FilterDisplay identity={`referenceStyles-${category}`} />
        <RefStyleTable category={category} search={search} />
      </IntlErrorBoundary>
    );
  };

  return (
    <div className={styles.root}>
      <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ sticky: true }}>
        <RefStyleHeading
          category={category}
          search={search}
          onSearchChange={setSearch}
          testId={`${testId}-heading`}
        />
      </IntlErrorBoundary>
      {!isLoading && renderContent()}
    </div>
  );
};

export default RefStyleSection;
