import { ReactNode, useState } from 'react';
import { SubMenu, SubMenuProps } from 'react-contextmenu';

import styles from './ContextSubMenu.module.scss';

type ContextSubMenuProps = {
  children: ReactNode;
  title: SubMenuProps['title'];
  dataTestId?: string;
};

const ContextSubMenu = ({ children, title, dataTestId }: ContextSubMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      data-testid={dataTestId}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {/* @ts-expect-error update react-contextmenu SubMenu component prop types out of date */}
      <SubMenu title={title}>
        {isOpen && (
          <div className={styles.submenuChildren} onClick={() => setIsOpen(false)}>
            {children}
          </div>
        )}
      </SubMenu>
    </div>
  );
};

export default ContextSubMenu;
