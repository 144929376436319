import { NodeDataBuilder } from 'Editor/services/DataManager';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { Command } from '../Command';

export class InsertImageCommand extends Command {
  image: File;

  constructor(context: Editor.Edition.ICommandArgs, image: File) {
    super(context);

    this.image = image;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    this.uploadImage(this.image, (imageData) => {
      if (!this.context.DataManager || !this.context.DataManager.selection) {
        return this;
      }

      const rangeData = this.context.DataManager.selection.current;

      const jsonRange = rangeData[0] ? JsonRange.buildFromRangeData(rangeData[0]) : null;

      if (!jsonRange) {
        return this;
      }

      const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
      const baseData = baseModel?.selectedData();

      if (!baseModel || !baseData) {
        return this;
      }

      const image = NodeDataBuilder.build({
        type: 'img',
        properties: {
          w: imageData.dimensions.width,
          h: imageData.dimensions.height,
          es: imageData.reference,
        },
      });

      if (image) {
        const result = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, ['p']);
        const closest = result?.data;

        if (closest) {
          this.insertInline(image);
        } else {
          this.insertBlock(image);
        }
      }
    });

    return this;
  }

  insertInline(image: Editor.Data.Node.Data) {
    if (this.context.commandFactory) {
      let command = this.context.commandFactory.getInsertInlineCommand();
      command.exec(image);
    }
  }

  insertBlock(image: Editor.Data.Node.Data) {
    //TODO implement insert block
    //let command = this.context.commandFactory.getInsertBlockCommand(image);
    throw new Error('InsertImageCommand: insert block not implemented.');
  }
}
