import { useEffect, useState } from 'react';
import { Input } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector, useDebounce } from '_common/hooks';
import {
  editNavigationSearchValue,
  selectFilteredNavigationElements,
} from 'Editor/redux/NavigationSlice';

import { FilterDisplay, FilterPopover } from '_common/components';

import styles from './NavigationTab.module.scss';

const NavigationFilters = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const initialSearchValue = useSelector((state) => state.editor.navigation.searchValue);
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const elements = useSelector(selectFilteredNavigationElements);

  const debouncedSearchValue = useDebounce(searchValue, 200);

  useEffect(() => {
    dispatch(editNavigationSearchValue(debouncedSearchValue));
  }, [debouncedSearchValue]);

  return (
    <>
      <div className={styles.filters_container}>
        <div style={{ width: '100%' }}>
          <Input
            prefix="NavSearchBlue"
            placeholder={intl.formatMessage({ id: 'global.search' })}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            size="medium"
            testId="navigation-pane-search"
          />
        </div>
        <FilterPopover
          identity="editorNavPanel"
          popperSettings={{ placement: 'bottom-start' }}
          elementType={{
            options: [
              { value: 'figure', label: 'FIGURE' },
              { value: 'table', label: 'TABLE' },
              { value: 'citation', label: 'REFERENCE' },
            ],
          }}
        />
      </div>
      <FilterDisplay identity="editorNavPanel" direction="column" margin="0 0 2rem 0" />
      {elements.total !== 0 && debouncedSearchValue && (
        <div className={styles.results}>
          <FormattedMessage
            id="SEARCH_RESULTS_IN_NAVIGATION_PANEL"
            values={{ elements: elements.total, searchValue: debouncedSearchValue }}
          />
        </div>
      )}
    </>
  );
};

export default NavigationFilters;
