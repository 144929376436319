import { Structure, StructureData } from 'Editor/services/DataManager/models';
import { BaseTypedEmitter } from '_common/services/Realtime';
import { BlockWindow } from '.';

export type SectionValuesUpdateType = {
  [index: string]: string;
};

type LockType = {
  state: boolean;
  value?: number;
};

export class LazyLoaderManager extends BaseTypedEmitter<{
  LOADED: () => void;
  UPDATED: () => void;
  RESET_WINDOW: (nodeIds: string[]) => void;
  UPDATE_WINDOW: (nodeIds: string[]) => void;
  UPDATE_WINDOW_DELTA: (delta: Realtime.Core.RealtimeOps) => void;
  UPDATE_SECTIONS_VALUES: (delta: SectionValuesUpdateType) => void;
  UPDATE_ANCHOR: (anchorId: string) => void;
  UPDATE_DEFAULT_TAB_VALUE: () => void;
}> {
  private backlog: number;
  private lookahead: number;
  private step: number;
  private structure: Structure;
  private anchorId?: string;
  private anchorIndex: number = 0;
  private lockStart: LockType = {
    state: false,
  };
  private length: number = 0;
  blockWindow: BlockWindow;
  constructor(
    structure: Structure,
    backlog: number = 75,
    lookahead: number = 75,
    step: number = 20,
  ) {
    super();
    this.structure = structure;
    this.backlog = backlog;
    this.lookahead = lookahead;
    this.step = step;
    this.blockWindow = new BlockWindow(0, 0);
    this.handleModelLoad = this.handleModelLoad.bind(this);
    this.handleModelUpdate = this.handleModelUpdate.bind(this);
    this.structure.on('LOADED', this.handleModelLoad);
    this.structure.on('UPDATED', this.handleModelUpdate);
    if (this.structure.loaded) {
      this.resetBlockWindow();
    }
  }

  private handleModelLoad() {
    this.resetBlockWindow();
  }

  private handleModelUpdate(data: StructureData | null, ops: Realtime.Core.RealtimeOps) {
    let delta: any[] = [];
    let sectionUpdates: any = {};
    let op;
    for (let index = 0; index < ops.length; index++) {
      op = ops[index];
      if (op.p.length === 2 && op.p[0] === 'childNodes') {
        this.adjustWindowWithOperation(op, delta);
      }
      if (op.p[0] === 'blkProps') {
        if (op.p.length === 3 && op.p[2] === 'sct' && op.oi) {
          sectionUpdates[op.p[1]] = op.oi;
        } else if (op.p.length === 2 && op.oi) {
          sectionUpdates[op.p[1]] = op.oi.sct;
        }
      }
      if (op.p.length === 2 && op.p[0] === 'extra' && op.p[1] === 'ts') {
        this.emit('UPDATE_DEFAULT_TAB_VALUE');
      }
    }
    const childNodes = this.structure.childNodes;
    this.emit('UPDATE_WINDOW_DELTA', delta);
    let sectionNodesUpdates = Object.keys(sectionUpdates);
    let nodeId;
    for (let index = 0; index < sectionNodesUpdates.length; index++) {
      nodeId = sectionNodesUpdates[index];
      if (!this.blockWindow.includes(childNodes.indexOf(nodeId))) {
        delete sectionUpdates[nodeId];
      }
    }
    this.emit('UPDATE_SECTIONS_VALUES', sectionUpdates);
  }

  private adjustWindowWithOperation(op: Realtime.Core.RealtimeOp, delta: any[]) {
    const elementIndex = op.p[1] as number;
    const isDelete = op.ld && op.li === undefined;
    const isInsert = op.li && op.ld === undefined;
    let computedOp;
    if (isDelete) {
      this.length -= 1;
    }
    if (isInsert) {
      this.length += 1;
    }
    if (elementIndex < this.blockWindow.start) {
      if (isDelete) {
        this.blockWindow.start = this.getProperStartValue(this.blockWindow.start - 1);
        this.blockWindow.end = this.getProperEndValue(this.blockWindow.end - 1);
        this.anchorIndex = Math.max(0, this.anchorIndex - 1);
      }
      if (isInsert) {
        this.blockWindow.start = this.getProperStartValue(this.blockWindow.start + 1);
        this.blockWindow.end = this.getProperEndValue(this.blockWindow.end + 1);
        this.anchorIndex = Math.max(0, this.anchorIndex + 1);
      }
    } else if (this.blockWindow.includes(elementIndex)) {
      if (elementIndex <= this.anchorIndex) {
        if (isDelete) {
          this.blockWindow.end = this.getProperEndValue(this.blockWindow.end - 1);
          this.anchorIndex = Math.max(0, this.anchorIndex - 1);
        }
        if (isInsert) {
          this.blockWindow.end = this.getProperEndValue(this.blockWindow.end + 1);
          this.anchorIndex = Math.max(0, this.anchorIndex - 1);
        }
      } else {
        if (isDelete) {
          this.blockWindow.end = this.getProperEndValue(this.blockWindow.end - 1);
        }
        if (isInsert) {
          this.blockWindow.end = this.getProperEndValue(this.blockWindow.end + 1);
        }
      }
      computedOp = {
        li: op.li,
        ld: op.ld,
        p: [elementIndex - this.blockWindow.start],
      };
    } else {
      if (isInsert) {
        if (this.blockWindow.end < this.anchorIndex + this.lookahead) {
          this.blockWindow.end = this.getProperEndValue(this.blockWindow.end + 1);
          computedOp = {
            li: op.li,
            ld: op.ld,
            p: [elementIndex - this.blockWindow.start],
          };
        }
      }
      // Do nothing ¯\_(ツ)_/¯
    }
    this.anchorId = this.structure.childNodes[this.anchorIndex];
    if (computedOp) {
      delta.push(computedOp);
    }
  }

  private adjustToNewWindow(newBlockWindow: BlockWindow, newAnchorIndex: number) {
    let delta: any[] = [];
    const childNodes = this.structure.childNodes;
    if (!this.blockWindow.equals(newBlockWindow)) {
      if (this.blockWindow.intersects(newBlockWindow)) {
        const removeWindows = this.blockWindow.substract(newBlockWindow);
        const insertWindows = newBlockWindow.substract(this.blockWindow);
        for (let j = 0; j < removeWindows.length; j++) {
          const removeWindow = removeWindows[j];
          for (let index = removeWindow.end; index >= removeWindow.start; index--) {
            delta.push({
              ld: childNodes[index],
              p: [index - this.blockWindow.start],
            });
          }
        }
        for (let j = 0; j < insertWindows.length; j++) {
          const insertWindow = insertWindows[j];
          for (let index = insertWindow.start; index <= insertWindow.end; index++) {
            delta.push({
              li: childNodes[index],
              p: [index - newBlockWindow.start],
            });
          }
        }
        this.blockWindow = newBlockWindow;
      } else {
        delta = [];
        this.blockWindow = newBlockWindow;
        this.anchorIndex = newAnchorIndex;
        this.anchorId = childNodes[this.anchorIndex];
        this.emit('UPDATE_WINDOW', this.getBlockWindow());
        return true;
      }
    }
    if (delta.length) {
      this.anchorIndex = newAnchorIndex;
      this.anchorId = childNodes[this.anchorIndex];
      this.emit('UPDATE_WINDOW_DELTA', delta);
      return true;
    }
    if (this.anchorIndex !== newAnchorIndex) {
      this.anchorIndex = newAnchorIndex;
      this.anchorId = childNodes[this.anchorIndex];
      this.emit('UPDATE_ANCHOR', this.anchorId);
    }
  }

  private getProperStartValue(...args: number[]) {
    if (this.length <= 0) {
      return 0;
    }

    if (this.lockStart.state) {
      return this.lockStart.value || 0;
    }

    return Math.min(this.length - 1, Math.max(0, ...args));
  }

  private getProperEndValue(...args: number[]) {
    if (this.length <= 0) {
      return 0;
    }

    return Math.max(0, Math.min(this.length - 1, ...args));
  }

  getWindowAnchor() {
    return this.anchorId;
  }

  getBlockWindow() {
    return this.structure.childNodes.slice(this.blockWindow.start, this.blockWindow.end + 1);
  }

  resetBlockWindow() {
    this.length = this.structure.childNodes.length;
    this.blockWindow = new BlockWindow(
      this.getProperStartValue(0),
      this.getProperEndValue(this.backlog + this.lookahead),
    );
    this.anchorId = this.structure.childNodes[this.blockWindow.start];
    this.anchorIndex = this.getProperStartValue(0);
    this.emit('RESET_WINDOW', this.getBlockWindow());
  }

  requestWindowExtension(place: 'TOP' | 'BOTTOM' | 'BOTH') {
    const newWindow = this.blockWindow.clone();
    if (place === 'TOP' || place === 'BOTH') {
      newWindow.start = this.getProperStartValue(this.blockWindow.start - this.step);
    }
    if (place === 'BOTTOM' || place === 'BOTH') {
      newWindow.end = this.getProperEndValue(this.blockWindow.end + this.step);
    }
    if (place === 'BOTH') {
      this.backlog = Math.max(this.backlog, this.anchorIndex - newWindow.start);
      this.lookahead = Math.max(this.lookahead, newWindow.end - this.anchorIndex);
    }
    return this.adjustToNewWindow(newWindow, this.anchorIndex);
  }

  isBehindAnchor(nodeId: string) {
    const nodeIndex = this.structure.childNodes.indexOf(nodeId);
    return nodeIndex >= 0 && nodeIndex < this.anchorIndex;
  }

  centerBlockWindowAt(nodeId?: string) {
    if (!nodeId) {
      return;
    }
    const childNodes = this.structure.childNodes;
    const newAnchorIndex = childNodes.indexOf(nodeId);
    if (newAnchorIndex < 0) {
      return;
    }
    this.length = childNodes.length;
    let newBlockWindow = this.blockWindow.clone();
    if (newAnchorIndex < this.backlog) {
      // almost start
      newBlockWindow.start = this.getProperStartValue(0);
      newBlockWindow.end = this.getProperEndValue(
        Math.max(this.backlog + this.lookahead, this.blockWindow.end),
      );
    } else if (newAnchorIndex > this.length - 1 - this.lookahead) {
      // almost end
      newBlockWindow.start = this.getProperStartValue(
        this.length - 1 - this.backlog - this.lookahead,
      );
      newBlockWindow.end = this.getProperEndValue(this.length - 1);
    } else {
      //
      if (newAnchorIndex > this.anchorIndex) {
        newBlockWindow.start = this.getProperStartValue(newAnchorIndex - this.backlog);
        newBlockWindow.end = this.getProperEndValue(
          Math.max(newAnchorIndex + this.lookahead, this.blockWindow.end),
        );
      } else {
        newBlockWindow.start = this.getProperStartValue(
          newAnchorIndex - this.backlog,
          this.blockWindow.start,
        );
        newBlockWindow.end = this.getProperEndValue(newAnchorIndex + this.lookahead);
      }
    }
    return this.adjustToNewWindow(newBlockWindow, newAnchorIndex);
  }

  lockWindowStartAt(index: number) {
    if (index < this.blockWindow.end) {
      this.blockWindow.start = Math.max(0, index);
      this.lockStart = {
        state: true,
        value: this.blockWindow.start,
      };
    }
  }

  unlockWindowStart() {
    this.lockStart = {
      state: false,
    };
    this.centerBlockWindowAt(this.anchorId);
  }

  destroy() {
    super.destroy();
    this.structure.removeListener('LOADED', this.handleModelLoad);
    this.structure.removeListener('UPDATED', this.handleModelUpdate);
  }
}
