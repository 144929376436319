import { store } from '_common/redux';
import {
  addUserOnline,
  removeUserOnline,
  setUsersOnline,
  setCurrentPage,
  setLoadedVersion,
  setZoomValue,
} from 'PDF/redux/PDFGeneralSlice';
import { selectAnnotation, setAnnotations, setCreating } from 'PDF/redux/PDFAnnotationsSlice';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import appApi from 'App/redux/AppApi';

export class ReduxInterface {
  static getPlatform() {
    return store.getState().app.platform;
  }

  static setCurrentPage(payload: Parameters<typeof setCurrentPage>[0]) {
    store.dispatch(setCurrentPage(payload));
  }

  static usersOnline(payload: Parameters<typeof setUsersOnline>[0]) {
    store.dispatch(setUsersOnline(payload));
  }

  static userJoined(payload: Parameters<typeof addUserOnline>[0]) {
    store.dispatch(addUserOnline(payload));
  }

  static userLeft(payload: Parameters<typeof removeUserOnline>[0]) {
    store.dispatch(removeUserOnline(payload));
  }

  static loadAnnotations(payload: Parameters<typeof setAnnotations>[0]) {
    // console.log('load annotations');
    store.dispatch(setAnnotations(payload));
  }

  static selectAnnotation(payload: Parameters<typeof selectAnnotation>[0]) {
    store.dispatch(selectAnnotation(payload));
  }

  static getAnnotationCreationValues() {
    return store.getState().pdf.annotations.creation;
  }

  static setCreating(payload: Parameters<typeof setCreating>[0]) {
    return store.dispatch(setCreating(payload));
  }

  static setLoadedVersion(payload: Parameters<typeof setLoadedVersion>[0]) {
    return store.dispatch(setLoadedVersion(payload));
  }

  static invalidateApiTags(payload: Parameters<typeof appApi.util.invalidateTags>[0]) {
    return store.dispatch(appApi.util.invalidateTags(payload));
  }

  static zoomIn() {
    return store.dispatch(setZoomValue(store.getState().pdf.general.zoom + 0.1));
  }

  static zoomOut() {
    return store.dispatch(setZoomValue(store.getState().pdf.general.zoom - 0.1));
  }

  static completeAction(payload: Parameters<typeof completeAction>[0]) {
    return store.dispatch(completeAction(payload));
  }

  static setPulseData(payload: Parameters<typeof setPulseData>[0]) {
    return store.dispatch(setPulseData(payload));
  }
}
