import { setNoteOverlayData } from 'Editor/redux/NotesSlice';
import EditorManager from 'Editor/services/EditorManager';
import { notify } from '_common/components/ToastSystem';
import { Card } from '_common/suite/components';
import { Button, TextArea } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { EditableCardProps } from '../EditableCard/EditableCardContent';
import { usePublicProfile } from '_common/hooks';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { SuiteAvatar } from '_common/suite/components';
import { toRoman } from 'utils';
import styles from './ViewNoteCard.module.scss';

type EditableNoteCardProps = {
  operation: string;
  note: Editor.Note;
  testId: string;
  type: 'endnote' | 'footnote';
  selected?: boolean;
  sidebar?: boolean;
  user: UserId;
};

const EditableNoteCard = ({
  operation,
  note,
  testId,
  type,
  sidebar,
  selected,
  user,
}: EditableNoteCardProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const manager = EditorManager.getInstance();
  const profile = usePublicProfile(user);
  const [content, setContent] = useState(operation === 'edit' ? note.content : '');
  const author = usePublicProfile(note?.author);
  const newCommentInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (newCommentInputRef.current) {
      newCommentInputRef.current.focus();
    }
  }, []);

  const handleNoteValueChanged: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setContent(e.target.value);
  };
  const handleInsertNote: EditableCardProps['handleCreateClicked'] = async (text) => {
    if (text) {
      const id = await manager.createNote(null, type, text);
      if (id) {
        manager.insertNote(type, id);
      }
    }
    handleCancelEdition();
  };

  const handleSaveClicked: EditableCardProps['handleSaveClicked'] = async (text) => {
    if (note?.id) {
      await manager.editNote(note.id, text).then(() => {
        switch (type) {
          case 'endnote':
            notify({
              type: 'success',
              title: 'ENDNOTES_EDITED',
              message: 'THE_NUMBERING_OF_ENDNOTES_WAS_EDITED',
            });
            break;
          case 'footnote':
            notify({
              type: 'success',
              title: 'FOOTNOTES_EDITED',
              message: 'THE_NUMBERING_OF_FOOTNOTES_WAS_EDITED',
            });
            break;
        }
      });
    }
    handleCancelEdition();
  };

  const handleCancelEdition = () => {
    dispatch(
      setNoteOverlayData(operation === 'create' ? { offsets: null } : { operation: 'view' }),
    );
  };

  return (
    <Card testId={testId} sidebar={sidebar} selected={selected}>
      <Card.Header>
        <div style={{ display: 'flex' }}>
          <SuiteAvatar
            margin="0 1rem 0 0"
            userId={user}
            name={operation === 'edit' ? author.name : undefined}
          />
          <div className={styles.labels}>
            <div className={styles.author}>{profile.name}</div>
            <div className={styles.order}>
              {intl.formatMessage({ id: type?.toUpperCase() })}
              {note && ` #${type === 'footnote' ? note.order : toRoman(note.order)}`}
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <TextArea
          size="medium"
          name="comment"
          placeholder={intl.formatMessage({
            id:
              type === 'footnote'
                ? 'NEW_FOOTNOTE_INPUT_PLACEHOLDER'
                : 'NEW_ENDNOTE_INPUT_PLACEHOLDER',
          })}
          onChange={handleNoteValueChanged}
          value={content}
          ref={newCommentInputRef}
          onKeyDown={(e) => {
            if (e.key === 'Escape' || e.code === 'Escape') {
              handleCancelEdition();
            }
          }}
          testId="temporary-note-card-textarea"
        />
      </Card.Body>
      <Card.Footer>
        <Card.Footer.Right>
          <Button
            variant="link"
            size="small"
            margin="0 1rem 0 0"
            onClick={handleCancelEdition}
            testId="temporary-note-card-cancel-button"
          >
            <FormattedMessage id="global.cancel" />
          </Button>
          <Button
            variant="primary"
            size="small"
            onClick={
              operation === 'edit'
                ? () => handleSaveClicked(content)
                : () => handleInsertNote(content)
            }
            testId={`temporary-note-card-${operation === 'edit' ? 'save' : 'create'}-button`}
          >
            {operation === 'edit' ? (
              <FormattedMessage id="global.save" />
            ) : (
              <FormattedMessage id="global.create" />
            )}
          </Button>
        </Card.Footer.Right>
      </Card.Footer>
    </Card>
  );
};

export default EditableNoteCard;
