import { useMemo, useEffect } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { selectIsIEnvision } from 'App/redux/appSlice';
import { selectCollaboratorsAndSuggestionsAuthors } from 'Editor/redux/EditorStatusSlice';

import { FilterDisplay, FilterDrawer } from '_common/components';
import { UserOption } from '_common/components/SearchUser/SearchUser';

import TrackedActionList from './TrackedActionList/TrackedActionList';
import TrackedActionButtons from './TrackedActionButtons/TrackedActionButtons';
import HideTrackedActions from './HideTrackedActions/HideTrackedActions';
import { completeAction } from 'App/redux/onboardingSlice';

const TrackedActionTab = () => {
  const dispatch = useDispatch();

  const isIEnvision = useSelector(selectIsIEnvision);
  const collaborators = useSelector(selectCollaboratorsAndSuggestionsAuthors);
  const collaboratorOptions = useMemo(
    () =>
      collaborators.map(
        (collaborator) =>
          ({
            id: collaborator.id,
            type: 'user',
            imported: collaborator.imported,
          } as UserOption),
      ),
    [collaborators],
  );

  useEffect(() => {
    dispatch(completeAction('editor_suggestions_openSidePanel'));
  }, []);

  return (
    <>
      {!isIEnvision && <TrackedActionButtons />}
      <FilterDrawer
        identity="editorTrackPanel"
        trackedActionType={{
          options: [
            { value: 'INSERT', label: 'editor.sidebar.review.filter.type.add' },
            { value: 'DELETE', label: 'editor.sidebar.review.filter.type.remove' },
            { value: 'REPLACE', label: 'editor.sidebar.review.filter.type.replace' },
            { value: 'FORMAT', label: 'editor.sidebar.review.filter.type.format' },
          ],
        }}
        cardPriority={{
          options: [
            { value: 'LOW', label: 'editor.sidebar.review.filter.priority.low' },
            { value: 'MEDIUM', label: 'editor.sidebar.review.filter.priority.medium' },
            { value: 'HIGH', label: 'editor.sidebar.review.filter.priority.high' },
          ],
        }}
        author={{ options: collaboratorOptions, settings: { editorAvatar: true } }}
      />
      <HideTrackedActions />
      <FilterDisplay identity="editorTrackPanel" direction="column" margin="0 0 2rem 0" />
      <TrackedActionList />
    </>
  );
};

export default TrackedActionTab;
