import { useIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { useParams } from 'react-router';

import { useSelector } from '_common/hooks';
import { navigateToMyFiles } from 'router/history';

import { useGetRoleQuery } from '../TenantSettingsPage/Roles/RolesApi';

import { TabMenu, MenuItem, ErrorView, IntlErrorBoundary } from '_common/components';

import RoleGeneralTab from 'Settings/pages/RoleSettingsPage/RoleGeneralTab/RoleGeneralTab';
import RoleObjectPermissionsTab from 'Settings/pages/RoleSettingsPage/RoleObjectPermissionsTab/RoleObjectPermissionsTab';

import styles from './RoleSettingsPage.module.scss';
import Header from './Header';

const RoleSettingsPage = () => {
  const intl = useIntl();

  const { id: objectId } = useParams<RouterParams['roleSettings']>();

  const { data: role } = useGetRoleQuery(objectId);
  const error = useSelector((state) => state.app.error);

  const checkErrorStatus = () => {
    return error.status === 400 || error.status === 403 || error.status === 404;
  };

  // check for forbidden view
  if (checkErrorStatus()) {
    return <ErrorView error={error} onClick={navigateToMyFiles} />;
  }

  if (!role) {
    return <div />;
  }

  return (
    <DocumentTitle title={`doDOC - ${role.name}`}>
      <div className={styles.root}>
        <div className={styles.header}>
          <IntlErrorBoundary size="small" margin="0 0 0 7rem">
            <Header />
          </IntlErrorBoundary>
        </div>
        <div className={styles.page}>
          <IntlErrorBoundary size="large" margin="13.5rem 0 0 0">
            <TabMenu menuId="roleSettings">
              <MenuItem text={intl.formatMessage({ id: 'settings.tabs.general' })} id="general">
                <RoleGeneralTab role={role} />
              </MenuItem>
              <MenuItem
                text={intl.formatMessage({
                  id: 'settings.roles.objectPermissions',
                })}
                id="objectPermissions"
              >
                <RoleObjectPermissionsTab role={role} />
              </MenuItem>
            </TabMenu>
          </IntlErrorBoundary>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default RoleSettingsPage;
