import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { selectCollaborators } from 'App/redux/appSlice';
import { completeAction } from 'App/redux/onboardingSlice';
import {
  cancelTemporaryComment,
  focusedTemporaryComment,
  selectFilteredComments,
} from 'Editor/redux/CommentsSlice';
import EditorManager from 'Editor/services/EditorManager';
import { InteractionController, RichTextEditor } from '_common/components';
import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';
import { UserPresentation } from '_common/suite/components/Card';
import { Card } from '_common/suite/components';
import { stringToRichText } from 'utils';

type EditableCommentCarProps = {
  id?: string;
  user: UserId;
  sidebar?: boolean;
  selected?: boolean;
  setEditMode: (isEdit: boolean) => void;
  testId: string;
} & (
  | { isTemporary?: true; comment?: undefined }
  | { isTemporary?: false; comment: Editor.Comment }
);

const EditableCommentCard = ({
  id,
  comment,
  isTemporary,
  user,
  selected,
  sidebar,
  setEditMode,
  testId,
}: EditableCommentCarProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const shouldFocus = useSelector((state) => state.editor.comments.insert.shouldFocus);
  const comments = useSelector(selectFilteredComments);

  const collaborators = useSelector((state) =>
    selectCollaborators(state, state.editor.status.documentId),
  );

  const [content, setContent] = useState(comment?.content);

  const handleCancelEdition = () => {
    if (isTemporary) {
      EditorManager.getInstance().removeTemporaryComment();
    }
    setEditMode(false);
  };

  const handleEditContent = () => {
    if (isTemporary) {
      EditorManager.getInstance().addComment(stringToRichText(content));
      dispatch(completeAction('editor_comments_createComment'));
      dispatch(cancelTemporaryComment());
    } else {
      if (comment) {
        EditorManager.getInstance()
          .editComment(comment.id, stringToRichText(content))
          .then(() => {
            notify({
              type: 'success',
              title: 'COMMENT_EDITED',
              message: 'THE_COMMENT_WAS_SUCCESSFULLY_EDITED',
            });
          });
      }
    }
    setEditMode(false);
  };

  const handleTemporaryCommentFocused = () => {
    if (isTemporary) {
      dispatch(focusedTemporaryComment());
    }
  };

  const handleTemporaryCommentSelected = () => {
    if (comments.insert?.inserting && comments.insert?.reference && isTemporary) {
      EditorManager.getInstance().focusComment(comments.insert.reference);
    }
  };

  return (
    <Card
      id={isTemporary ? `Comment#${comments.insert.reference}` : id}
      selected={selected}
      sidebar={sidebar}
      onClick={isTemporary ? handleTemporaryCommentSelected : undefined}
      testId={testId}
    >
      <Card.Header>
        <Card.Header.Left>
          <UserPresentation
            userId={comment?.author ?? user}
            fixedName={!comment?.author ? comment?.user : undefined}
            creationDate={comment?.time}
          />
        </Card.Header.Left>
      </Card.Header>
      <Card.Body>
        <InteractionController
          environment="editor"
          rules={[
            {
              interaction: 'editor_sidepanel_newComment',
              actions: ['editor_comments_createComment'],
            },
          ]}
        >
          <RichTextEditor
            initialValue={content}
            mentionableUsers={collaborators}
            placeholder={intl.formatMessage({ id: 'INSERT_YOUR_COMMENT_HERE' })}
            onChange={setContent}
            onFocus={handleTemporaryCommentFocused}
            skipFocus={isTemporary && !shouldFocus}
            testId="temporary-card-rich-text-editor"
          />
        </InteractionController>
      </Card.Body>
      <Card.Footer>
        <Card.Footer.Right>
          <Button
            variant="link"
            size="small"
            margin="0 0.5rem 0 0"
            onClick={handleCancelEdition}
            testId="annotation-cancel-button"
            id={`${testId}-cancel-button`}
          >
            <FormattedMessage id="global.cancel" />
          </Button>
          <InteractionController
            environment="editor"
            rules={[
              {
                interaction: 'editor_sidepanel_newComment',
                actions: ['editor_comments_createComment'],
              },
            ]}
          >
            <Button
              variant="primary"
              size="small"
              onClick={handleEditContent}
              testId="annotation-save-button"
              id={`${testId}-cta-button`}
            >
              {isTemporary ? (
                <FormattedMessage id="global.create" />
              ) : (
                <FormattedMessage id="global.save" />
              )}
            </Button>
          </InteractionController>
        </Card.Footer.Right>
      </Card.Footer>
    </Card>
  );
};

export default EditableCommentCard;
