import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks/redux';
import { Button, Modal, Upload, ProgressBar, Icon } from 'dodoc-design-system';
import { closeAndResetModal, updateModal } from '_common/modals/ModalsSlice';
import { useUploadTagsMutation } from 'Settings/pages/TenantSettingsPage/Tags/TagsApi';
import styles from './UploadTagsModal.module.scss';

const MODAL = 'UploadTagsModal';

const UploadTagsModal = () => {
  const intl = useIntl();
  // redux
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const state = useSelector((state) => state.modals[MODAL].state);
  const [mutUploadTags] = useUploadTagsMutation();

  // local
  const [file, setFile] = useState<File>();
  const [progress, setProgress] = useState<number>(0);

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const uploadDifferentFile = () => {
    dispatch(
      updateModal({
        modal: MODAL,
        data: {
          state: 'standby',
        },
      }),
    );
  };

  const handleFileUpload = (file: File, force: boolean) => {
    mutUploadTags({ file: file, force: force, onUploadProgress: setProgress });
  };

  const renderHeader = () => {
    switch (state) {
      case 'uploading':
      case 'standby':
        return <FormattedMessage id="UPLOAD_TAGS" />;
      case 'alreadyExist':
      case 'duplicated':
        return <FormattedMessage id="UPLOAD_TAGS_WARNING" />;
      case 'failed':
        return <FormattedMessage id="UPLOAD_TAGS_ERROR" />;
      default:
        return null;
    }
  };

  const renderBody = () => {
    switch (state) {
      case 'standby':
        return (
          <Upload
            text={intl.formatMessage({ id: 'DRAG_AND_DROP_TAGS_FILE' })}
            accept=".csv"
            onDrop={(_, files: File[]) => {
              handleFileUpload(files[0], false);
              setFile(files[0]);
            }}
            testId="upload-tags-input"
          />
        );
      case 'uploading':
        return (
          <div className={styles.uploading}>
            <Icon icon="File" size={96} />
            <FormattedMessage id="UPLOADING_YOUR_FILE" />
            <div style={{ marginTop: '3rem' }}>
              <ProgressBar progress={!!progress} testId="upload-tags-progressBar" />
            </div>
          </div>
        );
      case 'duplicated':
        return <FormattedMessage id="IMPORTED_DUPLICATED_TAGS_WARNING" />;
      case 'alreadyExist':
        return <FormattedMessage id="IMPORT_TAGS_THAT_ALREADY_EXIST_WARNING" />;
      case 'failed':
        return <FormattedMessage id="IMPORTED_TAGS_ERROR" />;
      default:
        return null;
    }
  };

  const renderFooter = () => {
    switch (state) {
      case 'duplicated':
        return (
          <>
            <Button size="medium" onClick={uploadDifferentFile} testId="different-file-button">
              <FormattedMessage id="UPLOAD_DIFFERENT_FILE" />
            </Button>
            <Button
              size="medium"
              variant="primary"
              onClick={() => {
                if (file) {
                  handleFileUpload(file, true);
                }
              }}
              testId="proceed-button"
            >
              <FormattedMessage id="PROCEED" />
            </Button>
          </>
        );
      case 'alreadyExist':
        return (
          <>
            <Button size="medium" onClick={close} testId="go-back-button">
              <FormattedMessage id="GO_BACK" />
            </Button>
            <Button
              size="medium"
              variant="primary"
              onClick={uploadDifferentFile}
              testId="different-file-button"
            >
              <FormattedMessage id="UPLOAD_DIFFERENT_FILE" />
            </Button>
          </>
        );
      case 'failed':
        return (
          <>
            <Button size="medium" onClick={close} testId="go-back-button">
              <FormattedMessage id="GO_BACK" />
            </Button>
            <Button
              size="medium"
              variant="primary"
              onClick={uploadDifferentFile}
              testId="try-again-button"
            >
              <FormattedMessage id="TRY_AGAIN" />
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="68rem" testId="upload-tags">
      <Modal.Header onClose={close}>{renderHeader()}</Modal.Header>
      <Modal.Body>{renderBody()}</Modal.Body>
      <Modal.Footer>{renderFooter()}</Modal.Footer>
    </Modal>
  );
};

export default UploadTagsModal;
