import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { InstanceService, Logger } from '_common/services';

export type NotificationsFilter =
  | 'obj_permissions'
  | 'comments'
  | 'suggestions'
  | 'tasks'
  | 'pdf_tasks'
  | 'pdf_annotations';

export type NotificationsSliceState = {
  list: ObjectList;
  dict: { [key in string]: NotificationRecord };
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  request: Request.OrderListParams;
  unreadCount: number;
  popoverOpen: boolean;
  stopUnreadRequest: boolean;
};

const REQUEST_INITIAL_STATE: NotificationsSliceState['request'] = {
  offset: 0,
  size: 200,
  order_type: 'desc',
  order_field: 'time.creation',
};

const SLICE_NAME = 'NOTIFICATIONS';
export const initialState: NotificationsSliceState = {
  list: [],
  dict: {},
  hasNextPage: false,
  isNextPageLoading: false,
  request: REQUEST_INITIAL_STATE,
  unreadCount: 0,
  popoverOpen: false,
  stopUnreadRequest: false,
};

// #region AsyncThunks
export const listNotifications = createAsyncThunk(
  `${SLICE_NAME}/listNotifications`,
  async ({ filterParams }: { filterParams: Request.FilterParams }, { getState }) => {
    const validRequest = { ...(getState() as RootState).notifications?.request, ...filterParams };
    const documentId = (getState() as RootState).editor?.status?.documentId;
    const pdf = (getState() as RootState).pdf.general;
    const pdfId = pdf.pdfId ?? '';

    let categoryFilters: string[] = [];
    const params: Request.AllListParams = {
      ...validRequest,
      filter_fields: [],
      filter_values: [],
      order_type: validRequest.order_type,
      order_field: validRequest.order_field,
    };
    const filterFields = validRequest.filter_fields.sort((a, b) => {
      if (a > b) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
      return 0;
    });

    for (let i = 0; i < validRequest.filter_values.length; i++) {
      if (
        validRequest.filter_values[i].includes('comments') ||
        validRequest.filter_values[i].includes('suggestions') ||
        validRequest.filter_values[i].includes('tasks') ||
        validRequest.filter_values[i].includes('obj_permissions') ||
        validRequest.filter_values[i].includes('pdf_tasks') ||
        validRequest.filter_values[i].includes('pdf_annotations')
      ) {
        categoryFilters.push(validRequest.filter_values[i]);
      }
    }

    if (validRequest.filter_values.length > 1) {
      if (validRequest.filter_values.includes('false')) {
        if ((documentId || pdfId) && validRequest.filter_fields.includes('target')) {
          params.filter_fields = filterFields;
          params.filter_values =
            decodeURIComponent(categoryFilters.toString()).length > 0
              ? [decodeURIComponent(categoryFilters.toString()), documentId || pdfId, 'false']
              : [documentId || pdfId, 'false'];
        } else {
          params.filter_fields = filterFields;
          params.filter_values = [decodeURIComponent(categoryFilters.toString()), 'false'];
        }
      } else if ((documentId || pdfId) && validRequest.filter_fields.includes('target')) {
        params.filter_fields = filterFields;
        params.filter_values = [
          decodeURIComponent(categoryFilters.toString()),
          documentId || pdfId,
        ];
      } else {
        params.filter_fields = validRequest.filter_fields;
        params.filter_values = [decodeURIComponent(validRequest.filter_values.toString())];
      }
    } else {
      params.filter_fields = validRequest.filter_fields;
      params.filter_values = validRequest.filter_values;
    }

    const { data: payload } = await new InstanceService().listNotifications(validRequest);

    const dict: { [key in string]: NotificationRecord } = {};
    const list: NotificationRecord['id'][] = [];
    payload.forEach((node: NotificationRecord) => {
      const id = node.id;

      dict[id] = node;
      list.push(id);
    });

    return {
      list,
      dict,
      request: validRequest,
    };
  },
);
export const lazyNotifications = createAsyncThunk(
  `${SLICE_NAME}/lazyNotifications`,
  async (_, { getState }) => {
    const request = (getState() as RootState).notifications?.request;

    const { data: payload } = await new InstanceService().listNotifications(request);

    const dict: { [key in string]: NotificationRecord } = {};
    const list: NotificationRecord['id'][] = [];
    payload.forEach((node: NotificationRecord) => {
      const id = node.id;

      dict[id] = node;
      list.push(id);
    });

    return {
      list,
      dict,
    };
  },
);

export const editNotification = createAsyncThunk(
  `${SLICE_NAME}/editNotification`,
  async ({ id, parameters }: { id: ObjectId; parameters: any }) => {
    const { data } = await new InstanceService().editNotification(id, parameters);
    return data;
  },
);

export const markAllNotificationsAsRead = createAsyncThunk(
  `${SLICE_NAME}/markAllNotificationsAsRead`,
  async () => {
    await new InstanceService().markAllNotificationsAsRead();
  },
);

export const getUnreadNotificationsCount = createAsyncThunk(
  `${SLICE_NAME}/getUnreadNotificationsCount`,
  async ({ request }: { request: Request.FilterParams | null }) => {
    const { data } = await new InstanceService().getUnreadNotificationsCount(request);
    return { unreadCount: data.unread };
  },
);
// #endregion

// #region Selectors
const getList = (state: RootState) => state.notifications.list;
const getDict = (state: RootState) => state.notifications.dict;

export const selectStartOfSections = createSelector([getList, getDict], (list, dict) => {
  try {
    let startOfToday: number | false = false;
    let startOfYesterday: number | false = false;
    let startOfOlder: number | false = false;

    let djToday = dayjs();
    let djYesterday = djToday.subtract(1, 'day');

    startOfToday =
      dict[list[0]] && djToday.isSame(dayjs(dict[list[0]].action?.time), 'day') ? 0 : false;

    for (let i = (startOfToday || -1) + 1; i < list.length; i++) {
      if (djYesterday.isSame(dayjs(dict[list[i]].action?.time), 'day')) {
        startOfYesterday = i;
        break;
      }
    }

    for (let i = (startOfYesterday || startOfToday || -1) + 1; i < list.length; i++) {
      if (djYesterday.isAfter(dayjs(dict[list[i]].action?.time), 'day')) {
        startOfOlder = i;
        break;
      }
    }

    return { startOfToday, startOfYesterday, startOfOlder };
  } catch (e) {
    Logger.captureException(e);
  }
});
// #endregion

// #region Slice
const NotificationsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setNotificationPopoverState: (
      state,
      action: PayloadAction<NotificationsSliceState['popoverOpen']>,
    ) => {
      state.popoverOpen = action.payload;
    },

    resetOffset: (state) => {
      state.hasNextPage = initialState.hasNextPage;
      state.isNextPageLoading = initialState.isNextPageLoading;
      state.request.offset = initialState.request.offset;
    },
    setStopUnreadRequest: (
      state,
      action: PayloadAction<NotificationsSliceState['stopUnreadRequest']>,
    ) => {
      state.stopUnreadRequest = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(listNotifications.fulfilled, (state, action) => {
      const { list, dict } = action.payload;

      state.list = list;
      state.dict = dict;
      state.hasNextPage = list.length === state.request.size;
      state.isNextPageLoading = false;
      state.request = {
        ...action.payload.request,
        offset: list.length,
      };
    });
    builder.addCase(lazyNotifications.fulfilled, (state, action) => {
      const { list, dict } = action.payload;
      state.list = state.list.concat(list.filter((objectId) => !state.list.includes(objectId)));
      state.dict = Object.assign(state.dict, dict);
      state.hasNextPage = list.length >= state.request.size;
      state.request.offset += list.length;
    });
    builder.addCase(editNotification.fulfilled, (state, action) => {
      const { id, read } = action.payload;
      state.dict[id].read = read;

      state.unreadCount += read ? -1 : 1;
    });
    builder.addCase(markAllNotificationsAsRead.fulfilled, (state) => {
      state.list.forEach((notificationId: ObjectId) => {
        state.dict[notificationId].read = true;
      });

      state.unreadCount -= state.list.length;
    });
    builder.addCase(getUnreadNotificationsCount.fulfilled, (state, action) => {
      const { unreadCount } = action.payload;
      state.unreadCount = unreadCount || 0;
    });
  },
});

export default NotificationsSlice.reducer;
// #endregion

// #region Actions
export const { setNotificationPopoverState, resetOffset, setStopUnreadRequest } =
  NotificationsSlice.actions;
// #endregion
