import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { openModal } from '_common/modals/ModalsSlice';

import styles from './MissingFontsLabel.module.scss';

const MissingFontsLabel = () => {
  const dispatch = useDispatch();

  const missingFonts = useSelector((state) => state.editor.fonts.missing);

  if (missingFonts.length === 0) {
    return null;
  }
  const handleOnClick = () => {
    dispatch(openModal('MissingFontsModal'));
  };

  return (
    <div className={styles.root} onClick={handleOnClick}>
      <FormattedMessage id="MISSING_FONTS" />
    </div>
  );
};

export default MissingFontsLabel;
