import { useEffect, useState } from 'react';
import * as docx from 'docx-preview';
import { useDownloadQuery } from '_common/services/api/FileApi';
import './DOCXPreview.css';
import ObjectPreviewControls from './ObjectPreviewControls';

type DOCXPreviewProps = {
  object: doDOC.File;
};

const DOCXPreview = ({ object }: DOCXPreviewProps) => {
  const { data } = useDownloadQuery({ object_id: object.id });
  const [zoom, setZoom] = useState({ label: '100%', value: '1' });

  useEffect(() => {
    if (data) {
      data.arrayBuffer().then((arrayBuffer) => {
        const container = document.getElementById('container');
        if (container) {
          docx.renderAsync(arrayBuffer, container);
        }
      });
    }
  }, [data]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div
        id="container"
        style={{ transform: `scale(${zoom.value})`, transformOrigin: 'top' }}
        data-testid="object-preview-docx-container"
      />
      <ObjectPreviewControls zoom={zoom} setZoom={setZoom} showPagination={false} object={object} />
    </div>
  );
};

export default DOCXPreview;
