import { DOMUtils } from '_common/utils';
import { AnnotationFlags } from '../../models';
import { AnnotationBuilder } from './AnnotationBuilder';

export class AnnotationsFactory {
  Data: PDF.Data.State;

  constructor(Data: PDF.Data.State) {
    this.Data = Data;
  }

  private annotationMapper: PDF.AnnotationFactoryMapper = {
    Highlight: this.builTextMarkup.bind(this),
    Text: this.notImplemented.bind(this),
    Link: this.notImplemented.bind(this),
    FreeText: this.buildFreeText.bind(this),
    Line: this.buildLine.bind(this),
    Square: this.buildSquareShape.bind(this),
    Circle: this.buildCircleShape.bind(this),
    Polygon: this.notImplemented.bind(this),
    PolyLine: this.notImplemented.bind(this),
    Underline: this.builTextMarkup.bind(this),
    Squiggly: this.notImplemented.bind(this),
    StrikeOut: this.builTextMarkup.bind(this),
    Stamp: this.notImplemented.bind(this),
    Caret: this.notImplemented.bind(this),
    Ink: this.buildInk.bind(this),
    Popup: this.notImplemented.bind(this),
    FileAttachment: this.notImplemented.bind(this),
    Sound: this.notImplemented.bind(this),
    Movie: this.notImplemented.bind(this),
    Widget: this.notImplemented.bind(this),
    Screen: this.notImplemented.bind(this),
    PrinterMark: this.notImplemented.bind(this),
    TrapNet: this.notImplemented.bind(this),
    Watermark: this.notImplemented.bind(this),
    '3D': this.notImplemented.bind(this),
    Redact: this.notImplemented.bind(this),
    Note: this.buildNote.bind(this),
    Task: this.buildTask.bind(this),
  };

  private notImplemented(creationData: PDF.AnnotationCreationData): void {
    logger.warn('Annotation builder not implemented!');
  }

  private builTextMarkup(creationData: PDF.TextMarkupCreationData): PDF.Annotation.TextMarkup {
    const builder = new AnnotationBuilder(creationData);
    builder
      .setAuthor(this.Data.users?.loggedUserId)
      .setQuadPoints(
        this.getRelativeQuadPointsFromDOMRects(
          creationData.rects,
          creationData.pageRect,
          creationData.viewportScale,
        ),
      );
    return builder.get() as PDF.Annotation.TextMarkup;
  }

  private buildTask(creationData: PDF.TaskCreationData): PDF.Annotation.Task {
    const builder = new AnnotationBuilder(creationData);
    builder.setAuthor(this.Data.users?.loggedUserId);
    if (creationData.isFreestyle) {
      builder.setAnnotationFlags(
        AnnotationFlags.Print + AnnotationFlags.NoZoom + AnnotationFlags.NoRotate,
      );
    } else {
      // build quand points for rects
      builder.setQuadPoints(
        this.getRelativeQuadPointsFromDOMRects(
          creationData.rects,
          creationData.pageRect,
          creationData.viewportScale,
        ),
      );
    }
    return builder.get() as PDF.Annotation.Task;
  }

  private buildNote(creationData: PDF.NoteCreationData): PDF.Annotation {
    const builder = new AnnotationBuilder(creationData);
    builder
      .setAuthor(this.Data.users?.loggedUserId)
      .setAnnotationFlags(
        AnnotationFlags.Print + AnnotationFlags.NoZoom + AnnotationFlags.NoRotate,
      );
    return builder.get();
  }

  private buildInk(creationData: PDF.InkCreationData): PDF.Annotation.Ink {
    const builder = new AnnotationBuilder(creationData);
    builder
      .setAuthor(this.Data.users?.loggedUserId)
      .setRect(creationData.pageRect, creationData.boundingRect, creationData.viewportScale);
    if (creationData.inkList && creationData.pageRect && creationData.viewportScale) {
      builder.setInkLists(
        this.getRelativeInkList(
          creationData.inkList,
          creationData.pageRect,
          creationData.viewportScale,
        ),
      );
    }
    return builder.get() as PDF.Annotation.Ink;
  }

  private buildLine(creationData: PDF.LineCreationData): PDF.Annotation.Line {
    const builder = new AnnotationBuilder(creationData);
    builder
      .setAuthor(this.Data.users?.loggedUserId)
      .setLineCoordinates(
        this.getRelativeLineCoordinates(
          creationData.lineCoordinates,
          creationData.pageRect,
          creationData.viewportScale,
        ),
      );
    builder.setLineEndings(creationData.lineEndings);
    builder.setRect(creationData.pageRect, creationData.boundingRect, creationData.viewportScale);
    return builder.get() as PDF.Annotation.Line;
  }

  private buildFreeText(creationData: PDF.FreeTextCreationData): PDF.Annotation {
    const builder = new AnnotationBuilder(creationData);
    builder.setAuthor(this.Data.users?.loggedUserId);
    return builder.get();
  }

  private buildSquareShape(creationData: PDF.CommonCreationData): PDF.Annotation {
    const builder = new AnnotationBuilder(creationData);
    builder
      .setAuthor(this.Data.users?.loggedUserId)
      .setRect(
        creationData.pageRect,
        creationData.boundingRect,
        creationData.viewportScale,
        creationData.border?.width,
      );
    return builder.get();
  }

  private buildCircleShape(creationData: PDF.CommonCreationData): PDF.Annotation {
    const builder = new AnnotationBuilder(creationData);
    builder
      .setAuthor(this.Data.users?.loggedUserId)
      .setRect(creationData.pageRect, creationData.boundingRect, creationData.viewportScale);
    return builder.get();
  }

  getRelativeRectFromDOMRect(
    boundingRect: PDF.Annotation.Rect | DOMRect,
    pageRect: PDF.Annotation.Rect | DOMRect,
    viewportScale: number,
  ) {
    const rect: PDF.Annotation.Rect = {
      left: 0,
      bottom: 0,
      right: 0,
      top: 0,
      width: 0,
      height: 0,
    };

    if (boundingRect && pageRect && viewportScale) {
      rect.left = (boundingRect.left - pageRect.left) / viewportScale;
      rect.right = (boundingRect.right - pageRect.left) / viewportScale;
      rect.top = (pageRect.bottom - boundingRect.top) / viewportScale;
      rect.bottom = (pageRect.bottom - boundingRect.bottom) / viewportScale;
      rect.width = Math.abs(rect.right - rect.left);
      rect.height = Math.abs(rect.top - rect.bottom);
    }

    return rect;
  }

  getRelativeQuadPointsFromDOMRects(
    rects: PDF.Annotation.Rect[] | DOMRectList | undefined,
    pageRect: PDF.Annotation.Rect | DOMRect,
    viewportScale: number,
  ) {
    const quadPoints: PDF.Annotation.TextMarkup['quadPoints'] = [];

    // TODO: filter rect list for quad points

    if (rects?.length && pageRect && viewportScale) {
      let left, right, top, bottom;

      const filteredRects = DOMUtils.filterAndAdjustDOMRectList(rects);

      for (let i = 0; i < filteredRects.length; i++) {
        const rect = filteredRects[i];

        left = (rect.left - pageRect.left) / viewportScale;
        right = (rect.right - pageRect.left) / viewportScale;
        top = (pageRect.bottom - rect.top) / viewportScale;
        bottom = (pageRect.bottom - rect.bottom) / viewportScale;

        quadPoints.push({
          topLeft: { x: left, y: top },
          topRight: { x: right, y: top },
          bottomRight: { x: right, y: bottom },
          bottomLeft: { x: left, y: bottom },
        });
      }
    }

    return quadPoints;
  }

  getRelativeLineCoordinates(
    lineCoordinates: PDF.Annotation.LineCoordinates,
    pageRect: PDF.Annotation.Rect | DOMRect,
    viewportScale: number,
  ) {
    let coords: PDF.Annotation.LineCoordinates;

    coords = {
      start: {
        x: lineCoordinates.start.x,
        y: pageRect.height / viewportScale - lineCoordinates.start.y,
      },
      end: {
        x: lineCoordinates.end.x,
        y: pageRect.height / viewportScale - lineCoordinates.end.y,
      },
    };

    return coords;
  }

  getRelativeInkList(
    inkList: PDF.Annotation.Ink['inkLists'],
    pageRect: PDF.Annotation.Rect | DOMRect,
    viewportScale: number,
  ) {
    let inkCoords: PDF.Annotation.Ink['inkLists'] = [];

    for (let i = 0; i < inkList.length; i++) {
      let lineCoords: PDF.Annotation.Point[] = [];
      for (let j = 0; j < inkList[i].length; j++) {
        const point = inkList[i][j];

        const x = point.x;
        const y = pageRect.height / viewportScale - point.y;

        lineCoords.push({
          x,
          y,
        });
      }

      inkCoords.push(lineCoords);
    }

    return inkCoords;
  }

  getAnnotation(creationData: PDF.AnnotationCreationData) {
    const data = this.annotationMapper[creationData.subtype](creationData);
    logger.info('AnnotationFactory getAnnotation ' + creationData, data);
    return data;
  }
}
