import { ELEMENTS } from 'Editor/services/consts';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import { DOMUtils } from '_common/utils/DOMUtils';
import { EditorDOMElements } from './EditorDOMElements';

export class EditorDOMUtils extends DOMUtils {
  static waitForNodeToRender(elementId: string, filter?: Function): Promise<void> {
    return new Promise((resolve, reject) => {
      const poll = setInterval(() => {
        try {
          const node = document.getElementById(elementId);
          if (!node) {
            return;
          }

          if (!filter || filter(node)) {
            clearInterval(poll);
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      }, 1000);
    });
  }

  static appendNode(parent: Node | null, node: Node, normalize = true) {
    if (!(parent instanceof Element)) {
      logger.warn('parent is not an Element!');
      return;
    }

    if (normalize) {
      DOMNormalizer.normalizeTree(node, parent.id);
    }
    parent.appendChild(node);
  }

  static replaceNode(parent: Node | null, newNode: Node, node: Node, normalize = true) {
    if (!(parent instanceof Element)) {
      logger.warn('parent is not an Element!');
      return;
    }

    // TODO: check old node permissions (copy id)
    if (normalize) {
      DOMNormalizer.normalizeTree(newNode, parent.id);
    }
    parent.replaceChild(newNode, node);
  }

  static insertNodeBefore(parent: Node | null, node: Node, before: Node | null, normalize = true) {
    if (!(parent instanceof Element)) {
      logger.warn('parent is not an Element!');
      return;
    }

    if (normalize) {
      DOMNormalizer.normalizeTree(node, parent.id);
    }
    parent.insertBefore(node, before);
  }

  static insertNodeAfter(parent: Node | null, node: Node, after: Node | null, normalize = true) {
    if (!(parent instanceof Element)) {
      logger.warn('parent is not an Element!');
      return;
    }

    if (after && after.nextSibling) {
      EditorDOMUtils.insertNodeBefore(parent, node, after.nextSibling, normalize);
    } else {
      EditorDOMUtils.appendNode(parent, node, normalize);
    }
  }

  static getContentContainer(node?: Node): Node | null {
    if (node) {
      const closestSection = EditorDOMUtils.closest(node, ELEMENTS.SectionElement.TAG);
      if (closestSection) {
        return closestSection;
      }
    }

    return document.querySelector('[ispagenode="true"]');
  }

  static getNode(id: string | undefined, page = EditorDOMUtils.getContentContainer()) {
    if (!id) {
      return undefined;
    }

    const node = document.getElementById(id);

    if (node && page?.contains(node)) {
      return node;
    }

    return undefined;
  }

  static getBlockNode(id: string | undefined) {
    if (!id) {
      return null;
    }

    let node: Node | null = document.getElementById(id);
    if (!node) {
      return null;
    }

    let container = EditorDOMUtils.getContentContainer(node);

    if (node.parentNode !== container) {
      node = EditorDOMUtils.findFirstLevelChildNode(container, node);
    }

    return node;
  }

  static isClosestTextElementEditable(node: Node | null) {
    if (!node) {
      return false;
    }

    const container = EditorDOMUtils.getContentContainer(node);
    if (!container) {
      return false;
    }

    let closestBlock = EditorDOMUtils.closest(node, EditorDOMElements.BLOCK_TEXT_ELEMENTS);
    if (!closestBlock) {
      return false;
    }

    if (container.contains(closestBlock) && closestBlock.parentNode !== container) {
      closestBlock = EditorDOMUtils.findFirstLevelChildNode(container, closestBlock);
    }

    if (EditorDOMElements.isSupportedElement(closestBlock) && closestBlock.isEditable) {
      return true;
    }
    return false;
  }

  static isClosestBlockNodeEditable(node: Node | null) {
    if (!node) {
      return false;
    }

    const container = EditorDOMUtils.getContentContainer(node);
    if (!container) {
      return false;
    }

    let closest = EditorDOMUtils.closest(node, EditorDOMElements.EDITABLE_LEVEL0_ELEMENTS);
    if (!closest) {
      return false;
    }

    if (container.contains(closest) && closest.parentNode !== container) {
      closest = EditorDOMUtils.findFirstLevelChildNode(container, closest);
    }

    if (EditorDOMElements.isSupportedElement(closest) && closest.isEditable) {
      return true;
    }

    return false;
  }

  static closestContainerElement(node: Node | null) {
    let closestContainer;
    let nodeToCheck = node;
    while (
      (closestContainer = EditorDOMUtils.closest(
        nodeToCheck,
        EditorDOMElements.BLOCK_CONTAINER_ELEMENTS,
      ))
    ) {
      if (
        EditorDOMElements.isSupportedElement(closestContainer) &&
        closestContainer.isContainerElement
      ) {
        return closestContainer;
      }
      nodeToCheck = closestContainer.parentNode;
    }
    return null;
  }

  static closestMultiBlockContainerElement(node: Node | null) {
    let closestContainer;
    let nodeToCheck = node;
    while (
      (closestContainer = EditorDOMUtils.closest(
        nodeToCheck,
        EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS,
      ))
    ) {
      if (
        EditorDOMElements.isSupportedElement(closestContainer) &&
        closestContainer.isContainerElement
      ) {
        return closestContainer;
      }
      nodeToCheck = closestContainer.parentNode;
    }
    return null;
  }

  static closestBlockTextElement(node: Node | null): Editor.Elements.BlockTextElements | null {
    let closest;
    let nodeToCheck = node;
    while ((closest = EditorDOMUtils.closest(nodeToCheck, EditorDOMElements.BLOCK_TEXT_ELEMENTS))) {
      if (EditorDOMElements.isNodeBlockTextElement(closest)) {
        return closest;
      }
      nodeToCheck = closest.parentNode;
    }

    return null;
  }

  static getPreviousSelectableElement(
    blockNode: Node | null,
    selectedElement: Node | null,
    selectedNode: Node | null,
    tableSelectionOption: 'row' | 'cell' = 'cell',
  ): Node | null {
    let selectableContent: Node | null = null;

    if (!blockNode) {
      return null;
    }

    let closestSection = EditorDOMUtils.closest(
      blockNode,
      ELEMENTS.SectionElement.TAG,
    ) as Editor.Elements.SectionElement;
    let closestPage = EditorDOMUtils.closest(
      blockNode,
      ELEMENTS.PageElement.TAG,
    ) as Editor.Elements.PageElement;

    let previousSibling: Node | null = blockNode.previousSibling;
    if (EditorDOMElements.isTableElement(blockNode)) {
      const closestCell = EditorDOMUtils.closest(selectedElement, [ELEMENTS.TableCellElement.TAG]);
      if (EditorDOMElements.isTableCellElement(closestCell)) {
        // WARN:
        // remove cell selection temporary
        // check before commit a6dfbbd
        selectableContent = EditorDOMUtils.getPreviousSelectableElement(
          EditorDOMUtils.findFirstLevelChildNode(closestCell, selectedNode),
          selectedElement,
          selectedNode,
          tableSelectionOption,
        );

        if (!selectableContent) {
          if (tableSelectionOption === 'cell') {
            // check next cell
            if (closestCell.previousSibling) {
              previousSibling = closestCell.previousSibling.lastChild;
            } else {
              const row = closestCell.parentNode;

              if (row?.previousSibling) {
                let nextCell =
                  row.previousSibling.childNodes[row.previousSibling.childNodes.length];

                if (EditorDOMElements.isTableCellElement(nextCell)) {
                  const headCellId = nextCell.getHeadCellId();
                  if (headCellId) {
                    nextCell = document.getElementById(headCellId) as ChildNode;
                  }
                }
                if (nextCell) {
                  previousSibling = nextCell.lastChild;
                }
              }
            }
          } else {
            // check previous row
            const row = closestCell.parentNode;

            if (row?.previousSibling) {
              let previousCell = row.previousSibling.childNodes[closestCell.cellIndex];

              if (EditorDOMElements.isTableCellElement(previousCell)) {
                const headCellId = previousCell.getHeadCellId();
                if (headCellId) {
                  previousCell = document.getElementById(headCellId) as ChildNode;
                }
              }

              if (previousCell) {
                previousSibling = previousCell.lastChild;
              }
            }
          }
        }
      }
    } else if (EditorDOMElements.isFigureElement(blockNode)) {
      const closestElement = EditorDOMUtils.closest(selectedElement, ['FIGCAPTION']);
      if (closestElement) {
        selectableContent = closestElement.previousSibling;
      }
    } else if (EditorDOMElements.isNodeBlockWrapperElement(blockNode)) {
      // aprove and readonlys
      selectableContent = EditorDOMUtils.getPreviousSelectableElement(
        blockNode.selectableContent,
        selectedElement,
        selectedNode,
        tableSelectionOption,
      );
    } else if (EditorDOMElements.isNodeContainerElement(blockNode)) {
      // check container elements
      selectableContent = EditorDOMUtils.getPreviousSelectableElement(
        EditorDOMUtils.findFirstLevelChildNode(blockNode, selectedNode),
        selectedElement,
        selectedNode,
        tableSelectionOption,
      );
    }

    if (
      previousSibling == null &&
      closestSection &&
      blockNode === closestSection.firstElementChild
    ) {
      if (EditorDOMElements.isSectionElement(closestSection.previousElementSibling)) {
        previousSibling = closestSection.previousElementSibling.lastElementChild;
      } else if (
        closestPage &&
        EditorDOMElements.isPageElement(closestPage.previousElementSibling)
      ) {
        const sectionElement = closestPage.previousElementSibling.lastSectionContainer;
        if (sectionElement) {
          previousSibling = sectionElement.lastElementChild;
        }
      }
    }

    if (!selectableContent && previousSibling) {
      if (EditorDOMUtils.isClosestTextElementEditable(previousSibling)) {
        selectableContent = previousSibling;
      } else if (EditorDOMElements.isNodeBlockWrapperElement(previousSibling)) {
        selectableContent = previousSibling.selectableContent;
      } else if (EditorDOMElements.isFigureElement(previousSibling)) {
        selectableContent = previousSibling.lastChild;
      } else if (EditorDOMElements.isTableElement(previousSibling)) {
        if (tableSelectionOption === 'cell') {
          const nRows = previousSibling.tBodies[0].rows.length;
          const nCells = previousSibling.tBodies[0].rows[nRows - 1].cells.length;
          let cell = previousSibling.tBodies[0].rows[nRows - 1].cells[
            nCells - 1
          ] as Editor.Elements.TableCellElement;
          const headCellId = cell.getHeadCellId();
          if (headCellId) {
            cell = document.getElementById(headCellId) as Editor.Elements.TableCellElement;
            selectableContent = cell?.lastChild;
          } else {
            selectableContent = cell?.lastChild;
          }
        } else if (tableSelectionOption === 'row') {
          const nRows = previousSibling.tBodies[0].rows.length;
          selectableContent = previousSibling.tBodies[0].rows[nRows - 1];
        } else {
          selectableContent = previousSibling;
        }
      } else if (EditorDOMElements.isNodeContainerElement(previousSibling)) {
        selectableContent = previousSibling.lastChild;
      } else if (
        !EditorDOMUtils.isClosestBlockNodeEditable(previousSibling) &&
        EditorDOMElements.isSupportedBlockElement(previousSibling)
      ) {
        selectableContent = previousSibling.selectableContent;
      }
    }

    // check for hidden content
    if (selectableContent instanceof Element) {
      const computedStyles = getComputedStyle(selectableContent);

      if (computedStyles.display === 'none' || computedStyles.height === '0px') {
        selectableContent = EditorDOMUtils.getPreviousSelectableElement(
          selectableContent,
          selectableContent,
          selectableContent,
          tableSelectionOption,
        );
      }
    }

    return selectableContent;
  }

  static getNextSelectableElement(
    blockNode: Node | null,
    selectedElement: Node | null,
    selectedNode: Node | null,
    tableSelectionOption: 'row' | 'cell' = 'cell',
  ): Node | null {
    let selectableContent: Node | null = null;

    if (!blockNode) {
      return null;
    }

    let closestSection = EditorDOMUtils.closest(
      blockNode,
      ELEMENTS.SectionElement.TAG,
    ) as Editor.Elements.SectionElement;
    let closestPage = EditorDOMUtils.closest(
      blockNode,
      ELEMENTS.PageElement.TAG,
    ) as Editor.Elements.PageElement;

    let nextSibling = blockNode.nextSibling;
    if (EditorDOMElements.isTableElement(blockNode)) {
      const closestCell = EditorDOMUtils.closest(selectedElement, [ELEMENTS.TableCellElement.TAG]);

      if (EditorDOMElements.isTableCellElement(closestCell)) {
        // WARN:
        // remove cell selection temporary
        // check before commit a6dfbbd
        selectableContent = EditorDOMUtils.getNextSelectableElement(
          EditorDOMUtils.findFirstLevelChildNode(closestCell, selectedNode),
          selectedElement,
          selectedNode,
          tableSelectionOption,
        );
        if (!selectableContent) {
          if (tableSelectionOption === 'cell') {
            // check next cell
            if (closestCell.nextSibling) {
              nextSibling = closestCell.nextSibling.firstChild;
            } else {
              const row = closestCell.parentNode;

              if (row?.nextSibling) {
                let nextCell = row.nextSibling.childNodes[0];

                if (EditorDOMElements.isTableCellElement(nextCell)) {
                  const headCellId = nextCell.getHeadCellId();
                  if (headCellId) {
                    const headCell = document.getElementById(
                      headCellId,
                    ) as Editor.Elements.TableCellElement;
                    nextCell = row.nextSibling.childNodes[headCell.colSpan];
                  }
                }
                if (nextCell) {
                  nextSibling = nextCell.lastChild;
                }
              }
            }
          } else {
            // check next row
            const row = closestCell.parentNode;

            if (row?.nextSibling) {
              let nextCell = row.nextSibling.childNodes[closestCell.cellIndex];

              if (EditorDOMElements.isTableCellElement(nextCell)) {
                const headCellId = nextCell.getHeadCellId();
                if (headCellId) {
                  const headCell = document.getElementById(
                    headCellId,
                  ) as Editor.Elements.TableCellElement;
                  nextCell = row.nextSibling.childNodes[closestCell.cellIndex + headCell.rowSpan];
                }
              }

              if (nextCell) {
                nextSibling = nextCell.firstChild;
              }
            }
          }
        }
      }
    } else if (EditorDOMElements.isFigureElement(blockNode)) {
      // selectableContent = EditorDOMUtils.findNextElementSibling(selectedElement);
      const closestElement = EditorDOMUtils.closest(selectedElement, ['IMAGE-ELEMENT']);
      if (closestElement) {
        selectableContent = closestElement.nextSibling;
      }
    } else if (EditorDOMElements.isNodeBlockWrapperElement(blockNode)) {
      // aprove and readonlys
      selectableContent = EditorDOMUtils.getNextSelectableElement(
        blockNode.selectableContent,
        selectedElement,
        selectedNode,
        tableSelectionOption,
      );
    } else if (EditorDOMElements.isNodeContainerElement(blockNode)) {
      // check tracked elements
      selectableContent = EditorDOMUtils.getNextSelectableElement(
        EditorDOMUtils.findFirstLevelChildNode(blockNode, selectedNode),
        selectedElement,
        selectedNode,
        tableSelectionOption,
      );
    }

    if (nextSibling == null && closestSection && closestSection.lastElementChild === blockNode) {
      if (EditorDOMElements.isSectionElement(closestSection.nextElementSibling)) {
        nextSibling = closestSection.nextElementSibling.firstChild;
      } else if (EditorDOMElements.isPageElement(closestPage.nextElementSibling)) {
        const sectionElement = closestPage.nextElementSibling.sectionContainerAt(0);
        if (sectionElement) {
          nextSibling = sectionElement.firstChild;
        }
      }
    }

    if (!selectableContent && nextSibling) {
      if (EditorDOMUtils.isClosestTextElementEditable(nextSibling)) {
        selectableContent = nextSibling;
      } else if (EditorDOMElements.isNodeBlockWrapperElement(nextSibling)) {
        selectableContent = nextSibling.selectableContent;
      } else if (EditorDOMElements.isFigureElement(nextSibling)) {
        selectableContent = nextSibling.firstChild;
      } else if (EditorDOMElements.isTableElement(nextSibling)) {
        if (tableSelectionOption === 'cell') {
          selectableContent = nextSibling.tBodies[0].rows[0].cells[0].firstChild;
        } else if (tableSelectionOption === 'row') {
          selectableContent = nextSibling.tBodies[0].rows[0];
        } else {
          selectableContent = nextSibling;
        }
      } else if (EditorDOMElements.isNodeContainerElement(nextSibling)) {
        selectableContent = nextSibling.firstChild;
      } else if (
        !EditorDOMUtils.isClosestBlockNodeEditable(nextSibling) &&
        EditorDOMElements.isSupportedBlockElement(nextSibling)
      ) {
        selectableContent = nextSibling.selectableContent;
      }
    }

    // check for hidden content
    if (selectableContent instanceof Element) {
      const computedStyles = getComputedStyle(selectableContent);

      if (computedStyles.display === 'none' || computedStyles.height === '0px') {
        selectableContent = EditorDOMUtils.getNextSelectableElement(
          selectableContent,
          selectableContent,
          selectableContent,
          tableSelectionOption,
        );
      }
    }

    return selectableContent;
  }

  static getSelectableElementFromBlock(
    blockNode: Node | null,
    selectedNode: Node | null,
  ): Node | null {
    if (!blockNode || !selectedNode) {
      return null;
    }

    let selectableElement: Node | null = null;
    if (EditorDOMElements.isNodeContainerElement(blockNode)) {
      selectableElement = EditorDOMUtils.getSelectableElementFromBlock(
        EditorDOMUtils.findFirstLevelChildNode(blockNode, selectedNode),
        selectedNode,
      );
    } else if (EditorDOMElements.isTableElement(blockNode)) {
      if (EditorDOMElements.isTableCellElement(selectedNode)) {
        selectableElement = selectedNode;
      } else {
        const closest = EditorDOMUtils.closest(selectedNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest) {
          if (EditorDOMElements.isTableCellElement(closest)) {
            selectableElement = EditorDOMUtils.getSelectableElementFromBlock(
              EditorDOMUtils.findFirstLevelChildNode(closest, selectedNode),
              selectedNode,
            );
          }
        } else {
          selectableElement = blockNode;
        }
      }
    } else if (EditorDOMElements.isFigureElement(blockNode)) {
      selectableElement = EditorDOMUtils.closest(selectedNode, ['IMAGE-ELEMENT', 'FIGCAPTION']);
    } else if (EditorDOMElements.isSupportedBlockElement(blockNode)) {
      selectableElement = blockNode.selectableContent;
    }

    return selectableElement;
  }

  static scrollIntoXY(px: number, py: number) {
    const editorRoot = document.getElementById('EditorRoot');

    if (!editorRoot) {
      return {};
    }

    const editorRootBoundingRect = editorRoot.getBoundingClientRect();

    const viewMargin = 50;

    let xScroll = 0;
    let yScroll = 0;

    if (py <= editorRootBoundingRect.top + viewMargin) {
      yScroll = py - editorRootBoundingRect.top - viewMargin;
    } else if (py >= editorRootBoundingRect.bottom - viewMargin) {
      yScroll = py - editorRootBoundingRect.bottom + viewMargin;
    }
    editorRoot.scrollTop += yScroll;

    if (px <= editorRootBoundingRect.left + viewMargin) {
      xScroll = px - editorRootBoundingRect.left - viewMargin;
    } else if (px >= editorRootBoundingRect.right - viewMargin) {
      xScroll = px - editorRootBoundingRect.right + viewMargin;
    }
    editorRoot.scrollLeft += xScroll;

    return { x: (px -= xScroll), y: (py -= yScroll) };
  }

  static getCaretOffsetFromPoint(
    x?: number,
    y?: number,
    baseContainer: Node | null = EditorDOMUtils.getContentContainer(),
  ) {
    let range;
    let textNode;
    let offset;

    if (!x || !y) {
      return { node: undefined, offset: undefined };
    }

    // set z-index 99 to avoid some render only content like widgets
    let position = null,
      zIndex = null;
    if (baseContainer instanceof HTMLElement) {
      position = baseContainer.style.position;
      zIndex = baseContainer.style.zIndex;

      if (position == null || position === '') {
        baseContainer.style.position = 'relative';
      }

      baseContainer.style.zIndex = '99';
    }

    if (document.caretRangeFromPoint) {
      // for chrome and others
      range = document.caretRangeFromPoint(x, y);
      if (range) {
        textNode = range.startContainer;
        offset = range.startOffset;
      }
    }
    //@ts-expect-error
    else if (document.caretPositionFromPoint) {
      // for firefox

      //@ts-expect-error
      range = document.caretPositionFromPoint(x, y);
      if (range) {
        textNode = range.offsetNode;
        offset = range.offset;
      }
    }

    if (baseContainer instanceof HTMLElement) {
      if (position != null) {
        baseContainer.style.position = position;
      } else {
        baseContainer.style.position = '';
      }

      if (zIndex != null) {
        baseContainer.style.zIndex = zIndex;
      } else {
        baseContainer.style.zIndex = '';
      }
    }

    return { node: textNode, offset };
  }

  static getChildNodeFromElement(
    parent: Node,
    childIndex: number,
    ignoreFrontendOnlyElements: boolean = true,
  ) {
    if (!(parent instanceof Element)) {
      return null;
    }

    let childNodes = Array.from(parent.childNodes);

    if (ignoreFrontendOnlyElements) {
      childNodes = childNodes.reduce((array: ChildNode[], child: ChildNode) => {
        if (
          !(
            child instanceof Element &&
            EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(child.tagName)
          )
        ) {
          array.push(child);
        }
        return array;
      }, []);
    }

    return childNodes[childIndex];
  }

  static findPreviousAncestorSibling(thisNode: Node, parentNode: Node | null) {
    let previousSibling: Node | null = null;

    if (!parentNode) {
      parentNode = EditorDOMUtils.getContentContainer(thisNode);
    }

    if (!EditorDOMUtils.parentContainsNode(parentNode, thisNode)) {
      return null;
    }

    let node: Node | null = thisNode;
    previousSibling = node.previousSibling;

    while (
      !previousSibling &&
      EditorDOMUtils.parentContainsNode(parentNode, node?.parentNode || null)
    ) {
      node = node?.parentNode || null;
      previousSibling = node?.previousSibling || null;
    }

    return previousSibling;
  }

  static findNextAncestorSibling(thisNode: Node, parentNode: Node | null) {
    let nextSibling: Node | null = null;

    if (!parentNode) {
      parentNode = EditorDOMUtils.getContentContainer(thisNode);
    }

    if (!EditorDOMUtils.parentContainsNode(parentNode, thisNode)) {
      return null;
    }

    let node: Node | null = thisNode;
    nextSibling = node.nextSibling;

    while (
      !nextSibling &&
      EditorDOMUtils.parentContainsNode(parentNode, node?.parentNode || null)
    ) {
      node = node?.parentNode || null;
      nextSibling = node?.nextSibling || null;
    }

    return nextSibling;
  }

  static findPreviousElementSibling(thisNode: Node, parentNode: Node | null) {
    let previousSibling: Element | null = null;

    if (!parentNode) {
      parentNode = EditorDOMUtils.getContentContainer(thisNode);
    }

    if (!(thisNode instanceof Element)) {
      return null;
    }

    let node: Node | null = thisNode;
    previousSibling = thisNode.previousElementSibling;

    while (
      !previousSibling &&
      EditorDOMUtils.parentContainsNode(parentNode, node?.parentNode || null)
    ) {
      node = node?.parentNode || null;

      if (node instanceof Element) {
        previousSibling = node.nextElementSibling;
      } else {
        previousSibling = null;
      }
    }

    return previousSibling;
  }

  static findNextElementSibling(thisNode: Node, parentNode: Node | null) {
    let nextSibling: Element | null = null;

    if (!parentNode) {
      parentNode = EditorDOMUtils.getContentContainer(thisNode);
    }

    if (!(thisNode instanceof Element)) {
      return null;
    }

    let node: Node | null = thisNode;
    nextSibling = thisNode.nextElementSibling;

    while (
      !nextSibling &&
      EditorDOMUtils.parentContainsNode(parentNode, node?.parentNode || null)
    ) {
      node = node?.parentNode || null;

      if (node instanceof Element) {
        nextSibling = node.nextElementSibling;
      } else {
        nextSibling = null;
      }
    }

    return nextSibling;
  }

  static isBlockNodeDeletable(node: Node | null) {
    if (!node || node instanceof Text) {
      return false;
    }

    let closest = EditorDOMUtils.closest(node, EditorDOMElements.DELETABLE_LEVEL0_ELEMENTS);
    if (!closest) {
      return false;
    }

    const pageNode = EditorDOMUtils.getContentContainer(node);
    if (pageNode && pageNode.contains(closest) && closest.parentNode !== pageNode) {
      closest = EditorDOMUtils.findFirstLevelChildNode(pageNode, closest);
    }

    if (EditorDOMElements.isSupportedElement(closest) && closest.isDeletable) {
      return true;
    }

    return false;
  }

  private static getClosestCitationGroup(node: Node) {
    const closestElement = EditorDOMUtils.closest(node, [
      ELEMENTS.CitationsGroupElement.TAG,
      ELEMENTS.TrackInsertElement.TAG,
    ]);

    if (EditorDOMElements.isCitationsGroupElement(closestElement)) {
      return closestElement;
    }

    if (
      EditorDOMElements.isTrackInsertElement(closestElement) &&
      EditorDOMElements.isCitationsGroupElement(closestElement.firstChild)
    ) {
      return closestElement.firstChild;
    }

    return null;
  }

  static findClosestCitationGroup(anchorNode: Node | null, anchorOffset: number) {
    if (!anchorNode) {
      return null;
    }

    let closestCitationGroup: Editor.Elements.CitationsGroupElement | null = null;
    let previousCitationGroup: Editor.Elements.CitationsGroupElement | null = null;
    let nextCitationGroup: Editor.Elements.CitationsGroupElement | null = null;

    // fix selection inside p
    if (
      anchorNode instanceof Element &&
      (EditorDOMElements.BLOCK_TEXT_ELEMENTS.includes(anchorNode.tagName) ||
        EditorDOMElements.INLINE_EDITABLE_ELEMENTS.includes(anchorNode.tagName))
    ) {
      const previousChild = anchorNode.childNodes[anchorOffset - 1];
      if (anchorNode.childNodes[anchorOffset]) {
        anchorNode = anchorNode.childNodes[anchorOffset];
        anchorOffset = 0;
      } else if (previousChild instanceof Element || previousChild instanceof Text) {
        anchorNode = previousChild;
        anchorOffset =
          previousChild instanceof Element ? previousChild.childNodes.length : previousChild.length;
      }
    }

    closestCitationGroup = EditorDOMUtils.getClosestCitationGroup(anchorNode);
    if (closestCitationGroup) {
      return closestCitationGroup;
    }

    if (anchorOffset === 0 && anchorNode.previousSibling) {
      previousCitationGroup = EditorDOMUtils.getClosestCitationGroup(anchorNode.previousSibling);
      if (previousCitationGroup) {
        return previousCitationGroup;
      }
    }

    if (anchorNode instanceof Element || anchorNode instanceof Text) {
      const anchorNodeLength =
        anchorNode instanceof Element ? anchorNode.childNodes.length : anchorNode.length;
      if (anchorOffset === anchorNodeLength && anchorNode.nextSibling) {
        nextCitationGroup = EditorDOMUtils.getClosestCitationGroup(anchorNode.nextSibling);
      }
    }

    return nextCitationGroup;
  }

  static isNodeNonSelectable(node: Node): node is HTMLElement {
    if (EditorDOMUtils.closest(node, EditorDOMElements.INLINE_NON_SELECTABLE_ELEMENTS)) {
      return true;
    }

    return false;
  }

  static isEmptyElement(node: Node | null) {
    if (!node) {
      return true;
    }
    let hasImages = false;
    if (node instanceof Element) {
      hasImages = !!node.querySelector('img') || node.nodeName === 'IMG';
    }
    return (
      (node.textContent === '' ||
        node.textContent === '\u00B6' ||
        node.textContent === '\uFEFF' ||
        node.textContent === '\u202F' ||
        node.textContent === '\u200B' ||
        EditorDOMElements.isNodeSuggestionParagraphMarker(node) ||
        EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(node.nodeName)) &&
      !EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS.includes(node.nodeName) &&
      !hasImages
    );
  }

  static isAtStartOfNode(parent: Node, anchor: Node, offset: number): boolean {
    if (
      anchor &&
      parent.parentNode &&
      EditorDOMUtils.parentContainsNode(parent.parentNode, anchor)
    ) {
      if (
        anchor !== parent &&
        (offset === 0 ||
          anchor.textContent === '' ||
          anchor.textContent === '\u00B6' ||
          anchor.textContent === '\uFEFF' ||
          anchor.textContent === '\u202F' ||
          anchor.textContent === '\u200B')
      ) {
        while (anchor !== parent) {
          if (anchor instanceof HTMLTableCellElement) {
            let row = anchor.parentElement as HTMLTableRowElement;
            if (anchor.cellIndex === 0 && row.sectionRowIndex === 0) {
              return true;
            } else {
              return false;
            }
          }
          if (anchor !== anchor?.parentNode?.firstChild) {
            let previousSibling = anchor?.previousSibling;
            while (previousSibling) {
              if (!EditorDOMUtils.isEmptyElement(previousSibling)) {
                return false;
              }
              previousSibling = previousSibling.previousSibling;
            }
          } else {
            // anchor is first child
            if (offset !== 0 && !EditorDOMUtils.isEmptyElement(anchor)) {
              return false;
            }
          }
          if (anchor?.parentNode) {
            offset = Array.from(anchor.parentNode.childNodes).indexOf(anchor as ChildNode);
            anchor = anchor.parentNode;
          }
        }
        return true;
      } else if (anchor === parent) {
        if (offset === 0) {
          return true;
        } else {
          // check previous elements
          let isAtStart = false;
          for (let i = offset - 1; i >= 0; i--) {
            if (parent.childNodes[i]) {
              if (EditorDOMUtils.isEmptyElement(parent.childNodes[i])) {
                isAtStart = true;
              } else {
                isAtStart = false;
                break;
              }
            }
          }
          return isAtStart;
        }
      }
    }
    return false;
  }

  static isAtEndOfNode(parent: Node, anchor: Node, offset: number): boolean {
    if (
      anchor &&
      parent.parentNode &&
      EditorDOMUtils.parentContainsNode(parent.parentNode, anchor)
    ) {
      if (
        parent !== anchor &&
        ((anchor instanceof Text && anchor.length === offset) ||
          (anchor instanceof Element && anchor.childNodes.length === offset) ||
          anchor.textContent === '' ||
          anchor.textContent === '\u00B6' ||
          anchor.textContent === '\uFEFF' ||
          anchor.textContent === '\u202F' ||
          anchor.textContent === '\u200B')
      ) {
        while (parent !== anchor) {
          if (anchor instanceof HTMLTableCellElement) {
            let row = anchor.parentElement as HTMLTableRowElement;
            let tbody = row.parentElement as HTMLTableSectionElement;
            if (
              anchor.cellIndex === row.cells.length - 1 &&
              row.sectionRowIndex === tbody.rows.length - 1
            ) {
              return true;
            } else {
              return false;
            }
          }
          if (anchor !== anchor?.parentNode?.lastChild) {
            let nextSibling = anchor?.nextSibling;
            while (nextSibling) {
              if (!EditorDOMUtils.isEmptyElement(nextSibling)) {
                return false;
              }
              nextSibling = nextSibling.nextSibling;
            }
          } else {
            const nodeLength = anchor instanceof Text ? anchor.length : anchor.childNodes.length;
            // anchor is last child
            if (offset !== nodeLength && !EditorDOMUtils.isEmptyElement(anchor)) {
              return false;
            }
          }
          if (anchor.parentNode) {
            offset = Array.from(anchor.parentNode.childNodes).indexOf(anchor as ChildNode) + 1;
            anchor = anchor.parentNode;
          }
        }
        return true;
      } else if (parent === anchor) {
        if (parent instanceof Element) {
          if (offset === parent.childNodes.length) {
            return true;
          } else {
            // check next elements
            let isAtEnd = false;
            let length = parent.childNodes.length;
            for (let i = offset; i < length; i++) {
              if (parent.childNodes[i]) {
                if (EditorDOMUtils.isEmptyElement(parent.childNodes[i])) {
                  isAtEnd = true;
                } else {
                  isAtEnd = false;
                  break;
                }
              }
            }
            return isAtEnd;
          }
        } else if (parent instanceof Text && offset === parent.length) {
          return true;
        }
      }
    }
    return false;
  }

  static getOffsets(
    reference: Node | Range | null,
    baseContainer: HTMLElement | null = document.querySelector('[ispagenode="true"]'),
  ): Editor.Common.Rect | null {
    if (!reference || !baseContainer) {
      return null;
    }

    if (reference instanceof Text) {
      reference = reference.parentNode as Node;
    }

    if (reference instanceof HTMLElement) {
      let top = reference.offsetTop;
      let left = reference.offsetLeft;
      const bounds = reference.getBoundingClientRect();
      let offsetParentClimber = reference.offsetParent as HTMLElement;

      while (offsetParentClimber) {
        left += offsetParentClimber.offsetLeft;
        if (baseContainer.contains(offsetParentClimber)) {
          top += offsetParentClimber.offsetTop;
        }
        offsetParentClimber = offsetParentClimber.offsetParent as HTMLElement;
      }

      return {
        top,
        left,
        right: 0, // TODO:
        bottom: 0, // TODO:
        width: bounds.width,
        height: bounds.height,
      };
    }

    if (reference instanceof Range) {
      const bounds = reference.getBoundingClientRect();
      const main = document.getElementById('main');

      return {
        top: bounds.top + baseContainer.scrollTop - baseContainer.offsetTop,
        left: bounds.left + (main?.scrollLeft || 0) * -1,
        right: bounds.right, // TODO:
        bottom: bounds.bottom, // TODO:
        width: bounds.width,
        height: bounds.height,
      };
    }

    return null;
  }

  static cleanAttributes(node: Node | null, attributes: string[]) {
    if (!(node instanceof Element)) {
      return;
    }

    for (let index = 0; index < attributes.length; index++) {
      const element = attributes[index];
      if (node.hasAttribute(element)) {
        node.removeAttribute(element);
      }
    }
  }
}
