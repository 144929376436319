import dayjs from 'dayjs';
import { ElementNodeBuilder } from 'Editor/services/Model';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement, FieldElement } from '..';

export class FieldViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<FieldElement, Editor.Data.Node.FieldData>
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<
    FieldElement,
    Editor.Data.Node.FieldData
  > = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json, node: FieldElement) => {
        node.setAttribute('element_type', ELEMENTS.FieldElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // type
    t: {
      parse: (node: FieldElement, builder: ElementNodeBuilder) => {
        if (node.dataset.type) {
          builder.addProperty('t', node.dataset.type);
        }
      },
      render: (json, node: FieldElement) => {
        if (json.properties && json.properties.t) {
          node.dataset.type = json.properties.t;
        }
      },
      remove: (node: FieldElement) => {
        delete node.dataset.type;
      },
    },
    // reference
    r: {
      parse: (node: FieldElement, builder: ElementNodeBuilder) => {
        if (node.dataset.ref) {
          builder.addProperty('r', node.dataset.ref);
        }
      },
      render: (json, node: FieldElement) => {
        if (json.properties && json.properties.r) {
          node.dataset.ref = json.properties.r;
        }
      },
      remove: (node: FieldElement) => {
        delete node.dataset.ref;
      },
    },
    // format
    f: {
      parse: (node: FieldElement, builder: ElementNodeBuilder) => {
        if (node.dataset.format) {
          builder.addProperty('f', node.dataset.format);
        }
      },
      render: (json, node: FieldElement) => {
        if (json.properties && json.properties.f) {
          node.dataset.format = json.properties.f;
        }
      },
      remove: (node: FieldElement) => {
        delete node.dataset.format;
      },
    },
    // caption label
    cpt: {
      parse: (node: FieldElement, builder: ElementNodeBuilder) => {
        if (node.dataset.cpt) {
          builder.addProperty('cpt', node.dataset.cpt);
        }
      },
      render: (json, node: FieldElement) => {
        if (json.properties && json.properties.cpt) {
          node.dataset.cpt = json.properties.cpt;
        }
      },
      remove: (node: FieldElement) => {
        delete node.dataset.cpt;
      },
    },
    // link
    l: {
      parse: (node: FieldElement, builder: ElementNodeBuilder) => {
        if (node.dataset.link) {
          builder.addProperty('l', node.dataset.link);
        }
      },
      render: (json, node: FieldElement) => {
        if (json.properties && json.properties.l) {
          node.dataset.link = `${json.properties.l}`;
        }
      },
      remove: (node: FieldElement) => {
        delete node.dataset.link;
      },
    },
    error: {
      parse: () => {},
      render: (json, node: FieldElement) => {
        if (json.properties && json.properties.error) {
          node.dataset.error = 'true';
        }
      },
      remove: (node: FieldElement) => {
        delete node.dataset.error;
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  build(json: Editor.Data.Node.FieldData) {
    const node = DOMElementFactory.buildElement(ELEMENTS.FieldElement.TAG) as FieldElement;

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    if (node.dataset.type === 'd') {
      node.setContent(dayjs(this.Data.fields?.get('date')).format(node.dataset.format));
    }
    if (node.dataset.type === 'mt') {
      node.setContent(this.Data.fields?.get('title') as string);
    }

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }

  shouldRenderChildren(json: Editor.Data.Node.FieldData) {
    if (json.properties && json.properties.t) {
      //@ts-expect-error type matching
      if (!ELEMENTS.FieldElement.TYPES.EDITABLE.includes(json.properties.t)) {
        return false;
      }
    }
    return true;
  }
}
