import { JsonRange } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { Command } from '../Command';
import { ELEMENTS } from 'Editor/services/consts';
import { UpdateImagePropertiesOperation } from '../../Operations/ImageOperations';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection/Utils/EditorSelectionUtils';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';
import { EditorDOMElements } from 'Editor/services/_Common/DOM';

export class UpdateImagePropertiesCommand extends Command {
  properties: Editor.Styles.ImageProperties;

  constructor(context: Editor.Edition.ICommandArgs, properties: Editor.Styles.ImageProperties) {
    super(context);

    this.properties = properties;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (!this.context.DataManager || !this.context.DataManager.selection) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return this;
    }

    const result = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, ['img', 'figure']);
    const closest = result?.data;

    const editorRange = EditorSelectionUtils.getRange();

    if (editorRange) {
      const image = EditorDOMUtils.closest(editorRange.startContainer, [
        ELEMENTS.FigureElement.TAG,
        ELEMENTS.ImageElement.TAG,
      ]);

      if (EditorDOMElements.isFigureElement(image) || EditorDOMElements.isImageElement(image)) {
        const offsets = EditorDOMUtils.getOffsets(image);

        if (offsets && closest && closest.id && image && closest.id === image.id) {
          const imageInfo = baseModel.getChildInfoById(closest.id);

          if (
            NodeUtils.isImageData(imageInfo.data) &&
            JsonRange.isValidSelectionPath(imageInfo.path)
          ) {
            this.applyOperations(
              baseModel,
              imageInfo.path,
              offsets,
              imageInfo.data,
              this.properties,
            );
          }
        }
      }
    }

    return this;
  }

  applyOperations(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    offsets: Editor.Common.Rect,
    imageData: Editor.Data.Node.ImageData,
    properties: Editor.Styles.ImageProperties,
  ) {
    const operation = new UpdateImagePropertiesOperation(
      baseModel,
      path,
      offsets,
      imageData,
      properties,
    );
    return operation.apply();
  }
}
