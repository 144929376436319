import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { useGetAffiliationsListQuery } from 'Settings/pages/TenantSettingsPage/Affiliations/AffiliationsApi';

import OptionsList from './OptionsList/OptionsList';

import styles from './Field.module.scss';

type FieldContentProps = {
  field: Affiliation.Field;
  search: string;
};

const FieldContent = ({ field, search }: FieldContentProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { data: fields, isLoading } = useGetAffiliationsListQuery();

  const optionsFiltered = useMemo(
    () =>
      fields && fields[field].filter((value) => value.toLowerCase().includes(search.toLowerCase())),
    [fields, search],
  );

  if (isLoading) {
    return null;
  }

  if (fields && fields[field]?.length === 0) {
    return (
      <div className={styles.emptyState}>
        <EmptyState
          size="medium"
          title={intl.formatMessage({ id: 'NO_OPTIONS_ASSOCIATED_WITH_THIS_FIELD' })}
          onClick={() =>
            dispatch(
              openAndUpdateModal({
                modal: 'AddAffiliationModal',
                data: {
                  field,
                },
              }),
            )
          }
          footer={<FormattedMessage id="ADD_NEW_FIELD_OPTION" />}
          testId={`emptyState-empty-affiliation-${field.toLocaleLowerCase()}`}
        >
          <FormattedMessage id="THIS_FIELDS_OPTIONS_WILL_BE_DISPLAYED_HERE" />
        </EmptyState>
      </div>
    );
  }
  if (optionsFiltered?.length === 0) {
    return (
      <div className={styles.emptyState} data-testid={`emptyState-filter-affiliation-${field.toLocaleLowerCase()}`}>
        <EmptyState
          size="medium"
          title={intl.formatMessage({ id: 'NO_AFFILIATIONS_FIELD_OPTIONS_FOUND' })}
          testId={`emptyState-filter-affiliation-${field.toLocaleLowerCase()}`}
        >
          <FormattedMessage id="THERE_ARE_NO_OPTIONS" values={{ search }} />
        </EmptyState>
      </div>
    );
  }
  return <OptionsList field={field} options={optionsFiltered} />;
};

export default FieldContent;
