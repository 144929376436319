import { Dropdown } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';
import Cell from '_common/components/Table2/Cells';
import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

type StatusSuffixCellProps = {
  status: ElementStatus;
};

const StatusSuffixCell = ({ status }: StatusSuffixCellProps) => {
  const dispatch = useDispatch();

  const openEditModal = (id: string) => {
    dispatch(openAndUpdateModal({ modal: 'ElementStatusFormModal', data: { mode: 'edit', id } }));
  };

  const openDeleteModal = (id: string) => {
    dispatch(openAndUpdateModal({ modal: 'DeleteElementStatusModal', data: { id } }));
  };

  return (
    <div>
      <Cell.Options
        //@ts-expect-error
        object={status}
        items={
          <>
            <Dropdown.Item
              size="large"
              onClick={() => openEditModal(status.id)}
              testId="edit-status-dropdown-item"
            >
              <FormattedMessage id="EDIT_STATUS" />
            </Dropdown.Item>
            {status.id !== 'active' && status.id !== 'approved' && (
              <Dropdown.Item
                size="large"
                onClick={() => openDeleteModal(status.id)}
                testId="delete-status-dropdown-item"
              >
                <FormattedMessage id="DELETE_STATUS" />
              </Dropdown.Item>
            )}
          </>
        }
        testId={`status-${status.id}`}
      />
    </div>
  );
};

export default StatusSuffixCell;
