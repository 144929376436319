import { useCallback } from 'react';
import { useDispatch } from '_common/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, Toggle, Tooltip } from 'dodoc-design-system';
import type { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { ShortcutsPopover } from '_common/components';
import styles from './ImageOptionsWidget.module.scss';

type ImageOptionsWidgetProps = {
  currentProperty: Editor.Styles.ImageWrapProperty;
  offsets: Editor.Common.Rect | null;
  onImageWrapChange: (props: Editor.Styles.ImageProperties) => void;
};

const ImageOptionsWidgetView = ({
  currentProperty,
  offsets,
  onImageWrapChange,
}: ImageOptionsWidgetProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const PROPERTIES: {
    id: Editor.Styles.ImageWrapProperty;
    icon: IconTypes['24'];
    label: string;
  }[] = [
      {
        id: 'inline',
        icon: 'TextWrapInline',
        label: intl.formatMessage({ id: 'IN_LINE_WITH_TEXT' }),
      },
      { id: 'left', icon: 'TextWrapLeft', label: intl.formatMessage({ id: 'LEFT' }) },
      { id: 'right', icon: 'TextWrapRight', label: intl.formatMessage({ id: 'RIGHT' }) },
      {
        id: 'topAndBottom',
        icon: 'TextWrapTopAndBottom',
        label: intl.formatMessage({ id: 'TOP_AND_BOTTOM' }),
      },
      {
        id: 'behindText',
        icon: 'TextWrapBehindText',
        label: intl.formatMessage({ id: 'BEHIND_TEXT' }),
      },
      {
        id: 'inFrontText',
        icon: 'TextWrapInFrontOfText',
        label: intl.formatMessage({ id: 'IN_FRONT_OF_TEXT' }),
      },
    ];

  const setCurrentProperty = useCallback(
    (property: Editor.Styles.ImageWrapProperty) => {
      if (property !== currentProperty) {
        /*TODO:InlineProperties callback*/
        onImageWrapChange({
          imageWrap: {
            value: property,
          },
        });
      }
    },
    [currentProperty],
  );

  const handleInsertCaption = () => {
    dispatch(openAndUpdateModal({ modal: 'CaptionsModal', data: { editModal: 'insert' } }));
  };

  if (!offsets) {
    return null;
  }

  return (
    <ShortcutsPopover testId="image" offsets={offsets}>
      <div className={styles.root}>
        <div className={styles.properties}>
          {PROPERTIES.map((property) => (
            <Tooltip
              key={property.id}
              placement="top"
              content={property.label}
              testId={`image-property-${property.id}-tooltip`}
            >
              <Toggle
                size="medium"
                variant="link"
                icon={property.icon}
                isToggled={currentProperty === property.id}
                onClick={() => {
                  setCurrentProperty(property.id);
                }}
                testId={`image-property-${property.id}-toggle`}
              />
            </Tooltip>
          ))}
        </div>
        <div style={{ height: '3rem' }}>
          <Divider vertical margin="0 1rem" />
        </div>
        <Button
          size="medium"
          variant="link"
          onClick={handleInsertCaption}
          testId="image-insert-caption"
        >
          <FormattedMessage id="INSERT_CAPTION" />
        </Button>
      </div>
    </ShortcutsPopover>
  );
};

export default ImageOptionsWidgetView;
