/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { isEqual } from 'lodash';
import { DataObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { EditorService } from '_common/services';
import { TemplateData } from './Template.types';

export class Template extends DataObject<
  TemplateData,
  {
    READY: () => void;
    LOADED: (data: TemplateData | null) => void;
    CREATED: () => void;
    UPDATED: (data: TemplateData | null) => void;
  }
> {
  transport: Transport;
  loaded: boolean;

  constructor(transport: Transport, id: string) {
    super(id);
    this.transport = transport;
    this.loaded = false;
    this.data = {
      styles: {},
      lists: {},
      l_st: {},
      page: {},
      tables: {},
    };
  }

  get styles() {
    if (this.data?.styles) {
      return JSON.parse(JSON.stringify(this.data?.styles));
    }
    return {};
  }

  get lists() {
    if (this.data?.lists) {
      return JSON.parse(JSON.stringify(this.data?.lists));
    }
    return {};
  }

  get listStyles() {
    if (this.data?.l_st) {
      return JSON.parse(JSON.stringify(this.data?.l_st));
    }
    return {};
  }

  get citations() {
    if (this.data?.citations) {
      return JSON.parse(JSON.stringify(this.data?.citations));
    }
    return {};
  }

  get referenceStyle() {
    return this.data?.citations?.type || '_blank';
  }

  get pageMeasures() {
    if (this.data?.page) {
      return JSON.parse(JSON.stringify(this.data?.page));
    }
    return {};
  }

  get headerRow() {
    if (this.data?.tables?.p?.hr) {
      return JSON.parse(JSON.stringify(this.data?.tables.p.hr));
    }
    return null;
  }

  async fetch(): Promise<Template> {
    const { data } = await new EditorService().getTemplateStylesDefinition(this.id);
    if (!isEqual(this.data, data)) {
      this.loaded = true;
      this.data = {
        ...this.data,
        // @ts-expect-error EditorService api is still not yet documented
        ...data,
      };
      this.emit('LOADED', this.data);
    }
    return this;
  }

  dispose(): void {
    super.dispose();
  }
}
