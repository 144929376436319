import { useEnvisionTheme, useFavicon, useSelector } from '_common/hooks';
import { SuiteProvider } from '_common/suite';
import Slides from './Slides/Slides';
import Title from './Title';
import Toolbar from './Toolbar';
import Navigation from './Navigation';
import styles from './Presentation.module.scss';
import Sidebar from './Sidebar';
import Footer from './Footers';
import SidebarPanel from './SidebarPanel';

const Presentation = () => {
  useEnvisionTheme();
  useFavicon('/favicon-orange.ico');

  const showNav = useSelector((state) => state.presentation.showNav);
  const rightPanel = useSelector((state) => state.presentation.rightPanel);

  return (
    <SuiteProvider app="presentation">
      <div className={styles.root}>
        <Title />
        <Toolbar />
        {showNav && <Navigation />}
        {rightPanel && <SidebarPanel />}
        <Slides />
        <Sidebar />
        <Footer />
      </div>
    </SuiteProvider>
  );
};

export default Presentation;
