import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, InputField, Modal, Select, Button, TextArea } from 'dodoc-design-system';
import { useDispatch, useSelector } from '_common/hooks/redux';
import { InstanceService } from '_common/services';
import { closeAndResetModal, openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectOrderedElementStatusList } from '_common/services/api/elementStatusApi';
import { useChangeElementStatusMutation } from 'App/redux/objectApi';
import { notify } from '_common/components/ToastSystem';
import { useObject } from '_common/hooks';

const MESSAGES = {
  header: 'CHANGE_ELEMENT_STATUS',
  statusLabel: 'STATUS',
  commentLabel: 'STATUS_CHANGE_COMMENT',
  commentPlaceholder: 'storage.modals.approve.placeholder',
  checkbox: 'CHANGE_STATUS_ON_CHILDREN',
  cancel: 'global.cancel',
  required: 'REQUIRED',
  confirmationLabel: 'CONFIRM_CHANGE_STATUS_LABEL',
  confirmationPlaceholder: 'CONFIRM_CHANGE_STATUS_PLACEHOLDER',
};

const ChangeElementStatusModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.ChangeElementStatusModal);
  const list = useSelector(selectOrderedElementStatusList);
  const objectId = useSelector((state) => state.modals.ChangeElementStatusModal.objectId);
  const objectType = useSelector((state) => state.modals.ChangeElementStatusModal.objectType);
  const { data: object } = useObject(
    { object_id: objectId, object_type: objectType },
    { skip: !objectId && !objectType },
  );
  const submitting = useSelector((state) => state.modals.ChangeElementStatusModal.submitting);
  const actionCode = useSelector((state) => state.modals.ConfirmationModal.actionCode);

  const [canRender, setCanRender] = useState(false);
  const [status, setStatus] = useState<{ value: string; label: string }>({ value: '', label: '' });
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState<string>('');
  const [recursive, setRecursive] = useState(false);
  const [recursiveDisabled, setRecursiveDisabled] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [changeElementStatus] = useChangeElementStatusMutation();
  const newStatus = list.find((s) => s.id === status?.value);
  const oldStatus = list.find((s) => s.id === object?.status);

  const close = useCallback(() => {
    dispatch(closeAndResetModal('ChangeElementStatusModal'));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      setStatus({ value: '', label: '' });
      setComment('');
      setCommentError('');
    };
  }, [isOpen]);

  useEffect(() => {
    if (object?.status) {
      const index = list.findIndex((status) => status.id === object.status);
      if (index > -1 && list.length - 1 !== index) {
        setStatus({ value: list[index + 1].id, label: list[index + 1].name });
      }
    }

    const changeAllowed = async () => {
      const { data } = await new InstanceService().changeElementStatusAllowed({
        id: object?.id,
        type: object?.type,
      });
      if (!data.allowed) {
        notify({
          type: 'error',
          title: 'STATUS_CANNOT_BE_UPDATED',
          message: 'STATUS_CANNOT_BE_UPDATED_MESSAGE',
          messageValues: { elementType: intl.formatMessage({ id: `global.${object?.type}` }) },
        });
        close();
      } else {
        setCanRender(true);
      }
    };
    if (object) {
      changeAllowed();
    }
  }, [close, intl, list, object]);

  useEffect(() => {
    if (status) {
      if (oldStatus?.confirm_input || newStatus?.confirm_input) {
        setShowConfirmation(true);
      } else {
        setShowConfirmation(false);
      }
      if (newStatus?.allow_edit) {
        setRecursiveDisabled(false);
      } else {
        setRecursive(true);
        setRecursiveDisabled(true);
      }
    }
  }, [newStatus?.allow_edit, newStatus?.confirm_input, oldStatus?.confirm_input, status]);

  useEffect(() => {
    if (actionCode) {
      setCanRender(false);
    } else {
      setCanRender(true);
    }
  }, [actionCode]);

  const onCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 200) {
      setCommentError(intl.formatMessage({ id: 'ERROR_CHARACTER_LIMIT' }, { number: 200 }));
    } else {
      setCommentError('');
    }
    setComment(e.target.value);
  };

  const submit = async () => {
    const { data } = await new InstanceService().changeElementStatusAllowed({
      id: object?.id,
      type: object?.type,
    });

    if (!data.allowed) {
      notify({
        type: 'error',
        title: 'STATUS_CANNOT_BE_UPDATED',
        message: 'STATUS_CANNOT_BE_UPDATED_MESSAGE',
        messageValues: { elementType: intl.formatMessage({ id: `global.${object?.type}` }) },
      });
      close();
      return;
    }

    const payload = {
      objectId: object?.id ?? objectId,
      objectType: object?.type ?? objectType,
      status: status.value,
      comment,
      recursive,
    };

    const messageValues = {
      elementName: object?.name ?? '',
      oldStatus: oldStatus?.name ?? '',
      newStatus: newStatus?.name ?? '',
    };

    if (showConfirmation) {
      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            title: 'CONFIRM_STATUS_UPDATE',
            message: `ARE_YOU_SURE_YOU_WANT_TO_UPDATE_THE_STATUS`,
            messageValues,
            confirmButtonTextId: 'UPDATE_STATUS',
            confirmButtonType: 'primary',
            cancelButtonTextId: 'global.cancel',
            cancelButtonShow: true,
            actionCode: 'changeElementStatus',
            actionValue: { payload, messageValues },
            width: '60rem',
          },
        }),
      );
    } else {
      changeElementStatus({ payload, messageValues }).then(() => {
        close();
      });
    }
  };
  const isSubmitDisabled = () => {
    // No status selected
    if (status === null) {
      return true;
    }
    // Changing to approved status requires a comment
    if (status?.value === 'approved' && comment === '') {
      return true;
    }
    // Current comment has an error
    if (commentError !== '') {
      return true;
    }
    if (submitting) {
      return true;
    }

    return false;
  };

  if (!canRender) {
    return null;
  }

  return (
    <Modal open={!!isOpen} width="60rem" onClose={close} testId="change-status">
      <Modal.Header onClose={close}>
        <FormattedMessage id={MESSAGES.header} />
      </Modal.Header>
      <Modal.Body>
        <InputField
          width="100%"
          size="large"
          label={intl.formatMessage({ id: MESSAGES.statusLabel })}
          testId="change-status-list-field"
        >
          <Select
            clearable={false}
            width="100%"
            size="large"
            options={list
              .filter((status) => status.allow_change)
              .map((status) => ({
                value: status.id,
                label: status.name,
              }))}
            value={status.value ? status : null}
            onChange={setStatus}
            filterOption={({ value }: { value: string }) => value !== object?.status}
            testId="change-status-list"
            menuPosition="fixed"
          />
        </InputField>
        <InputField
          size="large"
          label={intl.formatMessage({ id: MESSAGES.commentLabel })}
          feedback={commentError}
          required={
            status?.value === 'approved' ? intl.formatMessage({ id: MESSAGES.required }) : ''
          }
          testId="change-status-comment"
        >
          <TextArea
            size="large"
            placeholder={intl.formatMessage({ id: MESSAGES.commentPlaceholder })}
            value={comment}
            onChange={onCommentChange}
            error={!!commentError}
            testId="change-status-comment-textarea"
          />
        </InputField>

        {object?.type === 'folder' && (
          <Checkbox
            size="small"
            checked={recursive ? 'checked' : 'unchecked'}
            onChange={() => setRecursive((value) => !value)}
            disabled={recursiveDisabled}
            testId="change-status-on-children-checkbox"
          >
            <FormattedMessage id={MESSAGES.checkbox} />
          </Checkbox>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="change-status-cancel-button">
          <FormattedMessage id={MESSAGES.cancel} />
        </Button>
        <Button
          size="medium"
          variant="primary"
          disabled={isSubmitDisabled()}
          onClick={submit}
          loading={submitting}
          testId="change-status-submit-button"
        >
          <FormattedMessage id={MESSAGES.header} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeElementStatusModal;
