import { MouseEventHandler } from 'react';

import IntlErrorBoundary from '../IntlErrorBoundary/IntlErrorBoundary';
import InformationPanel from './InformationPanel';

import styles from './InformationPanel.module.scss';

type InformationPanelProps = {
  isOpen?: boolean;
  onClose: MouseEventHandler<HTMLSpanElement>;
};

const InformationPanelWrapper = ({ isOpen, onClose }: InformationPanelProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.root}>
      <IntlErrorBoundary size="medium" margin="10rem 0 0 0">
        <InformationPanel onClose={onClose} />
      </IntlErrorBoundary>
    </div>
  );
};

export default InformationPanelWrapper;
