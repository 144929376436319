type SnapshotTypes = Presentation.Data.Models.DataTypes & {
  USERS: string[];
};

type RealtimeStore = {
  type: Presentation.Data.Models.TypeName | 'USERS';
  object: Realtime.Core.RealtimeObject | Realtime.Core.DataObject;
  data: any;
  listeners: (() => void)[];
  handler: (data: any) => void;
};

export class RealtimeSyncStore {
  protected stores: Record<string, RealtimeStore> = {};
  protected data: Presentation.Data.API | undefined;

  constructor(data: Presentation.Data.API | undefined) {
    this.data = data;
    this.getSnapshot = this.getSnapshot.bind(this);
    this.subscribe = this.subscribe.bind(this);
  }

  protected emitSync(id: string) {
    const listeners = this.stores[id]?.listeners || [];
    for (let index = 0; index < listeners.length; index++) {
      listeners[index]();
    }
  }

  protected initStore(type: Presentation.Data.Models.TypeName | 'USERS', id: string) {
    if (this.stores[id]) {
      return;
    }
    let object: Realtime.Core.RealtimeObject | Realtime.Core.DataObject;
    if (type === 'USERS') {
      object = this.data?.users.users;
    } else {
      object = this.data?.models.get(type, id);
    }
    const handler = (data: any) => {
      this.stores[id].data = data;
      this.emitSync(id);
    };
    object.on('LOADED', handler);
    object.on('UPDATED', handler);
    object.on('UPDATE', handler);
    this.stores[id] = {
      type,
      object,
      data: object.get([]),
      listeners: [],
      handler,
    };
  }

  protected destroyStore(id: string) {
    if (this.stores[id]) {
      this.stores[id].object.off('LOADED', this.stores[id].handler);
      this.stores[id].object.off('UPDATED', this.stores[id].handler);
      this.stores[id].object.off('UPDATE', this.stores[id].handler);
      delete this.stores[id];
    }
  }

  subscribe(
    type: Presentation.Data.Models.TypeName | 'USERS',
    id: string,
    listener: () => void,
  ): () => void {
    if (!this.stores[id]) {
      this.initStore(type, id);
    }
    this.stores[id].listeners = [...this.stores[id].listeners, listener];
    return () => {
      this.stores[id].listeners = this.stores[id].listeners.filter((l) => l !== listener);
      if (this.stores[id].listeners.length === 0) {
        this.destroyStore(id);
      }
    };
  }

  getSnapshot<T extends Presentation.Data.Models.TypeName | 'USERS'>(
    type: T,
    id: string,
  ): SnapshotTypes[T] {
    return this.stores[id]?.data as SnapshotTypes[T];
  }
}
