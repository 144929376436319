// ApprovedElement
// ApproveViewElement
// AuthorsElement
// CitationElement
// CitationsGroupElement
// CommentElement
// CrossReferenceElement
// EquationElement
// FormatElement
// ImageElement
// KeywordsElement
// ListOfFiguresElement
// ListOfTablesElement
// NoteElement
// ParagraphElement
// PasteMarkerElement
// PageBreakElement
// PermissionViewElement
// RedactedElement
// SectionBreakElement
// SymbolElement
// TableElement
// TableCellElement
// TableOfContentsElement
// TemporaryComment
// TrackInsertElement
// TrackDeleteElement
// InvalidElement

export const ELEMENT_TAGS = {
  ApprovedElement: 'APPROVED-ELEMENT',
  ApproveViewElement: 'APPROVE-VIEW-ELEMENT',
  AuthorsElement: 'AUTHORS-ELEMENT',
  CitationElement: 'CITATION-ELEMENT',
  CitationsGroupElement: 'CITATIONS-GROUP-ELEMENT',
  CommentElement: 'COMMENT-ELEMENT',
  CrossReferenceElement: 'CROSS-REFERENCE-ELEMENT',
  EquationElement: 'EQUATION-ELEMENT',
  FieldElement: 'FIELD-ELEMENT',
  FigureElement: 'FIGURE-ELEMENT',
  FormatElement: 'FORMAT-ELEMENT',
  ImageElement: 'IMAGE-ELEMENT',
  InvalidElement: 'INVALID-ELEMENT',
  KeywordsElement: 'KEYWORDS-ELEMENT',
  ListOfFiguresElement: 'LIST-OF-FIGURES-ELEMENT',
  ListOfTablesElement: 'LIST-OF-TABLES-ELEMENT',
  NoteElement: 'NOTE-ELEMENT',
  ParagraphElement: 'PARAGRAPH-ELEMENT',
  PasteMarkerElement: 'PASTE-MARKER-ELEMENT',
  PageBreakElement: 'PAGE-BREAK-ELEMENT',
  PermissionViewElement: 'PERMISSION-VIEW-ELEMENT',
  LoaderElement: 'LOADER-ELEMENT',
  PlaceholderElement: 'PLACEHOLDER-ELEMENT',
  RedactedElement: 'REDACTED-ELEMENT',
  SectionBreakElement: 'SECTION-BREAK-ELEMENT',
  SymbolElement: 'SYMBOL-ELEMENT',
  TableHeaderElement: 'THEAD',
  TableElement: 'TABLE',
  TableBodyElement: 'TBODY',
  TableRowElement: 'TR',
  TableCellElement: 'TD',
  TableHeaderCellElement: 'TH',
  TableOfContentsElement: 'TABLE-OF-CONTENTS-ELEMENT',
  TemporaryComment: 'TEMP-COMMENT-ELEMENT',
  TrackInsertElement: 'TRACK-INS-ELEMENT',
  TrackDeleteElement: 'TRACK-DEL-ELEMENT',
  HyperlinkElement: 'HYPERLINK-ELEMENT',
  ColumnBreakElement: 'COLUMN-BREAK-ELEMENT',
  ReferencesSectionElement: 'REFERENCES-SECTION-ELEMENT',
  TabElement: 'TAB-ELEMENT',
  Text: 'TEXT',

  PageElement: 'PAGE-ELEMENT',
  SectionElement: 'SECTION-ELEMENT',
} as const;

export const ELEMENT_TYPES = {
  ApprovedElement: 'approved',
  ApproveViewElement: 'approve-view',
  AuthorsElement: 'a',
  CitationElement: 'citation',
  CitationsGroupElement: 'citations-group',
  CommentElement: 'comment',
  CrossReferenceElement: 'cross-reference',
  EquationElement: 'equation',
  FieldElement: 'f',
  FigureElement: 'figure',
  FormatElement: 'format',
  LegacyImageElement: 'image-element',
  ImageElement: 'img',
  InvalidElement: 'invalid',
  KeywordsElement: 'k',
  ListOfFiguresElement: 'tof',
  ListOfTablesElement: 'tot',
  NoteElement: 'note',
  ParagraphElement: 'p',
  PasteMarkerElement: 'paste-marker',
  PageBreakElement: 'pb',
  PermissionViewElement: 'permission-view',
  LoaderElement: 'loader',
  PlaceholderElement: 'ph',
  RedactedElement: 'redacted',
  SectionBreakElement: 'sb',
  SymbolElement: 'symbol',
  TableElement: 'tbl',
  TableHeaderElement: 'tblh',
  TableBodyElement: 'tblb',
  TableRowElement: 'tblr',
  TableCellElement: 'tblc',
  TableOfContentsElement: 'toc',
  TemporaryComment: 'temp-comment',
  TrackDeleteElement: 'tracked-delete',
  TrackInsertElement: 'tracked-insert',
  Text: 'text',
  HyperlinkElement: 'link',
  ColumnBreakElement: 'cb',
  ReferencesSectionElement: 'rs',
  TabElement: 'tab',

  PageElement: 'page',
  SectionElement: 'section',
} as const;

export const ELEMENT_IDENTIFIERS = {
  ApprovedElement: 'approved-element',
  ApproveViewElement: 'approve-view-element',
  AuthorsElement: 'authors-element',
  CitationElement: 'citation-element',
  CitationsGroupElement: 'citations-group-element',
  CommentElement: 'comment-element',
  CrossReferenceElement: 'cross-reference-element',
  EquationElement: 'equation-element',
  FieldElement: 'field-element',
  FigureElement: 'figure-element',
  FormatElement: 'format-element',
  ImageElement: 'image-element',
  InvalidElement: 'invalid-element',
  KeywordsElement: 'keywords-element',
  ListOfFiguresElement: 'list-of-figures-element',
  ListOfTablesElement: 'list-of-tables-element',
  NoteElement: 'note-element',
  ParagraphElement: 'paragraph-element',
  PasteMarkerElement: 'paste-marker-element',
  PageBreakElement: 'page-break-element',
  PermissionViewElement: 'permission-view-element',
  LoaderElement: 'loader-element',
  PlaceholderElement: 'placeholder-element',
  RedactedElement: 'redacted-element',
  SectionBreakElement: 'section-break-element',
  SymbolElement: 'symbol-element',
  TableElement: 'table-element',
  TableBodyElement: 'tbody',
  TableRowElement: 'tr',
  TableCellElement: 'table-cell-element',
  TableOfContentsElement: 'table-of-contents-element',
  TemporaryComment: 'temp-comment-element',
  TrackInsertElement: 'track-ins-element',
  TrackDeleteElement: 'track-del-element',
  HyperlinkElement: 'hyperlink-element',
  ColumnBreakElement: 'column-break-element',
  ReferencesSectionElement: 'references-section-element',
  TabElement: 'tab-element',
  Text: 'text',

  PageElement: 'page-element',
  SectionElement: 'section-element',
} as const;

export const DISPLAY_TYPES = {
  INLINE: 'INLINE',
  BLOCK: 'BLOCK',
} as const;

export const ELEMENT_TYPES_VALUES = Object.values(ELEMENT_TYPES);

export const SECTION_BREAK_TYPES = {
  CONTINUOUS: 'c',
  EVEN_PAGE: 'ep',
  NEXT_COLUMN: 'nc',
  NEXT_PAGE: 'np',
  ODD_PAGE: 'op',
} as const;

export const PARAGRAPH_STYLES_TYPES = {
  HEADING1: 'h1',
  HEADING2: 'h2',
  HEADING3: 'h3',
  HEADING4: 'h4',
  HEADING5: 'h5',
  HEADING6: 'h6',
  ABSTRACT: 'a',
  MAIN_TITLE: 't',
  FIGURE_CAPTION: 'fc',
  TABLE_CAPTION: 'tc',
  EQUATION_CAPTION: 'ec',
  PARAGRAPH: ELEMENT_TYPES.ParagraphElement,
} as const;

export const PARAGRAPH_HEANDING_TYPES = {
  HEADING1: PARAGRAPH_STYLES_TYPES.HEADING1,
  HEADING2: PARAGRAPH_STYLES_TYPES.HEADING2,
  HEADING3: PARAGRAPH_STYLES_TYPES.HEADING3,
  HEADING4: PARAGRAPH_STYLES_TYPES.HEADING4,
  HEADING5: PARAGRAPH_STYLES_TYPES.HEADING5,
  HEADING6: PARAGRAPH_STYLES_TYPES.HEADING6,
} as const;

export const FIELD_ELEMENT_TYPES = {
  CROSS_REFERENCE: 'cr',
  CAPTION: 'cpt',
  STYLE_REF: 'sr',
  CITATION_FIELD: 'cf',
  DATE_TIME: 'd',
} as const;

export const ELEMENTS = {
  ApprovedElement: {
    TAG: ELEMENT_TAGS.ApprovedElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.ApprovedElement,
    ELEMENT_TYPE: ELEMENT_TYPES.ApprovedElement,
  },
  ApproveViewElement: {
    TAG: ELEMENT_TAGS.ApproveViewElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.ApproveViewElement,
    ELEMENT_TYPE: ELEMENT_TYPES.ApproveViewElement,
  },
  AuthorsElement: {
    TAG: ELEMENT_TAGS.AuthorsElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.AuthorsElement,
    ELEMENT_TYPE: ELEMENT_TYPES.AuthorsElement,
  },
  CitationElement: {
    TAG: ELEMENT_TAGS.CitationElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.CitationElement,
    ELEMENT_TYPE: ELEMENT_TYPES.CitationElement,
  },
  CitationsGroupElement: {
    TAG: ELEMENT_TAGS.CitationsGroupElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.CitationsGroupElement,
    ELEMENT_TYPE: ELEMENT_TYPES.CitationsGroupElement,
  },
  CommentElement: {
    TAG: ELEMENT_TAGS.CommentElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.CommentElement,
    ELEMENT_TYPE: ELEMENT_TYPES.CommentElement,
  },
  CrossReferenceElement: {
    TAG: ELEMENT_TAGS.CrossReferenceElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.CrossReferenceElement,
    ELEMENT_TYPE: ELEMENT_TYPES.CrossReferenceElement,
  },
  EquationElement: {
    TAG: ELEMENT_TAGS.EquationElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.EquationElement,
    ELEMENT_TYPE: ELEMENT_TYPES.EquationElement,
  },
  FieldElement: {
    TAG: ELEMENT_TAGS.FieldElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.FieldElement,
    ELEMENT_TYPE: ELEMENT_TYPES.FieldElement,
    TYPES: {
      EDITABLE: [
        FIELD_ELEMENT_TYPES.CROSS_REFERENCE,
        FIELD_ELEMENT_TYPES.CAPTION,
        FIELD_ELEMENT_TYPES.CITATION_FIELD,
        FIELD_ELEMENT_TYPES.STYLE_REF,
      ],
      NON_EDITABLE: [FIELD_ELEMENT_TYPES.DATE_TIME],
      ...FIELD_ELEMENT_TYPES,
    },
  },
  FigureElement: {
    TAG: ELEMENT_TAGS.FigureElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.FigureElement,
    ELEMENT_TYPE: ELEMENT_TYPES.FigureElement,
  },
  FormatElement: {
    TAG: ELEMENT_TAGS.FormatElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.FormatElement,
    ELEMENT_TYPE: ELEMENT_TYPES.FormatElement,
  },
  ImageElement: {
    TAG: ELEMENT_TAGS.ImageElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.ImageElement,
    ELEMENT_TYPE: ELEMENT_TYPES.ImageElement,
    LEGACY_TYPE: ELEMENT_TYPES.LegacyImageElement,
  },
  InvalidElement: {
    TAG: ELEMENT_TAGS.InvalidElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.InvalidElement,
    ELEMENT_TYPE: ELEMENT_TYPES.InvalidElement,
  },
  KeywordsElement: {
    TAG: ELEMENT_TAGS.KeywordsElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.KeywordsElement,
    ELEMENT_TYPE: ELEMENT_TYPES.KeywordsElement,
  },
  ListOfFiguresElement: {
    TAG: ELEMENT_TAGS.ListOfFiguresElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.ListOfFiguresElement,
    ELEMENT_TYPE: ELEMENT_TYPES.ListOfFiguresElement,
  },
  ListOfTablesElement: {
    TAG: ELEMENT_TAGS.ListOfTablesElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.ListOfTablesElement,
    ELEMENT_TYPE: ELEMENT_TYPES.ListOfTablesElement,
  },
  NoteElement: {
    TAG: ELEMENT_TAGS.NoteElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.NoteElement,
    ELEMENT_TYPE: ELEMENT_TYPES.NoteElement,
  },
  ParagraphElement: {
    TAG: ELEMENT_TAGS.ParagraphElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.ParagraphElement,
    ELEMENT_TYPE: ELEMENT_TYPES.ParagraphElement,
    REPEATABLE_STYLES: [ELEMENT_TYPES.ParagraphElement, 'a'],
    NON_REPEATABLE_STYLES: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 't', 'fc', 'tc', 'ec'],
    BASE_STYLES: PARAGRAPH_STYLES_TYPES,
    HEADING_TYPES: PARAGRAPH_HEANDING_TYPES,
  },
  PasteMarkerElement: {
    TAG: ELEMENT_TAGS.PasteMarkerElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.PasteMarkerElement,
    ELEMENT_TYPE: ELEMENT_TYPES.PasteMarkerElement,
  },
  PageBreakElement: {
    TAG: ELEMENT_TAGS.PageBreakElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.PageBreakElement,
    ELEMENT_TYPE: ELEMENT_TYPES.PageBreakElement,
  },
  PermissionViewElement: {
    TAG: ELEMENT_TAGS.PermissionViewElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.PermissionViewElement,
    ELEMENT_TYPE: ELEMENT_TYPES.PermissionViewElement,
  },
  LoaderElement: {
    TAG: ELEMENT_TAGS.LoaderElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.LoaderElement,
    ELEMENT_TYPE: ELEMENT_TYPES.LoaderElement,
  },
  PlaceholderElement: {
    TAG: ELEMENT_TAGS.PlaceholderElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.PlaceholderElement,
    ELEMENT_TYPE: ELEMENT_TYPES.PlaceholderElement,
  },
  RedactedElement: {
    TAG: ELEMENT_TAGS.RedactedElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.RedactedElement,
    ELEMENT_TYPE: ELEMENT_TYPES.RedactedElement,
  },
  SectionBreakElement: {
    TAG: ELEMENT_TAGS.SectionBreakElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.SectionBreakElement,
    ELEMENT_TYPE: ELEMENT_TYPES.SectionBreakElement,
    TYPES: SECTION_BREAK_TYPES,
  },
  SymbolElement: {
    TAG: ELEMENT_TAGS.SymbolElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.SymbolElement,
    ELEMENT_TYPE: ELEMENT_TYPES.SymbolElement,
  },
  TableElement: {
    TAG: ELEMENT_TAGS.TableElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.TableElement,
    ELEMENT_TYPE: ELEMENT_TYPES.TableElement,
    ELEMENTS: {
      TABLE_HEADER: {
        TAG: ELEMENT_TAGS.TableHeaderElement,
        ELEMENT_TYPE: ELEMENT_TYPES.TableHeaderElement,
      },
      TABLE_HEADER_CELL: {
        TAG: ELEMENT_TAGS.TableHeaderCellElement,
      },
      TABLE_BODY: {
        TAG: ELEMENT_TAGS.TableBodyElement,
        ELEMENT_TYPE: ELEMENT_TYPES.TableBodyElement,
      },
      TABLE_ROW: {
        TAG: ELEMENT_TAGS.TableRowElement,
        ELEMENT_TYPE: ELEMENT_TYPES.TableRowElement,
      },
    },
  },
  TableBodyElement: {
    TAG: ELEMENT_TAGS.TableBodyElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.TableBodyElement,
    ELEMENT_TYPE: ELEMENT_TYPES.TableBodyElement,
  },
  TableRowElement: {
    TAG: ELEMENT_TAGS.TableRowElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.TableRowElement,
    ELEMENT_TYPE: ELEMENT_TYPES.TableRowElement,
  },
  TableCellElement: {
    TAG: ELEMENT_TAGS.TableCellElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.TableCellElement,
    ELEMENT_TYPE: ELEMENT_TYPES.TableCellElement,
  },
  TableOfContentsElement: {
    TAG: ELEMENT_TAGS.TableOfContentsElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.TableOfContentsElement,
    ELEMENT_TYPE: ELEMENT_TYPES.TableOfContentsElement,
  },
  TemporaryComment: {
    TAG: ELEMENT_TAGS.TemporaryComment,
    IDENTIFIER: ELEMENT_IDENTIFIERS.TemporaryComment,
    ELEMENT_TYPE: ELEMENT_TYPES.TemporaryComment,
  },
  TrackInsertElement: {
    TAG: ELEMENT_TAGS.TrackInsertElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.TrackInsertElement,
    ELEMENT_TYPE: ELEMENT_TYPES.TrackInsertElement,
  },
  TrackDeleteElement: {
    TAG: ELEMENT_TAGS.TrackDeleteElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.TrackDeleteElement,
    ELEMENT_TYPE: ELEMENT_TYPES.TrackDeleteElement,
  },
  HyperlinkElement: {
    TAG: ELEMENT_TAGS.HyperlinkElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.HyperlinkElement,
    ELEMENT_TYPE: ELEMENT_TYPES.HyperlinkElement,
  },
  ColumnBreakElement: {
    TAG: ELEMENT_TAGS.ColumnBreakElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.ColumnBreakElement,
    ELEMENT_TYPE: ELEMENT_TYPES.ColumnBreakElement,
  },
  ReferencesSectionElement: {
    TAG: ELEMENT_TAGS.ReferencesSectionElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.ReferencesSectionElement,
    ELEMENT_TYPE: ELEMENT_TYPES.ReferencesSectionElement,
  },
  TabElement: {
    TAG: ELEMENT_TAGS.TabElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.TabElement,
    ELEMENT_TYPE: ELEMENT_TYPES.TabElement,
  },
  Text: {
    ELEMENT_TYPE: ELEMENT_TYPES.Text,
    IDENTIFIER: ELEMENT_IDENTIFIERS.Text,
    TAG: ELEMENT_TAGS.Text,
  },
  PageElement: {
    ELEMENT_TYPE: ELEMENT_TYPES.PageElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.PageElement,
    TAG: ELEMENT_TAGS.PageElement,
  },
  SectionElement: {
    ELEMENT_TYPE: ELEMENT_TYPES.SectionElement,
    IDENTIFIER: ELEMENT_IDENTIFIERS.SectionElement,
    TAG: ELEMENT_TAGS.SectionElement,
  },
} as const;

export const TEXT_ALIGN_PROPERTIES = {
  left: 'left',
  justify: 'justify',
  right: 'right',
  center: 'center',
} as const;

export const INDENT_TYPE = {
  NONE: 'none',
  FIRST_LINE: 'f',
  HANGING: 'h',
} as const;

export const MEASURE_RATIO = {
  cm: {
    cm: 1,
    pt: 28.346456693,
    in: 0.393701,
  },
  in: {
    in: 1,
    pt: 72,
    cm: 2.54,
  },
  px: {
    px: 1,
    pt: 0.75,
    cm: 0.0264583333,
  },
  pt: {
    pt: 1,
    cm: 0.0352777778,
  },
} as const;

export const DEFAULT_STYLE_OBJECT: Editor.Elements.DefaultStyleObject = {
  p: {
    p: {
      a: 'l',
      bg: false,
      color: 'rgb(0, 0, 0)',
      fontfamily: 'Times New Roman',
      fontsize: 10,
      italic: false,
      lh: 1,
      sa: 0,
      sb: 0,
      underline: false,
      bold: false,
      v: false,
      asb: false,
      asa: false,
      wc: true,
      kn: false,
      kl: false,
      cts: false,
      pbb: false,
    },
    n: 'Paragraph',
    id: 'p',
    b: true,
  },
} as const;

//TODO:TS this should be related to _types/global.d.ts 'Citation.priority'
export const CITATION_PRIORITY = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
} as const;

export const DEFAULT_TABLE_BORDERS = {
  t: {
    w: 0.75,
    s: 's',
    c: '000000',
  },
  b: {
    w: 0.75,
    s: 's',
    c: '000000',
  },
  l: {
    w: 0.75,
    s: 's',
    c: '000000',
  },
  r: {
    w: 0.75,
    s: 's',
    c: '000000',
  },
} as const;
