import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, Toggle, Tooltip } from 'dodoc-design-system';

import { usePublicProfiles, useSelector } from '_common/hooks';

import styles from './WatchToggle.module.scss';

type WatchToggleProps = {
  watchers: Card.Task.Watchers;
  disabled: boolean;
  onWatch: () => void;
  openWatchModal: () => void;
  testId: string;
};

const WatchToggle = ({ watchers, disabled, testId, onWatch, openWatchModal }: WatchToggleProps) => {
  const intl = useIntl();
  const currentUserId = useSelector((state) => state.auth.userId);
  const { profiles } = usePublicProfiles(watchers.length === 1 ? watchers : undefined);

  const tooltipContent = useMemo(() => {
    if (watchers.length === 1) {
      return intl.formatMessage({ id: 'TASK_ONE_WATCHER' }, { data: profiles[watchers[0]]?.name });
    } else if (watchers.includes(currentUserId)) {
      return intl.formatMessage(
        { id: 'TASK_YOU_AND_OTHER_WATCHERS' },
        { data: watchers.length - 1 },
      );
    } else {
      return intl.formatMessage({ id: 'TASK_MANY_WATCHERS' }, { data: watchers.length });
    }
  }, [watchers]);

  return (
    <div className={styles.root}>
      <Toggle
        size="medium"
        variant="link"
        icon={watchers?.includes(currentUserId) ? 'WatcherBlue' : 'WatcherGrey'}
        isToggled={watchers?.includes(currentUserId)}
        onClick={onWatch}
        disabled={disabled}
        testId={`${testId}-watch-toggle`}
      />
      {watchers.length > 0 && (
        <Tooltip placement="top" content={tooltipContent} testId={`${testId}-watchers-tooltip`}>
          <span className={styles.counter}>
            <Link
              variant="neutral"
              size="medium"
              onClick={openWatchModal}
              testId={`${testId}-watchers-link`}
            >
              {watchers.length}
            </Link>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default WatchToggle;
