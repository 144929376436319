import { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { InputField, Input, Button } from 'dodoc-design-system';

import { useSelector, useDispatch } from '_common/hooks';

import { setAuthenticated } from 'Auth/redux/authSlice';
import { useActivateDeviceMutation } from '_common/services/api/authority';

import { LoadingIndicatorFull } from '_common/components';
import SecondaryAuthPageContainer from 'Auth/components/SecondaryAuthPageContainer/SecondaryAuthPageContainer';

import styles from './DeviceRegistrationPage.module.scss';

export const TEST_IDS = {
  CODE: {
    INPUT: 'email',
    ERROR: 'codeError',
  },
  DEVICE: {
    INPUT: 'email',
    ERROR: 'deviceError',
  },
  BUTTON: 'button',
};

const DeviceRegistrationPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const sendingNewCode = useSelector((state) => state.auth.sendingNewCode);
  const [activateDevice, { isLoading }] = useActivateDeviceMutation();

  const [submitting, setSubmitting] = useState(false);
  const [code, setCode] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [validations, setValidations] = useState({
    code: '',
    deviceName: '',
  });

  useEffect(() => {
    if (!isLoading) {
      setSubmitting(false);
    }
  }, [isLoading]);

  const goToSignIn = () => {
    dispatch(setAuthenticated(false));
  };

  const handleOnRegisterDevice = () => {
    const validations = {
      code: '',
      deviceName: '',
    };

    if (!code) {
      validations.code = intl.formatMessage({
        id: 'auth.errors.deviceCodeRequired',
      });
    }
    if (!deviceName) {
      validations.deviceName = intl.formatMessage({
        id: 'auth.errors.deviceNameRequired',
      });
    }

    if (Object.keys(validations).length > 0) {
      setValidations(validations);
    } else {
      setSubmitting(true);
      activateDevice({ code: Number(code), name: deviceName });
    }
  };

  return (
    <SecondaryAuthPageContainer
      title="NEW_DEVICE_DETECTED"
      description="NEW_DEVICE_DETECTED_DESCRIPTION"
    >
      {sendingNewCode && <LoadingIndicatorFull />}
      <InputField
        size="large"
        label={intl.formatMessage({ id: 'LOGIN_CODE' })}
        feedback={validations.code}
        testId={TEST_IDS.CODE.ERROR}
      >
        <span data-testid={TEST_IDS.CODE.INPUT}>
          <Input
            size="large"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder={intl.formatMessage({ id: 'LOGIN_CODE_PLACEHOLDER' })}
            error={!!validations.code}
            testId="login-code"
          />
        </span>
      </InputField>
      <InputField
        size="large"
        label={intl.formatMessage({ id: 'DEVICE_NAME' })}
        feedback={validations.deviceName}
        testId={TEST_IDS.DEVICE.ERROR}
      >
        <span data-testid={TEST_IDS.CODE.INPUT}>
          <Input
            size="large"
            value={deviceName}
            onChange={(e) => setDeviceName(e.target.value)}
            placeholder={intl.formatMessage({ id: 'DEVICE_NAME_PLACEHOLDER' })}
            error={!!validations.deviceName}
            testId="device-name"
          />
        </span>
      </InputField>
      <div className={styles.submit}>
        <Button
          size="medium"
          variant="primary"
          fullWidth
          onClick={handleOnRegisterDevice}
          loading={submitting}
          testId={TEST_IDS.BUTTON}
        >
          <FormattedMessage id="REGISTER_DEVICE" />
        </Button>
      </div>
      <Button size="medium" variant="link" onClick={goToSignIn} disabled={submitting} testId="sign-in-to-doDOC-button">
        <FormattedMessage id="SIGN_IN_TO_DODOC" />
      </Button>
    </SecondaryAuthPageContainer>
  );
};

export default DeviceRegistrationPage;
