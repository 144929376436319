import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';
import { usePDFContext } from 'PDF/PDFContext';

import { closeAndResetModal } from '_common/modals/ModalsSlice';

type ActionProps = {
  deleteTask: { taskId: PDF.Annotation.Task['id']; pageNumber: PDF.Annotation['pageNumber'] };
  restorePDFDocumentVersion: { loadedVersion: number };
  refresh: void;
};

type Action = keyof ActionProps;

const MODAL = 'PDFConfirmationModal';

const PDFConfirmationModal = () => {
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const headerType = useSelector((state) => state.modals[MODAL].headerType);
  const title = useSelector((state) => state.modals[MODAL].title);
  const titleValues = useSelector((state) => state.modals[MODAL].titleValues);
  const message = useSelector((state) => state.modals[MODAL].message);
  const messageValues = useSelector((state) => state.modals[MODAL].messageValues);
  const messageChildren = useSelector((state) => state.modals[MODAL].messageChildren);
  const confirmButtonTextId = useSelector((state) => state.modals[MODAL].confirmButtonTextId);
  const confirmButtonTextValues = useSelector(
    (state) => state.modals[MODAL].confirmButtonTextValues,
  );
  const confirmButtonType = useSelector((state) => state.modals[MODAL].confirmButtonType);
  const cancelButtonTextId = useSelector((state) => state.modals[MODAL].cancelButtonTextId);
  const cancelButtonTextValues = useSelector((state) => state.modals[MODAL].cancelButtonTextValues);
  const cancelButtonShow = useSelector((state) => state.modals[MODAL].cancelButtonShow);
  const persistent = useSelector((state) => state.modals[MODAL].persistent);
  const actionCode = useSelector((state) => state.modals[MODAL].actionCode);
  const actionValue = useSelector((state) => state.modals[MODAL].actionValue);
  const width = useSelector((state) => state.modals[MODAL].width);

  const ACTIONS: { [key in Action]: (props: ActionProps[key]) => void } = {
    deleteTask: async ({ pageNumber, taskId }) => {
      try {
        await pdfManager.deleteAnnotation(pageNumber, taskId);
        notify({
          type: 'success',
          title: 'TASK_DELETED',
          message: 'THE_TASK_WAS_SUCCESSFULLY_DELETED',
        });
      } catch (e) {
        throw e;
      }
    },
    restorePDFDocumentVersion: async ({ loadedVersion }) => {
      try {
        await pdfManager.restoreVersion(loadedVersion);
        notify({
          type: 'success',
          title: 'DOCUMENT_VERSION_RESTORED',
          message: 'THE_DOCUMENT_VERSION_WAS_SUCCESSFULLY_RESTORED',
        });
      } catch (e) {
        throw e;
      }
    },
    refresh: () => {
      window.location.reload();
    },
  };
  const typedActionCode = actionCode in ACTIONS ? (actionCode as Action) : undefined;

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const confirm = () => {
    if (typedActionCode && ACTIONS[typedActionCode]) {
      ACTIONS[typedActionCode](actionValue);
    }
    close();
  };

  const getText = ({
    id,
    values,
    messageChildren,
  }: TranslationMessage & { messageChildren?: ReactNode }) =>
    messageChildren || <FormattedMessage id={id} values={values} />;

  const renderConfirmButton = () => {
    if (confirmButtonType === 'primary') {
      return (
        <Button
          size="medium"
          variant="primary"
          onClick={confirm}
          testId="confirm-modal-submit-button"
        >
          <FormattedMessage id={confirmButtonTextId} values={confirmButtonTextValues} />
        </Button>
      );
    }
    if (confirmButtonType === 'danger') {
      return (
        <Button
          size="medium"
          variant="danger"
          onClick={confirm}
          testId="confirm-modal-submit-button"
        >
          <FormattedMessage id={confirmButtonTextId} values={confirmButtonTextValues} />
        </Button>
      );
    }

    return (
      <Button size="medium" onClick={confirm} testId="confirm-modal-submit-button">
        <FormattedMessage id={confirmButtonTextId} values={confirmButtonTextValues} />
      </Button>
    );
  };

  const modalWidth = typeof width === 'number' ? `${width}rem` : width;

  return (
    <Modal
      open={!!isOpen}
      onClose={persistent ? undefined : close}
      width={modalWidth}
      type={headerType}
      testId="confirmation-modal"
    >
      <Modal.Header onClose={persistent ? undefined : close}>
        {getText({ id: title, values: titleValues })}
      </Modal.Header>
      <Modal.Body>{getText({ id: message, values: messageValues, messageChildren })}</Modal.Body>
      <Modal.Footer>
        {cancelButtonShow && (
          <Button size="medium" onClick={close} testId="confirm-modal-close-button">
            <FormattedMessage id={cancelButtonTextId} values={cancelButtonTextValues} />
          </Button>
        )}
        {renderConfirmButton()}
      </Modal.Footer>
    </Modal>
  );
};

export default PDFConfirmationModal;
