import { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, Input, Tab, Modal, EmptyState } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks/redux';
import { useDebounce } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import {
  deletePersonalSavedFilter,
  FilterObject,
  loadPersonalSavedFilters,
  SearchPageSliceState,
  setAdvancedFilters,
  setAppliedFilterSet,
  setSearchMode,
  setSearchQuery,
} from 'Search/redux/SearchPageSlice';

import RemovableItem from './RemovableItem/RemovableItem';
import useOldFilterParser from './useOldFilterParser';

import styles from './AccessSavedFiltersModal.module.scss';

const MODAL = 'AccessSavedFiltersModal';
const SEARCH_LIMIT = 10;
const TABS = {
  personal: { id: 'personal', label: 'PERSONAL_FILTER' },
  // Disabled because it hasn't been developed yet
  // tenant: { id: 'tenant', label: 'TENANT_FILTER' },
};

const AccessSavedFiltersModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const oldFilterParser = useOldFilterParser();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const personalFilters = useSelector((state) => state.search.savedFilters.personal);

  const [filterSetSearch, setFilterSetSearch] = useState('');
  const [selectedTab, setSelectedTab] = useState(Object.values(TABS)[0].id);
  const [selectedFilterId, setSelectedFilterId] = useState<FilterObject['id'] | undefined>(
    undefined,
  );
  const [removingFilterId, setRemovingFilterId] = useState<FilterObject['id'] | undefined>(
    undefined,
  );

  const debouncedFilterValue = useDebounce(filterSetSearch, 250);
  const filteredList = useMemo(() => {
    const tempList = Object.values(personalFilters).filter((item) =>
      item.name.toLowerCase().includes(filterSetSearch.toLowerCase()),
    );

    // Selected filter will no longer be selected in it doesn't exist in the filtered list
    if (!tempList.some((item) => item.id === selectedTab)) {
      setSelectedFilterId(undefined);
    }
    return tempList;
  }, [debouncedFilterValue, personalFilters]);

  useEffect(() => {
    if (isOpen) {
      dispatch(loadPersonalSavedFilters());
    }
  }, [isOpen]);

  //#region UI Handles
  const handleClose = () => {
    setFilterSetSearch('');
    setRemovingFilterId(undefined);
    setSelectedFilterId(undefined);

    dispatch(closeAndResetModal(MODAL));
  };

  const handleFilterSetSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilterSetSearch(e.target.value);
  };

  const handleCancel = () => setRemovingFilterId(undefined);

  const handleStartDelete = (savedFilterId: FilterObject['id']) => {
    setRemovingFilterId(savedFilterId);
    setSelectedFilterId(undefined);
  };

  const handleChangeTab = (tabId: string) => {
    setSelectedTab(tabId);
    setSelectedFilterId(undefined);
  };
  //#endregion

  const handleDelete = () => {
    if (removingFilterId) {
      dispatch(
        deletePersonalSavedFilter({
          filter: personalFilters[removingFilterId],
        }),
      );
    }

    setRemovingFilterId(undefined);
  };

  const handleApplyFilterSet = () => {
    if (selectedFilterId) {
      const selectedFilter = personalFilters[selectedFilterId];

      if (Array.isArray(selectedFilter.filters)) {
        const indexedParsedFilters: SearchPageSliceState['advancedFilters'] = {};

        selectedFilter.filters.forEach((filter) => {
          const parsedFilter = oldFilterParser(filter);
          if (parsedFilter) {
            indexedParsedFilters[parsedFilter.id] = { ...parsedFilter, unprocessed: true };
          }
        });
        dispatch(setAppliedFilterSet(selectedFilter.name));
        dispatch(setSearchMode('normal'));
        dispatch(setAdvancedFilters(indexedParsedFilters));
      } else {
        dispatch(setSearchMode('advanced'));
        dispatch(setSearchQuery({ query: selectedFilter.filters }));
      }

      notify({
        type: 'success',
        title: 'COMBINATION_FILTERS_APPLIED',
        message: 'FILTER_APPLIED_IN_THE_LIST',
        messageValues: { filterName: selectedFilter.name },
      });

      handleClose();
    }
  };

  const renderPersonalContent = () => {
    // Doesn't have personal filters
    if (Object.keys(personalFilters).length === 0) {
      return (
        <div className={styles.emptyStateWrapper}>
          <EmptyState
            size="medium"
            title={intl.formatMessage({
              id: 'NO_PERSONAL_SAVED_FILTERS',
            })}
            testId="no-personal-saved-filters"
          >
            <FormattedMessage id="SAVE_A_PERSONAL_FILTER_AND_WILL_BE_DISPLAYED_HERE" />
          </EmptyState>
        </div>
      );
    }

    // Doesn't have personal filters following the search input filter
    if (filteredList.length === 0) {
      return (
        <div className={styles.emptyStateWrapper}>
          <EmptyState
            size="medium"
            title={intl.formatMessage({
              id: 'NO_RESULTS_FOUND',
            })}
            testId="no-results-found"
          >
            <FormattedMessage id="NO_FILTERS_MATCH_FILTER" />
          </EmptyState>
        </div>
      );
    }

    // Render tab content
    return (
      <div className={styles.filterSetsContainer}>
        {filteredList.map((item: FilterObject, index) => (
          <RemovableItem
            key={`saved-filter-item-${item.id}`}
            prefixIcon="FiltersBlue"
            savedFilter={item}
            selected={item.id === selectedFilterId}
            beingRemoved={item.id === removingFilterId}
            onSelect={setSelectedFilterId}
            onCancel={handleCancel}
            onDelete={handleDelete}
            onStartDelete={handleStartDelete}
            testId={`advanced-search-view-saved-filters-modal-item-${index}`}
          />
        ))}
      </div>
    );
  };

  const renderTenantContent = () => {
    //Missing figma mockup definition
    return null;
  };

  return (
    <Modal
      open={!!isOpen}
      width="75rem"
      onClose={handleClose}
      testId="advanced-search-view-saved-filters-modal"
    >
      <Modal.Header onClose={handleClose}>
        <FormattedMessage id="SAVED_FILTERS" />
      </Modal.Header>
      <Modal.Body overflow="visible">
        <div className={styles.tabsWrapper}>
          {Object.values(TABS).map((tab) => (
            <Tab
              key={`saved-filter-tab-${tab.id}`}
              selected={selectedTab === tab.id}
              onClick={() => handleChangeTab(tab.id)}
              testId={`saved-filter-tab-${tab.id}`}
            >
              {intl.formatMessage({ id: tab.label })}
            </Tab>
          ))}
        </div>
        <Divider margin="0 0 3rem 0" />
        {Object.keys(personalFilters).length > SEARCH_LIMIT && (
          <Input
            value={filterSetSearch}
            onChange={handleFilterSetSearchChange}
            size="large"
            placeholder={intl.formatMessage({ id: 'SEARCH_SAVED_FILTERS' })}
            prefix="NavSearchBlue"
            margin="0 0 3rem 0"
            testId="saved-filters-search"
          />
        )}
        {selectedTab === TABS.personal.id ? renderPersonalContent() : renderTenantContent()}
      </Modal.Body>

      <Modal.Footer>
        <Button
          size="medium"
          onClick={handleClose}
          testId="advanced-search-view-saved-filters-modal-close"
        >
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          disabled={!selectedFilterId}
          variant="primary"
          onClick={handleApplyFilterSet}
          testId="advanced-search-view-saved-filters-modal-apply"
        >
          <FormattedMessage id="APPLY_FILTERS_TO_LIST" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccessSavedFiltersModal;
