/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { DocumentModel } from '_common/services/Realtime';
import BaseController from '../BaseController';
import { VersionIndex } from './Versions.types';

const DEFAULT_INDEX = {
  comments: [],
  commentsLoc: {},
  sugg: [],
  suggLoc: {},
  tasks: [],
  taskLoc: {},
  dltTasks: [],
  sct: [],
  nav: {
    list: [],
    data: {},
  },
};

export class VersionsController extends BaseController {
  private doc?: DocumentModel;

  start(documentId: string): void {
    this.registerTransportEvents({
      'NEW:VERSION': this.handleEventNewDocumentVersion.bind(this),
    });
    this.doc = this.Data.models?.get(this.Data?.models.TYPE_NAME.DOCUMENT, documentId);
  }

  private handleEventNewDocumentVersion() {
    this.Data.events?.emit('FORCE_REMOTE_RELOAD');
  }

  saveVersion(description: string): Promise<void> {
    if (!this.Data.users?.user?.id) {
      return Promise.reject(new Error('No user id provided!'));
    }
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'SAVE:VERSION',
        {
          description,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  editVersionDescription(description: string, index: number): Promise<void> {
    if (!this.Data.users?.user?.id) {
      return Promise.reject(new Error('No user id provided!'));
    }
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'EDIT:VERSION',
        {
          description,
          versionIndex: index,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  private requestVersionIndexing(versionIndex: number): Promise<VersionIndex> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'INDEX:VERSION',
        {
          versionIndex,
        },
        (response) => {
          if (response.success) {
            const value = response.payload as VersionIndex;
            resolve(value);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  private loadVersionIndexing(
    version: Realtime.Core.Document.Version | null,
    index: VersionIndex['index'] = DEFAULT_INDEX,
  ) {
    this.Data.comments?.list?.setVersionData(version, {
      index: index?.comments,
      locations: index?.commentsLoc,
    });

    this.Data.suggestions?.list?.setVersionData(version, {
      index: index.sugg,
      locations: index.suggLoc,
    });

    this.Data.tasks?.list?.setVersionData(version, {
      index: index.tasks,
      locations: index.taskLoc,
    });

    this.Data.tasks?.deleteList?.setVersionData(version, index.dltTasks);

    this.Data.sections?.list?.setVersionData(version, index.sct);

    //@ts-ignore
    this.Data.notes?.list?.setVersionData(version, {
      fnt: [],
      ent: [],
      locations: {},
    });
    this.Data.tableOfContents?.toc?.setVersionData(version, index.nav);
  }

  getVersion() {
    return this.Data.context?.version;
  }

  hasLoadedVersion() {
    return !!this.Data.context?.version;
  }

  async loadVersion(index: number | null) {
    let availableVersions = this.doc?.versions || [];
    let oldVersion = this.Data.context?.version;
    let version = null;
    if (index !== null && index !== undefined) {
      version = availableVersions[index];
    }
    if (oldVersion && version) {
      if (oldVersion?.creation !== version?.creation) {
        // load version
        const versionIndexing = await this.requestVersionIndexing(index as number);
        logger.debug('index:version', versionIndexing);
        this.loadVersionIndexing(version, versionIndexing.index);
        await this.Data.models?.setModelsVersion(version);
        if (this.Data.context) {
          this.Data.context.version = version;
        }
      }
    } else if (!oldVersion && version) {
      // load version
      const versionIndexing = await this.requestVersionIndexing(index as number);
      logger.debug('index:version', versionIndexing);
      if (this.Data.context) {
        this.Data.context.version = version;
      }
      this.loadVersionIndexing(version, versionIndexing.index);
      await this.Data.models?.setModelsVersion(version);
    } else if (oldVersion && !version) {
      if (this.Data.context) {
        this.Data.context.version = version;
      }
      this.loadVersionIndexing(null);
      await this.Data.models?.setModelsVersion(null);
    }
  }

  async restoreVersion(index: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'RESTORE:VERSION',
        {
          versionIndex: index,
        },
        (response) => {
          if (response.success) {
            this.loadVersion(null);
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  backToCurrentVersion() {
    return this.loadVersion(null);
  }

  stop(): void {}

  destroy(): void {
    this.unregisterAllTransportEvents();
  }
}
