import { ChangeEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Divider, usePopper, Toggle, Tooltip } from 'dodoc-design-system';
import { useDispatch, useSelector } from '_common/hooks';
import { ELEMENTS } from 'Editor/services/consts';
import EditorManager from 'Editor/services/EditorManager';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import { openAndUpdateModal, openModal } from '_common/modals/ModalsSlice';
import TableCreator from 'Editor/components/TableCreator/TableCreator';
import { setSidebarPanelTab, setSidebarView } from 'Editor/redux/SidebarSlice';
import { selectIsIEnvision } from 'App/redux/appSlice';
import { useSuiteObject } from '_common/suite';

type InsertProps = {
  insertComment: () => void;
};

const Insert = ({ insertComment }: InsertProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const object = useSuiteObject();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isTextSelected = useSelector((state) => state.editor.status.selection.TEXT);
  const isInsertingComment = useSelector((state) => state.editor.comments.insert.inserting);
  const isPageLayout = useSelector(selectIsPageLayout);
  const isEnvision = useSelector(selectIsIEnvision);

  const disabled = isReadOnlyMode;
  const hasEditPermission =
    object.user_permissions.includes('admin') ||
    object.user_permissions.includes('owner') ||
    object.user_permissions.includes('edit');
  const canCreateTask = !isEnvision;

  const { isOpen, referenceProps, popperProps, close } = usePopper({
    placement: 'bottom-end',
  });

  const insertPageBreak = () => {
    EditorManager.getInstance().insertPageBreak();
  };

  const insertSectionBreak = (type: ValueOf<typeof ELEMENTS.SectionBreakElement.TYPES>) => {
    EditorManager.getInstance().insertSectionBreak(type);
  };

  const insertColumnBreak = () => {
    EditorManager.getInstance().insertColumnBreak();
  };

  const insertReferenceSection = () => {
    EditorManager.getInstance().insertReferenceSectionElement();
  };

  const insertTableOfContents = () => {
    EditorManager.getInstance().insertTableOfContents();
  };

  const insertListOfFigures = () => {
    EditorManager.getInstance().insertListOfFigures();
  };

  const insertListOfTables = () => {
    EditorManager.getInstance().insertListOfTables();
  };

  const startCreatingNote = (type: Editor.Note['type']) => {
    EditorManager.getInstance().startCreatingNote(type);
  };

  const insertKeywords = () => {
    EditorManager.getInstance().insertKeywords();
  };

  const insertAuthors = () => {
    EditorManager.getInstance().insertAuthors();
  };

  const handleUploadFigure: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;

    if (files?.[0]) {
      let i;
      for (i = 0; i < files.length; i += 1) {
        EditorManager.getInstance().handleInsertImage(files[i]);
      }
    }
  };

  const createNewTask = () => {
    const manager = EditorManager.getInstance();
    if (manager) {
      manager.openCreateTaskOverlay();
    }
  };

  const handleTableInsert = (rows: number, columns: number) => {
    EditorManager.getInstance().insertTable(rows, columns);
  };

  const openEquationsModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'EquationsModal',
        data: {
          isEdit: null,
          equation: null,
        },
      }),
    );
  };

  const openCaptionsModal = () => {
    dispatch(openAndUpdateModal({ modal: 'CaptionsModal', data: { editMode: false } }));
  };

  const showCharmapModal = () => {
    dispatch(openModal('CharmapModal'));
  };

  const showInsertLink = () => {
    const data = EditorManager.getInstance().getLinkData();
    dispatch(
      openAndUpdateModal({
        modal: 'HyperlinkModal',
        data: {
          ...data,
        },
      }),
    );
  };

  return (
    <>
      <Toggle
        size="medium"
        variant="ghost"
        isToggled={isOpen}
        margin="0 1rem 0 0"
        testId="insert-button"
        {...referenceProps}
      >
        <FormattedMessage id="editor.menu.insert.value" />
      </Toggle>
      <Dropdown {...popperProps} testId="topbar-menu-insert">
        <Dropdown.SubMenu
          width="fit-content"
          itemContent={<FormattedMessage id="editor.menu.insert.table" />}
          disabled={disabled || isPageLayout}
          testId="menu-insert-table"
        >
          <TableCreator
            insertTable={(rows, columns) => {
              handleTableInsert(rows, columns);
              close();
            }}
          />
        </Dropdown.SubMenu>
        <Dropdown.Item
          upload
          accept=".jpeg,.jpg,.bmp,.tiff,.gif,.png,.eps"
          multiple
          onChange={handleUploadFigure}
          disabled={disabled || isPageLayout}
          testId="figure-dropdown-item"
        >
          <FormattedMessage id="editor.menu.insert.figure" />
        </Dropdown.Item>
        <Divider />
        <Tooltip
          placement="right"
          disabled={!isInsertingComment}
          content={intl.formatMessage({ id: 'CANNOT_CREATE_COMMENT_BECAUSE_ALREADY_OPEN' })}
          testId="comment-dropdown-item-tooltip"
        >
          <Dropdown.Item
            onClick={insertComment}
            disabled={!isTextSelected}
            testId="comment-dropdown-item"
          >
            <FormattedMessage id="editor.menu.insert.comment" />
          </Dropdown.Item>
        </Tooltip>
        <Dropdown.Item
          onClick={() => {
            dispatch(setSidebarView('CROSS_REFERENCES'));
          }}
          disabled={disabled || isPageLayout}
          testId="cross-reference-dropdown-item"
        >
          <FormattedMessage id="CROSS_REFERENCE" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            startCreatingNote('footnote');
          }}
          disabled={disabled || isPageLayout}
          testId="footnote-dropdown-item"
        >
          <FormattedMessage id="editor.menu.insert.footnote" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            startCreatingNote('endnote');
          }}
          disabled={disabled || isPageLayout}
          testId="endnote-dropdown-item"
        >
          <FormattedMessage id="ENDNOTE" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={openEquationsModal}
          disabled={disabled || isPageLayout}
          testId="equation-dropdown-item"
        >
          <FormattedMessage id="EQUATION" />
        </Dropdown.Item>
        <Tooltip
          content={intl.formatMessage({
            id: 'NO_PERMISSION_TO_PERFORM_ACTION',
          })}
          placement="right"
          disabled={canCreateTask}
          testId="menu-insert-task-dropdown-item-tooltip"
        >
          <Dropdown.Item
            onClick={createNewTask}
            testId="menu-insert-task"
            disabled={!canCreateTask}
          >
            <FormattedMessage id="TASK" />
          </Dropdown.Item>
        </Tooltip>
        <Divider />
        <Dropdown.Item
          onClick={() => {
            dispatch(setSidebarView('REFERENCES'));
            dispatch(setSidebarPanelTab({ view: 'citations', tab: 'library' }));
          }}
          disabled={disabled || isPageLayout}
          testId="reference-dropdown-item"
        >
          <FormattedMessage id="editor.menu.insert.reference" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={insertReferenceSection}
          disabled={disabled || isPageLayout}
          testId="reference-section-dropdown-item"
        >
          <FormattedMessage id="REFERENCE_SECTION" />
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item
          onClick={openCaptionsModal}
          disabled={disabled || isPageLayout}
          testId="menu-insert-caption"
        >
          <FormattedMessage id="editor.toolbar.contentStructure.CAPTION" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={showInsertLink}
          disabled={disabled || isPageLayout}
          testId="hyperlink-dropdown-item"
        >
          <FormattedMessage id="HYPERLINK" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={showCharmapModal}
          disabled={disabled || isPageLayout}
          testId="special-character-dropdown-item"
        >
          <FormattedMessage id="editor.menu.insert.specialCharacter" />
        </Dropdown.Item>
        <Divider />
        <Dropdown.SubMenu
          itemContent={<FormattedMessage id="EDITOR_BREAK" />}
          disabled={disabled || isPageLayout}
          testId="break-dropdown-submenu"
        >
          <Dropdown.Item
            onClick={insertPageBreak}
            disabled={!hasEditPermission}
            testId="page-break-dropdown-item"
          >
            <FormattedMessage id="editor.menu.insert.pageBreak" />
          </Dropdown.Item>
          {hasEditPermission && (
            <Dropdown.SubMenu
              itemContent={<FormattedMessage id="EDITOR_SECTION_BREAK" />}
              disabled={disabled}
              testId="section-break-dropdown-submenu"
            >
              <Dropdown.Item
                onClick={() => {
                  insertSectionBreak(ELEMENTS.SectionBreakElement.TYPES.NEXT_PAGE);
                }}
                testId="next-page-dropdown-item"
              >
                <FormattedMessage id="EDITOR_NEXT_PAGE" />
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  insertSectionBreak(ELEMENTS.SectionBreakElement.TYPES.CONTINUOUS);
                }}
                testId="continuous-dropdown-item"
              >
                <FormattedMessage id="EDITOR_CONTINUOUS" />
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  insertSectionBreak(ELEMENTS.SectionBreakElement.TYPES.ODD_PAGE);
                }}
                testId="odd-page-dropdown-item"
              >
                <FormattedMessage id="EDITOR_ODD_PAGE" />
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  insertSectionBreak(ELEMENTS.SectionBreakElement.TYPES.EVEN_PAGE);
                }}
                testId="even-page-dropdown-item"
              >
                <FormattedMessage id="EDITOR_EVEN_PAGE" />
              </Dropdown.Item>
            </Dropdown.SubMenu>
          )}
          <Dropdown.Item onClick={insertColumnBreak} testId="column-break-dropdown-item">
            <FormattedMessage id="EDITOR_COLUMN_BREAK" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Dropdown.SubMenu
          itemContent={<FormattedMessage id="editor.menu.insert.indexAndTables" />}
          disabled={disabled || isPageLayout}
          testId="index-and-tables-dropdown-submenu"
        >
          <Dropdown.Item onClick={insertTableOfContents} testId="table-of-contents-item">
            <FormattedMessage id="editor.menu.insert.tableOfContents" />
          </Dropdown.Item>
          <Dropdown.Item onClick={insertListOfFigures} testId="list-of-figures-item">
            <FormattedMessage id="editor.menu.insert.listOfFigures" />
          </Dropdown.Item>
          <Dropdown.Item onClick={insertListOfTables} testId="list-of-tables-item">
            <FormattedMessage id="editor.menu.insert.listOfTables" />
          </Dropdown.Item>
          <Dropdown.Item onClick={insertAuthors} testId="authors-item">
            <FormattedMessage id="EDITOR_DOCUMENT_AUTHORS" />
          </Dropdown.Item>
          <Dropdown.Item onClick={insertKeywords} testId="keywords-item">
            <FormattedMessage id="EDITOR_DOCUMENT_KEYWORDS" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
      </Dropdown>
    </>
  );
};

export default Insert;
