import { useDownloadQuery } from '_common/services/api/FileApi';
import { PDFDocumentProxy } from 'pdfjs-dist';
import pdfjs from 'PDF/services/PDFJS';
import { useEffect, useRef, useState } from 'react';
import ObjectPreviewControls from './ObjectPreviewControls';

type PDFPreviewProps = {
  object: doDOC.File;
};

const PDFPreview = ({ object }: PDFPreviewProps) => {
  const { data } = useDownloadQuery({ object_id: object.id });
  const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState({ label: '100%', value: '1' });

  useEffect(() => {
    if (data) {
      data.arrayBuffer().then((arraybuffer) => {
        // @ts-expect-error pdfjs accepts arraybuffer, but it's not explicit in its types
        const loadingTask = pdfjs.getDocument({ data: arraybuffer });
        loadingTask.promise.then((pdf) => {
          setPdf(pdf);
        });
      });
    }
  }, [data]);

  if (pdf) {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Page pdf={pdf} pageNumber={pageNumber} zoom={zoom.value} />
        <ObjectPreviewControls
          zoom={zoom}
          setZoom={setZoom}
          currentPage={pageNumber}
          totalPageNumber={pdf.numPages}
          nextPageIsDisabled={pageNumber === pdf.numPages}
          previousPageIsDisabled={pageNumber === 1}
          onNextPage={() => {
            setPageNumber(Math.min(pdf.numPages, pageNumber + 1));
          }}
          onPreviousPage={() => {
            setPageNumber(Math.max(1, pageNumber - 1));
          }}
          object={object}
        />
      </div>
    );
  }
  return null;
};

type PageProps = {
  pdf: PDFDocumentProxy;
  pageNumber: number;
  zoom: string;
};

const Page = ({ pdf, pageNumber, zoom }: PageProps) => {
  const canvasRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    pdf.getPage(pageNumber).then((page) => {
      const viewport = page.getViewport({ scale: +zoom * 1.3 });

      // Prepare canvas using PDF page dimensions
      const canvasContainer = canvasRef.current;

      if (!canvasContainer) {
        return;
      }

      if (canvasContainer.firstChild) {
        canvasContainer.removeChild(canvasContainer.firstChild);
      }
      const canvas = document.createElement('canvas');
      if (canvas) {
        canvasContainer.appendChild(canvas);
        const context = canvas.getContext('2d');
        if (context) {
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Render PDF page into canvas context
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          page.render(renderContext);
        }
      }
    });
  }, [zoom, pdf, pageNumber]);
  return <div ref={canvasRef} />;
};

export default PDFPreview;
