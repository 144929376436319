import { Divider, Toggle, Tooltip } from 'dodoc-design-system';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { Footer, Help, ZoomControls } from '_common/suite/components';
import { useDispatch, useSelector } from '_common/hooks';
import { setShowNotes, setZoom } from './PresentationSlice';

const PresentationFooter = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const showNotes = useSelector((state) => state.presentation.showNotes);
  const zoom = useSelector((state) => state.presentation.zoom);

  const changeZoom = (newZoom: number) => {
    dispatch(setZoom(newZoom));
  };

  return (
    <Footer>
      <Footer.Left>
        <Tooltip
          content={intl.formatMessage({ id: 'NOTES' })}
          placement="bottom"
          testId="thumbnails-tooltip"
        >
          <Toggle
            variant="ghost"
            isToggled={showNotes}
            onClick={() => dispatch(setShowNotes(!showNotes))}
            size="medium"
            icon={showNotes ? 'SidepanelBlue' : 'SidepanelGrey'}
            testId="thumbnails-toggle"
          />
        </Tooltip>
      </Footer.Left>
      <Footer.Right>
        <ZoomControls zoom={zoom} changeZoom={changeZoom} />
        <Divider vertical margin="0 1rem" />
        <Help resetOnboarding={() => console.log('TBI')} />
      </Footer.Right>
    </Footer>
  );
};

export default memo(PresentationFooter);
