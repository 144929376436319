import { createSelector } from '@reduxjs/toolkit';

import { notify } from '_common/components/ToastSystem';
import { ExtensionsService } from '_common/services';

import { setAppLoading } from 'App/redux/appSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import api from '_common/services/api/api';
import { paths } from '_types/api';

export type ReferenceStyleApiState = {
  order: Template['id'][];
  extensions: Record<Uuid, Template>;
};

type APIError = {
  error: { data: any; status: number };
};

const referenceStylesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReferenceStylesList: builder.query<
      Pick<ReferenceStyleApiState, 'order' | 'extensions'>,
      void
    >({
      query: () => ({
        url: `/object/extension/citationsstyle/list`,
      }),
      transformResponse: (
        responseData: paths['/api/object/extension/template/list']['get']['responses']['200']['content']['application/json'],
      ) => {
        const payload = responseData.reduce<Pick<ReferenceStyleApiState, 'order' | 'extensions'>>(
          (payload, extension) => {
            payload.order.push(extension.id);
            payload.extensions[extension.id] = extension;
            return payload;
          },
          { order: [], extensions: {} },
        );

        return payload;
      },
      providesTags: () => {
        return ['ReferenceStyle'];
      },
    }),
    installReferenceStyle: builder.mutation<
      Template,
      Pick<Template, 'id' | 'name'> & { updating?: boolean }
    >({
      query: ({ id }) => ({
        url: `/object/extension/citationsstyle/${id}/install`,
        method: 'POST',
        body: { id },
        errorsExpected: [400],
      }),
      invalidatesTags: ['InstalledReferenceStyle'],
      //Instead of invalidating and fetching all templates, only the affected template is updated with endpoint response
      onQueryStarted: async ({ id, name, updating }, { dispatch, queryFulfilled }) => {
        //Same as RTK Thunk 'pending' state
        dispatch(
          setAppLoading({
            isOpen: true,
            message: updating ? 'UPDATING_REFERENCE_STYLE' : 'INSTALLING_REFERENCE_STYLE',
          }),
        );

        try {
          const resp = await queryFulfilled;

          //Same as RTK Thunk 'fulfilled' state
          dispatch(
            referenceStylesApi.util.updateQueryData(
              'getReferenceStylesList',
              undefined,
              (draft) => {
                draft.extensions[id] = resp.data;
              },
            ),
          );

          dispatch(setAppLoading({ isOpen: false }));
          notify({
            type: 'success',
            title: updating ? 'REFERENCE_STYLES_UPDATED' : 'REFERENCE_STYLE_INSTALLED',
            message: updating
              ? 'REFERENCE_STYLES_UPDATED_MESSAGE'
              : 'REFERENCE_STYLE_INSTALLED_MESSAGE',
            messageValues: { refStyleName: name },
          });
        } catch (error) {
          // Same as RTK Thunk 'rejected' state
          const typedError = (error as APIError).error;
          if (typedError.status === 400) {
            dispatch(setAppLoading({ isOpen: false }));
            dispatch(
              openAndUpdateModal({
                modal: 'ConfirmationModal',
                data: {
                  title: updating
                    ? 'ERROR_WHILE_UPDATING_REFERENCE_STYLES'
                    : 'ERROR_WHILE_INSTALLING_REFERENCE_STYLES',
                  message: updating
                    ? 'ERROR_WHILE_UPDATING_REFERENCE_STYLES_MESSAGE'
                    : 'ERROR_WHILE_INSTALLING_REFERENCE_STYLES_MESSAGE',
                  messageValues: { refStyleName: name },
                  confirmButtonTextId: 'TRY_AGAIN',
                  confirmButtonType: 'primary',
                  cancelButtonShow: true,
                  cancelButtonTextId: 'global.cancel',
                  headerType: 'error',
                  actionCode: 'installReferenceStyle',
                  actionValue: {
                    id,
                    name,
                    updating,
                  },
                  width: '60rem',
                },
              }),
            );
          }
        }
      },
    }),
    uninstallReferenceStyle: builder.mutation<Template, Pick<Template, 'id' | 'name'>>({
      query: ({ id }) => ({
        url: `/object/extension/citationsstyle/${id}/uninstall`,
        method: 'POST',
        body: { id },
        errorsExpected: [400],
      }),
      invalidatesTags: ['InstalledReferenceStyle'],
      //Instead of invalidating and fetching all templates, only the affected template is updated with endpoint response
      onQueryStarted: async ({ id, name }, { dispatch, queryFulfilled }) => {
        //Same as RTK Thunk 'pending' state
        dispatch(setAppLoading({ isOpen: true, message: 'UNINSTALLING_REFERENCE_STYLE' }));

        try {
          const resp = await queryFulfilled;

          //Same as RTK Thunk 'fulfilled' state
          dispatch(
            referenceStylesApi.util.updateQueryData(
              'getReferenceStylesList',
              undefined,
              (draft) => {
                draft.extensions[id] = resp.data;
              },
            ),
          );

          dispatch(setAppLoading({ isOpen: false }));
          notify({
            type: 'success',
            title: 'REFERENCE_STYLE_UNINSTALLED',
            message: 'REFERENCE_STYLE_UNINSTALLED_MESSAGE',
            messageValues: { refStyleName: name },
          });
        } catch (error) {
          // Same as RTK Thunk 'rejected' state
          const typedError = (error as APIError).error;
          if (typedError.status === 400) {
            dispatch(setAppLoading({ isOpen: false }));
            notify({
              type: 'error',
              title: 'CANNOT_UNINSTALL_REFERENCE_STYLE',
              message: 'CANNOT_UNINSTALL_REFERENCE_STYLE_MESSAGE',
            });
          }
        }
      },
    }),
    installAllReferenceStyles: builder.mutation<
      Template[],
      { updating?: boolean; idList: Template['id'][]; name?: Template['name'] }
    >({
      queryFn: async ({ idList, updating, name }, { dispatch, getState }) => {
        let returnData: ApiSchemas['TemplateSchema'][] = [];

        dispatch(
          setAppLoading({
            isOpen: true,
            message: updating ? 'UPDATING_REFERENCE_STYLE' : 'INSTALLING_REFERENCE_STYLE',
          }),
        );

        await Promise.all<void>(
          idList.map((id) => {
            return new Promise((resolve) => {
              new ExtensionsService()
                .install('citationsstyle', id)
                .then(({ data }) => {
                  //Instead of invalidating and fetching all templates, only the affected template is updated with endpoint response
                  dispatch(
                    referenceStylesApi.util.updateQueryData(
                      'getReferenceStylesList',
                      undefined,
                      (draft) => {
                        draft.extensions[id] = data;
                      },
                    ),
                  );
                  resolve();
                })
                .catch(() => {
                  dispatch(setAppLoading({ isOpen: false }));
                  dispatch(
                    openAndUpdateModal({
                      modal: 'ConfirmationModal',
                      data: {
                        title: updating
                          ? 'ERROR_WHILE_UPDATING_REFERENCE_STYLES'
                          : 'ERROR_WHILE_INSTALLING_REFERENCE_STYLES',
                        message: updating
                          ? 'ERROR_WHILE_UPDATING_REFERENCE_STYLES_MESSAGE'
                          : 'ERROR_WHILE_INSTALLING_REFERENCE_STYLES_MESSAGE',
                        messageValues: { refStyleName: name },
                        confirmButtonTextId: 'TRY_AGAIN',
                        confirmButtonType: 'primary',
                        cancelButtonShow: true,
                        cancelButtonTextId: 'global.cancel',
                        headerType: 'error',
                        actionCode: 'installReferenceStyle',
                        actionValue: { id, name, updating },
                        width: '60rem',
                      },
                    }),
                  );
                  resolve();
                });
            });
          }),
        ).then(() => {
          dispatch(setAppLoading({ isOpen: false }));
          notify({
            type: 'success',
            title: updating ? 'MULTIPLE_REFERENCE_STYLES_UPDATED' : 'REFERENCE_STYLE_INSTALLED',
            message: updating
              ? 'MULTIPLE_REFERENCE_STYLES_UPDATED_MESSAGE'
              : 'MULTIPLE_REFERENCE_STYLE_INSTALLED',
            messageValues: { noRefStyles: idList.length.toString() },
          });
        });
        return { data: returnData };
      },
      invalidatesTags: ['InstalledTemplate'],
    }),
  }),
});

// Export queries and mutations
export const {
  useGetReferenceStylesListQuery,
  useInstallReferenceStyleMutation,
  useUninstallReferenceStyleMutation,
  useInstallAllReferenceStylesMutation,
} = referenceStylesApi;

const selectReferenceStyleList = referenceStylesApi.endpoints.getReferenceStylesList.select();
export const selectReferenceStyleById = createSelector(
  [selectReferenceStyleList, (_: any, id: ObjectId) => id],
  ({ data: list }, id) => {
    return list?.extensions[id];
  },
);

export default referenceStylesApi;
