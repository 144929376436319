import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Checkbox,
  Input,
  InputField,
  Modal,
  Popover,
  usePopper,
  Button,
} from 'dodoc-design-system';
import cx from 'classnames';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';

import {
  EditElementStatusProps,
  selectElementStatusById,
  useCreateElementStatusMutation,
  useEditElementStatusMutation,
} from '_common/services/api/elementStatusApi';
import { closeModal, updateModal } from '_common/modals/ModalsSlice';

import styles from './ElementStatusFormModal.module.scss';

const COLORS = {
  grey: { background: '#F5F5F6', border: '#C0C0C9' },
  yellow: { background: '#FED608', border: '#F1CB08' },
  green: { background: '#49BC5C', border: '#32AB46' },
  blue: { background: '#4AA9F5', border: '#1D94F3' },
  red: { background: '#F15546', border: '#E33F2F' },
  purple: { background: '#7B4DFD', border: '#4200FC' },
  orange: { background: '#E49F63', border: '#D97620' },
  pink: { background: '#FB8BB1', border: '#F95990' },
};

const MESSAGES: { [key: string]: { header: string; button: string } } = {
  create: {
    header: 'ADD_ELEMENT_STATUS',
    button: 'ADD_STATUS',
  },
  edit: {
    header: 'EDIT_ELEMENT_STATUS',
    button: 'UPDATE_STATUS',
  },
};

const ElementStatusFormModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [createElementStatus] = useCreateElementStatusMutation();
  const [editElementStatus] = useEditElementStatusMutation();
  const isOpenModal = useSelector((state) => state.modals.open.ElementStatusFormModal);
  const mode = useSelector((state) => state.modals.ElementStatusFormModal.mode);
  const id = useSelector((state) => state.modals.ElementStatusFormModal.id);
  const status = useSelector((state) => selectElementStatusById(state, id));

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [color, setColor] = useState<keyof typeof COLORS>('green');
  const [visible, setVisible] = useState(true);
  const [editable, setEditable] = useState(true);
  const [movable, setMovable] = useState(true);
  const [deletable, setDeletable] = useState(true);
  const [confirmInput, setConfirmInput] = useState(false);
  const [permissionsDisabled, setPermissionsDisabled] = useState(false);

  const inputRef = useRef<HTMLInputElement>();
  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-end',
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (status) {
      setName(status.name);
      setColor(status.color);
      setVisible(status.visible);
      setEditable(status.allow_edit);
      setDeletable(status.allow_delete);
      setConfirmInput(status.confirm_input);
      setMovable(status.allow_move);
      setPermissionsDisabled(status.id === 'active' || status.id === 'approved');
    } else {
      setPermissionsDisabled(false);
    }
  }, [status]);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 16) {
      setNameError(intl.formatMessage({ id: 'ERROR_CHARACTER_LIMIT' }, { number: 16 }));
    } else {
      setNameError('');
    }
    setName(e.target.value);
  };
  const submit = async () => {
    const payload: EditElementStatusProps['status'] = {
      name,
      color,
      visible,
      allow_move: movable,
      allow_delete: deletable,
      allow_edit: editable,
      confirm_input: confirmInput,
    };

    if (permissionsDisabled) {
      delete payload.allow_edit;
    }

    if (mode === 'create') {
      await createElementStatus(payload);
      notify({
        type: 'success',
        title: 'ELEMENT_STATUS_CREATED_TITLE',
        message: 'ELEMENT_STATUS_CREATED_DESCRIPTION',
        messageValues: { name },
      });
      close();
    } else if (mode === 'edit') {
      await editElementStatus({ id, status: payload });
      notify({
        type: 'success',
        title: 'ELEMENT_STATUS_EDITED_TITLE',
        message: 'ELEMENT_STATUS_EDITED_DESCRIPTION',
        messageValues: { name },
      });
      close();
    }
  };

  const isSubmitDisabled = () => {
    if (name === '' || nameError !== '') {
      return true;
    }
    if (mode === 'edit') {
      return (
        name === status?.name &&
        color === status?.color &&
        visible === status?.visible &&
        editable === status.allow_edit &&
        movable === status.allow_move &&
        deletable === status.allow_delete &&
        confirmInput === status.confirm_input
      );
    }
    return false;
  };
  const close = () => {
    setName('');
    setColor('green');
    setVisible(true);
    setEditable(true);
    setMovable(true);
    setDeletable(true);
    setConfirmInput(false);
    dispatch(updateModal({ modal: 'ElementStatusFormModal', data: { mode: 'create', id: null } }));
    dispatch(closeModal('ElementStatusFormModal'));
  };
  return (
    <Modal open={!!isOpenModal} width="63.6rem" onClose={close} testId={`${mode}-element-status`}>
      <Modal.Header onClose={close}>
        <FormattedMessage id={MESSAGES[mode].header} />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.area}>
          <div className={styles.header}>
            <FormattedMessage id="STATUS_GENERAL_INFORMATION" />
          </div>
          <div className={styles.fields}>
            <InputField
              width="38.5rem"
              size="large"
              label={intl.formatMessage({ id: 'STATUS_NAME' })}
              feedback={nameError}
              testId={`${mode}-element-status-name`}
            >
              <Input
                size="large"
                value={name}
                onChange={onNameChange}
                placeholder={intl.formatMessage({ id: 'ENTER_STATUS_NAME' })}
                error={!!nameError}
                testId={`${mode}-element-status-name`}
              />
            </InputField>
            <InputField
              width="14rem"
              size="large"
              label={intl.formatMessage({ id: 'STATUS_COLOR' })}
              margin="0 0 0 3rem"
              testId={`${mode}-element-status-color-field`}
            >
              <>
                <Button
                  size="large"
                  fullWidth
                  {...referenceProps}
                  testId={`${mode}-element-status-color-button`}
                >
                  <div
                    className={styles.color}
                    style={{
                      background: COLORS[color].background,
                      border: `1px solid ${COLORS[color].border}`,
                    }}
                  />
                </Button>
                <Popover {...popperProps} testId="status-color-popper">
                  <div className={styles.squares}>
                    {Object.typedKeys(COLORS).map((c) => (
                      <div
                        className={cx(styles.square, c === color && styles.selected)}
                        style={{
                          background: COLORS[c].background,
                          border: `1px solid ${COLORS[c].border}`,
                        }}
                        key={c}
                        onClick={() => {
                          setColor(c);
                          popperProps.close();
                        }}
                        data-testid={`${c}-square`}
                      />
                    ))}
                  </div>
                </Popover>
              </>
            </InputField>
          </div>
          <Checkbox
            size="small"
            checked={visible ? 'checked' : 'unchecked'}
            onChange={() => setVisible((state) => !state)}
            margin="2rem 0 0 0"
            testId={`${mode}-element-status-checkbox-visible-status`}
          >
            <FormattedMessage id="STATUS_VISIBLE_IN_EXPLORER" />
          </Checkbox>
        </div>
        <div className={styles.area}>
          <div className={styles.header}>
            <FormattedMessage id="global.permissions" />
          </div>
          <Checkbox
            disabled={permissionsDisabled}
            size="small"
            checked={editable ? 'checked' : 'unchecked'}
            onChange={() => setEditable((state) => !state)}
            margin="2rem 0 0 0"
            testId={`${mode}-element-status-checkbox-element-can-be-edited`}
          >
            <FormattedMessage id="ELEMENTS_STATUS_CAN_BE_EDITED" />
          </Checkbox>
          <Checkbox
            size="small"
            checked={movable ? 'checked' : 'unchecked'}
            onChange={() => setMovable((state) => !state)}
            margin="1rem 0 0 0"
            testId={`${mode}-element-status-checkbox-element-can-be-moved`}
          >
            <FormattedMessage id="ELEMENTS_STATUS_CAN_BE_MOVED" />
          </Checkbox>
          <Checkbox
            size="small"
            checked={deletable ? 'checked' : 'unchecked'}
            onChange={() => setDeletable((state) => !state)}
            margin="1rem 0 0 0"
            testId={`${mode}-element-status-checkbox-element-can-be-deleted`}
          >
            <FormattedMessage id="ELEMENTS_STATUS_CAN_BE_DELETED" />
          </Checkbox>
        </div>
        <div className={styles.area}>
          <div className={styles.header}>
            <FormattedMessage id="CONFIRMATION_REQUEST" />
          </div>
          <Checkbox
            size="small"
            checked={confirmInput ? 'checked' : 'unchecked'}
            onChange={() => setConfirmInput((state) => !state)}
            margin="2rem 0 0 0"
            testId={`${mode}-element-status-checkbox-ask-confirmation`}
          >
            <FormattedMessage id="ASK_CONFIRMATION_WHEN_CHANGING_STATUS" />
          </Checkbox>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId={`${mode}-element-status-cancel-button`}>
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          disabled={isSubmitDisabled()}
          onClick={submit}
          testId={`${mode}-element-status-submit-button`}
        >
          <FormattedMessage id={MESSAGES[mode].button} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ElementStatusFormModal;
