import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { omit } from 'lodash';
import {
  Button,
  Input,
  TextArea,
  Select,
  DatePicker,
  EmptyState,
  InputField,
} from 'dodoc-design-system';
import type { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';

import { useDebounce, useSelector } from '_common/hooks';
import { CitationData } from 'Editor/services/DataManager';
import EditorManager from 'Editor/services/EditorManager';

import { selectDocumentCitations } from 'Editor/redux/CitationsSlice';

import PrioritySelect from '_common/components/PrioritySelect/PrioritySelect';

import styles from './CitationInformation.module.scss';
import { notify } from '_common/components/ToastSystem';

//#region Constants
export const ENTRIES: Record<Citation.Entry, Citation.Field[]> = {
  abstract: [
    'title',
    'author',
    'publication_date',
    'url',
    'source_acronym',
    'source_title',
    'volume',
    'pages',
    'isbn',
    'doi',
    'abstract',
    'file_id',
  ],
  article: [
    'author',
    'editor',
    'title',
    'publication_place',
    'publisher',
    'month',
    'year',
    'pages',
    'conference_name',
    'city',
    'state',
    'conference_place',
    'conference_date',
    'number',
    'doi',
    'url',
    'file_id',
    'accessed_date',
    'updated_date',
  ],
  book: [
    'title',
    'source_title',
    'reference',
    'author',
    'corporate_author',
    'editor',
    'publication_date',
    'edition',
    'publication_place',
    'publisher',
    'pages',
    'isbn',
    'url',
    'year',
    'volume',
    'series',
    'doi',
    'file_id',
    'internal_link',
  ],
  data_file: [
    'title',
    'accessed_date',
    'url',
    'report_number',
    'version',
    'pages',
    'notes',
    'file_id',
  ],
  ejournal: [
    'title',
    'reference',
    'author',
    'publication_date',
    'source_title',
    'source_acronym',
    'isbn',
    'url',
    'pubmed_id',
    'publication_status',
    'doi',
    'abstract',
    'file_id',
  ],
  inbook: [
    'author',
    'editor',
    'title',
    'source_title',
    'edition',
    'publication_date',
    'year',
    'city',
    'state',
    'publication_place',
    'publisher',
    'volume',
    'pages',
    'series',
    'url',
    'doi',
    'file_id',
  ],
  journal: [
    'author',
    'title',
    'source_title',
    'source_acronym',
    'conference_name',
    'conference_place',
    'publication_date',
    'volume',
    'issue',
    'pages',
    'abstract',
    'doi',
    'pubmed_id',
    'year',
    'url',
    'accessed_date',
    'updated_date',
    'file_id',
  ],
  misc: [
    'author',
    'title',
    'medium_type',
    'city',
    'state',
    'publisher',
    'month',
    'year',
    'publication_place',
    'publication_date',
    'conference_name',
    'number',
    'organization',
    'url',
    'file_id',
  ],
  patent: ['author', 'year', 'title', 'number', 'filed_date', 'issued_date', 'file_id'],
  proceedings: [
    'author',
    'title',
    'conference_name',
    'source_acronym',
    //@ts-expect-error CitationSchema issue: missing 'conference_date_range'
    'conference_date_range',
    'conference_place',
    'number',
    'presentation_type',
    'publisher',
    'publication_date',
    'pages',
    'abstract',
    'file_id',
    'internal_link',
    'url',
  ],
  report: [
    'author',
    'title',
    'publication_place',
    'publisher',
    'month',
    'year',
    'publication_date',
    'total_pages',
    'number',
    'department',
    'department_place',
    'url',
    'institution',
    'pages',
    'file_id',
  ],
  thesis: [
    'author',
    'title',
    'degree_level',
    'institution',
    'institution_place',
    'completion_date',
    'year',
    'pages',
    'file_id',
  ],
  webpage: [
    'author',
    'title',
    'organization',
    'city',
    'state',
    'publisher',
    'publication_place',
    'publication_date',
    'accessed_date',
    'updated_date',
    'file_id',
    'url',
  ],
  package_insert: [
    'title',
    //@ts-expect-error CitationSchema issue: missing 'short_title'
    'short_title',
    'publication_date',
    'organization',
    'city',
    'state',
    'file_id',
  ],
  press_release: [
    'title',
    'organization',
    'publication_date',
    'city',
    'country',
    'accessed_date',
    'file_id',
    'url',
  ],
};

const INPUTS: Record<Citation.Field, Citation.Input> = {
  abstract: 'textarea',
  accessed_date: 'date',
  author: 'multiple',
  chapter: 'block',
  city: 'block',
  completion_date: 'date',
  conference_date: 'date',
  conference_name: 'block',
  conference_place: 'block',
  corporate_author: 'multiple',
  country: 'block',
  degree_level: 'block',
  department: 'block',
  department_place: 'block',
  doi: 'block',
  drug_name: 'block',
  edition: 'block',
  editor: 'multiple',
  file_id: 'block',
  filed_date: 'date',
  institution: 'block',
  institution_place: 'block',
  isbn: 'block',
  issued_date: 'date',
  issue: 'block',
  key: 'block',
  medium_type: 'block',
  month: 'dropdown',
  notes: 'textarea',
  number: 'number',
  organization: 'block',
  pages: 'block',
  presentation_type: 'dropdown',
  publication_date: 'date',
  publication_place: 'block',
  publication_status: 'block',
  publisher: 'block',
  pubmed_id: 'block',
  reference: 'block',
  report_number: 'number',
  review_date: 'date',
  section: 'block',
  series: 'block',
  source_acronym: 'block',
  source_title: 'block',
  state: 'block',
  title: 'block',
  total_pages: 'block',
  updated_date: 'date',
  url: 'block',
  volume: 'block',
  website_name: 'block',
  year: 'block',
  version: 'number',
  //@ts-expect-error CitationSchema issue: missing 'city_state', 'conference_date_range', 'language', 'short_title', 'internal_link'
  city_state: 'block',
  conference_date_range: 'daterange',
  language: 'block',
  short_title: 'block',
  internal_link: 'block',
};

const MONTHS = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
const PRESENTATION_TYPES = [
  'editor.toolbar.contentStructure.abstract',
  'POSTER',
  'ORAL_PRESENTATION',
];

const CUSTOM_TRANSLATIONS: { [key in Citation.Entry]: { [key in Citation.Field]?: string } } = {
  abstract: {
    source_title: 'JOURNAL_TITLE',
    source_acronym: 'JOURNAL_TITLE_ABBREVIATION',
    volume: 'VOLUME_AND_SUPPLEMENT',
    isbn: 'ISSN/ISBN',
    abstract: 'editor.toolbar.contentStructure.abstract',
  },
  book: {
    title: 'CHAPTER_TITLE',
    source_title: 'BOOK_TITLE',
    isbn: 'ISSN/ISBN',
    url: 'WEB_URL',
    internal_link: 'INTERNAL_LINK',
  },
  data_file: {
    title: 'INTERNAL_DOCUMENT_TITLE',
    url: 'INTERNAL_LINK',
  },
  ejournal: {
    source_title: 'JOURNAL_TITLE',
    source_acronym: 'JOURNAL_TITLE_ABBREVIATION',
    isbn: 'ISSN/ISBN',
    url: 'URL_TO_FULL_TEXT',
    pubmed_id: 'PUBMED_UNIQUE_IDENTIFIER',
    abstract: 'editor.toolbar.contentStructure.abstract',
  },
  journal: {
    source_acronym: 'JOURNAL_TITLE_ABBREVIATION',
    abstract: 'editor.toolbar.contentStructure.abstract',
    pubmed_id: 'PUBMED_UNIQUE_IDENTIFIER',
    url: 'RELATED_LINK',
  },
  package_insert: {
    title: 'PRODUCT_TRADE_NAME',
    //@ts-expect-error CitationSchema issue: missing 'short_title'
    short_title: 'PRODUCT_GENERIC_NAME',
    publication_date: 'DATE',
    organization: 'MANUFACTURED_IN_THE_US_BY',
  },
  press_release: {
    organization: 'SOURCE_OF_PRESS_RELEASE',
    publication_date: 'DATE_OF_PRESS_RELEASE',
    country: 'COUNTRY',
    url: 'RELATED_LINK',
  },
  proceedings: {
    conference_name: 'CONFERENCE_TITLE',
    source_acronym: 'CONFERENCE_ACRONYM',
    abstract: 'editor.toolbar.contentStructure.abstract',
    number: 'PRESENTATION_NUMBER',
    url: 'RELATED_LINK',
    presentation_type: 'PRESENTATION_TYPE',
    //@ts-expect-error CitationSchema issue: missing 'conference_date_range'
    conference_date_range: 'editor.modals.citations.fields.conference_date',
    internal_link: 'INTERNAL_LINK',
  },
  webpage: {
    title: 'WEB_PAGE_TITLE',
    url: 'RELATED_LINK',
    publisher: 'editor.modals.citations.fields.source_title',
  },
};
//#endregion

type IndexedEntityFields = {
  [field in 'author' | 'editor' | 'corporate_author']: Record<
    string,
    NonNullable<Citation[field]>[number]
  >;
};
type CitationInformationProps = {
  citationId: Citation['id'];
  disabled?: boolean;
  multiple?: boolean;
};

const CitationInformation = ({ citationId, disabled, multiple }: CitationInformationProps) => {
  const intl = useIntl();

  const selectedTab = useSelector((state) => state.tabMenu.citationsManager);
  const citationDict = useSelector(selectDocumentCitations).dict;
  const searchedCitationDict = useSelector(
    (state) => state.modals.CitationsModal.search.dict,
  ) as Record<ObjectId, Citation>;
  const importedCitationDict = useSelector(
    (state) => state.modals.CitationsModal.import.dict,
  ) as Record<ObjectId, Citation>;

  const [processedCitation, setProcessedCitation] = useState<Citation['id'] | null>(null);
  const [validations, setValidations] = useState<{ [key in Citation.Field]?: string }>();
  const [indexedEntityFields, setIndexedEntityFields] = useState<IndexedEntityFields | null>(null);
  const [savingFields, setSavingFields] = useState<{ [key in Citation.Field]?: Citation[key] }>();
  const debouncedSavingFields = useDebounce(savingFields);

  useEffect(() => {
    //Only set value once
    if (indexedEntityFields && citationId === processedCitation) {
      return;
    }

    setProcessedCitation(citationId);

    let selectedCitation: Citation | null = null;

    switch (selectedTab) {
      case 'searchReferences':
        selectedCitation = citationId ? searchedCitationDict[citationId] : null;
        break;
      case 'importReferences':
        selectedCitation = citationId ? importedCitationDict[citationId] : null;
        break;
      default:
        selectedCitation = citationId ? citationDict[citationId] : null;
        break;
    }

    if (!selectedCitation) {
      return;
    }

    const {
      author: authors,
      editor: editors,
      corporate_author: corporateAuthors,
    } = selectedCitation ?? {};

    const authorList: IndexedEntityFields['author'] = {};
    authors?.forEach((author) => (authorList[uuid()] = author));

    const editorList: IndexedEntityFields['editor'] = {};
    editors?.forEach((editor) => (editorList[uuid()] = editor));

    const corporateAuthorList: IndexedEntityFields['corporate_author'] = {};
    corporateAuthors?.forEach((corporateAuthor) => (corporateAuthorList[uuid()] = corporateAuthor));

    setIndexedEntityFields({
      author: authorList,
      editor: editorList,
      corporate_author: corporateAuthorList,
    });
  }, [selectedTab, citationId, searchedCitationDict, importedCitationDict, citationDict]);

  const getConditionedCitation = () => {
    switch (selectedTab) {
      case 'searchReferences':
        return searchedCitationDict[citationId ?? ''];
      case 'importReferences':
        return importedCitationDict[citationId ?? ''];
      default:
        return citationDict[citationId ?? ''];
    }
  };
  const localCitation = { ...getConditionedCitation(), ...savingFields };

  useEffect(() => {
    if (savingFields) {
      const updatedCitation = { ...omit(localCitation, ['time', 'inserted']) } as Citation;
      updateCitation(updatedCitation.id, updatedCitation);
      setSavingFields(undefined);
    }
  }, [debouncedSavingFields]);

  const { months, entries, presentationTypes } = useMemo(() => {
    const entries = (Object.keys(ENTRIES) as Citation.Entry[]).map((entry) => ({
      value: entry,
      label: intl.formatMessage({ id: `editor.modals.citations.entries.${entry}` }),
    }));
    const months: SelectOption[] = MONTHS.map((month) => ({
      label: intl.formatMessage({ id: `global.month.${month}` }),
      value: month,
    }));
    const presentationTypes: SelectOption[] = PRESENTATION_TYPES.map((presentationType) => ({
      label: intl.formatMessage({ id: presentationType }),
      value: presentationType,
    }));

    return {
      months,
      entries,
      presentationTypes,
    };
  }, []);

  const getFieldTranslation = (type: Citation.Entry, field: Citation.Field) => {
    return CUSTOM_TRANSLATIONS[type]?.[field] || `editor.modals.citations.fields.${field}`;
  };

  const updateField = <F extends Citation.Field>(field: F, value: Citation[F]) => {
    setSavingFields((prevState) => ({ ...prevState, [field]: value }));
  };

  //TODO: Set appropriate select value type
  const onPriorityChange = (newPriority: { value: Citation['priority'] }) => {
    updateField('priority', newPriority.value);
  };

  //TODO: Set appropriate select value type
  const onEntryChange = (newEntry: { value: Citation['entry'] }) => {
    updateField('entry', newEntry.value);
  };

  const onFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: Citation.Field,
  ) => {
    updateField(field, e.target.value);
  };

  const getEntryFromValue = (value?: string) => {
    return entries.find((entry) => entry.value === value);
  };

  const updateCitation = (citationId: ObjectId, data: Citation) => {
    const manager = EditorManager.getInstance();
    const citData = {
      ...data,
      id: citationId,
    } as CitationData;

    if (manager) {
      manager.updateCitationInLibrary(citData).catch((error) => {
        const newValidations: typeof validations = {};

        const errorMessage = error.message?.toUpperCase();
        let errorField: Citation.Field | undefined = undefined;

        if (errorMessage.includes('YEAR')) {
          errorField = 'year';
        } else if (errorMessage.includes('DOI')) {
          errorField = 'doi';
        }

        if (errorField) {
          const newField = errorField.toUpperCase();
          newValidations[errorField] = intl.formatMessage({ id: `INVALID_${newField}` });
        }

        if (Object.keys(newValidations).length > 0) {
          setValidations(newValidations);
        } else {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'editor.errors.citations.invalidValueForField',
          });
        }
      });
    }
  };

  const listFields = () => {
    if (!localCitation.entry) {
      return null;
    }

    const typedEntry =
      localCitation.entry in ENTRIES ? (localCitation.entry as Citation.Entry) : undefined;
    const entryFields = typedEntry ? ENTRIES[typedEntry] : undefined;

    if (entryFields && typedEntry) {
      return (
        <div className={styles.fields}>
          <div className={styles.field}>
            <InputField
              label={intl.formatMessage({ id: 'REFERENCE_PRIORITY' })}
              disabled={disabled}
              size="large"
              feedback={false}
              testId="reference-priority-field"
            >
              <PrioritySelect
                size="large"
                width="100%"
                value={localCitation.priority}
                onChange={onPriorityChange}
                disabled={disabled}
                testId="reference-priority"
              />
            </InputField>
          </div>

          {entryFields.map((field) => {
            //@ts-expect-error CitationSchema issue: missing 'source'
            if (field === 'file_id' && localCitation.source !== 'quosa') {
              return null;
            } else {
              return renderField(typedEntry, field);
            }
          })}
        </div>
      );
    }

    return null;
  };

  const onFirstNameChange = (
    field: 'author' | 'editor' | 'corporate_author',
    fieldId: string,
    newValue: string,
  ) => {
    setIndexedEntityFields((prevState) => {
      if (!prevState) {
        return null;
      }
      const newState = {
        ...prevState,
        [field]: {
          ...prevState[field],
          [fieldId]: {
            first_name: newValue,
            last_name: prevState[field][fieldId]?.last_name ?? '',
          },
        },
      };

      updateField(field, Object.values(newState[field]));

      return newState;
    });
  };
  const onLastNameChange = (
    field: 'author' | 'editor' | 'corporate_author',
    fieldId: string,
    newValue: string,
  ) => {
    setIndexedEntityFields((prevState) => {
      if (!prevState) {
        return null;
      }

      const newState = {
        ...prevState,
        [field]: {
          ...prevState[field],
          [fieldId]: {
            first_name: prevState[field][fieldId]?.first_name ?? '',
            last_name: newValue,
          },
        },
      };

      updateField(field, Object.values(newState[field]));

      return newState;
    });
  };
  const onValueRemoval = (field: 'author' | 'editor' | 'corporate_author', fieldId: string) => {
    setIndexedEntityFields((prevState) => {
      if (!prevState) {
        return null;
      }

      let newState: IndexedEntityFields;
      if (Object.values(prevState[field]).length > 0) {
        const indexedEntities = { ...prevState };
        if (indexedEntities?.[field]) {
          const indexedField = indexedEntities[field];
          if (indexedField?.[fieldId]) {
            delete indexedField[fieldId];
          }
        }
        newState = indexedEntities;
      } else {
        newState = {
          ...prevState,
          [field]: {
            ...prevState[field],
            [fieldId]: {
              first_name: '',
              last_name: '',
            },
          },
        };
      }

      updateField(field, Object.values(newState[field]));
      return newState;
    });
  };
  const onValueAddition = (field: 'author' | 'editor' | 'corporate_author') => {
    setIndexedEntityFields((prevState) => {
      if (!prevState) {
        return null;
      }
      const newState = {
        ...prevState,
        [field]: {
          ...prevState[field],
          [uuid()]: {
            first_name: '',
            last_name: '',
          },
        },
      };

      updateField(field, Object.values(newState[field]));
      return newState;
    });
  };

  const renderMultipleInputs = (field: Citation.Field) => {
    if (INPUTS[field] !== 'multiple') {
      return;
    }

    const typedField = field as 'author' | 'editor' | 'corporate_author';

    const displayFields: IndexedEntityFields = {
      author: {},
      editor: {},
      corporate_author: {},
      ...indexedEntityFields,
    };
    if (!Object.keys(displayFields[typedField]).length) {
      displayFields[typedField][uuid()] = { first_name: '', last_name: '' };
    }

    return Object.keys(displayFields[typedField]).map((fieldId, index) => {
      const fieldValue = displayFields[typedField][fieldId];

      return (
        <div key={fieldId} className={styles.author} data-testid={fieldId}>
          <div className={styles.nameInputs}>
            <Input
              disabled={disabled}
              value={fieldValue.first_name}
              onChange={(e) => onFirstNameChange(typedField, fieldId, e.target.value)}
              placeholder={intl.formatMessage({ id: 'FIRST_AND_MIDDLE_NAMES' })}
              width="100%"
              size="large"
              testId={`${index}-${field.replace('_', '-')}-first-and-middle-names`}
            />
            <Input
              disabled={disabled}
              value={fieldValue.last_name}
              onChange={(e) => onLastNameChange(typedField, fieldId, e.target.value)}
              placeholder={intl.formatMessage({ id: 'LAST_NAME' })}
              width="100%"
              size="large"
              testId={`${index}-${field.replace('_', '-')}-last-name`}
            />
          </div>
          <Button
            disabled={disabled}
            icon="EditorRemove"
            margin="0 0 0 1rem"
            onClick={() => onValueRemoval(typedField, fieldId)}
            size="large"
            testId={`${index}-remove-${field}`}
          />
          <Button
            disabled={disabled}
            icon="EditorAdd"
            margin="0 0 0 0.5rem"
            onClick={() => onValueAddition(typedField)}
            size="large"
            testId={`${index}-add-${field}`}
          />
        </div>
      );
    });
  };

  const renderInput = (type: Citation.Entry, field: Citation.Field) => {
    switch (INPUTS[field]) {
      case 'block':
      case 'number':
        return (
          <div className={styles.input}>
            <Input
              name={field}
              disabled={disabled}
              value={localCitation[field]?.toString()}
              onChange={(e) => onFieldChange(e, field)}
              error={!!validations?.[field]}
              placeholder={intl.formatMessage({ id: getFieldTranslation(type, field) })}
              width="100%"
              size="large"
              testId={`${field}`}
            />
          </div>
        );
      case 'textarea':
        return (
          <div className={styles.input}>
            <TextArea
              name={field}
              disabled={disabled}
              size="large"
              value={localCitation[field]?.toString()}
              onChange={(e) => onFieldChange(e, field)}
              placeholder={intl.formatMessage({ id: getFieldTranslation(type, field) })}
              testId={`${field}-textarea`}
            />
          </div>
        );
      case 'multiple':
        return renderMultipleInputs(field);
      case 'dropdown':
        if (field === 'presentation_type') {
          return (
            <Select
              disabled={disabled}
              width="100%"
              value={
                presentationTypes.find(
                  (presentationType) => presentationType.label === localCitation.presentation_type,
                ) ||
                ({
                  label: localCitation.presentation_type,
                  value: localCitation.presentation_type,
                } as SelectOption)
              }
              options={presentationTypes}
              onChange={(presentationType) => {
                updateField('presentation_type', presentationType.label as string);
              }}
              size="large"
              testId={`${field}`}
            />
          );
        }
        return (
          <Select
            disabled={disabled}
            width="100%"
            value={months.find((month) => month.value === localCitation.month) || undefined}
            options={months}
            onChange={(month) => {
              updateField('month', month.value);
            }}
            size="large"
            testId={`${field}`}
          />
        );
      case 'date':
      case 'daterange': {
        //@ts-expect-error CitationSchema issue: missing 'conference_date_range'
        const startField = field === 'conference_date_range' ? 'conference_date' : field;
        const startDate = localCitation[startField]
          ? dayjs(localCitation[startField]).utc()
          : undefined;
        const endDate = localCitation.conference_end_date
          ? dayjs(localCitation.conference_end_date).utc()
          : undefined;
        return (
          <div className={styles.input}>
            {INPUTS[field] === 'daterange' ? (
              //Date Range Selection
              <DatePicker
                size="large"
                dateRange
                direction="horizontal"
                disabled={disabled}
                startDate={startDate && new Date(startDate.format('MM/DD/YYYY'))}
                onStartChange={(date: Date) => {
                  const value = date ? dayjs(date).format('MM/DD/YYYY') : '';
                  updateField(startField, value);
                }}
                placeholderStart=""
                endDate={endDate && new Date(endDate.format('MM//DD/YYYY'))}
                onEndChange={(date: Date) => {
                  const value = date ? dayjs(date).format('MM/DD/YYYY') : '';
                  updateField('conference_end_date', value);
                }}
                placeholderEnd=""
                testId={`${field}-datepicker`}
              />
            ) : (
              //Single Date Selection
              <DatePicker
                size="large"
                disabled={disabled}
                selectedDate={startDate && new Date(startDate.format('MM/DD/YYYY'))}
                onChange={(date: Date) => {
                  const value = date ? dayjs(date).format('MM/DD/YYYY') : '';
                  updateField(startField, value);
                }}
                placeholder=""
                testId={`${field}-datepicker`}
              />
            )}
          </div>
        );
      }
      default:
        break;
    }
    return null;
  };

  const renderField = (type: Citation.Entry, field: Citation.Field) => {
    return (
      <div key={field} className={styles.field}>
        <InputField
          label={intl.formatMessage({ id: getFieldTranslation(type, field) })}
          disabled={disabled}
          feedback={false}
          size="large"
          testId={`${field}-field`}
        >
          {renderInput(type, field)}
        </InputField>
      </div>
    );
  };

  //citationId can be 0, which would trigger !citationId
  if (citationId === undefined) {
    return (
      <div className={styles.root}>
        <div className={styles.empty}>
          <EmptyState
            size="medium"
            title={intl.formatMessage({
              id: 'editor.modals.citations.noReferenceSelected',
            })}
            testId="no-reference-selected"
          >
            <FormattedMessage id="NO_SELECTED_REFERENCE_MESSAGE" />
          </EmptyState>
        </div>
      </div>
    );
  }
  if (multiple) {
    return (
      <div className={styles.root}>
        <div className={styles.empty}>
          <EmptyState
            size="medium"
            title={intl.formatMessage({
              id: 'editor.modals.citations.multipleReferencesSelected',
            })}
            testId="multiple-references-selected"
          >
            <FormattedMessage id="MULTIPLE_REFERENCES_SELECTED_MESSAGE" />
          </EmptyState>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <div className={styles.entry}>
        <Select
          width="100%"
          disabled={disabled}
          onChange={onEntryChange}
          value={getEntryFromValue(localCitation.entry)}
          options={entries}
          size="large"
          clearable={false}
          testId="entry"
        />
      </div>
      {listFields()}
    </div>
  );
};

export default CitationInformation;
