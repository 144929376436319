import cx from 'classnames';

import { RichTextEditor } from '_common/components';

import styles from './MinifiedCard.module.scss';

type MinifiedCardProps = {
  task: Card.Task;
  order: number;
  onClick: () => void;
  testId: string;
};

const MinifiedCard = ({ task, order, testId, onClick }: MinifiedCardProps) => {
  return (
    <div
      onClick={onClick}
      className={cx({
        [styles.root]: true,
        [styles.done]: task.status === 'd',
        [styles.deleted]: task.state === 'Cancelled',
      })}
    >
      <div className={styles.order}>{`#${order}`}</div>
      <div className={styles.description}>
        <RichTextEditor
          initialValue={task.content?.content ? JSON.stringify(task.content.content) : ''}
          readOnly
          expanded={false}
          maxLines={1}
          testId={`${testId}-rich-text-editor`}
        />
      </div>
    </div>
  );
};

export default MinifiedCard;
