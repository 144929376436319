import { useSelector } from '_common/hooks';
import { Panel } from '_common/suite/components';
import DetailsPanel from './DetailsPanel';

const SidebarPanel = () => {
  const rightPanel = useSelector((state) => state.presentation.rightPanel);

  const renderPanel = () => {
    switch (rightPanel) {
      case 'details':
        return <DetailsPanel />;
      case 'annotations':
      case 'tasks':
      case 'versionhistory':
      default:
        return null;
    }
  };

  return (
    <Panel side="right" width="49rem">
      {renderPanel()}
    </Panel>
  );
};

export default SidebarPanel;
