/* eslint-disable class-methods-use-this */
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export class Theme extends RealtimeObject<Presentation.Data.Theme.Data> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'themes');
  }

  subscribe(): Promise<RealtimeObject<Presentation.Data.Theme.Data>> {
    return new Promise((resolve, reject) => {
      this.model.subscribe((error) => {
        if (error) {
          reject(error);
        } else {
          //! TEST
          if (!this.get([])) {
            this.create({
              _id: this.model.id,
              name: 'this theme!',
              colorScheme: {
                dk1: {
                  base: '000000',
                },
                lt1: {
                  base: 'FFFFFF',
                },
              },
              formatScheme: {
                fillStyleList: [],
                lineStyleList: [],
                effectsStyleList: [],
                bgFillStyleList: [],
              },
            });
          }
          resolve(this);
        }
      });
    });
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    const data = super.get() as Presentation.Data.Theme.Data;
    this.emit('UPDATED', data, ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }
}
