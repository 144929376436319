import dayjs from 'dayjs';
import { Icon, Toggle, Tooltip } from 'dodoc-design-system';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import UserAvatar from '_common/components/UserAvatar/UserAvatar';
import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';
import { useSelector, useDispatch } from '_common/hooks';
import { editNotification } from '../../NotificationsSlice';

import styles from './Header.module.scss';
import Subtitle, { SubtitleProps } from './Subtitle/Subtitle';

type HeaderProps = {
  notificationId: string;
  targetObject?: ObjectParams;
} & Pick<SubtitleProps, 'measure' | 'targetObject'>;

const Header = ({ notificationId, targetObject, measure }: HeaderProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notifications.dict[notificationId]);
  const [markHasRead, setMarkHasRead] = useState(notification.read);

  useEffect(() => {
    setMarkHasRead(notification.read);
  }, [notification.read]);

  const getTimeDiff = () => {
    const djToday = dayjs();
    const djNotification = dayjs(notification.time.access);

    //Weeks
    let diff: number | string = djToday.diff(djNotification, 'weeks');
    if (diff !== 0) {
      return `${diff}w`;
    }
    //Days
    diff = djToday.diff(djNotification, 'days');
    if (diff !== 0) {
      return `${diff}d`;
    }
    //Hours
    diff = djToday.diff(djNotification, 'hours');
    if (diff !== 0) {
      return `${diff}h`;
    }

    return `${djToday.diff(djNotification, 'minutes')}m`;
  };

  const renderTitle = () => {
    switch (notification.event) {
      //#region Element deadline
      case 'deadline_reminder': {
        return (
          <FormattedMessage
            id="YOU_HAVE_A_REMINDER_OF_A_ELEMENT_DEADLINE"
            values={{
              element: intl
                .formatMessage({
                  id: `global.${
                    (notification.action.extra as ApiSchemas['EditActionExtra'])?.type
                  }`,
                })
                .toLowerCase(),
            }}
          />
        );
      }
      case 'deadline_24_reminder': {
        return (
          <FormattedMessage
            id="THE_DUE_DATE_OF_A_ELEMENT_IS_NEAR"
            values={{
              element: intl
                .formatMessage({
                  id: `global.${
                    (notification.action.extra as ApiSchemas['EditActionExtra'])?.type
                  }`,
                })
                .toLowerCase(),
            }}
          />
        );
      }
      case 'deadline_expired': {
        return (
          <FormattedMessage
            id="THE_DUE_DATE_OF_A_ELEMENT_EXPIRED"
            values={{
              element: intl
                .formatMessage({
                  id: `global.${
                    (notification.action.extra as ApiSchemas['EditActionExtra'])?.type
                  }`,
                })
                .toLowerCase(),
            }}
          />
        );
      }
      //#endregion

      //#region Element status
      case 'status_changed':
        return (
          <FormattedMessage
            id="USER_UPDATED_STATUS_OF_ELEMENT"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
              element: intl
                .formatMessage({
                  id: `global.${
                    notification.action.collection === 'spaces'
                      ? 'space'
                      : (notification.action.extra as ApiSchemas['StatusChangeActionExtra'])
                          ?.type === 'dopdf'
                      ? 'document'
                      : (notification.action.extra as ApiSchemas['StatusChangeActionExtra'])?.type
                  }`,
                })
                .toLowerCase(),
            }}
          />
        );
      //#endregion

      //#region Element permissions
      case 'shared':
        return (
          <FormattedMessage
            id="USER_SHARED_AN_ELEMENT_WITH_YOU"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
              element: intl
                .formatMessage({
                  id: `global.${
                    notification.action.collection === 'spaces'
                      ? 'space'
                      : (notification.action.extra as ApiSchemas['AccessActionExtra'])?.type ===
                        'dopdf'
                      ? 'document'
                      : (notification.action.extra as ApiSchemas['AccessActionExtra'])?.type
                  }`,
                })
                .toLowerCase(),
            }}
          />
        );
      case 'permissions_updated': {
        switch (notification.action.action) {
          case 'role_added':
          case 'permission_added':
            return (
              <FormattedMessage
                id="USER_UPDATED_PERMISSIONS_ON_ELEMENT"
                values={{
                  user: <UsernameLabel userId={notification.action.user} />,
                  element: intl
                    .formatMessage({
                      id: `global.${
                        notification.action.collection === 'spaces'
                          ? 'space'
                          : (notification.action.extra as ApiSchemas['AccessActionExtra'])?.type ===
                            'dopdf'
                          ? 'document'
                          : (notification.action.extra as ApiSchemas['AccessActionExtra'])?.type
                      }`,
                    })
                    .toLowerCase(),
                }}
              />
            );
          case 'role_removed':
          case 'permission_removed':
            return (
              <FormattedMessage
                id="USER_REMOVED_PERMISSIONS_ON_ELEMENT"
                values={{
                  user: <UsernameLabel userId={notification.action.user} />,
                  element: intl
                    .formatMessage({
                      id: `global.${
                        notification.action.collection === 'spaces'
                          ? 'space'
                          : (notification.action.extra as ApiSchemas['AccessActionExtra'])?.type ===
                            'dopdf'
                          ? 'document'
                          : (notification.action.extra as ApiSchemas['AccessActionExtra'])?.type
                      }`,
                    })
                    .toLowerCase(),
                }}
              />
            );
        }
        break;
      }
      //#endregion

      //#region Element updates
      case 'added_to_container':
      case 'removed_from_container': {
        let elementType = '';
        let messageId = 'USER_ADDED_NEW_ELEMENT_INTO_SHARED_ELEMENT';

        switch (notification.action.action) {
          case 'created':
          case 'deleted': {
            const { parent } = notification.action.extra as ApiSchemas['CreateActionExtra'];
            elementType = intl.formatMessage({
              id: `global.${parent ? 'folder' : 'space'}`,
            });
            break;
          }
          case 'restored_object': {
            const { parent } = notification.action.extra as ApiSchemas['RestoredObjectActionExtra'];
            elementType = intl.formatMessage({
              id: `global.${parent ? 'folder' : 'space'}`,
            });
            break;
          }
          case 'copied':
          case 'moved': {
            const extra = notification.action.extra as ApiSchemas['CopyActionExtra'];
            const parent =
              notification.event === 'removed_from_container'
                ? extra.source.parent
                : extra.destination.parent;
            elementType = intl.formatMessage({
              id: `global.${parent ? 'folder' : 'space'}`,
            });
            break;
          }

          case 'imported': {
            const { parent } = notification.action.extra as ApiSchemas['ImportActionExtra'];

            elementType = intl.formatMessage({
              id: `global.${parent ? 'folder' : 'space'}`,
            });
            break;
          }
        }

        if (notification.event === 'removed_from_container') {
          messageId = 'USER_REMOVED_ELEMENTS_IN_SHARED_ELEMENT';
        }

        return (
          <FormattedMessage
            id={messageId}
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
              element: elementType.toLowerCase(),
            }}
          />
        );
      }

      //#endregion

      //#region User mention
      case 'user_mentioned': {
        let type = 'COMMENT';

        switch ((notification.action.extra as ApiSchemas['UserMentionActionExtra']).card_type) {
          case 'comment': {
            type = 'COMMENT';
            break;
          }
          case 'suggestion': {
            type = 'SUGGESTION';
            break;
          }
          case 'task': {
            type = 'TASK';
            break;
          }
        }

        return (
          <FormattedMessage
            id={`USER_MENTIONED_ON_${type}`}
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      }

      //#endregion

      //#region Document content
      case 'node_status_updated': {
        switch (notification.action.action) {
          case 'node_reopened':
            return (
              <FormattedMessage
                id="USER_REOPENED_CONTENT_FROM_DOCUMENT"
                values={{
                  user: <UsernameLabel userId={notification.action.user} />,
                }}
              />
            );
          case 'node_approved':
            return (
              <FormattedMessage
                id="USER_APPROVED_CONTENT_FROM_DOCUMENT"
                values={{
                  user: <UsernameLabel userId={notification.action.user} />,
                }}
              />
            );
        }
        break;
      }
      //#endregion

      //#region Document comments & annotations
      case 'annotation_created':
      case 'comment_created':
        return (
          <FormattedMessage
            id="USER_ADDED_A_NEW_COMMENT"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      case 'annotation_status_changed':
      case 'comment_status_updated': {
        switch (notification.action.action) {
          case 'annotation_resolved':
          case 'comment_resolved':
            return (
              <FormattedMessage
                id="USER_RESOLVED_A_COMMENT"
                values={{
                  user: <UsernameLabel userId={notification.action.user} />,
                }}
              />
            );
          case 'annotation_deleted':
          case 'comment_deleted':
            return (
              <FormattedMessage
                id="USER_DELETED_A_COMMENT"
                values={{
                  user: <UsernameLabel userId={notification.action.user} />,
                }}
              />
            );
        }
        break;
      }
      case 'annotation_replied':
      case 'comment_reply': {
        return (
          <FormattedMessage
            id="USER_REPLIED_TO_COMMENT"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      }
      case 'comment_liked': {
        return (
          <FormattedMessage
            id="USER_LIKED_A_COMMENT"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      }
      case 'annotation_content_edited': {
        return (
          <FormattedMessage
            id="USER_EDITED_THE_CONTENT_OF_A_COMMENT"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      }
      case 'comment_priority_changed':
      case 'annotation_priority_changed': {
        return (
          <FormattedMessage
            id="USER_UPDATED_THE_PRIORITY_OF_A_COMMENT"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      }

      //#endregion

      //#region Document suggestions
      case 'suggestion_status_updated': {
        switch (notification.action.action) {
          case 'suggestion_accepted':
            return (
              <FormattedMessage
                id="USER_ACCEPTED_A_SUGGESTION"
                values={{
                  user: <UsernameLabel userId={notification.action.user} />,
                }}
              />
            );
          case 'suggestion_rejected':
            return (
              <FormattedMessage
                id="USER_REJECTED_A_SUGGESTION"
                values={{
                  user: <UsernameLabel userId={notification.action.user} />,
                }}
              />
            );
        }
        break;
      }
      case 'suggestion_replied': {
        return (
          <FormattedMessage
            id="USER_REPLIED_TO_A_SUGGESTION"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      }
      case 'suggestion_liked': {
        return (
          <FormattedMessage
            id="USER_LIKED_A_SUGGESTION"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      }
      //#endregion

      //#region Document tasks
      case 'task_deadline_reminder': {
        return <FormattedMessage id="YOU_HAVE_A_REMINDER_OF_A_TASK_DEADLINE" />;
      }
      case 'task_deadline': {
        return <FormattedMessage id="THE_DUE_DATE_OF_A_TASK_EXPIRED" />;
      }
      case 'task_assignee_updated': {
        const extra = notification.action.extra as ApiSchemas['TaskAssigneeActionExtra'];

        const assignee =
          notification.action.action === 'task_assignee_updated'
            ? extra.new_assignee
            : extra.assignee;

        return (
          <FormattedMessage
            id="USER_UPDATED_THE_TASK_ASSIGNEE_TO"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
              assignee: <UsernameLabel userId={assignee} />,
            }}
          />
        );
      }
      case 'task_add_watcher': {
        return (
          <FormattedMessage
            id="USER_ADDED_YOU_AS_A_WATCHER_ON_A_TASK"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      }
      case 'task_remove_watcher': {
        return (
          <FormattedMessage
            id="USER_REMOVED_YOU_AS_A_WATCHER_ON_A_TASK"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      }
      case 'task_status_changed': {
        return (
          <FormattedMessage
            id="USER_UPDATED_STATUS_OF_ELEMENT"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
              element: intl
                .formatMessage({
                  id: `TASK`,
                })
                .toLowerCase(),
            }}
          />
        );
      }
      case 'task_reply_liked': {
        return (
          <FormattedMessage
            id="USER_LIKED_A_TASK_REPLY"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      }
      case 'task_replied': {
        return (
          <FormattedMessage
            id="USER_REPLIED_TO_A_TASK"
            values={{
              user: <UsernameLabel userId={notification.action.user} />,
            }}
          />
        );
      }
      //#endregion

      default:
        break;
    }
  };

  const renderAvatar = () => {
    const { event } = notification;
    switch (event) {
      case 'deadline_reminder':
      case 'deadline_24_reminder':
      case 'deadline_expired':
      case 'task_deadline_reminder':
      case 'task_deadline': {
        return <Icon size={40} icon="Warning" />;
      }
      default: {
        if (notification.action.user) {
          return <UserAvatar userId={notification.action.user} size="large" />;
        }

        return null;
      }
    }
  };

  return (
    <div className={styles.root}>
      <div>{renderAvatar()}</div>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>{renderTitle()}</div>
        <Subtitle notificationId={notificationId} targetObject={targetObject} measure={measure} />
      </div>
      <div className={styles.suffixWrapper}>
        <div className={styles.timeAgo}>{getTimeDiff()}</div>

        <Tooltip
          placement="bottom"
          content={
            markHasRead
              ? intl.formatMessage({ id: 'MARK_AS_UNREAD' })
              : intl.formatMessage({ id: 'MARK_AS_READ' })
          }
          testId={`notification-${notificationId}-mark-as-tooltip`}
        >
          <Toggle
            variant="link"
            size="small"
            margin="-0.5rem 0 0"
            icon={markHasRead ? 'Unread' : 'Read'}
            onClick={(e) => {
              e.stopPropagation();

              setMarkHasRead(!markHasRead);
              dispatch(
                editNotification({ id: notificationId, parameters: { read: !markHasRead } }),
              );
            }}
            testId={`notification-${notificationId}-mark-as-toggle`}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Header;
