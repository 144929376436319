import { useMemo } from 'react';
import { Accordion, DatePicker, RadioButton } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { Logger } from '_common/services';

import { setFilter } from '../../FilterSlice';
import {
  DateRange,
  FilterIdentity,
  FilterName,
  OptionByType,
  TRANSLATIONS,
} from '../../FilterController';
import styles from './RadioButtonHandler.module.scss';

const RadioButtonHandler = ({
  identity,
  filter,
  filterQuantity,
  options,
  testId,
}: {
  identity: FilterIdentity;
  filter: FilterName;
  filterQuantity: number;
  options: OptionByType['radioButton'];
  testId: string;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentValue = useSelector((state) => {
    const value = state.filters[identity]?.[filter];
    return Array.isArray(value) ? (value as Exclude<typeof value, DateRange>) : null; //Cast to avoid typescript false calls due to DateRange
  });

  const translatedOptions = useMemo(
    () =>
      options?.map((option) => ({
        ...option,
        label: intl.formatMessage({ id: `${option.label}` }, { number: option.labelValue }),
      })),
    [options],
  );

  const activeValues = useMemo(() => {
    if (!currentValue || !translatedOptions || !Array.isArray(currentValue)) {
      return [];
    }

    return translatedOptions
      .filter((option) =>
        currentValue.map((filterValue) => filterValue.value).includes(option.value),
      )
      .map((option) => option.value);
  }, [currentValue]);

  if (!translatedOptions) {
    Logger.error(
      '[RadioButtonHandler] - No options provided, unable to render filter: ',
      filter,
    );
    return null;
  }

  const handleToggleValue = (value: string) => {
    if (activeValues.includes(value)) {
      dispatch(
        setFilter({
          identity,
          filter,
          value: currentValue
            ? currentValue.filter((filterValue: { value: string }) => filterValue.value !== value)
            : null,
        }),
      );
    } else {
      const newValue = {
        value,
        label: translatedOptions?.find((option) => option.value === value)?.label,
      };

      dispatch(
        setFilter({
          identity,
          filter,
          value: [newValue],
        }),
      );
    }
  };

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: TRANSLATIONS[filter] })}
      initialCollapsed={activeValues.length <= 0}
      collapsable={filterQuantity > 1}
      testId={`${testId}-accordion`}
    >
      {translatedOptions?.map((option, index) => (
        <RadioButton
          key={`${filter}-${option.value}`}
          checked={activeValues.includes(option.value)}
          onChange={() => handleToggleValue(option.value)}
          testId={`${testId}-accordion-${index}`}
          size="x-small"
        >
          {option.label}
        </RadioButton>
      ))}
      <div className={styles.labelContainer}>
        <div
          className={`${styles.label} ${!currentValue?.map((value) => value.value).includes('specific_date_range') &&
            styles.disabled
            }`}
          style={{ marginRight: '1.5rem' }}
        >
          <FormattedMessage id="START_DATE" />
        </div>
        <div
          className={`${styles.label} ${!currentValue?.map((value) => value.value).includes('specific_date_range') &&
            styles.disabled
            }`}
        >
          <FormattedMessage id="END_DATE" />
        </div>
      </div>
      <DatePicker
        direction="horizontal"
        onStartChange={(date) => {
          const newValue = {
            value: currentValue?.map((value) => value.value)[0],
            label: translatedOptions?.find(
              (option) => option.value === currentValue?.map((value) => value.value)[0],
            )?.label,
            dateValue: {
              startDate: date && date.toString(),
              endDate:
                //@ts-expect-error
                currentValue && currentValue[0]?.dateValue?.endDate
                  //@ts-expect-error
                  ? currentValue[0]?.dateValue?.endDate
                  : null,
            },
          };

          dispatch(
            setFilter({
              identity,
              filter,
              //@ts-expect-error
              value: [newValue],
            }),
          );
        }}
        onEndChange={(date) => {
          const newValue = {
            value: currentValue?.map((value) => value.value)[0],
            label: translatedOptions?.find(
              (option) => option.value === currentValue?.map((value) => value.value)[0],
            )?.label,
            dateValue: {
              //@ts-expect-error
              startDate: currentValue[0]?.dateValue?.startDate
                //@ts-expect-error
                ? currentValue[0]?.dateValue?.startDate
                : null,
              endDate: date && date.toString(),
            },
          };

          dispatch(
            setFilter({
              identity,
              filter,
              //@ts-expect-error
              value: [newValue],
            }),
          );
        }}
        dateRange
        placeholderStart={intl.formatMessage({ id: 'START_DATE' })}
        placeholderEnd={intl.formatMessage({ id: 'END_DATE' })}
        testId="date-picker"
        startDate={
          //@ts-expect-error
          currentValue && currentValue[0]?.dateValue?.startDate
            ? //@ts-expect-error
            new Date(currentValue[0]?.dateValue?.startDate)
            : undefined
        }
        endDate={
          //@ts-expect-error
          currentValue && currentValue[0]?.dateValue?.endDate
            ? //@ts-expect-error
            new Date(currentValue[0]?.dateValue?.endDate)
            : undefined
        }
        disabled={!currentValue?.map((value) => value.value).includes('specific_date_range')}
      />
    </Accordion>
  );
};

export default RadioButtonHandler;
