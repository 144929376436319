/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { DataObject } from '_common/services/Realtime';
import BaseController from '../BaseController';

type FieldsDataType = {
  date?: Date;
  title: string;
};

class FieldsDataModel extends DataObject<
  FieldsDataType,
  {
    READY: () => void;
    LOADED: (data: FieldsDataType | null) => void;
    CREATED: () => void;
    UPDATED: (data: FieldsDataType | null) => void;
    UPDATED_PROP: <T extends keyof FieldsDataType>(property: T, data: FieldsDataType[T]) => void;
  }
> {
  protected data: FieldsDataType;

  constructor(id: string) {
    super(id);
    this.data = {
      title: 'No title found',
    };
  }

  setProperty<T extends keyof FieldsDataType>(property: T, data: FieldsDataType[T]) {
    this.data[property] = data;
    this.emit('UPDATED_PROP', property, this.data[property]);
  }

  getProperty<T extends keyof FieldsDataType>(property: T): FieldsDataType[T] {
    return this.data[property];
  }
}

export class FieldsController extends BaseController {
  private data: FieldsDataModel;

  constructor(Data: Editor.Data.State) {
    super(Data);
    this.data = new FieldsDataModel('fields');
  }

  start(documentId: string): void {
    this.data.setProperty('date', new Date());
    this.data?.setProperty('title', 'No title found');
    this.Data.tableOfContents
      ?.getDocumentMainTitle(false)
      ?.then((result?: TableOfContents.TOCElementType) => {
        if (result && result.content) {
          this.data?.setProperty('title', result.content);
        }
      });
    this.Data.tableOfContents?.toc?.on('UPDATED', () => {
      this.refreshDocumentTitle();
    });
    this.refreshDocumentTitle();
  }

  private refreshDocumentTitle() {
    this.Data.tableOfContents
      ?.getDocumentMainTitle(false)
      ?.then((result?: TableOfContents.TOCElementType) => {
        if (result && result.content) {
          this.data?.setProperty('title', result.content);
        }
      });
  }

  get<T extends keyof FieldsDataType>(property: T): FieldsDataType[T] {
    return this.data.getProperty(property);
  }

  stop(): void {}

  destroy(): void {
    this.unregisterAllTransportEvents();
  }
}
