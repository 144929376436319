import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';

import { selectWordCountHasSelection } from 'Editor/redux/EditorStatusSlice';
import { openModal } from '_common/modals/ModalsSlice';

import styles from './WordCount.module.scss';

const WordCount = () => {
  const dispatch = useDispatch();

  const documentLoaded = useSelector((state) => state.editor.status.paragraphsLoaded);
  const wordCountHasSelection = useSelector(selectWordCountHasSelection);
  const { document: documentQuantity, selection: selectionQuantity } = useSelector(
    (state) => state.editor.wordCount.wordsQuantity,
  );

  const handleOpenWordCountModal = () => {
    dispatch(openModal('WordCountModal'));
  };

  if (!documentLoaded) {
    return null;
  }

  return (
    <div className={styles.root} onClick={handleOpenWordCountModal}>
      {wordCountHasSelection && selectionQuantity ? (
        <FormattedMessage
          id="SELECTED_OF_TOTAL_WORDS"
          values={{ selectedWords: selectionQuantity, totalWords: documentQuantity }}
        />
      ) : (
        <FormattedMessage id="QUANTITY_WORDS" values={{ wordCount: documentQuantity }} />
      )}
    </div>
  );
};

export default WordCount;
