import { Command } from '../Command';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { EditorService } from '_common/services';
import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';

export class EditEquationCommand extends Command {
  async exec(): Promise<Editor.Edition.ICommand> {
    if (!this.context.DataManager || !this.context.DataManager.selection) {
      return this;
    }

    this.context.DataManager?.selection?.restore();

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return this;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return this;
    }

    const result = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, ['equation']);
    const closest = result?.data;

    if (closest?.properties?.equation) {
      new EditorService().convertEquation(closest?.properties?.equation).then(({ data }) => {
        ReduxInterface.openAndUpdateModal({
          modal: 'EquationsModal',
          data: {
            isEdit: true,
            equation: {
              elementId: closest.id,
              tex: data,
              mathml: closest?.properties?.equation,
              blockId: baseModel.id,
            },
          },
        });
      });
    }

    return this;
  }
}
