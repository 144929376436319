import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';

import styles from './RolesTable.module.scss';
import { useGetRolesListQuery } from '../RolesApi';
import Cell from '_common/components/Table2/Cells';
import ActionsCell from './Cells/ActionsCell';
import { Table } from '_common/components/Table2';

const RolesTable = () => {
  const intl = useIntl();

  const { data, isLoading } = useGetRolesListQuery();

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'global.name' }),
        width: 208,
      },
      {
        id: 'creationDate',
        header: intl.formatMessage({ id: 'global.creationDate' }),
        width: 120,
      },
      {
        id: 'description',
        header: intl.formatMessage({ id: 'global.description' }),
        width: 208,
        flex: true,
      },
      {
        id: 'actions',
        width: 200,
        flex: true,
      },
    ];
  }, []);

  const value = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.list.map((roleId) => {
      const role = data.dict[roleId];
      return {
        id: roleId,
        name: (
          <Cell testId={`${roleId}-name-column`} ellipsis>
            {role.name}
          </Cell>
        ),
        creationDate: (
          <Cell testId={`${roleId}-creationDate-column`}>
            <Cell.DateTime date={{ date: role.time.creation }} />
          </Cell>
        ),
        description: <Cell testId={`${roleId}-description-column`}>{role.description}</Cell>,
        actions: (
          <Cell testId={`${roleId}-actions-column`}>
            <ActionsCell roleId={roleId} />
          </Cell>
        ),
      };
    });
  }, [data]);

  return (
    <div className={styles.root}>
      <Table
        identity="roles"
        columns={columns}
        value={value}
        renderFooter={() => null}
        isLoading={isLoading}
        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
        testId="refStyles"
        selectable={false}
        flex={false}
        withSuffix={false}
        virtualized={false}
      />
    </div>
  );
};

export default RolesTable;
