import { JsonRange, SelectionFixer } from 'Editor/services/_Common/Selection';
import { Command } from '../Command';
import { Logger } from '_common/services';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager/models';
import { ActionContext } from '../../ActionContext';
import { InsertElementOperation } from '../../Operations';

export class DefaultCommand extends Command {
  private event: KeyboardEvent;

  constructor(context: Editor.Edition.ICommandArgs, event: KeyboardEvent) {
    super(context);
    this.event = event;
  }

  protected handleCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return false;
    }

    ctx.setModelAndData(baseModel, baseData);

    // normalize text selection
    SelectionFixer.collapsedTextSelection(
      ctx.range,
      {
        suggestionMode: this.context.editionMode === 'SUGGESTIONS',
      },
      this.context.DataManager,
    );

    let textElementData;

    const result = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, ['p', 'tblc']);

    if (result) {
      if (NodeUtils.isParagraphData(result.data)) {
        textElementData = ctx.baseData;
      } else if (
        NodeUtils.isTableCellData(result.data) &&
        result.data.childNodes?.length === 0 &&
        result.path
      ) {
        // is table cell and does not have a paragraph
        const pathToInsert: Editor.Selection.Path = [...result.path, 'childNodes', 0];
        const paragraphData = NodeDataBuilder.buildParagraph({
          data: {
            type: 'p',
            parent_id: result.data.id,
          },
        });
        if (paragraphData) {
          new InsertElementOperation(baseModel, pathToInsert, paragraphData).apply();

          pathToInsert.push('childNodes', 0);

          ctx.range.updateRangePositions({
            b: ctx.range.start.b,
            p: pathToInsert,
          });
          textElementData = paragraphData;
        }
      }
    }

    if (textElementData && this.context.contentManipulator) {
      // insert text
      return this.context.contentManipulator.insertText(ctx, ctx.range.start.p, this.event.key);
    }

    return false;
  }

  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (this.context.contentManipulator) {
      return this.context.contentManipulator.removeContent(ctx);
    }

    return false;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (this.debug) {
      Logger.trace('DefaultCommand exec', this);
    }

    if (!this.context.DataManager || !this.context.DataManager.selection) {
      return this;
    }

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange);

    // handle non collapsed selection
    if (!ctx.range.collapsed) {
      if (!this.handleNonCollapsedSelection(ctx)) {
        return this;
      }
    }

    // handle collapsed selection
    if (ctx.range.collapsed) {
      if (!this.handleCollapsedSelection(ctx)) {
        return this;
      }
    }

    // handle create suggestions???
    this.handleSuggestionsUpdate(ctx);

    // apply new selection
    if (this.context.DataManager?.selection) {
      // TEMP: flag last selection
      this.context.DataManager.selection.history.flag('debounce');
      this.context.DataManager.selection.setUserSelection([ctx.range.serializeToRangeData()]);
    }

    // create patch
    this.context.DataManager?.history.createPatch();

    return this;
  }
}
