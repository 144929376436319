import { JsonRange } from 'Editor/services/_Common/Selection';
import { Logger } from '_common/services';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { ActionContext } from '../../ActionContext';
import { InsertInlineCommand } from '../GenericCommands';

export class TabCommand extends InsertInlineCommand {
  private event: KeyboardEvent;

  constructor(context: Editor.Edition.ICommandArgs, event: KeyboardEvent) {
    super(context);
    this.event = event;
  }

  protected handleCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return false;
    }

    //TODO:
    // fix block selection?

    let textElementData;

    if (NodeUtils.isParagraphData(baseData)) {
      textElementData = baseData;
    } else {
      // get closest text element
      // const textElement = EditorDOMUtils.closestBlockTextElement(ctx.range.commonAncestorContainer);
      // watch out approved elements and non editable
      logger.warn('handleCollapsedSelection not implemented!');
    }

    // TODO:

    if (textElementData && this.context.contentManipulator) {
      // TODO fix selection

      // insert tab
      const tabData = NodeDataBuilder.build({
        type: 'tab',
      });
      if (tabData && this.context.commandFactory) {
        super.exec(tabData);

        return true;
      }
    }

    return false;
  }
  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    //TODO:
    logger.warn('handleNonCollapsedSelection not implemented!');

    // if (!this.context.DataManager) {
    //   return false;
    // }

    // const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    // const baseData = baseModel?.selectedData();
    // if (!baseModel || !baseData) {
    //   return false;
    // }

    // // check if element is editable
    // if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
    //   return false;
    // }

    return false;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (this.debug) {
      Logger.trace('TabCommand');
    }

    //TODO temporary implementation, needs to be finished

    if (!this.context.DataManager || !this.context.DataManager.selection) {
      return this;
    }

    //TODO:
    // fix block selection?

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange);

    // handle non collapsed selection
    if (!ctx.range.collapsed) {
      if (!this.handleNonCollapsedSelection(ctx)) {
        return this;
      }
    }

    // handle collapsed selection
    if (ctx.range.collapsed) {
      this.handleCollapsedSelection(ctx);
    }

    return this;
  }
}
