import { ReduxInterface } from 'Editor/services';
import { DataManager } from 'Editor/services/DataManager';
import EditorManager from 'Editor/services/EditorManager';
import { Logger } from '_common/services';

export function dataManagerInitializer(this: EditorManager) {
  // #############################################
  //                 Main Code
  // #############################################
  if (this.editorContext.transport) {
    this.editorContext.DataManager = DataManager(this.editorContext.transport);
    this.editorContext.DataManager.on('READY', () => {
      this.handleDocumentReady();
    });

    this.editorContext.DataManager.on(
      'DOCUMENT_UPDATED',
      (documentData: Realtime.Core.Document.Data) => {
        const docTrackingState = this.DataManager?.document.getDocumentTracking();
        if (documentData.tracking.state) {
          const onboardingState = ReduxInterface.getOnboardingState();
          const docIsOnboarding = onboardingState.started.editor || onboardingState.active.editor;

          ReduxInterface.setTracking({
            ...docTrackingState,
            userId: this.editorContext.user?.id,
            documentId: this.editorContext.documentId,
            //Avoid tracking being on when handling Standalone Onboarding document
            state:
              docIsOnboarding && !ReduxInterface.isEnvision() ? false : documentData.tracking.state,
          });
        }
        ReduxInterface.updateDocumentData(documentData);
      },
    );

    this.editorContext.DataManager.on(
      'TEMPLATE_UPDATED',
      (documentId: string, templateId: string) => {
        ReduxInterface.updateTemplate(documentId, templateId);
      },
    );

    this.editorContext.DataManager.on('LOAD_USERS_ONLINE', (users) => {
      ReduxInterface.usersOnline(users);
    });
    this.editorContext.DataManager.on('USER_JOINED', (user) => {
      ReduxInterface.userJoined(user);
    });
    this.editorContext.DataManager.on('USER_LEFT', (user) => {
      ReduxInterface.userLeft(user);
    });
    this.editorContext.DataManager.on('LOAD_CITATIONS_LIBRARY', (data) => {
      ReduxInterface.listcitations(data.document, Object.values(data.citations));
    });
    this.editorContext.DataManager.on('LOAD_DOCUMENT_CITATIONS', (data) => {
      ReduxInterface.citationsLoaded(data);
    });
    this.editorContext.DataManager.on('LOAD_DOCUMENT_REFERENCE_STYLES', (data) => {
      ReduxInterface.referenceStylesLoaded({ styleList: data });
    });
    this.editorContext.DataManager.on('LOAD_DOCUMENT_REFERENCE_STYLE', (referenceStyleId) => {
      ReduxInterface.setReferenceStyleId({ referenceStyleId });
    });
    this.editorContext.DataManager.on('LOAD_DOCUMENT_STYLES', (styles) => {
      ReduxInterface.documentStylesLoad(styles);
    });
    this.editorContext.DataManager.on('LOAD_LIST_STYLES', (styles) => {
      ReduxInterface.loadListStyles(styles);
    });
    this.editorContext.DataManager.on('SET_DOCUMENT_TEMPLATE_FINISHED', () => {
      ReduxInterface.stopEditorLoading();
    });
    this.editorContext.DataManager.on('LOAD_COMMENTS_DATA', (index, data) => {
      ReduxInterface.commentsLoad(index, data);
    });
    this.editorContext.DataManager.on('UPDATE_NOTE', (data) => {
      ReduxInterface.updateNote(data);
    });
    this.editorContext.DataManager.on('LOAD_NOTES_DATA', (data) => {
      ReduxInterface.loadNotes(data);
    });
    this.editorContext.DataManager.on('UPDATE_COMMENT', (data) => {
      //@ts-expect-error
      ReduxInterface.updateComment(data);
    });
    this.editorContext.DataManager.on('OPEN_TEMPORARY_COMMENT', (data) => {
      ReduxInterface.openTemporaryCommentCard(data);
    });
    this.editorContext.DataManager.on('CANCEL_TEMPORARY_COMMENT', () => {
      ReduxInterface.cancelTemporaryComment();
    });
    this.editorContext.DataManager.on('SUGGESTION_LOAD', (index, data) => {
      ReduxInterface.suggestionsLoad(index, data);
    });
    this.editorContext.DataManager.on('UPDATE_SUGGESTION', (data) => {
      ReduxInterface.updateTrackedActions([data]);
    });
    this.editorContext.DataManager.on('CREATE_SUGGESTION', (data) => {
      const actionsCompleted = ReduxInterface.getCompletedActions();

      switch (data?.type) {
        case 'REPLACE':
        case 'INSERT':
          ReduxInterface.completeAction('editor_suggestions_addText');
          break;
        case 'DELETE':
          ReduxInterface.completeAction(
            actionsCompleted['editor_suggestions_addText']
              ? 'editor_suggestions_deleteText'
              : 'editor_suggestions_addText',
          );
          break;
      }
    });
    this.editorContext.DataManager.on('LOAD_TABLE_OF_CONTENTS', ({ list, data }) => {
      ReduxInterface.loadTableOfContents(list, data);
    });
    this.editorContext.DataManager.on('LOAD_TASKS_DATA', (list, data) => {
      ReduxInterface.loadTasks(list, data);
    });
    this.editorContext.DataManager.on('LOAD_DELETED_TASKS_DATA', (list, data) => {
      ReduxInterface.loadDeletedTasks(list, data);
    });
    this.editorContext.DataManager.on('UPDATE_TASK', (data) => {
      ReduxInterface.updateTask(data);
    });
    this.editorContext.DataManager.on('UPDATE_WORD_COUNT', (data) => {
      ReduxInterface.setWordsQuantity({ target: 'document', value: data });
    });

    // TODO: change this to document view model
    this.editorContext.DataManager.on(
      'ADDED_BLOCK',
      (operationContext: Realtime.Core.OperationContext) => {
        // this._addNode(operationContext);
      },
    );

    // TODO: change this to document view model
    this.editorContext.DataManager.on(
      'REMOVED_BLOCK',
      (operationContext: Realtime.Core.OperationContext) => {
        // this._removeNode(operationContext);
      },
    );

    this.editorContext.DataManager.on('LOAD_LANGUAGES', (data) => {
      ReduxInterface.loadLanguages(data);
    });
    this.editorContext.DataManager.on(
      'LOAD_APPROVAL_SUMMARY',
      (summary: Approval.ApprovalSummary) => {
        ReduxInterface.loadNodesForApproval(summary);
      },
    );
    this.editorContext.DataManager.on(
      'LOAD_PERMISSIONS_SUMMARY',
      (summary: Permissions.PermissionSummary) => {
        ReduxInterface.loadedPermissions(summary);
      },
    );

    this.editorContext.DataManager.on('UPDATE_TRACK_LOCK_STATUS', () => {
      const currentTrackiongState = ReduxInterface.getEditorTrackingState();

      const loggedUserId = this.editorContext.DataManager?.users.loggedUserId as string;
      const documentId = this.editorContext.DataManager?.document.getDocumentId() as string;

      if (!currentTrackiongState.thirdParty) {
        const tracking = this.editorContext.DataManager?.document?.getDocumentTracking();
        if (tracking.lock) {
          ReduxInterface.setTracking({
            ...tracking,
            userId: loggedUserId,
            documentId: documentId,
          });
        } else {
          ReduxInterface.setTracking({
            ...currentTrackiongState,
            state: currentTrackiongState.state,
            lock_time: currentTrackiongState.lock_time,
            lock_user: currentTrackiongState.lock_user,
            lock: false,
            userId: loggedUserId,
            documentId: documentId,
          });
        }
        const loggedUserPermissions = ReduxInterface.getLoggedUserDocumentPermissions();

        const hasEditPermission =
          loggedUserPermissions &&
          (loggedUserPermissions.includes('admin') ||
            loggedUserPermissions.includes('owner') ||
            loggedUserPermissions.includes('edit'));

        if (tracking.lock_user && loggedUserId !== tracking.lock_user && hasEditPermission) {
          const isTrackingLockInformationRead = window.localStorage.getItem(
            `${loggedUserId}-${documentId}-lock-${tracking.lock}-read`,
          );

          if (!isTrackingLockInformationRead || isTrackingLockInformationRead === 'false') {
            ReduxInterface.openModal('SuggestionModeLockInfoModal');
          }
        }
      }
    });

    this.editorContext.DataManager.on(
      'UPDATE_OBJECT_STATUS',
      (
        documentId: Realtime.Core.Document.Data['_id'],
        status: Realtime.Core.Document.Data['status'],
        statusInfo: Realtime.Core.Document.Data['statusInfo'],
        reRender: boolean = true,
      ) => {
        if (status && statusInfo) {
          switch (status) {
            case 'processing':
              ReduxInterface.updateDocumentStatus(documentId, status, statusInfo);
              ReduxInterface.startEditorLoading('EDITOR_PROCESSING_STATE');
              break;
            case 'broken':
              ReduxInterface.stopEditorLoading();
              ReduxInterface.updateDocumentStatus(documentId, status, statusInfo);
              ReduxInterface.openAndUpdateModal({
                modal: 'ConfirmationModal',
                data: {
                  headerType: 'error',
                  title: 'EDITOR_BROKEN_STATE_TITLE',
                  message: 'EDITOR_BROKEN_STATE_MESSAGE',
                  confirmButtonTextId: 'editor.menu.file.backToExplorer',
                  confirmButtonType: '',
                  actionCode: 'backToExplorer',
                  cancelButtonShow: false,
                  persistent: true,
                },
              });
              break;
            default: {
              ReduxInterface.updateDocumentStatus(documentId, status, statusInfo);
              if (reRender) {
                this.reRender();
              }

              break;
            }
          }
        }
      },
    );
    this.editorContext.DataManager.on('UPDATE_READONLY_STATE', (payload: boolean) => {
      ReduxInterface.setReadonlyState(payload);
      EditorManager.getInstance().reRender();
    });
    this.editorContext.DataManager.on('FORCE_REMOTE_RELOAD', () => {
      EditorManager.getInstance().reloadEditorManager();
    });
    this.editorContext.DataManager.on('INITIAL_LOADING_ERROR', (error) => {
      if (error.data.code === 403) {
        const title = 'Insuficient Permissions';
        const message =
          'You are not authorized to access this document. If this is not correct please contact the administrator.';
        ReduxInterface.setEditorError(title, message, '', 403);
        ReduxInterface.setSidebarView(null);
        this.terminateServices();
      }
    });
    this.editorContext.DataManager.on('UNDO_STATUS_CHANGED', (status) => {
      ReduxInterface.setCanUndo(status === 'NOT_EMPTY');
    });
    this.editorContext.DataManager.on('REDO_STATUS_CHANGED', (status) => {
      ReduxInterface.setCanRedo(status === 'NOT_EMPTY');
    });
  } else {
    Logger.error(new Error('Transport not initialized!!!'));
  }
}
