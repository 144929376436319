import ReactDOM from 'react-dom';

import FindProvider from './FindContext';
import FindInstances, { FindInstancesProps } from './FindInstances';
import Search, { SearchProps } from './Search';

type FindProps = SearchProps &
  FindInstancesProps & { instancesBoundary: HTMLElement | undefined | null };

const Find = ({ resetFind, find, instancesBoundary, ...instancesProps }: FindProps) => {
  return (
    <FindProvider>
      <Search resetFind={resetFind} find={find} />
      {ReactDOM.createPortal(
        <FindInstances {...instancesProps} />,
        instancesBoundary ?? document.body,
      )}
    </FindProvider>
  );
};
export default Find;
