import { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { useParams } from 'react-router';
import LinesEllipsis from 'react-lines-ellipsis';

import TagSystem from '_common/components/TagSystem/TagSystem';
import UserCard from '_common/components/UserCard/UserCard';
import { DueDateSystem } from '_common/components';
import { Setting } from 'Settings/components';
import ApprovalComment from '_common/components/InfoPanels/ObjectPanel/Details/ApprovalComment/ApprovalComment';
import { Divider } from 'dodoc-design-system';

import styles from './FolderSettings.module.scss';

type OpenGenericSettingModalProps = {
  header: string;
  label: string;
  value: string;
  inputPlaceholder: string;
  inputType: string;
  type: string | undefined;
  data: ObjectDict;
  action: string;
  actionValues: {
    objectId: string;
    objectType: string;
  };
};

const FolderSettings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id: objectId }: { id: ObjectId } = useParams();

  const object = useSelector((state) => state.app.data[objectId]);
  const userPermissions = object.user_permissions;

  const [isExpanded, setIsExpanded] = useState(false);
  const [clamped, setClamped] = useState(false);

  useEffect(() => {
    setIsExpanded(false);
  }, [object.description]);

  const getApprovalData = () => {
    if (!object) return false;
    if (object.status === 'approved' && object.lifecycle?.approvals?.length > 0) {
      return object;
    }
    return false;
  };

  const openChangeManagerModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ChangeManagerModal',
        data: { name: object.name, data: object },
      }),
    );
  };

  const handleOpenGenericSettingModal = ({
    header,
    label,
    value,
    type,
    inputType,
    inputPlaceholder,
    data,
    action,
    actionValues,
  }: OpenGenericSettingModalProps) => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: {
          header,
          label,
          value,
          inputPlaceholder,
          inputType,
          type,
          data,
          action,
          actionValues,
        },
      }),
    );
  };

  const openEditName = () => {
    handleOpenGenericSettingModal({
      header: intl.formatMessage({ id: 'settings.name.header' }),
      label: intl.formatMessage({ id: 'EDIT_TITLE' }, { value: object.type }),
      value: object.name,
      inputPlaceholder: intl.formatMessage(
        { id: 'EDIT_TITLE_PLACEHOLDER' },
        { value: object.type },
      ),
      type: 'name',
      inputType: 'text',
      data: object,
      action: 'rename',
      actionValues: { objectId: object.id, objectType: object.type },
    });
  };

  const openEditDescription = () => {
    handleOpenGenericSettingModal({
      header: intl.formatMessage({ id: 'settings.description.header' }),
      label: intl.formatMessage({ id: 'EDIT_DESCRIPTION_LABEL' }, { value: object.type }),
      value: object.description,
      inputPlaceholder: intl.formatMessage(
        {
          id: 'EDIT_DESCRIPTION_PLACEHOLDER',
        },
        { value: object.type },
      ),
      type: 'description',
      inputType: 'textarea',
      data: object,
      action: 'editDescription',
      actionValues: { objectId: object.id, objectType: object.type },
    });
  };

  const handleChangeExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOnReflow = (data: { clamped: boolean; text: string }) => {
    if (clamped !== data.clamped) {
      setClamped(data.clamped);
    }
  };

  return (
    <>
      <div className={styles.root}>
        {/* Title */}
        <Setting
          label={intl.formatMessage({ id: 'settings.general.title' })}
          value={object.name}
          onEdit={
            (userPermissions.includes('admin') || userPermissions.includes('owner')) && openEditName
          }
          onEditLabel="global.edit"
          testId="name"
        />
        {/* Creation */}
        <Setting
          value={<UserCard userId={object.creator} />}
          label={intl.formatMessage({ id: 'global.creator' })}
          testId="creator"
        />
        {/* Owner */}
        <Setting
          manager
          value={<UserCard userId={object.owner} />}
          label={intl.formatMessage({ id: 'global.owner' })}
          onEdit={openChangeManagerModal}
          onEditLabel="global.edit"
          testId="owner"
          isOwner={userPermissions.includes('admin') || userPermissions.includes('owner')}
        />
        {/* Description */}
        <Setting
          isDescription
          clamped={clamped}
          expanded={isExpanded}
          changeExpanded={handleChangeExpanded}
          label={intl.formatMessage({ id: 'settings.general.description' })}
          value={
            <LinesEllipsis
              // ref={content}
              text={object.description || intl.formatMessage({ id: 'NO_DESCRIPTION' })}
              maxLine={isExpanded ? 20 : 3}
              ellipsis="..."
              trimRight
              basedOn="letters"
              onReflow={handleOnReflow}
            />
          }
          onEdit={
            (userPermissions.includes('admin') ||
              userPermissions.includes('owner') ||
              userPermissions.includes('edit')) &&
            openEditDescription
          }
          onEditLabel="global.edit"
          testId="description"
        />
        {/* Tags */}
        <div className={styles.setting} data-testid="tags-row-container">
          <span className={styles.label}>
            <FormattedMessage id="settings.general.tags" />
          </span>
          <div className={styles.value}>
            <TagSystem
              objectId={objectId}
              placeholder={intl.formatMessage({ id: 'SELECT_TAG' })}
              testId="settings-page"
            />
          </div>
        </div>
        <Divider />
        {/** Approval Comment */}
        {getApprovalData() && (
          <>
            <div className={styles.setting}>
              <span className={styles.label}>
                <FormattedMessage id="storage.actionBar.filters.approved" />
              </span>
              {getApprovalData() && <ApprovalComment objectId={objectId} />}
            </div>
            <Divider />
          </>
        )}
        {/* DueDate/Warning Date */}
        <div className={styles.setting}>
          <div className={styles.dates}>
            <span className={styles.label}>
              <FormattedMessage id="settings.general.dueDate" />
            </span>
            {(userPermissions.includes('admin') ||
              userPermissions.includes('owner') ||
              userPermissions.includes('edit') ||
              (object.events && object.events.warnings.length > 0)) && (
              <span
                className={styles.label}
                style={{
                  marginTop:
                    userPermissions.includes('admin') ||
                    userPermissions.includes('owner') ||
                    userPermissions.includes('edit')
                      ? '28px'
                      : '2rem',
                }}
              >
                <FormattedMessage id="storage.browserSidebar.dueDate.warningDate" />
              </span>
            )}
          </div>
          <div className={styles.value}>
            <DueDateSystem
              objectId={object.id}
              canEdit={
                userPermissions.includes('admin') ||
                userPermissions.includes('owner') ||
                userPermissions.includes('edit')
              } /*Assuming that this page is only accessed if it has edit permissions */
            />
          </div>
        </div>
        <Divider />
      </div>
    </>
  );
};

export default FolderSettings;
