import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';
import { useAuditLog } from '../AuditLogContext';

import UserCard from '_common/components/UserCard/UserCard';
import EmptyFilteredState from '_common/components/EmptyFilteredState/EmptyFilteredState';
import { Table as BaseTable } from '_common/components/Table2';
import Cell from '_common/components/Table2/Cells';
import AuditActionLabel from '_common/components/AuditLog/AuditCells/AuditActionLabel';
import AuditActionExtra from '_common/components/AuditLog/AuditCells/AuditActionExtra';

import styles from './Table.module.scss';

type TableProps = {
  noFlex?: boolean;
};

const IDENTITY = 'auditLog';

const Table = ({ noFlex }: TableProps) => {
  const intl = useIntl();

  const { data, isFetching, hasNextPage, originalArgs, fetchNextPage, params, setParams } =
    useAuditLog();

  const hasActiveFilters = useSelector((state) => selectHasFilters(state, state.filters.auditLog));

  const columns = useMemo(() => {
    return [
      {
        id: 'user',
        header: intl.formatMessage({ id: 'global.user' }),
        width: 224,
      },
      {
        id: 'name',
        header: intl.formatMessage({ id: 'editor.menu.view.action' }),
        width: 304,
      },
      {
        id: 'time',
        header: intl.formatMessage({ id: 'TIMESTAMP' }),
        orderable: true,
        width: 160,
      },
      {
        id: 'information',
        header: intl.formatMessage({ id: 'ADDITIONAL_INFORMATION' }),
        width: 240,
        flex: true,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return data.map((auditAction) => {
      return {
        id: auditAction.id ?? '',
        user: <Cell>{auditAction.user && <UserCard userId={auditAction.user} />}</Cell>,
        name: (
          <Cell ellipsis>
            <AuditActionLabel auditAction={auditAction} />
          </Cell>
        ),
        time: <Cell.DateTime date={{ date: auditAction.time }} time={{ time: auditAction.time }} />,
        information: (
          <Cell ellipsis>
            <AuditActionExtra auditAction={auditAction} />
          </Cell>
        ),
      };
    });
  }, [data]);

  const renderEmptyView = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flex: 1, marginTop: '10rem' }}>
        <EmptyState
          size="large"
          icon="EmptyDocument"
          title={intl.formatMessage({ id: 'AUDIT_LOG_IS_EMPTY' })}
          testId="empty-audit-log-list"
        >
          {intl.formatMessage({ id: 'THERE_ARE_NO_ACTIONS_LOGGED_YET_IN_THE_DOCUMENT' })}
        </EmptyState>
      </div>
    );
  };

  if (data.length === 0) {
    return hasActiveFilters ? (
      <EmptyFilteredState identity="auditLog" margin="10rem 0 0 0" />
    ) : (
      renderEmptyView()
    );
  }

  return (
    <div className={`${styles.root} ${noFlex ? styles.noFlex : null}`}>
      <BaseTable
        identity={IDENTITY}
        selectable={false}
        columns={columns}
        value={value}
        renderFooter={() => null}
        lazyLoading={{ fetchNextPage, hasNextPage, offset: originalArgs?.offset ?? 0 }}
        requestParams={{ params, setParams }}
        isLoading={isFetching}
        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
        testId={'audit-log'}
      />
    </div>
  );
};

export default Table;
