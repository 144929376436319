import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Tooltip } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { usePDFContext } from 'PDF/PDFContext';
import { navigateToEditor, navigateToPDF } from 'router/history';

import { closeModal, openAndUpdateModal, updateModal } from '_common/modals/ModalsSlice';
import { setNodeToFocus } from 'Editor/redux/EditorStatusSlice';
import { setAnnotationToFocus } from 'PDF/redux/PDFGeneralSlice';
import { useAuditLog } from '../AuditLogContext';

import FormattedDate from '_common/components/FormattedDate/FormattedDate';
import ObjectLabel from '_common/components/Labels/ObjectLabel';

type AuditAdditionalInfoProps = {
  auditAction: ActionLog;
};

const AuditAdditionalInfo = ({ auditAction }: AuditAdditionalInfoProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isEditor = useSelector((state) => state.editor.status.visible);
  const appData = useSelector((state) => state.app.data);
  const pdf = useSelector((state) => state.pdf);

  const pdfManager = usePDFContext();

  const { objectId } = useAuditLog();

  const navigateToObjectLocation = ({
    objectType,
    objectId,
  }: {
    objectType: 'comment' | 'suggestion' | 'task' | 'node';
    objectId: ObjectId;
  }) => {
    if (isEditor) {
      switch (objectType) {
        case 'comment':
          EditorManager.getInstance().focusComment(objectId);
          break;
        case 'suggestion':
          EditorManager.getInstance().focusTrackedAction(objectId);
          break;
        case 'task':
          EditorManager.getInstance().focusTask(objectId);
          break;
        case 'node':
          EditorManager.getInstance().scrollIntoView(objectId);
          break;
      }
      dispatch(closeModal('DocumentAuditLogModal'));
    } else if (pdf.general.pdfId) {
      switch (objectType) {
        case 'task': {
          pdfManager.selectAnnotation(pdf.annotations.byId[objectId]);
          break;
        }
        default: {
          break;
        }
      }
      dispatch(closeModal('DocumentAuditLogModal'));
    } else {
      if (auditAction.target) {
        if (appData[auditAction.target]?.type === 'document') {
          dispatch(setNodeToFocus({ objectType, objectId, documentId: auditAction.target }));
          navigateToEditor(auditAction.target);
        } else if (appData[auditAction.target]?.type === 'dopdf') {
          if (objectType === 'task') {
            dispatch(setAnnotationToFocus({ objectId, documentId: auditAction.target }));
          }
          navigateToPDF(auditAction.target);
        }
      }
    }
  };

  const handleActionClick = () => {
    const { action, extra } = auditAction;

    switch (action) {
      case 'comment_created':
      case 'comment_resolved':
      case 'comment_reply':
      case 'comment_deleted':
        navigateToObjectLocation({
          objectType: 'comment',
          objectId: (extra as ApiSchemas['CommentActionExtra']).comment,
        });

        break;

      case 'suggestion_accepted':
      case 'suggestion_rejected':
      case 'suggestion_replied':
        navigateToObjectLocation({
          objectType: 'suggestion',
          objectId: (extra as ApiSchemas['SuggestionActionExtra']).suggestion,
        });
        break;

      case 'task_created':
      case 'task_replied':
      case 'task_deleted':
      case 'task_assignee_updated':
      case 'task_status_changed':
        navigateToObjectLocation({
          objectType: 'task',
          objectId: (extra as ApiSchemas['TaskActionExtra']).task,
        });
        break;
      case 'node_approved':
      case 'node_reopened':
        navigateToObjectLocation({
          objectType: 'node',
          objectId: (extra as ApiSchemas['NodeStatusActionExtra']).nodes[0],
        });
        break;
      case 'created':
      case 'imported': {
        const actionExtra = extra as ApiSchemas['CreateActionExtra'];
        dispatch(updateModal({ modal: 'DocumentAuditLogModal', data: { isVisible: false } }));
        dispatch(
          openAndUpdateModal({
            modal: 'ConfirmationModal',
            data: {
              title: 'DOWNLOAD_ORIGINAL_FILE',
              message:
                actionExtra.type === 'document'
                  ? 'DOWNLOAD_ORIGINAL_DOCUMENT_FILE_CONFIRMATION'
                  : 'DOWNLOAD_ORIGINAL_DOPDF_FILE_CONFIRMATION',
              confirmButtonTextId: 'DOWNLOAD_ORIGINAL_FILE',
              confirmButtonType: 'primary',
              cancelButtonTextId: 'global.cancel',
              actionCode: 'downloadOriginalFile',
              actionValue: {
                objectId: objectId,
                objectType: actionExtra.type,
                filename: actionExtra.target_name,
              },
              headerType: 'information',
              cancelButtonShow: true,
            },
          }),
        );
        break;
      }
    }
  };

  const getAuditExtra = () => {
    //Assume property exists as it should, if not, navigation is disabled
    const inDocumentExtra = (auditAction.extra as unknown as ApiSchemas['CommentActionExtra'])
      ?.in_document;
    switch (auditAction.action) {
      case 'comment_created':
        return (
          <Tooltip
            content={intl.formatMessage({ id: 'THIS_CARD_NO_LONGER_EXIST' })}
            disabled={inDocumentExtra}
            testId={`audit-log-row-${auditAction.id}-navigate-to-tooltip`}
          >
            <Link
              onClick={handleActionClick}
              disabled={!inDocumentExtra}
              testId={`audit-log-row-${auditAction.id}-navigate-to`}
            >
              <FormattedMessage id="SEE_COMMENT" />
            </Link>
          </Tooltip>
        );
      case 'comment_reply': // Replies
      case 'task_replied': // Replies
      case 'suggestion_replied': // Replies
        return (
          <Tooltip
            content={intl.formatMessage({ id: 'THIS_CARD_NO_LONGER_EXIST' })}
            disabled={inDocumentExtra}
            testId={`audit-log-row-${auditAction.id}-navigate-to-tooltip`}
          >
            <Link
              onClick={handleActionClick}
              disabled={!inDocumentExtra}
              testId={`audit-log-row-${auditAction.id}-navigate-to`}
            >
              <FormattedMessage id="SEE_REPLY" />
            </Link>
          </Tooltip>
        );
      case 'task_created': // New
      case 'task_status_changed': // Status update
      case 'task_assignee_updated': // Add Assign
        return (
          <Tooltip
            content={intl.formatMessage({ id: 'THIS_CARD_NO_LONGER_EXIST' })}
            testId={`audit-log-row-${auditAction.id}-navigate-to-tooltip`}
            disabled={inDocumentExtra}
          >
            <Link
              onClick={handleActionClick}
              disabled={!inDocumentExtra}
              testId={`audit-log-row-${auditAction.id}-navigate-to`}
            >
              <FormattedMessage id="SEE_TASK" />
            </Link>
          </Tooltip>
        );
      case 'node_approved': // Approve content
      case 'node_reopened': // Reopen content
        return (
          <Tooltip
            content={intl.formatMessage({ id: 'THIS_CARD_NO_LONGER_EXIST' })}
            disabled={inDocumentExtra}
            testId={`audit-log-row-${auditAction.id}-navigate-to-tooltip`}
          >
            <Link
              onClick={handleActionClick}
              disabled={!inDocumentExtra}
              testId={`audit-log-row-${auditAction.id}-navigate-to`}
            >
              <FormattedMessage id="SEE_CONTENT" />
            </Link>
          </Tooltip>
        );
      case 'edited': // Rename element | Edit description | Edit due date | Edit reminder date
        const editActions = (auditAction.extra as ApiSchemas['EditActionExtra']).fields;
        const editAction = editActions.length > 0 ? editActions[0] : null;

        if (editAction) {
          switch (editAction.field) {
            case 'name':
              if (editAction.new) {
                return (
                  <>
                    {editAction.old ? (
                      <>
                        {editAction.old}
                        {` -> `}
                      </>
                    ) : null}
                    {editAction.new}
                  </>
                );
              }
              return '-';
            case 'description':
              return editAction.new;
            case 'events.due':
              if (editAction.new) {
                return (
                  <>
                    {editAction.old ? (
                      <>
                        <FormattedDate date={editAction.old} />
                        {` -> `}
                      </>
                    ) : null}
                    <FormattedDate date={editAction.new} />
                  </>
                );
              }
              return '-';
            case 'events.warning': {
              const typedEditAction = editAction as unknown as { old: ISODate[]; new: ISODate[] };

              //Check if date was added
              const newDates = typedEditAction.new.filter(
                (newDate) => !typedEditAction.old.includes(newDate),
              );
              if (newDates.length === 1) {
                return <FormattedDate date={newDates[0]} />;
              }

              //Check if date was removed
              const oldDates = typedEditAction.old.filter(
                (oldDate) => !typedEditAction.new.includes(oldDate),
              );
              if (oldDates.length === 1) {
                return <FormattedDate date={oldDates[0]} />;
              }
            }
          }
        }

        return '-';
      case 'status_changed': {
        // Change status
        const typedExtra = auditAction.extra as ApiSchemas['StatusChangeActionExtra'];
        return `${typedExtra.old_status} -> ${typedExtra.new_status}`;
      }
      case 'moved': // Move
        const typedExtra = auditAction.extra as ApiSchemas['CopyActionExtra'];
        return (
          <>
            <ObjectLabel
              objectId={typedExtra?.source?.parent || typedExtra?.source?.space}
              parentSpace={typedExtra?.source?.space}
            />
            {` -> `}
            <ObjectLabel
              objectId={typedExtra?.destination.parent || typedExtra?.destination.space}
              parentSpace={typedExtra?.destination.space}
            />
          </>
        );
      case 'lock_unlock_suggestions': // Lock | Unlock
        if ((auditAction.extra as ApiSchemas['SuggestionsLockStateActionExtra']).lock) {
          return (
            <FormattedMessage
              id="LOCKED_AT"
              values={{
                state: (auditAction.extra as ApiSchemas['SuggestionsLockStateActionExtra'])
                  .state ? (
                  <FormattedMessage id="ON" />
                ) : (
                  <FormattedMessage id="OFF" />
                ),
              }}
            />
          );
        }
        return '-';
      case 'template_changed': {
        const actionExtra = auditAction.extra as ApiSchemas['TemplateChangeActionExtra'];
        return `${actionExtra.old_template} -> ${actionExtra.template}`;
      }
      case 'copied': {
        const actionExtra = auditAction.extra as ApiSchemas['CopyActionExtra'];
        return (
          <>
            <div>{`${actionExtra?.source.obj_name} -> ${actionExtra?.target_name}`}</div>
            <div>
              <ObjectLabel
                objectId={actionExtra?.source.parent || actionExtra?.source.space}
                parentSpace={actionExtra?.source.space}
              />
              {` -> `}
              <ObjectLabel
                objectId={actionExtra?.destination.parent || actionExtra?.destination.space}
                parentSpace={actionExtra?.destination.space}
              />
            </div>
          </>
        );
      }
      //Create Element | Convert Element
      case 'created': {
        const actionExtra = auditAction.extra as ApiSchemas['CreateActionExtra'];
        if (actionExtra.source) {
          return (
            <Link onClick={handleActionClick} testId={`audit-log-row-${auditAction.id}-download`}>
              <FormattedMessage id="DOWNLOAD_ORIGINAL_FILE" />
            </Link>
          );
        }
        return '-';
      }
      case 'imported': {
        return (
          <Link onClick={handleActionClick} testId={`audit-log-row-${auditAction.id}-download`}>
            <FormattedMessage id="DOWNLOAD_ORIGINAL_FILE" />
          </Link>
        );
      }

      default:
        return '-';
    }
  };

  return <>{getAuditExtra()}</>;
};

export default AuditAdditionalInfo;
