import { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select } from 'dodoc-design-system';

import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import {
  SelectOption,
  SequenceOptions,
  METADATA_TYPES,
  METADATA_BEHAVIOUR,
  USER_TYPES,
  TEXT_USER_TYPES,
  SEQUENCES,
} from '../AdvancedFilterOptions';
import GenericHandler from './GenericHandler';
import UserHandler from './UserHandler';
import { useAdvancedFilterContext } from '../AdvancedFilterContext';

import styles from './Handlers.module.scss';

const getSanitizedString = (value: string) => value.toLowerCase().replaceAll(' ', '_');

type MetadataHandlerProps = {
  testId?: string;
};

const MetadataHandler = ({ testId }: MetadataHandlerProps) => {
  const intl = useIntl();

  const { data: metadata } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });
  const { data: statuses } = useGetElementStatusListQuery();

  const { advancedFilter, setMetadataOption, setValue, setQuery } = useAdvancedFilterContext();
  const { option, metadataOption, value } = advancedFilter;

  //#region Memos
  const handledMetadataOptions: SelectOption[] = useMemo(
    () =>
      Object.values(metadata).map((metadataObj) => ({
        label: metadataObj.name,
        value: getSanitizedString(metadataObj.name),
      })),
    [metadata],
  );

  const handledMetadataOptionSequence: Record<string, SequenceOptions> = useMemo(() => {
    const optionSequence: Record<string, SequenceOptions> = {};
    Object.values(metadata).forEach((metadataObj) => {
      if (metadataObj.data_type === 'text' || metadataObj.sub_type === 'text') {
        //TODO: If eventually possible, remove this specific condition
        if (metadataObj.name === 'Content status') {
          optionSequence[getSanitizedString(metadataObj.name)] = {
            preceding: 'storage.actionBar.search.rules.conditions.is',
            type: 'dynamic',
            options: statuses
              ? statuses.ids
                  .filter((id) => !statuses.entities[id]?.deleted)
                  .map((id) => {
                    return {
                      value: statuses.entities[id]?.name ?? '',
                      label: statuses.entities[id]?.name ?? '',
                    };
                  })
              : [],
          };
        } else if (metadataObj.name === 'Content author') {
          optionSequence[getSanitizedString(metadataObj.name)] = USER_TYPES;
        } else {
          optionSequence[getSanitizedString(metadataObj.name)] = {
            ...SEQUENCES.STRING_COMPARE,
            options: SEQUENCES.STRING_COMPARE.options.map((option) => ({
              ...option,
              label: intl.formatMessage({ id: option.label }),
            })),
          };
        }
      } else if (metadataObj.sub_type === 'date') {
        optionSequence[getSanitizedString(metadataObj.name)] = {
          ...SEQUENCES.DATE_COMPARE,
          options: SEQUENCES.DATE_COMPARE.options.map((option) => ({
            ...option,
            label: intl.formatMessage({ id: option.label }),
          })),
        };
      } else if (metadataObj.data_type === 'select' || metadataObj.data_type === 'multi_select') {
        optionSequence[getSanitizedString(metadataObj.name)] = {
          ...METADATA_TYPES,
          options: metadataObj.values.map((option: string) => ({ label: option, value: option })),
        };
      } else if (metadataObj.data_type === 'user') {
        optionSequence[getSanitizedString(metadataObj.name)] = USER_TYPES;
      } else if (metadataObj.data_type === 'text_user') {
        optionSequence[getSanitizedString(metadataObj.name)] = TEXT_USER_TYPES;
      } else {
        handledMetadataOptions.splice(
          handledMetadataOptions.findIndex((option) => option.label === metadataObj.name),
        );
        logger.warn(`MetadataHandler - Removing unhandled data_type: ${metadataObj.data_type}`);
      }
    });

    return optionSequence;
  }, [metadata]);
  //#endregion

  // Update query
  useEffect(() => {
    // If type isn't dynamic, then query should be handled by other handlers
    if (subSequence && subSequence.type !== 'dynamic') {
      return;
    }

    if (!subSequence?.type || !value) {
      setQuery(undefined);
      return;
    }

    //Metadata field names may have special chars, so a quotation mark wrap is necessary in order to group it
    const specialChars = /[ !@#$%^&*()+\-=[\]{};:\\|,<>/?~]/;

    const fieldMerge = `${option}.${metadataOption}`;
    const field =
      metadataOption && specialChars.test(metadataOption) ? `"${fieldMerge}"` : fieldMerge;

    setQuery(METADATA_BEHAVIOUR({ field, value: value }));
  }, [value]);

  const subSequence = useMemo(
    () => (metadataOption ? handledMetadataOptionSequence[metadataOption] : undefined),
    [metadataOption],
  );

  const handleSubFilterByChange = (newSubFilterBy: SelectOption) => {
    if (newSubFilterBy) {
      setMetadataOption(newSubFilterBy.value);
    }
  };

  const handleChangeValue = (newSelectValue: SelectOption) => {
    setValue(newSelectValue.value);
  };

  const renderSubFieldSequence = () => {
    if (!subSequence) {
      return null;
    }

    switch (subSequence.type) {
      case 'string':
      case 'date':
        return <GenericHandler sequence={subSequence} testId={`${testId}-generic`} />;
      case 'user':
      case 'text_user':
        return <UserHandler sequence={subSequence} testId={`${testId}-user`} />;
      case 'dynamic':
        return (
          subSequence?.options && (
            <Select
              clearable={false}
              width="20rem"
              size="medium"
              options={subSequence.options}
              onChange={handleChangeValue}
              value={
                subSequence.options.find((option) => option.value === value) || null //Force select UI cleanup by sending null
              }
              showOptionTooltip
              testId={`${testId}-sequence-options`}
            />
          )
        );
      default:
        logger.error('Trying to work with unhandled metadata field type');
        break;
    }
  };
  return (
    <div className={styles.handlerRoot} data-testid={testId}>
      <Select
        clearable={false}
        width="20rem"
        size="medium"
        options={handledMetadataOptions}
        onChange={handleSubFilterByChange}
        value={handledMetadataOptions.find((option) => option.value === metadataOption) || null} //Force select UI cleanup by sending null
        testId={`${testId}-options`}
      />
      {subSequence?.preceding && (
        <div className={styles.preceding}>
          <FormattedMessage id={subSequence.preceding} />
        </div>
      )}
      {renderSubFieldSequence()}
    </div>
  );
};

export default MetadataHandler;
