import { IntlErrorBoundary } from '_common/components';

import Menu from './Menu';

import styles from './Menu.module.scss';

const MenuBoundary = () => {
  return (
    <div className={styles.root}>
      <IntlErrorBoundary size="small">
        <Menu />
      </IntlErrorBoundary>
    </div>
  );
};

export default MenuBoundary;
