import {
  activateOnboarding,
  completeActionList,
  PDFActions,
  PDFInteractions,
  setCurrentDocument,
  setInteractions,
  setPulseData,
  stopOnboarding,
} from 'App/redux/onboardingSlice';
import { OnboardingIntegrationCards, Pulse, Box } from 'dodoc-design-system';
import {
  OnBoardingCarBoxProps,
  OnboardingIntegrationCardsProps,
} from 'dodoc-design-system/build/types/Components/OnboardingIntegrationCards/OnboardingIntegrationCards';
import { getBoxFromAnnotation } from 'PDF/Content/AnnotationsLayer/Annotation/Utils';
import { usePDFContext } from 'PDF/PDFContext';
import {
  clearSelection,
  selectAllPDFAnnotations,
  selectPDFTasks,
  setEditingAnnotation,
} from 'PDF/redux/PDFAnnotationsSlice';
import {
  selectPDFZoom,
  setCursorMode,
  setLeftPanel,
  setRightPanel,
  setZoomValue,
} from 'PDF/redux/PDFGeneralSlice';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';

import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import { OnboardingOverlay, WelcomeScreen } from '_common/components';
import { useDispatch, useSelector } from '_common/hooks';
import { useGetCurrentUserQuery } from '_common/services/api/authority';
import usePDFData from 'PDF/hooks/usePDFData';
import { getOnboardingProgress } from 'utils';

type VisibleSpace = OnboardingIntegrationCardsProps['cards'][number]['visibleSpace'];

type Position = {
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
};

type CardId =
  | 'beginning'
  | 'annotation_location'
  | 'annotation_description'
  | 'annotation_create'
  | 'annotation_commentAnnotation'
  | 'annotation_completed'
  | 'comment_description'
  | 'comment_create'
  | 'comment_save'
  | 'comment_replyMention'
  | 'comment_completed'
  | 'task_description'
  | 'task_create'
  | 'task_fillTask'
  | 'task_completed'
  | 'ending';

type BoxTarget = (
  | 'toolbar'
  | 'toolbar_annotations'
  | 'toolbar_highlight'
  | 'toolbar_comment'
  | 'toolbar_task'
)[];

type ExtendedCard = OnboardingIntegrationCardsProps['cards'][number] & {
  id: CardId;
  deps?: PDFActions[];
  interactions?: PDFInteractions[];
  pulseTarget?: (
    | 'annotation'
    | 'contextMenu_task'
    | 'annotationCard'
    | 'commentReply'
    | 'commentCard'
    | 'taskDescription'
    | 'taskAssigned'
    | 'taskDueDate'
    | 'sidebar_highlight'
    | 'sidebar_comment'
    | 'sidebar_task'
    | 'document_highlight'
    | 'document_comment'
    | 'document_task'
  )[];
  boxTarget?: BoxTarget;
  //Action to be executed when a refresh is made and this card is skipped
  autoAction?: () => void;
  //Action to be executed as soon as this card is rendered
  onRender?: () => void;
};

const OnboardingPDF = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const currentPdf = usePDFData();
  const pdfManager = usePDFContext();

  const [sendOnboardingStatus] = useOnboardingStatusMutation();
  const { isSuccess: userLoaded } = useGetCurrentUserQuery();

  const initialPhase = useSelector((state) => state.onboarding.initialPhase.dopdf);
  const originalDoc = useSelector((state) => state.onboarding.currentDocument.dopdf);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const active = useSelector((state) => state.onboarding.active.dopdf);
  const started = useSelector((state) => state.onboarding.started.dopdf);
  const pulseData = useSelector((state) => state.onboarding.pulseData);
  const annotations = useSelector(selectAllPDFAnnotations);
  const tasks = useSelector(selectPDFTasks);
  const zoom = useSelector(selectPDFZoom);
  const cursorMode = useSelector((state) => state.pdf.general.cursorMode);

  const [initialCardIndex, setInitialCardIndex] = useState<number>();
  const [activeCard, setActiveCard] = useState(initialCardIndex ?? 0);
  const [pulsePosition, setPulsePosition] = useState<{
    position: Position;
    boundary: HTMLElement | null | undefined;
  }>();
  const [boxPosition, setBoxPosition] = useState<{
    position: OnBoardingCarBoxProps;
    boundary: HTMLElement | null;
  }>();
  const [skipping, setSkipping] = useState(false);

  const topValue = '112px';
  const heightValue = '160px';

  const visibleSpace: Record<string, NonNullable<VisibleSpace>[number]> = useMemo(
    () => ({
      toolbar: {
        top: '63px',
        height: `48px`,
        width: '100%',
        left: '0px',
      },
      all: {
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
      },
    }),
    [topValue, heightValue],
  );

  const cards = useMemo<ExtendedCard[]>(
    () => [
      // Start
      {
        id: 'beginning',
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_STARTED' }),
        progress: getOnboardingProgress(2, 1, 0),
        currentPhase: 0,
        interactions: ['pdf_allow_zoom', 'pdf_text_layer', 'pdf_toggles_layer'],
      },
      // Annotations 1 (Location)
      {
        id: 'annotation_location',
        visibleSpace: [visibleSpace.toolbar],
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_ANNOTATIONS_LOCATION' }),
        progress: getOnboardingProgress(2, 1, 0),
        currentPhase: 1,
        boxTarget: ['toolbar'],
        interactions: ['pdf_allow_zoom'],
      },
      // Annotations 2 (Description)
      {
        id: 'annotation_description',
        boxTarget: ['toolbar_annotations'],
        visibleSpace: [visibleSpace.toolbar],
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_ANNOTATIONS_DESCRIPTION' }),
        progress: getOnboardingProgress(2, 1, 0),
        currentPhase: 1,
        interactions: ['pdf_allow_zoom', 'pdf_text_layer', 'pdf_toggles_layer'],
      },
      // Annotations 3 (Highlight)
      {
        id: 'annotation_create',
        visibleSpace: [visibleSpace.all],
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        phaseTitle: intl.formatMessage({ id: 'CREATE_ANNOTATION' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_ANNOTATIONS_CREATE_ANNOTATION' }),
        tipValue: intl.formatMessage({ id: 'ONBOARDING_PDF_ANNOTATIONS_CREATE_ANNOTATION_TIP' }),
        showPhases: false,
        progress: getOnboardingProgress(2, 1, 0),
        currentPhase: 1,
        subPhase: 1,
        deps: ['pdf_annotations_highlight'],
        pulseTarget: ['sidebar_highlight', 'document_highlight'],
        interactions: [
          'pdf_allow_zoom',
          'pdf_toolbar_highlight',
          'pdf_text_layer',
          'pdf_toggles_layer',
        ],
      },
      // Annotations 4 (Comment to Highlight)
      {
        id: 'annotation_commentAnnotation',
        visibleSpace: [visibleSpace.all],
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        phaseTitle: intl.formatMessage({ id: 'EDIT_ANNOTATION' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_ANNOTATIONS_COMMENT_ANNOTATION' }),
        tipValue: intl.formatMessage({ id: 'ONBOARDING_PDF_ANNOTATIONS_COMMENT_ANNOTATION_TIP' }),
        showPhases: false,
        progress: getOnboardingProgress(2, 1, 50),
        currentPhase: 1,
        subPhase: 2,
        deps: ['pdf_annotations_commentHighlight'],
        pulseTarget: ['annotation', 'contextMenu_task', 'annotationCard'],
        interactions: [
          'pdf_allow_zoom',
          'pdf_annotation_edit',
          'pdf_annotation_save',
          'pdf_text_layer',
          'pdf_toggles_layer',
        ],
      },
      // Annotations 5 (Completed)
      {
        id: 'annotation_completed',
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        phaseTitle: intl.formatMessage({ id: 'EDIT_ANNOTATION' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_ANNOTATIONS_COMPLETED' }),
        showPhases: true,
        progress: getOnboardingProgress(2, 1, 100),
        currentPhase: 1,
        subPhase: 2,
        phaseCompleted: 1,
        interactions: ['pdf_allow_zoom', 'pdf_text_layer', 'pdf_toggles_layer'],
      },
      // Comments 1 (Description)
      {
        id: 'comment_description',
        visibleSpace: [visibleSpace.toolbar],
        boxTarget: ['toolbar_comment'],
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        description: intl.formatMessage(
          { id: 'ONBOARDING_PDF_COMMENTS_DESCRIPTION' },
          { br: <br /> },
        ),
        showPhases: true,
        progress: getOnboardingProgress(2, 2, 0),
        currentPhase: 2,
        interactions: ['pdf_allow_zoom', 'pdf_text_layer', 'pdf_toggles_layer'],
        onRender: () => {
          dispatch(clearSelection());
        },
      },
      // Comments 2 (Add comment)
      {
        id: 'comment_create',
        visibleSpace: [visibleSpace.all],
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        phaseTitle: intl.formatMessage({ id: 'CREATE_COMMENT' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_COMMENTS_CREATE_COMMENT' }),
        showPhases: false,
        progress: getOnboardingProgress(2, 2, 0),
        currentPhase: 2,
        subPhase: 1,
        deps: ['pdf_comments_openComment'],
        pulseTarget: ['document_comment', 'sidebar_comment'],
        interactions: [
          'pdf_allow_zoom',
          'pdf_toolbar_comment',
          'pdf_text_layer',
          'pdf_toggles_layer',
        ],
      },
      //Comments 2.1 (Save comment)
      {
        id: 'comment_save',
        visibleSpace: [visibleSpace.all],
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        description: intl.formatMessage({
          id: 'ONBOARDING_STANDALONE_PDF_COMMENTS_SAVE',
        }),
        showPhases: false,
        progress: 36.6,
        currentPhase: 2,
        subPhase: 1,
        phaseTitle: intl.formatMessage({ id: 'CREATE_COMMENT' }),
        deps: ['pdf_comments_createComment'],
        pulseTarget: ['document_comment', 'commentCard'],
        interactions: [
          'pdf_allow_zoom',
          'pdf_annotation_save',
          'pdf_text_layer',
          'pdf_toggles_layer',
        ],
      },
      // Comments 3 (Reply to comment)
      {
        id: 'comment_replyMention',
        visibleSpace: [visibleSpace.all],
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        phaseTitle: intl.formatMessage({ id: 'ADD_A_REPLY' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_COMMENTS_REPLY_COMMENT' }),
        tipValue: intl.formatMessage({ id: 'ONBOARDING_PDF_COMMENTS_REPLY_COMMENT_TIP' }),
        showPhases: false,
        progress: getOnboardingProgress(2, 2, 50),
        currentPhase: 2,
        subPhase: 2,
        deps: ['pdf_comments_mentionInCommentReply'],
        pulseTarget: ['commentReply'],
        interactions: [
          'pdf_comment_reply',
          'pdf_allow_zoom',
          'pdf_text_layer',
          'pdf_toggles_layer',
        ],
      },
      // Comments 4 (Completed)
      {
        id: 'comment_completed',
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        phaseTitle: intl.formatMessage({ id: 'ADD_A_REPLY' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_COMMENTS_COMPLETED' }),
        progress: getOnboardingProgress(2, 2, 100),
        currentPhase: 2,
        subPhase: 2,
        phaseCompleted: 2,
        interactions: ['pdf_allow_zoom', 'pdf_text_layer', 'pdf_toggles_layer'],
      },
      //End
      {
        id: 'ending',
        title: intl.formatMessage({ id: 'AND_THATS_JUST_THE_BEGINNING' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_COMPLETED' }, { br: <br /> }),
        showPhases: false,
        progress: 100,
        currentPhase: 0,
        nextLabel: intl.formatMessage({ id: 'GO_TO_PDF' }),
        interactions: ['pdf_allow_zoom', 'pdf_text_layer', 'pdf_toggles_layer'],
      },
    ],
    [],
  );

  const skipProps = useMemo(() => {
    return {
      skipPreviousLabel: intl.formatMessage({ id: 'NO_CONTINUE_ONBOARDING' }),
      skipFinishLabel: intl.formatMessage({ id: 'YES_SKIP' }),
      skipHeader: intl.formatMessage({ id: 'SKIP_THE_ONBOARDING_QUESTION' }),
      skipContent: intl.formatMessage({ id: 'ONBOARDING_PDF_SKIP_CONFIRMATION' }),
    };
  }, [intl]);

  const getPositionInDocument = useCallback(
    (defaultTop: number, defaultLeft: number) => {
      const defaultHeight = 24 / 1.33;
      const pointerRadius = 12;

      const top = defaultTop * zoom;
      const left = defaultLeft * zoom;
      const height = defaultHeight * zoom;

      setPulsePosition({
        position: {
          top: `${top + height / 2 - pointerRadius}px`,
          left: `${left}px`,
        },
        boundary: document.getElementById(`page_1`),
      });

      return {
        top: `${top + height / 2 - pointerRadius}px`,
        left: `${left}px`,
      };
    },
    [zoom],
  );

  useEffect(() => {
    if (!userLoaded) {
      return;
    }

    /**
     * Shouldn't be necessary but added as a safeguard.
     *
     * Ignore initialPhase behaviour in the following states
     */
    const inactiveStates = ['beginning', 'ending'];
    if (initialPhase && !inactiveStates.includes(initialPhase)) {
      const initialCardIndex = cards.findIndex((card) => card.id === initialPhase);
      if (initialCardIndex > 0) {
        setActiveCard(initialCardIndex);
        setInitialCardIndex(initialCardIndex);

        const completedActions = cards
          .slice(0, initialCardIndex)
          .reduce<PDFActions[]>((allActions, card) => {
            if (card.deps) {
              allActions = [...allActions, ...card.deps];
            }

            return allActions;
          }, []);

        dispatch(completeActionList(completedActions));

        for (let i = initialCardIndex; i >= 0; i--) {
          const card = cards[i];

          if (card.autoAction) {
            card.autoAction();

            break;
          }
        }
      }
    }
  }, [userLoaded]);

  useEffect(() => {
    const { annotationId, commentId, taskId } = pulseData;

    const annotation = annotations.find(
      (annotation) => annotation.state !== 'Cancelled' && annotation.subtype === 'Highlight',
    );
    const comment = annotations.find(
      (annotation) => annotation.state !== 'Cancelled' && annotation.subtype === 'Note',
    );
    const task = tasks.find((task) => task.state !== 'Cancelled');

    if (annotation && !annotationId) {
      dispatch(setPulseData({ annotationId: annotation.id }));
    }
    if (comment && !commentId) {
      dispatch(setPulseData({ commentId: comment.id }));
    }
    if (task && !taskId) {
      dispatch(setPulseData({ taskId: task.id }));
    }
  }, [annotations, tasks, pulseData]);

  useEffect(() => {
    if (cards[activeCard].deps?.every((dep) => actionsCompleted[dep])) {
      const activeCardIndex = cards.findIndex((card) => card.id === cards[activeCard].id);

      //Validate that the next card exists
      if (activeCardIndex + 1 <= cards.length) {
        sendOnboardingStatus({
          target: 'dopdf',
          step: cards[activeCardIndex + 1].id,
        });
      }
    }
  }, [activeCard, actionsCompleted]);

  useEffect(() => {
    dispatch(setInteractions(cards[activeCard].interactions ?? []));

    cards[activeCard].onRender?.();
  }, [activeCard, cards]);

  useEffect(() => {
    if (actionsCompleted.pdf_annotations_highlight) {
      dispatch(setCursorMode('normal'));
    }
    switch (cards[activeCard].id) {
      case 'comment_create': {
        const { commentId } = pulseData;
        if (commentId) {
          const intervalId = setInterval(() => {
            const annotationCard = document.getElementById(`annotation-card-${commentId}`);

            if (annotationCard) {
              (document.activeElement as HTMLInputElement | undefined)?.blur?.();
              clearInterval(intervalId);
            }
          }, 100);
        }
        break;
      }
      case 'task_create': {
        const intervalId = setInterval(() => {
          const temporaryCard = document.getElementById(`temporaryTaskCard`);

          if (temporaryCard) {
            (document.activeElement as HTMLInputElement | undefined)?.blur?.();
            clearInterval(intervalId);
          }
        }, 100);

        break;
      }

      case 'comment_save': {
        if (!actionsCompleted.pdf_comments_createComment) {
          const { commentId } = pulseData;

          if (commentId) {
            dispatch(setEditingAnnotation({ id: commentId, isTask: false }));
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }, [actionsCompleted, activeCard, cards, pulseData]);

  useEffect(() => {
    const pointerRadius = 12;
    const pointerDiameter = pointerRadius * 2;

    const processPulse = () => {
      if (cards[activeCard].pulseTarget) {
        cards[activeCard].pulseTarget?.forEach(async (target) => {
          switch (target) {
            case 'annotation': {
              const {
                annotationId,
                contextMenuTaskItemRect,
                annotationCardCtaRect,
                annotationCardReplyRect,
              } = pulseData;
              if (annotationId) {
                const annotation = annotations.find((annotation) => annotation.id === annotationId);
                if (annotation) {
                  const pageId = `page_${annotation.pageNumber}`;
                  const pdfPage = await pdfManager.getPage(annotation.pageNumber);
                  const viewport = pdfPage?.getViewport({ scale: zoom });
                  if (viewport) {
                    if (
                      !contextMenuTaskItemRect &&
                      !annotationCardCtaRect &&
                      !annotationCardReplyRect
                    ) {
                      if (!actionsCompleted.pdf_annotations_commentHighlight) {
                        const box = getBoxFromAnnotation(annotation, viewport.scale);

                        setPulsePosition({
                          position: {
                            bottom: `${Math.ceil(box.bottom)}px`,
                            left: `${Math.ceil(box.left + box.width / 2)}px`,
                          },
                          boundary: document.getElementById(pageId),
                        });
                      } else {
                        setPulsePosition(undefined);
                      }
                    }
                  }
                }
              }
              break;
            }
            case 'contextMenu_task': {
              const { contextMenuTaskItemRect } = pulseData;
              if (contextMenuTaskItemRect) {
                setPulsePosition({
                  position: {
                    top: `${Math.ceil(
                      contextMenuTaskItemRect.top + contextMenuTaskItemRect.height / 2 - 12,
                    )}px`,
                    left: `${Math.ceil(contextMenuTaskItemRect.left)}px`,
                  },
                  boundary: document.getElementById('AnnotationContextMenu'),
                });
              }
              break;
            }
            case 'annotationCard':
            case 'commentCard': {
              const { annotationId, commentId } = pulseData;

              let id = annotationId;
              if (target === 'commentCard') {
                id = commentId;
              }

              const cardElement = document.getElementById(`annotation-card-${id}`);
              const ctaButton = document.getElementById(`annotation-${id}-cta-button`);

              if (cardElement && ctaButton) {
                const rects = {
                  top: ctaButton.offsetTop,
                  left: ctaButton.offsetLeft,
                  height: ctaButton.offsetHeight,
                  width: ctaButton.offsetWidth,
                };

                setPulsePosition({
                  position: {
                    top: `${Math.ceil(
                      rects.top + rects.height / 2 + pointerDiameter / 3, //One third of pointer diameter
                    )}px`,
                    left: `${Math.ceil(rects.left - pointerRadius + rects.width / 2)}px`,
                  },
                  boundary: cardElement,
                });
              }

              break;
            }
            case 'commentReply': {
              const { commentId, annotationCardReplyRect } = pulseData;
              if (annotationCardReplyRect) {
                setPulsePosition({
                  position: {
                    top: `${Math.ceil(
                      annotationCardReplyRect.top +
                        annotationCardReplyRect.height -
                        pointerDiameter / 3, //One third of pointer diameter,
                    )}px`,
                    left: `${Math.ceil(
                      annotationCardReplyRect.left +
                        annotationCardReplyRect.width / 2 -
                        pointerRadius,
                    )}px`,
                  },
                  boundary: document.getElementById(`annotation-card-${commentId}`),
                });
              } else {
                setPulsePosition(undefined);
              }
              break;
            }
            case 'taskDescription':
            case 'taskAssigned':
            case 'taskDueDate': {
              const { taskInputRect, taskButtonRect } = pulseData;
              if (taskInputRect) {
                setPulsePosition({
                  position: {
                    top: `${Math.ceil(
                      taskInputRect.top +
                        taskInputRect.height -
                        (pointerDiameter / 3) * 2 /*Two thirds of pointer diameter */,
                    )}px`,
                    left: `${Math.ceil(
                      taskInputRect.left +
                        taskInputRect.width -
                        pointerDiameter -
                        4 /*Right padding*/,
                    )}px`,
                  },
                  boundary: document.getElementById(`temporaryTaskCard`),
                });
              } else if (taskButtonRect) {
                setPulsePosition({
                  position: {
                    top: `${Math.ceil(
                      taskButtonRect.top + taskButtonRect.height / 2 + pointerDiameter / 3, //One third of pointer diameter
                    )}px`,
                    left: `${Math.ceil(
                      taskButtonRect.left - pointerRadius + taskButtonRect.width / 2,
                    )}px`,
                  },
                  boundary: document.getElementById(`temporaryTaskCard`),
                });
              }
              break;
            }

            case 'sidebar_highlight': {
              const { annotationId } = pulseData;
              if (!annotationId && cursorMode !== 'Highlight') {
                const target = document.getElementById('pdf-toolbar-highlight');
                if (target) {
                  const targetRect = target.getBoundingClientRect();
                  setPulsePosition({
                    position: {
                      top: `${targetRect.height - pointerRadius / 2}px`,
                      left: `2px`,
                    },
                    boundary: target,
                  });
                }
              }
              break;
            }
            case 'sidebar_comment': {
              const { commentId, annotationCardCtaRect } = pulseData;
              if (!annotationCardCtaRect && cursorMode !== 'Note') {
                if (!commentId) {
                  const target = document.getElementById('pdf-toolbar-comment');
                  if (target) {
                    const targetRect = target.getBoundingClientRect();
                    setPulsePosition({
                      position: {
                        top: `${targetRect.height - pointerRadius / 2}px`,
                        left: `${targetRect.width / 2 - pointerRadius}px`,
                      },
                      boundary: target,
                    });
                  }
                }
              }
              break;
            }
            case 'sidebar_task': {
              const { taskId } = pulseData;
              if (!taskId) {
                setPulsePosition({
                  position: {
                    right: 'calc(100% + 105.5rem)',
                    top: '12.3rem',
                    left: 'calc(50% + 269px)',
                  },
                  boundary: null,
                });
              }
              break;
            }
            case 'document_highlight': {
              const { annotationId } = pulseData;
              if (!annotationId && cursorMode === 'Highlight') {
                const defaultTop = 49;
                const defaultLeft = 690;

                setPulsePosition({
                  position: getPositionInDocument(defaultTop, defaultLeft),
                  boundary: document.getElementById(`page_1`),
                });
              } else if (annotationId) {
                setPulsePosition(undefined);
              }
              break;
            }
            case 'document_comment': {
              const { commentId } = pulseData;
              if (!commentId && cursorMode === 'Note') {
                const defaultTop = 48;
                const defaultLeft = 1225;

                setPulsePosition({
                  position: getPositionInDocument(defaultTop, defaultLeft),
                  boundary: document.getElementById(`page_1`),
                });
              } else if (commentId) {
                setPulsePosition(undefined);
              }
              break;
            }
            case 'document_task': {
              const { taskId } = pulseData;
              if (!taskId && cursorMode === 'Task') {
                const defaultTop = 422;
                const defaultLeft = 520;

                setPulsePosition({
                  position: getPositionInDocument(defaultTop, defaultLeft),
                  boundary: document.getElementById(`page_1`),
                });
              } else if (taskId) {
                setPulsePosition(undefined);
              }
              break;
            }
            default: {
              setPulsePosition(undefined);
              break;
            }
          }
        });
      } else {
        setPulsePosition(undefined);
      }
    };

    processPulse();
  }, [activeCard, cards, zoom, pulseData, cursorMode, actionsCompleted]);

  //Cards' box
  useEffect(() => {
    if (cards[activeCard]?.boxTarget) {
      cards[activeCard].boxTarget?.forEach(async (target) => {
        switch (target) {
          case 'toolbar': {
            const target = document.getElementById('pdf-toolbar');
            if (target) {
              const rect = target.getBoundingClientRect();
              setBoxPosition({
                position: {
                  top: `4px`,
                  left: `4px`,
                  width: `calc(100vw - 1rem)`,
                  height: `${rect.height - 8}px`,
                },
                boundary: target,
              });
            }
            break;
          }
          case 'toolbar_annotations': {
            const toolbar = document.getElementById('pdf-toolbar');
            const target = document.getElementById('pdf-toolbar-section-2');
            if (target && toolbar) {
              const toolbarRect = toolbar.getBoundingClientRect();
              const targetRect = target.getBoundingClientRect();
              const topPadding = targetRect.y - toolbarRect.y;

              setBoxPosition({
                position: {
                  top: `-${topPadding - 4}px`,
                  left: `-4px`,
                  width: `${targetRect.width + 10}px`,
                  height: `${targetRect.height + topPadding}px`,
                },
                boundary: target,
              });
            }
            break;
          }
          case 'toolbar_highlight': {
            const toolbar = document.getElementById('pdf-toolbar');
            const target = document.getElementById('pdf-toolbar-highlight');
            if (target && toolbar) {
              const toolbarRect = toolbar.getBoundingClientRect();
              const targetRect = target.getBoundingClientRect();
              const topPadding = targetRect.y - toolbarRect.y;

              setBoxPosition({
                position: {
                  top: `-${topPadding - 4}px`,
                  left: `-8px`,
                  width: `${targetRect.width + 8}px`,
                  height: `${targetRect.height + topPadding}px`,
                },
                boundary: target,
              });
            }
            break;
          }
          case 'toolbar_comment': {
            const toolbar = document.getElementById('pdf-toolbar');
            const target = document.getElementById('pdf-toolbar-comment');
            if (target && toolbar) {
              const toolbarRect = toolbar.getBoundingClientRect();
              const targetRect = target.getBoundingClientRect();
              const topPadding = targetRect.y - toolbarRect.y;

              setBoxPosition({
                position: {
                  top: `-${topPadding - 4}px`,
                  left: `-4px`,
                  width: `${targetRect.width + 8}px`,
                  height: `${targetRect.height + topPadding}px`,
                },
                boundary: target,
              });
            }
            break;
          }
          case 'toolbar_task': {
            const toolbar = document.getElementById('pdf-toolbar');
            const target = document.getElementById('pdf-toolbar-task');
            if (target && toolbar) {
              const toolbarRect = toolbar.getBoundingClientRect();
              const targetRect = target.getBoundingClientRect();
              const topPadding = targetRect.y - toolbarRect.y;

              setBoxPosition({
                position: {
                  top: `-${topPadding - 4}px`,
                  left: `-4px`,
                  width: `${targetRect.width + 8}px`,
                  height: `${targetRect.height + topPadding}px`,
                },
                boundary: target,
              });
            }
            break;
          }
          default: {
            setBoxPosition(undefined);
            break;
          }
        }
      });
    } else {
      setBoxPosition(undefined);
    }
  }, [activeCard, cards]);

  const handleNext = (currentCard: number) => {
    setActiveCard(currentCard);
    dispatch(setCursorMode('normal'));
    if (cards[currentCard].id !== 'ending') {
      sendOnboardingStatus({ target: 'dopdf', step: cards[currentCard].id });
    }
  };

  const handleBack = (currentCard: number) => {
    if (cards[activeCard].id === 'beginning') {
      dispatch(stopOnboarding('dopdf'));
      dispatch(activateOnboarding('dopdf'));
    }
    setActiveCard(currentCard);
    dispatch(setCursorMode('normal'));
  };

  const exitOnboarding = (skip?: boolean) => {
    const id = !originalDoc?.id || originalDoc.id === '' ? currentPdf?.id : originalDoc.id;
    sendOnboardingStatus({ target: 'dopdf', step: 'ending', skip: !!skip });
    dispatch(stopOnboarding('dopdf'));
    setSkipping(false);
    dispatch(setZoomValue(originalDoc?.zoom ?? 1));
    dispatch(setCurrentDocument({ target: 'dopdf' }));
    window.open(`/pdf/${id}`, '_self');
  };

  const handleSkip = () => {
    exitOnboarding(true);
  };

  const handleEnding = () => {
    exitOnboarding();
  };

  const onCancelSkip = () => {
    setSkipping(false);
  };

  useEffect(() => {
    if ((started || active) && (currentPdf?.id !== originalDoc?.id || originalDoc?.isOnboarding)) {
      dispatch(setRightPanel(undefined));
      dispatch(setLeftPanel(''));
    }
  }, [started, active, originalDoc, currentPdf]);

  if (
    (!started && !active) ||
    !originalDoc ||
    (!originalDoc?.isOnboarding && (originalDoc?.id === '' || currentPdf?.id === originalDoc?.id))
  ) {
    return null;
  }

  return (
    <>
      {(active || (started && skipping)) && (
        <WelcomeScreen
          location="dopdf"
          onSkip={setSkipping}
          labelsId={{
            title: 'WELCOME_TO_THE_PDF_REVIEWER',
            description: 'WELCOME_PDF_DESCRIPTION',
            cta: 'LEARN_MORE',
          }}
          hiddenElements={['pdfContainer']}
        />
      )}
      <OnboardingOverlay environment="dopdf">
        <div style={skipping || started ? undefined : { display: 'none' }}>
          <OnboardingIntegrationCards
            title={intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' })}
            nextDisabled={
              cards[activeCard].deps
                ? cards[activeCard].deps?.some((dep) => !actionsCompleted[dep]) ?? false
                : false
            }
            nextDisabledTooltip={intl.formatMessage({ id: 'ONBOARDING_PENDING_ACTION_TOOLTIP' })}
            tip={intl.formatMessage({ id: 'TIP_COLON' })}
            previousLabel={intl.formatMessage({ id: 'BACK' })}
            next={intl.formatMessage({ id: 'NEXT' })}
            onNext={handleNext}
            onBack={handleBack}
            skipButtonLabel={intl.formatMessage({ id: 'SKIP_ONBOARDING' })}
            cards={cards}
            phases={{
              annotations: {
                order: 1,
                label: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_5_HEADER' }),
              },
              comments: { order: 2, label: intl.formatMessage({ id: 'COMMENTS' }) },
            }}
            onExit={handleSkip}
            onFinalExit={handleEnding}
            {...skipProps}
            size="medium"
            onCancel={() => {}}
            skip={skipping}
            cancelSkip={skipping ? onCancelSkip : undefined}
            initialCard={initialCardIndex}
            testId="pdf"
            pulse={
              pulsePosition?.position &&
              (pulsePosition.boundary ? (
                ReactDOM.createPortal(
                  <Pulse position={pulsePosition.position} testId="pdf" />,
                  pulsePosition.boundary,
                )
              ) : (
                <Pulse position={pulsePosition.position} testId="pdf" />
              ))
            }
            box={
              boxPosition?.position &&
              (boxPosition.boundary ? (
                ReactDOM.createPortal(
                  <Box box={boxPosition.position} testId="editor" />,
                  boxPosition.boundary,
                )
              ) : (
                <Box box={boxPosition.position} testId="editor" />
              ))
            }
          />
        </div>
      </OnboardingOverlay>
    </>
  );
};

export default OnboardingPDF;
