import qs from 'qs';
import { paths } from '_types/api';
import InstanceService from './InstanceService';

/**
 * Groups related requests
 */
export default class GroupsService extends InstanceService {
  // -----------------------------------------------------------------
  // groups endpoints
  GROUPS_LIST = () => this.LIST('group');

  GROUPS_CREATE = () => this.CREATE('group');

  GROUPS_DELETE = (id: ObjectId) => this.DELETE('group', id);

  GROUPS_EDIT = (id: ObjectId) => this.EDIT('group', id);

  GROUPS_GET = (id: ObjectId) => this.GET_DATA('group', id);

  GROUPS_GIVE_PERMISSION = (id: ObjectId) => this.ADD_PERMISSION('group', id);

  GROUPS_REMOVE_PERMISSION = (id: ObjectId) => this.REMOVE_PERMISSION('group', id);

  GROUPS_ADD_USER = (id: ObjectId) => `/api/group/${id}/add/user`;

  GROUPS_REMOVE_USER = (id: ObjectId) => `/api/group/${id}/remove/user`;

  GROUP_AUTOCOMPLETE = (query: string) => `/api/group/autocomplete?query=${query}`;

  /**
   * get list of groups
   * @param {*} params
   */
  getGroupsList(params?: Request.AllListParams) {
    return this.get<
      paths['/api/object/group/list']['get']['responses']['200']['content']['multipart/form-data']
    >(this.GROUPS_LIST(), { params });
  }

  /**
   * create a group
   */
  createGroup(name: string) {
    return this.post<
      ValueOf<
        paths['/api/object/group/create']['post']['responses']['200']['content']['application/json']
      >
    >(this.GROUPS_CREATE(), qs.stringify({ name }));
  }

  /**
   * edit a group
   */
  editGroup(groupId: ObjectId, values: { name: string; description: string }) {
    return this.post(this.GROUPS_EDIT(groupId), qs.stringify(values));
  }

  /**
   * delete a group
   */
  deleteGroup(groupId: ObjectId) {
    return this.post(this.GROUPS_DELETE(groupId));
  }

  /**
   * get group info
   */
  getGroupById(id: ObjectId) {
    return this.get(this.GROUPS_GET(id));
  }

  /**
   * add user to group
   */
  addUserToGroup(groupId: ObjectId, userId: UserId) {
    return this.post(this.GROUPS_ADD_USER(groupId), qs.stringify({ user: userId }));
  }

  /**
   * remove user from group
   */
  removeUserFromGroup(groupId: ObjectId, userId: UserId) {
    return this.post(this.GROUPS_REMOVE_USER(groupId), qs.stringify({ user: userId }));
  }

  /**
   * give group onwer permission to user
   */
  giveGroupPermission(groupId: ObjectId, userId: UserId) {
    const data = {
      code: 'owner',
      user: userId,
    };
    return this.post(this.GROUPS_GIVE_PERMISSION(groupId), qs.stringify(data));
  }

  /**
   * remove group onwer permission to user
   * @param {*} groupId
   * @param {*} userId
   */
  removeGroupPermission(groupId: ObjectId, userId: UserId) {
    const data = {
      code: 'owner',
      user: userId,
    };
    return this.post(this.GROUPS_REMOVE_PERMISSION(groupId), qs.stringify(data));
  }

  getGroupAutocomplete(query: string) {
    return this.get<
      paths['/api/group/autocomplete']['get']['responses']['200']['content']['application/json']
    >(this.GROUP_AUTOCOMPLETE(query));
  }
}
