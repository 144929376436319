import { PageViewport } from 'pdfjs-dist';
import { NODE } from './AnnotationContext';

const MIN_HEIGHT = 20;
const MIN_WIDTH = 20;

type Box = {
  height: number;
  width: number;
  left: number;
  bottom: number;
};

function annotationHasQuadPoints(
  annotation: PDF.Annotation,
): annotation is PDF.Annotation.TextMarkup | PDF.Annotation.Task {
  return (
    annotation.subtype === 'Highlight' ||
    annotation.subtype === 'Underline' ||
    annotation.subtype === 'StrikeOut' ||
    annotation.subtype === 'Task'
  );
}

export function getBoxesFromQuadPoints(annotation: PDF.Annotation, scale: number) {
  let data: Box[] = [];
  if (annotationHasQuadPoints(annotation) && annotation.quadPoints) {
    data = annotation.quadPoints.map(({ topLeft, topRight, bottomRight, bottomLeft }) => {
      // we are subtracting rect[0] and rect[1] from left and bottom respectively to get the quadpoint
      // left and bottom value relative to the annotation bounding rect
      const left = (topLeft.x - annotation.rect.left) * scale;
      const bottom = (bottomRight.y - annotation.rect.bottom) * scale;
      const width = (topRight.x - topLeft.x) * scale;
      const height = (topLeft.y - bottomRight.y) * scale;
      return { height, width, left, bottom };
    });
  }
  return data;
}

export function getBoxFromAnnotation(
  annotation: PDF.Annotation,
  scale: number,
): PDF.Annotation.Rect {
  switch (annotation.subtype) {
    case 'Line': {
      const height =
        Math.abs(annotation.lineCoordinates.start.y - annotation.lineCoordinates.end.y) * scale;
      const width =
        Math.abs(annotation.lineCoordinates.start.x - annotation.lineCoordinates.end.x) * scale;
      const left =
        Math.min(annotation.lineCoordinates.start.x, annotation.lineCoordinates.end.x) * scale;
      const bottom =
        Math.min(annotation.lineCoordinates.start.y, annotation.lineCoordinates.end.y) * scale;
      const right = left + width;
      const top = bottom + height;
      return { height, width, left, bottom, right, top };
    }
    default: {
      const height = annotation.rect.height * scale;
      const width = annotation.rect.width * scale;
      const left = annotation.rect.left * scale;
      const bottom = annotation.rect.bottom * scale;
      const right = left + width;
      const top = bottom + height;

      return { height, width, left, bottom, right, top };
    }
  }
}

export function getPointsFromVertices(
  annotation: PDF.Annotation.PolyLine | PDF.Annotation.Polygon,
  scale: number,
) {
  if (annotation.vertices) {
    return annotation.vertices.map((points) => {
      const x = (points.x - annotation.rect.left) * scale;
      const y = (annotation.rect.height - (points.y - annotation.rect.bottom)) * scale;

      return { x, y };
    });
  }
  return [];
}

export function getPathsFromInkLists(
  annotation: PDF.Annotation.Ink,
  scale: number,
  box: PDF.Annotation.Rect,
) {
  let data: string[] = [];

  const xRatio = box.width / (annotation.rect.width * scale);
  const yRatio = box.height / (annotation.rect.height * scale);

  if (annotation.inkLists) {
    data = annotation.inkLists.map((list) =>
      getSVGBezierPath(
        list
          // .filter((point, index) => index % 5 === 0)
          .map((point) => ({
            x: (point.x - annotation.rect.left) * scale * xRatio,
            y: (annotation.rect.height - (point.y - annotation.rect.bottom)) * scale * yRatio,
          })),
      ),
    );
  }
  return data;
}

export function calculateRelativeCoordinates(
  div: HTMLDivElement,
  clientX: number,
  clientY: number,
  scale: number,
): PDF.Annotation.Rect | null {
  const rect = div.getBoundingClientRect();

  // relative from screen to element the element.

  const top = (clientY - rect.top) / scale;
  const right = (rect.left + rect.width - clientX) / scale;
  const bottom = (rect.height - clientY + rect.top) / scale; //y position within the element.
  const left = (clientX - rect.left) / scale;

  const height = Math.abs(top - bottom);
  const width = Math.abs(right - left);

  return { top, right, bottom, left, height, width };
}

export function getPathsForSVG(
  annotation: PDF.Annotation,
  viewport: PageViewport,
  box: PDF.Annotation.Rect,
) {
  const stroke = annotation.color?.stroke;
  const strokeWidth = (annotation.border?.width || 1) * viewport.scale;
  const fill = annotation.color?.fill || 'transparent';

  switch (annotation.subtype) {
    case 'Circle': {
      const x1 = strokeWidth / 2;
      const y1 = strokeWidth / 2;

      const cx = box.width / 2;
      const cy = box.height / 2;

      const rx = cx - strokeWidth / 2;
      const ry = cy - strokeWidth / 2;

      const mx = x1;
      const my = y1 + (box.height - strokeWidth) / 2;

      return [
        {
          d: `
            M ${mx} ${my}
            a ${rx} ${ry} 0 0 1 ${box.width - strokeWidth} 0
            a ${rx} ${ry} 0 0 1 -${box.width - strokeWidth} 0
          `,
          stroke,
          strokeWidth,
          fill,
        },
      ];
    }
    case 'Ink': {
      const paths = getPathsFromInkLists(annotation, viewport.scale, box);
      // const paths = svgPath(
      //   annotation.inkLists[0]
      //     .filter((_, i) => i % 5 === 0)
      //     .map((coords) => [coords.x * scale, (pageHeight - coords.y) * scale]),
      //   bezierCommand,
      // );
      return paths.map((path) => ({ d: path, stroke, strokeWidth, fill }));
    }
    case 'Line': {
      const paths = [];
      if (annotation.lineCoordinates) {
        const x1 = (annotation.lineCoordinates.start.x - annotation.rect.left) * viewport.scale;
        const y1 = (annotation.rect.top - annotation.lineCoordinates.start.y) * viewport.scale;
        const x2 = (annotation.lineCoordinates.end.x - annotation.rect.left) * viewport.scale;
        const y2 = (annotation.rect.top - annotation.lineCoordinates.end.y) * viewport.scale;

        paths.push({
          d: `
              M ${x1} ${y1}
              L ${x2} ${y2}
            `,
          stroke,
          strokeWidth,
          fill,
        });

        if (annotation.lineEndings?.end === 'OpenArrow') {
          const difX = x2 - x1;
          const difY = y2 - y1;
          const angle = Math.atan2(difY, difX);

          const headlen = 50;

          const a1 = {
            x: x2 - headlen * Math.cos(angle - Math.PI / 6),
            y: y2 - headlen * Math.sin(angle - Math.PI / 6),
          };

          const a2 = {
            x: x2 - headlen * Math.cos(angle + Math.PI / 6),
            y: y2 - headlen * Math.sin(angle + Math.PI / 6),
          };

          paths.push({
            d: `
                  M ${x2} ${y2}
                  L ${a1.x} ${a1.y}
                 `,

            stroke,
            strokeWidth,
            fill,
          });
          paths.push({
            d: `
                  M ${x2} ${y2}
                  L ${a2.x} ${a2.y}
                 `,

            stroke,
            strokeWidth,
            fill,
          });
        }
        return paths;
      }
      break;
    }
    case 'PolyLine':
    case 'Polygon': {
      const points = getPointsFromVertices(annotation, viewport.scale);
      let d = points
        .map(({ x, y }, index) => {
          if (index === 0) {
            return `M ${x} ${y}`;
          }
          return `L ${x} ${y}`;
        })
        .join(' ');
      // Polygons end where they start
      if (annotation.subtype === 'Polygon' && points.length > 1) {
        d += ' Z';
      }
      return [
        {
          d,
          stroke,
          strokeWidth,
          fill,
        },
      ];
    }
    case 'Square': {
      const x = strokeWidth / 2;
      const y = strokeWidth / 2;
      const width = box.width - strokeWidth;
      const height = box.height - strokeWidth;

      const startingPoint = `M ${x} ${y}`;
      const topLine = `h ${width}`;
      const rightLine = `v ${height}`;
      const bottomLine = `h -${width}`;
      const leftLine = 'Z'; // Z connects the current place to the start

      const d = `${startingPoint} ${topLine} ${rightLine} ${bottomLine} ${leftLine}`;

      return [
        {
          d,
          stroke,
          strokeWidth,
          fill,
        },
      ];
    }
  }
}

export function getPathsForSVGCreation(
  shape: PDF.Annotation.ShapeType,
  start: PDF.Annotation.Point,
  end: PDF.Annotation.Point,
  viewport: PageViewport,
  inkList: PDF.Annotation.Point[][],
) {
  switch (shape) {
    case 'Line': {
      const x1 = start.x * viewport.scale;
      const y1 = start.y * viewport.scale;

      const x2 = Math.max(0, Math.min(viewport.width, end.x * viewport.scale));
      const y2 = Math.max(0, Math.min(viewport.height, end.y * viewport.scale));
      return [
        `
      M ${x1} ${y1}
      L ${x2} ${y2}
      `,
      ];
    }
    case 'Arrow': {
      const x1 = start.x * viewport.scale;
      const y1 = start.y * viewport.scale;

      const x2 = Math.max(0, Math.min(viewport.width, end.x * viewport.scale));
      const y2 = Math.max(0, Math.min(viewport.height, end.y * viewport.scale));

      const difX = x2 - x1;
      const difY = y2 - y1;
      const angle = Math.atan2(difY, difX);

      const headlen = 50;

      const a1 = {
        x: x2 - headlen * Math.cos(angle - Math.PI / 6),
        y: y2 - headlen * Math.sin(angle - Math.PI / 6),
      };

      const a2 = {
        x: x2 - headlen * Math.cos(angle + Math.PI / 6),
        y: y2 - headlen * Math.sin(angle + Math.PI / 6),
      };

      return [
        `
        M ${x1} ${y1}
        L ${x2} ${y2}`,
        `
        M ${x2} ${y2}
        L ${a1.x} ${a1.y}`,
        `
        M ${x2} ${y2}
        L ${a2.x} ${a2.y}
      `,
      ];
    }
    case 'Circle': {
      const x1 = start.x * viewport.scale;
      const y1 = start.y * viewport.scale;

      const x2 = end.x * viewport.scale;
      const y2 = end.y * viewport.scale;

      const width = Math.abs(end.x - start.x) * viewport.scale;
      const height = Math.abs(end.y - start.y) * viewport.scale;

      const cx = width / 2;
      const cy = height / 2;

      const rx = cx;
      const ry = cy;

      const mx = end.x - start.x >= 0 ? x1 : x2;
      const my = end.y - start.y >= 0 ? y1 + height / 2 : y2 + height / 2;

      return [
        `
            M ${mx} ${my}
            a ${rx} ${ry} 0 0 1 ${width} 0
            a ${rx} ${ry} 0 0 1 -${width} 0
        `,
      ];
    }
    case 'Square': {
      const x1 = start.x * viewport.scale;
      const y1 = start.y * viewport.scale;
      const x2 = end.x * viewport.scale;
      const y2 = end.y * viewport.scale;

      const width = Math.abs(x2 - x1);
      const height = Math.abs(y2 - y1);

      const startingPoint = `M ${x2 - x1 >= 0 ? x1 : x2} ${y2 - y1 >= 0 ? y1 : y2}`;
      const topLine = `h ${width}`;
      const rightLine = `v ${height}`;
      const bottomLine = `h -${width}`;
      const leftLine = 'Z'; // Z connects the current place to the start

      return [`${startingPoint} ${topLine} ${rightLine} ${bottomLine} ${leftLine}`];
    }
    case 'Ink': {
      let data: string[] = [];
      if (inkList) {
        return inkList.map((list) =>
          list
            .map(({ x, y }, index) => {
              const command = index === 0 ? 'M' : 'L';
              const coords = `${x * viewport.scale}, ${y * viewport.scale}`;
              const point = `${command} ${coords}`;

              return point;
            })
            .join(' '),
        );
      }
      return data;
    }
    default:
      return [];
  }
}

export function getResizeValues(
  box: PDF.Annotation.Rect,
  node: NODE,
  mouse: PDF.Annotation.Point,
  viewport: PageViewport,
) {
  switch (node) {
    case 'nw': {
      const deltaWidth = Math.max(-(box.width - MIN_WIDTH), box.left - mouse.x);
      const deltaHeight = Math.max(-(box.height - MIN_HEIGHT), mouse.y - box.top);

      const width = box.width + deltaWidth;
      const height = Math.min(box.height + deltaHeight, viewport.height - box.bottom);
      const left = Math.max(0, box.left - deltaWidth);

      return { ...box, width, height, left };
    }
    case 'n': {
      const deltaHeight = Math.max(-(box.height - MIN_HEIGHT), mouse.y - box.top);

      const height = Math.max(
        MIN_HEIGHT,
        Math.min(box.height + deltaHeight, viewport.height - box.bottom),
      );

      return { ...box, height };
    }
    case 'ne': {
      const deltaWidth = Math.max(-(box.width - MIN_WIDTH), mouse.x - box.right);
      const deltaHeight = Math.max(-(box.height - MIN_HEIGHT), mouse.y - box.top);

      const width = Math.min(box.width + deltaWidth, viewport.width - box.left);
      const height = Math.min(box.height + deltaHeight, viewport.height - box.bottom);

      return { ...box, width, height };
    }
    case 'e': {
      const deltaWidth = Math.max(-(box.width - MIN_WIDTH), mouse.x - box.right);

      const width = Math.min(box.width + deltaWidth, viewport.width - box.left);

      return { ...box, width };
    }
    case 'se': {
      const deltaWidth = Math.max(-(box.width - MIN_WIDTH), mouse.x - box.right);
      const deltaHeight = Math.max(-(box.height - MIN_HEIGHT), box.bottom - mouse.y);

      const width = Math.min(box.width + deltaWidth, viewport.width - box.left);
      const height = box.height + deltaHeight;
      const bottom = Math.max(0, box.bottom - deltaHeight);

      return { ...box, width, bottom, height };
    }
    case 's': {
      const deltaHeight = Math.max(-(box.height - MIN_HEIGHT), box.bottom - mouse.y);

      const height = box.height + deltaHeight;
      const bottom = Math.max(0, box.bottom - deltaHeight);

      return { ...box, bottom, height };
    }

    case 'sw': {
      const deltaWidth = Math.max(-(box.width - MIN_WIDTH), box.left - mouse.x);
      const deltaHeight = Math.max(-(box.height - MIN_HEIGHT), box.bottom - mouse.y);

      const width = box.width + deltaWidth;
      const height = box.height + deltaHeight;
      const bottom = Math.max(0, box.bottom - deltaHeight);
      const left = Math.max(0, box.left - deltaWidth);

      return { ...box, width, height, left, bottom };
    }
    case 'w': {
      const deltaWidth = Math.max(-(box.width - MIN_WIDTH), box.left - mouse.x);

      const width = box.width + deltaWidth;
      const left = Math.max(0, box.left - deltaWidth);

      return { ...box, width, left };
    }
  }
}

const lineProperties = (pointA: PDF.Annotation.Point, pointB: PDF.Annotation.Point) => {
  const lengthX = pointB.x - pointA.x;
  const lengthY = pointB.y - pointA.y;
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
};

const controlPointCalc = (
  current: PDF.Annotation.Point,
  previous: PDF.Annotation.Point,
  next: PDF.Annotation.Point,
  reverse?: boolean,
) => {
  const c = current;
  const p = previous ? previous : c;
  const n = next ? next : c;
  const smoothing = 0.2;
  const o = lineProperties(p, n);
  const rev = reverse ? Math.PI : 0;

  const x = c.x + Math.cos(o.angle + rev) * o.length * smoothing;
  const y = c.y + Math.sin(o.angle + rev) * o.length * smoothing;

  return { x, y };
};

const getSVGBezierPath = (points: PDF.Annotation.Point[]) => {
  const d = points.reduce((acc, e, i, a) => {
    if (i > 0) {
      const cs = controlPointCalc(a[i - 1], a[i - 2], e);
      const ce = controlPointCalc(e, a[i - 1], a[i + 1], true);
      return `${acc} C ${cs.x},${cs.y} ${ce.x},${ce.y} ${e.x},${e.y}`;
    } else {
      return `${acc} M ${e.x},${e.y}`;
    }
  }, '');

  return d;
};
