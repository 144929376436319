import { DocumentModel } from '_common/services/Realtime';
import {
  CitationsLibrary,
  DocumentCitations,
  NodeModel,
  Structure,
  Template,
  Numbering,
  Cmapps,
  Section,
  Comment,
  Task,
  Notes,
  Suggestion,
} from '../../models';

export const TYPE_NAME = {
  DOCUMENT: 'DOCUMENT',
  STRUCTURE: 'STRUCTURE',
  CITATIONS_LIBRARY: 'CITATIONS_LIBRARY',
  DOCUMENT_CITATIONS: 'DOCUMENT_CITATIONS',
  TEMPLATE: 'TEMPLATE',
  NODE: 'NODE',
  NUMBERING: 'NUMBERING',
  CMAPPS: 'CMAPPS',
  SECTION: 'SECTION',
  COMMENT: 'COMMENT',
  TASK: 'TASK',
  SUGGESTION: 'SUGGESTION',
  NOTES: 'NOTES',
  SELECTION: 'SELECTION',
} as const;
export interface Types {
  [TYPE_NAME.DOCUMENT]: DocumentModel;
  [TYPE_NAME.STRUCTURE]: Structure;
  [TYPE_NAME.CITATIONS_LIBRARY]: CitationsLibrary;
  [TYPE_NAME.DOCUMENT_CITATIONS]: DocumentCitations;
  [TYPE_NAME.TEMPLATE]: Template;
  [TYPE_NAME.NODE]: NodeModel;
  [TYPE_NAME.NUMBERING]: Numbering;
  [TYPE_NAME.CMAPPS]: Cmapps;
  [TYPE_NAME.SECTION]: Section;
  [TYPE_NAME.COMMENT]: Comment;
  [TYPE_NAME.TASK]: Task;
  [TYPE_NAME.NOTES]: Notes;
  [TYPE_NAME.SUGGESTION]: Suggestion;
  [TYPE_NAME.SELECTION]: Editor.Data.Selection.Model;
}
export type TypeName = keyof Types;

export type IndexableElementType = Omit<Types, 'DOCUMENT' | 'TEMPLATE'>;
export type IndexableElementTypeName = keyof IndexableElementType;
export type IndexableType = IndexableElementType[keyof IndexableElementType];
