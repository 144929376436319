import { useMemo, useEffect } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { selectCollaboratorsAndCommentsAuthors } from 'Editor/redux/EditorStatusSlice';

import { FilterDisplay, FilterDrawer } from '_common/components';
import { UserOption } from '_common/components/SearchUser/SearchUser';

import CommentList from './CommentList/CommentList';
import HideComments from './HideComments/HideComments';
import CommentButtons from './CommentButtons/CommentButtons';
import { completeAction } from 'App/redux/onboardingSlice';

const CommentTab = () => {
  const dispatch = useDispatch();

  const collaborators = useSelector(selectCollaboratorsAndCommentsAuthors);
  const collaboratorOptions = useMemo(
    () =>
      collaborators.map(
        (collaborator) =>
          ({
            id: collaborator.id,
            type: 'user',
            imported: collaborator.imported,
          } as UserOption),
      ),
    [collaborators],
  );

  useEffect(() => {
    dispatch(completeAction('editor_comments_openSidePanel'));
  }, []);

  return (
    <>
      <CommentButtons />
      <FilterDrawer
        identity="editorCommentPanel"
        cardPriority={{
          options: [
            { value: 'LOW', label: 'editor.sidebar.review.filter.priority.low' },
            { value: 'MEDIUM', label: 'editor.sidebar.review.filter.priority.medium' },
            { value: 'HIGH', label: 'editor.sidebar.review.filter.priority.high' },
          ],
        }}
        author={{ options: collaboratorOptions, settings: { editorAvatar: true } }}
      />
      <HideComments />
      <FilterDisplay identity="editorCommentPanel" direction="column" margin="0 0 2rem 0" />
      <CommentList />
    </>
  );
};

export default CommentTab;
