import { BaseOperation } from '../BaseOperation';

export class ParseFigureForParagaphOperation extends BaseOperation {
  constructor(baseModel: Editor.Data.Node.Model) {
    super(baseModel);

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    let level0Data = this.model.get();
    let op = this.getObjectOperationforPathValue(level0Data.type, 'p', ['type']);
    if (op) {
      this.ops.push(op);
    }

    return this;
  }
}
