import { SectionHeading } from 'dodoc-design-system';
import React from 'react';
import { useIntl } from 'react-intl';

type EditMetadataHeadingProps = {
  option: {
    label: string;
    metadataOptions: {
      label: string;
      value: string;
    }[];
    id: string;
  };
  onEdit: () => void;
  testId: string;
};

const EditMetadataHeading = ({ option, onEdit, testId }: EditMetadataHeadingProps) => {
  const intl = useIntl();

  return (
    <SectionHeading
      title={intl.formatMessage({ id: option.label })}
      size="large"
      buttonRightProps={{
        size: 'medium',
        children: intl.formatMessage({ id: 'EDIT_METADATA' }),
        onClick: onEdit,
        testId: 'edit-metadata',
      }}
      testId={testId}
    />
  );
};

export default EditMetadataHeading;
