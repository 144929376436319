import { Mixin } from 'mixwith';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorRange, EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class TabHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       * selects a function to handle tab based on baseNode
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleTabOnCollapsedSelection(
        actionContext,
        e,
        baseNode,
        anchorNode,
        anchorOffset,
        blockSelection = false,
      ) {
        if (!baseNode || EditorDOMElements.isNodeContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        if (baseNode) {
          if (anchorNode) {
            if (
              baseNode.tagName === ELEMENTS.ParagraphElement.TAG &&
              this.dataManager.numbering.isListElement(baseNode.id)
            ) {
              // SELECTION IS A LIST
              this.handleTabOnListElement(actionContext, e, baseNode, anchorNode, blockSelection);
            } else if (EditorDOMUtils.isClosestTextElementEditable(baseNode)) {
              // SELECTION IS A DEFAULT TEXT ELEMENT
              this.handleTabOnTextElement(
                actionContext,
                e,
                baseNode,
                anchorNode,
                anchorNode,
                blockSelection,
              );
            } else if (!EditorDOMUtils.isClosestBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this.handleTabOnTableElement(actionContext, e, baseNode, anchorNode, blockSelection);
            } else if (EditorDOMElements.isNodeContainerElement(baseNode)) {
              this.handleTabOnContainerElement(
                actionContext,
                e,
                baseNode,
                anchorNode,
                anchorOffset,
                blockSelection,
              );
            }
          }
        }
      }

      /**
       * handle delete on multi selection
       * @param {ActionContext} actionContext
       * @param {Event} e
       */
      handleTabOnMultiSelection(actionContext, e) {
        let range = EditorSelectionUtils.getRange();

        const startBlock = EditorDOMUtils.findFirstLevelChildNode(this.page, range.startContainer);

        if (
          (range.commonAncestorContainer === this.page &&
            EditorSelectionUtils.isSelectionAtStart(startBlock)) ||
          (EditorSelectionUtils.isSelectionAtStart(startBlock) &&
            EditorSelectionUtils.isSelectionAtEnd(startBlock))
        ) {
          // multiple block nodes or whole block selected

          const elements = EditorRange.filterBlocksFromRange(
            range,
            true,
            EditorDOMElements.BLOCK_ELEMENTS,
          );

          let i;
          for (i = 0; i < elements.length; i++) {
            this.handleTabOnCollapsedSelection(actionContext, e, elements[i], elements[i], 0, true);
          }
        } else {
          this.handleTabOnCollapsedSelection(
            actionContext,
            e,
            startBlock,
            range.startContainer,
            range.startOffset,
          );
        }
      }

      handleTabOnContainerElement(
        actionContext,
        e,
        baseNode,
        anchorNode,
        anchorOffset,
        blockSelection,
      ) {
        if (EditorDOMElements.BLOCK_CONTAINER_ELEMENTS.includes(anchorNode.tagName)) {
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        const subLevel0Node = EditorDOMUtils.findFirstLevelChildNode(baseNode, anchorNode);
        if (subLevel0Node) {
          this.handleTabOnCollapsedSelection(
            actionContext,
            e,
            subLevel0Node,
            anchorNode,
            anchorOffset,
            blockSelection,
          );
        }
      }

      /**
       * handle tab event on a default text element
       * @param {ActionContext} actionContext
       * @param {Node} level0Node
       * @param {Node} textElementNode
       */
      handleTabOnTextElement(
        actionContext,
        e,
        level0Node,
        textElementNode,
        anchorNode,
        blockSelection,
      ) {
        if (blockSelection) {
          if (e.shiftKey) {
            level0Node.outdent();
          } else {
            level0Node.indent();
          }
          actionContext.addChangeUpdatedNode(level0Node);
        } else {
          if (this.joinSelectionContent(actionContext)) {
            EditorSelectionUtils.fixCollapsedTextSelection();

            const tab = DOMElementFactory.buildElement(ELEMENTS.TabElement.TAG);
            this.insertInlineNode(actionContext, tab);
            this.visualizerManager.Visualizer.tabulator.tabulate(level0Node.vm);
          }
        }
      }

      /**
       * handle tab event on list elements
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} listNode
       * @param {Node} anchorNode
       */
      handleTabOnListElement(actionContext, e, listNode, anchorNode, blockSelection) {
        if (EditorSelectionUtils.isSelectionAtStart(listNode) || blockSelection) {
          if (e.shiftKey) {
            this.stylesHandler.decreaseIndent(actionContext);
          } else {
            this.stylesHandler.increaseIndent(actionContext);
          }
        } else {
          // insert tab
          this.handleTabOnTextElement(
            actionContext,
            e,
            listNode,
            anchorNode,
            anchorNode,
            blockSelection,
          );
        }
      }

      /**
       * handle tab event on table element
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} tableNode
       * @param {Node} anchorNode
       */
      handleTabOnTableElement(actionContext, e, tableNode, anchorNode) {
        const td = EditorDOMUtils.closest(anchorNode, ELEMENTS.TableCellElement.TAG);
        if (td) {
          const tbody = EditorDOMUtils.closest(
            anchorNode,
            ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG,
          );
          const tds = Array.prototype.slice.call(
            tbody.querySelectorAll(ELEMENTS.TableCellElement.TAG),
          );
          let index = tds.indexOf(td);
          let next;
          let addRow = false;
          while (!next) {
            if (e.shiftKey) {
              index = index > 0 ? index - 1 : 0;
            } else {
              index = index + 1;
            }

            if (index < tds.length) {
              if (tds[index].style.display !== 'none') {
                next = tds[index];
              }
            } else {
              addRow = true;
              break;
            }
          }

          if (!addRow) {
            if (!EditorDOMUtils.isEmptyElement(next)) {
              EditorSelectionUtils.selectNodeContents(next);
            } else {
              EditorSelectionUtils.setCaret(next, 'INSIDE_START');
            }
          } else {
            // insert new row
            this.insertRowOperation(
              actionContext,
              EditorDOMUtils.findFirstLevelChildNode(this.page, tableNode),
              tbody.parentNode, // closest table
              [td],
              false,
            );

            setTimeout(() => {
              const parentRow = td.parentNode;
              if (parentRow.nextSibling) {
                EditorSelectionUtils.setCaret(parentRow.nextSibling.firstChild, 'INSIDE_START');
              }
            }, 0);
          }
        }
      }
    },
);
