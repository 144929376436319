import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { OnboardingIntegrationCardsProps } from 'dodoc-design-system/build/types/Components/OnboardingIntegrationCards/OnboardingIntegrationCards';

import { useDispatch, useSelector } from '_common/hooks';
import { components } from '_types/authority';

import { EditorActions, EditorInteractions, setInitialTaskValues } from 'App/redux/onboardingSlice';
import { setSidebarPanelTab } from 'Editor/redux/SidebarSlice';
import { setSidebarView } from 'Editor/redux/SidebarSlice';
import EditorManager from 'Editor/services/EditorManager/EditorManager';
import useDocumentParagraph from './useDocumentParagraph';
import { dayjs, newRTEObject } from 'utils';
import { GHOST_USERS } from '_common/services/api/publicProfilesApi';
import { setTaskOverlayData } from 'Editor/redux/TasksSlice';
import { setSelection } from 'Editor/redux/EditorStatusSlice';
import { setDocumentTracking } from 'Editor/redux/TrackingSlice';

type VisibleSpace = OnboardingIntegrationCardsProps['cards'][number]['visibleSpace'];

export type ExtendedCard = OnboardingIntegrationCardsProps['cards'][number] & {
  deps?: EditorActions[];
  interactions?: EditorInteractions[];
  dynamicPulse?: {
    position: OnboardingIntegrationCardsProps['cards'][number]['pulse'];
    targetAction: EditorActions;
  }[];
  //After refresh run callback of card closest to initialCard
  onSkip?: () => void;
  //On active card change, will run callback of new card only once
  onFirstRender?: () => void;
  //On active card change, will run callback of new card
  before?: () => void;
  id: components['schemas']['EditorOnboardingSteps'];
  pulseTarget?: (
    | 'taskDescription'
    | 'taskAssigned'
    | 'taskDueDate'
    | 'commentReply'
    | 'document_paragraph_selectFont'
    | 'document_paragraph_selectHeading'
    | 'document_suggestion_add'
    | 'document_suggestion_delete'
    | 'document_comment'
    | 'document_task'
    | 'contextMenu_task'
    | 'contextMenu_comment'
  )[];
  boxTarget?: (
    | 'document_paragraph_editedText'
    | 'document_paragraph_deletedText'
    | 'sidebar_taskPanel_header'
    | 'card_comment'
    | 'card_task'
  )[];
};

export type Journey = 'general' | 'writer' | 'reviewer' | 'limitedReviewer';

const useCards = (journey?: Journey) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const getDocumentParagraphs = useDocumentParagraph();

  const objectId = useSelector((state) => state.editor.status.documentId);
  const currentUser = useSelector((state) => state.auth.userId);
  const trackingState = useSelector((state) => state.editor.tracking[currentUser]?.[objectId]);
  const hasComments = useSelector((state) => !!state.editor.comments.order.length);
  const task = useSelector((state) => state.editor.tasks.order[0]);

  const visibleSpace: Record<
    'all' | 'toolbar' | 'sidebar' | 'sidepanel',
    NonNullable<VisibleSpace>[number]
  > = useMemo(
    () => ({
      all: {
        top: '0px',
        height: '100%',
        left: '0px',
        width: '100%',
      },
      toolbar: {
        top: '104px',
        height: `40px`,
        left: '0px',
        width: '100%',
      },
      sidebar: {
        top: '144px',
        height: `calc(100% - 184px)`,
        right: '0',
        width: '48px',
      },
      sidepanel: {
        top: '144px',
        height: `calc(100% - 184px)`,
        right: '48px',
        width: '376px',
      },
    }),
    [],
  );

  const getProgress = (totalPhases: number, phase: number, phasePercent: number) => {
    const progressBarWidth = 344;
    const spacingWidth = 4;

    //Onboarding split progress bars are not all the same size, so static values are used instead
    let firstPhaseWidth;
    let otherPhasesWidth;
    switch (totalPhases) {
      case 4:
        firstPhaseWidth = 86;
        otherPhasesWidth = 82;
        break;
      case 3:
        firstPhaseWidth = 115;
        otherPhasesWidth = 110;
        break;
      default: //2
        firstPhaseWidth = 172;
        otherPhasesWidth = 168;
        break;
    }

    const previousPhaseFiller =
      phase > 1
        ? firstPhaseWidth + spacingWidth + (phase - 2) * (otherPhasesWidth + spacingWidth)
        : 0;
    const currentPhaseFiller = Math.max(
      ((phase === 1 ? firstPhaseWidth : otherPhasesWidth) * phasePercent) / 100,
      8,
    );

    return ((previousPhaseFiller + currentPhaseFiller) * 100) / progressBarWidth;
  };

  const enableDocumentTracking = () => {
    dispatch(
      setDocumentTracking({
        ...trackingState,
        state: true,
        userId: currentUser,
        documentId: objectId,
      }),
    );
  };

  const informativeCards = useMemo<ExtendedCard[]>(() => {
    //#region General - Information cards
    const generalWritingCards: ExtendedCard[] = [
      {
        id: 'writing_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_WRITING_INTRODUCTION' }),
        currentPhase: 1,
        progress: 2.33,
        visibleSpace: [visibleSpace.all],
      },
      {
        id: 'writing_toolbar',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_WRITING_TOOLBAR' }),
        phaseTitle: intl.formatMessage({ id: 'INTRODUCTION_TO_THE_TOOLBAR' }),
        currentPhase: 1,
        subPhase: 1,
        progress: 11.9,
        showPhases: false,
        visibleSpace: [visibleSpace.toolbar],
        box: {
          top: '103px',
          height: '40px',
          width: '100%',
        },
      },
      {
        id: 'writing_completed',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_WRITING_COMPLETED' }),
        phaseTitle: intl.formatMessage({ id: 'INTRODUCTION_TO_THE_TOOLBAR' }),
        currentPhase: 1,
        subPhase: 1,
        progress: 25,
        phaseCompleted: 1,
      },
    ];

    const generalSuggestionCards: ExtendedCard[] = [
      {
        id: 'suggestion_description',
        description: intl.formatMessage({
          id: 'ONBOARDING_EDITOR_GENERAL_SUGGESTION_INTRODUCTION',
        }),
        currentPhase: 2,
        progress: 27.62,
        before: enableDocumentTracking,
        onSkip: enableDocumentTracking,
      },
      {
        id: 'suggestion_addText',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_SUGGESTION_EDIT' }),
        phaseTitle: intl.formatMessage({ id: 'PROPOSE_CHANGES' }),
        currentPhase: 2,
        subPhase: 1,
        progress: 27.62,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        boxTarget: ['document_paragraph_editedText'],
        onFirstRender: () => {
          try {
            const paragraph = getDocumentParagraphs(3);
            if (paragraph) {
              const textNode = paragraph.childNodes[1].childNodes[0];

              //Make sure edition is enabled
              EditorManager.getInstance().setEditionMode();

              //Select text to be overwritten
              EditorManager.getInstance().setSelection(textNode, 81, textNode, 84);
              //Edit selected text
              EditorManager.getInstance().insertText('99e8');

              //Disable edition so user doesn't change document content
              EditorManager.getInstance().disableEditionCapabilities();
              //Make sure cursor disappears
              EditorManager.getInstance().clearSelection();
            }
          } catch (e) {}
        },
      },
      {
        id: 'suggestion_removeText',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_SUGGESTION_DELETE' }),
        phaseTitle: intl.formatMessage({ id: 'PROPOSE_CHANGES' }),
        currentPhase: 2,
        subPhase: 1,
        progress: 34.59,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        boxTarget: ['document_paragraph_deletedText'],
        before: () => {
          dispatch(setSidebarView(null));
        },
        onFirstRender: () => {
          try {
            const paragraph = getDocumentParagraphs(4);
            if (paragraph) {
              //Make sure edition is enabled
              EditorManager.getInstance().setEditionMode();

              //Select text to be overwritten
              EditorManager.getInstance().setSelection(paragraph, 0, paragraph, 2);
              //Delete from selection
              EditorManager.getInstance().removeText();

              //Disable edition so user doesn't change document content
              EditorManager.getInstance().disableEditionCapabilities();
              //Make sure cursor disappears
              EditorManager.getInstance().clearSelection();
            }
          } catch (e) {}
        },
      },
      {
        id: 'suggestion_location',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_SUGGESTION_LOCATION' }),
        phaseTitle: intl.formatMessage({ id: 'VISUALIZE_TRACK_CHANGES' }),
        currentPhase: 2,
        subPhase: 2,
        progress: 46.22,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        onSkip: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ tab: 'changes', view: 'review' }));
        },
        before: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ tab: 'changes', view: 'review' }));
        },
      },
      {
        id: 'suggestion_completed',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_SUGGESTION_COMPLETED' }),
        phaseTitle: intl.formatMessage({ id: 'VISUALIZE_TRACK_CHANGES' }),
        currentPhase: 2,
        subPhase: 2,
        progress: 50,
        phaseCompleted: 2,
        onSkip: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ tab: 'changes', view: 'review' }));
        },
        before: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ tab: 'changes', view: 'review' }));
        },
      },
    ];

    const generalCommentCards: ExtendedCard[] = [
      {
        id: 'comment_location',
        description: intl.formatMessage({
          id: 'ONBOARDING_EDITOR_GENERAL_COMMENT_LOCATION',
        }),
        currentPhase: 3,
        progress: 52.33,
        visibleSpace: [visibleSpace.sidepanel, visibleSpace.sidebar],
        box: {
          top: '212px',
          right: '248px',
          height: '32px',
          width: '160px',
        },
        onSkip: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ tab: 'comments', view: 'review' }));
        },
        before: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ tab: 'comments', view: 'review' }));
        },
      },
      {
        id: 'comment_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_COMMENT_DESCRIPTION' }),
        phaseTitle: intl.formatMessage({ id: 'VISUALIZE_COMMENTS' }),
        currentPhase: 3,
        subPhase: 1,
        progress: 62.5,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        onSkip: () => {}, //Nulify older onSkip
        before: async () => {
          const paragraph = getDocumentParagraphs(1);
          if (!hasComments && paragraph) {
            //Make sure edition is enabled
            EditorManager.getInstance().setEditionMode();

            //Select text to comment
            EditorManager.getInstance().setSelection(paragraph, 0, paragraph, 2);

            //Create a new comment to selection
            const commentId = await EditorManager.getInstance().createCommentWithOutTemp(
              newRTEObject({ text: intl.formatMessage({ id: 'THIS_LOOKS_PRETTY_GOOD' }) }),
            );

            //Disable edition so user doesn't change document content
            EditorManager.getInstance().disableEditionCapabilities();
            //Make sure cursor disappears
            EditorManager.getInstance().clearSelection();

            if (commentId) {
              //Reply to previously created comment
              EditorManager.getInstance().replyComment(
                commentId,
                newRTEObject({
                  text: intl.formatMessage({ id: 'WHATS_YOUR_OPINION_ON_THIS' }),
                  mention: { userId: GHOST_USERS.davidBean.id, placement: 'start' },
                }),
              );

              //Add a vote
              EditorManager.getInstance().voteComment(commentId, true);
            }
          }

          // Open reply
          const intervalId = setInterval(() => {
            const commentCardReplyToggle = document.getElementById('comment-card-reply-toggle');

            if (commentCardReplyToggle) {
              const toggleState = commentCardReplyToggle.getAttribute('data-value');
              if (!toggleState || toggleState === 'false') {
                commentCardReplyToggle.click();
              }
              clearInterval(intervalId);
            }
          }, 100);
        },
        boxTarget: ['card_comment'],
      },
      {
        id: 'comment_completed',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_COMMENT_COMPLETED' }),
        phaseTitle: intl.formatMessage({ id: 'VISUALIZE_COMMENTS' }),
        currentPhase: 3,
        subPhase: 1,
        progress: 75,
        phaseCompleted: 3,
        onSkip: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ tab: 'comments', view: 'review' }));
        },
        before: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ tab: 'comments', view: 'review' }));
        },
      },
    ];

    const generalTaskCards: ExtendedCard[] = [
      {
        id: 'task_location',
        description: intl.formatMessage({
          id: 'ONBOARDING_EDITOR_GENERAL_TASK_LOCATION',
        }),
        currentPhase: 4,
        progress: 77.62,
        visibleSpace: [visibleSpace.sidepanel, visibleSpace.sidebar],
        box: {
          top: '312px',
          right: '8px',
          height: '32px',
          width: '32px',
        },
        onSkip: () => {
          dispatch(setSidebarView('TASKS'));
          dispatch(setTaskOverlayData({ operation: 'view' }));
        },
        before: () => {
          dispatch(setSidebarView('TASKS'));
          dispatch(setTaskOverlayData({ operation: 'view' }));
        },
      },
      {
        id: 'task_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_TASK_DESCRIPTION' }),
        phaseTitle: intl.formatMessage({ id: 'CREATE_A_TASK' }),
        currentPhase: 4,
        subPhase: 1,
        progress: 77.62,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        onSkip: () => {}, //Nulify older onSkip
        before: () => {
          dispatch(
            setSelection({
              TASK: false,
            }),
          );
          dispatch(setSidebarView(null));

          const paragraph = getDocumentParagraphs(2);
          if (paragraph) {
            //Make sure edition is enabled
            EditorManager.getInstance().setEditionMode();

            // Select text to comment
            EditorManager.getInstance().setSelection(paragraph, 0, paragraph, 2);

            // Define initial values for temporary task card
            dispatch(
              setInitialTaskValues({
                description: 'Hey',
                assignee: GHOST_USERS.davidBean.id,
                dueDate: dayjs('2031-09-23T12:00:00.000Z').toISOString(),
              }),
            );

            // Open a new temporary task card
            EditorManager.getInstance().openCreateTaskOverlay();

            // Disable edition so user doesn't change document content
            EditorManager.getInstance().disableEditionCapabilities();
            // Make sure cursor disappears
            EditorManager.getInstance().clearSelection();

            // Blur input
            const intervalId = setInterval(() => {
              const temporaryTaskCard = document.getElementById('temporaryTaskCard');

              if (temporaryTaskCard) {
                // @ts-expect-error
                document.activeElement?.blur();
                window.blur();

                clearInterval(intervalId);
              }
            }, 100);
          }
        },
      },
      {
        id: 'task_card_visualize',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_TASK_CARD_DESCRIPTION' }),
        phaseTitle: intl.formatMessage({ id: 'VISUALIZE_TASKS' }),
        currentPhase: 4,
        subPhase: 2,
        progress: 86.05,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        before: () => {
          dispatch(setTaskOverlayData({ operation: 'view' }));
          const paragraph = getDocumentParagraphs(2);
          if (paragraph) {
            const paragraphId = paragraph.getAttribute('id');
            if (paragraphId && task) {
              dispatch(
                setSelection({
                  TASK: { blockId: paragraphId, tasks: [task] },
                  BLOCK_IDS: [paragraphId],
                }),
              );
            }
          }
        },
        onFirstRender: async () => {
          const paragraph = getDocumentParagraphs(2);
          if (!task && paragraph) {
            //Make sure edition is enabled
            EditorManager.getInstance().setEditionMode();

            // Select text to comment
            EditorManager.getInstance().setSelection(paragraph, 0, paragraph, 2);
            EditorManager.getInstance().clearSelection();
            // Create a new task card
            const taskId = await EditorManager.getInstance().createTask({
              user: currentUser,
              description: newRTEObject({ text: 'Hey' }),
              assignee: GHOST_USERS.davidBean.id,
              dueDate: dayjs('2031-09-23T12:00:00.000Z').toISOString(),
            });

            // Disable edition so user doesn't change document content
            EditorManager.getInstance().disableEditionCapabilities();
            //Make sure cursor disappears
            EditorManager.getInstance().clearSelection();

            const paragraphId = paragraph.getAttribute('id');
            if (paragraphId && taskId) {
              dispatch(
                setSelection({
                  TASK: { blockId: paragraphId, tasks: [taskId] },
                  BLOCK_IDS: [paragraphId],
                }),
              );
            }
          }
        },
        boxTarget: ['card_task'],
      },

      {
        id: 'task_completed',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_GENERAL_TASK_COMPLETED' }),
        phaseTitle: intl.formatMessage({ id: 'VISUALIZE_TASKS' }),
        currentPhase: 4,
        subPhase: 2,
        progress: 100,
        phaseCompleted: 4,
        before: () => {
          dispatch(
            setSelection({
              TASK: false,
            }),
          );
        },
      },
    ];
    //#endregion

    return [
      ...generalWritingCards,
      ...generalSuggestionCards,
      ...generalCommentCards,
      ...generalTaskCards,
    ];
  }, [hasComments, task]);

  const orderOffset = useMemo(() => {
    switch (journey) {
      case 'reviewer':
        return 1;
      case 'limitedReviewer':
        return 2;
      default:
        return 0;
    }
  }, [journey]);

  const writingCards = useMemo<ExtendedCard[]>(
    () => [
      {
        id: 'writing_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_WRITING_INTRODUCTION' }),
        currentPhase: 1 - orderOffset,
        progress: getProgress(4 - orderOffset, 1 - orderOffset, 0),
      },
      {
        id: 'writing_toolbar',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_WRITING_TOOLBAR' }),
        currentPhase: 1 - orderOffset,
        progress: getProgress(4 - orderOffset, 1 - orderOffset, 25),
        showPhases: false,
        visibleSpace: [visibleSpace.toolbar],
        box: {
          top: '103px',
          height: '40px',
          width: '100%',
        },
      },
      {
        id: 'writing_changeFont',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_WRITING_CHANGE_FONT' }),
        phaseTitle: intl.formatMessage({ id: 'CHANGE_THE_FONT' }),
        currentPhase: 1 - orderOffset,
        subPhase: 1,
        progress: getProgress(4 - orderOffset, 1 - orderOffset, 50),
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        interactions: ['editor_mainComponent_selectFont', 'editor_toolbar_font'],
        deps: ['editor_writing_font_select'],
        pulseTarget: ['document_paragraph_selectFont'],
      },
      {
        id: 'writing_changeOutline',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_WRITING_CHANGE_OUTLINE' }),
        tipValue: intl.formatMessage({ id: 'ONBOARDING_EDITOR_WRITING_CHANGE_OUTLINE_TIP' }),
        phaseTitle: intl.formatMessage({ id: 'CHANGE_HEADING_LEVELS' }),
        currentPhase: 1 - orderOffset,
        subPhase: 2,
        progress: getProgress(4 - orderOffset, 1 - orderOffset, 75),
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        interactions: ['editor_mainComponent_selectHeading', 'editor_toolbar_heading'],
        deps: ['editor_writing_heading_select'],
        pulseTarget: ['document_paragraph_selectHeading'],
        before: () => {
          const paragraph = getDocumentParagraphs(0);
          if (paragraph) {
            EditorManager.getInstance().setSelection(paragraph, 0, paragraph, 0);
          }
        },
      },
      {
        id: 'writing_completed',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_WRITING_COMPLETED' }),
        phaseTitle: intl.formatMessage({ id: 'CHANGE_HEADING_LEVELS' }),
        currentPhase: 1 - orderOffset,
        phaseCompleted: 1 - orderOffset,
        subPhase: 2,
        progress: getProgress(4 - orderOffset, 1 - orderOffset, 100),
      },
    ],
    [orderOffset],
  );

  const suggestionCards = useMemo<ExtendedCard[]>(
    () => [
      {
        id: 'suggestion_description',
        description: intl.formatMessage({
          id: 'ONBOARDING_EDITOR_TRACK_CHANGES_DESCRIPTION',
        }),
        currentPhase: 2 - orderOffset,
        progress: getProgress(4 - orderOffset, 2 - orderOffset, 0),
        visibleSpace: [visibleSpace.toolbar],
        box: {
          top: '110px',
          right: '42px',
          width: '139px',
          height: '28px',
        },
        before: enableDocumentTracking,
        onSkip: enableDocumentTracking,
      },
      {
        id: 'suggestion_addText',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TRACK_CHANGES_ADD_TEXT' }),
        tipValue: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TRACK_CHANGES_ADD_TEXT_TIP' }),
        phaseTitle: intl.formatMessage({ id: 'PROPOSE_CHANGES' }),
        currentPhase: 2 - orderOffset,
        subPhase: 1,
        progress: getProgress(4 - orderOffset, 2 - orderOffset, 25),
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        interactions: ['editor_mainComponent_addText'],
        deps: ['editor_suggestions_addText'],
        pulseTarget: ['document_suggestion_add'],
      },
      {
        id: 'suggestion_removeText',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TRACK_CHANGES_REMOVE_TEXT' }),
        phaseTitle: intl.formatMessage({ id: 'PROPOSE_CHANGES' }),
        currentPhase: 2 - orderOffset,
        subPhase: 1,
        progress: getProgress(4 - orderOffset, 2 - orderOffset, 50),
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        interactions: ['editor_mainComponent_deleteText'],
        deps: ['editor_suggestions_deleteText'],
        pulseTarget: ['document_suggestion_delete'],
      },
      {
        id: 'suggestion_location',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TRACK_CHANGES_LOCATION' }),
        phaseTitle: intl.formatMessage({ id: 'VISUALIZE_TRACK_CHANGES' }),
        currentPhase: 2 - orderOffset,
        subPhase: 2,
        progress: getProgress(4 - orderOffset, 2 - orderOffset, 75),
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        dynamicPulse: [
          {
            position: {
              top: '356px',
              right: '35px',
            },
            targetAction: 'editor_suggestions_openSidePanel',
          },
        ],
        deps: ['editor_suggestions_openSidePanel'],
        interactions: ['editor_sidepanel_review_suggestions'],
        before: () => dispatch(setSidebarView(null)),
      },
      {
        id: 'suggestion_completed',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TRACK_CHANGES_COMPLETED' }),
        phaseTitle: intl.formatMessage({ id: 'VISUALIZE_TRACK_CHANGES' }),
        currentPhase: 2 - orderOffset,
        phaseCompleted: 2 - orderOffset,
        subPhase: 2,
        progress: getProgress(4 - orderOffset, 2 - orderOffset, 100),
        onSkip: () => dispatch(setSidebarView('REVIEW')),
        before: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ view: 'review', tab: 'changes' }));
        },
      },
    ],
    [orderOffset],
  );

  const commentCards = useMemo<ExtendedCard[]>(
    () => [
      {
        id: 'comment_location',
        description: intl.formatMessage({
          id:
            journey === 'limitedReviewer'
              ? 'ONBOARDING_EDITOR_COMMENTS_LOCATION_LIMITED'
              : 'ONBOARDING_EDITOR_COMMENTS_LOCATION',
        }),
        currentPhase: 3 - orderOffset,
        progress: getProgress(4 - orderOffset, 3 - orderOffset, 0),
        visibleSpace:
          journey === 'limitedReviewer'
            ? [visibleSpace.sidebar]
            : [visibleSpace.sidepanel, visibleSpace.sidebar],
        deps: ['editor_comments_openSidePanel'],
        interactions:
          journey === 'limitedReviewer'
            ? ['editor_sidepanel_review_comments']
            : ['editor_tabmenu_comments'],
        dynamicPulse: [
          {
            position: {
              top: journey === 'limitedReviewer' ? '356px' : '234px',
              right: journey === 'limitedReviewer' ? '35px' : '316px',
            },
            targetAction: 'editor_comments_openSidePanel',
          },
        ],
        onSkip:
          journey === 'limitedReviewer'
            ? undefined
            : () => {
                dispatch(
                  setSelection({
                    COMMENT: [],
                  }),
                );
                dispatch(setSidebarView('REVIEW'));
                dispatch(setSidebarPanelTab({ view: 'review', tab: 'changes' }));
              },
        before:
          journey === 'limitedReviewer'
            ? () => {
                dispatch(setSidebarView(null));
              }
            : () => {
                dispatch(
                  setSelection({
                    COMMENT: [],
                  }),
                );
              },
      },
      {
        id: 'comment_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMMENTS_DESCRIPTION' }),
        currentPhase: 3 - orderOffset,
        progress: getProgress(4 - orderOffset, 3 - orderOffset, 25),
        visibleSpace: [visibleSpace.sidepanel, visibleSpace.sidebar],
        box: {
          top: '212px',
          right: '243px',
          width: '165px',
          height: '32px',
        },
        onSkip: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
        },
        before: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
        },
      },
      {
        id: 'comment_create',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMMENTS_CREATE' }),
        phaseTitle: intl.formatMessage({ id: 'CREATE_COMMENTS' }),
        currentPhase: 3 - orderOffset,
        subPhase: 1,
        progress: getProgress(4 - orderOffset, 3 - orderOffset, 50),
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        deps: ['editor_comments_createComment'],
        interactions: [
          'editor_mainComponent_addComment',
          'editor_contextMenu_createComment',
          'editor_sidepanel_newComment',
        ],
        pulseTarget: ['document_comment', 'contextMenu_comment'],
      },
      {
        id: 'comment_replyMention',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMMENTS_REPLY_WITH_MENTION' }),
        tipValue: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMMENTS_REPLY_WITH_MENTION_TIP' }),
        phaseTitle: intl.formatMessage({ id: 'ADD_A_REPLY' }),
        currentPhase: 3 - orderOffset,
        subPhase: 2,
        progress: getProgress(4 - orderOffset, 3 - orderOffset, 75),
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        deps: ['editor_comments_mentionInCommentReply'],
        interactions: ['editor_sidepanel_mentionComment'],
        pulseTarget: ['commentReply'],
      },
      {
        id: 'comment_completed',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMMENTS_COMPLETED' }),
        phaseTitle: intl.formatMessage({ id: 'ADD_A_REPLY' }),
        currentPhase: 3 - orderOffset,
        phaseCompleted: 3 - orderOffset,
        subPhase: 2,
        progress: getProgress(4 - orderOffset, 3 - orderOffset, 100),
        before: () => dispatch(setSidebarView('REVIEW')),
      },
    ],
    [journey, orderOffset],
  );

  const taskCards = useMemo<ExtendedCard[]>(
    () => [
      {
        id: 'task_location',
        description: intl.formatMessage({
          id: 'ONBOARDING_EDITOR_TASKS_LOCATION',
        }),
        currentPhase: 4 - orderOffset,
        progress: getProgress(4 - orderOffset, 4 - orderOffset, 0),
        visibleSpace: [visibleSpace.sidebar],
        deps: ['editor_tasks_openSidePanel'],
        interactions: ['editor_sidepanel_tasks'],
        dynamicPulse: [
          {
            position: {
              top: '316px',
              right: '40px',
            },
            targetAction: 'editor_tasks_openSidePanel',
          },
        ],
        before: () => {
          dispatch(
            setSelection({
              TASK: false,
            }),
          );
        },
      },
      {
        id: 'task_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TASKS_DESCRIPTION' }),
        currentPhase: 4 - orderOffset,
        progress: getProgress(4 - orderOffset, 4 - orderOffset, 33),
        visibleSpace: [visibleSpace.sidepanel, visibleSpace.sidebar],
        boxTarget: ['sidebar_taskPanel_header'],
        onSkip: () => {
          dispatch(setSidebarView('TASKS'));
        },
        before: () => dispatch(setSidebarView('TASKS')),
      },
      {
        id: 'task_create',
        description: intl.formatMessage({
          id: 'ONBOARDING_EDITOR_TASKS_CREATE',
        }),
        phaseTitle: intl.formatMessage({ id: 'CREATE_A_TASK' }),
        currentPhase: 4 - orderOffset,
        subPhase: 1,
        progress: getProgress(4 - orderOffset, 4 - orderOffset, 66),
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        deps: ['editor_tasks_startTaskCreation', 'editor_tasks_createTask'],
        interactions: [
          'editor_mainComponent_addTask',
          'editor_contextMenu_createTask',
          'editor_tasks_tempCard',
        ],

        pulseTarget: [
          'document_task',
          'contextMenu_task',
          'taskDescription',
          'taskAssigned',
          'taskDueDate',
        ],
      },
      {
        id: 'task_completed',
        description: intl.formatMessage({
          id: 'ONBOARDING_EDITOR_TASKS_COMPLETED',
        }),
        phaseTitle: intl.formatMessage({ id: 'CREATE_A_TASK' }),
        currentPhase: 4 - orderOffset,
        phaseCompleted: 4 - orderOffset,
        subPhase: 1,
        progress: getProgress(4 - orderOffset, 4 - orderOffset, 100),
      },
    ],
    [orderOffset],
  );

  const initialCards: ExtendedCard[] = [
    {
      id: 'beginning',
      description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_INTRODUCTION' }),
      currentPhase: 0,
      progress: 0,
    },
  ];

  const finalCards: ExtendedCard[] = [
    {
      id: 'ending',
      title: intl.formatMessage({ id: 'AND_THATS_JUST_THE_BEGINNING' }),
      description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMPLETED' }, { br: <br /> }),
      currentPhase: 0,
      showPhases: false,
      progress: 0,
      nextLabel: intl.formatMessage({ id: 'GO_TO_MY_DOCUMENT' }),
    },
  ];

  switch (journey) {
    case 'general':
      return [...initialCards, ...informativeCards, ...finalCards];
    case 'writer':
      return [
        ...initialCards,
        ...writingCards,
        ...suggestionCards,
        ...commentCards,
        ...taskCards,
        ...finalCards,
      ];
    case 'reviewer':
      return [...initialCards, ...suggestionCards, ...commentCards, ...taskCards, ...finalCards];
    case 'limitedReviewer':
      return [...initialCards, ...commentCards, ...taskCards, ...finalCards];
    default:
      return [...initialCards, ...finalCards];
  }
};

export default useCards;
