import { Command } from '../Command';
import { Descendant } from 'slate';
import { ReduxInterface } from 'Editor/services';
import { JsonRange } from 'Editor/services/_Common/Selection';

export class AddCommentCommand extends Command {
  protected comment: Descendant[];

  constructor(context: Editor.Edition.ICommandArgs, comment: Descendant[]) {
    super(context);

    this.comment = comment;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (
      !this.context.DataManager ||
      !this.context.DataManager.comments ||
      !this.context.DataManager.selection
    ) {
      return this;
    }

    try {
      const tempCommentData = ReduxInterface.getEditorState().comments.insert;

      const result: any = await this.context.DataManager.comments.addComment(
        this.comment,
        tempCommentData.reference,
      );

      ReduxInterface.cancelTemporaryComment();

      const rangeData = this.context.DataManager.selection.current;
      const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

      //TODO to discuss a viable option

      setTimeout(() => {
        if (!tempCommentData.level0) {
          return this;
        }
        const blockModel = this.context.DataManager?.nodes.getNodeModelById(tempCommentData.level0);

        if (!blockModel) {
          return this;
        }

        let path = blockModel.findPath('element_reference', result.id);

        const indexOf = path.indexOf(blockModel.KEYS.PROPERTIES);

        path = path.slice(0, indexOf);
        const childData = blockModel.getChildDataByPath(path);

        if (childData.childNodes) {
          path.push('childNodes');
          path.push(childData.childNodes.length);
        }

        if (JsonRange.isValidSelectionPath(path)) {
          jsonRange.updateRangePositions({
            b: jsonRange.start.b,
            p: path,
          });

          // apply new selection
          if (this.context.DataManager?.selection) {
            this.context.DataManager.selection.setUserSelection([jsonRange.serializeToRangeData()]);
          }
        }
      }, 0);
    } catch (error) {
      ReduxInterface.cancelTemporaryComment();
      throw error;
    }
    return this;
  }
}
