import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, RadioButton } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { navigateToEditor, navigateToPDF, navigateToPresentation } from 'router/history';
import { notify } from '_common/components/ToastSystem';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { convertFileTo, setAppLoading } from 'App/redux/appSlice';

import styles from './ConvertToModal.module.scss';
import { FILE_TYPE_FROM_MIME } from '_common/consts';
import ObjectApi from '_common/services/api/ObjectApi';

function isFile(object: any): object is doDOC.File {
  return object.type && object.type === 'file';
}

const MODAL = 'ConvertToModal';

type ConvertAction = 'keep' | 'remove';

const ConvertToModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const objectIds = useSelector((state) => state.modals[MODAL].objectIds);
  const openAfterConverting = useSelector((state) => state.modals[MODAL].openAfterConverting);

  const [action, setAction] = useState<ConvertAction>('keep');
  const [objectsData, setObjectsData] =
    useState<Record<ObjectId, { type: 'dopdf' | 'document' | 'presentation'; name: string }>>();

  useEffect(() => {
    const fetchData = async () => {
      const data: typeof objectsData = {};
      for (const i in objectIds) {
        try {
          const { data: objectData } = await dispatch(
            ObjectApi.endpoints.getObject2.initiate({
              object_id: objectIds[i],
              object_type: 'file',
            }),
          );

          if (objectData && isFile(objectData)) {
            data[objectIds[i]] = {
              // @ts-expect-error can't restrict mime type TS type
              type: objectData.mime?.type ? FILE_TYPE_FROM_MIME[objectData.mime.type] : 'document',
              name: objectData.name,
            };
          }
        } catch (e) {
          throw e;
        }
      }
      setObjectsData(data);
    };

    fetchData();
  }, [objectIds]);

  const objectsType = useMemo<'dopdf' | 'document' | 'presentation' | 'multi' | null>(() => {
    let type: 'dopdf' | 'document' | 'presentation' | 'multi' | null = null;
    if (objectsData) {
      Object.keys(objectsData).forEach((key) => {
        if (!type) {
          type = objectsData[key].type;
        } else if (type !== objectsData[key].type) {
          type = 'multi';
        }
      });
    }
    return type;
  }, [objectsData]);

  const submit = async () => {
    if (objectsData) {
      close();
      dispatch(
        setAppLoading({
          isOpen: true,
          message: 'CONVERTING_FILE',
          messageValues: { length: objectIds.length },
        }),
      );
      Promise.all(
        objectIds.map((objectId: string) =>
          dispatch(
            convertFileTo({
              objectId,
              deleteSource: action === 'remove',
              objectType: objectsData?.[objectId].type,
            }),
          ),
        ),
      ).then((result) => {
        if (openAfterConverting && result.length === 1) {
          const convertedObject = result[0].payload;
          if (convertedObject.type === 'document') {
            navigateToEditor(convertedObject.id, true);
          } else if (objectsType === 'dopdf') {
            navigateToPDF(convertedObject.id, true);
          } else if (objectsType === 'presentation') {
            navigateToPresentation(convertedObject.id, true);
          }
        }
        notify({
          type: 'success',
          title: 'FILE_CONVERTED',
          titleValues: { length: objectIds.length },
          message: 'CONVERTED_FILE_MESSAGE',
          messageValues: {
            newFileType:
              objectsType === 'dopdf'
                ? 'doDOC PDF'
                : objectsType === 'presentation'
                ? 'doPPT'
                : 'doDOC',
            length: objectIds.length,
          },
        });
      });
    }
  };

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal open={!!isOpen} onClose={close} testId="convert-to" width="60rem">
      <Modal.Header onClose={close}>
        <FormattedMessage
          id="CONVERT_FILE_TO_MODAL_TITLE"
          values={{
            newFileType:
              objectsType === 'dopdf' ? 'doPDF' : objectsType === 'presentation' ? 'doPPT' : '.ddc',
            length: objectIds.length,
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.description}>
          <FormattedMessage
            id="CONVERT_FILE_TO_MODAL_DESCRIPTION"
            values={{
              fileTitle: objectsData?.[objectIds[0]]?.name,
              newFileType:
                objectsType === 'dopdf'
                  ? 'doPDF'
                  : objectsType === 'presentation'
                  ? 'doPPT'
                  : '.ddc',
              length: objectIds.length,
            }}
          />
        </div>
        <div className={styles.actions}>
          <RadioButton
            size="small"
            checked={action === 'keep'}
            onChange={() => setAction('keep')}
            testId="convert-to-modal-radio-button-keep-original-file"
          >
            <FormattedMessage id="KEEP_ORIGINAL_FILE" values={{ length: objectIds.length }} />
          </RadioButton>
          <RadioButton
            size="small"
            checked={action === 'remove'}
            onChange={() => setAction('remove')}
            testId="convert-to-modal-radio-button-remove-original-file"
          >
            <FormattedMessage id="REMOVE_ORIGINAL_FILE" values={{ length: objectIds.length }} />
          </RadioButton>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="convert-to-modal-close">
          <FormattedMessage id="global.close" />
        </Button>
        <Button variant="primary" size="medium" onClick={submit} testId="convert-to-modal-submit">
          <FormattedMessage id="CONVERT" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConvertToModal;
