import { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import api from '_common/services/api/api';
import { PublicLinkData } from '_common/services/PublicLinkService';

export type PublicLinkSettingsPayload = {
  objectId: ObjectId;
  linkId: string;
  values: PublicLinkData;
  params: Request.ListParams;
};

const publicLinkSettingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPublicLinkList: builder.query<
      PublicLink[],
      Pick<PublicLinkSettingsPayload, 'objectId' | 'params'>
    >({
      query: ({ objectId, params }) => {
        return {
          url: `/object/${objectId}/link/list`,
          config: {
            params,
          },
        };
      },
      transformResponse: (responseData: PublicLink[]) => {
        return responseData;
      },
      providesTags: () => {
        return ['PublicLinkSetting'];
      },
    }),
    getPublicLink: builder.query<PublicLink, PublicLinkSettingsPayload['linkId']>({
      query: (id) => ({
        url: `/object/link/get/${id}`,
      }),
      providesTags: (result) => {
        const tags: TagDescription<'PublicLinkSetting'>[] = [];
        if (result) {
          tags.push({ type: 'PublicLinkSetting', id: result.id });
        }
        return tags;
      },
    }),
    createPublicLink: builder.mutation<
      void,
      Pick<PublicLinkSettingsPayload, 'objectId' | 'values'>
    >({
      query: ({ objectId, values }) => {
        return {
          url: `/object/${objectId}/link/create`,
          method: 'POST',
          body: values,
        };
      },
      invalidatesTags: (_, __, arg) => [{ type: 'PublicLinkSetting', objectId: arg.objectId }],
    }),
    editPublicLink: builder.mutation<void, Pick<PublicLinkSettingsPayload, 'linkId' | 'values'>>({
      query: ({ linkId, values }) => {
        return {
          url: `/object/link/${linkId}/edit`,
          method: 'POST',
          body: values,
        };
      },
      invalidatesTags: (_, __, arg) => [{ type: 'PublicLinkSetting', linkId: arg.linkId }],
    }),
    deletePublicLink: builder.mutation<
      void,
      Pick<PublicLinkSettingsPayload, 'objectId' | 'linkId'>
    >({
      query: ({ objectId, linkId }) => {
        return {
          url: `/object/${objectId}/link/${linkId}/delete`,
          method: 'POST',
          body: {},
        };
      },
      invalidatesTags: (_, __, arg) => [
        { type: 'PublicLinkSetting', objectId: arg.objectId, linkId: arg.linkId },
      ],
    }),
  }),
});

// Export queries and mutations
export const {
  useGetPublicLinkQuery,
  useGetPublicLinkListQuery,
  useCreatePublicLinkMutation,
  useEditPublicLinkMutation,
  useDeletePublicLinkMutation,
} = publicLinkSettingsApi;

export default publicLinkSettingsApi;
