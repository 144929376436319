import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Divider, Toggle, Tooltip } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { usePDFContext, usePDFDataEvents } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import usePDFData from 'PDF/hooks/usePDFData';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectIsIEnvision } from 'App/redux/appSlice';
import { setLeftPanel, setCursorMode, CursorMode } from 'PDF/redux/PDFGeneralSlice';
import { selectCurrentAnnotation } from 'PDF/redux/PDFAnnotationsSlice';

import { InteractionController } from '_common/components';
import { Versions } from 'Editor/components';
import IntegrationiEnvisionLogo from 'Editor/pages/EditorPage/TopBarComponent/Menu/IntegrationiEnvisionLogo/IntegrationiEnvisionLogo';

import ColorDropdown from './Toggles/ColorDropdown';
import ROIToggle from './Toggles/ROIToggle';
import ShapesToggle from './Toggles/ShapesToggle';
import StrokeWidthDropdown from './Toggles/StrokeWidthDropdown';
import FreehandToggle from './Toggles/FreehandToggle';
import TextboxToggle from './Toggles/TextboxToggle';
import Search from './Search';

import styles from './Menu.module.scss';
import { PageNavigator } from '_common/suite/components';

const Menu = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();

  const isIEnvision = useSelector(selectIsIEnvision);
  const leftPanel = useSelector((state) => state.pdf.general.leftPanel);
  const cursorMode = useSelector((state) => state.pdf.general.cursorMode);
  const currentPageNumber = useSelector((state) => state.pdf.general.currentPageNumber);
  const pdfData = usePDFData();
  const selectedAnnotation = useSelector(selectCurrentAnnotation);
  const versionHistory = useSelector((state) => state.pdf.general.versionHistory);
  const loadedVersion = useSelector((state) => state.pdf.general.loadedVersion);
  const pulseData = useSelector((state) => state.onboarding.pulseData);
  const versions = pdfData?.lifecycle.versions;
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  const inCurrentVersion = loadedVersion == null;

  const {
    canCreateAnnotation,
    canCreateTask,
    canSaveVersion,
    canAccessVersionHistory,
    canAccessAuditLog,
    canRestoreVersion,
    canEditAnnotation,
  } = usePDFPermissions();
  const isROIType = (['Highlight', 'Underline', 'StrikeOut'] as PDF.Annotation.Type[]).some(
    (type) => type === selectedAnnotation?.subtype || type === cursorMode,
  );

  usePDFDataEvents('UNDO_STATUS_CHANGED', (status) => {
    setCanUndo(status === 'NOT_EMPTY');
  });

  usePDFDataEvents('REDO_STATUS_CHANGED', (status) => {
    setCanRedo(status === 'NOT_EMPTY');
  });

  useEffect(() => {
    setCanUndo(pdfManager.canUndo());
    setCanRedo(pdfManager.canRedo());
  }, [pdfManager]);

  const toggleLeftPanel = (panel: string) => {
    dispatch(setLeftPanel(leftPanel === panel ? '' : panel));
  };

  const handleCursorMode = (mode: CursorMode) => {
    dispatch(setCursorMode(mode));
  };

  const openSaveVersionModal = (index?: number | null) => {
    dispatch(
      openAndUpdateModal({
        modal: 'SaveVersionModal',
        data: {
          type: index == null ? 'saveVersion' : 'editDescription',
          index: index ?? null,
          description: index == null ? undefined : versions?.[index]?.description,
        },
      }),
    );
  };

  const openRestoreVersionModal = () => {
    if (!inCurrentVersion) {
      dispatch(
        openAndUpdateModal({
          modal: 'PDFConfirmationModal',
          data: {
            title: 'RESTORE_DOCUMENT_VERSION',
            message: 'ARE_YOU_SURE_YOU_WANT_TO_RESTORE_THIS_PDF_DOCUMENT_VERSION',
            messageValues: { versionNumber: loadedVersion + 1 },
            confirmButtonTextId: 'RESTORE_VERSION',
            confirmButtonType: 'primary',
            cancelButtonTextId: 'global.cancel',
            actionCode: 'restorePDFDocumentVersion',
            actionValue: { loadedVersion },
            headerType: 'information',
            cancelButtonShow: true,
            width: '60rem',
          },
        }),
      );
    }
  };

  const openAuditLogModal = () => {
    if (pdfData) {
      dispatch(
        openAndUpdateModal({
          modal: 'DocumentAuditLogModal',
          data: { objectId: pdfData?.id, objectType: pdfData.type },
        }),
      );
    }
  };

  const undo = () => {
    pdfManager.undo();
  };

  const redo = () => {
    pdfManager.redo();
  };

  return (
    <InteractionController environment="dopdf" style={{ width: '100%' }}>
      <div className={styles.leftSide}>
        <div className={styles.section}>
          <Tooltip
            content={intl.formatMessage({ id: 'SIDEBAR' })}
            placement="bottom"
            testId="menu-thumbnails-tooltip"
          >
            <Toggle
              variant="ghost"
              isToggled={leftPanel === 'thumbnails'}
              onClick={() => toggleLeftPanel('thumbnails')}
              size="medium"
              icon={leftPanel === 'thumbnails' ? 'SidepanelBlue' : 'SidepanelGrey'}
              testId="menu-thumbnails-toggle"
            />
          </Tooltip>
          <Divider vertical margin="0 0.75rem" />
          <Tooltip
            content={
              canUndo
                ? intl.formatMessage({ id: 'editor.menu.edit.undo' })
                : intl.formatMessage({ id: 'NO_ACTIONS_TO_UNDO' })
            }
            placement="bottom"
            testId="menu-undo-tooltip"
          >
            <Toggle
              variant="ghost"
              onClick={undo}
              size="medium"
              icon="Undo"
              disabled={!canUndo}
              testId="menu-undo-toggle"
            />
          </Tooltip>
          <Tooltip
            content={
              canRedo
                ? intl.formatMessage({ id: 'editor.menu.edit.redo' })
                : intl.formatMessage({ id: 'NO_ACTIONS_TO_REDO' })
            }
            placement="bottom"
            testId="menu-redo-tooltip"
          >
            <Toggle
              variant="ghost"
              onClick={redo}
              size="medium"
              icon="Redo"
              margin="0 0 0 0.75rem"
              disabled={!canRedo}
              testId="menu-redo-toggle"
            />
          </Tooltip>
          <Divider vertical margin="0 0.75rem" />
          {!isIEnvision && canAccessAuditLog && !versionHistory && (
            <Tooltip
              content={intl.formatMessage({ id: 'DOCUMENT_AUDIT_LOG' })}
              placement="bottom"
              testId="audit-log-tooltip"
            >
              <Toggle
                margin="0 0.75rem 0 0"
                variant="ghost"
                size="medium"
                onClick={openAuditLogModal}
                icon="PDFAuditLog"
                testId="audit-log-toggle"
              />
            </Tooltip>
          )}
          {canAccessVersionHistory && versionHistory && (
            <>
              <Versions
                loadedVersion={loadedVersion}
                versions={versions}
                loadVersion={pdfManager.loadVersion}
                width="20"
                testId="versions"
              />
              <Divider vertical margin="0 0.75rem" />
              <Tooltip
                content={
                  inCurrentVersion
                    ? intl.formatMessage({ id: 'CANNOT_RESTORE_VERSION_WHILE_IN_CURRENT_VERSION' })
                    : intl.formatMessage({ id: 'RESTORE_VERSION' })
                }
                placement="bottom"
                testId="restore-version-tooltip"
              >
                <Toggle
                  disabled={inCurrentVersion || !canRestoreVersion}
                  margin="0 0.5rem 0 0"
                  variant="ghost"
                  size="medium"
                  onClick={openRestoreVersionModal}
                  icon="PDFRestoreGrey"
                  testId="restore-version-toggle"
                />
              </Tooltip>
            </>
          )}
          {!isIEnvision && canSaveVersion && (
            <Tooltip
              content={intl.formatMessage({ id: 'SAVE_VERSION' })}
              placement="bottom"
              testId="save-version-tooltip"
            >
              <Toggle
                margin="0 0.75rem 0 0"
                variant="ghost"
                size="medium"
                onClick={() => {
                  openSaveVersionModal();
                }}
                icon="SaveGrey"
                testId="save-version-toggle"
              />
            </Tooltip>
          )}
          {versionHistory && <Divider vertical margin="0 0.75rem 0 0" />}
          {pdfManager.navigation && (
            <PageNavigator
              goToPage={pdfManager.navigation.goTo}
              onPageDown={pdfManager.navigation.next}
              onPageUp={pdfManager.navigation.previous}
              totalPageNumber={pdfManager.getNumPages()}
              currentPageNumber={currentPageNumber}
            />
          )}
        </div>
        {!versionHistory && (
          <div className={styles.section} id="pdf-toolbar-section-2">
            <Tooltip
              placement="bottom"
              content={intl.formatMessage({ id: 'MOUSE' })}
              testId="menu-mouse-tooltip"
            >
              <Toggle
                variant="ghost"
                isToggled={cursorMode === 'normal'}
                onClick={() => handleCursorMode('normal')}
                size="medium"
                icon={cursorMode === 'normal' ? 'CursorBlue' : 'CursorGrey'}
                testId="menu-mouse-toggle"
              />
            </Tooltip>
            <Tooltip
              placement="bottom"
              content={intl.formatMessage({ id: 'PAN' })}
              testId="menu-pan-tooltip"
            >
              <Toggle
                margin="0 0 0 0.75rem"
                variant="ghost"
                isToggled={cursorMode === 'pan'}
                onClick={() => handleCursorMode('pan')}
                size="medium"
                icon={cursorMode === 'pan' ? 'HandBlue' : 'HandGrey'}
                testId="menu-pan-toggle"
              />
            </Tooltip>
            <Divider vertical margin="0 0.75rem" />
            <ROIToggle disabled={!canCreateAnnotation} />
            <TextboxToggle disabled={!canCreateAnnotation} />
            <ShapesToggle disabled={!canCreateAnnotation} />
            <Tooltip
              content={
                canCreateAnnotation
                  ? intl.formatMessage({ id: 'FREEHAND' })
                  : intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
              }
              placement="bottom"
              testId="menu-freehand-tooltip"
            >
              <FreehandToggle disabled={!canCreateAnnotation} />
            </Tooltip>
          </div>
        )}
      </div>
      {!versionHistory && <Divider vertical margin="0 0.75rem" />}
      {!versionHistory && (
        <div className={styles.rightSide}>
          <div className={styles.section}>
            <ColorDropdown
              property="fill"
              disabled={
                !canCreateAnnotation ||
                cursorMode === 'Ink' ||
                selectedAnnotation?.subtype === 'Ink' ||
                isROIType ||
                !!(selectedAnnotation && !canEditAnnotation(selectedAnnotation))
              }
              testId="menu-roi-color"
            />
            <ColorDropdown
              property="stroke"
              disabled={
                !canCreateAnnotation ||
                isROIType ||
                !!(selectedAnnotation && !canEditAnnotation(selectedAnnotation))
              }
              testId="menu-stroke-color"
            />
            <StrokeWidthDropdown
              disabled={
                !canCreateAnnotation ||
                isROIType ||
                !!(selectedAnnotation && !canEditAnnotation(selectedAnnotation))
              }
            />
            <Divider vertical margin="0" />
            <InteractionController
              environment="dopdf"
              rules={
                !pulseData.commentId
                  ? [
                      {
                        interaction: 'pdf_toolbar_comment',
                        actions: ['pdf_comments_createComment'],
                      },
                    ]
                  : undefined
              }
            >
              <Tooltip
                content={
                  canCreateAnnotation
                    ? intl.formatMessage({ id: 'NEW_COMMENT' })
                    : intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
                }
                placement="bottom"
                testId="menu-comment-tooltip"
              >
                <span
                  id="pdf-toolbar-comment"
                  style={{ position: 'relative', margin: '0 0 0 0.75rem', zIndex: 1 }}
                >
                  <Toggle
                    variant="ghost"
                    isToggled={cursorMode === 'Note'}
                    onClick={() => handleCursorMode('Note')}
                    size="medium"
                    icon={cursorMode === 'Note' ? 'PDFCommentBlue' : 'PDFCommentGrey'}
                    disabled={!canCreateAnnotation}
                    testId="menu-comment-toggle"
                  />
                </span>
              </Tooltip>
            </InteractionController>
            <InteractionController
              environment="dopdf"
              rules={!pulseData.taskId ? [{ interaction: 'pdf_toolbar_task' }] : undefined}
            >
              <Tooltip
                content={
                  canCreateTask
                    ? intl.formatMessage({ id: 'NEW_TASK' })
                    : intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
                }
                placement="bottom"
                testId="menu-task-tooltip"
              >
                <span
                  id="pdf-toolbar-task"
                  style={{ position: 'relative', margin: '0 0 0 0.75rem', zIndex: 1 }}
                >
                  <Toggle
                    variant="ghost"
                    isToggled={cursorMode === 'Task'}
                    onClick={() => handleCursorMode('Task')}
                    size="medium"
                    icon={cursorMode === 'Task' ? 'TaskBlue' : 'TaskGrey'}
                    disabled={!canCreateTask}
                    testId="menu-task-toggle"
                  />
                </span>
              </Tooltip>
            </InteractionController>
          </div>
          <div className={styles.section}>
            <Search />
            <IntegrationiEnvisionLogo />
          </div>
        </div>
      )}
    </InteractionController>
  );
};

export default Menu;
