/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Doc, Snapshot, Path } from 'sharedb';
/* @ts-ignore */
import json0 from 'ot-json0/lib/json0';
import { isEqual } from 'lodash';
import { Logger } from '_common/services';
import { BaseTypedEmitter } from '../../BaseTypedEmitter';
import { Transport } from '../../Transport';
import { NestedPaths, TypeFromPath } from './NestedTypes';
import { RealtimeOpsBuilder } from './RealtimeUtils';

function isDocObject(obj: Realtime.Core.RealtimeObjectId): obj is Doc {
  if (!(obj instanceof String) && (obj as Doc).id) {
    return true;
  }
  return false;
}

// type AllowedPendingEvents<T> = Pick<ObjectEvents<T>, 'LOADED'>;
type AllowedPendingEventNames<T> = keyof Pick<Realtime.Core.ObjectEvents<T>, 'LOADED'>;

export abstract class RealtimeFragment<T extends Realtime.Core.BaseData>
  extends BaseTypedEmitter<Realtime.Core.ObjectEvents<T>>
  implements Realtime.Core.IRealtimeObject<T>
{
  protected subscribed: boolean = false;
  protected data: T | null;
  model: Doc;
  protected loadedVersion: Realtime.Core.RealtimeVersion | null = null;
  transport: Transport;
  modelType?: string;
  protected pendingEvents: any = {};
  protected path: Path = [];
  static TYPE_NAME() {
    throw new Error('Not Implemented!');
  }

  static TYPE_COLLECTION() {
    throw new Error('Not Implemented!');
  }

  constructor(
    transport: Transport,
    id: Realtime.Core.RealtimeObjectId,
    type?: string,
    path?: Path,
  ) {
    super();
    this.data = null;
    this.transport = transport;
    this.path = path || [];
    if (isDocObject(id)) {
      this.model = id;
      this.modelType = this.model.collection;
    } else if (typeof id === 'string' && type) {
      this.model = this.transport.get(type, id) as Doc;
      this.modelType = type;
    } else {
      throw new Error('Wrong arguments provided to constructor');
    }
    this.data = this.selectedData();
    this.proxyHandleLoad = this.proxyHandleLoad.bind(this);
    this.proxyHandleLoadVersion = this.proxyHandleLoadVersion.bind(this);
    this.proxyHandleCreate = this.proxyHandleCreate.bind(this);
    this.proxyHandleBeforeOpBatch = this.proxyHandleBeforeOpBatch.bind(this);
    this.proxyHandleOpBatch = this.proxyHandleOpBatch.bind(this);
    this.proxyHandleOp = this.proxyHandleOp.bind(this);
    this.proxyHandleBeforeOp = this.proxyHandleBeforeOp.bind(this);
  }

  setVersion(
    version: { creation: string; creator: string; description?: string | undefined } | null,
  ): Promise<any> {
    return Promise.resolve();
  }
  backToCurrentVersion() {
    return Promise.resolve();
  }

  protected setEventListeners() {
    this.subscribed = true;
    this.model.on('load', this.proxyHandleLoad);
    //! Hack
    // @ts-expect-error
    this.model.on('loaded version', this.proxyHandleLoadVersion);
    this.model.on('create', this.proxyHandleCreate);
    this.model.on('before op batch', this.proxyHandleBeforeOpBatch);
    this.model.on('op batch', this.proxyHandleOpBatch);
    this.model.on('op', this.proxyHandleOp);
    this.model.on('before op', this.proxyHandleBeforeOp);
  }

  protected removeEventListeners() {
    this.subscribed = true;
    this.model.off('load', this.proxyHandleLoad);
    //! Hack
    // @ts-expect-error
    this.model.off('loaded version', this.proxyHandleLoadVersion);
    this.model.off('create', this.proxyHandleCreate);
    this.model.off('before op batch', this.proxyHandleBeforeOpBatch);
    this.model.off('op batch', this.proxyHandleOpBatch);
    this.model.off('op', this.proxyHandleOp);
    this.model.off('before op', this.proxyHandleBeforeOp);
  }

  protected proxyHandleLoad() {
    this.data = this.selectedData();
    if (this.data) {
      this.handleLoad();
      !this.loadedVersion && this.triggerLoaded();
    }
  }

  protected proxyHandleCreate(source: Realtime.Core.RealtimeSourceType) {
    this.data = this.selectedData();
    if (source === false && this.data) {
      this.emit('CREATED');
    }
  }

  protected proxyHandleLoadVersion(version: Realtime.Core.RealtimeVersion | null) {
    this.loadedVersion = version;
    this.triggerLoaded();
  }

  protected proxyHandleBeforeOpBatch(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ) {
    const filtered = this.filterOps(ops);
    if (!this.loadedVersion && filtered.length) {
      this.handlePreBatchOperations(filtered, source);
    }
  }

  protected proxyHandleOpBatch(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ) {
    const filtered = this.filterOps(ops);
    if (!this.loadedVersion && filtered.length) {
      this.handleBatchOperations(filtered, source);
    }
  }

  protected proxyHandleOp(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ) {
    const filtered = this.filterOps(ops);
    if (!this.loadedVersion && filtered.length) {
      this.handleOperations(filtered, source);
    }
  }

  protected proxyHandleBeforeOp(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ) {
    const filtered = this.filterOps(ops);
    if (!this.loadedVersion && filtered.length) {
      this.handlePreOperations(filtered, source);
    }
  }

  protected filterOps(ops: Realtime.Core.RealtimeOps) {
    const strPath = this.path.join('.');
    return ops.filter((op) => {
      return op.p.join('.').indexOf(strPath) === 0;
    });
  }

  protected triggerLoaded() {
    this.emit('LOADED', this.selectedData());
    this.triggerPendingEvent('LOADED');
  }

  protected selectedData(): T | null {
    // TODO: this needs cashing
    let data;
    if (this.loadedVersion) {
      data = this.loadedVersion.data ? this.loadedVersion.data : null;
    } else {
      data = this.model.data;
    }

    let thisData = null;
    if (data != null) {
      thisData = JSON.parse(JSON.stringify(data));
    }
    for (let index = 0; index < this.path.length; index++) {
      const key: string | number = this.path[index];
      thisData = thisData ? thisData[key] : null;
    }
    return thisData;
  }

  abstract handleLoad(): void;
  abstract handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void;
  abstract handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void;
  abstract handleOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void;
  abstract handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void;

  get id(): string {
    return this.model.id;
  }

  get loaded(): boolean {
    return (
      (this.model.data !== undefined && this.model.data != null && !!this.selectedData()) ||
      !!this.loadedVersion
    );
  }

  get created(): boolean {
    return this.model.type !== undefined;
  }

  awaitForEvent(eventName: AllowedPendingEventNames<T>): Promise<void> {
    if (eventName === 'LOADED' && this.loaded) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      if (!this.pendingEvents[eventName]) {
        this.pendingEvents[eventName] = [];
      }
      this.pendingEvents[eventName].push(resolve);
    });
  }

  private triggerPendingEvent(eventName: AllowedPendingEventNames<T>) {
    if (this.pendingEvents[eventName]) {
      for (let index = 0; index < this.pendingEvents[eventName].length; index++) {
        this.pendingEvents[eventName][index]();
      }
    }
  }

  getWithStringPath<P extends NestedPaths<{ a: string; b: { c: number } }>>(
    thisPath: P,
  ): TypeFromPath<{ a: string; b: { c: number } }, P> {
    let data: any;
    let arrayPath = thisPath.split('.');

    const selectedData = this.selectedData();
    if (selectedData != null) {
      data = selectedData;
      if (arrayPath) {
        for (let index = 0; index < arrayPath.length; index++) {
          const key: string | number = arrayPath[index];
          data = data ? data[key] : null;
        }
      }
    }
    return data;
  }

  get(thisPath?: any) {
    let data: any;

    const selectedData = this.selectedData();
    if (selectedData != null) {
      data = selectedData;
      if (thisPath) {
        for (let index = 0; index < thisPath.length; index++) {
          const key: string | number = thisPath[index];
          data = data ? data[key] : null;
        }
      }
    }
    return data;
  }

  create(data: T): Promise<RealtimeFragment<T>> {
    return new Promise((resolve, reject) => {
      this.model.create(data, json0.type, {}, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve(this);
        }
      });
    });
  }

  apply(
    ops: Realtime.Core.RealtimeOps,
    options?: Realtime.Core.RealtimeSourceOptions,
  ): Promise<RealtimeFragment<T>> {
    return new Promise((resolve, reject) => {
      if (!ops) {
        reject(new Error('No ops object provided!'));
      }
      if (Array.isArray(ops) && ops.length <= 0) {
        resolve(this);
      }
      this.model.submitOp(ops, options, (error) => {
        if (error) {
          Logger.error('Apply error!', error, ops, options);
          reject(error);
        } else {
          resolve(this);
        }
      });
    });
  }

  pause(): void {
    // this.model.pause();
  }

  resume(): void {
    // this.model.resume();
  }

  revert(
    ops: Realtime.Core.RealtimeOps,
    options?: Realtime.Core.RealtimeSourceOptions,
  ): Promise<RealtimeFragment<T>> {
    return new Promise((resolve, reject) => {
      if (!ops) {
        reject(new Error('No ops object provided!'));
      }
      if (Array.isArray(ops) && ops.length <= 0) {
        resolve(this);
      }
      const invertedOps = json0.invert(ops);

      this.model.submitOp(invertedOps, options, (error) => {
        if (error) {
          Logger.error('Revert error!', error, invertedOps, options);
          reject(error);
        } else {
          resolve(this);
        }
      });
    });
  }

  fetch(): Promise<RealtimeFragment<T>> {
    return new Promise((resolve, reject) => {
      this.model.fetch((error) => {
        if (error) {
          reject(error);
        } else {
          this.triggerLoaded();
          resolve(this);
        }
      });
    });
  }

  forceUpdate(): Promise<RealtimeFragment<T>> {
    return new Promise((resolve, reject) => {
      this.transport.fetchSnapshot(
        this.model.collection,
        this.model.id,
        null,
        (error: unknown, snapshot: Snapshot) => {
          if (!error) {
            if (!this.model.version) {
              this.triggerLoaded();
              resolve(this);
              return;
            }
            if (snapshot.v >= this.model.version) {
              this.model.data = snapshot.data;
            }
            this.triggerLoaded();
            resolve(this);
          } else {
            reject(error);
          }
        },
      );
    });
  }

  subscribe(): Promise<RealtimeFragment<T>> {
    if (!this.subscribed) {
      this.setEventListeners();
    }
    if (this.loaded) {
      this.data = this.selectedData();
      return Promise.resolve(this);
    }
    return new Promise((resolve, reject) => {
      this.model.subscribe((error) => {
        if (error) {
          reject(error);
        } else {
          resolve(this);
        }
      });
    });
  }

  unsubscribe(): Promise<RealtimeFragment<T>> {
    this.removeEventListeners();
    return Promise.resolve(this);
  }

  buildOp(path: Path, newValue: any, oldValue?: any, options: any = {}) {
    let p = [];
    if (Array.isArray(path)) {
      p = path;
    } else {
      p = [path];
    }

    if (p.length > 0) {
      const parentPath = p.slice(0, p.length - 1);
      const parentValue = this.get(parentPath);

      if (oldValue == null) {
        oldValue = this.get(p);
      }

      if (Array.isArray(parentValue)) {
        if (newValue == null && oldValue != null) {
          return RealtimeOpsBuilder.listDelete(oldValue, p);
        }
        if ((oldValue == null || options?.insert) && newValue != null) {
          return RealtimeOpsBuilder.listInsert(newValue, p);
        }
        if (oldValue != null && newValue != null && !isEqual(oldValue, newValue)) {
          return RealtimeOpsBuilder.listReplace(oldValue, newValue, p);
        }
      } else {
        if (newValue == null && oldValue !== undefined) {
          return RealtimeOpsBuilder.objectDelete(oldValue, p);
        }
        if (oldValue === undefined && newValue !== undefined) {
          return RealtimeOpsBuilder.objectInsert(newValue, p);
        }
        if (oldValue !== undefined && newValue !== undefined && !isEqual(oldValue, newValue)) {
          return RealtimeOpsBuilder.objectReplace(oldValue, newValue, p);
        }
      }
    }

    return null;
  }

  async set(
    path: Path,
    value: any,
    options?: Realtime.Core.RealtimeSourceOptions,
  ): Promise<RealtimeFragment<T>> {
    if (path.length > 0) {
      const oldValue = this.get(path);

      let op: Realtime.Core.RealtimeOp | null = null;

      if (oldValue === undefined) {
        op = RealtimeOpsBuilder.objectInsert(value, path);
      } else if (!isEqual(value, oldValue)) {
        op = RealtimeOpsBuilder.objectReplace(oldValue, value, path);
      }

      if (op) {
        return this.apply([op], options);
      }

      return Promise.resolve(this);
    }

    return Promise.reject(new Error('No path provided!'));
  }

  async delete(
    path: Path,
    options?: Realtime.Core.RealtimeSourceOptions,
  ): Promise<RealtimeFragment<T>> {
    if (path.length > 0) {
      const oldValue = this.get(path);

      if (oldValue !== undefined) {
        const op: Realtime.Core.RealtimeOp = RealtimeOpsBuilder.objectDelete(oldValue, path);

        return this.apply([op], options);
      }
      return Promise.resolve(this);
    }

    return Promise.reject();
  }

  async listInsert(
    path: Path,
    value: any,
    options?: Realtime.Core.RealtimeSourceOptions,
  ): Promise<RealtimeFragment<T>> {
    if (path.length > 0) {
      const parentPath = path.slice(0, path.length - 1);
      const parentValue = this.get(parentPath);

      if (Array.isArray(parentValue) && value != null) {
        const op: Realtime.Core.RealtimeOp = RealtimeOpsBuilder.listInsert(value, path);

        return this.apply([op], options);
      }
    }

    return Promise.reject();
  }

  async listReplace(
    path: Path,
    value: any,
    options?: Realtime.Core.RealtimeSourceOptions,
  ): Promise<RealtimeFragment<T>> {
    if (path.length > 0) {
      const parentPath = path.slice(0, path.length - 1);
      const parentValue = this.get(parentPath);

      const oldValue = this.get(path);

      if (Array.isArray(parentValue) && value != null) {
        let op: Realtime.Core.RealtimeOp | null = null;

        if (oldValue === undefined) {
          op = RealtimeOpsBuilder.listInsert(value, path);
        } else if (!isEqual(value, oldValue)) {
          op = RealtimeOpsBuilder.listReplace(oldValue, value, path);
        }
        if (op) {
          return this.apply([op], options);
        }
        return Promise.resolve(this);
      }
    }

    return Promise.reject();
  }

  async listDelete(
    path: Path,
    options?: Realtime.Core.RealtimeSourceOptions,
  ): Promise<RealtimeFragment<T>> {
    if (path.length > 0) {
      const parentPath = path.slice(0, path.length - 1);
      const parentValue = this.get(parentPath);

      const oldValue = this.get(path);

      if (Array.isArray(parentValue) && oldValue !== undefined) {
        const op: Realtime.Core.RealtimeOp = RealtimeOpsBuilder.listDelete(oldValue, path);

        return this.apply([op], options);
      }
    }

    return Promise.reject();
  }

  findPath(prop: string | number, value: any): (string | number)[] {
    const path = [];

    let workingRoot: any = this.selectedData();

    if (workingRoot) {
      let keysArray = Object.keys(workingRoot);

      path.push({ value: workingRoot });

      while (keysArray.length > 0) {
        const key = keysArray[0];

        if (key === prop && workingRoot[key] === value) {
          // key found
          path.push({ key, value: workingRoot[key] });
          break;
        }

        if (
          workingRoot[key] != null &&
          (typeof workingRoot[key] === 'object' || Array.isArray(workingRoot[key])) &&
          Object.keys(workingRoot[key]).length > 0
        ) {
          // check childreen
          path.push({ key, value: workingRoot[key] });
          workingRoot = workingRoot[key];
          keysArray = Object.keys(workingRoot);
        } else {
          // remove this key from array
          keysArray.shift();

          while (keysArray.length === 0 && path.length > 1) {
            // continue cheking on parent keys
            const lastPath: any = path.pop();
            const parentPath = path[path.length - 1];

            const parentKeys = Object.keys(parentPath.value);
            const index = parentKeys.indexOf(lastPath.key);

            workingRoot = parentPath.value;
            keysArray = parentKeys.slice(index + 1, parentKeys.length);
          }
        }
      }
    }

    return path.reduce((array: (string | number)[], current: any) => {
      if (current.key) {
        array.push(current.key);
      }
      return array;
    }, []);
  }

  dispose(): void {
    this.removeEventListeners();
    /* if (this.model) {
      this.model.destroy();
    }

    super.destroy(); */
  }
}
