import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, ProgressBar } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import {
  selectManualMetadata,
  selectAutomaticMetadata,
  useGetMetadataListQuery,
} from 'App/redux/MetadataApi';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';
import { Setting } from 'Settings/components';

import UsernameLabel from '../Labels/UsernameLabel/UsernameLabel';
import IntlErrorBoundary from '../IntlErrorBoundary/IntlErrorBoundary';
import MetadataHeading from './SectionHeadings/MetadataHeading';
import EditMetadataHeading from './SectionHeadings/EditMetadataHeading';

import styles from './Metadata.module.scss';

type MetadataItemProps = {
  label: string;
  children: string | ReactNode | undefined | boolean;
};

type MetadataProps = {
  id: ObjectId;
  setting?: boolean;
};

const MetadataItem = ({ label, children }: MetadataItemProps) => (
  <div className={styles.metadataItemContainer} key={label}>
    <div className={styles.labelContainer}>{label}</div>
    <div className={`${styles.valueContainer} ${children === 'not set' && styles.noValue}`}>
      {children}
    </div>
  </div>
);

const Metadata = ({ id, setting }: MetadataProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.app.data);
  const object = appData[id];
  const { data: metadata, isLoading } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });

  const automaticMetadata = useSelector((state) => selectAutomaticMetadata(state, id));
  const manualMetadata = useSelector((state) => selectManualMetadata(state, id));
  const element = useSelector((state) => state.app.data[id]);
  const { data } = useGetElementStatusListQuery();
  const status =
    data?.entities[
      Object.entries(metadata)
        .filter((id) => metadata[id[0]]?.name === 'Content status')
        .map((id) => (element?.metadata && element?.metadata[id[0]]) || '')[0]
    ];
  const tagsSeparator = Object.entries(metadata)
    .filter((id) => metadata[id[0]]?.name === 'Tags')
    .map(([_, values]) => values)[0]?.separator;

  const handleEdit = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'EditMetadata',
        data: {
          id: element.id,
        },
      }),
    );
  };

  const METADATA = [
    {
      label: 'MANUAL_METADATA',
      metadataOptions: manualMetadata,
      id: 'manual',
    },
    {
      label: 'AUTOMATIC_METADATA',
      metadataOptions: automaticMetadata,
      id: 'automatic',
    },
  ];
  const handleValue = (field: { label: string; value: string }) => {
    if (field.label === 'Content author' || field.label === 'Assignee') {
      return <UsernameLabel userId={field.value} />;
    }
    if (field.label === 'Content status') {
      return status && status.name;
    }
    if (field.label === 'Tags') {
      return object.tags.length > 0
        ? object.tags.map((v: string) => v).join(tagsSeparator)
        : intl.formatMessage({ id: 'NOT_SET' });
    }
    if (Array.isArray(field.value)) {
      return field.value.length > 0
        ? field.value.map((v) => v).join(', ')
        : intl.formatMessage({ id: 'NOT_SET' });
    }
    return field.value;
  };

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <ProgressBar testId="metadata-progressBar" />
        <div className={styles.message}>
          <FormattedMessage id="LOADING_METADATA" />
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.container} ${setting && styles.setting}`}>
      {METADATA.map((option) => (
        <div key={option.label} className={styles.metadataGroup}>
          {setting ? (
            option.label !== 'AUTOMATIC_METADATA' ? (
              <IntlErrorBoundary fallbackType="sectionHeading">
                <EditMetadataHeading
                  option={option}
                  onEdit={handleEdit}
                  testId={`${option.id}-heading`}
                />
              </IntlErrorBoundary>
            ) : (
              <IntlErrorBoundary fallbackType="sectionHeading">
                <MetadataHeading option={option} testId={`${option.id}-heading`} />
              </IntlErrorBoundary>
            )
          ) : (
            <>
              <div className={styles.manualContainer} key={option.label}>
                <div className={styles.text}>
                  <FormattedMessage id={option.label} />
                </div>
                {option.label !== 'AUTOMATIC_METADATA' && (
                  <Button
                    variant="link"
                    onClick={handleEdit}
                    size="small"
                    testId="edit-metadata-button"
                  >
                    <FormattedMessage id="global.edit" />
                  </Button>
                )}
              </div>
              <Divider margin="0.5rem 0 2rem 0" />
            </>
          )}
          {option.metadataOptions.map((field, i) =>
            setting ? (
              <Setting
                key={field.label}
                label={field.label}
                value={
                  field.value || field.label === 'Tags' ? (
                    handleValue(field)
                  ) : (
                    <FormattedMessage id="NOT_SET" />
                  )
                }
                labelStyles={{ flex: 1, maxWidth: '36rem' }}
                valueStyles={{ paddingRight: '3rem', whiteSpace: 'nowrap', flex: 1 }}
                testId={`setting-${i}`}
              />
            ) : (
              <MetadataItem label={field.label} key={field.label}>
                {field.value || field.label === 'Tags'
                  ? handleValue(field)
                  : intl.formatMessage({ id: 'NOT_SET' })}
              </MetadataItem>
            ),
          )}
        </div>
      ))}
    </div>
  );
};

export default Metadata;
