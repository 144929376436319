import {
  createSlice,
  PayloadAction,
  createSelector,
  createAsyncThunk,
  isAnyOf,
} from '@reduxjs/toolkit';

import { EditorService, Logger } from '_common/services';
import type { Data } from 'Editor/modals/ExportIntegrationModal/ExportIntegrationModalTypes';
import type { ExportIntegrationModal as ExportIntegrationModalTypes } from 'Editor/modals/ExportIntegrationModal/ExportIntegrationModalTypes';

import { closeModal, openAndUpdateModal, updateModal } from '_common/modals/ModalsSlice';
import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';
import authority from '_common/services/api/authority';

type CurrentIntegrationSliceState = Data;

const SLICE_NAME = 'EDITOR_CURRENT_INTEGRATION';

const initialState: CurrentIntegrationSliceState = {
  instances: [],
  content: {
    list: [],
    dict: {},
  },
  selected: '',
  current: {
    id: 'allFiles',
    name: '',
  },
  path: [],
  loading: false,
  exportTemplate: {
    template: '',
    format: '',
    keep_comments: false,
    keep_suggestions: false,
  },
  hasNextPage: false,
  isNextPageLoading: true,
  searchValue: '',
  offset: 0,
};

// #region AsyncThunks
type Object = {
  id: 'allFiles' | 'recent' | 'favourites';
  name: string;
};

type ActionPayloadValues = {
  object: Object;
  data: {
    extension: string;
    id: string;
    name: string;
    type: string;
  }[];
  isRoot?: boolean;
  isEmpty?: boolean;
  location?: string;
  isPrevPath?: boolean;
  offset?: number;
  next?: boolean;
  nextSearch?: boolean;
  lazyLoadindSearch?: { query: string; container: string };
};

// ------------ netDocuments Integration ----------------
// - Get cabinet list.
export const getCabinetList = createAsyncThunk(
  `${SLICE_NAME}/getCabinetList`,
  async (token: string, { dispatch }) => {
    dispatch(setIntegrationLoading(true));
    try {
      const { data } = await new EditorService({ errorsExpected: [401] }).getCabinetList(token);
      // @ts-expect-error API not fully typed yet
      dispatch(updateCabinetList(data));
      dispatch(
        updateModal({
          modal: 'ExportIntegrationModal',
          data: {
            needLogin: false,
          },
        }),
      );
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch(
            updateModal({
              modal: 'ExportIntegrationModal',
              data: {
                needLogin: true,
              },
            }),
          );
        } else {
          Logger.captureException(error);
        }
      }
    }
  },
);

// - Get cabinet content objects.
export const getCabinetContent = createAsyncThunk(
  `${SLICE_NAME}/getCabinetContent`,
  async (
    {
      object,
      params,
    }: {
      object: Object;
      params: { size: number };
    },
    { dispatch },
  ) => {
    dispatch(setIntegrationLoading(true));

    try {
      const { data } = await new EditorService().getCabinetContent(object.id, params);

      dispatch(
        updateCurrentIntegrationData({
          object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          next: data.next,
          isRoot: true,
        }),
      );
      // @ts-expect-error API not fully typed yet
      if (data.next) {
        dispatch(lazyLoadIntegration());
      }
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

// - Get content of folder.
export const listFolderContent = createAsyncThunk(
  `${SLICE_NAME}/listFolderContent`,
  async (
    parameters: {
      object: Object;
      params: { size: number };
      location?: boolean;
      isEmpty?: boolean;
      isPrevPath?: boolean;
    },
    { dispatch },
  ) => {
    dispatch(setIntegrationLoading(true));
    try {
      const { data } = await new EditorService().listFolderContent(
        parameters.object.id,
        parameters.params,
      );

      dispatch(
        updateCurrentIntegrationData({
          object: parameters.object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          next: data.next,
          isEmpty: parameters.isEmpty,
          isPrevPath: parameters.isPrevPath,
        }),
      );
      // @ts-expect-error API not fully typed yet
      if (data.next) {
        dispatch(lazyLoadIntegration());
      }
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

export const lazyloadFolderContent = createAsyncThunk(
  `${SLICE_NAME}/getCabinetList`,
  async (
    {
      object,
      params,
    }: {
      object: Object;
      params: { size: number };
    },
    { dispatch },
  ) => {
    try {
      const { data } = await new EditorService().listFolderContent(object.id, params);

      dispatch(
        folderContentLazyloaded({
          object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          next: data.next,
        }),
      );
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

export const lazyloadCabinetContent = createAsyncThunk(
  `${SLICE_NAME}/getCabinetList`,
  async ({ object, params }: { object: Object; params: { size: number } }, { dispatch }) => {
    try {
      const { data } = await new EditorService().getCabinetContent(object.id, params);

      dispatch(
        folderContentLazyloaded({
          object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          next: data.next,
        }),
      );
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

export const lazyloadSearch = createAsyncThunk(
  `${SLICE_NAME}/getCalazyloadSearchbinetList`,
  async (
    {
      id,
      params,
      form,
    }: {
      id: 'allFiles' | 'recent' | 'favourites';
      params: { size: number; next: boolean };
      form: { query: string; object: Object; container: string };
    },
    { dispatch },
  ) => {
    try {
      const { data } = await new EditorService().searchNetDocuments(id, params, form);

      dispatch(
        folderContentLazyloaded({
          object: form.object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          nextSearch: data.next,
        }),
      );
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

// - Get content of Workspace
export const listWorkspaceContent = createAsyncThunk(
  `${SLICE_NAME}/listWorkspaceContent`,
  async ({ object, isPrevPath = false }: { object: Object; isPrevPath: boolean }, { dispatch }) => {
    dispatch(setIntegrationLoading(true));

    try {
      const { data } = await new EditorService().listWorkspaceContent(object.id);
      // @ts-expect-error API not fully typed yet
      dispatch(updateCurrentIntegrationData({ object, data, isPrevPath }));
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

// - Get content of Favourites
export const listFavouritesContent = createAsyncThunk(
  `${SLICE_NAME}/listFavouritesContent`,
  async (object: { id: string }, { dispatch }) => {
    dispatch(setIntegrationLoading(true));

    try {
      const { data } = await new EditorService().listFavouritesContent(object.id);
      // @ts-expect-error API not fully typed yet
      dispatch(updateCurrentIntegrationData({ object, data, isRoot: true }));
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

// - Get content of Recents
export const listRecentsContent = createAsyncThunk(
  `${SLICE_NAME}/listRecentsContent`,
  async (object: { id: string }, { dispatch }) => {
    dispatch(setIntegrationLoading(true));

    try {
      const { data } = await new EditorService().listRecentsContent(object.id);
      // @ts-expect-error API not fully typed yet
      dispatch(updateCurrentIntegrationData({ object, data, isRoot: true }));
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

export const exportToNetDocuments = createAsyncThunk(
  `${SLICE_NAME}/exportToNetDocuments`,
  async ({ documentId, options }: { documentId: string; options: MyAny }, { dispatch }) => {
    try {
      const { data } = await new EditorService({ errorsExpected: [400] }).exportDocument(
        documentId,
        options,
      );

      dispatch(
        openAndUpdateModal({
          modal: 'ExportDocumentModal',
          data: { exporting: data.id },
        }),
      );
      dispatch(closeModal('ExportIntegrationModal'));
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          dispatch(closeModal('ExportIntegrationModal'));
          dispatch(
            openAndUpdateModal({
              modal: 'ConfirmationModal',
              data: {
                message: 'NET_DOCUMENTS_EXPORT_ERROR',
                title: 'NET_DOCUMENTS_EXPORT_ERROR_TITLE',
                titleValues: { values: options.name },
                cancelButtonShow: false,
                actionCode: 'backToEditor',
                confirmButtonTextId: 'BACK_TO_EDITOR',
                confirmButtonType: 'danger',
                headerType: 'error',
                size: 'medium',
                cancelButtonTextId: 'global.cancel',
              },
            }),
          );
        }
      }
    }
  },
);

export const searchNetDocuments = createAsyncThunk(
  `${SLICE_NAME}/searchNetDocuments`,
  async (
    {
      id,
      params,
      form,
      isEmpty = true,
    }: {
      id: string;
      params: { size: number; next: boolean };
      form: { query: string; object: Object; container: string };
      isEmpty?: boolean;
    },
    { dispatch },
  ) => {
    dispatch(setIntegrationLoading(true));

    try {
      const { data } = await new EditorService().searchNetDocuments(id, params, form);
      // @ts-expect-error API not fully typed yet
      if (!data.next) {
        dispatch(
          updateCurrentIntegrationData({
            object: form.object,
            // @ts-expect-error API not fully typed yet
            data: data.elements,
            // @ts-expect-error API not fully typed yet
            nextSearch: data.next,
            isEmpty,
          }),
        );
      } else {
        dispatch(
          updateCurrentIntegrationData({
            object: form.object,
            // @ts-expect-error API not fully typed yet
            data: data.elements,
            // @ts-expect-error API not fully typed yet
            nextSearch: data.next,
            lazyLoadindSearch: {
              query: form.query,
              container: form.container,
            },
            isEmpty,
          }),
        );
        dispatch(lazyLoadIntegration());
      }
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

// ------------------------------------------------------
// ------------------ Box Integration -------------------
// - Get root folders.
export const getBoxAllFiles = createAsyncThunk(
  `${SLICE_NAME}/getBoxAllFiles`,
  async (
    { object, params }: { object: Object; params: { size: number } },
    { dispatch, getState },
  ) => {
    dispatch(setIntegrationLoading(true));

    try {
      const { data } = await new EditorService({ errorsExpected: [401] }).getBoxAllFiles(params);
      const waitingValidation = (getState() as RootState).modals.ExportIntegrationModal
        .waitingValidation;
      if (waitingValidation) {
        dispatch(
          updateModal({
            modal: 'ExportIntegrationModal',
            data: {
              waitingValidation: false,
            },
          }),
        );
      }
      dispatch(
        updateCurrentIntegrationData({
          object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          next: data.next,
          isRoot: true,
        }),
      );
      // @ts-expect-error API not fully typed yet
      if (data.next) {
        dispatch(lazyLoadIntegration());
      }
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          //Assuming that the current user was previously fetched
          const { data: currentUser } = authority.endpoints.getCurrentUser.select()(
            getState() as RootState,
          );

          const currentTenant = currentUser?.other.tenants?.find((tenant) => tenant.current);

          //Assuming that the links were previously fetched
          const { data } = authority.endpoints.getLinks.select({
            userId: currentUser?.profile.id ?? '',
            tenant: currentTenant?.name ?? '',
          })(getState() as RootState);

          const link = data?.providers?.find((link) => link.name === 'box');
          const userId = (getState() as RootState).auth.userId;
          const url = `${link?.url}&state=${userId}`;
          window.open(url, '_blank');
        } else {
          Logger.captureException(error);
        }
      }
    }
  },
);

export const lazyBoxAllFiles = createAsyncThunk(
  `${SLICE_NAME}/lazyBoxAllFiles`,
  async (params: { current: Object; params: { size: number; next?: boolean } }, { dispatch }) => {
    try {
      const { data } = await new EditorService().getBoxAllFiles({
        size: params.params.size,
        // @ts-expect-error API not fully typed yet
        next: params.params.next,
      });
      dispatch(
        folderContentLazyloaded({
          object: params.current,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          next: data.next,
        }),
      );
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

// - Get content of folder.
export const listBoxFolderContent = createAsyncThunk(
  `${SLICE_NAME}/listBoxFolderContent`,
  async (
    params: { object: Object; params: { size: number }; isPrevPath?: boolean; isEmpty?: boolean },
    { dispatch },
  ) => {
    dispatch(setIntegrationLoading(true));
    try {
      const { data } = await new EditorService().listBoxFolderContent(
        params.object.id,
        params.params,
      );

      dispatch(
        updateCurrentIntegrationData({
          object: params.object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          next: data.next,
          isEmpty: params.isEmpty,
          isPrevPath: params.isPrevPath,
        }),
      );
      // @ts-expect-error API not fully typed yet
      if (data.next) {
        dispatch(lazyLoadIntegration());
      }
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

export const lazyloadBoxFolderContent = createAsyncThunk(
  `${SLICE_NAME}/lazyloadBoxFolderContent`,
  async (
    { object, params }: { object: Object; params: { size: number; next?: boolean } },
    { dispatch },
  ) => {
    try {
      const { data } = await new EditorService().listBoxFolderContent(object.id, {
        size: params.size,
        // @ts-expect-error API not fully typed yet
        next: params.next,
      });

      dispatch(
        folderContentLazyloaded({
          object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          next: data.next,
        }),
      );
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

// - Get content of Recents
export const listBoxRecentsContent = createAsyncThunk(
  `${SLICE_NAME}/listBoxRecentsContent`,
  async ({ object, params }: { object: Object; params: { size: number } }, { dispatch }) => {
    dispatch(setIntegrationLoading(true));

    try {
      const { data } = await new EditorService().listBoxRecentsContent(params);

      dispatch(
        updateCurrentIntegrationData({
          object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          next: data.next,
          isRoot: true,
        }),
      );
      // @ts-expect-error API not fully typed yet
      if (data.next) {
        dispatch(lazyLoadIntegration());
      }
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

export const lazyRecentContent = createAsyncThunk(
  `${SLICE_NAME}/lazyRecentContent`,
  async (
    { object, params }: { object: Object; params: { size: number; next: boolean } },
    { dispatch },
  ) => {
    try {
      const { data } = await new EditorService().listBoxRecentsContent({
        size: params.size,
        // @ts-expect-error API not fully typed yet
        next: params.next,
      });

      dispatch(
        folderContentLazyloaded({
          object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          next: data.next,
        }),
      );
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

// - Get content of Box Favourites
export const listBoxFavouritesContent = createAsyncThunk(
  `${SLICE_NAME}/listBoxFavouritesContent`,
  async (
    { object, params }: { object: Object; params: { size: number; offset: number } },
    { dispatch },
  ) => {
    dispatch(setIntegrationLoading(true));

    try {
      const { data } = await new EditorService().listBoxFavouritesContent(params);

      dispatch(
        updateCurrentIntegrationData({
          object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          isRoot: true,
          offset: 0 + params.size,
        }),
      );
      // @ts-expect-error API not fully typed yet
      if (data.elements.length === params.size) {
        dispatch(lazyLoadIntegration());
      }
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

export const lazyFavouritesContent = createAsyncThunk(
  `${SLICE_NAME}/lazyFavouritesContent`,
  async (
    { object, params }: { object: Object; params: { size: number; offset: number } },
    { dispatch, getState },
  ) => {
    const offset = (getState() as RootState).editor.currentIntegration.offset;

    try {
      const { data } = await new EditorService().listBoxFavouritesContent({
        size: params.size,
        // @ts-expect-error API not fully typed yet
        offset,
      });
      const lazyData = {
        object,
        // @ts-expect-error API not fully typed yet
        data: data.elements,
      };
      // @ts-expect-error API not fully typed yet
      if (data.elements.length === params.size) {
        // @ts-expect-error API not fully typed yet
        lazyData.offset = offset + params.size;
      }
      dispatch(folderContentLazyloaded(lazyData));
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

export const exportToBox = createAsyncThunk(
  `${SLICE_NAME}/exportToBox`,
  async ({ documentId, options }: { documentId: string; options: MyAny }, { dispatch }) => {
    try {
      const { data } = await new EditorService({ errorsExpected: [400] }).exportToBox(
        documentId,
        options,
      );

      dispatch(
        updateModal({
          modal: 'ExportDocumentModal',
          // @ts-expect-error API not fully typed yet
          data: { exporting: data.id },
        }),
      );
      dispatch(closeModal('ExportIntegrationModal'));
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          dispatch(closeModal('ExportIntegrationModal'));
          dispatch(
            openAndUpdateModal({
              modal: 'ConfirmationModal',
              data: {
                message: 'NET_DOCUMENTS_EXPORT_ERROR',
                title: 'NET_DOCUMENTS_EXPORT_ERROR_TITLE',
                titleValues: { values: options.name },
                cancelButtonShow: false,
                actionCode: 'backToEditor',
                confirmButtonTextId: 'BACK_TO_EDITOR',
                confirmButtonType: 'danger',
                headerType: 'error',
                size: 'medium',
                cancelButtonTextId: 'global.cancel',
              },
            }),
          );
        }
      }
    }
  },
);

// - Search content inside Box.
export const searchBox = createAsyncThunk(
  `${SLICE_NAME}/searchBox`,
  async (
    {
      params,
      form,
      isEmpty = true,
    }: {
      params: { size: number; offset: number };
      form: { query: string; object: Object; container: string };
      isEmpty?: boolean;
    },
    { dispatch },
  ) => {
    dispatch(setIntegrationLoading(true));

    try {
      const { data } = await new EditorService().searchBox(params, form);
      // @ts-expect-error API not fully typed yet
      if (!data.next) {
        dispatch(
          updateCurrentIntegrationData({
            object: form.object,
            // @ts-expect-error API not fully typed yet
            data: data.elements,
            // @ts-expect-error API not fully typed yet
            nextSearch: data.next,
            isEmpty,
          }),
        );
      } else {
        dispatch(
          updateCurrentIntegrationData({
            object: form.object,
            // @ts-expect-error API not fully typed yet
            data: data.elements,
            // @ts-expect-error API not fully typed yet
            nextSearch: data.next,
            lazyLoadindSearch: {
              query: form.query,
              container: form.container,
            },
            isEmpty,
          }),
        );
        dispatch(lazyLoadIntegration());
      }
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

export const lazyloadBoxSearch = createAsyncThunk(
  `${SLICE_NAME}/lazyloadBoxSearch`,
  async (
    {
      params,
      form,
    }: {
      params: { size: number; offset: number; next?: boolean };
      form: { query: string; object: Object; container: string };
    },
    { dispatch },
  ) => {
    try {
      const { data } = await new EditorService().searchBox(params, form);

      dispatch(
        folderContentLazyloaded({
          object: form.object,
          // @ts-expect-error API not fully typed yet
          data: data.elements,
          // @ts-expect-error API not fully typed yet
          nextSearch: data.next,
        }),
      );
    } catch (error) {
      Logger.captureException(error);
    }
  },
);
// #endregion

// #region Selectors
const getCurrentIntegrationObjects = (state: RootState) => state.editor.currentIntegration;
const getExportType = (state: RootState) => state.modals.ExportIntegrationModal.exportType;

export const normalizeNewDocuments = createSelector(
  [getCurrentIntegrationObjects, getExportType],
  (objects, exportType) => {
    const newObjects: CurrentIntegrationSliceState = {
      ...objects,
      content: { ...objects.content, list: [], dict: {} },
    };

    objects.content.list.forEach((id) => {
      const element = { ...objects.content.dict[id] };
      let type = element.type || '';
      if (exportType === 'netdocuments') {
        if (element.extension === 'ndfld' || element.extension === 'fld') {
          type = 'folder';
        } else if (element.extension === 'ndws' || element.extension === 'wsp') {
          type = 'workspace';
        } else if (element.extension === 'docx') {
          type = 'document';
        } else if (element.extension === 'zip' || element.extension === 'rar') {
          type = 'file';
        }
      }

      newObjects.content.dict[id] = { ...objects.content.dict[id], type };
      newObjects.content.list.push(id);
    });

    return newObjects;
  },
);
// #endregion

// #region Slice
const CurrentIntegrationSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateSearchValue: (
      state,
      action: PayloadAction<CurrentIntegrationSliceState['searchValue']>,
    ) => {
      state.searchValue = action.payload;
    },
    resetIntegrationData: (state) => {
      state.instances = [];
      state.content = {
        list: [],
        dict: {},
      };
      state.selected = '';
      state.current = {
        id: 'allFiles',
        name: '',
      };
      state.path = [];
      state.loading = false;
      state.exportTemplate = {
        template: '',
        format: '',
        keep_comments: false,
        keep_suggestions: false,
      };
      state.hasNextPage = false;
      state.isNextPageLoading = true;
      state.searchValue = '';
      state.offset = 0;
    },
    setIntegrationLoading: (
      state,
      action: PayloadAction<CurrentIntegrationSliceState['loading']>,
    ) => {
      state.loading = action.payload;
    },
    updateSelectedObject: (state, action) => {
      state.selected = action.payload.id;
      state.loading = false;
    },
    lazyLoadIntegration: (state) => {
      state.hasNextPage = true;
      state.isNextPageLoading = false;
    },
    folderContentLazyloaded: (state, action: PayloadAction<ActionPayloadValues>) => {
      const { object, data, nextSearch, next, offset } = action.payload;
      const moreListItems = data.map((element) => element.id);
      const moreDictItems = data.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {});
      state.current = { ...object };
      state.loading = false;
      state.selected = '';
      state.isNextPageLoading = !(nextSearch || next || offset);
      state.hasNextPage = !!(nextSearch || next || offset);
      state.content = {
        ...state.content,
        list: [...state.content.list, ...moreListItems],
        dict: {
          ...state.content.dict,
          ...moreDictItems,
        },
      };
      state.offset = offset ?? 0;
      state.next = next;
      state.nextSearch = nextSearch;
    },
    updateCurrentIntegrationData: (state, action: PayloadAction<ActionPayloadValues>) => {
      const { object, data, isRoot, isEmpty, location, isPrevPath, offset, next, nextSearch } =
        action.payload;
      const list = data.map((element) => element.id);
      const dict = data.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {});
      let newPath = [];
      if (isRoot) {
        newPath = [{ ...object }];
      } else if (isPrevPath) {
        newPath = [...state.path];
        newPath.pop();
      } else {
        newPath = [...state.path, { ...object }];
      }

      if (location) {
        state.content.list = list;
        state.content.dict = dict;
        state.current = { ...object };
        state.path = newPath;
        state.loading = false;
        state.selected = '';
        state.offset = offset ?? state.offset;
        state.next = next;
      } else {
        state.content.list = list;
        state.content.dict = dict;
        state.current = { ...object };
        state.path = isEmpty ? [...state.path] : newPath;
        state.loading = false;
        state.selected = '';
        state.hasNextPage = false;
        state.offset = offset ?? state.offset;
        state.next = next;
        state.nextSearch = nextSearch;
      }
    },
    updateCabinetList: (
      state,
      action: PayloadAction<{ [key: string]: ExportIntegrationModalTypes.Element[] }>,
    ) => {
      const instances = Object.keys(action.payload).map((key) => ({
        key,
        value: action.payload[key],
      }));
      state.needLogin = false;
      state.instances = instances;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export default CurrentIntegrationSlice.reducer;
// #endregion

// #region Actions
export const {
  updateSearchValue,
  resetIntegrationData,
  setIntegrationLoading,
  updateSelectedObject,
  updateCabinetList,
  updateCurrentIntegrationData,
  lazyLoadIntegration,
  folderContentLazyloaded,
} = CurrentIntegrationSlice.actions;
// #endregion
