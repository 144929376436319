import { memo } from 'react';
import { NotificationsCenter } from '_common/components';
import { useSuiteObject } from '_common/suite';
import {
  AvatarList,
  EditableName,
  ExportButton,
  Logo,
  ObjectStatus,
  ShareButton,
  Title,
  UserCenter,
} from '_common/suite/components';
import { useUsersSync } from './SyncStore';

const PresentationTitle = () => {
  const usersOnline = useUsersSync();
  const object = useSuiteObject();
  return (
    <Title divider>
      <Logo object={object} />
      <EditableName object={object} />
      <ObjectStatus object={object} />
      <AvatarList users={usersOnline ?? []} />
      <ShareButton object={object} />
      <ExportButton object={object} />
      <NotificationsCenter object={object} margin="0 0 0 2rem" />
      <UserCenter />
    </Title>
  );
};

export default memo(PresentationTitle);
