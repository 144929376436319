// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { Logger } from '_common/services';
import { Transport } from '_common/services/Realtime/Transport';
import {
  DocumentController,
  EventsController,
  ModelsController,
  StructureController,
  UsersController,
  ThemesController,
} from './controllers';
import * as Util from './Util';

export function DataManager(transport: Transport): Presentation.Data.API {
  const Data: Presentation.Data.State = {
    transport: transport,
    context: {
      version: null,
    },
  };

  let ready = false;
  let documentId: string;
  let user: Realtime.Core.User;

  const events: EventsController = new EventsController();
  const users: UsersController = new UsersController(Data);
  const models: ModelsController = new ModelsController(Data);
  const document: DocumentController = new DocumentController(Data);
  const structure: StructureController = new StructureController(Data);
  const themes: ThemesController = new ThemesController(Data);

  const _transportEvents: any = {
    // 'FORCE:REMOTE:RELOAD': _handleEventForceRemoteReload,
  };

  async function start(docId: string, u: any) {
    user = u;
    documentId = docId;
    Data.context.documentId = docId;
    Logger.debug('DATA MANAGER START');

    const eventKeys = Object.keys(_transportEvents);
    for (let i = 0; i < eventKeys.length; i++) {
      const event: Realtime.Transport.ServerEventName = eventKeys[
        i
      ] as Realtime.Transport.ServerEventName;
      Data.transport.handleEvent(event, _transportEvents[event]);
    }

    await users.start(
      documentId,
      // @ts-expect-error
      {
        id: documentId,
      },
      user,
    );
    await models.start();
    await document.start(documentId);
    await structure.start(documentId);
    await themes.start(documentId);

    ready = true;
  }

  function stop() {
    ready = false;
    Logger.debug('DATA MANAGER NOT READY');

    // destroy controllers
    events.destroy();
    models.destroy();
    document.destroy();
    structure.destroy();
    themes.destroy();
  }

  function destroy() {
    stop();
  }

  const API: Presentation.Data.API = {
    isReady: () => ready,
    start,
    stop,
    destroy,
    on: events.on.bind(events),
    off: events.off.bind(events),
    events,
    models,
    document,
    structure,
    users,
    themes,
  };

  Util.extend(Data, {
    ...API,
    emit: events.emit.bind(events),
  });

  return API;
}
