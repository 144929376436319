import { Mixin } from 'mixwith';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class JoinContentHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       * join multi selection content
       * @param {ActionContext} actionContext
       * @param {Array} extraBlocksToJoin
       */
      joinSelectionContent(actionContext, extraBlocksToJoin = []) {
        // TODO: carefull with node permissions on join nodes

        let range;
        let anchorNode;
        let focusNode;

        if (this.removeSelectionContent(actionContext)) {
          const blocksToJoin = [
            ...EditorDOMElements.DEFAULT_SPLITABLE_LEVEL0_ELEMENTS,
            ...extraBlocksToJoin,
          ];

          range = EditorSelectionUtils.getRange();

          // check selection direction
          anchorNode = range.startContainer;
          focusNode = range.endContainer;

          const anchorClosest = EditorDOMUtils.closest(anchorNode, blocksToJoin);
          const focusClosest = EditorDOMUtils.closest(focusNode, blocksToJoin);

          // copy children from last to first
          if (anchorClosest && focusClosest && anchorClosest !== focusClosest) {
            // remove BR if paragraph empty
            const anchorBR = anchorClosest.querySelector('br');
            if (anchorBR) {
              // Not needed to register node in action context
              anchorBR.remove();
            }

            // join children
            const focusBR = focusClosest.querySelector('br');
            if (focusBR) {
              // Not needed to register node in action context
              focusBR.remove();
            }

            if (
              !EditorDOMUtils.isEmptyElement(anchorClosest) ||
              anchorClosest.childNodes.length > 0 ||
              this.dataManager.numbering.isListElement(anchorClosest.id)
            ) {
              // if anchor node its not empty join them
              while (focusClosest.firstChild) {
                let hasImages =
                  focusClosest.firstChild instanceof Element &&
                  (focusClosest.firstChild.nodeName === ELEMENTS.ImageElement.TAG ||
                    focusClosest.firstChild.querySelector(ELEMENTS.ImageElement.TAG));
                if (
                  (!EditorDOMUtils.isEmptyElement(focusClosest.firstChild) &&
                    focusClosest.firstChild.nodeName !== 'BR') ||
                  hasImages ||
                  focusClosest.firstChild.nodeName === ELEMENTS.PasteMarkerElement.TAG ||
                  this.isAddParagraphMarker(focusClosest.firstChild) ||
                  this.isDeleteParagraphMarker(focusClosest.firstChild)
                ) {
                  anchorClosest.appendChild(focusClosest.firstChild);
                } else {
                  focusClosest.firstChild.remove();
                }
              }

              // register nodes in action context
              actionContext.addChangeUpdatedNode(anchorClosest);

              this.removeBlockElementFromDOM(actionContext, focusClosest);
            } else {
              // anchor node is empty
              range.collapseToEnd();
              this.removeBlockElementFromDOM(actionContext, anchorClosest);
            }
          }

          // set caretEditorSelectionUtils.getSelection();node if exists
          range = EditorSelectionUtils.getRange();
          anchorNode = range.startContainer;

          if (anchorNode.nodeType === Node.TEXT_NODE && anchorNode.textContent === '') {
            anchorNode.remove();
          }

          // add br if paragraph is empty
          if (
            anchorClosest &&
            EditorDOMUtils.isEmptyElement(anchorClosest) &&
            anchorClosest.childNodes.length === 0 &&
            !anchorClosest.querySelector('br')
          ) {
            let node = anchorClosest;
            while (node.firstChild && node.firstChild.nodeType === Node.ELEMENT_NODE) {
              node = node.firstChild;
            }
            EditorDOMUtils.appendNode(node, DOMElementFactory.buildElement('BR'));
          }

          this.joinCitationGroupsContent(actionContext, anchorClosest);

          // get final selection and set caret
          range = EditorSelectionUtils.getRange();
          anchorNode = range.startContainer;
          const anchorOffset = range.startOffset;

          if (
            EditorDOMUtils.isClosestTextElementEditable(anchorNode) &&
            anchorNode.childNodes[anchorOffset - 1] &&
            anchorNode.childNodes[anchorOffset - 1].tagName !== 'BR'
          ) {
            if (
              EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS.includes(
                anchorNode.childNodes[anchorOffset - 1].nodeName,
              )
            ) {
              this.selectionManager.setCaret(anchorNode.childNodes[anchorOffset - 1], 'POST');
            } else {
              this.selectionManager.setCaret(anchorNode.childNodes[anchorOffset - 1], 'INSIDE_END');
            }
          }

          return true;
        }
        return false;
      }
    },
);
