import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon, Select } from 'dodoc-design-system';
import { v4 as uuid } from 'uuid';

import { Logger } from '_common/services';
import { useEffectOnUpdate } from '_common/hooks';

import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import {
  FILTER_OPTIONS,
  CONDITION_TYPES,
  SelectOption,
  OPTION_SEQUENCE,
  AdvancedFilter,
  SequenceOptions,
  FilterOption,
} from './AdvancedFilterOptions';
import AdvancedFilterContext from './AdvancedFilterContext';

import GenericHandler from './Handlers/GenericHandler';
import MetadataHandler from './Handlers/MetadataHandler';
import MimeTypeHandler from './Handlers/MimeTypeHandler';
import RefPriorityHandler from './Handlers/RefPriorityHandler';
import StatusHandler from './Handlers/StatusHandler';

import styles from './AdvancedFilterRow.module.scss';

type AdvancedFilterRowProps = {
  // Callback to inform when the filter has been changed manually
  onManualChange?: () => void;
  /** ID to be used in unit or automated tests.\
   * Will result in 2 different attributes:
   * * ${testId}-generic
   * * ${testId}-mimetype
   * * ${testId}-metadata
   * * ${testId}-status
   * * ${testId}-refpriority
   * * ${testId}-filter-by
   * * ${testId}-filter-operator
   * * ${testId}-remove-filter
   * * ${testId}-add-filter
   */
  testId?: string;
} & (
  | {
      /**
       * Use query mode when you want to build a complex query
       * Use filter when you want to have a group of filters
       */
      type: 'query';
      /** When query mode, conditions selection and "Add" button are rendered,
       * which when clicked the query section built in the moment will be sent to this callback
       */
      onAddQuery: (newQuerySection: string, conditionType: string) => void;
      // Initial state of the filter
      initialFilter?: undefined;
      // Whenever the filter changes and is valid (has a query), the updated version is sent through this callback
      onFilterChange?: undefined;
      // Define is filter is removable (renders X icon)
      removable?: undefined;
      // If removable this callback should be passed
      onRemoveFilter?: undefined;
    }
  | {
      type: 'filter';
      onAddQuery?: undefined;
      initialFilter?: AdvancedFilter;
      onFilterChange: (newFilter: AdvancedFilter) => void;
      removable?: boolean;
      onRemoveFilter?: (removingFilterId: AdvancedFilter['id']) => void;
    }
);

const AdvancedFilterRow = ({ onManualChange, testId, ...props }: AdvancedFilterRowProps) => {
  const intl = useIntl();

  const { data: metadata } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });

  //#region Translated constants
  const TRANSLATED_OPTIONS = useMemo(
    () =>
      FILTER_OPTIONS.reduce<SelectOption[]>((optionsList, currOption) => {
        //If is metadata option and there is no metadata, don't include metadata as an option
        if (currOption.value === 'metadata' && Object.keys(metadata).length <= 0) {
          return optionsList;
        }

        optionsList.push({
          ...currOption,
          label: intl.formatMessage({
            id: currOption.label,
          }),
        });

        return optionsList;
      }, []),
    [metadata],
  );
  const TRANSLATED_SEQUENCES = useMemo(() => {
    const translatedSequences: { [key in FilterOption]?: SequenceOptions } = {};

    Object.typedKeys(OPTION_SEQUENCE).forEach((filterOption) => {
      const sequenceOptions = OPTION_SEQUENCE[filterOption].options;

      translatedSequences[filterOption] = {
        ...OPTION_SEQUENCE[filterOption],
        options: sequenceOptions.map((option: SelectOption) => ({
          ...option,
          label: intl.formatMessage({
            id: option.label,
          }),
        })),
      };
    });

    return translatedSequences;
  }, []);
  //#endregion

  const [advancedFilter, setAdvancedFilter] = useState<AdvancedFilter>(
    props.initialFilter ?? {
      id: uuid(),
      option: FILTER_OPTIONS[0].value,
    },
  );

  useEffect(() => {
    if (props.type === 'filter') {
      props.onFilterChange(advancedFilter);
    }
  }, [advancedFilter]);

  //#region AdvancedFilter properties cleanup
  useEffectOnUpdate(() => {
    setOperator(undefined);
    setMetadataOption(undefined);

    setValue(undefined);
    setAuxValue(undefined);
    setQuery(undefined);
  }, [advancedFilter.option]);

  useEffectOnUpdate(() => {
    setValue(undefined);
    setAuxValue(undefined);
    setQuery(undefined);
  }, [advancedFilter.operator]);

  useEffectOnUpdate(() => {
    setOperator(undefined);

    setValue(undefined);
    setAuxValue(undefined);
    setQuery(undefined);
  }, [advancedFilter.metadataOption]);
  //#endregion

  const sequence: SequenceOptions | undefined = useMemo(
    () => TRANSLATED_SEQUENCES[advancedFilter.option],
    [advancedFilter.option],
  );

  const [conditionType, setConditionType] = useState(CONDITION_TYPES[0]);
  //#region Filter fields handlers

  const handleConditionChange = (newCondition: SelectOption) => {
    setConditionType(newCondition);
  };
  //#endregion

  const handleAddToQuery = () => {
    if (advancedFilter.query && props.type === 'query') {
      props.onAddQuery(advancedFilter.query, conditionType.value);
    }
  };

  const handleRemoveFilter = () => {
    if (props.type === 'filter' && props.onRemoveFilter) {
      props.onRemoveFilter(advancedFilter.id);
    }
  };

  const renderSequenceBehaviour = () => {
    if (!sequence?.type) {
      return null;
    }

    switch (sequence.type) {
      case 'string':
      case 'date':
        return <GenericHandler sequence={sequence} testId={`${testId}-generic`} />;
      case 'dynamic':
        //Handled dynamic types
        switch (advancedFilter.option) {
          case 'mime_type':
            return <MimeTypeHandler sequence={sequence} testId={`${testId}-mimetype`} />;
          case 'metadata':
            return <MetadataHandler testId={`${testId}-metadata`} />; //This handler does not need sequence since metadata is defined dynamically
          case 'status':
            return <StatusHandler sequence={sequence} testId={`${testId}-status`} />;
          case 'reference_priority':
            return <RefPriorityHandler sequence={sequence} testId={`${testId}-refpriority`} />;
          default:
            Logger.error(
              'AdvancedFilterRow - Trying to render unhandled dynamic filter of type',
              advancedFilter.option,
            );
            return null;
        }
    }
  };

  const handleChangeOption = (selectNewValue: SelectOption) => {
    setOption(selectNewValue.value as FilterOption);
  };

  //#region Advanced Filter handlers
  const setOption = (newOption: AdvancedFilter['option']) => {
    onManualChange?.();
    setAdvancedFilter((prevFilter) => ({ ...prevFilter, option: newOption }));
  };
  const setOperator = (newOperator: AdvancedFilter['operator']) => {
    onManualChange?.();
    setAdvancedFilter((prevFilter) => ({ ...prevFilter, operator: newOperator }));
  };
  const setMetadataOption = (newOption: AdvancedFilter['metadataOption']) => {
    onManualChange?.();
    setAdvancedFilter((prevFilter) => ({ ...prevFilter, metadataOption: newOption }));
  };
  const setValue = (newValue: AdvancedFilter['value']) => {
    onManualChange?.();
    setAdvancedFilter((prevFilter) => ({ ...prevFilter, value: newValue }));
  };
  const setAuxValue = (newValue: AdvancedFilter['auxValue']) => {
    onManualChange?.();
    setAdvancedFilter((prevFilter) => ({ ...prevFilter, auxValue: newValue }));
  };
  const setQuery = (newQuery: AdvancedFilter['query']) => {
    setAdvancedFilter((prevFilter) => ({ ...prevFilter, query: newQuery, unprocessed: false }));
  };
  //#endregion

  return (
    <AdvancedFilterContext.Provider
      value={{
        advancedFilter,
        setOption,
        setOperator,
        setMetadataOption,
        setValue,
        setAuxValue,
        setQuery,
      }}
    >
      <div className={styles.queryBuilder} data-testid={testId}>
        <div className={styles.builderSelects}>
          <Select
            clearable={false}
            width="23rem"
            size="medium"
            options={TRANSLATED_OPTIONS}
            onChange={handleChangeOption}
            value={TRANSLATED_OPTIONS.find(
              (filterOption) => filterOption.value === advancedFilter.option,
            )}
            testId={`${testId}-filter-by`}
          />
          {sequence?.preceding && (
            <div className={styles.preceding}>
              <FormattedMessage id={sequence.preceding} />
            </div>
          )}
          {renderSequenceBehaviour()}
        </div>
        {props.type === 'filter' && props.removable && (
          <div
            className={styles.builderActions}
            onClick={handleRemoveFilter}
            data-testid={`${testId}-remove-filter`}
          >
            <Icon icon="CloseGrey" size={24} />
          </div>
        )}
        {props.type === 'query' && (
          <div className={styles.builderActions}>
            <Select
              clearable={false}
              width="12rem"
              size="medium"
              options={CONDITION_TYPES}
              onChange={handleConditionChange}
              value={conditionType}
              menuWidth="18rem"
              testId={`${testId}-filter-operator`}
            />
            <Button
              size="medium"
              onClick={handleAddToQuery}
              disabled={
                !advancedFilter.query //Filter only has query when is valid (set by each filter type handler)
              }
              testId={`${testId}-add-filter`}
            >
              <FormattedMessage id="global.add" />
            </Button>
          </div>
        )}
      </div>
    </AdvancedFilterContext.Provider>
  );
};

export default AdvancedFilterRow;
