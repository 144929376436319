import { useEffect, useMemo, useRef } from 'react';
import { uniq } from 'lodash';
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useSelector } from '_common/hooks';
import { selectDocumentStyles } from 'Editor/redux/StylesSlice';

import Style from './Style/Style';
import styles from './StyleList.module.scss';

import type { ListRowProps } from 'react-virtualized';

const StyleList = () => {
  const documentStyles = useSelector(selectDocumentStyles);
  const editorStatus = useSelector((state) => state.editor.status);
  const { data, list } = documentStyles;

  const listRef = useRef<List>(null);
  const _cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 41,
    }),
  );

  const ids = uniq(list);
  const itemData = useMemo(() => ids.map((id) => ({ ...data[id] })), [list, data]);

  useEffect(() => {
    if (editorStatus.selectedStyle?.id) {
      listRef.current?.scrollToRow(ids.indexOf(editorStatus.selectedStyle.id));
    }
  }, [editorStatus.selectedStyle]);

  useEffect(() => {
    _cache.current?.clearAll();
  }, [itemData]);

  const _rowRenderer = (props: ListRowProps) => {
    const { index, key, parent, style } = props;
    const documentStyle = data[ids[index]];
    return (
      // @ts-expect-error component prop types out of date
      <CellMeasurer
        cache={_cache.current}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        {({ registerChild }) => (
          //@ts-expect-error registerChild argument should be ready to be null because ref can be null
          <div ref={registerChild} style={style}>
            <Style
              key={documentStyle.id}
              id={documentStyle.id}
              style={documentStyle}
              name={documentStyle.n}
              selected={
                editorStatus.selectedStyle && editorStatus.selectedStyle.id === documentStyle.id
              }
              testId={`style-${documentStyle.id}`}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <div className={styles.list}>
      <AutoSizer>
        {({ height, width }) => (
          // @ts-expect-error component prop types out of date
          <List
            ref={listRef}
            deferredMeasurementCache={_cache.current}
            overscanRowCount={15}
            rowCount={ids.length}
            rowHeight={_cache.current.rowHeight}
            rowRenderer={_rowRenderer}
            width={width}
            height={height}
            itemData={itemData}
            // className={styles.virtualized}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default StyleList;
