import BaseController from '../BaseController';
import { TableOfContents } from './TableOfContents';

export class TableOfContentsController extends BaseController {
  private running: number = 0;
  toc?: TableOfContents;
  constructor(data: Editor.Data.State) {
    super(data);
    this.handleTableOfContentsUpdate = this.handleTableOfContentsUpdate.bind(this);
  }

  start(documentId: string): void {
    if (this.Data.models) {
      this.toc = new TableOfContents(this.Data.transport, this.Data.models, documentId);
      this.toc
        .bindToStyles(this.Data.styles?.documentStyles)
        .bindToCitations(this.Data.citations)
        .bindToNumbering(this.Data.numbering)
        .bindToProofing(this.Data.proofing);
      this.toc.on('UPDATED', this.handleTableOfContentsUpdate);
    }
  }

  private handleTableOfContentsUpdate(data: TableOfContents.TOCStructureDataType) {
    this.Data.events?.emit('LOAD_TABLE_OF_CONTENTS', data);
  }

  getDocumentMainTitle(force: boolean) {
    return this.toc?.getDocumentMainTitle(force);
  }

  resume() {
    if (this.Data.context?.document?.id) {
      if (this.running <= 0) {
        this.toc?.start(this.Data.context?.document.id);
        this.running = 1;
      } else {
        this.running += 1;
      }
    }
  }

  pause() {
    this.running -= 1;
    if (this.running <= 0) {
      this.running = 0;
      this.toc?.stop();
    }
  }

  stop(): void {
    this.running = 0;
    this.toc?.stop();
  }

  destroy(): void {
    this.stop();
  }
}
