import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export type DisplayUnit = Exclude<Editor.Data.Node.TableWidthTypes, 'abs' | 'nil'> | 'cm' | 'in';

/**
 * Decimal rules when converting from data value to display value
 *
 * Percent [0-1] to [0-100]: maintain 1 decimal digits
 *
 * Point (pt) to Centimeter (cm) or Inches (in): 2 decimal digits
 *
 * @param value Value to be converted into a displayable measure unit
 * @param originalUnit Current measure unit of given value
 * @param desiredAbsUnit Decides which unit the value should be converted to in case originalUnit is 'abs'
 */
export const convertToDisplayUnit = (
  value?: number,
  originalUnit: Editor.Data.Node.TableWidthTypes = 'abs',
  desiredAbsUnit: 'cm' | 'in' = 'cm',
) => {
  if (!value) {
    return 0;
  }

  switch (originalUnit) {
    case 'pct':
      //Percent value is [0-1], display as [0-100]
      return +(value * 100).toFixed(1);
    case 'abs':
      //pt is the default unit for absolute (abs)
      return EditorDOMUtils.convertUnitTo(value, 'pt', desiredAbsUnit, 2) ?? 0;
    default:
      return 0;
  }
};

/**
 * Decimal rules when converting from display value to data value
 *
 * Percent [0-100] to [0-1]: 3 decimal digits
 *
 * Centimeter (cm) or Inches (in) to Point (pt): 2 decimal digits
 *
 * @param value Value to be converted into a displayable measure unit
 * @param originalUnit Current measure unit of given value
 */
export const convertToDataUnit = (value?: number, originalUnit: DisplayUnit = 'cm') => {
  if (!value) {
    return 0;
  }

  switch (originalUnit) {
    case 'pct':
      //Displayed percent value is [0-100], save in data as [0-1]
      return +(value / 100).toFixed(3);
    case 'cm':
    case 'in':
      return EditorDOMUtils.convertUnitTo(value, originalUnit, 'pt', 2) ?? 0;
    default:
      return 0;
  }
};

export const EMPTY_WIDTH: Editor.Styles.TableProperties['width'] = {
  value: { t: 'abs', v: 0 },
};

export const EMPTY_HEIGHT: Editor.Styles.TableProperties['height'] = {
  value: 0,
};
