import { MouseEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { Toggle } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';

import NodeIcon from '_common/components/Tables/NodeIcon/NodeIcon';

import styles from './Title.module.scss';

type TitleProps = {
  onClose: MouseEventHandler<HTMLSpanElement>;
};

const Title = ({ onClose }: TitleProps) => {
  const intl = useIntl();

  const elementId = Object.keys(useSelector((state) => state.table.selected))[0];
  const object = useSelector((state) => state.app.data[elementId]);

  return (
    <div className={styles.root}>
      <NodeIcon object={object} />
      <div className={styles.elementTitle} title={object.name}>
        {object.type === 'space' && object.personal
          ? intl.formatMessage({ id: 'spaces.personalSpace' })
          : object.name}
      </div>
      <Toggle
        size="medium"
        variant="link"
        icon="CloseGrey"
        onClick={onClose}
        testId="information-panel-close-toggle"
      />
    </div>
  );
};

export default Title;
