import { useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { closeAllModals } from '_common/modals/ModalsSlice';
import { usePDFContext } from 'PDF/PDFContext';

import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import { DocumentAuditLogModal, SaveVersionModal } from 'Editor/modals';
import { PDFConfirmationModal, PDFTaskWatchModal, PDFExportingDocumentModal } from './Modals';
import { LikesModal } from '_common/modals';

const PDFModalConductor = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();

  const open = useSelector((state) => state.modals.open);
  const firstOpenModal = Object.entries(open).find(([, v]) => v)?.[0] as keyof typeof open;

  return (
    <IntlErrorBoundary
      fallbackType="modal"
      mockProps={{
        title: intl.formatMessage({ id: 'global.error' }),
        onClose: () => dispatch(closeAllModals()),
      }}
      disabled={!firstOpenModal}
    >
      {open.PDFConfirmationModal && <PDFConfirmationModal />}
      {open.PDFTaskWatchModal && <PDFTaskWatchModal />}
      {open.SaveVersionModal && <SaveVersionModal saveVersion={pdfManager.saveVersion} />}
      {open.PDFExportingDocumentModal && <PDFExportingDocumentModal />}
      {open.LikesModal && <LikesModal />}
      {open.DocumentAuditLogModal && <DocumentAuditLogModal />}
    </IntlErrorBoundary>
  );
};

export default PDFModalConductor;
