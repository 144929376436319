import { ReactNode } from 'react';
import { Dropdown, Toggle, usePopper } from 'dodoc-design-system';

type OptionsCellProps = {
  object: Objekt;
  items: ReactNode;
  testId: string;
};

const OptionsCell = ({ object, items, testId }: OptionsCellProps) => {
  const { isOpen, referenceProps, popperProps } = usePopper({ placement: 'bottom-end' });

  return (
    <div>
      <Toggle
        size="small"
        variant="link"
        isToggled={isOpen}
        icon="MoreOptions"
        disabled={object.personal}
        testId={`${testId}-options-toggle`}
        {...referenceProps}
      />
      <Dropdown {...popperProps} testId={`${testId}-dropdown`}>
        {items}
      </Dropdown>
    </div>
  );
};

export default OptionsCell;
