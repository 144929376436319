import { Command } from '../Command';
import { JsonRange } from 'Editor/services/_Common/Selection';

export class UpdateEquationCommand extends Command {
  blockId: string;
  elementId: string;
  value: any;

  constructor(
    context: Editor.Edition.ICommandArgs,
    blockId: string,
    elementId: string,
    value: any,
  ) {
    super(context);

    this.blockId = blockId;
    this.elementId = elementId;
    this.value = value;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (!this.context.DataManager || !this.context.DataManager.selection) {
      return this;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(this.blockId);

    if (baseModel) {
      let path = baseModel.findPathToChild(this.elementId);

      if (JsonRange.isValidSelectionPath(path)) {
        const startPosition: Editor.Selection.Position = {
          b: baseModel.id,
          p: [...path],
        };

        const childOffset = Number(path[path.length - 1]);

        if (!isNaN(childOffset)) {
          path[path.length - 1] = childOffset + 1;

          const endPosition: Editor.Selection.Position = {
            b: baseModel.id,
            p: [...path],
          };

          const jsonRange = new JsonRange(startPosition, endPosition);
          this.context.DataManager.selection.update([jsonRange.serializeToRangeData()]);

          if (this.context.commandFactory) {
            let command = this.context.commandFactory.getEquationsCommand(
              'INSERT_EQUATION',
              this.value,
              this.blockId,
              this.elementId,
            );
            command?.exec();
          }
        }
      }
    }

    return this;
  }
}
