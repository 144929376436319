import { intersection } from 'lodash';
import { ELEMENTS } from 'Editor/services/consts';

type AllowedDescendants = {
  [index in Editor.Elements.ElementTypesType]?: Editor.Elements.ElementTypesType[];
};

type DataPathInfo = {
  data: Editor.Data.Node.Data;
  path: Editor.Selection.Path;
};
export class NodeUtils {
  static NON_CONTENT_ELEMENTS: Editor.Elements.ElementTypesType[] = [
    'citations-group',
    'tracked-delete',
  ];
  static NON_CONTENT_ELEMENTS_WITH_PROPERTIES: any = { 'tracked-insert': ['replacewith'] };

  // ----------------------------------------------------------------
  //                    Inline Elements
  // ----------------------------------------------------------------
  static INLINE_NON_EDITABLE_TYPES: Editor.Elements.ElementTypesType[] = [
    ELEMENTS.CitationsGroupElement.ELEMENT_TYPE,
    // 'CITATION-ELEMENT',
    ELEMENTS.NoteElement.ELEMENT_TYPE,
    ELEMENTS.SymbolElement.ELEMENT_TYPE,
    ELEMENTS.PasteMarkerElement.ELEMENT_TYPE,
    ELEMENTS.EquationElement.ELEMENT_TYPE,
    ELEMENTS.InvalidElement.ELEMENT_TYPE,
    ELEMENTS.PlaceholderElement.ELEMENT_TYPE,
    ELEMENTS.PageBreakElement.ELEMENT_TYPE,
    ELEMENTS.SectionBreakElement.ELEMENT_TYPE,
    ELEMENTS.ColumnBreakElement.ELEMENT_TYPE,
    ELEMENTS.TabElement.ELEMENT_TYPE,
    ELEMENTS.ImageElement.ELEMENT_TYPE,
    // 'FIELD-ELEMENT',
  ];

  static INLINE_WRAP_TYPES: Editor.Elements.ElementTypesType[] = [
    ELEMENTS.CommentElement.ELEMENT_TYPE,
    ELEMENTS.TemporaryComment.ELEMENT_TYPE,
    ELEMENTS.HyperlinkElement.ELEMENT_TYPE,
    ELEMENTS.FieldElement.ELEMENT_TYPE,
  ];

  static INLINE_TEXT_TYPES: Editor.Elements.ElementTypesType[] = [
    ELEMENTS.FormatElement.ELEMENT_TYPE,
  ];

  static INLINE_TYPES: string[] = [
    ...NodeUtils.INLINE_TEXT_TYPES,
    ...NodeUtils.INLINE_WRAP_TYPES,
    ...NodeUtils.INLINE_NON_EDITABLE_TYPES,
    ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
    ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
  ];

  static INLINE_LAST_CHILD_TYPES: Editor.Elements.ElementTypesType[] = [
    ELEMENTS.PageBreakElement.ELEMENT_TYPE,
    ELEMENTS.SectionBreakElement.ELEMENT_TYPE,
    ELEMENTS.ColumnBreakElement.ELEMENT_TYPE,
  ];

  // ----------------------------------------------------------------
  //                    Block Elements
  // ----------------------------------------------------------------

  static BLOCK_INVALID_TYPES: string[] = [
    ELEMENTS.InvalidElement.ELEMENT_TYPE,
    ELEMENTS.LoaderElement.ELEMENT_TYPE,
  ];

  static BLOCK_TEXT_TYPES: Editor.Elements.ElementTypesType[] = [
    ELEMENTS.ParagraphElement.ELEMENT_TYPE,
  ];

  static BLOCK_EDITABLE_TYPES: Editor.Elements.ElementTypesType[] = [
    ELEMENTS.ParagraphElement.ELEMENT_TYPE,
    ELEMENTS.TableElement.ELEMENT_TYPE,
    ELEMENTS.FigureElement.ELEMENT_TYPE,
    ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
    ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
    ELEMENTS.ReferencesSectionElement.ELEMENT_TYPE,
  ];

  static BLOCK_NON_EDITABLE_TYPES: Editor.Elements.ElementTypesType[] = [
    ELEMENTS.PageBreakElement.ELEMENT_TYPE, // legacy
    ELEMENTS.SectionBreakElement.ELEMENT_TYPE, // legacy
    ELEMENTS.TableOfContentsElement.ELEMENT_TYPE,
    ELEMENTS.ListOfFiguresElement.ELEMENT_TYPE,
    ELEMENTS.ListOfTablesElement.ELEMENT_TYPE,
    ELEMENTS.KeywordsElement.ELEMENT_TYPE,
    ELEMENTS.AuthorsElement.ELEMENT_TYPE,
  ];

  static BLOCK_DELETABLE_TYPES: Editor.Elements.ElementTypesType[] = [
    ...NodeUtils.BLOCK_EDITABLE_TYPES,
    ...NodeUtils.BLOCK_NON_EDITABLE_TYPES,
  ];

  static MULTI_BLOCK_CONTAINER_TYPES: Editor.Elements.ElementTypesType[] = [
    ELEMENTS.ReferencesSectionElement.ELEMENT_TYPE,
    ELEMENTS.TableElement.ELEMENT_TYPE,
  ];

  static BLOCK_CONTAINER_TYPES: Editor.Elements.ElementTypesType[] = [
    ...NodeUtils.MULTI_BLOCK_CONTAINER_TYPES,
    ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
    ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
  ];

  static ALLOWED_DESCENDANTS: AllowedDescendants = {
    rs: ['p', 'tbl', 'figure', 'tracked-insert', 'tracked-delete', 'k', 'a', 'toc', 'tof', 'tot'],
    tbl: ['tblh', 'tblb'],
    tblh: ['tblr'],
    tblb: ['tblr'],
    tblr: ['tblc'],
    tblc: ['p', 'tbl', 'figure', 'tracked-insert', 'tracked-delete'],
    figure: ['img', 'image-element'],
    p: [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'cross-reference',
      'equation',
      'f',
      'ph',
      'img',
      'pb',
      'sb',
      'cb',
      'tab',
      'text',
    ],
    format: [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'cross-reference',
      'equation',
      'f',
      'ph',
      'img',
      'tab',
      'text',
    ],
    'temp-comment': [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'cross-reference',
      'equation',
      'f',
      'ph',
      'img',
      'tab',
      'text',
    ],
    comment: [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'cross-reference',
      'equation',
      'f',
      'ph',
      'img',
      'tab',
      'text',
    ],
    f: [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'cross-reference',
      'equation',
      'f',
      'ph',
      'img',
      'tab',
      'text',
    ],
    'citations-group': ['citation', 'tracked-insert', 'tracked-delete', 'format', 'text'],
    citation: ['text'],
    'cross-reference': [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'cross-reference',
      'equation',
      'f',
      'ph',
      'img',
      'tab',
      'text',
    ],
    link: [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'cross-reference',
      'equation',
      'f',
      'ph',
      'img',
      'tab',
      'text',
    ],
    'tracked-insert': [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'cross-reference',
      'equation',
      'f',
      'ph',
      'img',
      'pb',
      'sb',
      'cb',
      'tab',
      'text',
      'tbl',
      'figure',
      'k',
      'a',
      'toc',
      'tof',
      'tot',
    ],
    'tracked-delete': [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'cross-reference',
      'equation',
      'f',
      'ph',
      'img',
      'pb',
      'sb',
      'cb',
      'tab',
      'text',
      'tbl',
      'figure',
      'k',
      'a',
      'toc',
      'tof',
      'tot',
    ],
  };

  static isAllowedUnder(
    parentType: Editor.Elements.ElementTypesType,
    elementType: Editor.Elements.ElementTypesType,
  ): boolean {
    return !!(
      NodeUtils.ALLOWED_DESCENDANTS[parentType] &&
      NodeUtils.ALLOWED_DESCENDANTS[parentType]?.includes(elementType)
    );
  }

  private static getProperParentForReferenceNode(element: Editor.Data.Node.Data, ref: string) {
    const queue: { last: Editor.Data.Node.Data; node: Editor.Data.Node.Data }[] = [
      {
        last: element,
        node: element,
      },
    ];
    let result = element;
    let index;

    while ((index = queue.shift())) {
      const { node, last } = index;
      if (node.id === ref) {
        result = last;
        break;
      }
      if (
        !NodeUtils.NON_CONTENT_ELEMENTS.includes(node.type) &&
        !(
          NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[node.type] &&
          intersection(
            Object.keys(node.properties || {}),
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[node.type],
          ).length > 0
        ) &&
        node.childNodes
      ) {
        let properLast = last;
        if (node.type === 'p') {
          properLast = node;
        }

        // eslint-disable-next-line no-loop-func
        const childreen = node.childNodes.map((value: Editor.Data.Node.Data) => ({
          last: properLast,
          node: value,
        }));

        queue.unshift(...childreen);
      }
    }
    return result;
  }

  private static getNodeContents(node: Editor.Data.Node.Data) {
    const queue = [node];
    let result = '';
    while (queue.length) {
      const element = queue.shift();
      if (element) {
        if (element.type === 'text') {
          result += element.content;
        } else if (
          !NodeUtils.NON_CONTENT_ELEMENTS.includes(element.type) &&
          !(
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
            intersection(
              Object.keys(element.properties || {}),
              NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
            ).length > 0
          ) &&
          element.childNodes
        ) {
          queue.unshift(...element.childNodes);
        }
      }
    }
    return result;
  }

  static getContent(element: Editor.Data.Node.Data, ref: string | null = null) {
    if (element.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      if (ref) {
        return NodeUtils.getNodeContents(NodeUtils.getProperParentForReferenceNode(element, ref));
      }
      return NodeUtils.getNodeContents(element);
    }
    if (element.type === 'p') {
      return NodeUtils.getNodeContents(element);
    }
    return null;
  }

  static getNodeContentsAfterField(node: Editor.Data.Node.Data, fieldId: string | undefined) {
    let result = '';
    if (!fieldId) {
      return result;
    }
    let queue = [JSON.parse(JSON.stringify(node))];
    if (node.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      queue = [NodeUtils.getProperParentForReferenceNode(node, fieldId)];
    }
    while (queue.length) {
      const element = queue.shift();
      if (element.type === 'f' && element.id === fieldId) {
        result = '';
      } else if (element.type === 'text') {
        result += element.content;
      } else if (
        !NodeUtils.NON_CONTENT_ELEMENTS.includes(element.type) &&
        !(
          NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
          intersection(
            Object.keys(element.properties),
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
          ).length > 0
        ) &&
        element.childNodes
      ) {
        queue.unshift(...element.childNodes);
      }
    }
    return result;
  }

  static getNodeContentsBeforeField(node: Editor.Data.Node.Data, fieldId: string | undefined) {
    let result = '';
    if (!fieldId) {
      return result;
    }
    let queue = [JSON.parse(JSON.stringify(node))];
    if (node.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      queue = [NodeUtils.getProperParentForReferenceNode(node, fieldId)];
    }
    while (queue.length) {
      const element = queue.shift();
      if (element.type === 'f' && element.id === fieldId) {
        queue = [...(element.childNodes || [])];
      } else if (element.type === 'text') {
        result += element.content;
      } else if (
        !NodeUtils.NON_CONTENT_ELEMENTS.includes(element.type) &&
        !(
          NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
          intersection(
            Object.keys(element.properties),
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
          ).length > 0
        ) &&
        element.childNodes
      ) {
        queue.unshift(...element.childNodes);
      }
    }
    return result;
  }

  static closestOfTypeByPath(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    type: Editor.Elements.ElementTypesType | Editor.Elements.ElementTypesType[],
  ): DataPathInfo | null {
    let result: DataPathInfo | null = null;

    let types: Editor.Elements.ElementTypesType[] = [];

    if (Array.isArray(type)) {
      types = type;
    } else {
      types.push(type);
    }

    let auxData = baseData;
    let lastKey: string | number | null = null;

    if (types.includes(auxData.type)) {
      result = {
        data: auxData,
        path: [],
      };
    }

    for (let i = 0; i < path.length; i++) {
      const key = path[i];

      if (lastKey === 'childNodes' && !isNaN(+key)) {
        if (lastKey === 'childNodes' && NodeUtils.isElementData(auxData) && auxData.childNodes) {
          auxData = auxData.childNodes[+key];

          if (result?.data !== auxData && types.includes(auxData?.type)) {
            result = {
              data: auxData,
              path: path.slice(0, i + 1),
            };
          }
        }
      }

      if (key === 'childNodes') {
        lastKey = key;
      } else {
        lastKey = null;
      }
    }

    return result;
  }

  static closestAncestorOfType(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    type: Editor.Elements.ElementTypesType | Editor.Elements.ElementTypesType[],
  ) {
    let result: DataPathInfo | null = null;

    let types: Editor.Elements.ElementTypesType[] = [];

    if (Array.isArray(type)) {
      types = type;
    } else {
      types.push(type);
    }

    result = NodeUtils.closestOfTypeByPath(baseData, path, types);

    if (!result) {
      // check previous and next
      const childData = NodeUtils.getChildDataByPath(baseData, path);
      const pathKey = path[path.length - 2];
      const pathOffset = Number(path[path.length - 1]);

      if (NodeUtils.isTextData(childData) && pathKey === 'content' && !isNaN(pathOffset)) {
        if (pathOffset === 0) {
          result = NodeUtils.getPreviousAncertor(baseData, path);
        } else if (pathOffset === childData.content.length) {
          result = NodeUtils.getNextAncestor(baseData, path);
        }
      } else {
        result =
          NodeUtils.getPreviousAncertor(baseData, path) ||
          NodeUtils.getNextAncestor(baseData, path);
      }
    }

    if (result && types.includes(result.data.type)) {
      return result;
    }

    return null;
  }

  static firstOfTypeByPath(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    type: Editor.Elements.ElementTypesType | Editor.Elements.ElementTypesType[],
  ): { element: Editor.Data.Node.Data | null; path: Editor.Selection.Path | null } | null {
    let types: Editor.Elements.ElementTypesType[] = [];

    if (Array.isArray(type)) {
      types = type;
    } else {
      types.push(type);
    }

    let auxData = baseData;
    let lastKey: string | number | null = null;

    if (types.includes(auxData.type)) {
      return {
        element: auxData,
        path: [],
      };
    }

    for (let i = 0; i < path.length; i++) {
      const key = path[i];

      if (lastKey === 'childNodes' && !isNaN(+key)) {
        if (lastKey === 'childNodes' && NodeUtils.isElementData(auxData) && auxData.childNodes) {
          auxData = auxData.childNodes[+key];

          if (types.includes(auxData?.type)) {
            return {
              element: auxData,
              path: path.slice(0, i + 1),
            };
          }
        }
      }

      if (key === 'childNodes') {
        lastKey = key;
      } else {
        lastKey = null;
      }
    }

    return null;
  }

  static getChildDataByPath(
    baseData: Editor.Data.Node.Data,
    path: Realtime.Core.RealtimePath,
  ): Editor.Data.Node.Data | undefined {
    let data = baseData;
    let lastKey: string | number | null = null;

    for (let i = 0; i < path.length; i++) {
      const key = path[i];

      if (lastKey === 'childNodes' && !isNaN(+key)) {
        if (lastKey === 'childNodes' && NodeUtils.isElementData(data) && data.childNodes) {
          const child = data.childNodes[+key];
          if (child) {
            data = child;
          } else {
            return data;
          }
        }
      }

      if (key === 'childNodes') {
        lastKey = key;
      } else {
        lastKey = null;
      }
    }

    return data;
  }

  static getParentOfChildInfoByPath(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
  ): DataPathInfo | null {
    let parentPath: Editor.Selection.Path;

    if (path.includes('content')) {
      parentPath = path.slice(0, path.length - 4);
    } else {
      parentPath = path.slice(0, path.length - 2);
    }

    let parentData = NodeUtils.getChildDataByPath(baseData, parentPath);

    if (parentData) {
      return {
        data: parentData,
        path: parentPath,
      };
    }

    return null;
  }

  static getContentFromData(data?: Editor.Data.Node.Data | null) {
    if (!data) {
      return '';
    }

    let content = data.content || '';

    if (data.childNodes) {
      const queue = [...data.childNodes];
      while (queue.length) {
        const child = queue.shift();

        if (child?.content) {
          content += child?.content;
        } else if (child?.childNodes) {
          queue.unshift(...child.childNodes);
        }
      }
    }

    return content;
  }

  static getPreviousAncertor(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
  ): DataPathInfo | null {
    let parentPath;
    let childOffset;
    if (path.includes('content')) {
      parentPath = path.slice(0, path.length - 4);
      childOffset = Number(path[path.length - 3]);
    } else {
      parentPath = path.slice(0, path.length - 2);
      childOffset = Number(path[path.length - 1]);
    }
    let parentData: Editor.Data.Node.Data | undefined = NodeUtils.getChildDataByPath(
      baseData,
      parentPath,
    );

    while (parentData && !isNaN(childOffset)) {
      if (parentData.childNodes?.[childOffset - 1]) {
        return {
          data: parentData.childNodes?.[childOffset - 1],
          path: [...parentPath, 'childNodes', childOffset - 1],
        };
      }

      if (parentPath.length > 0) {
        childOffset = Number(parentPath[parentPath.length - 1]);
        parentPath = parentPath.slice(0, parentPath.length - 2);
        parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
      } else {
        parentData = undefined;
      }
    }

    return null;
  }

  static getNextAncestor(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
  ): DataPathInfo | null {
    let parentPath;
    let childOffset;
    if (path.includes('content')) {
      parentPath = path.slice(0, path.length - 4);
      childOffset = Number(path[path.length - 3]);
    } else {
      parentPath = path.slice(0, path.length - 2);
      childOffset = Number(path[path.length - 1]);
    }
    let parentData: Editor.Data.Node.Data | undefined = NodeUtils.getChildDataByPath(
      baseData,
      parentPath,
    );

    while (parentData && !isNaN(childOffset)) {
      if (parentData.childNodes?.[childOffset + 1]) {
        return {
          data: parentData.childNodes?.[childOffset + 1],
          path: [...parentPath, 'childNodes', childOffset + 1],
        };
      }

      if (parentPath.length > 0) {
        childOffset = Number(parentPath[parentPath.length - 1]);
        parentPath = parentPath.slice(0, parentPath.length - 2);
        parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
      } else {
        parentData = undefined;
      }
    }

    return null;
  }

  static expandStartPath(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
  ): Editor.Selection.Path | null {
    let parentPath = [...path];
    let childKey;
    let childOffset;
    let parentData: Editor.Data.Node.Data | undefined;

    while (!parentData) {
      // find closest parent in data
      childKey = parentPath[parentPath.length - 2];
      childOffset = Number(parentPath[parentPath.length - 1]);
      parentPath = parentPath.slice(0, parentPath.length - 2);

      parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
    }

    while (parentData && childOffset != null && childKey != null && !isNaN(childOffset)) {
      if (childOffset !== 0) {
        break;
      }

      if (parentPath.length >= 2) {
        childKey = parentPath[parentPath.length - 2];
        childOffset = Number(parentPath[parentPath.length - 1]);
        parentPath = parentPath.slice(0, parentPath.length - 2);
        parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
      } else {
        parentData = undefined;
        break;
      }
    }

    if (childOffset != null && childKey != null && !isNaN(childOffset)) {
      parentPath.push(childKey);
      parentPath.push(childOffset);
    }

    return parentPath;
  }

  static expandEndPath(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
  ): Editor.Selection.Path | null {
    let parentPath: Editor.Selection.Path = [...path];
    let childKey;
    let childOffset;
    let parentData: Editor.Data.Node.Data | undefined;

    while (!parentData) {
      // find closest parent in data
      childKey = parentPath[parentPath.length - 2];
      childOffset = Number(parentPath[parentPath.length - 1]);
      parentPath = parentPath.slice(0, parentPath.length - 2);

      parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
    }

    while (parentData && childOffset != null && childKey != null && !isNaN(childOffset)) {
      if (
        (NodeUtils.isTextData(parentData) && childOffset < parentData.content.length) ||
        (parentData.childNodes &&
          parentData.childNodes.length > 0 &&
          childOffset < parentData.childNodes.length)
      ) {
        break;
      }

      if (parentPath.length >= 2) {
        childKey = parentPath[parentPath.length - 2];
        childOffset = Number(parentPath[parentPath.length - 1]) + 1;
        parentPath = parentPath.slice(0, parentPath.length - 2);
        parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
      } else {
        parentData = undefined;
        break;
      }
    }

    if (childOffset != null && childKey != null && !isNaN(childOffset)) {
      parentPath.push(childKey);
      parentPath.push(childOffset);
    }

    return parentPath;
  }

  static isPathAtContentStart(baseData: Editor.Data.Node.Data, path: Realtime.Core.RealtimePath) {
    let data: Editor.Data.Node.Data = baseData;
    let lastKey: string | number | null = null;

    let isAtStart = false;

    for (let i = 0; i < path.length; i++) {
      const key = path[i];

      if (data) {
        if (lastKey === 'childNodes' && !isNaN(+key) && data.childNodes) {
          const index = +key;
          if (index === 0) {
            data = data.childNodes[index];
            if (i === path.length - 1) {
              isAtStart = true;
            }
          } else if (data.childNodes.length) {
            for (let j = 0; j < index; j++) {
              if (NodeUtils.isNonEditableInlineData(data.childNodes[j])) {
                return false;
              } else {
                const content = NodeUtils.getContentFromData(data.childNodes[j]);
                if (content.length === 0) {
                  isAtStart = true;
                } else {
                  return false;
                }
              }
            }
          }
        } else if (lastKey === 'content' && !isNaN(+key) && data.content) {
          const index = +key;
          if (index === 0) {
            isAtStart = true;
          } else {
            return false;
          }
        }

        if (key === 'childNodes' || key === 'content') {
          lastKey = key;
        } else {
          lastKey = null;
        }
      }
    }

    return isAtStart;
  }

  static isPathAtContentEnd(baseData: Editor.Data.Node.Data, path: Realtime.Core.RealtimePath) {
    let data: Editor.Data.Node.Data = baseData;
    let lastKey: string | number | null = null;

    let isAtEnd = false;

    for (let i = 0; i < path.length; i++) {
      const key = path[i];

      if (data) {
        if (lastKey === 'childNodes' && !isNaN(+key) && data.childNodes) {
          const index = +key;
          if (index >= data.childNodes.length) {
            return true;
          } else if (index === data.childNodes.length - 1) {
            data = data.childNodes[index];
            const content = NodeUtils.getContentFromData(data);
            if (index !== 0 && content.length === 0) {
              isAtEnd = true;
            }
          } else if (data.childNodes.length) {
            for (let j = index + 1; j < data.childNodes.length; j++) {
              if (NodeUtils.isNonEditableInlineData(data.childNodes[j])) {
                return false;
              } else {
                const content = NodeUtils.getContentFromData(data.childNodes[j]);
                if (content.length === 0) {
                  isAtEnd = true;
                } else {
                  return false;
                }
              }
            }
          }
        } else if (lastKey === 'content' && !isNaN(+key) && data.content) {
          const index = +key;
          if (index === data.content.length && data.content.length !== 0) {
            isAtEnd = true;
          } else {
            return false;
          }
        }

        if (key === 'childNodes' || key === 'content') {
          lastKey = key;
        } else {
          lastKey = null;
        }
      }
    }

    return isAtEnd;
  }

  static checkPropertyValueExists(
    elementData: Editor.Data.Node.Data,
    prop: string | number,
    value: any,
  ) {
    if (elementData.properties?.[prop] === value) {
      return true;
    }

    if (elementData.childNodes) {
      for (let i = 0; elementData.childNodes.length > i; i++) {
        if (this.checkPropertyValueExists(elementData.childNodes[i], prop, value)) {
          return true;
        }
      }
    }
    return false;
  }

  static findPreviousOrNextAncestorByType(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    type: string,
  ): Editor.Data.Node.Data | null {
    const previous = NodeUtils.getPreviousAncertor(baseData, path);
    const next = NodeUtils.getNextAncestor(baseData, path);

    let closest = null;
    if (previous?.data.type === type) {
      closest = previous.data;
    }

    if (next?.data.type === type) {
      closest = next.data;
    }

    const data = NodeUtils.getChildDataByPath(baseData, path);
    const isText = NodeUtils.isTextData(data);

    const startOffset = Number(path[path.length - 1]);

    if (
      isText &&
      ((startOffset !== 0 && startOffset !== data.content.length) ||
        (data.content.length === 0 && !data.childNodes))
    ) {
      return null;
    }

    return closest;
  }

  static cloneData(
    baseData: Editor.Data.Node.Data,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
  ): Editor.Data.Node.Data[] {
    if (!startPath.length) {
      if (NodeUtils.isTextData(baseData)) {
        startPath = ['content', 0];
      } else {
        startPath = ['childNodes', 0];
      }
    }

    if (!endPath.length) {
      if (NodeUtils.isTextData(baseData)) {
        startPath = ['content', baseData.content.length];
      } else if (baseData.childNodes?.length) {
        endPath = ['childNodes', baseData.childNodes?.length];
      }
    }

    let startKey = startPath[0];
    let startOffset = Number(startPath[1]);
    let remainingStart = startPath.slice(2, startPath.length);

    let endKey = endPath[0];
    let endOffset = Number(endPath[1]);
    let remainingEnd = endPath.slice(2, endPath.length);

    let clonedData: Editor.Data.Node.Data[] = [];

    if (!isNaN(startOffset) && !isNaN(endOffset)) {
      if (startKey === 'childNodes' && endKey === 'childNodes') {
        const childNodes = baseData.childNodes;

        if (childNodes) {
          const length = remainingEnd.length ? endOffset + 1 : endOffset;
          for (let i = startOffset; i < length; i++) {
            if (childNodes[i]) {
              if (
                (i === startOffset && remainingStart.length) ||
                (i === length - 1 && remainingEnd.length)
              ) {
                // start node and end node
                const child: Editor.Data.Node.Data = JSON.parse(JSON.stringify(childNodes[i]));
                if (NodeUtils.isTextData(child)) {
                  let startContent = 0;
                  if (remainingStart.includes('content') && i === startOffset) {
                    startContent = Number(remainingStart[remainingStart.indexOf('content') + 1]);
                  }

                  let endContent = child.content.length;
                  if (remainingEnd.includes('content') && i === length) {
                    endContent = Number(remainingEnd[remainingEnd.indexOf('content') + 1]);
                  }

                  if (!isNaN(startContent) && !isNaN(endContent)) {
                    child.content = child.content.slice(startContent, endContent);
                    clonedData.push(child);
                  }
                } else {
                  child.childNodes = NodeUtils.cloneData(child, remainingStart, remainingEnd);
                  clonedData.push(child);
                }
              } else {
                // middle nodes
                clonedData.push(JSON.parse(JSON.stringify(childNodes[i])));
              }
            }
          }
        }
      }
    }

    return clonedData;
  }

  // ----------------------------------------------------------
  //                  data type validations
  // ----------------------------------------------------------
  static isParagraphData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.ParagraphData {
    return data?.type === 'p';
  }

  static isTableData(data?: any): data is Editor.Data.Node.TableData {
    return NodeUtils.isElementData(data) && data?.type === 'tbl';
  }

  static isImageData(data?: Editor.Data.Node.Data | null): data is Editor.Data.Node.ImageData {
    return data?.type === 'img' || data?.type === 'image-element';
  }

  static isTableCellData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.TableCellData {
    return data?.type === 'tblc';
  }

  static isTextData(data?: Editor.Data.Node.Data | null): data is Editor.Data.Node.TextData {
    return data?.type === 'text';
  }

  static isTrackedData(data?: Editor.Data.Node.Data | null): data is Editor.Data.Node.TrackedData {
    return data?.type === 'tracked-insert' || data?.type === 'tracked-delete';
  }

  static isFigureData(data?: any): data is Editor.Data.Node.FigureData {
    return NodeUtils.isElementData(data) && data?.type === 'figure';
  }

  static isCommentData(data?: Editor.Data.Node.Data | null): data is Editor.Data.Node.CommentData {
    return data?.type === 'comment' || data?.type === 'temp-comment';
  }

  static isCitationsGroupData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.CitationsGroupData {
    return data?.type === 'citations-group';
  }

  static isCitationData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.CitationData {
    return data?.type === 'citation';
  }

  static isParagraphMarker(data?: Editor.Data.Node.Data | null) {
    return (
      NodeUtils.isTrackedData(data) &&
      (!!data.properties?.replacewith || !!data.properties?.replacewithsibling)
    );
  }

  static isElementData(data?: any): data is Editor.Data.Node.Data {
    return !!data?.type && !!data?.childNodes;
  }

  static isLastChildElementData(data?: any): data is Editor.Data.Node.Data {
    return (
      NodeUtils.isElementData(data) &&
      (NodeUtils.INLINE_LAST_CHILD_TYPES.includes(data.type) || NodeUtils.isParagraphMarker(data))
    );
  }

  static isSupportedInlineData(data?: any): data is Editor.Data.Node.Data {
    return (
      NodeUtils.isElementData(data) &&
      (NodeUtils.INLINE_TYPES.includes(data.type) || data.type === 'tblc')
    );
  }

  static isNonEditableInlineData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.INLINE_NON_EDITABLE_TYPES.includes(data.type);
  }

  static isBlockTextData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_TEXT_TYPES.includes(data.type);
  }

  static isBlockEditableData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_EDITABLE_TYPES.includes(data.type);
  }

  static isBlockDeletableData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_DELETABLE_TYPES.includes(data.type);
  }

  static isBlockNonEditableData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_NON_EDITABLE_TYPES.includes(data.type);
  }

  static isBlockContainerData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_CONTAINER_TYPES.includes(data.type);
  }

  static isMultiBlockContainerData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.MULTI_BLOCK_CONTAINER_TYPES.includes(data.type);
  }
}
