import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { OnboardingIntegrationCards, Pulse, Box } from 'dodoc-design-system';
import { useDispatch, useSelector } from '_common/hooks';
import { setSidebarPanelTab, setSidebarView } from 'Editor/redux/SidebarSlice';
import { setVersionHistoryValue } from 'Editor/redux/EditorStatusSlice';
import {
  OnboardingIntegrationCardsProps,
  OnBoardingCarBoxProps,
} from 'dodoc-design-system/build/types/Components/OnboardingIntegrationCards/OnboardingIntegrationCards';
import {
  activateOnboarding,
  completeActionList,
  EditorActions,
  EditorInteractions,
  setInteractions,
  stopOnboarding,
} from 'App/redux/onboardingSlice';
import { OnboardingOverlay, WelcomeScreen } from '_common/components';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';
import { components } from '_types/authority';
import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { useGetObjectQuery } from 'App/redux/objectApi';
import ReactDOM from 'react-dom';
import { getTrackingState } from 'Editor/redux/TrackingSlice';
import EditorManager from 'Editor/services/EditorManager/EditorManager';
import { getOnboardingProgress } from 'utils';

type PulseTarget =
  | 'taskDescription'
  | 'taskAssigned'
  | 'taskDueDate'
  | 'commentReply'
  | 'document_suggestion_add'
  | 'document_suggestion_delete'
  | 'document_comment'
  | 'document_task'
  | 'contextMenu_comment'
  | 'contextMenu_task'
  | 'sidebar_review_toggle'
  | 'sidebar_review_comments_tab';

type BoxTarget = 'toolbar_trackChanges' | 'sidebar_review_comments_tab';

type ExtendedCard = OnboardingIntegrationCardsProps['cards'][number] & {
  deps?: EditorActions[];
  interactions?: EditorInteractions[];
  dynamicPulse?: {
    position: OnboardingIntegrationCardsProps['cards'][number]['pulse'];
    targetAction: EditorActions;
  }[];
  autoAction?: () => void;
  onRender?: () => void;
  id: components['schemas']['EditorOnboardingSteps'];
  pulseTarget?: PulseTarget[];
  boxTarget?: BoxTarget[];
};
type VisibleSpace = OnboardingIntegrationCardsProps['cards'][number]['visibleSpace'];

type Position = {
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
};

const OnboardingEditorIntegration = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [sendOnboardingStatus] = useOnboardingStatusMutation();

  const { isSuccess: userLoaded } = useGetCurrentUserQuery();
  const sidebar = useSelector((state) => state.editor.sidebar.view);
  const started = useSelector((state) => state.onboarding.started.editor);
  const active = useSelector((state) => state.onboarding.active.editor);
  const initialPhase = useSelector((state) => state.onboarding.initialPhase.editor);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const originalDocId = useSelector((state) => state.onboarding.currentDocument.editor?.id);
  const currentDocumentId = useSelector((state) => state.editor.status.documentId);
  const pulseData = useSelector((state) => state.onboarding.pulseData);
  const zoom = useSelector((state) => state.editor.status.zoom);
  const tracking = useSelector(getTrackingState);
  const paragraphsAreLoaded = useSelector((state) => state.editor.status.paragraphsLoaded);
  const { isSuccess: originalDocLoaded, canEditInOriginalDoc } = useGetObjectQuery(
    {
      objectId: originalDocId ?? '',
      objectType: 'document',
      open: false,
    },
    {
      skip: !originalDocId,
      selectFromResult: (result) => ({
        ...result,
        canEditInOriginalDoc:
          result.data?.user_permissions.includes('owner') ||
          result.data?.user_permissions.includes('edit'),
      }),
    },
  );

  const [skipping, setSkipping] = useState<boolean>(false);
  const [pulsePosition, setPulsePosition] = useState<{
    position?: Position;
    boundary?: HTMLElement | Element | null | undefined;
    paragraphId?: string;
  }>();
  const [boxPosition, setBoxPosition] = useState<{
    position: OnBoardingCarBoxProps;
    boundary: HTMLElement | null;
    paragraphId?: string;
  }>();
  const [paragraphsToPulse, setParagraphsPulse] = useState<{ [x in PulseTarget]?: HTMLElement }>();

  useEffect(() => {
    if (pulsePosition?.paragraphId) {
      EditorManager.getInstance().scrollIntoView(pulsePosition.paragraphId);
    }
  }, [pulsePosition]);

  const getDocumentParagraph = useCallback(
    (paragraphIndex: number) => {
      const paragraphs = document.getElementsByTagName('paragraph-element');
      if (paragraphs) {
        return paragraphs[paragraphIndex];
      }
    },
    [paragraphsAreLoaded],
  );

  //There are pulse that have to be added to a specific paragraph
  //We are identifying the paragraph by index
  //But the user can change the number of paragraphs of the document with track changes
  //So, when the document paragraphs are fist loaded, we save the paragraphs to set the pulse
  useEffect(() => {
    if (!userLoaded || !originalDocLoaded || !started || paragraphsToPulse) {
      return;
    }

    const paragraphsToFind: { [x in PulseTarget]?: number } = {
      document_suggestion_add: 3,
      document_suggestion_delete: 4,
      document_comment: 1,
      document_task: 2,
    };
    const paragraphsFound: { [x in PulseTarget]?: HTMLElement } = {};

    Object.typedKeys(paragraphsToFind).forEach((pulseTarget) => {
      const paragraphIndex = paragraphsToFind[pulseTarget];
      if (paragraphIndex != null) {
        const paragraph = getDocumentParagraph(paragraphIndex);
        if (paragraph) {
          paragraphsFound[pulseTarget] = paragraph as HTMLElement;
        }
      }
    });

    setParagraphsPulse(paragraphsFound);
  }, [paragraphsAreLoaded, userLoaded, originalDocLoaded, started, paragraphsToPulse]);

  useEffect(() => {
    if (started) {
      dispatch(
        setSidebarPanelTab({ view: 'review', tab: canEditInOriginalDoc ? 'changes' : 'comments' }),
      );
    }
  }, [canEditInOriginalDoc, started]);

  useEffect(() => {
    if (started) {
      dispatch(setSidebarView(null));
      dispatch(setVersionHistoryValue(false));
    }
  }, [started]);

  const visibleSpace: Record<string, NonNullable<VisibleSpace>[number]> = useMemo(
    () => ({
      all: {
        top: '0px',
        height: '100%',
        left: '0px',
        width: '100%',
      },
      toolbar: {
        top: '104px',
        height: `40px`,
        left: '0px',
        width: '100%',
      },
      sidebar: {
        top: '144px',
        height: `calc(100% - 184px)`,
        right: '0',
        width: '48px',
      },
      sidepanel: {
        top: '144px',
        height: `calc(100% - 184px)`,
        right: '48px',
        width: '376px',
      },
    }),
    [],
  );

  const phases = useMemo(() => {
    const staticPhases: OnboardingIntegrationCardsProps['phases'] = {
      comments: {
        order: canEditInOriginalDoc ? 2 : 1,
        label: intl.formatMessage({ id: 'COMMENTS' }),
      },
    };

    return canEditInOriginalDoc
      ? {
          suggestions: { order: 1, label: intl.formatMessage({ id: 'TRACK_CHANGES' }) },
          ...staticPhases,
        }
      : staticPhases;
  }, [canEditInOriginalDoc]);

  const cards = useMemo<ExtendedCard[]>(() => {
    const totalPhases = canEditInOriginalDoc ? 2 : 1;

    const initialCards: ExtendedCard[] = [
      {
        id: 'beginning',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_STARTED' }),
        currentPhase: 0,
        progress: getOnboardingProgress(totalPhases, 1, 0),
      },
    ];

    const suggestionCards: ExtendedCard[] = canEditInOriginalDoc
      ? [
          {
            id: 'suggestion_description',
            description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TRACK_CHANGES_DESCRIPTION' }),
            currentPhase: 1,
            progress: getOnboardingProgress(totalPhases, 1, 0),
            visibleSpace: [visibleSpace.toolbar],
            boxTarget: ['toolbar_trackChanges'],
          },
          {
            id: 'suggestion_addText',
            phaseTitle: intl.formatMessage({ id: 'PROPOSE_CHANGES' }),
            description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TRACK_CHANGES_ADD_TEXT' }),
            tipValue: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TRACK_CHANGES_ADD_TEXT_TIP' }),
            currentPhase: 1,
            subPhase: 1,
            progress: getOnboardingProgress(totalPhases, 1, 0),
            showPhases: false,
            visibleSpace: [visibleSpace.all],
            interactions: ['editor_mainComponent_addText'],
            deps: ['editor_suggestions_addText'],
            pulseTarget: ['document_suggestion_add'],
          },
          {
            id: 'suggestion_removeText',
            phaseTitle: intl.formatMessage({ id: 'PROPOSE_CHANGES' }),
            description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TRACK_CHANGES_REMOVE_TEXT' }),
            currentPhase: 1,
            subPhase: 1,
            progress: getOnboardingProgress(totalPhases, 1, 33),
            showPhases: false,
            visibleSpace: [visibleSpace.all],
            interactions: ['editor_mainComponent_deleteText'],
            deps: ['editor_suggestions_deleteText'],
            pulseTarget: ['document_suggestion_delete'],
          },
          {
            id: 'suggestion_location',
            phaseTitle: intl.formatMessage({ id: 'VISUALIZE_TRACK_CHANGES' }),
            description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TRACK_CHANGES_LOCATION' }),
            currentPhase: 1,
            subPhase: 2,
            progress: getOnboardingProgress(totalPhases, 1, 66),
            showPhases: false,
            visibleSpace: [visibleSpace.all],
            dynamicPulse: [
              {
                position: {
                  top: '356px',
                  right: '35px',
                },
                targetAction: 'editor_suggestions_openSidePanel',
              },
            ],
            deps: ['editor_suggestions_openSidePanel'],
            interactions: ['editor_sidepanel_review_suggestions'],
            autoAction: () => {
              dispatch(setSidebarView(null));
            },
          },
          {
            id: 'suggestion_completed',
            phaseTitle: intl.formatMessage({ id: 'VISUALIZE_TRACK_CHANGES' }),
            description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_TRACK_CHANGES_COMPLETED' }),
            currentPhase: 1,
            subPhase: 2,
            progress: getOnboardingProgress(totalPhases, 1, 100),
            phaseCompleted: 1,
          },
        ]
      : [];

    const commentPhase = canEditInOriginalDoc ? 2 : 1;
    const normalCommentLocation: ExtendedCard = {
      id: 'comment_location',
      description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMMENTS_LOCATION' }),
      currentPhase: commentPhase,
      progress: getOnboardingProgress(totalPhases, commentPhase, 0),
      visibleSpace: [visibleSpace.sidepanel, visibleSpace.sidebar],
      deps: ['editor_comments_openSidePanel'],
      interactions: ['editor_tabmenu_comments'],
      autoAction: () => {
        dispatch(setSidebarView('REVIEW'));
        dispatch(setSidebarPanelTab({ view: 'review', tab: 'changes' }));
      },
      pulseTarget: ['sidebar_review_comments_tab'],
    };
    const limitedCommentLocation: ExtendedCard = {
      id: 'comment_location',
      description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMMENTS_LOCATION_LIMITED' }),
      currentPhase: commentPhase,
      progress: getOnboardingProgress(totalPhases, commentPhase, 0),
      visibleSpace: [visibleSpace.sidebar],
      deps: ['editor_comments_openSidePanel'],
      interactions: ['editor_sidepanel_review_comments'],
      dynamicPulse: [
        {
          position: {
            top: '356px',
            right: '35px',
          },
          targetAction: 'editor_comments_openSidePanel',
        },
      ],
      autoAction: () => {
        dispatch(setSidebarView(null));
      },
    };
    const staticCommentCards: ExtendedCard[] = [
      {
        id: 'comment_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMMENTS_DESCRIPTION' }),
        currentPhase: commentPhase,
        progress: getOnboardingProgress(totalPhases, commentPhase, 0),
        visibleSpace: [visibleSpace.sidepanel, visibleSpace.sidebar],
        boxTarget: ['sidebar_review_comments_tab'],
        autoAction: () => {
          dispatch(setSidebarView('REVIEW'));
          dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
        },
      },
      {
        id: 'comment_create',
        phaseTitle: intl.formatMessage({ id: 'CREATE_COMMENTS' }),
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMMENTS_CREATE' }),
        currentPhase: commentPhase,
        subPhase: 1,
        progress: getOnboardingProgress(totalPhases, commentPhase, 0),
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        deps: ['editor_comments_createComment'],
        interactions: [
          'editor_mainComponent_addComment',
          'editor_contextMenu_createComment',
          'editor_sidepanel_newComment',
        ],
        pulseTarget: ['document_comment', 'contextMenu_comment'],
      },
      {
        id: 'comment_replyMention',
        phaseTitle: intl.formatMessage({ id: 'ADD_A_REPLY' }),
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMMENTS_REPLY_WITH_MENTION' }),
        tipValue: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMMENTS_REPLY_WITH_MENTION_TIP' }),
        currentPhase: commentPhase,
        subPhase: 2,
        progress: getOnboardingProgress(totalPhases, commentPhase, 50),
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        deps: ['editor_comments_mentionInCommentReply'],
        interactions: ['editor_sidepanel_mentionComment'],
        pulseTarget: ['commentReply'],
      },
      {
        id: 'comment_completed',
        phaseTitle: intl.formatMessage({ id: 'ADD_A_REPLY' }),
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_INTEGRATION_COMMENTS_COMPLETED' }),
        currentPhase: commentPhase,
        subPhase: 2,
        progress: getOnboardingProgress(totalPhases, commentPhase, 100),
        phaseCompleted: commentPhase,
      },
    ];
    const commentCards: ExtendedCard[] = [
      canEditInOriginalDoc ? normalCommentLocation : limitedCommentLocation,
      ...staticCommentCards,
    ];

    const finalCards: ExtendedCard[] = [
      {
        id: 'ending',
        title: intl.formatMessage({ id: 'AND_THATS_JUST_THE_BEGINNING' }),
        description: intl.formatMessage({ id: 'ONBOARDING_EDITOR_COMPLETED' }, { br: <br /> }),
        currentPhase: 0,
        showPhases: false,
        progress: 100,
        nextLabel: intl.formatMessage({ id: 'GO_TO_MY_DOCUMENT' }),
      },
    ];

    return [...initialCards, ...suggestionCards, ...commentCards, ...finalCards];
  }, [canEditInOriginalDoc, tracking]);

  const [initialCardIndex, setInitialCardIndex] = useState<number>();
  const [activeCard, setActiveCard] = useState<ExtendedCard>(cards[initialCardIndex ?? 0]);

  const paragraphPulseOffset = useMemo(() => {
    return {
      top: `-3px`,
      left: `-25px`,
    };
  }, []);

  useEffect(() => {
    if (!userLoaded || !originalDocLoaded) {
      return;
    }

    /**
     * Shouldn't be necessary but added as a safeguard.
     *
     * Ignore initialPhase behaviour in the following states
     */
    const inactiveStates = ['beginning', 'ending'];
    if (initialPhase && !inactiveStates.includes(initialPhase)) {
      const initialCardIndex = cards.findIndex((card) => card.id === initialPhase);
      if (initialCardIndex > 0) {
        setActiveCard(cards[initialCardIndex]);
        setInitialCardIndex(initialCardIndex);

        const completedActions = cards
          .slice(0, initialCardIndex)
          .reduce<EditorActions[]>((allActions, card) => {
            if (card.deps) {
              allActions = [...allActions, ...card.deps];
            }

            return allActions;
          }, []);

        dispatch(completeActionList(completedActions));

        for (let i = initialCardIndex; i >= 0; i--) {
          const card = cards[i];
          const cardAction = card.autoAction || card.onRender;
          if (cardAction) {
            cardAction();
            break;
          }
        }
      }
    }
  }, [userLoaded, originalDocLoaded]);

  useEffect(() => {
    //If initial phase has been cleaned up, also clean up local states
    if (!initialPhase) {
      setInitialCardIndex(undefined);
      setActiveCard(cards[0]);
    }
  }, [initialPhase]);

  useEffect(() => {
    dispatch(setInteractions(activeCard.interactions ?? []));
  }, [activeCard]);

  useEffect(() => {
    if (activeCard.deps?.every((dep) => actionsCompleted[dep])) {
      const activeCardIndex = cards.findIndex((card) => card.id === activeCard.id);

      //Validate that the next card exists
      if (activeCardIndex + 1 <= cards.length) {
        sendOnboardingStatus({
          target: 'editor',
          step: cards[activeCardIndex + 1].id,
        });
      }
    }
  }, [activeCard, actionsCompleted]);

  const addPulseToParagraph = (paragraph: HTMLElement) => {
    if (paragraph) {
      EditorManager.getInstance().addWidget({
        type: 'onboardingPulse',
        view: paragraph,
        props: { testId: 'editor', pulsePosition: paragraphPulseOffset },
      });
    }
  };

  const removeAllPulsesFromParagraphs = () => {
    EditorManager.getInstance().removeAllWidgetsForType('onboardingPulse');
  };

  useEffect(() => {
    const pointerRadius = 12;
    const pointerDiameter = pointerRadius * 2;

    if (activeCard.pulseTarget) {
      activeCard.pulseTarget?.forEach((target) => {
        switch (target) {
          case 'contextMenu_task': {
            const { contextMenuTaskItemRect } = pulseData;
            if (contextMenuTaskItemRect) {
              setPulsePosition({
                position: {
                  top: `${Math.ceil(
                    contextMenuTaskItemRect.top +
                      contextMenuTaskItemRect.height / 2 -
                      pointerRadius,
                  )}px`,
                  left: `${Math.ceil(contextMenuTaskItemRect.left)}px`,
                },
                boundary: document.getElementById('EditorContextMenu'),
              });
            }
            break;
          }
          case 'contextMenu_comment': {
            const { contextMenuCommentItemRect } = pulseData;
            if (contextMenuCommentItemRect) {
              setPulsePosition({
                position: {
                  top: `${Math.ceil(
                    contextMenuCommentItemRect.top +
                      contextMenuCommentItemRect.height / 2 -
                      pointerRadius,
                  )}px`,
                  left: `${Math.ceil(contextMenuCommentItemRect.left)}px`,
                },
                boundary: document.getElementById('EditorContextMenu'),
              });
            }
            break;
          }
          case 'taskDescription':
          case 'taskAssigned':
          case 'taskDueDate': {
            const { taskInputRect, taskButtonRect } = pulseData;
            if (taskInputRect) {
              setPulsePosition({
                position: {
                  top: `${Math.ceil(
                    taskInputRect.top +
                      taskInputRect.height -
                      (pointerDiameter / 3) * 2 /*Two thirds of pointer diameter */,
                  )}px`,
                  left: `${Math.ceil(
                    taskInputRect.left +
                      taskInputRect.width -
                      pointerDiameter -
                      4 /*Right padding*/,
                  )}px`,
                },
                boundary: document.getElementById(`temporaryTaskCard`),
              });
            } else if (taskButtonRect && !actionsCompleted.editor_tasks_createTask) {
              setPulsePosition({
                position: {
                  top: `${Math.ceil(
                    taskButtonRect.top +
                      taskButtonRect.height / 2 +
                      pointerDiameter / 3 /*One third of pointer diameter*/,
                  )}px`,
                  left: `${Math.ceil(
                    taskButtonRect.left - pointerRadius + taskButtonRect.width / 2,
                  )}px`,
                },
                boundary: document.getElementById(`temporaryTaskCard`),
              });
            }
            break;
          }
          case 'commentReply': {
            const { commentId, annotationCardReplyRect } = pulseData;
            if (annotationCardReplyRect) {
              setPulsePosition({
                position: {
                  top: `${Math.ceil(
                    annotationCardReplyRect.top +
                      annotationCardReplyRect.height -
                      pointerDiameter / 3 /*One third of pointer diameter */,
                  )}px`,
                  left: `${Math.ceil(
                    annotationCardReplyRect.left +
                      annotationCardReplyRect.width / 2 -
                      pointerRadius,
                  )}px`,
                },
                boundary: document.getElementById(`Comment#${commentId}`),
              });
            } else {
              setPulsePosition(undefined);
            }
            break;
          }
          case 'document_suggestion_add': {
            if (!actionsCompleted.editor_suggestions_addText) {
              const paragraph = paragraphsToPulse?.document_suggestion_add;

              if (paragraph) {
                setPulsePosition({
                  paragraphId: paragraph.id,
                });
                addPulseToParagraph(paragraph);
              }
            } else {
              removeAllPulsesFromParagraphs();
            }

            break;
          }
          case 'document_suggestion_delete': {
            if (!actionsCompleted.editor_suggestions_deleteText) {
              const paragraph = paragraphsToPulse?.document_suggestion_delete;

              if (paragraph) {
                setPulsePosition({
                  paragraphId: paragraph.id,
                });
                addPulseToParagraph(paragraph);
              }
            } else {
              removeAllPulsesFromParagraphs();
            }

            break;
          }
          case 'document_comment': {
            if (
              !actionsCompleted.editor_comments_startCommentCreation &&
              !actionsCompleted.editor_comments_createComment
            ) {
              const paragraph = paragraphsToPulse?.document_comment;

              if (paragraph) {
                setPulsePosition({
                  paragraphId: paragraph.id,
                });
                addPulseToParagraph(paragraph);
              }
            } else {
              removeAllPulsesFromParagraphs();
            }

            break;
          }
          case 'document_task': {
            if (!actionsCompleted.editor_tasks_startTaskCreation) {
              const paragraph = paragraphsToPulse?.document_task;

              if (paragraph) {
                addPulseToParagraph(paragraph);
              }
            } else {
              removeAllPulsesFromParagraphs();
            }

            break;
          }
          case 'sidebar_review_comments_tab': {
            if (!actionsCompleted.editor_comments_openSidePanel) {
              const target = document.getElementById('sidebar-review-comments-tab');
              if (target) {
                const targetRect = target.getBoundingClientRect();
                setPulsePosition({
                  position: {
                    top: `${targetRect.height / 3 + pointerRadius + 2}px`,
                    left: `${targetRect.width / 2 - pointerRadius}px`,
                  },
                  boundary: target,
                });
              }
            } else {
              setPulsePosition(undefined);
            }
            break;
          }
          default: {
            setPulsePosition(undefined);
            removeAllPulsesFromParagraphs();
          }
        }
      });
    } else {
      setPulsePosition(undefined);
      removeAllPulsesFromParagraphs();
    }
  }, [
    pulseData,
    activeCard,
    zoom,
    actionsCompleted,
    getDocumentParagraph,
    paragraphPulseOffset,
    paragraphsToPulse,
  ]);

  useEffect(() => {
    if (activeCard.boxTarget) {
      activeCard.boxTarget?.forEach((target) => {
        switch (target) {
          case 'toolbar_trackChanges': {
            const element = document.getElementById('toolbar-track-changes-switch');
            if (element) {
              const elementRect = element.getBoundingClientRect();

              setBoxPosition({
                position: {
                  top: `-2px`,
                  left: '-8px',
                  width: `${elementRect.width + 8}px`,
                  height: `${elementRect.height + 4}px`,
                },
                boundary: element,
              });
            }
            break;
          }
          case 'sidebar_review_comments_tab': {
            const element = document.getElementById('sidebar-review-comments-tab');
            if (element) {
              const elementRect = element.getBoundingClientRect();

              setBoxPosition({
                position: {
                  top: `8px`,
                  left: '0px',
                  width: `${elementRect.width}px`,
                  height: `32px`,
                },
                boundary: element,
              });
            }
            break;
          }

          default: {
            setBoxPosition(undefined);
          }
        }
      });
    } else {
      setBoxPosition(undefined);
    }
  }, [activeCard, zoom, actionsCompleted, paragraphsAreLoaded, pulseData]);

  const endOnboarding = async (skip?: boolean) => {
    /* [Firefox] DDC-9585 - User is not able to skip onboarding when using Firefox
     * Have to await for request, otherwise the request will be aborted (NS_BINDING_ABORTED), not sure why
     * Maybe the browser does the window.open before the request is finishied and the request gets aborted
     */
    await sendOnboardingStatus({ target: 'editor', step: 'ending', skip: !!skip });
    dispatch(stopOnboarding('editor'));
    setSkipping(false);

    window.open(`/editor/${originalDocId}`, '_self');
  };

  const handleSkip = () => {
    endOnboarding(true);
  };

  const handleEnding = () => {
    endOnboarding();
  };

  const handleNext = (newActiveCard: number) => {
    //Make sure requirements are completed by forcing their execution (fixes restart using back)
    cards[newActiveCard].autoAction?.();
    cards[newActiveCard].onRender?.();

    setActiveCard(cards[newActiveCard]);
    if (cards[newActiveCard].id !== 'ending') {
      sendOnboardingStatus({ target: 'editor', step: cards[newActiveCard].id });
    }
  };
  const handleBack = (newActiveCard: number) => {
    cards[newActiveCard].autoAction?.();
    cards[newActiveCard].onRender?.();

    if (activeCard.id === 'beginning') {
      dispatch(activateOnboarding('editor'));
      dispatch(stopOnboarding('editor'));
    } else {
      setActiveCard(cards[newActiveCard]);
    }
  };

  const onCancelSkip = () => {
    setSkipping(false);
  };

  const renderDynamicPulse = () => {
    const nonCompletedPulse =
      activeCard.dynamicPulse?.filter((pulse) => !actionsCompleted[pulse.targetAction]) ?? [];

    return nonCompletedPulse.length > 0 ? (
      <Pulse position={nonCompletedPulse[0].position} testId="editor" />
    ) : null;
  };

  if ((!started && !active) || originalDocId === '' || currentDocumentId === originalDocId) {
    return null;
  }

  return (
    <>
      {(active || (started && skipping)) &&
        ReactDOM.createPortal(
          <WelcomeScreen
            location="editor"
            onSkip={setSkipping}
            labelsId={{
              title: 'WELCOME_TO_THE_DODOC_EDITOR',
              description: 'WELCOME_EDITOR_DESCRIPTION',
              cta: 'EXPLORE_DODOC_EDITOR',
            }}
            hiddenElements={['EditorRoot']}
            padding="18.5rem 0 5rem"
          />,
          document.getElementById('WelcomeScreen') ?? document.body,
        )}
      <OnboardingOverlay environment="editor">
        <span
          style={{
            visibility:
              (!started && !skipping) || !userLoaded || !originalDocLoaded ? 'hidden' : 'visible',
          }}
        >
          <OnboardingIntegrationCards
            cards={cards}
            initialCard={initialCardIndex}
            onExit={handleSkip}
            onFinalExit={handleEnding}
            phases={phases}
            onNext={handleNext}
            onBack={handleBack}
            skipButtonLabel={intl.formatMessage({ id: 'SKIP_ONBOARDING' })}
            previousLabel={intl.formatMessage({ id: 'BACK' })}
            next={intl.formatMessage({ id: 'NEXT' })}
            skipPreviousLabel={intl.formatMessage({ id: 'NO_CONTINUE_ONBOARDING' })}
            skipFinishLabel={intl.formatMessage({ id: 'YES_SKIP' })}
            skipHeader={intl.formatMessage({ id: 'SKIP_THE_ONBOARDING_QUESTION' })}
            skipContent={intl.formatMessage(
              { id: 'ONBOARDING_EDITOR_SKIP_CONFIRMATION' },
              { br: <br /> },
            )}
            tip={intl.formatMessage({ id: 'TIP_COLON' })}
            title={intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' })}
            nextDisabled={
              !!activeCard.deps && !activeCard.deps.every((dep) => actionsCompleted[dep])
            }
            nextDisabledTooltip={intl.formatMessage({ id: 'ONBOARDING_PENDING_ACTION_TOOLTIP' })}
            offset={sidebar ? { right: '55rem' } : undefined}
            skip={skipping}
            cancelSkip={skipping ? onCancelSkip : undefined}
            testId="editor"
            pulse={
              pulsePosition?.position ? (
                pulsePosition.boundary ? (
                  ReactDOM.createPortal(
                    <Pulse position={pulsePosition.position} testId="editor" />,
                    pulsePosition.boundary,
                  )
                ) : (
                  <Pulse position={pulsePosition.position} testId="editor" />
                )
              ) : (
                renderDynamicPulse()
              )
            }
            box={
              boxPosition?.position &&
              (boxPosition.boundary ? (
                ReactDOM.createPortal(
                  <Box box={boxPosition.position} testId="editor" />,
                  boxPosition.boundary,
                )
              ) : (
                <Box box={boxPosition.position} testId="editor" />
              ))
            }
          />
        </span>
      </OnboardingOverlay>
    </>
  );
};

export default OnboardingEditorIntegration;
