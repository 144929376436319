import { useState } from 'react';

import ViewCommentCard from './ViewCommentCard';
import EditableCommentCard from './EditableCommentCard';

export type CommentCardProps = {
  id?: string;
  user: UserId;
  mainComment?: MyAny;
  sidebar?: boolean;
  subcomment?: boolean;
  isTask?: boolean;
  selected?: boolean;
  isReadOnlyMode?: boolean;
} & (
  | { isTemporary: true; comment?: undefined }
  | { isTemporary?: false | undefined; comment: Editor.Comment }
);

const CommentCard = ({
  id,
  comment,
  user,
  isReadOnlyMode,
  isTemporary,
  selected,
  sidebar,
}: CommentCardProps) => {
  const [editMode, setEditMode] = useState(isTemporary);

  if (editMode) {
    if (isTemporary) {
      return (
        <EditableCommentCard
          id={id}
          isTemporary={isTemporary}
          user={user}
          setEditMode={setEditMode}
          selected={selected}
          sidebar={sidebar}
          testId={`temporary`}
        />
      );
    } else {
      return (
        <EditableCommentCard
          id={id}
          comment={comment}
          user={user}
          setEditMode={setEditMode}
          selected={selected}
          sidebar={sidebar}
          testId={comment.id}
        />
      );
    }
  }

  if (comment) {
    return (
      <ViewCommentCard
        id={id}
        comment={comment}
        user={user}
        isReadOnlyMode={isReadOnlyMode}
        selected={selected}
        sidebar={sidebar}
        setEditMode={setEditMode}
        testId={comment.id}
      />
    );
  }

  return null;
};

export default CommentCard;
