/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */

import { Logger } from '_common/services';
import BaseController from '../BaseController';
import { NoteModel, NotesList } from './NotesList';
import { IndexerDeltaType } from '../Models/ModelIndexer';

export class NotesController extends BaseController {
  private notesList?: NotesList;
  private documentId?: string;

  constructor(Data: Editor.Data.State) {
    super(Data);
    this.handleNotesListError = this.handleNotesListError.bind(this);
    this.handleNotesListChanged = this.handleNotesListChanged.bind(this);
    this.handleNoteChanged = this.handleNoteChanged.bind(this);
    this.handleNotesModelUpdated = this.handleNotesModelUpdated.bind(this);
  }

  start(documentId: string): void {
    this.documentId = documentId;
    this.notesList = this.Data.models?.getIndexer('NOTES') as NotesList;
    this.notesList.on('ERROR', this.handleNotesListError);
    this.notesList.on('CHANGED_DELTA', this.handleNotesListChanged);
    this.notesList.start(documentId);
    this.notesList.notesModel?.on('UPDATED', this.handleNotesModelUpdated);
  }

  private handleNotesModelUpdated(
    data: Notes.NotesModelData | null,
    ops: Realtime.Core.RealtimeOps,
    source: any,
  ) {
    for (let index = 0; index < ops.length; index++) {
      if (ops[index].p[0] === 'p') {
        this.loadNotesData();
      }
    }
  }

  private handleNotesListError(error: Error) {
    Logger.error(error);
  }

  private handleNotesListChanged(
    data: IndexerDeltaType<{
      footNotes: Notes.NoteDataId[];
      endnotes: Notes.NoteDataId[];
    }>,
  ) {
    if (this.notesList) {
      for (let index = 0; index < data.in.footNotes.length; index++) {
        const element = this.notesList.note(data.in.footNotes[index]);
        element.on('UPDATED', this.handleNoteChanged);
      }
      for (let index = 0; index < data.in.endnotes.length; index++) {
        const element = this.notesList.note(data.in.endnotes[index]);
        element.on('UPDATED', this.handleNoteChanged);
      }
      this.loadNotesData();
    }
  }

  private loadNotesData() {
    const notesData: Notes.NotesPayload = {
      footnoteIndex: this.notesList?.footnotesList || [],
      endnoteIndex: this.notesList?.endnotesList || [],
      nts: {},
      p: this.notesList?.placement() || 'd',
    };
    if (this.notesList) {
      const notesList = [...notesData.endnoteIndex, ...notesData.footnoteIndex];
      for (let index = 0; index < notesList.length; index++) {
        notesData.nts[notesList[index]] = this.notesList.note(notesList[index])?.data;
      }
    }
    this.Data.events?.emit('LOAD_NOTES_DATA', notesData);
  }

  private handleNoteChanged(id: Notes.NoteDataId, data: Notes.NoteData, serial: number) {
    this.Data.events?.emit('UPDATE_NOTE', { ...data, id });
  }

  stop(): void {}

  destroy(): void {}

  get(noteId: Notes.NoteDataId): NoteModel | undefined {
    return this.notesList?.note(noteId);
  }

  getList() {
    return this.notesList;
  }

  get list() {
    return this.notesList;
  }

  refresh() {
    this.notesList?.refresh();
  }

  getNote(noteId: Notes.NoteDataId): Notes.NoteData | undefined {
    return this.notesList?.note(noteId).data;
  }

  getFootnoteSerial(footnoteId: Notes.NoteDataId): number | undefined {
    return this.notesList?.getFootnoteSerial(footnoteId);
  }

  getEndnoteSerial(endnoteId: Notes.NoteDataId): number | undefined {
    return this.notesList?.getEndnoteSerial(endnoteId);
  }

  getNoteLocation(noteId: Notes.NoteDataId): Notes.NoteLocation | undefined {
    return this.notesList?.getNoteLocation(noteId);
  }

  async addNote(noteId: Notes.NoteDataId | null, type: Notes.NoteType, content: string) {
    return this.notesList?.addNote(noteId, type, content, this.Data.users?.loggedUserId as string);
  }

  async editNote(noteId: Notes.NoteDataId, content?: string) {
    return this.notesList?.editNote(noteId, content);
  }

  deleteNote(noteId: Notes.NoteDataId): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'NOTE:DELETE',
        {
          document: this.documentId as string,
          noteId,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject();
          }
        },
      );
    });
  }

  placement() {
    return this.notesList?.placement();
  }

  async setPlacement(placement: Notes.NotePlacement) {
    return this.notesList?.setPlacement(placement);
  }
}
