import { FormattedMessage, useIntl } from 'react-intl';
import { Toggle } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { restoreObject } from './RecyclePageSlice';

import { ActionBar } from '_common/components';

const Header = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const selectedState = useSelector((state) => state.table.selected);

  const handleRestore = () => {
    Object.keys(selectedState).forEach((sKey) => {
      if (selectedState[sKey]) {
        dispatch(restoreObject({ objectId: sKey }));
      }
    });
  };

  return (
    <ActionBar>
      <ActionBar.Breadcrumb
        path={[
          {
            id: 'recycle',
            name: intl.formatMessage({
              id: 'storage.sidebar.recycleBin',
            }),
          },
        ]}
      />
      <Toggle
        size="medium"
        variant="standard"
        icon="RestoreBlue"
        onClick={handleRestore}
        disabled={Object.values(selectedState).length === 0}
        testId="recycle-page-restore-button"
      >
        <FormattedMessage id="storage.actionBar.restore" />
      </Toggle>
    </ActionBar>
  );
};

export default Header;
