import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProgressBar } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';

import { AppOverlay } from '_common/components';
import styles from './EditorContentLoader.module.scss';

const ALLOWED_STRINGS: TranslationId[] = [
  'LOADING_CONTENT',
  'LOADING_PASTED_CONTENT',
  'UPDATING_DOCUMENT',
  'EDITOR_PROCESSING_STATE',
  'UPDATING_TEMPLATE',
  'DOWNLOADING_REFERENCE_LIBRARY',
  'EXPORTING_REFERENCE_LIBRARY',
  'EXPORTING_AUDIT_LOG',
  'EXPORTING_DOCUMENT',
];

const EditorContentLoader = () => {
  const editorLoading = useSelector((state) => state.editor.status.loading);

  if (!editorLoading) {
    return null;
  }

  let stringId: (typeof ALLOWED_STRINGS)[number];

  if (typeof editorLoading !== 'boolean' && ALLOWED_STRINGS.includes(editorLoading)) {
    stringId = editorLoading;
  } else {
    stringId = 'LOADING_CONTENT';
  }

  return (
    <AppOverlay>
      <div className={styles.backgroundContainer}>
        <div className={styles.mainContainer}>
          <ProgressBar size="medium" color="dark" testId="editor-loading-progressBar" />
          <div className={styles.textContainer}>
            <FormattedMessage
              id={stringId}
              values={{ p: (chunks: ReactNode) => <div>{chunks}</div> }}
            />
          </div>
        </div>
      </div>
    </AppOverlay>
  );
};

export default EditorContentLoader;
