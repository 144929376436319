import { useIntl } from 'react-intl';
import { Label } from 'dodoc-design-system';

import StatusLabel from '_common/components/Tables/Status/Status';
import styles from './Status.module.scss';

import type { LabelProps } from 'dodoc-design-system/build/types/Components/Label/Label';
import { useSelector } from '_common/hooks';

type StatusProps = { oldStatusId: string; newStatusId: string; testId: string };

const TASK_STATUS = {
  td: 'TODO',
  pr: 'IN_PROGRESS',
  d: 'auth.login.done',
  [`TO-DO`]: 'TODO',
  [`IN PROGRESS`]: 'IN_PROGRESS',
  DONE: 'auth.login.done',
};

const Status = ({ oldStatusId, newStatusId, testId }: StatusProps) => {
  const intl = useIntl();
  const isPdf = useSelector((state) => state.pdf.general.pdfId);

  const renderTaskStatus = (statusId: keyof typeof TASK_STATUS, testId: string) => {
    const status: { name: string; color: LabelProps['color'] } = { name: '', color: 'neutral' };
    status.name = intl.formatMessage({ id: TASK_STATUS[statusId] }).toUpperCase();

    switch (statusId) {
      case 'TO-DO':
      case 'td':
        status.color = 'neutral';
        break;
      case 'IN PROGRESS':
      case 'pr':
        status.color = 'blue';
        break;
      case 'DONE':
      case 'd':
        status.color = 'green';
        break;
      default:
        break;
    }

    return (
      <Label
        title={status.name}
        color={status.color}
        size={isPdf ? 'small' : 'medium'}
        testId={testId}
      >
        {status.name}
      </Label>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {oldStatusId === 'td' ||
        oldStatusId === 'pr' ||
        oldStatusId === 'd' ||
        oldStatusId === 'TO-DO' ||
        oldStatusId === 'IN PROGRESS' ||
        oldStatusId === 'DONE' ? (
          renderTaskStatus(oldStatusId, `${testId}-old-${oldStatusId}-label`)
        ) : (
          <StatusLabel
            id={oldStatusId}
            size={isPdf ? 'small' : undefined}
            testId={`${testId}-old-${oldStatusId}-label`}
          />
        )}
        <div className={styles.arrow}>→</div>
        {newStatusId === 'td' ||
        newStatusId === 'pr' ||
        newStatusId === 'd' ||
        newStatusId === 'TO-DO' ||
        newStatusId === 'IN PROGRESS' ||
        newStatusId === 'DONE' ? (
          renderTaskStatus(newStatusId, `${testId}-new-${oldStatusId}-label`)
        ) : (
          <StatusLabel
            id={newStatusId}
            size={isPdf ? 'small' : undefined}
            testId={`${testId}-new-${newStatusId}-label`}
          />
        )}
      </div>
    </div>
  );
};
export default Status;
