import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import styles from './Description.module.scss';

type VersionDescriptionProps = { description?: Card.Version['description'] };

const VersionDescription = ({ description }: VersionDescriptionProps) => {
  return (
    <div
      className={cx({
        [styles.description]: true,
        [styles.empty]: !description,
      })}
      title={description}
    >
      {description || <FormattedMessage id="NO_DESCRIPTION" />}
    </div>
  );
};

export default VersionDescription;
