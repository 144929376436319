import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

export type Structure = {
  section: IconTypes['40'];
  label: TranslationId;
  elements: {
    label: string;
    mathml: string;
    latex: string;
    repX?: number;
    repY?: number;
  }[];
};

export type Symbol = {
  section: IconTypes['40'];
  label: TranslationId;
  elements: {
    code: string;
    label: string;
    mathml?: string;
    latex: string;
  }[];
};

//#region Structures Consts
//#region Fractions
const FRACTIONS = {
  section: 'Fraction',
  label: 'EQUATION_STRUCTURE_FRACTION',
  elements: [
    {
      label: 'fraction',
      mathml: `<math xmlns="http://www.w3.org/1998/Math/MathML"><mfrac><mi>a</mi><mi>b</mi></mfrac></math>`,
      latex: '\\frac{a}{b}',
    },
    {
      label: 'fraction2',
      mathml: `<math xmlns="http://www.w3.org/1998/Math/MathML"><mfrac bevelled="true"><mi>a</mi><mi>b</mi></mfrac></math>`,
      latex: 'a/b',
    },
    {
      label: 'fraction3',
      mathml: `<math xmlns="http://www.w3.org/1998/Math/MathML"><mi>a</mi><mo>/</mo><mi>b</mi></math>`,
      latex: 'a/b',
    },
  ],
};
//#endregion

//#region Radicals
const RADICALS = {
  section: 'Radical',
  label: 'EQUATION_STRUCTURE_RADICAL',
  elements: [
    {
      label: 'radical1',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
      <msqrt>
          <mi>a</mi>
          </msqrt>
      </math>`,
      latex: '\\sqrt{a}',
    },
    {
      label: 'radical2',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mroot>
          <mi>a</mi>
          <mi>b</mi>
        </mroot>
      </math>`,
      latex: '\\sqrt[b]{a}',
    },
    {
      label: 'radical3',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mroot>
          <mi>a</mi>
          <mn>2</mn>
        </mroot>
      </math>`,
      latex: '\\sqrt[2]a',
    },
    {
      label: 'radical4',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mroot>
          <mi>a</mi>
          <mn>3</mn>
        </mroot>
      </math>`,
      latex: '\\sqrt[3]a',
    },
  ],
};
//#endregion

//#region Integrals
const INTEGRALS = {
  section: 'DifferentialsIntegrals',
  label: 'EQUATION_STRUCTURE_DIFFERENTIAL',
  elements: [
    {
      label: 'integral1',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mo>∫</mo>
        <mi>a</mi>
      </math>`,
      latex: '\\int a',
    },
    {
      label: 'integral2',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <msubsup>
          <mo>∫</mo>
          <mi>c</mi>
          <mi>b</mi>
        </msubsup>
        <mi>a</mi>
      </math>`,
      latex: '{\\int }_{c}^{b}a',
    },
    {
      label: 'integral3',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <munderover>
          <mo>∫</mo>
          <mi>c</mi>
          <mi>b</mi>
        </munderover>
        <mi>a</mi>
      </math>`,
      latex: '\\underset{c}{\\overset{b}{\\int }}a',
    },
    {
      label: 'integral4',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mo>∬</mo>
        <mi>a</mi>
      </math>`,
      latex: '\\iint a',
    },
    {
      label: 'integral5',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <msubsup>
          <mo>∬</mo>
          <mi>c</mi>
          <mi>b</mi>
        </msubsup>
        <mi>a</mi>
      </math>`,
      latex: '\\{\\iint }_{c}^{b}a',
    },
    {
      label: 'integral6',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <munderover>
          <mo>∬</mo>
          <mi>c</mi>
          <mi>b</mi>
        </munderover>
        <mi>a</mi>
      </math>`,
      latex: '\\underset{c}{\\overset{b}{\\iint }}a',
    },
    {
      label: 'integral7',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mo>∭</mo>
        <mi>a</mi>
      </math>`,
      latex: '\\iiint a',
    },
    {
      label: 'integral8',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <msubsup>
          <mo>∭</mo>
          <mi>c</mi>
          <mi>b</mi>
        </msubsup>
        <mi>a</mi>
      </math>`,
      latex: '{\\iiint }_{c}^{b}a',
    },
    {
      label: 'integral9',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <munderover>
          <mo>∭</mo>
          <mi>c</mi>
          <mi>b</mi>
        </munderover>
        <mi>a</mi>
      </math>`,
      latex: '\\underset{c}{\\overset{b}{\\iiint }}a',
    },
    {
      label: 'contour1',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mrow>
          <mo stretchy="false">∮</mo>
          <mrow>
            <mi>a</mi>
          </mrow>
        </mrow>
      </math>`,
      latex: '\\oint a',
    },
    {
      label: 'contour2',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <msubsup>
          <mo>∮</mo>
          <mi>c</mi>
          <mi>b</mi>
        </msubsup>
        <mi>a</mi>
      </math>
      `,
      latex: '{\\oint }_{c}^{b}a',
    },
    {
      label: 'contour3',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <munderover>
          <mo>∮</mo>
          <mi>c</mi>
          <mi>b</mi>
        </munderover>
        <mi>a</mi>
      </math>
      `,
      latex: '\\underset{c}{\\overset{b}{\\oint }}a',
    },
    {
      label: 'contour4',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mo>∯</mo>
        <mi>a</mi>
      </math>
      `,
      latex: '∯a',
    },
    {
      label: 'contour5',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <msubsup>
          <mo>∯</mo>
          <mi>c</mi>
          <mi>b</mi>
        </msubsup>
        <mi>a</mi>
      </math>
      `,
      latex: '{∯}_{c}^{b}a',
    },
    {
      label: 'contour6',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <munderover>
          <mo>∯</mo>
          <mi>c</mi>
          <mi>b</mi>
        </munderover>
        <mi>a</mi>
      </math>
      `,
      latex: '\\underset{c}{\\overset{b}{∯}}a',
    },
    {
      label: 'contour7',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mo>∰</mo>
        <mi>a</mi>
      </math>
      `,
      latex: '∰a',
    },
    {
      label: 'contour8',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mrow>
          <msubsup>
            <mo stretchy="false">∰</mo>
            <mrow>
              <mi>c</mi>
            </mrow>
            <mrow>
              <mi>b</mi>
            </mrow>
          </msubsup>
          <mrow>
            <mi>a</mi>
          </mrow>
        </mrow>
      </math>
      `,
      latex: '{∰}_{c}^{b}a',
    },
    {
      label: 'contour9',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mrow>
          <munderover>
            <mo stretchy="false">∰</mo>
            <mrow>
              <mi>c</mi>
            </mrow>
            <mrow>
              <mi>b</mi>
            </mrow>
          </munderover>
          <mrow>
            <mi>a</mi>
          </mrow>
        </mrow>
      </math>
      `,
      latex: '\\underset{c}{\\overset{b}{∰}}a',
    },
  ],
};
//#endregion

//#region Scripts
const SCRIPTS = {
  section: 'Scripts',
  label: 'EQUATION_STRUCTURE_SCRIPTS',
  elements: [
    {
      label: 'superscript',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <msup>
            <mi>a</mi>
            <mi>b</mi>
        </msup>
    </math>`,
      latex: '{a}^{b}',
    },
    {
      label: 'subscript',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <msub>
            <mi>a</mi>
            <mi>b</mi>
        </msub>
    </math>`,
      latex: '{a}_{b}',
    },
    {
      label: 'supsubscript',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
          <msubsup>
              <mi>a</mi>
              <mi>c</mi>
              <mi>b</mi>
          </msubsup>
      </math>`,
      latex: '{a}_{c}^{b}',
    },
    {
      label: 'multiscript',
      mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mmultiscripts>
            <mi>a</mi>
            <mprescripts/>
            <mi>c</mi>
            <mi>b</mi>
            </mmultiscripts>
    </math>`,
      latex: '{}_{c}{}^{b}a',
    },
  ],
};
//#endregion

//#region LargeOperators
const LARGE_SUMMATIONS = [
  {
    label: 'summation1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mo>∑</mo>
      <mi>a</mi>
    </math>`,
    latex: '\\sum a',
  },
  {
    label: 'summation2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <munderover>
        <mo>∑</mo>
        <mi>c</mi>
        <mi>b</mi>
      </munderover>
      <mi>a</mi>
  </math>`,
    latex: '\\sum _{c}^{b}a',
  },

  {
    label: 'summation4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
  <munder>
      <mo>∑</mo>
      <mi>b</mi>
  </munder>
  <mi>a</mi>
</math>`,
    latex: '\\sum _{b}a',
  },
];

const LARGE_PRODUCTS = [
  {
    label: 'product1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
  <mo>∏</mo>
  <mi>a</mi>
</math>`,
    latex: '\\prod a',
  },
  {
    label: 'product2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
  <mrow>
      <munderover>
          <mo stretchy="false">∏</mo>
          <mrow>
              <mi>c</mi>
          </mrow>
          <mrow>
              <mi>b</mi>
          </mrow>
      </munderover>
      <mrow>
          <mi>a</mi>
      </mrow>
  </mrow>
</math>`,
    latex: '\\prod _{c}^{b}a',
  },

  {
    label: 'product4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
  <munder>
      <mo>∏</mo>
      <mi>b</mi>
  </munder>
  <mi>a</mi>
</math>`,
    latex: '\\prod _{b}a',
  },

  {
    label: 'product6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
  <mo>∐</mo>
  <mi>a</mi>
</math>`,
    latex: '\\coprod a',
  },
  {
    label: 'product7',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
  <munderover>
      <mo>∐</mo>
      <mi>c</mi>
      <mi>b</mi>
  </munderover>
  <mi>a</mi>
</math>`,
    latex: '\\coprod _{c}^{b}a',
  },

  {
    label: 'product9',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
  <munder>
      <mo>∐</mo>
      <mi>b</mi>
  </munder>
  <mi>a</mi>
</math>
`,
    latex: '\\coprod _{b}a',
  },
];

const LARGE_UNIONS = [
  {
    label: 'union1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mo largeop="true">∪</mo>
    <mi>a</mi>
</math>
    `,
    latex: '\\bigcup a',
  },
  {
    label: 'union2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
        <munderover>
            <mo largeop="true">∪</mo>
            <mi>c</mi>
            <mi>b</mi>
        </munderover>
        <mi>a</mi>
    </math>
    `,
    latex: '\\bigcup _{c}^{b}a',
  },
  {
    label: 'union3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mrow>
        <msubsup>
            <mo stretchy="false">⋃</mo>
            <mrow>
                <mi>c</mi>
            </mrow>
            <mrow>
                <mi>b</mi>
            </mrow>
        </msubsup>
        <mrow>
            <mi>a</mi>
        </mrow>
    </mrow>
</math>
    `,
    latex: '{\\bigcup }_{c}^{b}a',
  },
  {
    label: 'union4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <munder>
        <mo largeop="true">∪</mo>
        <mi>b</mi>
    </munder>
    <mi>a</mi>
</math>
    `,
    latex: '\\bigcup _{b}a',
  },
  {
    label: 'union5',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msub>
        <mo largeop="true">∪</mo>
        <mi>b</mi>
    </msub>
    <mi>a</mi>
</math>
    `,
    latex: '{\\bigcup }_{b}a',
  },
  {
    label: 'union6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mo largeop="true">∩</mo>
    <mi>a</mi>
</math>
    `,
    latex: '\\bigcap a',
  },
  {
    label: 'union7',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <munderover>
        <mo largeop="true">∩</mo>
        <mi>c</mi>
        <mi>b</mi>
    </munderover>
    <mi>a</mi>
</math>
    `,
    latex: '\\bigcap _{c}^{b}a',
  },
  {
    label: 'union8',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msubsup>
        <mo largeop="true">∩</mo>
        <mi>c</mi>
        <mi>b</mi>
    </msubsup>
    <mi>a</mi>
</math>
    `,
    latex: '{\\bigcap }_{c}^{b}a',
  },
  {
    label: 'union9',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <munder>
        <mo largeop="true">∩</mo>
        <mi>b</mi>
    </munder>
    <mi>a</mi>
</math>
    `,
    latex: '\\bigcap _{b}a',
  },
  {
    label: 'union10',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msub>
        <mo largeop="true">∩</mo>
        <mi>b</mi>
    </msub>
    <mi>a</mi>
</math>
    `,
    latex: '{\\bigcap }_{b}a',
  },
];

const OTHER_LARGE_OPERATORS = [
  {
    label: 'otherOperator1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mo>⋁</mo>
    <mi>a</mi>
</math>
    `,
    latex: '\\bigvee a',
  },
  {
    label: 'otherOperator2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <munderover>
        <mo>⋁</mo>
        <mi>c</mi>
        <mi>b</mi>
    </munderover>
    <mi>a</mi>
</math>
    `,
    latex: '\\underset{c}{\\overset{b}{\\bigvee }}a',
  },
  {
    label: 'otherOperator4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <munder>
        <mo>⋁</mo>
        <mi>b</mi>
    </munder>
    <mi>a</mi>
</math>
    `,
    latex: '\\underset{b}{\\bigvee }a',
  },
  {
    label: 'otherOperator6',
    mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mo>⋀</mo>
      <mi>a</mi>
  </math>
      `,
    latex: '\\bigwedge a',
  },
  {
    label: 'otherOperator7',
    mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
          <munderover>
              <mo stretchy="false">⋀</mo>
              <mrow>
                  <mi>c</mi>
              </mrow>
              <mrow>
                  <mi>b</mi>
              </mrow>
          </munderover>
          <mrow>
              <mi>a</mi>
          </mrow>
      </mrow>
  </math>
      `,
    latex: '\\underset{c}{\\overset{b}{\\bigwedge }}a',
  },
  {
    label: 'otherOperator10',
    mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
      <msub>
          <mo>⋀</mo>
          <mi>b</mi>
      </msub>
      <mi>a</mi>
  </math>
      `,
    latex: '{\\bigwedge }_{b}a',
  },
];

const LARGE_OPERATORS = {
  section: 'LargeOperators',
  label: 'EQUATION_STRUCTURE_LARGE_OPERATORS',
  elements: [...LARGE_SUMMATIONS, ...LARGE_PRODUCTS, ...LARGE_UNIONS, ...OTHER_LARGE_OPERATORS],
};
//#endregion

//#region Brackets
const BRACKETS = [
  {
    label: 'bracket1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced separators="|">
        <mi>a</mi>
    </mfenced>
</math>
    `,
    latex: '\\left(a\\right)',
  },
  {
    label: 'bracket2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="[" close="]" separators="|">
        <mi>a</mi>
    </mfenced>
    </math>
    `,
    latex: '\\left[a\\right]',
  },
  {
    label: 'bracket3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="{" close="}" separators="|">
        <mi>a</mi>
    </mfenced>
    </math>
    `,
    latex: '\\left\\{a\\right\\}',
  },
  {
    label: 'bracket4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="<" close=">" separators="|">
        <mi>a</mi>
    </mfenced>
</math>
    `,
    latex: '⟨a⟩',
  },
  {
    label: 'bracket5',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="⌊" close="⌋" separators="|">
        <mi>a</mi>
    </mfenced>
</math>
    `,
    latex: '⌊a⌋',
  },
  {
    label: 'bracket6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="⌈" close="⌉" separators="|">
        <mrow>
            <mi>a</mi>
        </mrow>
    </mfenced>
</math>
    `,
    latex: '⌈a⌉',
  },
  {
    label: 'bracket7',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="|" close="|" separators="|">
        <mi>a</mi>
    </mfenced>
</math>
    `,
    latex: '\\left|a\\right|',
  },
  {
    label: 'bracket8',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="||" close="||" separators="|">
        <mi>a</mi>
    </mfenced>
</math>
    `,
    latex: '‖a‖',
  },
  {
    label: 'bracket9',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="[" close="[" separators="|">
        <mi>a</mi>
    </mfenced>
</math>
    `,
    latex: '\\left[a\\right[',
  },
  {
    label: 'bracket10',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="]" close="]" separators="|">
        <mi>a</mi>
    </mfenced>
</math>
    `,
    latex: '\\left]a\\right]',
  },
  {
    label: 'bracket11',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="]" close="[" separators="|">
        <mi>a</mi>
    </mfenced>
</math>
    `,
    latex: '\\left]a\\right[',
  },
  {
    label: 'bracket12',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mfenced open="⟦" close="⟧" separators="|">
          <mi>a</mi>
      </mfenced>
    </math>
    `,
    latex: '⟦a⟧',
  },
];

const BRACKETS_WITH_SEPARATORS = [
  {
    label: 'bracketWSep1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced separators="|">
        <mi>a</mi>
        <mi>b</mi>
    </mfenced>
</math>
    `,
    latex: '\\left(a|b\\right)',
  },
  {
    label: 'bracketWSep2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="{" close="}" separators="|">
        <mi>a</mi>
        <mi>b</mi>
    </mfenced>
</math>
    `,
    latex: '\\left\\{ a|b\\right\\}',
  },
  {
    label: 'bracketWSep3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="⟨" close="⟩" separators="|">
        <mrow>
            <mi>a</mi>
        </mrow>
        <mrow>
            <mi>b</mi>
        </mrow>
    </mfenced>
</math>
    `,
    latex: '⟨a|b⟩',
  },
  {
    label: 'bracketWSep4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mfenced open="⟨" close="⟩" separators="|">
        <mrow>
            <mi>a</mi>
        </mrow>
        <mrow>
            <mi>b</mi>
        </mrow>
        <mrow>
            <mi>c</mi>
        </mrow>
    </mfenced>
</math>
    `,
    latex: '⟨a|b|c⟩',
  },
];

const CASES_AND_STACKS = [
  {
    label: 'case1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML" >
   <mfenced open="{" close="" separators="|">
      <mtable>
         <mtr>
            <mtd>
               <mi>a</mi>
            </mtd>
         </mtr>
         <mtr>
            <mtd>
               <mi>b</mi>
            </mtd>
         </mtr>
      </mtable>
   </mfenced>
</math>
    `,
    latex: '\\left\\{\\begin{array}{c}a\\\\ b\\end{array}\\right\\}',
  },
  {
    label: 'case2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mfenced open="{" close="" separators="|">
      <mtable>
         <mtr>
            <mtd>
               <mi>a</mi>
            </mtd>
         </mtr>
         <mtr>
            <mtd>
               <mi>b</mi>
            </mtd>
         </mtr>
         <mtr>
            <mtd>
               <mi>c</mi>
            </mtd>
         </mtr>
      </mtable>
   </mfenced>
</math>
    `,
    latex: '\\left\\{\\begin{array}{c}a\\\\ b\\\\ c\\end{array}\\right\\}',
  },
  {
    label: 'case3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mfrac linethickness="0pt">
      <mi>a</mi>
      <mi>b</mi>
   </mfrac>
</math>
    `,
    latex: '\\genfrac{}{}{0pt}{}{a}{b}',
  },
  {
    label: 'case4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mfenced separators="|">
      <mfrac linethickness="0pt">
         <mi>a</mi>
         <mi>b</mi>
      </mfrac>
   </mfenced>
</math>
    `,
    latex: '\\left(\\genfrac{}{}{0pt}{}{a}{b}\\right)',
  },
];

const BRACKET = {
  section: 'Brackets',
  label: 'EQUATION_STRUCTURE_BRACKETS',
  elements: [...BRACKETS, ...BRACKETS_WITH_SEPARATORS, ...CASES_AND_STACKS],
};
//#endregion

//#region Function
const TRIGONOMETRIC = [
  {
    label: 'trigonometric1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mi>sin</mi>
    <mo>⁡⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '\\mathrm{sin}a',
  },
  {
    label: 'trigonometric2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mrow>
        <mrow>
            <mi mathvariant="normal">cos</mi>
        </mrow>
        <mo>⁡</mo>
        <mrow>
            <mi>a</mi>
        </mrow>
    </mrow>
</math>
    `,
    latex: '\\mathrm{cos}a',
  },
  {
    label: 'trigonometric3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mrow>
        <mrow>
            <mi mathvariant="normal">tan</mi>
        </mrow>
        <mo>⁡</mo>
        <mrow>
            <mi>a</mi>
        </mrow>
    </mrow>
</math>
    `,
    latex: '\\mathrm{tan}a',
  },
  {
    label: 'trigonometric4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mi>csc</mi>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '\\mathrm{csc}a',
  },
  {
    label: 'trigonometric5',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mi>sec</mi>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '\\mathrm{sec}a',
  },
  {
    label: 'trigonometric6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mi>cot</mi>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '\\mathrm{cot}a',
  },
];

const INVERSE = [
  {
    label: 'inverse1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msup>
        <mi>sin</mi>
        <mrow>
            <mo>-</mo>
            <mn>1</mn>
        </mrow>
    </msup>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '{\\mathrm{sin}}^{-1}a',
  },
  {
    label: 'inverse2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msup>
        <mi>cos</mi>
        <mrow>
            <mo>-</mo>
            <mn>1</mn>
        </mrow>
    </msup>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '{\\mathrm{cos}}^{-1}a',
  },
  {
    label: 'inverse3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msup>
        <mi>tan</mi>
        <mrow>
            <mo>-</mo>
            <mn>1</mn>
        </mrow>
    </msup>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '{\\mathrm{tan}}^{-1}a',
  },
  {
    label: 'inverse4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msup>
        <mi>csc</mi>
        <mrow>
            <mo>-</mo>
            <mn>1</mn>
        </mrow>
    </msup>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '{\\mathrm{csc}}^{-1}a',
  },
  {
    label: 'inverse5',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msup>
        <mi>sec</mi>
        <mrow>
            <mo>-</mo>
            <mn>1</mn>
        </mrow>
    </msup>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '{\\mathrm{sec}}^{-1}a',
  },
  {
    label: 'inverse6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msup>
        <mi>cot</mi>
        <mrow>
            <mo>-</mo>
            <mn>1</mn>
        </mrow>
    </msup>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '{\\mathrm{cot}}^{-1}a',
  },
];

const HYPERBOLIC = [
  {
    label: 'hyperbolic1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mi>sinh</mi>
    <mo>⁡⁡⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '\\mathrm{sinh}a',
  },
  {
    label: 'hyperbolic2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mrow>
        <mrow>
            <mi mathvariant="normal">cosh</mi>
        </mrow>
        <mo>⁡</mo>
        <mrow>
            <mi>a</mi>
        </mrow>
    </mrow>
</math>
    `,
    latex: '\\mathrm{cosh}a',
  },
  {
    label: 'hyperbolic3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mrow>
        <mrow>
            <mi mathvariant="normal">tanh</mi>
        </mrow>
        <mo>⁡</mo>
        <mrow>
            <mi>a</mi>
        </mrow>
    </mrow>
</math>
    `,
    latex: '\\mathrm{tanh}a',
  },
  {
    label: 'hyperbolic4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mrow>
        <mrow>
            <mi mathvariant="normal">csch</mi>
        </mrow>
        <mo>⁡</mo>
        <mrow>
            <mi>a</mi>
        </mrow>
    </mrow>
</math>
    `,
    latex: '\\mathrm{csch}a',
  },
  {
    label: 'hyperbolic5',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mi>sech</mi>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '\\mathrm{sech}a',
  },
  {
    label: 'hyperbolic6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mi>coth</mi>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '\\mathrm{coth}a',
  },
];

const INVERSE_HYPERBOLIC = [
  {
    label: 'invrseHyperbolic1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mrow>
        <mrow>
            <msup>
                <mrow>
                    <mi mathvariant="normal">sinh</mi>
                </mrow>
                <mrow>
                    <mo>-</mo>
                    <mn>1</mn>
                </mrow>
            </msup>
        </mrow>
        <mo>⁡</mo>
        <mrow>
            <mi>a</mi>
        </mrow>
    </mrow>
</math>
    `,
    latex: '{\\mathrm{sinh}}^{-1}a',
  },
  {
    label: 'invrseHyperbolic2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mrow>
        <mrow>
            <msup>
                <mrow>
                    <mi mathvariant="normal">cosh</mi>
                </mrow>
                <mrow>
                    <mo>-</mo>
                    <mn>1</mn>
                </mrow>
            </msup>
        </mrow>
        <mo>⁡</mo>
        <mrow>
            <mi>a</mi>
        </mrow>
    </mrow>
</math>
    `,
    latex: '{\\mathrm{cosh}}^{-1}a',
  },
  {
    label: 'invrseHyperbolic3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msup>
        <mi>tanh</mi>
        <mrow>
            <mo>-</mo>
            <mn>1</mn>
        </mrow>
    </msup>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '{\\mathrm{tanh}}^{-1}a',
  },
  {
    label: 'invrseHyperbolic4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msup>
        <mi>csch</mi>
        <mrow>
            <mo>-</mo>
            <mn>1</mn>
        </mrow>
    </msup>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '{\\mathrm{csch}}^{-1}a',
  },
  {
    label: 'invrseHyperbolic5',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <msup>
        <mi>sech</mi>
        <mrow>
            <mo>-</mo>
            <mn>1</mn>
        </mrow>
    </msup>
    <mo>⁡</mo>
    <mi>a</mi>
</math>
    `,
    latex: '{\\mathrm{sech}}^{-1}a',
  },
  {
    label: 'invrseHyperbolic6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <msup>
      <mi>coth</mi>
      <mrow>
         <mo>-</mo>
         <mn>1</mn>
      </mrow>
   </msup>
   <mo>⁡</mo>
   <mi>a</mi>
</math>
    `,
    latex: '{\\mathrm{coth}}^{-1}a',
  },
];

const FUNCTION = {
  section: 'Functions',
  label: 'EQUATION_STRUCTURE_FUNCTIONS',
  elements: [...TRIGONOMETRIC, ...INVERSE, ...HYPERBOLIC, ...INVERSE_HYPERBOLIC],
};
//#endregion

//#region Symbol
const ACCENTS = [
  {
    label: 'accent1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>˙</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{˙}{a}',
  },
  {
    label: 'accent2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>¨</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{¨}{a}',
  },
  {
    label: 'accent3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>⃛</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{⃛}{a}',
  },
  {
    label: 'accent4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>^</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{^}{a}',
  },
  {
    label: 'accent5',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>ˇ</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{ˇ}{a}',
  },
  {
    label: 'accent6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>´</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{´}{a}',
  },
  {
    label: 'accent7',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>\`</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{`}{a}',
  },
  {
    label: 'accent8',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>˘</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{˘}{a}',
  },
  {
    label: 'accent9',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>~</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{~}{a}',
  },
  {
    label: 'accent10',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>-</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{-}{a}',
  },
  {
    label: 'accent11',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>̿</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{̿}{a}',
  },
  {
    label: 'accent12',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>⏞</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{⏞}{a}',
  },
  {
    label: 'accent13',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <mi>a</mi>
      <mo>⏟</mo>
   </munder>
</math>
    `,
    latex: '\\underset{⏟}{a}',
  },
  {
    label: 'accent14',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>b</mi>
      <mi>a</mi>
   </mover>
</math>
    `,
    latex: '\\stackrel{a}{b}',
  },
  {
    label: 'accent15',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <munder>
         <mi>a</mi>
         <mo>⏟</mo>
      </munder>
      <mi>b</mi>
   </munder>
</math>
    `,
    latex: '\\underset{b}{\\underset{⏟}{a}}',
  },
  {
    label: 'accent16',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>←</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{←}{a}',
  },
  {
    label: 'accent17',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>→</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{\\to }{a}',
  },
  {
    label: 'accent18',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>⃡</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{⃡}{a}',
  },
  {
    label: 'accent19',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>⃐</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{⃐}{a}',
  },
  {
    label: 'accent20',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>⃑</mo>
   </mover>
</math>
    `,
    latex: '\\stackrel{⃑}{a}',
  },
];

const BOXED_FORMULAS = [
  {
    label: 'boxed1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <menclose notation="box">
      <mi>a</mi>
   </menclose>
</math>
    `,
    latex: '\\overline{)a}',
  },
  {
    label: 'boxed2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <menclose notation="box">
      <msup>
         <mi>a</mi>
         <mn>2</mn>
      </msup>
      <mo>=</mo>
      <msup>
         <mi>b</mi>
         <mn>2</mn>
      </msup>
      <mo>+</mo>
      <msup>
         <mi>c</mi>
         <mn>2</mn>
      </msup>
   </menclose>
</math>
    `,
    latex: '{a}^{2}={b}^{2}+{c}^{2}',
    repX: 2,
  },
];

const OVER_UNDER_BARS = [
  {
    label: 'overUnderBars1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mi>a</mi>
      <mo>¯</mo>
   </mover>
</math>
    `,
    latex: '\\overline{a}',
  },
  {
    label: 'overUnderBars2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <mi>a</mi>
      <mo>_</mo>
   </munder>
</math>
    `,
    latex: '\\underset{_}{a}',
  },
];

const SYMBOL = {
  section: 'Symbols',
  label: 'EQUATION_STRUCTURE_SYMBOLS',
  elements: [...ACCENTS, ...BOXED_FORMULAS, ...OVER_UNDER_BARS],
};
//#endregion

//#region LogLimit
const LOG_LIMIT_FUNCTIONS = [
  {
    label: 'logLimitFunction1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <msub>
      <mi>log</mi>
      <mi>b</mi>
   </msub>
   <mo>⁡</mo>
   <mi>a</mi>
</math>
    `,
    latex: '{\\mathrm{log}}_{b}a',
  },
  {
    label: 'logLimitFunction2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mi>log</mi>
   <mo>⁡</mo>
   <mi>a</mi>
</math>
    `,
    latex: '\\mathrm{log}a',
  },
  {
    label: 'logLimitFunction3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <mi>lim</mi>
      <mi>b</mi>
   </munder>
   <mo>⁡</mo>
   <mi>a</mi>
</math>
    `,
    latex: '\\underset{b}{\\mathrm{lim}}a',
  },
  {
    label: 'logLimitFunction4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <mi>min</mi>
      <mi>b</mi>
   </munder>
   <mo>⁡</mo>
   <mi>a</mi>
</math>
    `,
    latex: '\\underset{b}{\\mathrm{min}}a',
  },
  {
    label: 'logLimitFunction5',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <mi>max</mi>
      <mi>b</mi>
   </munder>
   <mo>⁡</mo>
   <mi>a</mi>
</math>
    `,
    latex: '\\underset{b}{\\mathrm{max}}a',
  },
  {
    label: 'logLimitFunction6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mi>ln</mi>
   <mo>⁡</mo>
   <mi>a</mi>
</math>
    `,
    latex: '\\mathrm{ln}a',
  },
];

const LOGLIMIT = {
  section: 'LogarithmsLimits',
  label: 'EQUATION_STRUCTURE_LOG_LIMIT',
  elements: [...LOG_LIMIT_FUNCTIONS],
};
//#endregion

//#region Operator
const BASIC_OPERATORS = [
  {
    label: 'basicOperator1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mo>∶</mo>
   <mo>=</mo>
</math>
    `,
    latex: ':=',
  },
  {
    label: 'basicOperator2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mo>=</mo>
   <mo>=</mo>
</math>
    `,
    latex: '==',
  },
  {
    label: 'basicOperator3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mo>+</mo>
      <mo>=</mo>
    </math>
    `,
    latex: '+=',
  },
  {
    label: 'basicOperator4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mo>-</mo>
    <mo>=</mo>
    </math>
    `,
    latex: '-=',
  },
  {
    label: 'basicOperator5',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mo>≝</mo>
</math>
    `,
    latex: '\\stackrel{\\scriptscriptstyle\\mathrm{def}}{=}',
  },
  {
    label: 'basicOperator6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mo>≞</mo>
</math>
    `,
    latex: '\\stackrel{\\scriptscriptstyle\\mathrm{m}}{=}',
  },
  {
    label: 'basicOperator7',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mo>≜</mo>
</math>
    `,
    latex: '\\triangleq',
  },
];

const OPERATOR_STRUCTURES = [
  {
    label: 'operatorStructure1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <mo>←</mo>
      <mi>a</mi>
   </munder>
</math>
    `,
    latex: '\\underset{a}{←}',
  },
  {
    label: 'operatorStructure2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <mo>→</mo>
      <mi>a</mi>
   </munder>
</math>
    `,
    latex: '\\underset{a}{\\to }',
  },
  {
    label: 'operatorStructure3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mo>←</mo>
      <mi>a</mi>
   </mover>
</math>
    `,
    latex: '\\stackrel{a}{←}',
  },
  {
    label: 'operatorStructure4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mo>→</mo>
      <mi>a</mi>
   </mover>
</math>
    `,
    latex: '\\stackrel{a}{\to }',
  },
  {
    label: 'operatorStructure5',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <mo>⇐</mo>
      <mi>a</mi>
   </munder>
</math>
    `,
    latex: '\\underset{a}{⇐}',
  },
  {
    label: 'operatorStructure6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <mo>⇒</mo>
      <mi>a</mi>
   </munder>
</math>
    `,
    latex: '\\underset{a}{⇒}',
  },
  {
    label: 'operatorStructure7',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mo>⇐</mo>
      <mi>a</mi>
   </mover>
</math>
    `,
    latex: '\\stackrel{a}{⇐}',
  },
  {
    label: 'operatorStructure8',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mo>⇒</mo>
      <mi>a</mi>
   </mover>
</math>
    `,
    latex: '\\stackrel{a}{⇒}',
  },
  {
    label: 'operatorStructure9',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <mo>↔</mo>
      <mi>a</mi>
   </munder>
</math>
    `,
    latex: '\\underset{a}{↔}',
  },
  {
    label: 'operatorStructure10',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
       <mover accent="false">
          <mo>↔</mo>
          <mrow>
             <mi>a</mi>
          </mrow>
       </mover>
    </math>
    `,
    latex: '\\stackrel{a}{⇔}',
  },
  {
    label: 'operatorStructure11',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <munder>
      <mo>⇔</mo>
      <mi>a</mi>
   </munder>
</math>
    `,
    latex: '\\underset a\\Leftrightarrow',
  },
  {
    label: 'operatorStructure12',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mover>
      <mo>⇔</mo>
      <mi>a</mi>
   </mover>
</math>
    `,
    latex: '\\overset a\\Leftrightarrow',
  },
];

const OPERATOR = {
  section: 'Operators',
  label: 'EQUATION_STRUCTURE_OPERATOR',
  elements: [...BASIC_OPERATORS, ...OPERATOR_STRUCTURES],
};
//#endregion

//#region Matrix
const EMPTY_MATRIX = [
  {
    label: 'emtpyMatrix1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mi>a</mi>
         </mtd>
         <mtd>
            <mi>b</mi>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{cc}a& b\\end{array}',
  },
  {
    label: 'emtpyMatrix2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mi>a</mi>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mi>b</mi>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{c}a\\\\ b\\end{array}',
  },
  {
    label: 'emtpyMatrix3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mi>a</mi>
         </mtd>
         <mtd>
            <mi>b</mi>
         </mtd>
         <mtd>
            <mi>c</mi>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{ccc}a& b& c\\end{array}',
  },
  {
    label: 'emtpyMatrix4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mi>a</mi>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mi>b</mi>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mi>c</mi>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{c}a\\\\ b\\\\ c\\end{array}',
  },
  {
    label: 'emtpyMatrix5',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mi>a</mi>
         </mtd>
         <mtd>
            <mi>b</mi>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mi>c</mi>
         </mtd>
         <mtd>
            <mi>d</mi>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{cc}a& b\\\\ c& d\\end{array}',
  },
  {
    label: 'emtpyMatrix6',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mi>a</mi>
         </mtd>
         <mtd>
            <mi>b</mi>
         </mtd>
         <mtd>
            <mi>c</mi>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mi>d</mi>
         </mtd>
         <mtd>
            <mi>e</mi>
         </mtd>
         <mtd>
            <mi>f</mi>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{ccc}a& b& c\\\\ d& e& f\\end{array}',
  },
  {
    label: 'emtpyMatrix7',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mi>a</mi>
         </mtd>
         <mtd>
            <mi>b</mi>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mi>c</mi>
         </mtd>
         <mtd>
            <mi>d</mi>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mi>e</mi>
         </mtd>
         <mtd>
            <mi>f</mi>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{cc}a& b\\\\ c& d\\\\ e& f\\end{array}',
  },
  {
    label: 'emtpyMatrix8',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mi>a</mi>
         </mtd>
         <mtd>
            <mi>b</mi>
         </mtd>
         <mtd>
            <mi>c</mi>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mi>d</mi>
         </mtd>
         <mtd>
            <mi>e</mi>
         </mtd>
         <mtd>
            <mi>f</mi>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mi>g</mi>
         </mtd>
         <mtd>
            <mi>h</mi>
         </mtd>
         <mtd>
            <mi>i</mi>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{ccc}a& b& c\\\\ d& e& f\\\\ g& h& i\\end{array}',
  },
];

const DOTS_MATRIX = [
  {
    label: 'dotsMatrix1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mo>⋯</mo>
</math>
    `,
    latex: '\\cdots',
  },
  {
    label: 'dotsMatrix2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mo>…</mo>
 </math>
    `,
    latex: '\\dots',
  },
  {
    label: 'dotsMatrix3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mo>⋮</mo>
</math>
    `,
    latex: '⋮',
  },
  {
    label: 'dotsMatrix4',
    mathml: `
<math xmlns="http://www.w3.org/1998/Math/MathML">
<mo>⋱</mo>
</math>
    `,
    latex: '\\ddots',
  },
];

const IDENTITY_MATRIX = [
  {
    label: 'identityMatrix1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mn>1</mn>
         </mtd>
         <mtd>
            <mn>0</mn>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mn>0</mn>
         </mtd>
         <mtd>
            <mn>1</mn>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{cc}1& 0\\\\ 0& 1\\end{array}',
  },
  {
    label: 'identityMatrix2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mn>1</mn>
         </mtd>
         <mtd />
      </mtr>
      <mtr>
         <mtd />
         <mtd>
            <mn>1</mn>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{cc}1& \\\\ & 1\\end{array}',
  },
  {
    label: 'identityMatrix3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mn>1</mn>
         </mtd>
         <mtd>
            <mn>0</mn>
         </mtd>
         <mtd>
            <mn>0</mn>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mn>0</mn>
         </mtd>
         <mtd>
            <mn>1</mn>
         </mtd>
         <mtd>
            <mn>0</mn>
         </mtd>
      </mtr>
      <mtr>
         <mtd>
            <mn>0</mn>
         </mtd>
         <mtd>
            <mn>0</mn>
         </mtd>
         <mtd>
            <mn>1</mn>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{ccc}1& 0& 0\\\\ 0& 1& 0\\\\ 0& 0& 1\\end{array}',
  },
  {
    label: 'identityMatrix4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mtable>
      <mtr>
         <mtd>
            <mn>1</mn>
         </mtd>
         <mtd />
         <mtd />
      </mtr>
      <mtr>
         <mtd />
         <mtd>
            <mn>1</mn>
         </mtd>
         <mtd />
      </mtr>
      <mtr>
         <mtd />
         <mtd />
         <mtd>
            <mn>1</mn>
         </mtd>
      </mtr>
   </mtable>
</math>
    `,
    latex: '\\begin{array}{ccc}1& & \\\\ & 1& \\\\ & & 1\\end{array}',
  },
];

const BRACKETS_MATRIX = [
  {
    label: 'bracketsMatrix1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mfenced separators="|">
      <mtable>
         <mtr>
            <mtd>
               <mi>a</mi>
            </mtd>
            <mtd>
               <mi>b</mi>
            </mtd>
         </mtr>
         <mtr>
            <mtd>
               <mi>c</mi>
            </mtd>
            <mtd>
               <mi>d</mi>
            </mtd>
         </mtr>
      </mtable>
   </mfenced>
</math>
    `,
    latex: '\\left(\\begin{array}{cc}a& b\\\\ c& d\\end{array}\\right)',
  },
  {
    label: 'bracketsMatrix2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mfenced open="[" close="]" separators="|">
      <mtable>
         <mtr>
            <mtd>
               <mi>a</mi>
            </mtd>
            <mtd>
               <mi>b</mi>
            </mtd>
         </mtr>
         <mtr>
            <mtd>
               <mi>c</mi>
            </mtd>
            <mtd>
               <mi>d</mi>
            </mtd>
         </mtr>
      </mtable>
   </mfenced>
</math>
    `,
    latex: '\\left[\\begin{array}{cc}a& b\\\\ c& d\\end{array}\\right]',
  },
  {
    label: 'bracketsMatrix3',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mfenced open="|" close="|" separators="|">
      <mtable>
         <mtr>
            <mtd>
               <mi>a</mi>
            </mtd>
            <mtd>
               <mi>b</mi>
            </mtd>
         </mtr>
         <mtr>
            <mtd>
               <mi>c</mi>
            </mtd>
            <mtd>
               <mi>d</mi>
            </mtd>
         </mtr>
      </mtable>
   </mfenced>
</math>
    `,
    latex: '\\left|\\begin{array}{cc}a& b\\\\ c& d\\end{array}\\right|',
  },
  {
    label: 'bracketsMatrix4',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mfenced open="||" close="||" separators="|">
      <mtable>
         <mtr>
            <mtd>
               <mi>a</mi>
            </mtd>
            <mtd>
               <mi>b</mi>
            </mtd>
         </mtr>
         <mtr>
            <mtd>
               <mi>c</mi>
            </mtd>
            <mtd>
               <mi>d</mi>
            </mtd>
         </mtr>
      </mtable>
   </mfenced>
</math>
    `,
    latex: '‖\\begin{array}{cc}a& b\\\\ c& d\\end{array}‖',
  },
];

const SPARSE_MATRIX = [
  {
    label: 'sparseMatrix1',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mfenced separators="|">
      <mtable>
         <mtr>
            <mtd>
               <mi>a</mi>
            </mtd>
            <mtd>
               <mo>⋯</mo>
            </mtd>
            <mtd>
               <mi>b</mi>
            </mtd>
         </mtr>
         <mtr>
            <mtd>
               <mo>⋮</mo>
            </mtd>
            <mtd>
               <mo>⋱</mo>
            </mtd>
            <mtd>
               <mo>⋮</mo>
            </mtd>
         </mtr>
         <mtr>
            <mtd>
               <mi>c</mi>
            </mtd>
            <mtd>
               <mo>⋯</mo>
            </mtd>
            <mtd>
               <mi>d</mi>
            </mtd>
         </mtr>
      </mtable>
   </mfenced>
</math>
    `,
    latex:
      '\\left(\\begin{array}{ccc}a& \\cdots & b\\\\ ⋮& \\ddots & ⋮\\ \\newline c& \\cdots & d\\end{array}\\right)',
    repX: 2,
    repY: 2,
  },
  {
    label: 'sparseMatrix2',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
   <mfenced open="[" close="]" separators="|">
      <mtable>
         <mtr>
            <mtd>
               <mi>a</mi>
            </mtd>
            <mtd>
               <mo>⋯</mo>
            </mtd>
            <mtd>
               <mi>b</mi>
            </mtd>
         </mtr>
         <mtr>
            <mtd>
               <mo>⋮</mo>
            </mtd>
            <mtd>
               <mo>⋱</mo>
            </mtd>
            <mtd>
               <mo>⋮</mo>
            </mtd>
         </mtr>
         <mtr>
            <mtd>
               <mi>c</mi>
            </mtd>
            <mtd>
               <mo>⋯</mo>
            </mtd>
            <mtd>
               <mi>d</mi>
            </mtd>
         </mtr>
      </mtable>
   </mfenced>
</math>
    `,
    latex:
      '\\left[\\begin{array}{ccc}a& \\cdots & b\\\\ ⋮& \\ddots & ⋮\\ \\newline c& \\cdots & d\\end{array}\\right]',
    repX: 2,
    repY: 2,
  },
];

const MATRIX = {
  section: 'Matrix',
  label: 'EQUATION_STRUCTURE_MATRIX',
  elements: [
    ...EMPTY_MATRIX,
    ...DOTS_MATRIX,
    ...IDENTITY_MATRIX,
    ...BRACKETS_MATRIX,
    ...SPARSE_MATRIX,
  ],
};
//#endregion
//#endregion

//#region Symbols Consts
//#region Math
const COMMON_BINARY = [
  { code: '±', label: 'Mathematical Shape', mathml: '±', latex: '±' },
  { code: '÷', label: 'Mathematical Shape', mathml: '÷', latex: '÷' },
  { code: '×', label: 'Mathematical Shape', mathml: '×', latex: '×' },
  { code: '∓', label: 'Mathematical Shape', mathml: '∓', latex: '∓' },
  { code: '∝∝', label: 'Mathematical Shape', mathml: '∝', latex: '∝' },
  { code: '∕', label: 'Mathematical Shape', mathml: '∕', latex: '∕' },
  { code: '*', label: 'Mathematical Shape', mathml: '*', latex: '*' },
  { code: '∘', label: 'Mathematical Shape', mathml: '∘', latex: '∘' },
  { code: '∙', label: 'Mathematical Shape', mathml: '∙', latex: '∙' },
  { code: '⋅', label: 'Mathematical Shape', mathml: '⋅', latex: '⋅' },
  { code: '∩', label: 'Mathematical Shape', mathml: '∩', latex: '∩' },
  { code: '∪', label: 'Mathematical Shape', mathml: '∪', latex: '∪' },
  { code: '⊎', label: 'Mathematical Shape', mathml: '⊎', latex: '⊎' },
  { code: '⊓', label: 'Mathematical Shape', mathml: '⊓', latex: '⊓' },
  { code: '⊔', label: 'Mathematical Shape', mathml: '⊔', latex: '⊔' },
  { code: '∧', label: 'Mathematical Shape', mathml: '∧', latex: '∧' },
  { code: '∨', label: 'Mathematical Shape', mathml: '∨', latex: '∨' },
];

const COMMON_RELATIONAL = [
  { code: '=', label: 'Common Relational operator', mathml: '=', latex: '=' },
  { code: '≠', label: 'Common Relational operator', mathml: '≠', latex: '≠' },
  { code: '<', label: 'Common Relational operator', mathml: '<', latex: '<' },
  { code: '>', label: 'Common Relational operator', mathml: '>', latex: '>' },
  { code: '≤', label: 'Common Relational operator', mathml: '≤', latex: '≤' },
  { code: '≥', label: 'Common Relational operator', mathml: '≥', latex: '≥' },
  { code: '≮', label: 'Common Relational operator', mathml: '≮', latex: '≮' },
  { code: '≰', label: 'Common Relational operator', mathml: '≰', latex: '≰' },
  { code: '≯', label: 'Common Relational operator', mathml: '≯', latex: '≯' },
  { code: '≱', label: 'Common Relational operator', mathml: '≱', latex: '≱' },
  { code: '≡', label: 'Common Relational operator', mathml: '≡', latex: '≡' },
  { code: '∼', label: 'Common Relational operator', mathml: '∼', latex: '∼' },
  { code: '≃', label: 'Common Relational operator', mathml: '≃', latex: '≃' },
  { code: '≈', label: 'Common Relational operator', mathml: '≈', latex: '≈' },
  { code: '≅', label: 'Common Relational operator', mathml: '≅', latex: '≅' },
  { code: '≢≢', label: 'Common Relational operator', mathml: '≢', latex: '≢' },
  { code: '≄', label: 'Common Relational operator', mathml: '≄', latex: '≄' },
  { code: '≉≉', label: 'Common Relational operator', mathml: '≉', latex: '≉' },
  { code: '≇', label: 'Common Relational operator', mathml: '≇', latex: '≇' },
  { code: '∝', label: 'Common Relational operator', mathml: '∝', latex: '∝' },
  { code: '≪', label: 'Common Relational operator', mathml: '≪', latex: '≪' },
  { code: '≫', label: 'Common Relational operator', mathml: '≫', latex: '≫' },
  { code: '∈', label: 'Common Relational operator', mathml: '∈', latex: '∈' },
  { code: '∋', label: 'Common Relational operator', mathml: '∋', latex: '∋' },
  { code: '∉', label: 'Common Relational operator', mathml: '∉', latex: '∉' },
  { code: '⊂', label: 'Common Relational operator', mathml: '⊂', latex: '⊂' },
  { code: '⊃', label: 'Common Relational operator', mathml: '⊃', latex: '⊃' },
  { code: '⊆⊆', label: 'Common Relational operator', mathml: '⊆', latex: '⊆' },
  { code: '⊇⊇', label: 'Common Relational operator', mathml: '⊇', latex: '⊇' },
  { code: '≺', label: 'Common Relational operator', mathml: '≺', latex: '≺' },
  { code: '≻', label: 'Common Relational operator', mathml: '≻', latex: '≻' },
  { code: '≼', label: 'Common Relational operator', mathml: '≼', latex: '≼' },
  { code: '≽', label: 'Common Relational operator', mathml: '≽', latex: '≽' },
  { code: '⊏⊏', label: 'Common Relational operator', mathml: '⊏', latex: '⊏' },
  { code: '⊐⊐', label: 'Common Relational operator', mathml: '⊐', latex: '⊐' },
  { code: '⊑', label: 'Common Relational operator', mathml: '⊑', latex: '⊑' },
  { code: '⊒', label: 'Common Relational operator', mathml: '⊒', latex: '⊒' },
  { code: '∥', label: 'Common Relational operator', mathml: '∥', latex: '∥' },
  { code: '⊥', label: 'Common Relational operator', mathml: '⊥', latex: '⊥' },
  { code: '⊢', label: 'Common Relational operator', mathml: '⊢', latex: '⊢' },
  { code: '⊣', label: 'Common Relational operator', mathml: '⊣', latex: '⊣' },
  { code: '⋈', label: 'Common Relational operator', mathml: '⋈', latex: '⋈' },
  { code: '≍', label: 'Common Relational operator', mathml: '≍', latex: '≍' },
];

const NARY_OPERATORS = [
  {
    code: 'sum',
    label: 'N-ary operator sum',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mo>∑</mo>
    </math>`,
    latex: '\\sum',
  },
  {
    code: 'product',
    label: 'N-ary operator product',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mo>∏</mo>
    </math>`,
    latex: '\\prod',
  },
  {
    code: 'coproduct',
    label: 'N-ary operator coproduct',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mo>∐</mo>
    </math>`,
    latex: '\\coprod',
  },
  {
    code: 'integral',
    label: 'N-ary operator integral',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mo>∫</mo>
    </math>`,
    latex: '\\int',
  },
  {
    code: 'double integral',
    label: 'N-ary operator double integral',
    mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mo>∬</mo>
      </math>`,
    latex: '\\iint',
  },
  {
    code: 'triple integral',
    label: 'N-ary operator triple integral',
    mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mo>∭</mo>
      </math>`,
    latex: '\\iiint',
  },
  {
    code: 'contour integral',
    label: 'N-ary operator contour integral',
    mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mo>∮</mo>
      </math>`,
    latex: '\\oint',
  },
  {
    code: 'contour double integral',
    label: 'N-ary operator contour double integral',
    mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mo>∯</mo>
      </math>`,
    latex: '∯',
  },
  {
    code: 'contour triple integral',
    label: 'N-ary operator contour triple integral',
    mathml: `
      <math xmlns="http://www.w3.org/1998/Math/MathML">
        <mo>∰</mo>
      </math>`,
    latex: '∰',
  },
  {
    code: 'large cup',
    label: 'N-ary operator large cup',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mo largeop="true">∪</mo>
    </math>
    `,
    latex: '\\bigcup',
  },
  {
    code: 'large cap',
    label: 'N-ary operator large cap',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mo largeop="true">∩</mo>
    </math>
    `,
    latex: '\\bigcap',
  },
  {
    code: 'large vee',
    label: 'N-ary operator large vee',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mo>⋁</mo>
    </math>
    `,
    latex: '\\bigvee',
  },
  {
    code: 'large wedge',
    label: 'N-ary operator large wedge',
    mathml: `
    <math xmlns="http://www.w3.org/1998/Math/MathML">
      <mo>⋀</mo>
    </math>
    `,
    latex: '\\bigwedge',
  },
  { code: '⨀', label: 'N-ary operator', mathml: '⨀', latex: '⨀' },
  { code: '⨂', label: 'N-ary operator', mathml: '⨂', latex: '⨂' },
  { code: '⨁', label: 'N-ary operator', mathml: '⨁', latex: '⨁' },
  { code: '⨄', label: 'N-ary operator', mathml: '⨄', latex: '⨄' },
  { code: '⨃', label: 'N-ary operator', mathml: '⨃', latex: '⨃' },
];

const ADVANCED_BINARY = [
  { code: '∔', label: 'Advanced Binary operator', mathml: '∔', latex: '∔' },
  { code: '∸', label: 'Advanced Binary operator', mathml: '∸', latex: '∸' },
  { code: '∖', label: 'Advanced Binary operator', mathml: '∖', latex: '∖' },
  { code: '⋒', label: 'Advanced Binary operator', mathml: '⋒', latex: '⋒' },
  { code: '⋓', label: 'Advanced Binary operator', mathml: '⋓', latex: '⋓' },
  { code: '⊠', label: 'Advanced Binary operator', mathml: '⊠', latex: '⊠' },
  { code: '⊡', label: 'Advanced Binary operator', mathml: '⊡', latex: '⊡' },
  { code: '⊞', label: 'Advanced Binary operator', mathml: '⊞', latex: '⊞' },
  { code: '⋇', label: 'Advanced Binary operator', mathml: '⋇', latex: '⋇' },
  { code: '⋉', label: 'Advanced Binary operator', mathml: '⋉', latex: '⋉' },
  { code: '⋊', label: 'Advanced Binary operator', mathml: '⋊', latex: '⋊' },
  { code: '⋋', label: 'Advanced Binary operator', mathml: '⋋', latex: '⋋' },
  { code: '⋌', label: 'Advanced Binary operator', mathml: '⋌', latex: '⋌' },
  { code: '⋏', label: 'Advanced Binary operator', mathml: '⋏', latex: '⋏' },
  { code: '⋎', label: 'Advanced Binary operator', mathml: '⋎', latex: '⋎' },
  { code: '⊝', label: 'Advanced Binary operator', mathml: '⊝', latex: '⊝' },
  { code: '⊺', label: 'Advanced Binary operator', mathml: '⊺', latex: '⊺' },
  { code: '⊕', label: 'Advanced Binary operator', mathml: '⊕', latex: '⊕' },
  { code: '⊖', label: 'Advanced Binary operator', mathml: '⊖', latex: '⊖' },
  { code: '⊗', label: 'Advanced Binary operator', mathml: '⊗', latex: '⊗' },
  { code: '⊘', label: 'Advanced Binary operator', mathml: '⊘', latex: '⊘' },
  { code: '⊙', label: 'Advanced Binary operator', mathml: '⊙', latex: '⊙' },
  { code: '⊛', label: 'Advanced Binary operator', mathml: '⊛', latex: '⊛' },
  { code: '⊚', label: 'Advanced Binary operator', mathml: '⊚', latex: '⊚' },
  { code: '†', label: 'Advanced Binary operator', mathml: '†', latex: '†' },
  { code: '‡', label: 'Advanced Binary operator', mathml: '‡', latex: '‡' },
  { code: '⋆', label: 'Advanced Binary operator', mathml: '⋆', latex: '⋆' },
  { code: '⋄', label: 'Advanced Binary operator', mathml: '⋄', latex: '⋄' },
  { code: '≀', label: 'Advanced Binary operator', mathml: '≀', latex: '≀' },
  { code: '△', label: 'Advanced Binary operator', mathml: '△', latex: '△' },
  { code: '⋀', label: 'Advanced Binary operator', mathml: '⋀', latex: '⋀' },
  { code: '⨀⨀', label: 'Advanced Binary operator', mathml: '⨀', latex: '⨀' },
  { code: '⨂⨂', label: 'Advanced Binary operator', mathml: '⨂', latex: '⨂' },
  { code: '⨁⨁', label: 'Advanced Binary operator', mathml: '⨁', latex: '⨁' },
  { code: '⨅', label: 'Advanced Binary operator', mathml: '⨅', latex: '⨅' },
  { code: '⨆', label: 'Advanced Binary operator', mathml: '⨆', latex: '⨆' },
  { code: '⨄⨄', label: 'Advanced Binary operator', mathml: '⨄', latex: '⨄' },
  { code: '⨃⨃', label: 'Advanced Binary operator', mathml: '⨃', latex: '⨃' },
];

const ADVANCED_RELATIONAL = [
  { code: '∴∴', label: 'Advanced Relational operator', mathml: '∴', latex: '∴' },
  { code: '∵∵', label: 'Advanced Relational operator', mathml: '∵', latex: '∵' },
  { code: '⋘', label: 'Advanced Relational operator', mathml: '⋘', latex: '⋘' },
  { code: '⋙', label: 'Advanced Relational operator', mathml: '⋙', latex: '⋙' },
  { code: '≦', label: 'Advanced Relational operator', mathml: '≦', latex: '≦' },
  { code: '≧', label: 'Advanced Relational operator', mathml: '≧', latex: '≧' },
  { code: '≲', label: 'Advanced Relational operator', mathml: '≲', latex: '≲' },
  { code: '≳', label: 'Advanced Relational operator', mathml: '≳', latex: '≳' },
  { code: '⋖', label: 'Advanced Relational operator', mathml: '⋖', latex: '⋖' },
  { code: '⋗', label: 'Advanced Relational operator', mathml: '⋗', latex: '⋗' },
  { code: '≶', label: 'Advanced Relational operator', mathml: '≶', latex: '≶' },
  { code: '⋚', label: 'Advanced Relational operator', mathml: '⋚', latex: '⋚' },
  { code: '≷', label: 'Advanced Relational operator', mathml: '≷', latex: '≷' },
  { code: '⋛', label: 'Advanced Relational operator', mathml: '⋛', latex: '⋛' },
  { code: '≑', label: 'Advanced Relational operator', mathml: '≑', latex: '≑' },
  { code: '≒', label: 'Advanced Relational operator', mathml: '≒', latex: '≒' },
  { code: '≓', label: 'Advanced Relational operator', mathml: '≓', latex: '≓' },
  { code: '∽', label: 'Advanced Relational operator', mathml: '∽', latex: '∽' },
  { code: '≊', label: 'Advanced Relational operator', mathml: '≊', latex: '≊' },
  { code: '⋍', label: 'Advanced Relational operator', mathml: '⋍', latex: '⋍' },
  { code: '≼≼', label: 'Advanced Relational operator', mathml: '≼', latex: '≼' },
  { code: '≽≽', label: 'Advanced Relational operator', mathml: '≽', latex: '≽' },
  { code: '⋞', label: 'Advanced Relational operator', mathml: '⋞', latex: '⋞' },
  { code: '⋟', label: 'Advanced Relational operator', mathml: '⋟', latex: '⋟' },
  { code: '≾', label: 'Advanced Relational operator', mathml: '≾', latex: '≾' },
  { code: '≿', label: 'Advanced Relational operator', mathml: '≿', latex: '≿' },
  { code: '⋜', label: 'Advanced Relational operator', mathml: '⋜', latex: '⋜' },
  { code: '⋝', label: 'Advanced Relational operator', mathml: '⋝', latex: '⋝' },
  { code: '⊆', label: 'Advanced Relational operator', mathml: '⊆', latex: '⊆' },
  { code: '⊇', label: 'Advanced Relational operator', mathml: '⊇', latex: '⊇' },
  { code: '⊲', label: 'Advanced Relational operator', mathml: '⊲', latex: '⊲' },
  { code: '⊳', label: 'Advanced Relational operator', mathml: '⊳', latex: '⊳' },
  { code: '⊴', label: 'Advanced Relational operator', mathml: '⊴', latex: '⊴' },
  { code: '⊵', label: 'Advanced Relational operator', mathml: '⊵', latex: '⊵' },
  { code: '⊨', label: 'Advanced Relational operator', mathml: '⊨', latex: '⊨' },
  { code: '⋐', label: 'Advanced Relational operator', mathml: '⋐', latex: '⋐' },
  { code: '⋑', label: 'Advanced Relational operator', mathml: '⋑', latex: '⋑' },
  { code: '⊏', label: 'Advanced Relational operator', mathml: '⊏', latex: '⊏' },
  { code: '⊐', label: 'Advanced Relational operator', mathml: '⊐', latex: '⊐' },
  { code: '⊩', label: 'Advanced Relational operator', mathml: '⊩', latex: '⊩' },
  { code: '⊪', label: 'Advanced Relational operator', mathml: '⊪', latex: '⊪' },
  { code: '≖', label: 'Advanced Relational operator', mathml: '≖', latex: '≖' },
  { code: '≗', label: 'Advanced Relational operator', mathml: '≗', latex: '≗' },
  { code: '≜', label: 'Advanced Relational operator', mathml: '≜', latex: '≜' },
  { code: '≏', label: 'Advanced Relational operator', mathml: '≏', latex: '≏' },
  { code: '≎', label: 'Advanced Relational operator', mathml: '≎', latex: '≎' },
  { code: '∝∝∝', label: 'Advanced Relational operator', mathml: '∝', latex: '∝' },
  { code: '≬', label: 'Advanced Relational operator', mathml: '≬', latex: '≬' },
  { code: '⋔', label: 'Advanced Relational operator', mathml: '⋔', latex: '⋔' },
  { code: '≐', label: 'Advanced Relational operator', mathml: '≐', latex: '≐' },
  { code: '⋈⋈', label: 'Advanced Relational operator', mathml: '⋈', latex: '⋈' },
];

const NEGATED_RELATIONAL = [
  { code: '≠≠', label: 'Negated Relational operator', mathml: '≠', latex: '≠' },
  { code: '≮≮', label: 'Negated Relational operator', mathml: '≮', latex: '≮' },
  { code: '≯≯', label: 'Negated Relational operator', mathml: '≯', latex: '≯' },
  { code: '≰≰', label: 'Negated Relational operator', mathml: '≰', latex: '≰' },
  { code: '≱≱', label: 'Negated Relational operator', mathml: '≱', latex: '≱' },
  { code: '≢', label: 'Negated Relational operator', mathml: '≢', latex: '≢' },
  { code: '≁', label: 'Negated Relational operator', mathml: '≁', latex: '≁' },
  { code: '≄≄', label: 'Negated Relational operator', mathml: '≄', latex: '≄' },
  { code: '≉', label: 'Negated Relational operator', mathml: '≉', latex: '≉' },
  { code: '≇≇', label: 'Negated Relational operator', mathml: '≇', latex: '≇' },
  { code: '≭', label: 'Negated Relational operator', mathml: '≭', latex: '≭' },
  { code: '≨', label: 'Negated Relational operator', mathml: '≨', latex: '≨' },
  { code: '≩', label: 'Negated Relational operator', mathml: '≩', latex: '≩' },
  { code: '⊀', label: 'Negated Relational operator', mathml: '⊀', latex: '⊀' },
  { code: '⊁', label: 'Negated Relational operator', mathml: '⊁', latex: '⊁' },
  { code: '⋠', label: 'Negated Relational operator', mathml: '⋠', latex: '⋠' },
  { code: '⋡', label: 'Negated Relational operator', mathml: '⋡', latex: '⋡' },
  { code: '∉∉', label: 'Negated Relational operator', mathml: '∉', latex: '∉' },
  { code: '∌', label: 'Negated Relational operator', mathml: '∌', latex: '∌' },
  { code: '⊄', label: 'Negated Relational operator', mathml: '⊄', latex: '⊄' },
  { code: '⊅', label: 'Negated Relational operator', mathml: '⊅', latex: '⊅' },
  { code: '⊈', label: 'Negated Relational operator', mathml: '⊈', latex: '⊈' },
  { code: '⊉', label: 'Negated Relational operator', mathml: '⊉', latex: '⊉' },
  { code: '⊊', label: 'Negated Relational operator', mathml: '⊊', latex: '⊊' },
  { code: '⊋', label: 'Negated Relational operator', mathml: '⊋', latex: '⊋' },
  { code: '⋢', label: 'Negated Relational operator', mathml: '⋢', latex: '⋢' },
  { code: '⋣', label: 'Negated Relational operator', mathml: '⋣', latex: '⋣' },
  { code: '⋦', label: 'Negated Relational operator', mathml: '⋦', latex: '⋦' },
  { code: '⋧', label: 'Negated Relational operator', mathml: '⋧', latex: '⋧' },
  { code: '⋨', label: 'Negated Relational operator', mathml: '⋨', latex: '⋨' },
  { code: '⋩', label: 'Negated Relational operator', mathml: '⋩', latex: '⋩' },
  { code: '⋪', label: 'Negated Relational operator', mathml: '⋪', latex: '⋪' },
  { code: '⋫', label: 'Negated Relational operator', mathml: '⋫', latex: '⋫' },
  { code: '⋬', label: 'Negated Relational operator', mathml: '⋬', latex: '⋬' },
  { code: '⋭', label: 'Negated Relational operator', mathml: '⋭', latex: '⋭' },
  { code: '∤', label: 'Negated Relational operator', mathml: '∤', latex: '∤' },
  { code: '∦', label: 'Negated Relational operator', mathml: '∦', latex: '∦' },
  { code: '⊬', label: 'Negated Relational operator', mathml: '⊬', latex: '⊬' },
  { code: '⊭', label: 'Negated Relational operator', mathml: '⊭', latex: '⊭' },
  { code: '⊮', label: 'Negated Relational operator', mathml: '⊮', latex: '⊮' },
  { code: '⊯', label: 'Negated Relational operator', mathml: '⊯', latex: '⊯' },
  { code: '∄', label: 'Negated Relational operator', mathml: '∄', latex: '∄' },
];
//#endregion

//#region Shapes
const MATHEMATICAL_SHAPES = [
  { code: '∟', label: 'Mathematical Shape', mathml: '∟', latex: '∟' },
  { code: '∠', label: 'Mathematical Shape', mathml: '∠', latex: '∠' },
  { code: '∡', label: 'Mathematical Shape', mathml: '∡', latex: '∡' },
  { code: '∢', label: 'Mathematical Shape', mathml: '∢', latex: '∢' },
  { code: '⊾', label: 'Mathematical Shape', mathml: '⊾', latex: '⊾' },
  { code: '⊿', label: 'Mathematical Shape', mathml: '⊿', latex: '⊿' },
  { code: '⋕', label: 'Mathematical Shape', mathml: '⋕', latex: '⋕' },
  { code: '⊥', label: 'Mathematical Shape', mathml: '⊥', latex: '⊥' },
  { code: '∤', label: 'Mathematical Shape', mathml: '∤', latex: '∤' },
  { code: '∥', label: 'Mathematical Shape', mathml: '∥', latex: '∥' },
  { code: '∦', label: 'Mathematical Shape', mathml: '∦', latex: '∦' },
  { code: '∶', label: 'Mathematical Shape', mathml: '∶', latex: '∶' },
  { code: '∷', label: 'Mathematical Shape', mathml: '∷', latex: '∷' },
  { code: '∴', label: 'Mathematical Shape', mathml: '∴', latex: '∴' },
  { code: '∵', label: 'Mathematical Shape', mathml: '∵', latex: '∵' },
  { code: '∎', label: 'Mathematical Shape', mathml: '∎', latex: '∎' },
];
//#endregion

//#region Greek
const GREEK = [
  { code: '\u{391}', label: 'Greek Capital Letter Alpha', latex: 'A' },
  { code: '\u{392}', label: 'Greek Capital Letter Beta', latex: 'B' },
  { code: '\u{393}', label: 'Greek Capital Letter Gamma', latex: '\\Gamma' },
  { code: '\u{394}', label: 'Greek Capital Letter Delta', latex: '\\Delta' },
  { code: '\u{395}', label: 'Greek Capital Letter Épsilon', latex: 'E' },
  { code: '\u{396}', label: 'Greek Capital Letter Zeta', latex: 'Z' },
  { code: '\u{397}', label: 'Greek Capital Letter Eta', latex: 'H' },
  { code: '\u{398}', label: 'Greek Capital Letter Theta', latex: '\\Theta' },
  { code: '\u{399}', label: 'Greek Capital Letter Iota', latex: 'I' },
  { code: '\u{39A}', label: 'Greek Capital Letter kappa', latex: 'K' },
  { code: '\u{39B}', label: 'Greek Capital Letter Lambda', latex: '\\Lambda' },
  { code: '\u{39C}', label: 'Greek Capital Letter Mu', latex: 'M' },
  { code: '\u{39D}', label: 'Greek Capital Letter Nu', latex: 'N' },
  { code: '\u{39E}', label: 'Greek Capital Letter Csi', latex: '\\Xi' },
  { code: '\u{39F}', label: 'Greek Capital Letter Ómicron', latex: 'O' },
  { code: '\u{3A0}', label: 'Greek Capital Letter Pi', latex: '\\Pi' },
  { code: '\u{3A1}', label: 'Greek Capital Letter Rho', latex: 'P' },
  { code: '\u{3A3}', label: 'Greek Capital Letter Sigma', latex: '\\Sigma' },
  { code: '\u{3A4}', label: 'Greek Capital Letter Tau', latex: 'T' },
  { code: '\u{3A5}', label: 'Greek Capital Letter Úpsilon', latex: '\\Upsilon' },
  { code: '\u{3A6}', label: 'Greek Capital Letter Phi', latex: '\\Phi' },
  { code: '\u{3A7}', label: 'Greek Capital Letter Chi', latex: 'X' },
  { code: '\u{3A8}', label: 'Greek Capital Letter Psi', latex: '\\Psi' },
  { code: '\u{3A9}', label: 'Greek Capital Letter Omega', latex: '\\Omega' },

  { code: '\u{3B1}', label: 'Greek Capital Letter Alpha', latex: '\\alpha' },
  { code: '\u{3B2}', label: 'Greek Capital Letter Beta', latex: '\\beta' },
  { code: '\u{3B3}', label: 'Greek Capital Letter Gamma', latex: '\\gamma' },
  { code: '\u{3B4}', label: 'Greek Capital Letter Delta', latex: '\\delta' },
  { code: '\u{3B5}', label: 'Greek Capital Letter Épsilon', latex: '\\epsilon' },
  { code: '\u{3B6}', label: 'Greek Capital Letter Zeta', latex: '\\zeta' },
  { code: '\u{3B7}', label: 'Greek Capital Letter Eta', latex: '\\eta' },
  { code: '\u{3B8}', label: 'Greek Capital Letter Theta', latex: '\\theta' },
  { code: '\u{3B9}', label: 'Greek Capital Letter Iota', latex: '\\iota' },
  { code: '\u{3BA}', label: 'Greek Capital Letter kappa', latex: '\\kappa' },
  { code: '\u{3BB}', label: 'Greek Capital Letter Lambda', latex: '\\lambda' },
  { code: '\u{3BC}', label: 'Greek Capital Letter Mu', latex: '\\mu' },
  { code: '\u{3BD}', label: 'Greek Capital Letter Nu', latex: '\\mu' },
  { code: '\u{3BE}', label: 'Greek Capital Letter Csi', latex: '\\xi' },
  { code: '\u{3BF}', label: 'Greek Capital Letter Ómicron', latex: 'o' },
  { code: '\u{3C0}', label: 'Greek Capital Letter Pi', latex: '\\pi' },
  { code: '\u{3C1}', label: 'Greek Capital Letter Rho', latex: '\\rho' },
  { code: '\u{3C3}', label: 'Greek Capital Letter Sigma', latex: '\\sigma' },
  { code: '\u{3C4}', label: 'Greek Capital Letter Tau', latex: '\\tau' },
  { code: '\u{3C5}', label: 'Greek Capital Letter Úpsilon', latex: '\\upsilon' },
  { code: '\u{3C6}', label: 'Greek Capital Letter Phi', latex: '\\phi' },
  { code: '\u{3C7}', label: 'Greek Capital Letter Chi', latex: '\\chi' },
  { code: '\u{3C8}', label: 'Greek Capital Letter Psi', latex: '\\psi' },
  { code: '\u{3C9}', label: 'Greek Capital Letter Omega', latex: '\\omega' },
];
//#endregion

//#region Typeface
const TYPE_SCRIPT = [
  { code: '𝒜', label: 'Mathematical Script Capital A', mathml: '𝒜', latex: '𝒜' },
  { code: 'ℬ', label: 'Mathematical Script Capital B', mathml: 'ℬ', latex: 'ℬ' },
  { code: '𝒞', label: 'Mathematical Script Capital C', mathml: '𝒞', latex: '𝒞' },
  { code: '𝒟', label: 'Mathematical Script Capital D', mathml: '𝒟', latex: '𝒟' },
  { code: 'ℰ', label: 'Mathematical Script Capital E', mathml: 'ℰ', latex: 'ℰ' },
  { code: 'ℱ', label: 'Mathematical Script Capital F', mathml: 'ℱ', latex: 'ℱ' },
  { code: '𝒢', label: 'Mathematical Script Capital G', mathml: '𝒢', latex: '𝒢' },
  { code: 'ℋ', label: 'Mathematical Script Capital H', mathml: 'ℋ', latex: 'ℋ' },
  { code: 'ℐ', label: 'Mathematical Script Capital I', mathml: 'ℐ', latex: 'ℐ' },
  { code: '𝒥', label: 'Mathematical Script Capital J', mathml: '𝒥', latex: '𝒥' },
  { code: '𝒦', label: 'Mathematical Script Capital K', mathml: '𝒦', latex: '𝒦' },
  { code: 'ℒ', label: 'Mathematical Script Capital L', mathml: 'ℒ', latex: 'ℒ' },
  { code: 'ℳ', label: 'Mathematical Script Capital M', mathml: 'ℳ', latex: 'ℳ' },
  { code: '𝒩', label: 'Mathematical Script Capital N', mathml: '𝒩', latex: '𝒩' },
  { code: '𝒪', label: 'Mathematical Script Capital O', mathml: '𝒪', latex: '𝒪' },
  { code: '𝒫', label: 'Mathematical Script Capital P', mathml: '𝒫', latex: '𝒫' },
  { code: '𝒬', label: 'Mathematical Script Capital Q', mathml: '𝒬', latex: '𝒬' },
  { code: 'ℛ', label: 'Mathematical Script Capital R', mathml: 'ℛ', latex: 'ℛ' },
  { code: '𝒮', label: 'Mathematical Script Capital S', mathml: '𝒮', latex: '𝒮' },
  { code: '𝒯', label: 'Mathematical Script Capital T', mathml: '𝒯', latex: '𝒯' },
  { code: '𝒰', label: 'Mathematical Script Capital U', mathml: '𝒰', latex: '𝒰' },
  { code: '𝒱', label: 'Mathematical Script Capital V', mathml: '𝒱', latex: '𝒱' },
  { code: '𝒲', label: 'Mathematical Script Capital W', mathml: '𝒲', latex: '𝒲' },
  { code: '𝒳', label: 'Mathematical Script Capital X', mathml: '𝒳', latex: '𝒳' },
  { code: '𝒴', label: 'Mathematical Script Capital Y', mathml: '𝒴', latex: '𝒴' },
  { code: '𝒵', label: 'Mathematical Script Capital Z', mathml: '𝒵', latex: '𝒵' },
  { code: '𝒶', label: 'Mathematical Script Small A', mathml: '𝒶', latex: '𝒶' },
  { code: '𝒷', label: 'Mathematical Script Small B', mathml: '𝒷', latex: '𝒷' },
  { code: '𝒸', label: 'Mathematical Script Small C', mathml: '𝒸', latex: '𝒸' },
  { code: '𝒹', label: 'Mathematical Script Small D', mathml: '𝒹', latex: '𝒹' },
  { code: 'ℯ', label: 'Mathematical Script Small E', mathml: 'ℯ', latex: 'ℯ' },
  { code: '𝒻', label: 'Mathematical Script Small F', mathml: '𝒻', latex: '𝒻' },
  { code: 'ℊ', label: 'Mathematical Script Small G', mathml: 'ℊ', latex: 'ℊ' },
  { code: '𝒽', label: 'Mathematical Script Small H', mathml: '𝒽', latex: '𝒽' },
  { code: '𝒾', label: 'Mathematical Script Small I', mathml: '𝒾', latex: '𝒾' },
  { code: '𝒿', label: 'Mathematical Script Small J', mathml: '𝒿', latex: '𝒿' },
  { code: '𝓀', label: 'Mathematical Script Small K', mathml: '𝓀', latex: '𝓀' },
  { code: '𝓁', label: 'Mathematical Script Small L', mathml: '𝓁', latex: '𝓁' },
  { code: '𝓂', label: 'Mathematical Script Small M', mathml: '𝓂', latex: '𝓂' },
  { code: '𝓃', label: 'Mathematical Script Small N', mathml: '𝓃', latex: '𝓃' },
  { code: 'ℴ', label: 'Mathematical Script Small O', mathml: 'ℴ', latex: 'ℴ' },
  { code: '𝓅', label: 'Mathematical Script Small P', mathml: '𝓅', latex: '𝓅' },
  { code: '𝓆', label: 'Mathematical Script Small Q', mathml: '𝓆', latex: '𝓆' },
  { code: '𝓇', label: 'Mathematical Script Small R', mathml: '𝓇', latex: '𝓇' },
  { code: '𝓈', label: 'Mathematical Script Small S', mathml: '𝓈', latex: '𝓈' },
  { code: '𝓉', label: 'Mathematical Script Small T', mathml: '𝓉', latex: '𝓉' },
  { code: '𝓊', label: 'Mathematical Script Small U', mathml: '𝓊', latex: '𝓊' },
  { code: '𝓋', label: 'Mathematical Script Small V', mathml: '𝓋', latex: '𝓋' },
  { code: '𝓌', label: 'Mathematical Script Small W', mathml: '𝓌', latex: '𝓌' },
  { code: '𝓍', label: 'Mathematical Script Small X', mathml: '𝓍', latex: '𝓍' },
  { code: '𝓎', label: 'Mathematical Script Small Y', mathml: '𝓎', latex: '𝓎' },
  { code: '𝓏', label: 'Mathematical Script Small Z', mathml: '𝓏', latex: '𝓏' },
];

const TYPE_FRAKTUR = [
  { code: '\u{1D504}', label: 'Mathematical Fraktur Capital A', latex: '𝕬' },
  { code: '\u{1D505}', label: 'Mathematical Fraktur Capital B', latex: '𝕭' },
  // { code: '\u{1D506}', label: 'Mathematical Fraktur Capital C' },
  { code: '\u{1D507}', label: 'Mathematical Fraktur Capital D', latex: '𝕯' },
  { code: '\u{1D508}', label: 'Mathematical Fraktur Capital E', latex: '𝕰' },
  { code: '\u{1D509}', label: 'Mathematical Fraktur Capital F', latex: '𝕱' },
  { code: '\u{1D50A}', label: 'Mathematical Fraktur Capital G', latex: '𝕲' },
  // { code: '\u{1D50B}', label: 'Mathematical Fraktur Capital H' },
  // { code: '\u{1D50C}', label: 'Mathematical Fraktur Capital I' },
  { code: '\u{1D50D}', label: 'Mathematical Fraktur Capital J', latex: '𝕵' },
  { code: '\u{1D50E}', label: 'Mathematical Fraktur Capital K', latex: '𝕶' },
  { code: '\u{1D50F}', label: 'Mathematical Fraktur Capital L', latex: '𝕷' },
  { code: '\u{1D510}', label: 'Mathematical Fraktur Capital M', latex: '𝕸' },
  { code: '\u{1D511}', label: 'Mathematical Fraktur Capital N', latex: '𝕹' },
  { code: '\u{1D512}', label: 'Mathematical Fraktur Capital O', latex: '𝕺' },
  { code: '\u{1D513}', label: 'Mathematical Fraktur Capital P', latex: '𝕻' },
  { code: '\u{1D514}', label: 'Mathematical Fraktur Capital Q', latex: '𝕼' },
  // { code: '\u{1D515}', label: 'Mathematical Fraktur Capital R' },
  { code: '\u{1D516}', label: 'Mathematical Fraktur Capital S', latex: '𝕾' },
  { code: '\u{1D517}', label: 'Mathematical Fraktur Capital T', latex: '𝕿' },
  { code: '\u{1D518}', label: 'Mathematical Fraktur Capital U', latex: '𝖀' },
  { code: '\u{1D519}', label: 'Mathematical Fraktur Capital V', latex: '𝖁' },
  { code: '\u{1D51A}', label: 'Mathematical Fraktur Capital W', latex: '𝖂' },
  { code: '\u{1D51B}', label: 'Mathematical Fraktur Capital X', latex: '𝖃' },
  { code: '\u{1D51C}', label: 'Mathematical Fraktur Capital Y', latex: '𝖄' },
  // { code: '\u{1D51D}', label: 'Mathematical Fraktur Capital Z' },
  { code: '\u{1D51E}', label: 'Mathematical Fraktur Small A', latex: '𝖆' },
  { code: '\u{1D51F}', label: 'Mathematical Fraktur Small B', latex: '𝖇' },
  { code: '\u{1D520}', label: 'Mathematical Fraktur Small C', latex: '𝖈' },
  { code: '\u{1D521}', label: 'Mathematical Fraktur Small D', latex: '𝖉' },
  { code: '\u{1D522}', label: 'Mathematical Fraktur Small E', latex: '𝖊' },
  { code: '\u{1D523}', label: 'Mathematical Fraktur Small F', latex: '𝖋' },
  { code: '\u{1D524}', label: 'Mathematical Fraktur Small G', latex: '𝖌' },
  { code: '\u{1D525}', label: 'Mathematical Fraktur Small H', latex: '𝖍' },
  { code: '\u{1D526}', label: 'Mathematical Fraktur Small I', latex: '𝖎' },
  { code: '\u{1D527}', label: 'Mathematical Fraktur Small J', latex: '𝖏' },
  { code: '\u{1D528}', label: 'Mathematical Fraktur Small K', latex: '𝖐' },
  { code: '\u{1D529}', label: 'Mathematical Fraktur Small L', latex: '𝖑' },
  { code: '\u{1D52A}', label: 'Mathematical Fraktur Small M', latex: '𝖒' },
  { code: '\u{1D52B}', label: 'Mathematical Fraktur Small N', latex: '𝖓' },
  { code: '\u{1D52C}', label: 'Mathematical Fraktur Small O', latex: '𝖔' },
  { code: '\u{1D52D}', label: 'Mathematical Fraktur Small P', latex: '𝖕' },
  { code: '\u{1D52E}', label: 'Mathematical Fraktur Small Q', latex: '𝖖' },
  { code: '\u{1D52F}', label: 'Mathematical Fraktur Small R', latex: '𝖗' },
  { code: '\u{1D530}', label: 'Mathematical Fraktur Small S', latex: '𝖘' },
  { code: '\u{1D531}', label: 'Mathematical Fraktur Small T', latex: '𝖙' },
  { code: '\u{1D532}', label: 'Mathematical Fraktur Small U', latex: '𝖚' },
  { code: '\u{1D533}', label: 'Mathematical Fraktur Small V', latex: '𝖛' },
  { code: '\u{1D534}', label: 'Mathematical Fraktur Small W', latex: '𝖜' },
  { code: '\u{1D535}', label: 'Mathematical Fraktur Small X', latex: '𝖝' },
  { code: '\u{1D536}', label: 'Mathematical Fraktur Small Y', latex: '𝖞' },
  { code: '\u{1D537}', label: 'Mathematical Fraktur Small Z', latex: '𝖟' },
];
//#endregion

//#region Arrows
const ARROWS = [
  { code: '\u{2190}', label: 'leftwards arrow', latex: '\\leftarrow' },
  { code: '\u{2191}', label: 'upwards arrow', latex: '\\uparrow' },
  { code: '\u{2192}', label: 'rightwards arrow', latex: '\\rightarrow' },
  { code: '\u{2193}', label: 'downwards arrow', latex: '\\downarrow' },
  { code: '\u{2194}', label: 'left right arrow', latex: '\\leftrightarrow' },
  { code: '\u{2195}', label: 'up down arrow', latex: '\\updownarrow' },
  { code: '\u{2196}', label: 'northest arrow', latex: '\\nearrow' },
  { code: '\u{2197}', label: 'southest arrow', latex: '\\searrow' },
  { code: '\u{2198}', label: 'southwest arrow', latex: '\\swarrow' },
  { code: '\u{2199}', label: 'northwest arrow', latex: '\\nwarrow' },
  { code: '\u{21D0}', label: 'leftwards double arrow', latex: '\\Leftarrow' },
  { code: '\u{21D1}', label: 'upwards double arrow', latex: '\\Uparrow' },
  { code: '\u{21D2}', label: 'rightwards double arrow', latex: '\\Rightarrow' },
  { code: '\u{21D3}', label: 'downwards double arrow', latex: '\\Downarrow' },
  { code: '\u{21D4}', label: 'left right double arrow', latex: '\\Leftrightarrow' },
  { code: '\u{21D5}', label: 'up down double arrow', latex: '\\Updownarrow' },
  { code: '\u{21D6}', label: '', latex: '' },
  { code: '\u{21D7}', label: '', latex: '' },
  { code: '\u{21D8}', label: '', latex: '' },
  { code: '\u{21D9}', label: '', latex: '' },
];
//#endregion
//#endregion

const toExport = {
  structures: [
    FRACTIONS,
    RADICALS,
    INTEGRALS,
    SCRIPTS,
    LARGE_OPERATORS,
    BRACKET,
    FUNCTION,
    SYMBOL,
    LOGLIMIT,
    OPERATOR,
    MATRIX,
  ] as Structure[],
  symbols: [
    {
      section: 'Math',
      label: 'EQUATION_SYMBOL_MATH',
      elements: [
        ...COMMON_BINARY,
        ...COMMON_RELATIONAL,
        ...NARY_OPERATORS,
        ...ADVANCED_BINARY,
        ...ADVANCED_RELATIONAL,
        ...NEGATED_RELATIONAL,
      ],
    },
    {
      section: 'Shapes',
      label: 'EQUATION_SYMBOL_SHAPE',
      elements: [...MATHEMATICAL_SHAPES],
    },
    { section: 'Greek', label: 'EQUATION_SYMBOL_GREEK', elements: [...GREEK] },
    {
      section: 'Typeface',
      label: 'EQUATION_SYMBOL_TYPEFACE',
      elements: [...TYPE_SCRIPT, ...TYPE_FRAKTUR],
    },
    { section: 'Arrows', label: 'EQUATION_SYMBOL_ARROW', elements: [...ARROWS] },
  ] as Symbol[],
};

export type Type = keyof typeof toExport;

export default toExport;
