import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { WidgetViewModel } from './WidgetViewModel';
import { TableOptionsWidgetView } from '../../Views';

export class TableOptionsWidgetModel extends WidgetViewModel<'tableOptions'> {
  constructor(
    Visualizer: Editor.Visualizer.State,
    refView: Editor.Visualizer.WidgetData['tableOptions']['view'],
    props: Editor.Visualizer.WidgetData['tableOptions']['props'],
  ) {
    super(Visualizer, refView, props);

    this.container.dataset.type = 'tableOptions';
  }

  render() {
    // set widget position
    const offsets = EditorDOMUtils.getOffsets(this.refView);
    if (offsets) {
      this.container.style.position = 'absolute';
      this.container.style.top = `${offsets.top}px`;

      if (this.Visualizer.getLayoutType?.() === 'PAGE') {
        this.container.style.left = `calc(50% - ${offsets.width / 2}px)`;
      } else {
        this.container.style.left = `${offsets.left}px`;
      }

      this.container.style.width = `0px`;
      this.container.style.height = `${offsets.height}px`;
    }

    let polygons: Editor.Elements.Table.CellPolygonArea[] = [];
    const selectedCells = this.refView.getSelectedCells();
    if (selectedCells.length > 1) {
      polygons = this.refView.getCellsPolygonAreas(selectedCells);
    }

    this.renderReactComponent(
      <TableOptionsWidgetView
        columnWidths={this.refView.getComputedColumnWidths()}
        rowHeights={this.refView.getComputedRowHeights()}
        selection={{
          polygon: polygons || [],
          columns: this.props.selection?.columns || [],
          rows: this.props.selection?.rows || [],
          fullySelected: {
            row: this.props.selection?.fullySelected?.row || [],
            column: this.props.selection?.fullySelected?.column || [],
          },
        }}
        offsets={offsets}
        insert={this.refView.shortcutInsert}
        remove={this.refView.shortcutRemove}
        select={this.refView.shortcutSelect}
        resize={this.refView.resize}
      />,
    );
  }
}
