/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Cmapps, NodeNumberingData, Numbering, Structure, Template } from '../../models';
import BaseController from '../BaseController';
import { MergedListStylesData } from '../Styles';
import List from './List';
import ListsManager, { MergedListsData } from './ListsManager';
import MultilevelStructure from './MultilevelStructure';
import NumberingApplier from './NumberingApplier';

type NumberingControllerArgType = Pick<
  Editor.Data.State,
  'transport' | 'styles' | 'models' | 'context'
>;

export class NumberingController extends BaseController {
  private template?: Template;
  private structure?: Structure;
  numbering?: Numbering;
  private cmapps?: Cmapps;
  applier: NumberingApplier;
  lists: ListsManager;

  constructor(Data: NumberingControllerArgType) {
    super(Data);
    this.lists = new ListsManager();
    this.applier = new NumberingApplier(this.Data.styles?.listStyles, this.lists);
    this.handleListStyleUpdate = this.handleListStyleUpdate.bind(this);
    this.handleListUpdateStyle = this.handleListUpdateStyle.bind(this);
    this.handleLoadListStyles = this.handleLoadListStyles.bind(this);
  }

  async start(documentId: string) {
    this.template = this.Data.models?.get(this.Data?.models.TYPE_NAME.TEMPLATE, documentId);
    this.structure = this.Data.models?.get(
      this.Data?.models.TYPE_NAME.STRUCTURE,
      `DS${documentId}`,
    );
    this.numbering = this.Data.models?.get(this.Data?.models.TYPE_NAME.NUMBERING, `N${documentId}`);
    this.cmapps = this.Data.models?.get(this.Data?.models.TYPE_NAME.CMAPPS, `CMI${documentId}`);
    this.applier = new NumberingApplier(this.Data.styles?.listStyles, this.lists);
    this.lists
      .bindToTemplate(this.template)
      .bindToStructure(this.structure)
      .bindToNumbering(this.numbering);
    this.lists
      .on('LOAD_LISTS', (data: MergedListsData) => {})
      .on('LOAD_LIST', (id: string, data: List) => {})
      .on('LIST_REMOVE', (id: string) => {})
      .on('LIST_UPDATE', () => {})
      .on('LIST_UPDATE_ELEMENTS', () => {})
      .on('LIST_UPDATE_STYLE', this.handleListUpdateStyle);
    this.Data.styles?.listStyles.on('LIST_STYLE_UPDATE', this.handleListStyleUpdate);
    this.Data.styles?.listStyles.on('LOAD_LIST_STYLES', this.handleLoadListStyles);
    this.lists.start();
  }

  //istanbul ignore next
  private handleListUpdateStyle(data: any) {
    this.Data.styles?.documentStyles.listStyleUpdated(data.listId);
  }

  //istanbul ignore next
  private handleListStyleUpdate(listStyleId: string) {
    this.lists.updatedListStyle(listStyleId);
  }

  //istanbul ignore next
  private handleLoadListStyles(listStyles: MergedListStylesData) {
    const styleKeys = Object.keys(listStyles);

    for (let i = 0; i < styleKeys.length; i++) {
      this.lists.updatedListStyle(styleKeys[i]);
    }
  }

  //istanbul ignore next
  private getNumberingInfoForBlock(nodeId: string): NodeNumberingData | null {
    const numbering = this.numbering?.getBlockInfo(nodeId);
    const props = this.structure?.getBlockProperties(nodeId).lst;

    if (props) {
      const propsLevel = +props.lLv;
      if (numbering && numbering.listId === props.lId && numbering.level === propsLevel) {
        return numbering;
      } else {
        return {
          level: propsLevel,
          listId: props.lId,
          number: 0,
          allLevels: {},
        };
      }
    } else if (numbering) {
      return numbering;
    }

    return null;
  }

  isBlockInOutlineList(nodeId: string) {
    const nodeInfo = this.getNumberingInfoForBlock(nodeId);
    return nodeInfo
      ? this.Data.styles?.documentStyles.getListsWithStyles().includes(nodeInfo.listId)
      : false;
  }

  isFirstNode(nodeId: string) {
    const nodeData = this.getNumberingInfoForBlock(nodeId);
    if (nodeData) {
      const levels = Object.keys(nodeData.allLevels);
      for (let index = 0; index < levels.length; index++) {
        const levelIndex = levels[index];
        const levelDef = this.applier.levelDefinition(nodeData.listId, levelIndex);
        if (nodeData.allLevels[+levelIndex] !== levelDef?.st) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  listsWithStyle(styleId: string) {
    const result = [];
    const lists = Object.keys(this.lists.lists());
    for (let index = 0; index < lists.length; index++) {
      const list = this.lists.list(lists[index]);
      if (list?.style === styleId) {
        result.push(lists[index]);
      }
    }
    return result;
  }

  getStyleIdForList(listId: string) {
    return this.lists.list(listId)?.style ?? null;
  }

  isBulletList(listId: string) {
    const style = this.getStyleIdForList(listId);
    if (style) {
      return this.Data.styles?.listStyles?.style(style)?.isBulletList();
    }
    return null;
  }

  getDefinitionForList(listId: string) {
    const style = this.getStyleIdForList(listId);
    if (style) {
      return this.Data.styles?.listStyles.style(style);
    }
    return null;
  }

  listExists(listId: string) {
    return !!this.lists.list(listId);
  }

  getListElementNumbering(nodeId: string) {
    // TODO :
    const listInfo = this.getNumberingInfoForBlock(nodeId);
    return this.applier.applyStyle(listInfo);
  }

  removeBlocksFromList(nodeIds: string[]) {
    const removeOp = [];
    const removeBlockOp = [];
    let listId;
    const listTables: any = {};
    const blkPropsData = this.structure?.get(['blkProps']);
    const listsData = this.structure?.get(['lists']);
    for (let index = 0; index < nodeIds.length; index++) {
      const nodeId = nodeIds[index];
      const blkProps = blkPropsData[nodeId];
      const listInfo = this.getNumberingInfoForBlock(nodeId);
      if (listInfo && listInfo.listId != null) {
        listId = listInfo.listId;
        if (!listTables[listId]) {
          listTables[listId] = {
            n: [],
            rNO: [],
            rBO: [],
          };
        }
        const listPosition = listsData[listId]?.n.indexOf(nodeId);
        if (listPosition != null && listPosition >= 0) {
          listTables[listId].n.push(nodeId);
          listTables[listId].rNO.push({
            p: ['lists', listId, 'n', listPosition - index],
            ld: nodeId,
          });
          if (blkProps && blkProps.lst) {
            listTables[listId].rBO.push({
              od: blkProps.lst,
              p: ['blkProps', nodeId, 'lst'],
            });
          }
        } else if (blkProps && blkProps.lst) {
          listTables[listId].rBO.push({
            od: blkProps.lst,
            oi: { lId: null, lLv: null },
            p: ['blkProps', nodeId, 'lst'],
          });
        } else {
          listTables[listId].rBO.push({
            oi: { lId: null, lLv: null },
            p: ['blkProps', nodeId, 'lst'],
          });
        }
      } else if (listInfo == null && blkProps?.lst?.lId === null) {
        removeBlockOp.push({
          od: blkProps.lst,
          p: ['blkProps', nodeId, 'lst'],
        });
      }
    }
    const lists = Object.keys(listTables);
    for (let index = 0; index < lists.length; index++) {
      const listKey = lists[index];
      if (listTables[listKey] && listTables[listKey].n.length === listsData[listKey]?.n.length) {
        const listStyles = this.Data.styles?.documentStyles.getStylesForList(listKey) || [];
        if (listStyles.length === 0) {
          removeOp.push({
            p: ['lists', listKey],
            od: listsData[listKey],
          });
        }
      } else {
        removeOp.push(...listTables[listKey].rNO);
      }
      removeBlockOp.push(...listTables[listKey].rBO);
    }
    if (removeOp.length > 0 || removeBlockOp.length > 0) {
      return this.structure?.apply([...removeOp, ...removeBlockOp]);
    }
  }

  addBlocksToList(
    actionContext: any,
    nodeIds: string[],
    listId: string,
    listLevel: string,
    previousSibling?: string,
  ) {
    const op = [];
    const listsData = this.structure?.get(['lists']);
    const blkPropsData = this.structure?.get(['blkProps']);
    const listTables: any = {};
    if (listsData[listId]) {
      let position = listsData[listId].n.length;
      if (previousSibling) {
        const prevIndex = listsData[listId].n.indexOf(previousSibling);
        position = prevIndex < 0 ? listsData[listId].n.length : prevIndex + 1;
      }
      for (let index = 0; index < nodeIds.length; index++) {
        const nodeId = nodeIds[index];
        const blkProps = blkPropsData[nodeId] || {};
        if (blkProps && blkProps.lst && blkProps.lst.lId != null) {
          // belongs to a list
          if (blkProps.lst.lId && blkProps.lst.lId === listId) {
            // eslint-disable-next-line no-continue
            continue;
          } else {
            const oldListId = blkProps.lst.lId;
            if (!listTables[oldListId]) {
              listTables[oldListId] = {
                n: [],
                rNO: [],
                rBO: [],
              };
            }
            op.push({
              od: oldListId,
              oi: listId,
              p: ['blkProps', nodeId, 'lst', 'lId'],
            });
            op.push({
              p: ['lists', listId, 'n', position + index],
              li: nodeId,
            });
            const listPosition = listsData[oldListId].n.indexOf(nodeId);
            if (listPosition >= 0) {
              listTables[oldListId].n.push(nodeId);
              listTables[oldListId].rNO.push({
                p: ['lists', oldListId, 'n', listPosition],
                ld: nodeId,
              });
            }
          }
        } else {
          op.push({
            od: blkProps,
            oi: {
              ...blkProps,
              lst: {
                lLv: `${listLevel}`,
                lId: listId,
              },
            },
            p: ['blkProps', nodeId],
          });
          op.push({
            p: ['lists', listId, 'n', position + index],
            li: nodeId,
          });
        }
      }
      const lists = Object.keys(listTables);
      for (let index = 0; index < lists.length; index++) {
        const listKey = lists[index];
        if (listTables[listKey] && listTables[listKey].n.length === listsData[listKey].n.length) {
          op.push({
            p: ['lists', listKey],
            od: listsData[listKey],
          });
        } else {
          op.push(...listTables[listKey].rNO);
        }
      }
      if (op.length > 0) {
        actionContext.jsonChanges = true;
        return this.structure?.apply(op);
      }
    }
  }

  async outdentNodes(listElements: any[], removeFromList = false) {
    const removeOp = [];
    const removeBlockOp = [];
    const updateOps = [];
    const blkPropsData = this.structure?.get(['blkProps']);
    const listsData = this.structure?.get(['lists']);
    for (let index = 0; index < listElements.length; index++) {
      const listElement = listElements[index];

      // clear node inline indentation when indenting list
      const nodeModel = this.Data.models?.get(
        this.Data?.models.TYPE_NAME.NODE,
        listElement.blockId,
      );
      nodeModel?.clearLeftIndentation(listElement.id);

      const blkProps = blkPropsData[listElement.id];
      if (blkProps.lst && blkProps.lst.lId) {
        const level = parseInt(blkProps.lst.lLv, 10);
        if (Number.isNaN(level)) {
          throw new Error('List element does not have a numerical list level');
        }
        if (level > 0) {
          updateOps.push({
            od: blkProps.lst.lLv,
            oi: `${level - 1}`,
            p: ['blkProps', listElement.id, 'lst', 'lLv'],
          });
        } else if (removeFromList) {
          const listId = blkProps.lst.lId;
          const listPosition = listsData[listId].n.indexOf(listElement.id);
          if (listPosition >= 0) {
            if (listsData[blkProps.lst.lId].n.length === 1) {
              removeOp.push({
                p: ['lists', blkProps.lst.lId],
                od: listsData[blkProps.lst.lId],
              });
            } else {
              removeOp.push({
                p: ['lists', blkProps.lst.lId, 'n', listPosition],
                ld: listElement.id,
              });
            }
            removeBlockOp.push({
              od: blkProps.lst,
              p: ['blkProps', listElement.id, 'lst'],
            });
          }
        }
      }
    }
    if (updateOps.length > 0 || removeOp.length > 0 || removeBlockOp.length > 0) {
      await this.structure?.apply([...updateOps, ...removeOp, ...removeBlockOp]);
    }
  }

  async indentNodes(listElements: any[]) {
    const updateOps = [];
    const blkPropsData = this.structure?.get(['blkProps']);
    for (let index = 0; index < listElements.length; index++) {
      const listElement = listElements[index];

      // clear node inline indentation when indenting list

      const nodeModel = this.Data.models?.get(
        this.Data?.models.TYPE_NAME.NODE,
        listElement.blockId,
      );
      nodeModel?.clearLeftIndentation(listElement.id);

      const blkProps = blkPropsData[listElement.id];
      if (blkProps.lst && blkProps.lst.lId) {
        const level = parseInt(blkProps.lst.lLv, 10);
        if (Number.isNaN(level)) {
          throw new Error('List element does not have a numerical list level');
        }
        if (level < 8) {
          updateOps.push({
            od: blkProps.lst.lLv,
            oi: `${level + 1}`,
            p: ['blkProps', listElement.id, 'lst', 'lLv'],
          });
        }
      }
    }
    if (updateOps.length > 0) {
      await this.structure?.apply([...updateOps]);
    }
  }

  setListStyle(actionContext: any, listId: string, styleId: string) {
    this.structure?.set(['lists', listId, 'style'], styleId);
  }

  listStartOverride(listId?: string) {
    if (!listId) {
      return null;
    }
    const listData = this.structure?.get(['lists', listId]);
    if (listData.so) {
      const ops = [];
      const levelKeys = Object.keys(listData.so);
      for (let index = 0; index < levelKeys.length; index++) {
        const level = levelKeys[index];
        // TODO: review next condition
        // eslint-disable-next-line
        if (listData.so[level][0].sto == undefined) {
          ops.push({
            p: ['lists', listId, 'so', level, 0],
            li: {
              sto: 1,
            },
          });
        }
      }
      return this.structure?.apply(ops);
    }
    return this.structure?.set(['lists', listId, 'so'], {
      0: [
        {
          sto: 1,
        },
      ],
      1: [
        {
          sto: 1,
        },
      ],
      2: [
        {
          sto: 1,
        },
      ],
      3: [
        {
          sto: 1,
        },
      ],
      4: [
        {
          sto: 1,
        },
      ],
      5: [
        {
          sto: 1,
        },
      ],
      6: [
        {
          sto: 1,
        },
      ],
      7: [
        {
          sto: 1,
        },
      ],
      8: [
        {
          sto: 1,
        },
      ],
    });
  }

  //istanbul ignore next
  private findPreviousNumberedList(nodeId: string) {
    let previousNumberedList = null;
    const multilevelStructure = new MultilevelStructure(this.structure, this.cmapps);
    multilevelStructure.start();
    const multilevelIndex = multilevelStructure.index;
    const position = multilevelIndex.indexOf(nodeId);
    let foundList;
    let element;
    for (let index = position - 1; index >= 0; index--) {
      element = multilevelIndex[index];
      if (!this.isBlockInOutlineList(element)) {
        foundList = this.getNumberingInfoForBlock(element);
        if (foundList) {
          if (!this.isBulletList(foundList.listId)) {
            previousNumberedList = foundList.listId;
            break;
          }
        }
      }
    }
    return previousNumberedList;
  }

  continueListNumbering(actionContext: any, nodeId: string) {
    if (this.isBlockInOutlineList(nodeId)) {
      return;
    }
    const blockNumbering = this.getNumberingInfoForBlock(nodeId);
    if (blockNumbering) {
      const listsData = this.lists.lists();
      let previousNumberedList = this.findPreviousNumberedList(nodeId);
      if (previousNumberedList) {
        const oldListData = listsData[blockNumbering.listId];
        const blockListPosition = oldListData?.nodes?.indexOf(nodeId);
        if (blockListPosition === 0) {
          this.addBlocksToList(actionContext, oldListData?.nodes || [], previousNumberedList, '0');
        }
      }
    }
  }

  async restartListNumbering(actionContext: any, nodeId: string) {
    const blockNumbering = this.getNumberingInfoForBlock(nodeId);
    if (blockNumbering) {
      const listData = this.lists.lists();
      const position = this.lists.list(blockNumbering.listId)?.nodes?.indexOf(nodeId);
      if (position === 0) {
        if (this.isFirstNode(nodeId)) {
          return;
        }
        this.listStartOverride(blockNumbering.listId);
      } else {
        const ops = [];
        const newListId = List.generateListId();
        const newList = this.lists.list(blockNumbering.listId)?.duplicate();
        const listTables: any = {};
        const blockProps = this.structure?.blockProperties;
        const nodeIds = this.lists.list(blockNumbering.listId)?.nodes?.slice(position) || [];
        let oldListId;
        for (let index = 0; index < nodeIds.length; index++) {
          const _nodeId = nodeIds[index];
          const blkProps = blockProps[_nodeId] || {};
          if (blkProps && blkProps.lst) {
            // belongs to a list
            oldListId = blkProps.lst.lId;
            ops.push({
              od: oldListId,
              oi: newListId,
              p: ['blkProps', _nodeId, 'lst', 'lId'],
            });
            if (!listTables[oldListId]) {
              listTables[oldListId] = {
                n: [],
                rNO: [],
                rBO: [],
                nCount: 0,
              };
            }
            const listPosition = (
              this.lists.list(blockNumbering.listId)?.documentList.n || []
            ).indexOf(_nodeId);
            if (listPosition >= 0) {
              listTables[oldListId].n.push(_nodeId);
              listTables[oldListId].rNO.push({
                p: ['lists', oldListId, 'n', listPosition],
                ld: _nodeId,
              });
            }
            listTables[oldListId].nCount += 1;
          } else {
            const numbering = this.numbering?.generalNumbering[_nodeId];
            if (numbering) {
              ops.push({
                od: blkProps,
                oi: {
                  ...blkProps,
                  lst: {
                    lLv: `${numbering.level}`,
                    lId: newListId,
                  },
                },
                p: ['blkProps', _nodeId],
              });
              listTables[numbering.listId].nCount += 1;
            } else {
              ops.push({
                od: blkProps,
                oi: {
                  ...blkProps,
                  lst: {
                    lLv: `${0}`,
                    lId: newListId,
                  },
                },
                p: ['blkProps', _nodeId],
              });
            }
          }
          newList.n.push(_nodeId);
        }
        const lists = Object.keys(listTables);
        for (let index = 0; index < lists.length; index++) {
          const listKey = lists[index];
          if (
            listTables[listKey] &&
            listTables[listKey].nCount === (this.lists.list(listKey)?.nodes?.length || 0)
          ) {
            ops.push({
              p: ['lists', listKey],
              od: listData[listKey].data,
            });
          } else {
            ops.push(...listTables[listKey].rNO.reverse());
          }
        }
        ops.unshift({
          p: ['lists', newListId],
          oi: newList,
        });
        actionContext.jsonChanges = true;
        if (ops.length > 0) {
          return this.structure?.apply(ops);
        }
      }
    }
  }

  createNewList(style: string, id?: string, start: number = 1) {
    const listId = id || List.generateListId();
    this.structure?.set(['lists', listId], {
      style,
      start,
      n: [],
      so: {
        0: [{ sto: 1 }],
        1: [{ sto: 1 }],
        2: [{ sto: 1 }],
        3: [{ sto: 1 }],
        4: [{ sto: 1 }],
        5: [{ sto: 1 }],
        6: [{ sto: 1 }],
        7: [{ sto: 1 }],
        8: [{ sto: 1 }],
      },
    });
    return listId;
  }

  isListElement(nodeId: string) {
    const listInfo = this.getNumberingInfoForBlock(nodeId);
    return listInfo && listInfo.listId;
  }

  isBulletListElement(nodeId: string) {
    const listInfo = this.getNumberingInfoForBlock(nodeId);
    if (listInfo?.listId) {
      return this.isBulletList(listInfo.listId);
    }
    return false;
  }

  getListIdFromBlock(nodeId: string) {
    const listInfo = this.getNumberingInfoForBlock(nodeId);
    return listInfo?.listId;
  }

  getListLevelFromBlock(nodeId: string) {
    const listInfo = this.getNumberingInfoForBlock(nodeId);
    return listInfo && listInfo.listId !== undefined ? listInfo.level : null;
  }

  getLevelDefinitionFromBlock(nodeId: string) {
    const listInfo = this.getNumberingInfoForBlock(nodeId);
    if (listInfo) {
      const def = this.getDefinitionForList(listInfo.listId);
      if (def) {
        return def.ldef[listInfo.level];
      }
    }
    return null;
  }

  updateNumbering() {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent('UPDATE:NUMBERING', {}, (response) => {
        if (response.success) {
          resolve(response.tempReference);
        } else {
          reject(response.error);
        }
      });
    });
  }

  isListFromTemplate(listId: string) {
    return this.lists.isListFromTemplate(listId);
  }

  isListInDocument(listId: string) {
    return this.lists.isListInDocument(listId);
  }

  removeNodeFromOutline(nodeId: string) {
    return this.structure?.removeFromOutline(nodeId);
  }

  getListType(nodeId: string): 'simple' | 'outline' | null {
    const nodeBlockProps = this.structure?.getBlockProperties(nodeId);
    const listInfo = this.getNumberingInfoForBlock(nodeId);

    if (nodeBlockProps?.lst?.lId) {
      return 'simple';
    } else if (listInfo?.listId) {
      return 'outline';
    }

    return null;
  }

  async includeNodeInOutline(nodeId: string) {
    const nodeBlockProps = this.structure?.getBlockProperties(nodeId);

    if (nodeBlockProps.lst) {
      if (nodeBlockProps?.lst?.lId != null) {
        await this.removeBlocksFromList([nodeId]);
      } else {
        await this.structure?.apply([
          {
            od: nodeBlockProps.lst,
            p: [this.structure?.KEYS.BLOCK_PROPERTIES, nodeId, 'lst'],
          },
        ]);
      }
    }
  }

  hasBlockADocumentStyleWithList(blockId: string, elementId: string) {
    const block = this.Data.nodes?.getNodeModelById(blockId);
    const elementStyle = block?.getStyleForElementId(elementId);
    return this.Data.styles?.documentStyles.hasStyleAList(elementStyle);
  }

  stop(): void {}

  destroy(): void {
    this.lists.destroy();
  }
}
