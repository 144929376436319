import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { navigateToSettings } from 'router/history';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { selectRoleById } from '../../RolesApi';

import styles from './ActionsCell.module.scss';

type ActionsCellProps = {
  roleId: Role['id'];
};

const ActionsCell = ({ roleId }: ActionsCellProps) => {
  const dispatch = useDispatch();

  const role = useSelector((state) => selectRoleById(state, roleId));

  const handleSettingsClick = () => {
    navigateToSettings('role', roleId);
  };

  const handleDeleteClick = () => {
    if (!role) {
      return;
    }

    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'settings.tenant.deleteRoleTitle',
          titleValues: { name: role.name },
          message: 'settings.tenant.deleteRoleMessage',
          messageValues: { name: role.name },
          confirmButtonTextId: 'settings.tenant.deleteRoleConfirm',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'deletedRole',
          actionValue: { roleId },
          headerType: 'information',
          cancelButtonShow: true,
        },
      }),
    );
  };

  return (
    <div className={styles.root}>
      <Button
        size="small"
        style={{ marginRight: '2rem' }}
        onClick={handleSettingsClick}
        testId={`${roleId}-settings-button`}
      >
        <FormattedMessage id="global.settings" />
      </Button>
      <Button
        variant="danger"
        testId={`${roleId}-delete-button`}
        size="small"
        onClick={handleDeleteClick}
      >
        <FormattedMessage id="global.delete" />
      </Button>
    </div>
  );
};

export default ActionsCell;
