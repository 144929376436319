import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';

import EditorManager from 'Editor/services/EditorManager';
import { useDispatch, useSelector } from '_common/hooks';

import { closeModal, updateModal } from '_common/modals/ModalsSlice';

import { CustomColorPickerProps } from '_common/components/CustomColorPicker/CustomColorPicker';
import { CustomColorPicker } from '_common/components';
import { hexToRgb } from 'assets/colors';

type ActionProps = {
  updateTableColor: {};
  toggleSelectionStyle: { style: string };
  applyParagraphBackgroundColor: {};
};

type Action = keyof ActionProps;

const MODAL = 'ColorPickerModal';

const ColorPickerModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const modal = useSelector((state) => state.modals[MODAL]);
  const attribute = useSelector((state) => state.modals[MODAL].attribute);
  const updateColorCallback = useSelector((state) => state.modals[MODAL].updateColorCallback);
  const actionCode = useSelector((state) => state.modals[MODAL].action);
  const actionValues = useSelector((state) => state.modals[MODAL].actionValues);

  const [color, setColor] = useState<CustomColorPickerProps['hex']>();
  const handleBackgroundColorChanged: CustomColorPickerProps['onChange'] = (color) => {
    setColor(color.hex);
  };

  const ACTIONS: { [key in Action]: (props: ActionProps[key]) => void } = {
    updateTableColor: () => {
      updateColorCallback?.({ property: attribute, value: color });
      dispatch(updateModal({ modal: MODAL, data: { data: { name: attribute, value: color } } }));
    },
    toggleSelectionStyle: ({ style }) => {
      if (color) {
        const customColor = hexToRgb(color);

        if (customColor) {
          EditorManager.getInstance().toggleSelectionStyle(
            style as Editor.Styles.Styles,
            `rgb(${customColor[0]},${customColor[1]},${customColor[2]})`,
          );
        }
      }
    },
    applyParagraphBackgroundColor: () => {
      if (color) {
        const customColor = hexToRgb(color);

        if (customColor) {
          EditorManager.getInstance().applyParagraphBackgroundColor(
            `rgb(${customColor[0]},${customColor[1]},${customColor[2]})`,
          );
        }
      }
    },
  };
  const typedActionCode = actionCode in ACTIONS ? (actionCode as Action) : undefined;

  const close = () => {
    dispatch(closeModal(MODAL));
  };

  const applyStyles = () => {
    if (typedActionCode && ACTIONS[typedActionCode]) {
      ACTIONS[typedActionCode](actionValues);
    }

    close();
  };

  return (
    <Modal open={!!isOpen} width="44rem" onClose={close} testId="color-picker">
      <Modal.Header onClose={close} size="small">
        <FormattedMessage id="CREATE_CUSTOM_COLOR" />
      </Modal.Header>
      <Modal.Body>
        <CustomColorPicker {...modal} color={color} onChange={handleBackgroundColorChanged} />
      </Modal.Body>
      <Modal.Footer size="small">
        <Button size="small" onClick={close} testId="color-picker-modal-close-button">
          <FormattedMessage id="global.close" />
        </Button>
        <Button variant="primary" size="small" onClick={applyStyles} testId="color-picker-modal-submit-button">
          <FormattedMessage id="APPLY_COLOR" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ColorPickerModal;
