import { BaseModifier } from './BaseModifier';
import { ExpandCharacterModifier, ExpandLineModifier, ExpandWordModifier } from './ExpandModifiers';
import { MoveCharacterModifier, MoveLineModifier, MoveWordModifier } from './MoveModifiers';

export class ModifiersFactory {
  private static getMoveModifier(
    Data: Editor.Data.API,
    granularity: Editor.Selection.ModifierGranularity,
    direction: Editor.Selection.ModifierDirection,
    incrementOffset?: number,
  ): BaseModifier {
    switch (granularity) {
      case 'character':
        return new MoveCharacterModifier(Data, direction, incrementOffset);
      case 'word':
        return new MoveWordModifier(Data, direction);
      case 'line':
        return new MoveLineModifier(Data, direction);
    }
  }

  private static getExpandModifier(
    Data: Editor.Data.API,
    granularity: Editor.Selection.ModifierGranularity,
    direction: Editor.Selection.ModifierDirection,
    incrementOffset?: number,
  ): BaseModifier {
    switch (granularity) {
      case 'character':
        return new ExpandCharacterModifier(Data, direction, incrementOffset);
      case 'word':
        return new ExpandWordModifier(Data, direction);
      case 'line':
        return new ExpandLineModifier(Data, direction);
    }
  }

  static getModifier(
    Data: Editor.Data.API,
    type: Editor.Selection.ModifierType,
    granularity: Editor.Selection.ModifierGranularity,
    direction: Editor.Selection.ModifierDirection,
    incrementOffset?: number,
  ): BaseModifier {
    switch (type) {
      case 'move':
        return this.getMoveModifier(Data, granularity, direction, incrementOffset);
      case 'expand':
        return this.getExpandModifier(Data, granularity, direction, incrementOffset);
    }
  }
}
