import { useState, forwardRef, Ref, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { stringToRichText } from 'utils';

import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { useDispatch, useSelector } from '_common/hooks';
import { DEFAULT_CARD_STYLES_PANEL, setEditingAnnotation } from 'PDF/redux/PDFAnnotationsSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import usePDFCollaborators from 'PDF/hooks/usePDFCollaborators';

import { UserPresentation } from '_common/components';
import {
  AssigneePresentation,
  TaskOptions,
  TaskStatus,
  ViewRichTextEditor,
  WatchToggle,
  ReplyToggle,
  ReplyList,
  MinifiedCard,
} from '_common/suite/components/Card';
import { Card } from '_common/suite/components';

import type { LikeToggleProps } from '_common/suite/components/Card/LikeToggle/LikeToggle';

export type ViewTaskCardProps = {
  task: PDF.Annotation.Task;
  sidebar: boolean;
  index?: number;
  order?: number;
  testId: string;
};

const ViewTaskCardContent = forwardRef(
  ({ task, sidebar, index, order, testId }: ViewTaskCardProps, ref: Ref<HTMLDivElement>) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const pdfManager = usePDFContext();
    const collaborators = usePDFCollaborators();
    const currentUserId = useSelector((state) => state.auth.userId);
    const isSelected = useSelector((state) => state.pdf.annotations.selected === task.id);
    const isListMode = useSelector((state) => state.pdf.annotations.tasksListMode);

    const {
      canComment,
      canWatchAnnotation,
      canEditAnnotation,
      canDeleteAnnotation,
      canChangeAnnotationStatus,
      canEditAnnotationReply,
      canDeleteAnnotationReply,
    } = usePDFPermissions();

    const [showReplies, setShowReplies] = useState(false);

    const headerBackgroundColor = useMemo(() => {
      if (task.state === 'Cancelled') {
        return 'red';
      } else if (task.status === 'd') {
        return 'green';
      }
    }, [task]);

    //#region Task methods
    const handleSetNewStatus = async (newStatus: Card.Task.Status) => {
      try {
        await pdfManager.changeTaskStatus(task.pageNumber, task.id, newStatus);
      } catch (error) {
        throw error;
      }
    };

    const handleWatchTask = () => {
      if (!task.watchers?.includes(currentUserId)) {
        pdfManager.watchTask(task.pageNumber, task.id, currentUserId);
      } else {
        pdfManager.removeWatchFromTask(task.pageNumber, task.id, currentUserId);
      }
    };

    const handleDeleteTask = () => {
      dispatch(
        openAndUpdateModal({
          modal: 'PDFConfirmationModal',
          data: {
            title: 'DELETE_TASK',
            message: 'DELETING_THIS_TASK_WILL_PERMANENTLY_REMOVE_IT_CONFIRM',
            messageValues: { taskNumber: (index ?? 0) + 1 },
            cancelButtonTextId: 'global.cancel',
            confirmButtonType: 'danger',
            confirmButtonTextId: 'global.delete',
            headerType: 'error',
            actionCode: 'deleteTask',
            actionValue: {
              taskId: task.id,
              pdf: true,
              pageNumber: task.pageNumber,
            },
            cancelButtonShow: true,
          },
        }),
      );
    };

    const handleOpenWatchModal = () => {
      dispatch(openAndUpdateModal({ modal: 'PDFTaskWatchModal', data: { taskId: task.id } }));
    };

    const handleReplyToAnnotation = (reply: string) => {
      pdfManager.replyToAnnotation(task.pageNumber, task.id, JSON.parse(reply));
    };

    const handleVoteReply: LikeToggleProps['onVote'] = ({ replyId, currentUserLiked }) => {
      if (replyId) {
        pdfManager.voteReplyToAnnotation(
          task.pageNumber,
          task.id,
          replyId,
          currentUserLiked ? 0 : 1,
        );
      }
    };

    const handleEditReply = async ({
      replyId,
      newContent,
    }: {
      replyId: string;
      newContent: string;
    }) => {
      try {
        await pdfManager.editReplyAnnotation(
          task.pageNumber,
          task.id,
          replyId,
          stringToRichText(newContent),
        );
      } catch (e) {
        throw e;
      }
    };

    const handleDeleteReply = async (replyId: string) => {
      try {
        await pdfManager.deleteReplyAnnotation(task.pageNumber, task.id, replyId);
      } catch (e) {
        throw e;
      }
    };

    const canEditReply = (replyId: string) => {
      const reply = task.replies?.find((reply) => reply.id === replyId);
      if (reply) {
        return canEditAnnotationReply(reply);
      }
      return false;
    };
    const canDeleteReply = (replyId: string) => {
      const reply = task.replies?.find((reply) => reply.id === replyId);
      if (reply) {
        return canDeleteAnnotationReply(reply);
      }
      return false;
    };
    const canVoteReply = () => {
      return canComment;
    };

    //#endregion

    //#region Card methods
    const handleEditTask = () => {
      dispatch(setEditingAnnotation({ id: task.id, isTask: true }));
    };

    const handleToggleReplies = () => {
      setShowReplies(!showReplies);
    };
    const handleScrollToAnnotation = () => {
      if (sidebar) {
        pdfManager.selectAnnotation(task);
      }
    };
    //#endregion

    return (
      <span data-testid={testId ? `${testId}-view` : undefined} id={`annotation-card-${task.id}`}>
        {sidebar && isListMode && !isSelected && order != null ? (
          <MinifiedCard
            task={task}
            order={order}
            onClick={handleScrollToAnnotation}
            testId={testId}
          />
        ) : (
          <Card
            sidebar={sidebar}
            selected={isSelected}
            onClick={handleScrollToAnnotation}
            ref={ref}
            testId={testId}
          >
            <Card.Header size="large" background={headerBackgroundColor}>
              <Card.Header.Left>
                <AssigneePresentation userId={task.assignee} />
              </Card.Header.Left>
              <Card.Header.Right>
                <TaskStatus taskState={task.state} taskStatus={task.status} />
              </Card.Header.Right>
            </Card.Header>
            <Card.DueDateBanner
              dueDate={task.dueDate}
              divider={headerBackgroundColor && !task.dueDate}
            />
            <Card.Body>
              <Card.Body.Header>
                <Card.Header.Left>
                  <UserPresentation
                    userId={task.authorId ?? ''}
                    title={intl.formatMessage({ id: 'REPORTER' })}
                  />
                </Card.Header.Left>
                <Card.Header.Right>
                  <TaskOptions
                    editTask={{ onClick: handleEditTask, disabled: !canEditAnnotation(task) }}
                    deleteTask={{
                      onClick: handleDeleteTask,
                      disabled: !canDeleteAnnotation(task),
                    }}
                    changeStatus={{
                      onClick: handleSetNewStatus,
                      disabled: !canChangeAnnotationStatus(task),
                    }}
                    watchOptions={{ onClick: handleOpenWatchModal, disabled: false }}
                    testId={testId}
                  />
                </Card.Header.Right>
              </Card.Body.Header>
              <ViewRichTextEditor
                initialValue={task.content?.content && JSON.stringify(task.content.content)}
                overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
                dependencies={[task.content]}
                testId={`${testId}-rich-text-editor`}
              />
            </Card.Body>
            <Card.Footer>
              <Card.Footer.Left>
                <WatchToggle
                  watchers={task.watchers}
                  disabled={!canWatchAnnotation}
                  onWatch={handleWatchTask}
                  openWatchModal={handleOpenWatchModal}
                  testId={testId}
                />
                <ReplyToggle
                  repliesCount={task.replies?.length ?? 0}
                  isToggled={showReplies}
                  onToggleReplies={handleToggleReplies}
                  canComment={canComment}
                  id={`task-${task.id}-replyToggle`}
                  testId={testId}
                />
              </Card.Footer.Left>
            </Card.Footer>
            {showReplies && (
              <ReplyList
                replies={task.replies ?? []}
                testId={`${testId}-replyList`}
                commentId={task.id}
                collaborators={collaborators}
                newReplyInteraction={{ environment: 'dopdf' }}
                createReply={handleReplyToAnnotation}
                editReply={handleEditReply}
                deleteReply={handleDeleteReply}
                voteReply={handleVoteReply}
                canComment={canComment}
                canEditReply={canEditReply}
                canDeleteReply={canDeleteReply}
                canVoteReply={canVoteReply}
              />
            )}
          </Card>
        )}
      </span>
    );
  },
);

export default ViewTaskCardContent;
