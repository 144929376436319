import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Divider, Select, Toggle, Tooltip } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';

import styles from './ObjectPreview.module.scss';

type ObjectPreviewControlsProps = {
  object: doDOC.File;
  zoom: {
    label: string;
    value: string;
  };
  setZoom: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
} & (
  | {
      showPagination?: true;
      previousPageIsDisabled: boolean;
      nextPageIsDisabled: boolean;
      onPreviousPage: () => void;
      onNextPage: () => void;
      currentPage: number;
      totalPageNumber: number;
    }
  | {
      showPagination?: undefined | false;
      previousPageIsDisabled?: boolean;
      nextPageIsDisabled?: boolean;
      onPreviousPage?: () => void;
      onNextPage?: () => void;
      currentPage?: number;
      totalPageNumber?: number;
    }
);

const ObjectPreviewControls = ({
  object,
  zoom,
  setZoom,
  nextPageIsDisabled,
  onNextPage,
  onPreviousPage,
  previousPageIsDisabled,
  currentPage,
  totalPageNumber,
  showPagination = true,
}: ObjectPreviewControlsProps) => {
  const intl = useIntl();
  const userId = useSelector((state) => state.auth.userId);

  useEffect(() => {
    const zoom = window.localStorage.getItem(`${userId}-${object.id}-preview-zoom`);
    if (zoom) {
      const zoomLabel = (+zoom * 100).toFixed(0);
      setZoom({ value: zoom, label: `${zoomLabel}%` });
    }
  }, [userId, object]);

  const zoomOptions = useMemo(() => {
    return [
      { value: '0.5', label: '50%' },
      { value: '1', label: '100%' },
      { value: '1.5', label: '150%' },
      { value: '2', label: '200%' },
    ];
  }, []);

  const handleZoomChange = (value: (typeof zoomOptions)[number]) => {
    setZoom(value);
    window.localStorage.setItem(`${userId}-${object.id}-preview-zoom`, `${value.value}`);
  };

  return (
    <div className={styles.controls} data-testid="object-preview-controls">
      {showPagination && (
        <>
          <Tooltip
            disabled={previousPageIsDisabled}
            content={intl.formatMessage({ id: 'PREVIOUS_PAGE' })}
            placement="top"
            testId="object-preview-controls-previous-page-tooltip"
          >
            <Toggle
              size="medium"
              variant="link"
              icon="ArrowUpGrey"
              disabled={previousPageIsDisabled}
              onClick={onPreviousPage}
              testId="object-preview-controls-previous-page"
            />
          </Tooltip>
          <Tooltip
            disabled={nextPageIsDisabled}
            content={intl.formatMessage({ id: 'NEXT_PAGE' })}
            placement="top"
            testId="object-preview-controls-next-page-tooltip"
          >
            <Toggle
              size="medium"
              variant="link"
              icon="ArrowDownGrey"
              disabled={nextPageIsDisabled}
              onClick={onNextPage}
              margin="0 1rem 0 0"
              testId="object-preview-controls-next-page"
            />
          </Tooltip>
          <span className={styles.pagination} data-testid="object-preview-controls-pagination">
            {currentPage}/{totalPageNumber}
          </span>
          <Divider vertical margin="0 2rem" />
        </>
      )}
      <Select.Ghost
        width="10rem"
        menuWidth="18rem"
        size="medium"
        menuPlacement="top"
        clearable={false}
        value={zoomOptions.find((option) => option.value === zoom.value)}
        onChange={handleZoomChange}
        options={zoomOptions}
        testId="object-preview-controls-zoom"
      />
    </div>
  );
};

export default ObjectPreviewControls;
