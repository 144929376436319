import { Tooltip } from 'dodoc-design-system';
import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';
import styles from './MultipleUsersAvatar.module.scss';

type MultipleUsersAvatarProps = {
  usersId: UserId[];
  testId: string;
};

const MultipleUsersAvatar = ({ usersId, testId }: MultipleUsersAvatarProps) => {
  return (
    <Tooltip
      content={
        <>
          {usersId.map((id) => (
            <div key={`multipleUsersAvatar-${id}`}>
              <UsernameLabel userId={id} />
            </div>
          ))}
        </>
      }
      testId={`${testId}-tooltip`}
    >
      <div className={styles.root} tabIndex={0}>
        +{usersId.length}
      </div>
    </Tooltip>
  );
};

export default MultipleUsersAvatar;
