import {
  createContext,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PageViewport } from 'pdfjs-dist';
import { calculateRelativeCoordinates, getBoxFromAnnotation, getResizeValues } from './Utils';
import { usePDFContext } from 'PDF/PDFContext';
import { useDispatch } from '_common/hooks';
import { setAnnotationBeingTransformed } from 'PDF/redux/PDFAnnotationsSlice';

export type NODE = 'nw' | 'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w' | 'start' | 'end';

type AnnotationContextValue = {
  annotation: PDF.Annotation;
  viewport: PageViewport;
  pageNum: number;
  isSelected: boolean;
  box: PDF.Annotation.Rect;
  move: Moving;
  resize: Resizing;
  onResizeDown: (e: ReactMouseEvent<HTMLSpanElement>, node: NODE) => void;
  onMoveDown: (e: ReactMouseEvent<HTMLSpanElement>) => void;
};

type AnnotationContextProviderProps = {
  annotation: PDF.Annotation;
  viewport: PageViewport;
  pageNum: number;
  isSelected: boolean;
  children: ReactNode;
};

type Resizing = { isOn: boolean; node?: NODE; mouse?: PDF.Annotation.Point };
type Moving = { isOn: boolean; start?: PDF.Annotation.Point; end?: PDF.Annotation.Point };

const AnnotationContext = createContext<AnnotationContextValue>({} as AnnotationContextValue);

const AnnotationContextProvider = ({
  children,
  annotation,
  viewport,
  pageNum,
  isSelected,
}: AnnotationContextProviderProps) => {
  const manager = usePDFContext();
  const dispatch = useDispatch();
  const [resize, setResize] = useState<Resizing>({ isOn: false });
  const [move, setMove] = useState<Moving>({ isOn: false });

  useEffect(() => {
    if (move.isOn) {
      const page = manager.getPageFromNumber(pageNum);
      page?.addEventListener('mousemove', onMove);
      return () => {
        page?.removeEventListener('mousemove', onMove);
      };
    }
  }, [move.isOn]);

  useEffect(() => {
    if (move.isOn && move.start && move.end) {
      document.addEventListener('mouseup', onMoveUp);
      return () => {
        document.removeEventListener('mouseup', onMoveUp);
      };
    }
  }, [move]);

  useEffect(() => {
    if (resize.isOn) {
      const page = manager.getPageFromNumber(pageNum);
      page?.addEventListener('mousemove', onResize);
      return () => {
        page?.removeEventListener('mousemove', onResize);
      };
    }
  }, [resize.isOn]);

  useEffect(() => {
    if (resize.isOn && resize.mouse && resize.node) {
      document.addEventListener('mouseup', onResizeUp);

      return () => {
        document.removeEventListener('mouseup', onResizeUp);
      };
    }
  }, [resize]);

  const parsedAnnotation = useMemo(() => {
    if (resize.isOn && resize.node && resize.mouse && annotation.subtype === 'Line') {
      return {
        ...annotation,
        lineCoordinates: {
          ...annotation.lineCoordinates,
          [resize.node]: {
            x: resize.mouse.x / viewport.scale,
            y: resize.mouse.y / viewport.scale,
          },
        },
      };
    }
    return annotation;
  }, [annotation, resize]);

  const box = useMemo(() => {
    return getBoxFromAnnotation(parsedAnnotation, viewport.scale);
  }, [viewport, annotation]);

  const onMoveDown = useCallback((e: ReactMouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setMove({ isOn: true, start: { x: e.pageX, y: e.pageY }, end: { x: e.pageX, y: e.pageY } });
    dispatch(setAnnotationBeingTransformed(annotation.id));
  }, []);

  const onMove = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setMove((state) => ({ ...state, end: { x: e.pageX, y: e.pageY } }));
    },
    [move],
  );

  const onMoveUp = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (move.isOn && move.start && move.end) {
        const deltaX = (move.end.x - move.start.x) / viewport.scale;
        const deltaY = (move.start.y - move.end.y) / viewport.scale;

        const lowerLimitX = -annotation.rect.left;
        const lowerLimitY = -annotation.rect.bottom;

        const upperLimitX = viewport.width / viewport.scale - annotation.rect.right;
        const upperLimitY = viewport.height / viewport.scale - annotation.rect.top;

        manager.moveAnnotation(pageNum, annotation.id, {
          x: Math.min(upperLimitX, Math.max(deltaX, lowerLimitX)),
          y: Math.min(upperLimitY, Math.max(deltaY, lowerLimitY)),
        });
      }
      setMove({ isOn: false });
      dispatch(setAnnotationBeingTransformed(null));
    },
    [move],
  );

  /************ RESIZE ************/

  const onResizeDown = useCallback((e: ReactMouseEvent<HTMLSpanElement>, node: NODE) => {
    e.stopPropagation();
    e.preventDefault();
    const page = manager.getPageFromNumber(pageNum);
    if (page) {
      const coords = calculateRelativeCoordinates(page, e.pageX, e.pageY, 1);
      if (coords) {
        setResize({ isOn: true, node, mouse: { x: coords.left, y: coords.bottom } });
        dispatch(setAnnotationBeingTransformed(annotation.id));
      }
    }
  }, []);

  const onResize = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      const page = manager.getPageFromNumber(pageNum);
      if (resize.isOn && page) {
        const coords = calculateRelativeCoordinates(page, e.pageX, e.pageY, 1);
        if (coords) {
          setResize((state) => ({ ...state, mouse: { x: coords.left, y: coords.bottom } }));
        }
      }
    },
    [resize],
  );

  const consumeOnClick = useCallback((e: MouseEvent) => {
    //As a click event, we can now stop click propagation
    e.stopPropagation();
    document.removeEventListener(
      'click',
      consumeOnClick,
      true /** useCapture: If true prioritize over other events in DOM tree */,
    );
  }, []);

  const onResizeUp = useCallback(
    (e: MouseEvent) => {
      //MouseUp is triggered before Click, the event handle below will be triggered right after MouseUp
      document.addEventListener(
        'click',
        consumeOnClick,
        true /** useCapture: If true prioritize over other events in DOM tree */,
      );

      e.stopPropagation();
      e.preventDefault();
      if (resize.isOn && resize.node && resize.mouse) {
        if (annotation.subtype === 'Line' && (resize.node === 'start' || resize.node === 'end')) {
          const deltaX =
            resize.mouse.x / viewport.scale - annotation.lineCoordinates[resize.node].x;
          const deltaY =
            resize.mouse.y / viewport.scale - annotation.lineCoordinates[resize.node].y;

          manager.moveLineAnnotation(pageNum, annotation.id, resize.node, {
            x: deltaX,
            y: deltaY,
          });
        } else {
          const values = getResizeValues(box, resize.node, resize.mouse, viewport);
          if (values) {
            manager.resizeAnnotation(pageNum, annotation.id, {
              width: (values.width - box.width) / viewport.scale,
              height: (values.height - box.height) / viewport.scale,
              left: (values.left - box.left) / viewport.scale,
              bottom: (values.bottom - box.bottom) / viewport.scale,
            });
          }
        }
      }
      setResize({ isOn: false });
      dispatch(setAnnotationBeingTransformed(null));
    },
    [resize],
  );

  return (
    <AnnotationContext.Provider
      value={{
        annotation: parsedAnnotation,
        viewport,
        pageNum,
        isSelected,
        box,
        resize,
        onResizeDown,
        move,
        onMoveDown,
      }}
    >
      {children}
    </AnnotationContext.Provider>
  );
};

export const useAnnotationContext = () => {
  return useContext(AnnotationContext);
};

export const useAnnotationBox = () => {
  const { box, move, resize, viewport } = useAnnotationContext();

  if (move.isOn && move.start && move.end) {
    const deltaX = move.end.x - move.start.x;
    const deltaY = move.start.y - move.end.y;

    const left = Math.min(Math.max(box.left + deltaX, 0), viewport.width - box.width);
    const bottom = Math.max(
      0,
      Math.min(Math.max(box.bottom + deltaY, 0), viewport.height - box.height),
    );

    return { ...box, left, bottom };
  } else if (resize.isOn && resize.node && resize.mouse) {
    return { ...box, ...getResizeValues(box, resize.node, resize.mouse, viewport) };
  }

  return box;
};

export default AnnotationContextProvider;
