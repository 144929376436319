import { useGetMetadataListQuery } from 'App/redux/MetadataApi';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { UsernameLabel } from '_common/components';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';
import { usePublicProfile } from '_common/hooks';

import styles from './MetadataOption.module.scss';

type MetadataOptionProps = {
  metadata: {
    label: string;
    value: string;
  };
};

const MetadataOption = ({ metadata }: MetadataOptionProps) => {
  const contentStatus = metadata.label === 'Content status' && metadata.value;
  const element = useSelector(getDocumentObject);
  const { status } = useGetElementStatusListQuery(undefined, {
    selectFromResult: ({ data }) => ({
      status: contentStatus ? data?.entities[contentStatus] : undefined,
    }),
  });
  const { data: metadataList } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });
  const profile = usePublicProfile(metadata.value, '', !metadata.label.includes('Content Author') || !metadata.label.includes('Assignee'));
  const tagsSeparator = Object.entries(metadataList)
    .filter((id) => metadata.label === 'Tags')
    .map(([_, values]) => values)[0]?.separator;

  const handleValue = (field: MetadataOptionProps['metadata']) => {
    if (field.label === 'Content author' || field.label === 'Assignee') {
      return <UsernameLabel userId={field.value} />;
    }
    if (field.label === 'Content status') {
      return status && status.name;
    }
    if (field.label === 'Tags') {
      return element.tags.length > 0
        ? element.tags.map((v: string) => v).join(tagsSeparator)
        : <FormattedMessage id="NOT_SET" />
    }
    if (Array.isArray(field.value)) {
      return field.value.length > 0 ? (
        field.value.map((v) => v).join(', ')
      ) : (
        <FormattedMessage id="NOT_SET" />
      );
    }
    return field.value;
  };

  const handleTitle = (field: MetadataOptionProps['metadata']) => {
    if (field.label === 'Content author' || field.label === 'Assignee') {
      return profile.name;
    }
    if (field.label === 'Content status') {
      return status && status.name;
    }
    if (field.label === 'Tags') {
      return element.tags;

    }
    return field.value;
  }

  return (
    <div className={styles.item}>
      <div className={styles.itemLabel}>{metadata.label}</div>
      <div className={styles.itemValue} title={handleTitle(metadata)}>
        {(metadata.value || metadata.label === 'Tags') ? handleValue(metadata) : <FormattedMessage id="NOT_SET" />}
      </div>
    </div>
  );
};

export default MetadataOption;
