import { WidgetViewModel } from './WidgetViewModel';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export class TabBarWidgetModel extends WidgetViewModel<'tabulations'> {
  constructor(
    Visualizer: Editor.Visualizer.State,
    refView: Editor.Visualizer.WidgetData['tabulations']['view'],
    props: Editor.Visualizer.WidgetData['tabulations']['props'],
  ) {
    super(Visualizer, refView, props);

    this.container.dataset.type = 'tabulations';
  }

  render(): void {
    let offsets = EditorDOMUtils.getOffsets(this.refView);
    const computedStyle = getComputedStyle(this.refView);

    while (this.container.firstChild) {
      this.container.removeChild(this.container.firstChild);
    }

    if (offsets) {
      let marginTop = EditorDOMUtils.convertUnitTo(computedStyle.marginTop, 'px', 'px');
      if (isNaN(marginTop)) {
        marginTop = 0;
      }

      let marginBottom = EditorDOMUtils.convertUnitTo(computedStyle.marginBottom, 'px', 'px');
      if (isNaN(marginBottom)) {
        marginBottom = 0;
      }

      let top = offsets.top - marginTop;
      let height = offsets.height + marginTop + marginBottom;

      this.container.style.position = 'absolute';
      this.container.style.top = `${top}px`;

      if (this.Visualizer.getLayoutType?.() === 'PAGE') {
        this.container.style.left = `calc(50% - ${offsets.width / 2}px)`;
      } else {
        this.container.style.left = `${offsets.left}px`;
      }

      this.container.style.width = `0px`;
      this.container.style.height = `${height}px`;
      this.container.style.userSelect = 'none';

      for (let i = 0; i < this.props.tabPos.length; i++) {
        const pos = this.props.tabPos[i];

        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.left = `${pos}px`;
        div.style.height = `${height}px`;
        div.style.width = '1px';
        div.style.background = '#000000';
        this.container.appendChild(div);
      }
    }
  }

  dispose(): void {
    this.container.remove();
  }
}
