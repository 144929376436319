import { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import { useDispatch, usePublicProfile, useSelector } from '_common/hooks';
import { usePDFContext } from 'PDF/PDFContext';

import { setEditingAnnotation, setCreating, selectAnnotation } from 'PDF/redux/PDFAnnotationsSlice';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';

import { InteractionController } from '_common/components';
import {
  RichTextEditorHandler,
  RichTextEditorProps,
} from '_common/components/RichTextEditor/RichTextEditor';
import { UserOption } from '_common/components/SearchUser/SearchUser';

import usePDFCollaborators from 'PDF/hooks/usePDFCollaborators';
import {
  AssigneeField,
  DescriptionField,
  DueDateField,
  UserPresentation,
} from '_common/suite/components/Card';
import { Card } from '_common/suite/components';

export type EditableTaskCardProps = {
  sidebar: boolean;
  testId: string;
} & (
  | {
      mode: 'create';
      task?: undefined;
      creating: Omit<PDF.TaskCreationData, 'task'>;
    }
  | {
      mode: 'edit';
      task: PDF.Annotation.Task;
      creating?: undefined;
    }
);

const EditableTaskCardContent = ({
  mode,
  task,
  creating,
  sidebar,
  testId,
}: EditableTaskCardProps) => {
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();

  const assigneeProfile = usePublicProfile(task?.assignee ?? '');
  const collaborators = usePDFCollaborators();
  const currentUserId = useSelector((state) => state.auth.userId);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);

  const cardRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<RichTextEditorHandler>(null);
  const assignedRef = useRef<HTMLDivElement>(null);
  const dueDateRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLButtonElement>(null);

  const [localDescription, setDescription] = useState<string | undefined>(
    task?.content?.content ? JSON.stringify(task?.content?.content) : undefined,
  );
  const [localAssignee, setAssignee] = useState<UserOption>();
  const [localDueDate, setDueDate] = useState<Date>();

  useEffect(() => {
    if (sidebar && cardRef.current) {
      cardRef.current.scrollIntoView({ block: 'center' });
    }
  }, [sidebar]);

  useEffect(() => {
    if (localAssignee) {
      dispatch(setPulseData({ taskHasAssignee: true }));
      dispatch(completeAction('pdf_tasks_assignee'));
    }
    if (localDueDate) {
      dispatch(setPulseData({ taskHasDueDate: true }));
      dispatch(completeAction('pdf_tasks_dueDate'));
    }
    if (!localDescription) {
      dispatch(setPulseData({ taskHasDescription: false }));
      const current = descriptionRef?.current?.getCurrent();
      if (current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: current.offsetTop,
              left: current.offsetLeft,
              height: current.offsetHeight,
              width: current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else if (!actionsCompleted.pdf_tasks_assignee) {
      dispatch(setPulseData({ taskHasDescription: true }));
      if (assignedRef?.current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: assignedRef.current.offsetTop,
              left: assignedRef.current.offsetLeft,
              height: assignedRef.current.offsetHeight,
              width: assignedRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else if (!actionsCompleted.pdf_tasks_dueDate) {
      dispatch(setPulseData({ taskHasDescription: true }));
      if (dueDateRef?.current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: dueDateRef.current.offsetTop,
              left: dueDateRef.current.offsetLeft,
              height: dueDateRef.current.offsetHeight,
              width: dueDateRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else {
      dispatch(setPulseData({ taskHasDescription: true }));
      if (ctaRef?.current)
        dispatch(
          setPulseData({
            taskButtonRect: {
              top: ctaRef.current.offsetTop,
              left: ctaRef.current.offsetLeft,
              height: ctaRef.current.offsetHeight,
              width: ctaRef.current.offsetWidth,
            },
            taskInputRect: undefined,
          }),
        );
    }

    return () => {
      dispatch(
        setPulseData({
          taskInputRect: undefined,
        }),
      );
    };
  }, [localDueDate, localAssignee, localDescription, actionsCompleted]);

  useEffect(() => {
    if (mode === 'edit' && task.content?.content) {
      setDescription(
        task.content?.content.length > 0 ? JSON.stringify(task.content?.content) : undefined,
      );
    }
  }, [task?.content?.content, mode]);

  useEffect(() => {
    if (mode === 'edit' && assigneeProfile.id) {
      const assigneeValue: UserOption = {
        value: task.assignee,
        label: task.assignee ? assigneeProfile.name : '',
        avatar: '',
        description: '',
        username: '',
        name: '',
        is_superuser: false,
        type: 'user',
      };
      setAssignee(assigneeValue);
    } else {
      setAssignee(undefined);
    }
  }, [assigneeProfile.id]);

  useEffect(() => {
    if (mode === 'edit') {
      setDueDate(task.dueDate ? new Date(task.dueDate) : undefined);
    }
  }, [task?.dueDate]);

  const usersList: UserOption[] = collaborators.map(
    (id) =>
      ({
        id: id,
        type: 'user',
      } as UserOption),
  );

  const handleDescriptionChange: RichTextEditorProps['onChange'] = (description) => {
    setDescription(description);
  };

  const handleDueDateChange = (newDueDate: Date) => {
    setDueDate(newDueDate);
  };

  const handleCancelClicked = () => {
    if (mode === 'create') {
      dispatch(setCreating());
    } else {
      dispatch(setEditingAnnotation({ id: null, isTask: false }));
    }
  };

  const handleApply = () => {
    if (!localDescription) {
      return;
    }

    if (mode === 'edit') {
      pdfManager.editTask(task.pageNumber, task.id, {
        content: JSON.parse(localDescription),
        assignee: localAssignee?.value ?? '',
        dueDate: localDueDate?.toISOString() ?? '',
      });
      dispatch(setEditingAnnotation({ id: null, isTask: false }));
      notify({
        type: 'success',
        title: 'TASK_EDITED',
        message: 'THE_TASK_WAS_SUCCESSFULLY_EDITED',
      });
    } else {
      const taskId = pdfManager.createAnnotation({
        ...creating,
        content: JSON.parse(localDescription),
        task: {
          assignee: localAssignee?.value ?? '',
          dueDate: localDueDate?.toISOString() ?? '',
          status: 'td',
          watchers: [],
        },
      });

      dispatch(setCreating());
      dispatch(completeAction('pdf_tasks_createTask'));
      if (taskId) {
        dispatch(selectAnnotation(taskId));
        dispatch(setPulseData({ taskId }));
      }
    }
  };

  return (
    <InteractionController environment="dopdf">
      <span data-testid={`${testId}-editable`}>
        <Card
          sidebar={sidebar}
          ref={cardRef}
          id={task?.id ? undefined : 'temporaryTaskCard'}
          testId={task?.id ? `task-${task?.id}-card` : 'temporaryTask-card'}
        >
          <Card.Header>
            <Card.Header.Left>
              <UserPresentation userId={task?.authorId || currentUserId} />
            </Card.Header.Left>
          </Card.Header>
          <Card.Body>
            <InteractionController
              environment="dopdf"
              rules={[{ interaction: 'pdf_task_fill' }]}
              style={{ flexDirection: 'column' }}
            >
              <DescriptionField
                id={task?.id ? undefined : 'temporaryTaskDescription'}
                ref={descriptionRef}
                initialValue={localDescription}
                collaborators={collaborators}
                onChange={handleDescriptionChange}
                testId={testId}
              />
              <AssigneeField
                id={task?.id ? undefined : 'temporaryTaskAssignedTo'}
                collaborators={usersList}
                assignee={localAssignee}
                onChange={setAssignee}
                ref={assignedRef}
                testId={testId}
              />
              <DueDateField
                id={task?.id ? undefined : 'temporaryTaskDueDate'}
                dueDate={localDueDate}
                onChange={handleDueDateChange}
                ref={dueDateRef}
                testId={testId}
              />
            </InteractionController>
          </Card.Body>
          <Card.Footer>
            <Card.Footer.Right>
              <Button
                size="small"
                variant="link"
                onClick={handleCancelClicked}
                testId={`${testId}-cancel-button`}
              >
                <FormattedMessage id="global.cancel" />
              </Button>
              <InteractionController environment="dopdf" rules={[{ interaction: 'pdf_task_fill' }]}>
                <Button
                  size="small"
                  variant="primary"
                  margin="0 0 0 1rem"
                  disabled={!localDescription}
                  onClick={handleApply}
                  ref={ctaRef}
                  testId={`${testId}-submit-button`}
                  id={task?.id ? undefined : 'temporaryTaskCTA'}
                >
                  {mode === 'create' ? (
                    <FormattedMessage id="global.create" />
                  ) : (
                    <FormattedMessage id="SAVE_CHANGES" />
                  )}
                </Button>
              </InteractionController>
            </Card.Footer.Right>
          </Card.Footer>
        </Card>
      </span>
    </InteractionController>
  );
};

export default EditableTaskCardContent;
