import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, Toggle } from 'dodoc-design-system';
import type { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

import { parseMeasurement } from 'utils';
import { useDispatch, useSelector } from '_common/hooks';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import EditorManager from 'Editor/services/EditorManager';

import MeasureField, { Measure, MeasureFieldProps } from './MeasureField/MeasureField';
import styles from './WrapTextOptionsModal.module.scss';

type ImageWrap = Editor.Styles.ImageWrapProperty;
type Distance = Measure & { editable: boolean; hasError?: boolean };
type Margin = { top: Distance; right: Distance; bottom: Distance; left: Distance };
type Margins = { [x in ImageWrap]: Margin };

const MODAL = 'WrapTextOptionsModal';
const DEFAULT_WRAP_OPTION: ImageWrap = 'inline';
const DEFAULT_DISTANCE = {
  top: 0,
  right: 0.32,
  bottom: 0,
  left: 0.32,
};
const DEFAULT_UNIT: Measure['unit'] = 'cm';
const DEFAULT_MAX_MEASURE: Measure = {
  value: 55.87,
  unit: DEFAULT_UNIT,
};
const DEFAULT_MIN_MEASURE: Measure = {
  value: 0,
  unit: DEFAULT_UNIT,
};

const WrapTextOptionsModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const WRAP_OPTIONS: {
    [x in ImageWrap]: { icon: IconTypes['24']; label: string };
  } = {
    inline: {
      icon: 'TextWrapInline',
      label: intl.formatMessage({ id: 'IN_LINE_WITH_TEXT' }),
    },
    left: {
      icon: 'TextWrapLeft',
      label: intl.formatMessage({ id: 'LEFT' }),
    },
    right: {
      icon: 'TextWrapRight',
      label: intl.formatMessage({ id: 'RIGHT' }),
    },
    topAndBottom: {
      icon: 'TextWrapTopAndBottom',
      label: intl.formatMessage({ id: 'TOP_AND_BOTTOM' }),
    },
    behindText: {
      icon: 'TextWrapBehindText',
      label: intl.formatMessage({ id: 'BEHIND_TEXT' }),
    },
    inFrontText: {
      icon: 'TextWrapInFrontOfText',
      label: intl.formatMessage({ id: 'IN_FRONT_OF_TEXT' }),
    },
  };

  //Original data of the image inline properties
  const originalInlineProperties = useMemo(() => {
    const imageInlineProperties = EditorManager.getInstance().getSelectedImageProperties();
    const properties: {
      imageWrap: Exclude<Editor.Styles.ImageProperties['imageWrap'], undefined>;
      margins: Margins;
    } = {
      imageWrap: { value: DEFAULT_WRAP_OPTION },
      margins: {
        inline: {
          top: { value: DEFAULT_DISTANCE.top, unit: DEFAULT_UNIT, editable: false },
          right: { value: DEFAULT_DISTANCE.right, unit: DEFAULT_UNIT, editable: false },
          bottom: { value: DEFAULT_DISTANCE.bottom, unit: DEFAULT_UNIT, editable: false },
          left: { value: DEFAULT_DISTANCE.left, unit: DEFAULT_UNIT, editable: false },
        },
        left: {
          top: { value: DEFAULT_DISTANCE.top, unit: DEFAULT_UNIT, editable: true },
          right: { value: DEFAULT_DISTANCE.right, unit: DEFAULT_UNIT, editable: true },
          bottom: { value: DEFAULT_DISTANCE.bottom, unit: DEFAULT_UNIT, editable: true },
          left: { value: DEFAULT_DISTANCE.left, unit: DEFAULT_UNIT, editable: true },
        },
        right: {
          top: { value: DEFAULT_DISTANCE.top, unit: DEFAULT_UNIT, editable: true },
          right: { value: DEFAULT_DISTANCE.right, unit: DEFAULT_UNIT, editable: true },
          bottom: { value: DEFAULT_DISTANCE.bottom, unit: DEFAULT_UNIT, editable: true },
          left: { value: DEFAULT_DISTANCE.left, unit: DEFAULT_UNIT, editable: true },
        },
        topAndBottom: {
          top: { value: DEFAULT_DISTANCE.top, unit: DEFAULT_UNIT, editable: true },
          right: { value: DEFAULT_DISTANCE.right, unit: DEFAULT_UNIT, editable: false },
          bottom: { value: DEFAULT_DISTANCE.bottom, unit: DEFAULT_UNIT, editable: true },
          left: { value: DEFAULT_DISTANCE.left, unit: DEFAULT_UNIT, editable: false },
        },
        behindText: {
          top: { value: DEFAULT_DISTANCE.top, unit: DEFAULT_UNIT, editable: false },
          right: { value: DEFAULT_DISTANCE.right, unit: DEFAULT_UNIT, editable: false },
          bottom: { value: DEFAULT_DISTANCE.bottom, unit: DEFAULT_UNIT, editable: false },
          left: { value: DEFAULT_DISTANCE.left, unit: DEFAULT_UNIT, editable: false },
        },
        inFrontText: {
          top: { value: DEFAULT_DISTANCE.top, unit: DEFAULT_UNIT, editable: false },
          right: { value: DEFAULT_DISTANCE.right, unit: DEFAULT_UNIT, editable: false },
          bottom: { value: DEFAULT_DISTANCE.bottom, unit: DEFAULT_UNIT, editable: false },
          left: { value: DEFAULT_DISTANCE.left, unit: DEFAULT_UNIT, editable: false },
        },
      },
    };

    if (imageInlineProperties) {
      const { imageWrap, margins } = imageInlineProperties;

      if (imageWrap?.value) {
        if (margins) {
          //Set margins
          (Object.keys(margins) as Array<keyof typeof margins>).forEach((margin) => {
            //Set margin value
            properties.margins[imageWrap.value][margin].value =
              parseMeasurement(`${margins[margin]?.value}`, DEFAULT_UNIT, {
                defaultUnit: 'pt',
                max: undefined,
              }) ?? 0;

            //Set margin incoherent
            properties.margins[imageWrap.value][margin].incoherent = margins[margin]?.incoherent;
            //Set margin unit
            properties.margins[imageWrap.value][margin].unit = DEFAULT_UNIT;
          });
        }
        //Set wrap option
        properties.imageWrap = imageWrap;
      }
    }

    return properties;
  }, []);

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const [currentWrapOption, setCurrentWrapOption] = useState<ImageWrap>(
    originalInlineProperties.imageWrap.value,
  );
  const [marginsOptions, setMarginsOptions] = useState<Margins>(originalInlineProperties.margins);

  useEffect(() => {
    setMarginsOptions({ ...originalInlineProperties.margins });
    setCurrentWrapOption(originalInlineProperties.imageWrap.value);
  }, [originalInlineProperties]);

  //Validate if the data is valid or edited
  const ctaIsEnabled = useMemo(() => {
    //Validate if any field has an error
    const dataHasError = (Object.keys(marginsOptions) as Array<keyof typeof marginsOptions>).some(
      (wrapOptionId) => {
        const marginOptions = marginsOptions[wrapOptionId];
        return (Object.keys(marginOptions) as Array<keyof typeof marginOptions>).some(
          (marginId) => {
            const distance = marginOptions[marginId];
            return !!distance.hasError;
          },
        );
      },
    );

    //Validate if the image wrap option was changed
    const imageWrapOptionIsEdited = originalInlineProperties.imageWrap.value !== currentWrapOption;

    //Validate if any field from original margins is edited
    const originalWrapOption = originalInlineProperties.imageWrap.value;
    const originalMargins = originalInlineProperties.margins[originalWrapOption];

    const originalMarginsAreEdited = (
      Object.keys(originalMargins) as Array<keyof typeof originalMargins>
    ).some((originalMarginId) => {
      const originalDistance = originalMargins[originalMarginId];
      const currentDistance = marginsOptions[originalWrapOption][originalMarginId];

      const originalValue = parseMeasurement(`${originalDistance?.value}`, 'cm', {
        defaultUnit: originalDistance?.unit,
        max: undefined,
      });
      const currentValue = parseMeasurement(`${currentDistance?.value}`, 'cm', {
        defaultUnit: currentDistance?.unit,
        max: undefined,
      });
      const defaultValue = parseMeasurement(`${DEFAULT_DISTANCE[originalMarginId]}`, 'cm', {
        defaultUnit: DEFAULT_UNIT,
        max: undefined,
      });

      return originalValue != null
        ? currentValue !== originalValue
        : currentValue !== originalValue && currentValue !== defaultValue;
    });

    return (
      !dataHasError &&
      (imageWrapOptionIsEdited || (!imageWrapOptionIsEdited && originalMarginsAreEdited))
    );
  }, [marginsOptions, currentWrapOption, originalInlineProperties]);

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const handleSave = () => {
    const currentMargins = marginsOptions[currentWrapOption];
    const parsedMargins: {
      top?: Editor.Styles.IncoherentProperty<number>;
      right?: Editor.Styles.IncoherentProperty<number>;
      bottom?: Editor.Styles.IncoherentProperty<number>;
      left?: Editor.Styles.IncoherentProperty<number>;
    } = {};

    (Object.keys(currentMargins) as Array<keyof typeof currentMargins>).forEach((marginId) => {
      const currentMargin = currentMargins[marginId];
      Object.assign(parsedMargins, {
        [marginId]: {
          value:
            parseMeasurement(`${currentMargin.value}`, 'pt', {
              defaultUnit: currentMargin.unit,
              max: undefined,
            }) ?? currentMargin.value,
        },
      });
    });

    EditorManager.getInstance().handleUpdateImageProperties({
      imageWrap: { value: currentWrapOption },
      margins: parsedMargins,
    });
    close();
  };

  const handleMeasureChange: MeasureFieldProps<keyof Margin>['onChange'] = (data) => {
    setMarginsOptions({
      ...marginsOptions,
      [currentWrapOption]: {
        ...marginsOptions[currentWrapOption],
        [data.id]: {
          ...marginsOptions[currentWrapOption][data.id],
          value: data.value,
          unit: data.unit,
          hasError: data.hasError,
        },
      },
    });
  };

  return (
    <Modal width="67rem" open={!!isOpen} onClose={close} testId="text-wrap-options">
      <Modal.Header onClose={close}>
        <FormattedMessage id="WRAP_TEXT_OPTIONS" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.root}>
          <div className={styles.wrap}>
            <div className={styles.options}>
              {(Object.keys(WRAP_OPTIONS) as Array<keyof typeof WRAP_OPTIONS>).map(
                (wrapOptionId) => (
                  <Toggle
                    key={wrapOptionId}
                    size="medium"
                    variant="link"
                    icon={WRAP_OPTIONS[wrapOptionId].icon}
                    isToggled={currentWrapOption === wrapOptionId}
                    onClick={() => {
                      setCurrentWrapOption(wrapOptionId);
                    }}
                    testId={`${wrapOptionId}-toggle`}
                  />
                ),
              )}
            </div>
            <div className={styles.currentOption} data-testid="current-wrap-text-option">
              {WRAP_OPTIONS[currentWrapOption].label}
            </div>
          </div>
          <div className={styles.fields}>
            <div className={styles.section}>
              <MeasureField
                id="top"
                value={marginsOptions[currentWrapOption].top.value}
                unit={marginsOptions[currentWrapOption].top.unit}
                disabled={!marginsOptions[currentWrapOption].top.editable}
                fieldLabel={intl.formatMessage({ id: 'TOP' })}
                min={DEFAULT_MIN_MEASURE}
                max={DEFAULT_MAX_MEASURE}
                onChange={handleMeasureChange}
              />
              <MeasureField
                id="left"
                value={marginsOptions[currentWrapOption].left.value}
                unit={marginsOptions[currentWrapOption].left.unit}
                disabled={!marginsOptions[currentWrapOption].left.editable}
                fieldLabel={intl.formatMessage({ id: 'LEFT' })}
                min={DEFAULT_MIN_MEASURE}
                max={DEFAULT_MAX_MEASURE}
                onChange={handleMeasureChange}
              />
            </div>
            <div className={styles.section}>
              <MeasureField
                id="bottom"
                value={marginsOptions[currentWrapOption].bottom.value}
                unit={marginsOptions[currentWrapOption].bottom.unit}
                disabled={!marginsOptions[currentWrapOption].bottom.editable}
                fieldLabel={intl.formatMessage({ id: 'BOTTOM' })}
                min={DEFAULT_MIN_MEASURE}
                max={DEFAULT_MAX_MEASURE}
                onChange={handleMeasureChange}
              />
              <MeasureField
                id="right"
                value={marginsOptions[currentWrapOption].right.value}
                unit={marginsOptions[currentWrapOption].right.unit}
                disabled={!marginsOptions[currentWrapOption].right.editable}
                fieldLabel={intl.formatMessage({ id: 'RIGHT' })}
                min={DEFAULT_MIN_MEASURE}
                max={DEFAULT_MAX_MEASURE}
                onChange={handleMeasureChange}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleSave}
          disabled={!ctaIsEnabled}
          testId="cta-button"
        >
          <FormattedMessage id="SAVE_CHANGES" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WrapTextOptionsModal;
