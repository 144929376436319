import { MouseEventHandler } from 'react';
import { Link, Toggle } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { LikesTooltip } from 'Editor/components';
import { LikeParent } from '_common/modals/LikesModal/LikesModal';

import styles from './LikeToggle.module.scss';

export type LikeToggleProps = {
  votes: Card.Vote[];
  replyId?: Card.Reply['id'];
  target: LikeParent;
  onVote: (payload: { replyId?: string; currentUserLiked: boolean }) => void;
  disabled?: boolean;
  testId: string;
};

const LikeToggle = ({ votes, replyId, target, onVote, disabled, testId }: LikeToggleProps) => {
  const dispatch = useDispatch();

  const currentUserId = useSelector((state) => state.auth.userId);
  const likeVotes: Card.Vote[] = votes.map((vote) => ({
    time: '',
    user: vote.user,
    value: vote.value,
  }));
  const usersLiked = votes.filter((vote) => vote.value !== 0).map((vote) => vote.user);
  const currentUserLiked = usersLiked.includes(currentUserId);

  const handleLikeReply: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();

    onVote({ replyId, currentUserLiked });
  };

  return (
    <div className={styles.likeCounter}>
      <Toggle
        size="medium"
        variant="link"
        icon={currentUserLiked ? 'LikeBlue' : 'LikeGrey'}
        isToggled={currentUserLiked}
        testId={`${testId}-like-toggle`}
        onClick={handleLikeReply}
        disabled={disabled}
      />
      {usersLiked.length > 0 && (
        <LikesTooltip votes={likeVotes} testId={`${testId}-likes-tooltip`}>
          <span className={styles.counter}>
            <Link
              onClick={() =>
                dispatch(
                  openAndUpdateModal({
                    modal: 'LikesModal',
                    data: { votes: likeVotes, type: target },
                  }),
                )
              }
              variant="neutral"
              testId={`${testId}-likes-link`}
            >
              {usersLiked.length}
            </Link>
          </span>
        </LikesTooltip>
      )}
    </div>
  );
};

export default LikeToggle;
