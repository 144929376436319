import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import tippy from 'tippy.js';
import { store } from '_common/redux';
import Intl from 'Intl/Intl';
import { FormattedMessage } from 'react-intl';

import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS, DISPLAY_TYPES } from 'Editor/services/consts';
import { BaseViewElement } from '../..';

import './FieldElement.module.scss';
import EditorManager from 'Editor/services/EditorManager';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export class FieldElement extends BaseViewElement {
  private linkTooltipInstance: any;
  private linkTooltipElement?: HTMLElement;

  constructor() {
    super();
    this.selectField = this.selectField.bind(this);
    this.deselectField = this.deselectField.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this._handleOnTooltipClick = this._handleOnTooltipClick.bind(this);
    this._handleOnTriggerTooltip = this._handleOnTriggerTooltip.bind(this);
    this._setLinkTooltip = this._setLinkTooltip.bind(this);
    this._destroyLinkTooltip = this._destroyLinkTooltip.bind(this);
    this._handleErrorState = this._handleErrorState.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('click', this._handleOnTooltipClick);
    this.addEventListener('mouseover', this.onMouseOver);

    this.addEventListener('mouseout', this.onMouseLeave);

    this._setLinkTooltip();
    this._handleErrorState();
  }

  disconnectedCallback() {
    this.removeEventListener('click', this._handleOnTooltipClick);
    this.removeEventListener('mouseover', this.onMouseOver);
    this.removeEventListener('mouseout', this.onMouseLeave);
    this._destroyLinkTooltip();
    super.disconnectedCallback();
  }

  get displayType() {
    return DISPLAY_TYPES.INLINE;
  }

  get isEditable() {
    return ELEMENTS.FieldElement.TYPES.EDITABLE.includes(
      // @ts-expect-error
      this.dataset?.type as Editor.Elements.FieldElementTypes,
    );
  }

  get isContentWrapper() {
    return this.isEditable;
  }

  get fieldType() {
    return this.dataset?.type;
  }

  get fieldLabel() {
    return this.dataset?.cpt;
  }

  get fieldReference() {
    return this.dataset?.ref;
  }

  get format() {
    return this.dataset?.format;
  }

  get selected() {
    return !!this.getAttribute('selected');
  }

  set selected(value) {
    if (value === true) {
      this.setAttribute('selected', 'true');
    } else {
      this.removeAttribute('selected');
    }
  }

  selectField() {
    this.setAttribute('selected', 'true');
  }

  deselectField() {
    this.removeAttribute('selected');
  }

  isTypeCaption() {
    return this.fieldType === ELEMENTS.FieldElement.TYPES.CAPTION;
  }

  isFieldLink() {
    return this.dataset?.link === 'true';
  }

  isBroken() {
    return this.dataset?.error === 'true';
  }

  onMouseOver() {
    if (!this.isBroken() && this.isFieldLink() && this.dataset?.type === 'cr') {
      this.linkTooltipElement = document.createElement('div');
      ReactDOM.render(
        <Provider store={store}>
          <Intl>
            <FormattedMessage id="GO_TO_ELEMENT" />
          </Intl>
        </Provider>,
        this.linkTooltipElement,
      );

      const fieldElementIsMultiline = this.getClientRects().length > 1;

      this.linkTooltipInstance = tippy(this, {
        content: this.linkTooltipElement,
        placement: fieldElementIsMultiline ? 'right' : 'top',
        trigger: 'manual',
        interactive: true,
        arrow: true,
        multiple: true,
        hideOnClick: false,
        //These options have to be accordingly to the popper version tippy is using (v1)
        popperOptions: {
          modifiers: {
            preventOverflow: { enabled: !fieldElementIsMultiline }, //If the fieldElement is in multilines the tooltip shall not repositioned
          },
        },
      });

      this.linkTooltipInstance.show();
    }
  }

  onMouseLeave() {
    if (!this.isBroken() && this.isFieldLink() && this.dataset?.type === 'cr') {
      this.linkTooltipInstance.hide();
    }
  }

  handleScroll() {
    document.getElementById('EditorRoot')?.removeEventListener('scroll', this.handleScroll);
    if (this.linkTooltipInstance) {
      this.linkTooltipInstance.hide();
    }
  }

  updateAttributes(options: Editor.Data.CrossReferences.PresentationTextOptionsType) {
    if (this.dataset) {
      if (options.format != null) {
        let value = options.format;
        if (value) {
          if (typeof options.format === 'object') {
            value = options.format;
          }
          this.dataset.format = value;
        }
      }

      if (options.link === true) {
        this.dataset.link = `${options.link}`;
      } else {
        delete this.dataset.link;
      }

      if (options.target != null) {
        this.dataset.ref = options.target;
      }

      if (
        options.label != null &&
        (this.dataset.type === ELEMENTS.FieldElement.TYPES.CAPTION ||
          this.dataset.type === ELEMENTS.FieldElement.TYPES.STYLE_REF)
      ) {
        this.dataset.cpt = options.label;
      }
    }
  }

  setContent(content: string | HTMLElement | any[]) {
    const childElements = [];
    if (content == null) {
      if (this.dataset) {
        this.dataset.broken = 'true';
      }
      const formatElementStyles: { [index: string]: string } = {};
      formatElementStyles.highlightcolor = 'FFF7CE';
      formatElementStyles.color = '2E2E4C';

      // TODO: call view factory instead
      const formatElement = DOMElementFactory.buildElement('format-element', formatElementStyles);
      if (formatElement instanceof BaseViewElement) {
        formatElement.preRender();
      }
      EditorDOMUtils.appendNode(this, formatElement);
      formatElement.appendChild(
        DOMElementFactory.createTextNode(' Broken cross-reference, reference source not found '),
      );
      childElements.push(formatElement);
    } else if (Array.isArray(content)) {
      // is array
    } else if (typeof content === 'string') {
      // is string
      childElements.push(DOMElementFactory.createTextNode(content));
    } else if (content.nodeType != null) {
      // is node
      childElements.push(content);
    }

    // remove childs
    for (let i = 0; i < this.childNodes.length; i++) {
      this.removeChild(this.childNodes[i]);
    }

    // append new content
    for (let j = 0; j < childElements.length; j++) {
      this.appendChild(childElements[j]);
    }
  }

  _handleOnTooltipClick() {
    if (!this.isBroken() && this.isFieldLink() && this.dataset?.type === 'cr') {
      EditorManager.getInstance().handleCrossReferenceSelected(this.dataset.ref);
    }
  }

  _handleOnTriggerTooltip(instance: any, event: MouseEvent) {
    const rects = [].slice.call(this.getClientRects());

    // We need to choose which rect to use. Check which rect the cursor landed on.
    let index = -1;
    rects.forEach((rect: DOMRect, i) => {
      if (
        event.clientY >= Math.floor(rect.top) &&
        event.clientY <= Math.ceil(rect.bottom) &&
        event.clientX >= Math.floor(rect.left) &&
        event.clientX <= Math.ceil(rect.right)
      ) {
        index = i;
      }
    });

    instance.reference.getBoundingClientRect = () => {
      // If the trigger event was `focus` instead, you can either choose a rectIndex or use
      // the whole boundingClientRect instead. The focus ring will help keyboard users.
      return event instanceof MouseEvent
        ? this.getClientRects()[index]
        : this.getBoundingClientRect();
    };
  }

  _setLinkTooltip() {
    this._destroyLinkTooltip();
  }

  _destroyLinkTooltip() {
    if (this.linkTooltipInstance) {
      this.linkTooltipInstance.hide();
      this.linkTooltipInstance.destroy(true);
      this.linkTooltipInstance = undefined;
    }
  }

  _handleErrorState() {
    if (this.dataset?.error) {
      this.style.fontWeight = 'bold';
      // this.style.background = '#FFFBE6';
      // this.style.border = '1px solid #FEE66B';
      // this.style.borderRadius = '0.5rem';
      // this.style.padding = '0.5rem 1rem';
      // this.style.whiteSpace = 'nowrap';
      // if (!this.errorIcon) {
      //   this.errorIcon = document.createElement('span');
      //   this.errorIcon.style.marginRight = '1rem';
      //   this.insertBefore(this.errorIcon, this.firstChild);
      //   ReactDOM.render(<Icon icon="EditorWarning" size={24} />, this.errorIcon);
      // }
    }
  }
}

if (!window.customElements.get(ELEMENTS.FieldElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.FieldElement.IDENTIFIER, FieldElement);
}
