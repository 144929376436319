import { createSelector } from '@reduxjs/toolkit';
import api from '_common/services/api/api';

const metadataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMetadataList: builder.query<MetadataList, void>({
      query: () => ({
        url: `/object/metadata/list`,
      }),
      providesTags: ['Metadata'],
    }),
  }),
});

// Export queries and mutations
export const { useGetMetadataListQuery } = metadataApi;

// Select data from where RTK Query stores the data from the list endpoint
export const selectMetadataList = metadataApi.endpoints.getMetadataList.select();

const getData = (state: RootState) => state.app.data;

export const selectAutomaticMetadata = createSelector(
  [getData, selectMetadataList, (_: any, id: ObjectId) => id],
  (data, { data: list }, id) => {
    if (!list) {
      return [];
    }

    const documentMetadata = data[id]?.metadata;
    return Object.entries(list)
      .filter(([id]) => list && list[id] && list[id].data_type === 'dynamic')
      .map(([id]) => ({
        label: list[id].name,
        value: documentMetadata ? documentMetadata[id] : '',
      }));
  },
);

export const selectManualMetadata = createSelector(
  [getData, selectMetadataList, (_: any, id: ObjectId) => id],
  (data, { data: list }, id) => {
    if (!list) {
      return [];
    }

    const documentMetadata = data[id]?.metadata;
    return Object.entries(list)
      .filter(([id]) => list && list[id] && list[id].data_type !== 'dynamic')
      .map(([id]) => ({
        label: list[id].name,
        value: documentMetadata ? documentMetadata[id] : '',
      }));
  },
);

export default metadataApi;
