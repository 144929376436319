import { ReactNode } from 'react';
import styles from './Toolbar.module.scss';

type ToolbarProps = {
  children: ReactNode;
};

const Toolbar = ({ children }: ToolbarProps) => {
  return <div className={styles.toolbar}>{children}</div>;
};

const Left = ({ children }: { children: ReactNode }) => {
  return <div className={styles.left}>{children}</div>;
};
const MiddleLeft = ({ children }: { children: ReactNode }) => {
  return <div className={styles.middleleft}>{children}</div>;
};
const Middle = ({ children }: { children: ReactNode }) => {
  return <div className={styles.middle}>{children}</div>;
};
const MiddleRight = ({ children }: { children: ReactNode }) => {
  return <div className={styles.middleright}>{children}</div>;
};
const Right = ({ children }: { children: ReactNode }) => {
  return <div className={styles.right}>{children}</div>;
};

export default Object.assign(Toolbar, {
  Left,
  MiddleLeft,
  Middle,
  MiddleRight,
  Right,
});
