import { Logger } from '_common/services';
import { Command } from '../Command';
import { JsonRange, SelectionFixer } from 'Editor/services/_Common/Selection';
import { ActionContext } from '../../ActionContext';
import { NodeUtils } from 'Editor/services/DataManager';

export class BackspaceCommand extends Command {
  private event: KeyboardEvent;

  constructor(context: Editor.Edition.ICommandArgs, event: KeyboardEvent) {
    super(context);
    this.event = event;
  }

  protected handleBackspaceAtStart(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    logger.info('handleBackspaceAtStart', ctx, elementData, elementPath);
    if (!this.context.DataManager || !ctx.baseData) {
      return false;
    }

    let structureModel = this.context.DataManager.structure.structureModel;

    if (!structureModel) {
      return false;
    }

    let previousData: Editor.Data.Node.Data | null = null;
    let previousPath: Editor.Selection.Path = [];
    let baseId: string | null = null;

    if (ctx.baseData.id && elementData.id === ctx.baseData.id) {
      // get preivous base block element
      const previousId = this.context.DataManager.structure.getPreviousChildId(ctx.baseData.id);
      if (previousId) {
        const previousModel = this.context.DataManager.nodes.getNodeModelById(previousId);
        if (previousModel) {
          previousData = previousModel?.selectedData();
          baseId = previousData?.id || null;
        }
      }
    } else if (elementPath.length > 0) {
      //
      const result = NodeUtils.getPreviousAncertor(ctx.baseData, elementPath);
      if (result) {
        previousData = result.data;
        previousPath = result.path;

        baseId = ctx.baseData.id || null;
      }
    }

    if (baseId && previousData) {
      // TODO:

      if (!NodeUtils.isBlockDeletableData(previousData)) {
        // check non deletable
        // TODO check if current is empty (???)

        ctx.range.updateRangePositions(
          {
            b: baseId,
            p: [...previousPath, 'childNodes', 0],
          },
          {
            b: baseId,
            p: [...previousPath, 'childNodes', previousData.childNodes?.length || 1],
          },
        );
        ctx.avoidNextNonCollapsedAction = true;
        return true;
      }
      // if (!NodeUtils.isBlockEditableData(previousData)) {
      //   // check non editable
      //   if (this.context.contentManipulator) {
      //     return this.context.contentManipulator.removeBlock(ctx, baseId);
      //   }
      // } else
      else if (
        !NodeUtils.isBlockEditableData(previousData) ||
        NodeUtils.isMultiBlockContainerData(previousData) ||
        NodeUtils.isFigureData(previousData) ||
        NodeUtils.isTableData(previousData)
      ) {
        // check non editable, figures, tables and multiblock containers
        ctx.range.updateRangePositions(
          {
            b: baseId,
            p: [...previousPath, 'childNodes', 0],
          },
          {
            b: baseId,
            p: [...previousPath, 'childNodes', previousData.childNodes?.length || 1],
          },
        );
        ctx.avoidNextNonCollapsedAction = true;
        return true;
      } else if (NodeUtils.isBlockTextData(previousData)) {
        // check text data

        // TODO
        // check empty

        // check last child elements

        // check mergeables
        if (NodeUtils.isBlockTextData(previousData)) {
          ctx.range.updateRangePositions(
            {
              b: baseId,
              p: [...previousPath, 'childNodes', previousData.childNodes?.length || 0],
            },
            ctx.range.end,
          );
        } else {
        }
        return true;
      }
    }

    return false;
  }

  protected handleNonTextElement(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    if (NodeUtils.isPathAtContentStart(elementData, ctx.range.start.p)) {
      return this.handleBackspaceAtStart(ctx, elementData, elementPath);
    } else {
      // TODO: check if block is deletable

      let startPath: Editor.Selection.Path = ['childNodes', 0];
      let endPath: Editor.Selection.Path = ['childNodes', elementData.childNodes?.length || 0];
      ctx.range.updateRangePositions(
        { b: ctx.range.start.b, p: startPath },
        { b: ctx.range.start.b, p: endPath },
      );
      ctx.avoidNextNonCollapsedAction = true;

      return true;
    }
  }

  protected handleTextElement(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    if (
      !ctx.baseModel ||
      !ctx.baseData ||
      !this.context.selection?.modifiers ||
      !this.context.DataManager ||
      !this.context.stylesHandler
    ) {
      return false;
    }

    if (NodeUtils.isPathAtContentStart(elementData, ctx.range.start.p)) {
      // selection at start
      if (this.context.DataManager.numbering.isListElement(ctx.baseModel.id)) {
        // handle list
        this.context.DataManager.numbering.removeBlocksFromList([ctx.baseModel.id]);
        return true;
      } else {
        return this.handleBackspaceAtStart(ctx, elementData, elementPath);
      }
    } else {
      // selection at middle or end

      // handle inline non editables
      let closestNonEditable = NodeUtils.closestAncestorOfType(
        ctx.baseData,
        ctx.range.start.p,
        NodeUtils.INLINE_NON_EDITABLE_TYPES,
      );

      if (closestNonEditable) {
        const offset = Number(closestNonEditable.path[closestNonEditable.path.length - 1]);

        if (isNaN(offset) || !ctx.baseData.id) {
          return false;
        }

        let startPath = [...closestNonEditable.path];
        let endPath = [...closestNonEditable.path];
        endPath[endPath.length - 1] = offset + 1;

        ctx.range.updateRangePositions(
          {
            b: ctx.baseData.id,
            p: startPath,
          },
          {
            b: ctx.baseData.id,
            p: endPath,
          },
        );

        ctx.avoidNextNonCollapsedAction = true;
      } else {
        // normalize text selection
        SelectionFixer.collapsedTextSelection(
          ctx.range,
          {
            suggestionMode: this.context.editionMode === 'SUGGESTIONS',
            forceWrapAsText: true,
            isBackspace: true,
          },
          this.context.DataManager,
        );

        this.context.selection.modifiers.modify(ctx.range, 'expand', 'character', 'backward');
      }
      return true;
    }

    // return false;
  }

  protected handleCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      return false;
    }

    ctx.setModelAndData(baseModel, baseData);

    const result = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, [
      ...NodeUtils.BLOCK_EDITABLE_TYPES,
    ]);

    if (result) {
      if (NodeUtils.isBlockTextData(result.data)) {
        // text elements
        return this.handleTextElement(ctx, result.data, result.path);
      } else {
        // multi block container elements
        // legacy figure elements
        // table elements
        // non editable elements
        return this.handleNonTextElement(ctx, result.data, result.path);
      }
    }

    return false;
  }

  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager) {
      return false;
    }

    if (this.context.contentManipulator) {
      return this.context.contentManipulator.removeContent(ctx);
    }

    return false;
  }

  async exec(): Promise<Editor.Edition.ICommand> {
    if (this.debug) {
      Logger.trace('BackspaceCommand exec', this);
    }

    if (!this.context.DataManager || !this.context.DataManager.selection) {
      return this;
    }

    //TODO:
    // fix block selection?

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (!jsonRange) {
      return this;
    }

    let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange);

    // handle collapsed selection
    if (ctx.range.collapsed) {
      if (!this.handleCollapsedSelection(ctx)) {
        return this;
      }
    }

    // handle non collapsed selection
    if (!ctx.range.collapsed && !ctx.avoidNextNonCollapsedAction) {
      if (!this.handleNonCollapsedSelection(ctx)) {
        return this;
      }
    }

    // handle create suggestions???
    this.handleSuggestionsUpdate(ctx);

    // apply new selection
    if (this.context.DataManager?.selection) {
      // TEMP: flag last selection
      this.context.DataManager.selection.history.flag('debounce');
      this.context.DataManager.selection.setUserSelection([ctx.range.serializeToRangeData()]);
    }

    // create patch
    this.context.DataManager.history.createPatch();

    return this;
  }
}
