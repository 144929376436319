import { useIntl } from 'react-intl';
import { Tooltip, Icon } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';

import IEnvisionLog from './logo.svg';

import styles from './IntegrationiEnvisionLogo.module.scss';
import { selectIsIEnvision } from 'App/redux/appSlice';

const IntegrationiEnvisionLogo = () => {
  const intl = useIntl();
  const isiEnvision = useSelector(selectIsIEnvision);
  const extra = useSelector((state) => state.app.information.extra);

  if (!isiEnvision) {
    return null;
  }

  return (
    <Tooltip
      content={intl.formatMessage({ id: 'GO_BACK_TO_IENVISON' })}
      testId="go-back-to-ienvision-tooltip"
    >
      <div
        className={styles.root}
        onClick={() => {
          if (extra) {
            window.location.href = `${extra.source}`;
          }
        }}
      >
        <Icon size={16} icon="ArrowLeft" />
        <img src={IEnvisionLog} alt="iEnvision logo" />
      </div>
    </Tooltip>
  );
};

export default IntegrationiEnvisionLogo;
