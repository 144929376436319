import { Command } from '../Command';
import { JsonRange, SelectionFixer } from 'Editor/services/_Common/Selection';
import { ReduxInterface } from 'Editor/services';
import { ActionContext } from '../../ActionContext';

export class AddTemporaryCommentCommand extends Command {
  async exec(): Promise<Editor.Edition.ICommand> {
    try {
      let allowCreateComment = true;

      if (
        !this.context.DataManager ||
        !this.context.DataManager.selection ||
        !this.context.selection?.modifiers
      ) {
        return this;
      }

      const rangeData = this.context.DataManager.selection.current;
      const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

      if (!jsonRange) {
        return this;
      }

      const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
      const baseData = baseModel?.selectedData();

      if (!baseModel || !baseData) {
        return this;
      }

      if (baseData.id) {
        allowCreateComment = this.context.DataManager?.permissions.canUserPerform(
          baseData.id,
          'comment',
        );

        let ctx: Editor.Edition.ActionContext = new ActionContext(jsonRange);

        if (ctx.range.collapsed) {
          this.context.selection.modifiers.modify(jsonRange, 'move', 'word', 'backward');
          this.context.selection.modifiers.modify(jsonRange, 'expand', 'word', 'forward');
        } else {
          // fix non collapse selection
          SelectionFixer.nonCollapsedTextSelection(ctx.range, {}, this.context.DataManager);
        }

        // TODO add comments on many table cells

        if (allowCreateComment) {
          const tempCommentReference = EditorDOMUtils.generateUUID();

          const jsonRanges: Editor.Selection.RangeData[] = JsonRange.splitRangeByBlocks(
            this.context.DataManager,
            jsonRange,
            ['p'],
          );

          //TODO to apply selection after creating temp comment; needs further discussion;
          // apply new selection
          // if (this.context.DataManager?.selection) {
          //   this.context.DataManager.selection.history.flag('debounce');
          //   this.context.DataManager.selection.setUserSelection([ctx.range.serializeToRangeData()]);
          // }

          await this.context.DataManager?.comments?.addTemporaryComment(
            tempCommentReference,
            jsonRanges,
          );

          ReduxInterface.openTemporaryCommentCard({
            reference: tempCommentReference,
            level0: baseData.id,
          });
        } else {
          throw new Error('User has no permissions to comment selection');
        }
      }
    } catch (error) {
      logger.captureException(error);
    }
    return this;
  }
}
