import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, RadioButton } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';

import { closeModal } from '_common/modals/ModalsSlice';
import EditorManager from 'Editor/services/EditorManager';

const MODAL = 'DeleteCellsModal';

const DeleteCellsModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const OPTIONS = [
    { label: intl.formatMessage({ id: 'SHIFT_CELLS_LEFT' }), id: 'scl' },
    { label: intl.formatMessage({ id: 'SHIFT_CELLS_UP' }), id: 'scu' },
    { label: intl.formatMessage({ id: 'DELETE_ENTIRE_ROW' }), id: 'der' },
    { label: intl.formatMessage({ id: 'DELETE_ENTIRE_COLUMN' }), id: 'dec' },
  ];
  const [selected, setSelected] = useState(OPTIONS[0].id);

  const close = () => {
    dispatch(closeModal('DeleteCellsModal'));
    setSelected(OPTIONS[0].id);
  };

  const handleDeleteCells = () => {
    const manager = EditorManager.getInstance();
    switch (selected) {
      case 'scl':
        manager.deleteCells('SHIFT_LEFT');
        break;
      case 'scu':
        manager.deleteCells('SHIFT_UP');
        break;
      case 'der':
        manager.deleteRows();
        break;
      case 'dec':
        manager.deleteColumns();
        break;
      default:
        break;
    }
    notify({
      type: 'success',
      title: 'CELLS_REMOVED',
      message: 'CELLS_REMOVED_MESSAGE',
    });
    close();
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="60rem" testId="delete-cells">
      <Modal.Header onClose={close}>
        <FormattedMessage id="DELETE_CELLS" />
      </Modal.Header>
      <Modal.Body>
        {OPTIONS.map(({ id, label }) => {
          return (
            <div style={{ marginBottom: '1rem' }}>
              <RadioButton
                key={id}
                checked={selected === id}
                onChange={() => setSelected(id)}
                size="small"
                testId={`delete-cells-modal-${label
                  .toLowerCase()
                  .replaceAll(' ', '-')}-radio-button`}
              >
                {label}
              </RadioButton>
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="delete-cells-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleDeleteCells}
          testId="delete-cells-modal-submit-button"
        >
          <FormattedMessage id="DELETE_CELLS" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCellsModal;
