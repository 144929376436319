import { Button, Icon } from 'dodoc-design-system';
import { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useObject, useSelector } from '_common/hooks';
import { setObjectPreview } from './ObjectPreviewSlice';
import ImagePreview from './ImagePreview';
import DOCXPreview from './DOCXPreview';
import PDFPreview from './PDFPreview';
import styles from './ObjectPreview.module.scss';
import { useLazyDownloadQuery } from '_common/services/api/FileApi';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import WopiActions from './WopiActions';
import { navigateToFile } from 'router/history';
import { notify } from '../ToastSystem';
import { useGetTenantSettingsQuery } from '_common/services/api/authority';

type ObjectPreviewType = 'image' | 'docx' | 'pdf' | undefined;

const ICONS = {
  image: 'EditorImage',
  docx: 'FileWord',
  pdf: 'FilePDFGrey',
} as const;

type PreviewProps = {
  object: doDOC.File;
  type: Required<ObjectPreviewType>;
};

const Preview = ({ object, type }: PreviewProps) => {
  switch (type) {
    case 'image':
      return <ImagePreview object={object} />;
    case 'docx':
      return <DOCXPreview object={object} />;
    case 'pdf':
      return <PDFPreview object={object} />;
  }

  return null;
};

const ObjectPreview = () => {
  const dispatch = useDispatch();

  const objectId = useSelector((state) => state.objectPreview.id);
  const currentUserId = useSelector((state) => state.auth.userId);

  const [downloadBlob] = useLazyDownloadQuery();
  const { data: tenantSettings } = useGetTenantSettingsQuery();

  const { data } = useObject(
    { object_id: objectId ?? '', object_type: 'file' },
    { skip: !objectId },
  );

  const type = useMemo(() => {
    let type: ObjectPreviewType = undefined;

    if (data?.type && data.mime?.type) {
      switch (data.mime.type) {
        case 'image/png':
        case 'image/jpeg':
        case 'image/jpg':
          // add remaining image mime types
          type = 'image';
          break;
        case 'application/pdf':
          type = 'pdf';
          break;
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          type = 'docx';
          break;
        default:
          navigateToFile(data.id);
          notify({
            type: 'success',
            title: 'ELEMENT_TYPE_DOWNLOADED',
            titleValues: { elementType: data.type.charAt(0).toUpperCase() + data.type.slice(1) },
            message: 'ELEMENT_TYPE_SUCCESSFULLY_DOWNLOADED',
            messageValues: { elementTitle: data.name },
          });

          dispatch(setObjectPreview({ id: null }));
      }
    }

    return type;
  }, [data]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      close();
    }
  };

  const close = () => {
    dispatch(setObjectPreview({ id: null }));
  };

  const download = async () => {
    if (data) {
      const { data: blob } = await downloadBlob({ object_id: data.id });
      if (blob) {
        const name = data.name;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    }
  };

  const open = () => {
    if (data) {
      dispatch(
        openAndUpdateModal({
          modal: 'ConvertToModal',
          data: {
            objectIds: [data.id],
            openAfterConverting: true,
          },
        }),
      );
    }
  };

  if (data?.type === 'file' && type) {
    return (
      <div className={styles.root} data-testid="object-preview-root">
        <div className={styles.topbar} data-testid="object-preview-topbar">
          <div className={styles.buttons} data-testid="object-preview-buttons">
            {type === 'docx' && (
              <Button
                variant="link"
                size="medium"
                icon="Open"
                margin="0 0.5rem 0 0"
                onClick={open}
                testId="object-preview-docx-open"
              >
                <FormattedMessage id="OPEN_IN_EDITOR" />
              </Button>
            )}
            {type === 'pdf' &&
              //Added due to bug report: Only owners should able to convert file to doPDF
              data.owner === currentUserId && (
                <Button
                  variant="link"
                  size="medium"
                  icon="Open"
                  margin="0 0.5rem 0 0"
                  onClick={open}
                  testId="object-preview-pdf-open"
                  disabled={!tenantSettings?.['product.pdf_reviewer']}
                >
                  <FormattedMessage id="OPEN_IN_ANNOTATOR" />
                </Button>
              )}
            <Button
              variant="link"
              size="medium"
              icon="Download"
              onClick={download}
              testId="object-preview-download-button"
            >
              <FormattedMessage id="storage.actionBar.actions.download" />
            </Button>
            {data.wopi && <WopiActions object={data} />}
          </div>
          <div className={styles.title} data-testid="object-preview-title">
            <Icon margin="0 1rem 0 0" size={24} icon={ICONS[type]} />
            {data.name}
          </div>
          <div className={styles.close} data-testid="object-preview-close">
            <Button
              variant="link"
              size="medium"
              icon="CloseGrey"
              onClick={close}
              testId="object-preview-close-icon-button"
            />
          </div>
        </div>
        <div className={styles.background} onClick={close} data-testid="object-preview-background">
          <Preview object={data} type={type} />
        </div>
      </div>
    );
  }

  return null;
};

export default ObjectPreview;
