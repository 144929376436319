import { useState, useEffect, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { useParams } from 'react-router';
import { Dropzone } from 'dodoc-design-system';

import {
  navigateToEditor,
  // navigateToFile,
  navigateToMyFiles,
  navigateToObject,
} from 'router/history';

import { useEffectOnUpdate } from '_common/hooks';
import {
  EmptyFilteredState,
  ErrorView,
  FilterDisplay,
  InformationPanel,
  InteractionController,
  IntlErrorBoundary,
  ObjectPreview,
} from '_common/components';
import { InstanceService } from '_common/services';

import { setCleanState, setCurrentAppPage } from 'App/redux/appSlice';
import { loadStorageData, uploadFile, toggleInfoPanel } from './StoragePageSlice';
import { updateMiniExplorer } from '_common/components/MiniExplorer/miniExplorerSlice';
import { listObjects } from '_common/components/Table/TableSlice';
import { setActiveTab, setTriggerPage } from 'Search/redux/SearchPageSlice';
import { useFilterSelector } from '_common/components/Filters/FilterSlice';
// import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { ThunksTable } from '_common/components/Table2';
import Cell, { objectIsOverdue } from '_common/components/Table2/Cells';
import Footer from '_common/components/Table2/Footer/Footer';
import SelectionCounter from '_common/components/Table2/Footer/SelectionCounter';
import StorageEmptyState from './components/StorageEmptyState/StorageEmptyState';
import Header from './Header/Header';

import styles from './StoragePage.module.scss';

import type {
  ColumnProps,
  TableProps,
} from 'dodoc-design-system/build/types/Components/Table/Table';
import { setObjectPreview } from '_common/components/ObjectPreview/ObjectPreviewSlice';

const PAGE_IDENTITY = 'storage';

/**
 * Storage Page
 */
const StoragePage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const params: { id: ObjectId; type: ObjectType } = useParams();
  const { selectFilterParams } = useFilterSelector();

  const data = useSelector((state) => state.app.data);
  const list = useSelector((state) => state.table.identity.storage.list);
  const current = useSelector((state) => data[state.storage.current ?? '']);
  const breadcrumb = useSelector((state) => state.storage.breadcrumb);
  const error = useSelector((state) => state.app.error);
  const infoPanelOpen = useSelector((state) => state.storage.infoPanelOpen);
  const tableListLength = useSelector((state) => state.table.identity.storage.list.length);
  const started = useSelector((state) => state.onboarding.started.explorer);
  const [loading, setLoading] = useState(true);

  // const userId = useSelector((state) => state.auth.userId);
  const filterParams = useSelector((state) => selectFilterParams(state, state.filters.storage));
  const filters = useMemo(() => {
    return {
      ...JSON.parse(localStorage.getItem(PAGE_IDENTITY) ?? '{}'),
      ...filterParams,
    };
  }, [filterParams]);

  const [resetScroll, setResetScroll] = useState(false);

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'storage.browserHeader.name' }),
        orderable: true,
        width: 86,
        flex: true,
      },
      {
        id: 'dueDate',
        width: 48,
      },
      {
        id: 'status',
        header: intl.formatMessage({ id: 'storage.browserHeader.status' }),
        orderable: true,
        width: 136,
      },
      {
        id: 'time.modification',
        header: intl.formatMessage({ id: 'storage.browserHeader.dateModified' }),
        orderable: true,
        width: 160,
        body: (data) => {
          return data['time.modification'];
        },
      },
      {
        id: 'sharedWith',
        header: intl.formatMessage({ id: 'storage.browserHeader.sharedWith' }),
        width: 160,
      },
    ];
  }, []);

  useEffect(() => {
    if (resetScroll) {
      setResetScroll(false);
    }
  }, [resetScroll]);

  //#region Table object listing handlers
  const fetchObjects = useCallback(
    async (parameters: any) => {
      const instanceService = new InstanceService();

      let { id, type }: { id: ObjectId; type: ObjectType } = params;

      if (!id || !type) {
        const { data } = await instanceService.getPersonalSpaceInfo();
        //@ts-expect-error Missing endpoint type "/api/object/space/get"
        id = data.id;
        //@ts-expect-error Missing endpoint type "/api/object/space/get"
        type = data.type;
      }

      return instanceService.listObject(id, type, parameters);
    },
    [params.id, params.type],
  );

  useEffect(() => {
    dispatch(setCleanState());
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, [started]);

  useEffectOnUpdate(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'FILTER',
        request: { offset: 0, ...filters },
      }),
    );
  }, [fetchObjects, filters]);
  //#endregion

  useEffect(() => {
    dispatch(updateMiniExplorer({ identity: PAGE_IDENTITY }));
    return () => {
      dispatch(updateMiniExplorer({ identity: undefined }));
    };
  }, []);

  useEffect(() => {
    const { type } = params;

    dispatch(setTriggerPage(type ? `storage/${type}` : 'storage'));
    dispatch(setActiveTab('parent'));
  }, [params.type]);

  useEffect(() => {
    if (current) {
      if (current.type === 'folder') {
        // Current object is a folder and not a space root
        if (breadcrumb.parents.length > 0) {
          // Folder has parents that the user can access
          const root: {
            id: string;
            personal: boolean;
            type?: string;
          } = breadcrumb.parents[0];
          if (root.type === 'folder') {
            // Root object is not a space, means user has no access to the real root -> shared
            dispatch(setCurrentAppPage('/shared'));
          } else if (root.personal) {
            // Root is not a folder and also personal -> my files (beware superusers)
            dispatch(setCurrentAppPage('/storage'));
          } else {
            // Root is not a folder and not personal
            dispatch(setCurrentAppPage('/storage/spaces'));
          }
        } else {
          // User can't access folder parents -> shared
          dispatch(setCurrentAppPage('/shared'));
        }
      } else if (current.type === 'space' && current.personal) {
        // Current object is a personal space -> my files (beware superusers)
        dispatch(setCurrentAppPage('/storage'));
      } else {
        // Current is a space -> spaces
        dispatch(setCurrentAppPage('/storage/spaces'));
      }
    }
  }, [current]);

  useEffect(() => {
    const promise = dispatch(
      loadStorageData({
        id: params.id,
        type: params.type,
        parameters: {
          offset: 0,
          size: 200,
          ...filters,
        },
      }),
    );
    promise.then((resp) => {
      if (resp.meta.requestStatus !== 'rejected') {
        setLoading(false);
      }
    });

    return () => {
      promise?.abort();
    };
  }, [params.id, params.type]);

  const dispatchUploadFile = (_: React.DragEvent<HTMLDivElement>, files: File[]) => {
    const parameterName = () => {
      if (params.type === 'space') {
        return 'space';
      } else return 'parent';
    };

    dispatch(
      uploadFile({
        identity: PAGE_IDENTITY,
        parameters: {
          files: [...files],
          description: '',
          [parameterName()]: params.id,
        },
      }),
    );

    setResetScroll(true);
  };

  const handleCloseInfoPanel = () => {
    dispatch(toggleInfoPanel());
  };

  const handleRowDoubleClick: TableProps<{ id: string }[]>['onRowDoubleClick'] = ({
    data: object,
    originalEvent,
  }) => {
    const currentObject = data[object.id];

    if (currentObject.status === 'processing' || currentObject.status === 'broken') {
      return;
    }

    let type = currentObject.type;
    let id = currentObject.id;
    switch (type) {
      case 'document':
        navigateToEditor(id);
        break;
      case 'file':
        dispatch(setObjectPreview({ id: currentObject.id }));
        originalEvent.preventDefault();
        break;
      case 'folder':
        navigateToObject('folder', id);
        break;
      case 'dopdf':
        window.open(`/pdf/${id}`, '_blank');
        break;
      case 'presentation':
        window.open(`/presentation/${id}`, '_blank');
        break;
      default:
        break;
    }
  };

  const hasError = error.status === 400 || error.status === 403 || error.status === 404;

  const value = useMemo(() => {
    return list?.map((objectId) => {
      const object = data[objectId];
      const isProcessing = object.status === 'processing';
      return {
        id: object.id,
        hasError: objectIsOverdue(object),
        name: (
          <Cell testId={`${objectId}-name-column`} ellipsis>
            <Cell.ObjectName object={object} testId={`storage-${object.id}-object-name`} />
          </Cell>
        ),
        dueDate: (
          <Cell testId={`${objectId}-due-date-column`} disabled={isProcessing}>
            <Cell.ObjectDueDate object={object} testId={`storage-${object.id}-object-due-date`} />
          </Cell>
        ),
        status: (
          <Cell testId={`${objectId}-status-column`} disabled={isProcessing}>
            <Cell.ObjectStatus object={object} testId="object-status" />
          </Cell>
        ),
        'time.modification': (
          <Cell testId={`${objectId}-date-modified-column`} disabled={isProcessing}>
            <Cell.DateTime
              date={{ date: object.time.modification }}
              time={{ time: object.time.modification }}
            />
          </Cell>
        ),
        sharedWith: (
          <Cell testId={`${objectId}-shared-column`} disabled={isProcessing}>
            <Cell.Collaborators object={object} />
          </Cell>
        ),
      };
    });
  }, [list, data]);

  // ----------------------------------------------------------
  // ----------------------- Renders -------------------------

  if (!current) {
    return null;
  }

  const renderEmptyState = () => <StorageEmptyState current={current} filters={filters} />;

  const renderContent = () => {
    if (hasError) {
      return <ErrorView error={error} onClick={navigateToMyFiles} />;
    }

    //If has filters and no matching objects to display
    if (filterParams.filter_fields.length > 0 && tableListLength === 0) {
      return <EmptyFilteredState identity="storage" />;
    }
    return (
      <div className={styles.content}>
        <Dropzone onDrop={dispatchUploadFile} noClick testId="storage-page-dropzone">
          <InteractionController environment="explorer" style={{ width: '100%', height: '100%' }}>
            <div className={styles.listView}>
              <ThunksTable
                identity={PAGE_IDENTITY}
                columns={columns}
                value={value}
                lazyLoading
                loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
                renderFooter={() => (
                  <Footer>
                    <SelectionCounter identity={PAGE_IDENTITY} />
                  </Footer>
                )}
                renderEmptyState={renderEmptyState}
                fetchObjects={fetchObjects}
                onRowDoubleClick={handleRowDoubleClick}
                testId="storage-page"
              />
            </div>
          </InteractionController>
        </Dropzone>

        <InformationPanel isOpen={infoPanelOpen} onClose={handleCloseInfoPanel} />
      </div>
    );
  };

  return (
    <>
      <div id="WelcomeScreen" />
      <div className={styles.root} id="ExplorerStoragePage">
        <div className={styles.header}>
          <IntlErrorBoundary size="small" margin="0 0 0 7rem">
            <Header
              hasError={hasError}
              loading={loading}
              setResetScroll={setResetScroll}
              infoPanelOpen={infoPanelOpen}
              handleCloseInfoPanel={handleCloseInfoPanel}
            />
          </IntlErrorBoundary>
        </div>
        <FilterDisplay identity="storage" margin="3rem 0 0 6rem" />
        {renderContent()}
        <ObjectPreview />
      </div>
    </>
  );
};

export default StoragePage;
