import React, { useState, useMemo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, Select, Button, Icon, Divider, Modal } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import styles from './EditCrossReferencesModal.module.scss';
import EditorManager from 'Editor/services/EditorManager';
import { clearFilterIdentity } from '_common/components/Filters/FilterSlice';

import { FilterPopover } from '_common/components';

const MODAL = 'EditCrossReferencesModal';

const EditCrossReferencesModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters.editorDocCaption);
  const data = useSelector((state) => state.editor.toc.data);
  const list = useSelector((state) => Object.keys(state.editor.toc.data));
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const insertAsLink = useSelector((state) => state.modals[MODAL].insertAsLink);
  const crossReferenceId = useSelector((state) => state.modals[MODAL].crossReferenceId);
  const defaultFilters = filters;
  const captionsFormats = [
    {
      value: 'lnt',
      label: intl.formatMessage({ id: 'ENTIRE_CAPTION' }),
    },
    {
      value: 'ln',
      label: intl.formatMessage({ id: 'ONLY_LABEL_AND_NUMBER' }),
    },
    {
      value: 't',
      label: intl.formatMessage({ id: 'ONLY_CAPTION_TEXT' }),
    },
    {
      value: 'pn',
      label: intl.formatMessage({ id: 'PAGE_NUMBER' }),
    },
    {
      value: 'cab',
      label: intl.formatMessage({ id: 'ABOVE_BELOW' }),
    },
  ];
  const headingsFormats = [
    {
      value: 't',
      label: intl.formatMessage({ id: 'HEADING_TEXT' }),
    },
    {
      value: 'hn',
      label: intl.formatMessage({ id: 'HEADING_NUMBER' }),
    },
    {
      value: 'hnn',
      label: intl.formatMessage({ id: 'HEADING_NUMBER_NO_CONTEXT' }),
    },
    {
      value: 'hnf',
      label: intl.formatMessage({ id: 'HEADING_NUMBER_FULL_CONTEXT' }),
    },
    {
      value: 'hab',
      label: intl.formatMessage({ id: 'ABOVE_BELOW' }),
    },
  ];
  const [captionSelected, setCaptionSelected] = useState<{ value: string; label: string }>({
    value: '',
    label: '',
  });
  const [typeSelected, setTypeSelected] = useState({
    value: 'heading',
    label: intl.formatMessage({ id: 'HEADING' }),
  });
  const [formatSelected, setFormatSelected] = useState(headingsFormats[0]);
  const [checkInsertAsLink, setCheckInsertAsLink] = useState(insertAsLink);

  useEffect(() => {
    if (isOpen) {
      EditorManager.getInstance().initializeCrossReferences();
    }

    return () => {
      EditorManager.getInstance().destroyCrossReferences();
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setTypeSelected(
        crossReferenceId &&
          data[crossReferenceId] &&
          (data[crossReferenceId].label === 'figure' ||
            data[crossReferenceId].label === 'table' ||
            data[crossReferenceId].label === 'equation')
          ? {
              value: 'caption',
              label: intl.formatMessage({ id: 'editor.toolbar.contentStructure.CAPTION' }),
            }
          : { value: 'heading', label: intl.formatMessage({ id: 'HEADING' }) },
      );
    }
    setCheckInsertAsLink(insertAsLink);
  }, [crossReferenceId, data, isOpen]);

  useEffect(() => {
    if (typeSelected.value === 'heading') {
      dispatch(clearFilterIdentity({ identity: 'editorDocCaption', noFeedback: true }));
    }
    setFormatSelected(typeSelected.value === 'caption' ? captionsFormats[0] : headingsFormats[0]);
  }, [typeSelected]);
  useEffect(() => {
    if (crossReferenceId && data[crossReferenceId] && isOpen) {
      const crossReferenceFormat = () => {
        if (typeSelected.value === 'caption') {
          //@ts-expect-error state.editor.toc.data has to be typed
          if (data[crossReferenceId].content === data[crossReferenceId].p_content.ln) {
            return { value: 'ln', label: intl.formatMessage({ id: 'ONLY_LABEL_AND_NUMBER' }) };
          }
          if (data[crossReferenceId].content === data[crossReferenceId].p_content.t) {
            return {
              value: 't',
              label: intl.formatMessage({ id: 'ONLY_CAPTION_TEXT' }),
            };
          }
          return {
            value: 'lnt',
            label: intl.formatMessage({ id: 'ENTIRE_CAPTION' }),
          };
        }
        if (typeSelected.value === 'heading') {
          if (
            data[crossReferenceId].content.includes(`${data[crossReferenceId].p_content.hn}`) &&
            data[crossReferenceId].p_content.t === ''
          ) {
            return { value: 'hn', label: intl.formatMessage({ id: 'HEADING_NUMBER' }) };
          }
          if (data[crossReferenceId].content.includes(data[crossReferenceId].p_content.t)) {
            return {
              value: 't',
              label: intl.formatMessage({ id: 'HEADING_TEXT' }),
            };
          }
          return {
            value: 't',
            label: intl.formatMessage({ id: 'HEADING_TEXT' }),
          };
        }
        return { value: '', label: '' };
      };
      setCaptionSelected({
        value: data[crossReferenceId].target,
        label: data[crossReferenceId].content,
      });
      setFormatSelected(crossReferenceFormat());
    } else {
      setCaptionSelected({ value: '', label: '' });
    }
  }, [crossReferenceId, data, isOpen]);

  const captionsList: { value: string; label: string }[] = useMemo(() => {
    const showAll = (Object.keys(defaultFilters) as Array<keyof typeof defaultFilters>).every(
      (filter) => !defaultFilters[filter],
    );
    return list
      .filter((id) => {
        if (data[id]) {
          if (typeSelected.value === 'heading') {
            return data[id].type === 'p' && data[id].st !== 't';
          }
          if (typeSelected.value !== 'heading') {
            if (showAll) {
              return data[id].type === 'f';
            }
            const label = data[id].label;
            if (
              label &&
              label !== 'citation' &&
              filters.captionLabel?.map((filterValue) => filterValue.value).includes(label)
            ) {
              return true;
            }
          }
        }
        return false;
      })
      .reduce((previous, current) => {
        //@ts-expect-error state.editor.toc.data has to be typed
        previous.push({ label: data[current].content, value: data[current].target });
        return previous;
      }, []);
  }, [list, data, typeSelected.value, filters]);

  const selectCaption = (option: { value: string; label: string }) => {
    setCaptionSelected(option);
  };

  const handleInsert = () => {
    const options: Editor.Data.CrossReferences.PresentationTextOptionsType = {
      label: typeSelected.value,
      link: checkInsertAsLink,
      //@ts-expect-error fix types
      format: formatSelected.value,
      //@ts-expect-error fix types
      target: captionSelected?.value,
    };
    close();
    EditorManager.getInstance().editCrossReference(options);
    notify({
      type: 'success',
      title: 'CROSS_REFERENCES_EDITED',
      message: 'CROSS_REFERENCE_WAS_SUCCESSFULLY_EDITED',
    });
  };

  const handlePreviewFromFormat = () => {
    if (captionSelected && captionSelected.label) {
      if (
        typeSelected.value === 'heading' &&
        formatSelected.value === 'hn' &&
        !EditorManager.getInstance().hasStyleAList(data[captionSelected.value.split(':')[0]].st)
      ) {
        return undefined;
      }
      return (
        EditorManager.getInstance().getCrossReferencePresentationText({
          target: captionSelected.value,
          format: formatSelected.value,
        }) || undefined
      );
    }
    return intl.formatMessage({ id: 'CROSS_REFERENCE_PREVIEW' });
  };

  const validateFormatReference = () => {
    if (!captionSelected) {
      return true;
    }
    return !handlePreviewFromFormat();
  };

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const getEmptyTitle = () => {
    if (typeSelected.value === 'heading') {
      return 'NO_HEADINGS';
    }
    if (list.length === 0) {
      return 'NO_CAPTIONS';
    }
    return 'NO_CAPTIONS_FOUND';
  };

  const getEmptyMessage = () => {
    if (typeSelected.value === 'heading') {
      return 'NO_HEADINGS_MESSAGE';
    }
    if (list.length === 0) {
      return 'NO_CAPTIONS_MESSAGE';
    }
    return 'NO_CAPTIONS_FOUND_MESSAGE';
  };

  const defaultLabels = [
    {
      value: 'caption',
      label: intl.formatMessage({ id: 'editor.toolbar.contentStructure.CAPTION' }),
    },
    { value: 'heading', label: intl.formatMessage({ id: 'HEADING' }) },
  ];

  return (
    <Modal open={!!isOpen} onClose={close} width="70rem" testId="edit-cross-references">
      <Modal.Header onClose={close}>
        <FormattedMessage id="EDIT_CROSS_REFERENCE" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.preview}>
          <div className={styles.title}>{intl.formatMessage({ id: 'PREVIEW' })}</div>
          <Divider margin="0 0 2rem 0" />
          <div
            className={`${styles.previewCrossReference} ${
              validateFormatReference() && styles.noSupport
            }`}
          >
            {validateFormatReference() ? (
              <div className={styles.warningState}>
                <Icon icon="Warning" size={32} />
                <div className={`${styles.text} ${styles.empty}`}>
                  {intl.formatMessage({ id: 'EMPTY_CROSS_REFERENCE' })}
                </div>
              </div>
            ) : (
              <div
                title={handlePreviewFromFormat()}
                className={`${
                  captionSelected && captionSelected.label ? styles.text : styles.noPreview
                }`}
              >
                {handlePreviewFromFormat()}
              </div>
            )}
          </div>
        </div>
        <div className={styles.content}>
          <div style={{ marginBottom: '3rem' }}>
            <div className={styles.title}>{intl.formatMessage({ id: 'REFERENCE_TYPE' })}</div>
            <Divider margin="0 0 2rem 0" />
            <Select
              options={defaultLabels}
              value={typeSelected}
              onChange={setTypeSelected}
              size="large"
              width="100%"
              testId="reference-type"
            />
          </div>
          <div style={{ marginBottom: '4rem' }}>
            <div className={styles.title}> {intl.formatMessage({ id: 'REFERENCE_FORMAT' })}</div>
            <Divider margin="0 0 2rem 0" />
            <Select
              options={typeSelected.value === 'caption' ? captionsFormats : headingsFormats}
              size="large"
              value={formatSelected}
              onChange={setFormatSelected}
              width="100%"
              testId="reference-format"
            />
          </div>
          <div style={{ marginBottom: '3rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div className={styles.title}>{intl.formatMessage({ id: 'DOCUMENT_CAPTIONS' })}</div>
              {typeSelected.value === 'caption' && (
                <FilterPopover
                  identity="editorDocCaption"
                  popperSettings={{ placement: 'bottom-start' }}
                  toggleVariant="link"
                  captionLabel={{
                    options: [
                      { value: 'table', label: 'TABLE' },
                      { value: 'equation', label: 'EQUATION' },
                      { value: 'figure', label: 'FIGURE' },
                    ],
                  }}
                />
              )}
            </div>
            <Divider margin="0 0 2rem 0" />

            <div className={`${captionsList.length > 0 ? styles.captionsList : styles.emptyList}`}>
              {captionsList.length > 0 &&
                captionsList.map((option) => (
                  <div
                    key={option.value}
                    onClick={() => selectCaption(option)}
                    className={`${styles.captionElement}
                    ${
                      captionSelected && captionSelected.value === option.value && styles.selected
                    }`}
                  >
                    {option.label}
                  </div>
                ))}
              {captionsList.length === 0 && (
                <div className={styles.emptyCaptionsList}>
                  <div className={styles.emptyCaptionsListTitle}>
                    {intl.formatMessage({ id: getEmptyTitle() })}
                  </div>
                  <div className={styles.emptyCaptionsListMessage}>
                    {intl.formatMessage({ id: getEmptyMessage() })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <Checkbox
            size="small"
            checked={checkInsertAsLink ? 'checked' : 'unchecked'}
            onChange={() => {
              setCheckInsertAsLink(!checkInsertAsLink);
            }}
            testId="link-to-reference-checkbox"
          >
            {intl.formatMessage({ id: 'LINK_TO_THE_REFERENCE' })}
          </Checkbox>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="edit-cross-references-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleInsert}
          disabled={validateFormatReference()}
          testId="edit-cross-references-modal-submit-button"
        >
          <FormattedMessage id="UPDATE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCrossReferencesModal;
