import { ModelIndexer } from '../Models/ModelIndexer';
import { Transport } from '_common/services/Realtime/Transport';
import { NodeModel, Structure, StructureData } from '../../models';
import { ELEMENTS } from 'Editor/services/consts';
import { ModelsController } from '../Models/ModelsController';

const DEFAULT_LABEL_ITEMS = ['Table', 'Figure', 'Equation'];

function mapOrderById(array: NodeModel[], order: string[]) {
  array.sort((a: NodeModel, b: NodeModel) => {
    const A = a.id;
    const B = b.id;

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    return -1;
  });
  return array;
}
export class TableOfContentsList extends ModelIndexer<'NODE'> {
  protected structure?: Structure;
  protected documentId?: string;
  protected timer?: any;
  protected stylesMap: TableOfContents.TOCStylesMapType = {};
  constructor(transport: Transport, models: ModelsController) {
    super(transport, models, 'NODE');
    this.handleStructureLoaded = this.handleStructureLoaded.bind(this);
    this.handleStructureUpdated = this.handleStructureUpdated.bind(this);
  }

  get list() {
    return mapOrderById([...this.results], this.structure?.childNodes || []);
  }

  private buildQuery() {
    const styles = Object.keys(this.stylesMap);
    return {
      parent_id: this.documentId,
      _id: { $in: this.structure?.childNodes },
      $or: [
        {
          $and: [
            {
              properties: { $exists: true },
            },
            {
              'properties.s': { $in: styles /* this._styles */ },
            },
          ],
        },
        {
          'properties.otl': { $exists: true },
        },
        {
          st: { $in: styles /* this._styles */ },
        },
        {
          $and: [
            {
              refs: { $exists: true },
            },
            {
              'refs.citations': { $not: { $size: 0 } },
            },
          ],
        },
        {
          $and: [
            {
              refs: { $exists: true },
            },
            {
              'refs.f': {
                $not: {
                  $size: 0,
                },
                $exists: true,
                $elemMatch: {
                  t: 'cpt',
                  cpt: { $in: DEFAULT_LABEL_ITEMS },
                },
              },
            },
          ],
        },
        {
          type: ELEMENTS.TableElement.ELEMENT_TYPE,
        },
      ],
    };
  }

  start(documentId: string, stylesMap: TableOfContents.TOCStylesMapType = {}) {
    this.documentId = documentId;
    this.stylesMap = stylesMap;
    this.structure = this.models.get(this.models.TYPE_NAME.STRUCTURE, `DS${documentId}`);
    this.structure.on('LOADED', this.handleStructureLoaded);
    this.structure.on('CHILDREN_UPDATE', this.handleStructureUpdated);
    if (this.structure.loaded) {
      super.start(this.buildQuery());
    }
  }

  stop() {
    this.structure?.off('LOADED', this.handleStructureLoaded);
    this.structure?.off('CHILDREN_UPDATE', this.handleStructureUpdated);
    let oldDocs: NodeModel[] = this.results.splice(0, this.results.length);
    this.qResults = [];
    this.emit('REMOVED', oldDocs);
    this.emit('CHANGED', this.results);
    this.cancelQuery();
  }

  destroy() {
    this.stop();
    super.destroy();
  }

  private handleStructureUpdated(/* data: StructureData | null */) {
    super.start(this.buildQuery());
  }

  private handleStructureLoaded(data: StructureData | null) {
    super.start(this.buildQuery());
  }

  updateStyleMap(stylesMap: TableOfContents.TOCStylesMapType = {}) {
    this.stylesMap = stylesMap;
    super.start(this.buildQuery());
  }
}
