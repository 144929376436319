/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Structure } from '../../models';
import { BaseController } from '../BaseController';

export class StructureController extends BaseController {
  private structure?: Structure;

  async start(documentId: string) {
    this.structure = this.Data.models?.get('STRUCTURE', `PS${documentId}`);
    await this.structure?.awaitForEvent('LOADED');

    //! TESTING
    if (!this.structure?.get([])) {
      this.structure?.create({
        _id: `PS${documentId}`,
        theme: 'theme1',
        sldIdLst: ['slide1', 'slide2'],
        sldMasterIdLst: [],
        sldSz: {
          type: 'custom',
          cx: 600,
          cy: 800,
        },
      });
    }
  }

  stop(): void {}

  destroy(): void {
    //
  }
}
