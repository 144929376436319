import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { closeModal, openAndUpdateModal } from '_common/modals/ModalsSlice';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import MetadataFields from '_common/components/MetadataFields/MetadataFields';

const EditMetadata = () => {
  const dispatch = useDispatch();

  const { data: metadata } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });

  const isOpen = useSelector((state) => state.modals.open.EditMetadata);
  const id = useSelector((state) => state.modals.EditMetadata.id);
  const element = useSelector((state) => state.app.data[id]);
  const exportDocument = useSelector((state) => state.modals.EditMetadata.export);
  const manualMetadataIds = Object.keys(metadata).filter(
    (id) => metadata[id].data_type !== 'dynamic',
  );
  const currentPage = useSelector((state) => state.app.currentPage);
  const [errors, setErrors] = useState('');
  const close = () => {
    if (exportDocument) {
      dispatch(closeModal('EditMetadata'));
      dispatch(
        openAndUpdateModal({
          modal: 'PublishVeevaModal',
          data: {
            id,
            explorer: currentPage !== '/editor',
          },
        }),
      );
    } else {
      dispatch(closeModal('EditMetadata'));
    }
  };

  return (
    <Modal open={!!isOpen} width="70rem" onClose={close} testId="edit-metadata">
      <Modal.Header onClose={close}>
        <FormattedMessage id="EDIT_ELEMENT_METADATA" values={{ element: element?.name }} />
      </Modal.Header>
      <Modal.Body>
        {manualMetadataIds &&
          manualMetadataIds.map((field) => (
            <MetadataFields
              key={field}
              validations={errors}
              type={metadata[field].data_type}
              metadataList={metadata}
              id={field}
              element={element}
              setValidations={setErrors}
            />
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="edit-metadata-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button size="medium" variant="primary" onClick={close} disabled={errors?.length > 0} testId="edit-metadata-submit-button">
          <FormattedMessage id="UPDATE_METADATA" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditMetadata;
