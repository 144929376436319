import { ReactNode, useState } from 'react';
import styles from './Cell.module.scss';

type CellProps = {
  size?: string;
  ellipsis?: boolean;
  children: string | undefined | ReactNode;
  disabled?: boolean;
  testId?: string;
  noWidth?: boolean;
};

function Cell({ size, ellipsis, children, disabled, testId, noWidth }: CellProps) {
  const [renderedString, setRenderedString] = useState<string>();

  return (
    <div
      style={{ width: `${size ? `${size}rem` : ''}` }}
      className={`
      ${noWidth ? styles.cellNoWidth : styles.cell} ${disabled && styles.disabled} ${
        ellipsis && styles.shadowSpacing
      }`}
      title={ellipsis ? renderedString : undefined}
      data-testid={testId}
    >
      {ellipsis && children ? (
        <span
          ref={(ref) => setRenderedString(ref?.textContent ?? undefined)}
          className={`${styles.ellipsis} ${styles.shadowSpacing}`}
        >
          {children}
        </span>
      ) : (
        children
      )}
    </div>
  );
}

export default Cell;
