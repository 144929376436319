import { useIntl, FormattedMessage } from 'react-intl';

import { Button, Tooltip, Icon, Divider } from 'dodoc-design-system';
import styles from './Setting.module.scss';
import { ReactNode } from 'react';

type SettingProps = {
  style?: React.CSSProperties;
  labelStyles?: React.CSSProperties;
  label: string;
  manager?: boolean;
  value?: ReactNode;
  onEdit?: () => void;
  onEditLabel?: string;
  secondValue?: ReactNode;
  isDescription?: boolean;
  expanded?: boolean;
  clamped?: boolean;
  changeExpanded?: () => void;
  valueStyles?: React.CSSProperties;
  isOwner?: boolean;
  editable?: boolean;
  testId: string;
};

const Setting = ({
  style,
  labelStyles,
  label,
  manager,
  value,
  onEdit,
  onEditLabel,
  secondValue,
  isDescription,
  expanded,
  clamped,
  changeExpanded,
  valueStyles,
  isOwner,
  editable,
  testId,
}: SettingProps) => {
  const intl = useIntl();
  return (
    <>
      <div className={styles.setting} style={style} data-testid={`${testId}-row-container`}>
        <div className={styles.labelContainer} style={labelStyles}>
          <span className={styles.label}>{label}</span>
          {manager && (
            <div style={{ marginLeft: '1rem' }}>
              <Tooltip
                content={intl.formatMessage({ id: 'OWNER_DESCRIPTION' })}
                testId={`${testId}-owner-description-tooltip`}
              >
                <Icon icon="InformationGrey" size={16} />
              </Tooltip>
            </div>
          )}
        </div>
        <div
          className={
            // @ts-expect-error
            typeof value !== 'string' && value?.props.text === 'No description'
              ? styles.noValue
              : styles.value
          }
          style={valueStyles}
          data-testid={`${testId}-value`}
        >
          {value}
          {isDescription && (
            <>
              {clamped && !expanded && (
                <span onClick={changeExpanded} className={styles.underlinedText}>
                  <FormattedMessage id="global.seeMore" />
                </span>
              )}
              {expanded && (
                <span onClick={changeExpanded} className={styles.underlinedText}>
                  <FormattedMessage id="global.seeLess" />
                </span>
              )}
            </>
          )}
        </div>

        {onEdit && (
          <Tooltip
            disabled={editable || editable === undefined}
            content={
              !editable
                ? intl.formatMessage({
                    id: 'CANNOT_EDIT_PROFILE_BECAUSE_YOUR_ACCOUNT_IS_CONNECTED_TO_AN_SSO',
                  })
                : ''
            }
            testId={`${testId}-cannot-edit-tooltip`}
          >
            <Button
              size="small"
              onClick={onEdit}
              testId={`${testId}-edit-button`}
              margin="0 20rem 0 0"
              disabled={
                editable !== undefined ? !editable : typeof isOwner === 'boolean' ? !isOwner : false
              }
            >
              <FormattedMessage id={onEditLabel} />
            </Button>
          </Tooltip>
        )}
        {secondValue && <div className={styles.secondValue}>{secondValue}</div>}
      </div>
      <Divider />
    </>
  );
};

export default Setting;
