import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

type PasteOptionsSliceState = {
  offsets: { top: number; left: number; width: number; height: number } | null;
};

const SLICE_NAME = 'PasteOptions';
const initialState: PasteOptionsSliceState = {
  offsets: null,
};

// #region Slice
const PasteOptionsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPasteOptionsData(state, action: PayloadAction<PasteOptionsSliceState['offsets']>) {
      state.offsets = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export default PasteOptionsSlice.reducer;
// #endregion

// #region Actions
export const { setPasteOptionsData } = PasteOptionsSlice.actions;
// #endregion
