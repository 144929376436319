import type { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';
import ToolbarDropdown from 'Editor/components/ToolbarDropdown/ToolbarDropdown';

type VersionsProps = {
  versions?: Editor.Version[];
  loadedVersion: number | undefined | null;
  loadVersion: (index: number | null) => void;
  width?: string;
  testId: string;
};

const Versions = ({ versions, loadedVersion, width, testId, loadVersion }: VersionsProps) => {
  const onChange = (value: SelectOption) => {
    if (`${loadedVersion}` !== value.value) {
      const index = value.value === 'current' ? null : +value.value;
      loadVersion(index);
    }
  };

  const options =
    versions?.map((_, index) => {
      return {
        label: `${
          loadedVersion != null && loadedVersion - 1 === index
            ? `V.${loadedVersion}`
            : `V.${index + 1}`
        }`,
        value: `${index}`,
      };
    }) || [];
  options.push({ label: `Current version (V.${(versions?.length ?? 0) + 1})`, value: 'current' });
  options.reverse();
  return (
    <ToolbarDropdown
      options={options}
      value={
        loadedVersion != null && loadedVersion >= 0
          ? options.find((option) => option.value === `${loadedVersion}`)
          : options[0]
      }
      width={width ?? '21'}
      onChange={onChange}
      testId={testId}
    />
  );
};

export default Versions;
