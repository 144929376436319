import { useMemo } from 'react';

import TrackedActionContent, {
  getContentValue,
} from 'Editor/components/TrackedActionCard/TrackedActionBody';
import RichTextEditor from '_common/components/RichTextEditor/RichTextEditor';
import AnnotationType from 'PDF/RightSidePanel/AnnotationsPanel/AnnotationCard/ViewAnnotationCard/AnnotationType';
import { DEFAULT_CARD_STYLES_PANEL } from 'PDF/redux/PDFAnnotationsSlice';

import styles from './Snapshot.module.scss';

type PreviewProps = { testId: string } & (
  | {
      type: 'comment' | 'task';
      data?: string | Snapshot;
      subType?: undefined;
    }
  | {
      type: 'suggestion';
      data: {
        content: ApiSchemas['SuggestionActionExtra']['content'];
        type: ApiSchemas['SuggestionActionExtra']['type'];
      };
      subType?: undefined;
    }
  | {
      type: 'annotation';
      subType: PDF.Annotation['subtype'];
      data?: string | Snapshot;
    }
);

const Preview = ({ data, type, subType, testId }: PreviewProps) => {
  const richTextHasContent = useMemo(() => {
    //No content if 'data' is null, not array or empty array
    if (!data || !Array.isArray(data) || data.length <= 0) {
      return false;
    }

    //No content if data only has one paragraph with no text
    if (
      data.length === 1 &&
      data[0].type === 'paragraph' &&
      (!data[0].children[0].text || data[0].children[0].text.trim() === '')
    ) {
      return false;
    }

    return true;
  }, [data]);

  const content = useMemo(() => {
    if ((data as unknown as Snapshot)?.[0]?.type && richTextHasContent) {
      return (
        <RichTextEditor
          readOnly
          expanded
          initialValue={JSON.stringify(data)}
          overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
          testId={`${testId}-rich-text-editor`}
        />
      );
    } else {
      if (type === 'suggestion') {
        return (
          <TrackedActionContent
            //@ts-expect-error APISpec: SuggestionContentSchema.value is unknown
            content={getContentValue({ content: data.content, type: data.type })}
            type={data.type}
          />
        );
      }
      if (typeof data === 'string' && data.trim() !== '') {
        return data;
      } else {
        return null;
      }
    }
  }, [data, type, richTextHasContent]);

  const subTypeContent = useMemo(() => {
    if (type === 'annotation') {
      return <AnnotationType type={subType} notification />;
    }
    return null;
  }, [type, subType]);

  if (!content && !subTypeContent) {
    return null;
  }

  return (
    <div className={styles.root}>
      {subTypeContent && (
        <div style={content ? { paddingBottom: '4px' } : undefined}>{subTypeContent}</div>
      )}
      {content && <div className={styles.content}>{content}</div>}
    </div>
  );
};
export default Preview;
