/* eslint-disable camelcase */
import { ELEMENTS } from 'Editor/services/consts';
import { Logger } from '_common/services';

export default class ParseMapper {
  /**
   * @deprecated use ViewMapper.getElementTypeForTag
   */
  static getElementTypeForNode(node) {
    if (node.nodeType === 3) {
      return 'text';
    }
    switch (node.nodeName) {
      case ELEMENTS.ParagraphElement.TAG:
        return ELEMENTS.ParagraphElement.ELEMENT_TYPE;
      case 'STYLE':
        return 'style';
      case ELEMENTS.TableElement.TAG:
        return ELEMENTS.TableElement.ELEMENT_TYPE;
      case ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER.TAG:
        return ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER.ELEMENT_TYPE;
      case ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER_CELL.TAG:
        return ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER_CELL.ELEMENT_TYPE;
      case ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG:
        return ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.ELEMENT_TYPE;
      case ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG:
        return ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE;
      case ELEMENTS.TableCellElement.TAG:
        return ELEMENTS.TableCellElement.ELEMENT_TYPE;
      case ELEMENTS.FigureElement.TAG:
        return ELEMENTS.FigureElement.ELEMENT_TYPE;
      case 'IMG':
        return 'image';
      case ELEMENTS.ImageElement.TAG:
        return ELEMENTS.ImageElement.ELEMENT_TYPE;
      case ELEMENTS.HyperlinkElement.TAG:
        return ELEMENTS.HyperlinkElement.ELEMENT_TYPE;
      case 'BR':
        return 'breakline';
      case 'BREAKLINE-ELEMENT':
        return 'breakline-element';
      case 'DIV':
        return 'container';
      case 'REDACTED-ELEMENT':
        return 'redacted';
      case 'READONLY-ELEMENT':
        return 'readonly';
      case ELEMENTS.ApprovedElement.TAG:
        return ELEMENTS.ApprovedElement.ELEMENT_TYPE;
      case ELEMENTS.CommentElement.TAG:
        return ELEMENTS.CommentElement.ELEMENT_TYPE;
      case 'TEMP-COMMENT-ELEMENT':
        return 'temp-comment';
      case 'TRACK-INS-ELEMENT':
        return 'tracked-insert';
      case 'TRACK-DEL-ELEMENT':
        return 'tracked-delete';
      case 'SPAN':
        return 'span';
      case ELEMENTS.ColumnBreakElement.TAG:
        return ELEMENTS.ColumnBreakElement.ELEMENT_TYPE;
      case ELEMENTS.PageBreakElement.TAG:
        return ELEMENTS.PageBreakElement.ELEMENT_TYPE;
      case ELEMENTS.SectionBreakElement.TAG:
        return ELEMENTS.SectionBreakElement.ELEMENT_TYPE;
      case ELEMENTS.TableOfContentsElement.TAG:
        return ELEMENTS.TableOfContentsElement.ELEMENT_TYPE;
      case ELEMENTS.KeywordsElement.TAG:
        return ELEMENTS.KeywordsElement.ELEMENT_TYPE;
      case ELEMENTS.AuthorsElement.TAG:
        return ELEMENTS.AuthorsElement.ELEMENT_TYPE;
      case ELEMENTS.ListOfFiguresElement.TAG:
        return ELEMENTS.ListOfFiguresElement.ELEMENT_TYPE;
      case ELEMENTS.ListOfTablesElement.TAG:
        return ELEMENTS.ListOfTablesElement.ELEMENT_TYPE;
      case ELEMENTS.FormatElement.TAG:
        return ELEMENTS.FormatElement.ELEMENT_TYPE;
      case ELEMENTS.CitationElement.TAG:
        return ELEMENTS.CitationElement.ELEMENT_TYPE;
      case ELEMENTS.CitationsGroupElement.TAG:
        return ELEMENTS.CitationsGroupElement.ELEMENT_TYPE;
      case 'SYMBOL-ELEMENT':
        return 'symbol';
      case 'NOTE-ELEMENT':
        return 'note';
      case ELEMENTS.InvalidElement.TAG:
        return ELEMENTS.InvalidElement.ELEMENT_TYPE;
      case ELEMENTS.CrossReferenceElement.TAG:
        return ELEMENTS.CrossReferenceElement.ELEMENT_TYPE;
      case 'EQUATION-ELEMENT':
        return 'equation';
      case ELEMENTS.PasteMarkerElement.TAG:
        return ELEMENTS.PasteMarkerElement.ELEMENT_TYPE;
      case ELEMENTS.LoaderElement.TAG:
        return ELEMENTS.LoaderElement.ELEMENT_TYPE;
      case ELEMENTS.PlaceholderElement.TAG:
        return ELEMENTS.PlaceholderElement.ELEMENT_TYPE;
      case ELEMENTS.FieldElement.TAG:
        return ELEMENTS.FieldElement.ELEMENT_TYPE;
      case ELEMENTS.ReferencesSectionElement.TAG:
        return ELEMENTS.ReferencesSectionElement.ELEMENT_TYPE;
      case ELEMENTS.TabElement.TAG:
        return ELEMENTS.TabElement.ELEMENT_TYPE;
      // frontend only elements
      case ELEMENTS.PermissionViewElement.TAG:
      case ELEMENTS.ApproveViewElement.TAG:
      case ELEMENTS.PageElement.TAG:
      case ELEMENTS.SectionElement.TAG:
        return null;
      default: {
        Logger.warn(`Invalid element type for: "${node.nodeName}"`, node.cloneNode(true));
        return ELEMENTS.InvalidElement.ELEMENT_TYPE;
      }
    }
  }

  // ---------------------------------------------------------------
  //               atributes and properties mappers
  // ---------------------------------------------------------------
  static alignmentMapper = {
    parse: (alignment) => {
      switch (alignment) {
        case 'l':
        case 'left':
          return 'l';
        case 'r':
        case 'right':
          return 'r';
        case 'c':
        case 'center':
          return 'c';
        case 'j':
        case 'justify':
          return 'j';
        default:
          return 'l';
      }
    },
    render: (a) => {
      switch (a) {
        case 'l':
        case 'left':
          return 'left';
        case 'r':
        case 'right':
          return 'right';
        case 'c':
        case 'center':
          return 'center';
        case 'j':
        case 'justify':
          return 'justify';
        default:
          return 'left';
      }
    },
  };

  static verticalAlignmentMapper = {
    parse: (va) => {
      switch (va) {
        case 't':
        case 'top':
          return 't';
        case 'b':
        case 'bottom':
          return 'b';
        case 'c':
        case 'center':
        default:
          return 'c';
      }
    },
    render: (va) => {
      switch (va) {
        case 't':
        case 'top':
          return 'top';
        case 'b':
        case 'bottom':
          return 'bottom';
        case 'c':
        case 'center':
          return 'center';
        default:
          return '';
      }
    },
  };

  static borderStyleMapper = {
    parse: (bs) => {
      switch (bs) {
        case 'd':
        case 'double':
          return 'd';
        case 'das':
        case 'dashed':
          return 'das';
        case 'dot':
        case 'dotted':
          return 'dot';
        case 's':
        case 'solid':
          return 's';
        case 'n':
        case 'none':
          return 'n';
        default:
          return 's';
      }
    },
    render: (bs) => {
      switch (bs) {
        case 'd':
        case 'double':
          return 'double';
        case 'das':
        case 'dashed':
          return 'dashed';
        case 'dot':
          return 'dotted';
        case 's':
        case 'solid':
          return 'solid';
        case 'n':
        case 'none':
          return 'none';
        default:
          return '';
      }
    },
  };
}
