import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { BaseOperation } from './BaseOperation';
import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { InsertElementOperation } from '.';

export class InsertTextOperation extends BaseOperation<Editor.Data.Node.Model> {
  private textToInsert: string;
  protected path: Editor.Selection.Path;

  constructor(model: Editor.Data.Node.Model, path: Editor.Selection.Path, textToInsert: string) {
    super(model);
    this.textToInsert = textToInsert;
    this.path = path;

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const index = this.path.indexOf('content');
    if (index >= 0) {
      let textOffset = Number(this.path[index + 1]);
      let pathToChild = this.path.slice(0, index);

      const data = this.model.getChildDataByPath(pathToChild);

      if (NodeUtils.isTextData(data) && !isNaN(textOffset)) {
        this.ops.push(RealtimeOpsBuilder.stringInsert(this.textToInsert, this.path));
        this.resultPath = [...pathToChild, 'content', textOffset + this.textToInsert.length];
      }
    } else {
      // create text element
      const textData = NodeDataBuilder.build({
        type: 'text',
        content: this.textToInsert,
      });

      // insert element operation
      if (textData) {
        const operation = new InsertElementOperation(this.model, this.path, textData);
        this.ops.push(...operation.getOps());
        this.resultPath = operation.getResultPath();
      }
    }

    return this;
  }
}
