import { FilterPopover } from '_common/components';

type TaskFiltersProps = {
  //TODO:PRESENTATION Change type
  tasksList: PDF.Annotation.Task[];
  options: {
    [key: string]: {
      label: string;
      id: string;
      email?: string | undefined;
      value: string;
      type?: string;
    }[];
  };
};

const TaskFilters = ({ tasksList, options }: TaskFiltersProps) => {
  const handleTasksByStatus = (status: string) => {
    if (status === 'deleted') {
      return tasksList.filter((task) => task.state === 'Cancelled').length;
    }
    return tasksList.filter((task) => task.status === status && task.state !== 'Cancelled').length;
  };

  return (
    <FilterPopover
      identity="reviewerTaskPanel"
      popperSettings={{ placement: 'bottom-start' }}
      toggleVariant="link"
      reviewerTaskStatus={{
        options: [
          { value: 'td', label: 'TODO', labelNumber: handleTasksByStatus('td') },
          { value: 'pr', label: 'IN_PROGRESS', labelNumber: handleTasksByStatus('pr') },
          { value: 'd', label: 'DONE', labelNumber: handleTasksByStatus('d') },
          {
            value: 'dlt',
            label: 'editor.sidebar.review.filter.status.deleted',
            labelNumber: handleTasksByStatus('deleted'),
          },
        ],
      }}
      assignedUser={{ options: options.users }}
      persist
      variant="reviewer"
    />
  );
};

export default TaskFilters;
