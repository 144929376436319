import { CSSProperties, memo, useMemo } from 'react';
import { SLIDES_SIZES } from 'Presentation/consts';
import styles from './Slide.module.scss';
import { useSlideSync, useStructureSync } from 'Presentation/SyncStore';
// import useShapeFill from './useShapeFill';

const Slide = ({ slideId }: { slideId: string }) => {
  const structure = useStructureSync();
  const slide = useSlideSync(slideId);

  // const background = useShapeFill('NoFill');

  const style = useMemo(() => {
    const style: CSSProperties = {};
    if (structure) {
      if (structure.sldSz.type === 'custom') {
        style.width = structure.sldSz.cx;
        style.height = structure.sldSz.cy;
      } else {
        style.width = SLIDES_SIZES[structure.sldSz.type].width;
        style.height = SLIDES_SIZES[structure.sldSz.type].height;
      }
    }
    return style;
  }, [structure]);

  if (structure && slide) {
    return (
      <div style={style} className={styles.slide}>
        <p>Slide use master slide : {`${slide.clrMapOvr.useMasterSlide}`}</p>
        <p>Slide size type : {structure?.sldSz.type}</p>
      </div>
    );
  }
  return null;
};

export default memo(Slide);
