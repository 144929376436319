import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks/redux';
import { Modal, Button, InputField, Input } from 'dodoc-design-system';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import {
  useAddAffiliationFieldMutation,
  useEditAffiliationFieldMutation,
} from 'Settings/pages/TenantSettingsPage/Affiliations/AffiliationsApi';

const MODAL = 'AddAffiliationModal';

const AddNewAffiliationFieldModal = () => {
  const intl = useIntl();
  // redux
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const operation = useSelector((state) => state.modals[MODAL].operation);
  const field = useSelector((state) => state.modals[MODAL].field);
  const option = useSelector((state) => state.modals[MODAL].option);
  const [mutAddAffiliationField] = useAddAffiliationFieldMutation();
  const [mutEditAffiliationField] = useEditAffiliationFieldMutation();
  // local
  const [newOption, setNewOption] = useState('');
  const [validations, setValidations] = useState<{ name?: string }>({});

  useEffect(() => {
    if (isOpen) {
      setNewOption(operation === 'edit' ? option : '');
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const nameField = () => {
    if (field === 'address1') {
      return 'address 1st line';
    }
    if (field === 'address2') {
      return 'address 2nd line';
    }
    if (field === 'zip_code') {
      return 'zip code';
    }
    return field;
  };

  const newOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.value;
    setNewOption(inputName);
    const validations = {
      name: '',
    };

    if (inputName.length > 1024) {
      validations.name = 'Field options have a maximum of 1024 characters. Please try again';
    }

    if (Object.keys(validations).length > 0) {
      setValidations({ ...validations });
    } else {
      setValidations({});
    }
  };

  const close = () => {
    setNewOption('');
    setValidations({});
    dispatch(closeAndResetModal(MODAL));
  };

  const addOption = () => {
    if (operation === 'edit') {
      mutEditAffiliationField({ field, old: option, newValue: newOption });
    } else {
      mutAddAffiliationField({ field, value: newOption });
    }
    close();
  };
  return (
    <Modal
      open={isOpen}
      onClose={close}
      width="60rem"
      testId={operation === 'edit' ? 'edit-affiliation' : 'add-affiliation'}
    >
      <Modal.Header onClose={close}>
        <FormattedMessage
          id={
            operation === 'edit' ? 'EDIT_AFFILIATION_FIELD_OPTION' : 'NEW_AFFILIATION_FIELD_OPTION'
          }
        />
      </Modal.Header>
      <Modal.Body>
        <InputField
          size="large"
          label={intl.formatMessage({ id: 'NEW_FIELD_OPTION' }, { field: nameField() })}
          feedback={validations.name}
          testId={
            operation === 'edit' ? 'inputField_editAffFieldName' : 'inputField_newAffFieldName'
          }
        >
          <Input
            placeholder={intl.formatMessage({ id: 'INSERT_HERE_A_NEW_OPTION_FOR_THIS_FIELD' })}
            value={newOption}
            onChange={newOptionChange}
            size="large"
            error={!!validations.name}
            testId={operation === 'edit' ? 'input_editAffFieldName' : 'input_newAffFieldName'}
          />
        </InputField>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="medium"
          onClick={close}
          testId={operation === 'edit' ? 'button_editAffCancel' : 'button_newAffCancel'}
        >
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={addOption}
          disabled={
            newOption.length === 0 ||
            !!validations.name ||
            (option === newOption && operation !== 'edit')
          }
          testId={operation === 'edit' ? 'button_editAffSaveEdit' : 'button_newAffAdd'}
        >
          <FormattedMessage id={operation === 'edit' ? 'EDIT_OPTION' : 'ADD_OPTION'} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewAffiliationFieldModal;
