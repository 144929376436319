import { useIntl } from 'react-intl';
import { Icon, SectionHeading, Select } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { useCreateTagMutation, useGetTagsListQuery } from './TagsApi';

import styles from './Tags.module.scss';

type TagsHeadingProps = {
  testId: string;
  search: string;
  onSearchChange: (search: string) => void;
};

const customStyles = {
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: '5rem',
  }),
};

const TagsHeading = ({ search, onSearchChange, testId }: TagsHeadingProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { data: tags } = useGetTagsListQuery();
  const [mutCreateTag] = useCreateTagMutation();

  const handleOnchange = (values: { value: string }) => {
    if (values) {
      mutCreateTag(values.value);
    }
  };

  const toggleCreatingTag = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'CreateNewTagModal',
        data: {},
      }),
    );
  };

  const handleValidNewOption = (values: string) => {
    if (search.length > 0 && search.length < 100 && !tags?.list.includes(values)) {
      return true;
    }
    if (tags?.list.includes(values)) {
      return false;
    }
    return false;
  };

  const openUploadTagsModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'UploadTagsModal',
        data: { state: 'standby', progress: 0 },
      }),
    );
  };

  const handleSearchTag = (value: string) => {
    onSearchChange(value || '');
  };

  return (
    <SectionHeading
      margin="3rem 0 0 0"
      title={intl.formatMessage({ id: 'settings.general.tags' })}
      size="large"
      buttonLeftProps={{
        size: 'medium',
        children: intl.formatMessage({ id: 'NEW' }),
        margin: '0 0 0 2rem',
        onClick: toggleCreatingTag,
        testId: `${testId}-new`,
      }}
      buttonRightProps={{
        size: 'medium',
        children: intl.formatMessage({ id: 'UPLOAD' }),
        margin: '0 0 0 0.5rem',
        onClick: openUploadTagsModal,
        testId: `${testId}-upload`,
      }}
      search={
        <div className={styles.search} data-testid={`${testId}-search`}>
          <div className={styles.icon}>
            <Icon icon="NavSearchBlue" size={24} />
          </div>
          <Select
            testId={`${testId}-header-search`}
            size="medium"
            width="31rem"
            creatable
            placeholder={intl.formatMessage({ id: 'PLACEHOLDER_SEARCH' })}
            options={tags?.list.map((tag) => ({ value: tag, label: tag }))}
            onChange={handleOnchange}
            value={search ? { value: search, label: search } : null}
            onInputChange={handleSearchTag}
            isValidNewOption={handleValidNewOption}
            escapeClearsValue
            createOptionPosition="first"
            noOptionsMessage={({ inputValue }) => {
              if (inputValue === '') {
                return null;
              }
              return intl.formatMessage(
                {
                  id: 'LONG_TAG_NAME',
                },
                { length: 100 },
              );
            }}
            customStyles={customStyles}
          />
        </div>
      }
      testId={`${testId}-header`}
    />
  );
};

export default TagsHeading;
