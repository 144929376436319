import { createContext, DependencyList, ReactNode, useContext, useEffect, useState } from 'react';
import { useSelector } from '_common/hooks';
import { useParams } from 'react-router';
import LoadingPage from '_common/components/Loading/LoadingPage/LoadingPage';
import { ErrorScreen } from '_common/components/ErrorBoundary/ErrorBoundary';
import { PresentationManager } from './services/PresentationManager';
import { SessionStorage } from '_common/utils';

const PresentationContext = createContext<PresentationManager | undefined>(undefined);

const PresentationProvider = ({ children }: { children: ReactNode }) => {
  const { id } = useParams<{ id: string }>();
  const userId = useSelector((state) => state.auth.userId);
  const token = SessionStorage.getToken();
  const [presentationManager, setManager] = useState<PresentationManager | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id && userId && token) {
      let instance = presentationManager || new PresentationManager();
      instance.on('STATUS_CHANGED', (error: Error, status: Presentation.Status) => {
        if (error) {
          setLoading(false);
          setManager(null);
          setError(error);
        } else {
          setError(null);
          if (status === 'READY') {
            setLoading(false);
            setManager(instance);
          } else if (status === 'DESTROYED' || status === 'DESTROYING') {
            setLoading(false);
            setManager(null);
          }
        }
      });
      instance.connect(id, {
        id: userId,
        token,
      });
    }

    return () => {
      if (presentationManager) {
        presentationManager.destroy();
      }
    };
  }, [id, userId, token]);

  useEffect(() => {
    if (id && presentationManager) {
      // presentationManager.initializeShortcutsManager();
    }
    return () => {
      if (presentationManager) {
        // presentationManager.destroyShortcutsManager();
      }
    };
  }, [id, presentationManager]);

  return (
    <PresentationContext.Provider value={presentationManager!}>
      {error && <ErrorScreen />}
      {!error && presentationManager && children}
      {loading && <LoadingPage />}
    </PresentationContext.Provider>
  );

  // return <PresentationContext.Provider value={undefined}>{children}</PresentationContext.Provider>;
};

export const usePresentationManager = () => {
  const context = useContext(PresentationContext);
  if (context === undefined) {
    throw new Error('usePresentationManager can only be used in a PresentationProvider');
  }
  return context;
};

export function usePresentationDataEvents<
  E extends Presentation.Data.DataEventsEventName,
  H extends Presentation.Data.DataEvents[E],
>(type: E, listener: H, deps: DependencyList = []) {
  const manager = usePresentationManager();
  useEffect(() => {
    manager?.getData()?.events.on(type, listener);
    return () => {
      manager?.getData()?.events.off(type, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, listener, ...deps]);
}

export function usePresentationEvents<
  E extends keyof Presentation.Events,
  H extends Presentation.Events[E],
>(type: E, listener: H, deps: DependencyList = []) {
  const manager = usePresentationManager();
  useEffect(() => {
    manager?.on(type, listener);
    return () => {
      manager?.off(type, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, listener, ...deps]);
}

export default PresentationProvider;
