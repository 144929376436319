import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LinesEllipsis, { ReactLinesEllipsisProps } from 'react-lines-ellipsis';
import { Button } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { ExtensionsService } from '_common/services';

import { getDocumentObject, selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';
import { useGetInstalledTemplatesListQuery } from 'Settings/pages/TenantSettingsPage/Templates/TemplatesApi';

import { TemplateGallery } from '_common/components';

import styles from './Template.module.scss';

const Template = () => {
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const object = useSelector(getDocumentObject);
  const { objectTemplate: template } = useGetInstalledTemplatesListQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      objectTemplate: result.data?.find((docTemplate) => docTemplate.id === object.template),
    }),
  });

  const [thumbnail, setThumbnail] = useState('');
  const [choosingTemplate, setChoosingTemplate] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
    setThumbnail('');
    if (object.template) {
      new ExtensionsService().previewTemplate(object.template).then((response) => {
        const url = URL.createObjectURL(response.data);
        setThumbnail(url);
      });
    }
  }, [object.template]);

  const handleToggleReadMore: ReactLinesEllipsisProps['onReflow'] = (data) => {
    if (isClamped !== data.clamped) {
      setIsClamped(data.clamped);
    }
  };

  const name = template?.name || 'No Template';
  const description = template?.description || 'Template description';
  return (
    <div className={styles.container}>
      <div className={styles.templateContainer}>
        {object.template ? (
          <img className={`${styles.img} `} alt={name} src={thumbnail} />
        ) : (
          <div className={`${styles.img} `} />
        )}
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.name}>{name}</div>
        <div
          className={`${styles.descriptionContainer} ${
            template?.description && styles.description
          }`}
        >
          <LinesEllipsis
            text={description}
            maxLine={readMore ? 20 : 4}
            ellipsis={readMore ? '' : '...'}
            onReflow={handleToggleReadMore}
          />
        </div>
        {isClamped && !readMore && (
          <div onClick={() => setReadMore(!readMore)} className={styles.readMore}>
            <FormattedMessage id="global.seeMore" />
          </div>
        )}
        {readMore && (
          <div onClick={() => setReadMore(!readMore)} className={styles.readMore}>
            <FormattedMessage id="global.seeLess" />
          </div>
        )}
        <div style={{ marginTop: '1rem' }}>
          <Button
            variant="primary"
            size="small"
            onClick={() => setChoosingTemplate(true)}
            disabled={isReadOnlyMode}
            testId="change-template-button"
          >
            <FormattedMessage id="CHANGE_TEMPLATE" />
          </Button>
        </div>
        {choosingTemplate && <TemplateGallery close={() => setChoosingTemplate(false)} />}
      </div>
    </div>
  );
};

export default Template;
