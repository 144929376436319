import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { EditorService } from '_common/services';
import { useDispatch, useSelector } from '_common/hooks';
import {
  closeAndResetModal,
  updateModal,
  closeModal,
  openAndUpdateModal,
} from '_common/modals/ModalsSlice';

import { Divider, Modal, Button, ProgressBar } from 'dodoc-design-system';

import { normalizeNewDocuments } from 'Editor/redux/CurrentIntegrationSlice';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import {
  resetIntegrationData,
  updateCurrentIntegrationData,
  getCabinetList,
  getCabinetContent,
  exportToBox,
  exportToNetDocuments,
  getBoxAllFiles,
  lazyBoxAllFiles,
  lazyFavouritesContent,
  lazyloadBoxFolderContent,
  lazyloadBoxSearch,
  lazyloadCabinetContent,
  lazyloadFolderContent,
  lazyloadSearch,
  lazyRecentContent,
  listBoxFavouritesContent,
  listBoxFolderContent,
  listBoxRecentsContent,
  listFavouritesContent,
  listFolderContent,
  listRecentsContent,
  listWorkspaceContent,
  searchBox,
  searchNetDocuments,
} from 'Editor/redux/CurrentIntegrationSlice';
import {
  selectUserCurrentTenant,
  useGetCurrentUserQuery,
  useGetLinksQuery,
} from '_common/services/api/authority';

import NewIntegrationLogin from './NewIntegrationLogin/NewIntegrationLogin';
import ExpansibleMenus from 'Editor/modals/ExportIntegrationModal/Menus/ExpansibleMenus/ExpansibleMenus';
import StaticMenus from 'Editor/modals/ExportIntegrationModal/Menus/StaticMenus/StaticMenus';
import Content from 'Editor/modals/ExportIntegrationModal/Content/Content';

import styles from './ExportIntegrationModal.module.scss';

import type { ExportIntegrationModal as ExportIntegrationModalTypes } from 'Editor/modals/ExportIntegrationModal/ExportIntegrationModalTypes';
import type { ExpansibleMenusProps } from 'Editor/modals/ExportIntegrationModal/Menus/ExpansibleMenus/ExpansibleMenus';

const ExportIntegrationModal = () => {
  const dispatch = useDispatch();

  const { data: currentUser } = useGetCurrentUserQuery();
  const currentTenant = useSelector(selectUserCurrentTenant);

  //Make sure links are fetched because they will be needed in getBoxAllFiles thunk
  useGetLinksQuery(
    { userId: currentUser?.profile.id ?? '', tenant: currentTenant?.name ?? '' },
    { skip: !currentUser?.other.tenants || !currentTenant },
  );

  const isOpen = useSelector((state) => state.modals.open.ExportIntegrationModal);
  const exportTemplate = useSelector((state) => state.modals.ExportIntegrationModal.exportTemplate);
  const waitingValidation = useSelector(
    (state) => state.modals.ExportIntegrationModal.waitingValidation,
  );
  const needLogin = useSelector((state) => state.modals.ExportIntegrationModal.needLogin);
  const currentIntegration = useSelector((state) => normalizeNewDocuments(state));
  const document = useSelector((state) => getDocumentObject(state));
  const exportType: ExportIntegrationModalTypes.ExportType = useSelector(
    (state) => state.modals.ExportDocumentModal.exportType,
  );
  const intl = useIntl();
  const LABELS = {
    netdocuments: 'netDocuments',
    box: 'Box',
  };

  const INTEGRATION_UTILS: ExportIntegrationModalTypes.IntegrationUtils = {
    netdocuments: {
      getList: (token) => dispatch(getCabinetList(token ?? '')),
      getContent: (elem, params) => dispatch(getCabinetContent({ object: elem, params })),
      lazyloadCabinetContent: ({ current, next }) =>
        dispatch(lazyloadCabinetContent({ object: current, params: next })),
      resetData: () => dispatch(resetIntegrationData()),
      search: ({ id, size, query }) =>
        dispatch(searchNetDocuments({ id, params: size, form: query })),
      lazySearch: ({ id, params, form }) => dispatch(lazyloadSearch({ id, params, form })),
      listFolderContent: (params) => dispatch(listFolderContent(params)),
      lazyFolderContent: ({ current, next }) =>
        dispatch(lazyloadFolderContent({ object: current, params: next })),
      listWorkspaceContent: (current, path = false) =>
        dispatch(listWorkspaceContent({ object: current, isPrevPath: path })),
      listRecentsContent: (elem) => dispatch(listRecentsContent(elem)),
      listFavouritesContent: (elem) => dispatch(listFavouritesContent(elem)),
      exportToPlatform: (documentId, params) =>
        dispatch(exportToNetDocuments({ documentId, options: params })),
      loginComponent: NewIntegrationLogin,
      menusComponent: ExpansibleMenus,
      utilsMenusComponent: {
        withoutSubOptions: false,
        options: [],
      },
      data: currentIntegration,
      instances: currentIntegration.instances,
    },
    box: {
      getList: () => {},
      getContent: (elem, params) => dispatch(getBoxAllFiles({ object: elem, params })),
      lazyRootContent: (params) => dispatch(lazyBoxAllFiles(params)),
      exportToPlatform: (documentId, params) =>
        dispatch(exportToBox({ documentId, options: params })),
      resetData: () => dispatch(resetIntegrationData()),
      search: ({ size, query }) => dispatch(searchBox({ params: size, form: query })),
      lazySearch: ({ params, form }) => dispatch(lazyloadBoxSearch({ params, form })),
      listFolderContent: (params) => dispatch(listBoxFolderContent(params)),
      lazyFolderContent: ({ current, next }) =>
        dispatch(lazyloadBoxFolderContent({ object: current, params: next })),
      listRecentsContent: (elem) =>
        dispatch(listBoxRecentsContent({ object: elem, params: { size: 20 } })),
      lazyRecentContent: ({ current, params }) =>
        dispatch(lazyRecentContent({ object: current, params })),
      listFavouritesContent: (elem) =>
        dispatch(listBoxFavouritesContent({ object: elem, params: { size: 20, offset: 0 } })),
      lazyFavouritesContent: ({ current, params }) =>
        dispatch(lazyFavouritesContent({ object: current, params })),
      menusComponent: StaticMenus,
      utilsMenusComponent: {
        withoutSubOptions: true,
        options: [
          { id: 'allFiles', name: intl.formatMessage({ id: 'ALL_FILES' }) },
          { id: 'recent', name: intl.formatMessage({ id: 'RECENT' }) },
          { id: 'favourites', name: intl.formatMessage({ id: 'FAVOURITES' }) },
        ],
      },
      data: currentIntegration,
      instances: [],
    },
  };

  const integrationType = INTEGRATION_UTILS[exportType];
  const [selectedOption, setSelectedOption] =
    useState<ExpansibleMenusProps<typeof exportType>['selectedOption']>();
  const [selectedSubOption, setSelectedSubOption] = useState({ id: '', name: '' });
  const [isOptionOpen, setOptionState] = useState(false);
  let timeoutBoxIntegration: number;

  useEffect(() => {
    integrationType?.resetData();
    integrationType?.getList();
    if (exportType === 'box') {
      const obj: typeof selectedOption = {
        id: 'allFiles',
        name: intl.formatMessage({ id: 'ALL_FILES' }),
      };
      dispatch(getBoxAllFiles({ object: obj, params: { size: 20 } }));
      setSelectedOption(obj);
    }
  }, [isOpen]);

  useEffect(() => {
    if (exportType === 'box' && waitingValidation) {
      getBoxStatus();
    }

    return () => {
      if (timeoutBoxIntegration) {
        clearTimeout(timeoutBoxIntegration);
      }
    };
  }, [waitingValidation]);

  useEffect(() => {
    if (integrationType?.instances.length > 0) {
      const firstCabinet = integrationType.instances[0].value[0];
      setSelectedOption(firstCabinet);
      if (document.netdocuments) {
        const location = true;
        const pathSize = document.netdocuments.path.length;
        if (document.netdocuments.path[pathSize - 1].type === 'folder') {
          const object = {
            ...document.netdocuments.path[pathSize - 1],
            path: document.netdocuments.path,
          };
          //TODO: listNetDocumentsFolderContent doesnt exist and i think it never did.
          //not sure if it sould be listFolderContent instead of listNetDocumentsFolderContent
          //dispatch(listNetDocumentsFolderContent({ object, params: {}, location }));
          dispatch(listFolderContent({ object, params: { size: 20 }, location }));
        } else {
          const object = {
            ...document.netdocuments.path[pathSize - 1],
            path: document.netdocuments.path,
          };
          dispatch(getCabinetContent(object));
        }
      } else {
        dispatch(getCabinetContent({ object: firstCabinet, params: { size: 20 } }));
      }
    }
  }, [integrationType?.instances]);

  const getButtonExportDisabled = () => {
    if (integrationType?.data.loading) return true;
    if (
      currentIntegration.selected &&
      currentIntegration.content.dict[currentIntegration.selected].type === 'web_link'
    ) {
      return true;
    }

    if (
      currentIntegration.current.subOption &&
      (!currentIntegration.selected ||
        currentIntegration.content.dict[currentIntegration.selected].type === 'folder' ||
        currentIntegration.content.dict[currentIntegration.selected].type === 'workspace')
    )
      return true;

    if (
      exportType === 'box' &&
      selectedOption?.id !== 'allFiles' &&
      (!currentIntegration.selected ||
        currentIntegration.content.dict[currentIntegration.selected].type === 'folder')
    ) {
      return true;
    }

    return false;
  };

  const getBoxStatus = () => {
    if (timeoutBoxIntegration) {
      clearTimeout(timeoutBoxIntegration);
    }

    timeoutBoxIntegration = window.setTimeout(async () => {
      try {
        const { data } = await new EditorService({ errorsExpected: [401] }).getBoxAllFiles({
          size: 20,
        });

        clearTimeout(timeoutBoxIntegration);
        dispatch(
          updateCurrentIntegrationData({
            object: { id: 'allFiles', name: intl.formatMessage({ id: 'ALL_FILES' }) },
            //@ts-expect-error APISpec: data is unknown because endpoint is not typed yet
            data: data.elements,
            isRoot: true,
          }),
        );

        dispatch(
          updateModal({
            modal: 'ExportIntegrationModal',
            data: {
              waitingValidation: false,
            },
          }),
        );
      } catch (error) {
        clearTimeout(timeoutBoxIntegration);
        close();
      }

      getBoxStatus();
    }, 3000);
  };

  const close = () => {
    dispatch(closeAndResetModal('ExportIntegrationModal'));
    integrationType.resetData();
  };

  const handleExportToIntegration = () => {
    const data = integrationType.data;

    let type = exportType === 'box' ? 'folder' : 'cabinet';
    const params = {
      name: document.name,
      path: data.path,
      ...exportTemplate,
    };

    if (data.selected) {
      if (data.content.dict[data.selected].type === 'folder') {
        type = data.content.dict[data.selected].type;
      } else if (
        data.content.dict[data.selected].type === 'document' &&
        exportType === 'netdocuments'
      ) {
        dispatch(closeModal('ExportDocumentModal'));
        dispatch(
          openAndUpdateModal({
            modal: 'ConfirmationModal',
            data: {
              title: 'ADD_NEW_VERSION',
              message: 'NEW_VERSION_ADDED_TO_DOCUMENT',
              confirmButtonTextId: 'ACCEPT',
              size: 'medium',
              cancelButtonTextId: 'global.cancel',
              confirmButtonType: 'primary',
              actionCode: 'exportToIntegration',
              headerType: 'default',
              cancelButtonAction: true,
              actionValue: {
                integrationType: exportType,
                id: document.id,
                destination: data.selected,
                type: 'document',
                exportTemplate,
              },
              cancelButtonShow: true,
            },
          }),
        );
        dispatch(closeModal('ExportIntegrationModal'));
      } else {
        dispatch(closeModal('ExportDocumentModal'));
        dispatch(
          openAndUpdateModal({
            modal: 'ConfirmationModal',
            data: {
              title: 'EXPORT_INTEGRATION',
              titleValues: { name: document.name, integrationName: exportType },
              message: 'EXPORT_CREATE_NEW_VERSION',
              cancelButtonTextId: 'GO_BACK',
              confirmButtonTextId: 'EXPORT_NEW_VERSION',
              confirmButtonType: 'primary',
              actionCode: 'exportToIntegration',
              headerType: 'default',
              actionValue: {
                integrationType: exportType,
                id: document.id,
                destination: data.selected,
                type: 'file',
                exportTemplate,
              },
              width: '85rem',
              cancelButtonShow: true,
            },
          }),
        );
        dispatch(closeModal('ExportIntegrationModal'));
      }
    } else if (data.current.type) {
      type = data.current.type;
    }

    if (!data.selected || (data.selected && data.content.dict[data.selected].type === 'folder')) {
      params.type = type;
      const destination = data.selected || data.current.id;
      if (destination && destination !== 'allFiles') {
        params.destination = destination;
      }

      integrationType.exportToPlatform(document.id, params);
    }
  };

  // Variable Components
  const LoginComponent =
    exportType === 'netdocuments'
      ? (integrationType as ExportIntegrationModalTypes.IntegrationUtils['netdocuments'])
          ?.loginComponent
      : null;
  const MenusComponent = integrationType?.menusComponent;
  // Memo
  const MemoMyMenusComponent = integrationType && React.memo(MenusComponent);
  const isExportButtonDisabled = getButtonExportDisabled();
  if (!isOpen) {
    return null;
  }
  return (
    <Modal width="109rem" open={isOpen} onClose={close} testId="export-integration">
      <Modal.Header onClose={close}>
        <FormattedMessage
          id="EXPORT_INTEGRATION"
          values={{ name: document?.name, integrationName: LABELS[exportType] }}
        />
      </Modal.Header>
      <Modal.Body>
        {waitingValidation && (
          <div className={styles.waitingValidation}>
            <ProgressBar
              label={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
              testId="loading-elements-progressBar"
            />
          </div>
        )}
        {needLogin && <LoginComponent />}
        {!needLogin && !waitingValidation && (
          <div className={styles.container}>
            {isOpen && (
              <MemoMyMenusComponent
                info={integrationType}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                selectedSubOption={selectedSubOption}
                setSelectedSubOption={setSelectedSubOption}
                utilsMenusComponent={integrationType?.utilsMenusComponent}
                isOptionOpen={isOptionOpen}
                setOptionState={setOptionState}
              />
            )}

            <Divider vertical />
            <Content info={integrationType} exportType={exportType} />
          </div>
        )}
      </Modal.Body>
      {!needLogin && (
        <Modal.Footer>
          {!waitingValidation && (
            <>
              <Button size="medium" disabled={integrationType?.data.loading} onClick={close} testId="export-integration-modal-cancel-button">
                <FormattedMessage id="global.cancel" />
              </Button>
              <Button
                size="medium"
                variant="primary"
                onClick={handleExportToIntegration}
                disabled={isExportButtonDisabled}
                testId="export-integration-modal-submit-button"
              >
                <FormattedMessage
                  id={currentIntegration.selected ? 'global.export' : 'EXPORT_HERE'}
                />
              </Button>
            </>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ExportIntegrationModal;
