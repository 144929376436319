import { useEffect, useMemo, useRef, useState } from 'react';
import { useSuiteObject, useSuitePermissions } from '_common/suite/SuiteContext/SuiteContext';
import {
  VersionDescription,
  VersionOptions,
  VersionTitle,
  VersionUserPresentation,
} from '_common/suite/components/Card';
import { Card } from '_common/suite/components';
import styles from './PanelVersionCard.module.scss';
import { usePublicProfile, useSelector } from '_common/hooks';
import { selectUser } from 'Editor/redux/EditorStatusSlice';

export type VersionCardProps = {
  loadedVersion: number | null;
  onLoadVersion: (versionIndex: number | null) => void;
  onRestoreClick: (versionIndex: number) => void;
  onEditDescription?: (versionIndex: number) => void;
} & (
  | { currentVersion: true; versionIndex?: undefined; version?: undefined }
  | {
      currentVersion?: false;
      versionIndex: number;
      version: Exclude<FileObject['lifecycle']['versions'], undefined>[number];
    }
);

const PanelVersionCard = ({
  loadedVersion,
  onLoadVersion,
  onRestoreClick,
  onEditDescription,
  versionIndex,
  currentVersion,
  version,
}: VersionCardProps) => {
  const { canRestoreVersion } = useSuitePermissions();
  const versions = useSuiteObject().lifecycle.versions;
  const user = useSelector(selectUser);
  const profile = usePublicProfile(currentVersion ? user.id : version.creator);

  const testId = useMemo(() => {
    return `version${versionIndex != null ? `-${versionIndex}` : '-current'}`;
  }, [versionIndex]);

  const [isActive, setIsActive] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //If there is a loaded version, we are not in the current version
    if (loadedVersion != null && versions) {
      const activeIndex = versions.length - loadedVersion - 1;
      if (activeIndex === versionIndex) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
    //If there isn't a loadedVersion, we are on the current version
    else {
      //If the card doesnt have versionIndex, its the "Current Version" card
      if (versionIndex === undefined) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
  }, [loadedVersion, versions, versionIndex]);

  useEffect(() => {
    if (ref.current) {
      if (isActive) {
        ref.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    }
  }, [isActive]);

  const handleActiveVersion = () => {
    const ind = versionIndex != null && versions ? versions.length - versionIndex - 1 : null;
    if (loadedVersion !== ind) {
      onLoadVersion(ind);
    }
  };

  const handleRestoreVersion = () => {
    if (!currentVersion && versionIndex != null && versions) {
      const ind = versions.length - versionIndex - 1;
      onRestoreClick(ind);
    }
  };

  const handleEditDescription = () => {
    if (!currentVersion && versionIndex != null && versions) {
      const ind = versions.length - versionIndex - 1;
      onEditDescription?.(ind);
    }
  };

  return (
    <div onClick={handleActiveVersion} className={styles.root} ref={ref}>
      <Card sidebar width="auto" selected={isActive} testId={`${testId}-card`}>
        <div className={currentVersion ? styles.currentVersionCard : styles.card}>
          <Card.Header>
            <Card.Header.Left>
              <VersionTitle
                versions={versions}
                versionIndex={versionIndex}
                currentVersion={currentVersion}
                user={profile.name}
              />
            </Card.Header.Left>
            {!currentVersion && (
              <Card.Header.Right>
                <VersionOptions
                  restoreVersion={{ disabled: !canRestoreVersion, onClick: handleRestoreVersion }}
                  editDescription={
                    onEditDescription ? { onClick: handleEditDescription } : undefined
                  }
                  testId={testId}
                />
              </Card.Header.Right>
            )}
          </Card.Header>
          {version && (
            <Card.Body>
              <VersionUserPresentation version={version} />
              <VersionDescription description={version.description} />
            </Card.Body>
          )}
        </div>
      </Card>
      <div
        className={`${styles.indicator} ${isActive && styles.active}`}
        data-testid={`${testId}-indicator`}
      />
    </div>
  );
};

export default PanelVersionCard;
