import { useDispatch } from '_common/hooks';
import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';
import { ShortcutsPopover } from '_common/components';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

type EquationOptionsWidgetProps = {
  offsets: Editor.Common.Rect | null;
};

const EquationOptionsWidgetView = ({ offsets }: EquationOptionsWidgetProps) => {
  const dispatch = useDispatch();

  const handleInsertCaption = () => {
    dispatch(openAndUpdateModal({ modal: 'CaptionsModal', data: { editModal: 'insert' } }));
  };

  return (
    <ShortcutsPopover testId='equation' offsets={offsets}>
      <div style={{ padding: '1rem' }}>
        <Button
          size="medium"
          variant="link"
          onClick={handleInsertCaption}
          testId="equation-insert-caption"
        >
          <FormattedMessage id="INSERT_CAPTION" />
        </Button>
      </div>
    </ShortcutsPopover>
  );
};

export default EquationOptionsWidgetView;
