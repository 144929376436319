import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Icon, usePopper } from 'dodoc-design-system';

import { useDispatch, usePublicGroup, usePublicProfile } from '_common/hooks';

import { BaseRow, RowCell, UserAvatar, UsernameLabel } from '_common/components';
import { BaseRowProps } from '_common/components/Table/BaseRow/BaseRow';

import GroupSettingsContext from '../GroupSettingsContext';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

type AdminsViewRowProps = Pick<
  BaseRowProps,
  'style' | 'objectId' | 'selected' | 'clickHandles' | 'selectable' | 'dataTestId'
>;

const AdminsViewRow = ({
  style,
  objectId: userId,
  selected,
  clickHandles,
  selectable,
  dataTestId,
}: AdminsViewRowProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { groupId } = useContext(GroupSettingsContext);
  const { popperProps, referenceProps } = usePopper({
    placement: 'left-start',
  });

  const { group } = usePublicGroup(groupId || '');
  const profile = usePublicProfile(userId);
  const userIsOwner =
    group.user_permissions.includes('admin') || group.user_permissions.includes('owner');
  const subjectIsOwner = group.owner === userId;

  if (!groupId) {
    return null;
  }

  const handleRemoveUser = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'REMOVE_SOMETHING_QUOTED',
          titleValues: { label: profile.name },
          message: 'REMOVING_THIS_USER__FROM_GROUP_WILL_REVOKE_PERMISSIONS_CONFIRM',
          confirmButtonTextId: 'global.remove',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'removeGroupPermission',
          actionValue: { groupId, userId },
          headerType: 'error',
          cancelButtonShow: true,
        },
      }),
    );
  };

  const renderPermission = () => {
    let permission = intl.formatMessage({ id: 'groups.header.member' }, { membersCount: 1 });

    if (group.permissions.users[profile.id]) {
      if (group.permissions.users[profile.id].includes('owner')) {
        permission = intl.formatMessage({ id: 'groups.admin' });
      } else {
        permission = 'Other Role';
      }
    }

    if (group.owner === profile.id) {
      permission = intl.formatMessage({ id: 'groups.header.owner' });
    }

    return permission;
  };

  return !group || profile.type === 'removed' ? (
    <UsernameLabel />
  ) : (
    <BaseRow
      style={style}
      objectId={userId}
      selected={selected}
      selectable={selectable}
      clickHandles={clickHandles}
      dataTestId={dataTestId}
    >
      <RowCell size="6.5">
        <UserAvatar userId={userId} size="large" />
      </RowCell>
      <RowCell>{profile.name}</RowCell>
      <RowCell size="40">{profile.email}</RowCell>
      <RowCell size="20">{renderPermission()}</RowCell>
      <RowCell size="8">
        {userIsOwner && !subjectIsOwner && (
          <>
            <div {...referenceProps}>
              <Icon icon="Options" size={24} />
            </div>
            <Dropdown {...popperProps} testId="options-dropdown">
              <Dropdown.Item onClick={handleRemoveUser} testId="remove-user-dropdown-item">
                <FormattedMessage id="groups.removeUser" />
              </Dropdown.Item>
            </Dropdown>
          </>
        )}
      </RowCell>
    </BaseRow>
  );
};

export default AdminsViewRow;
