import { useState } from 'react';
import { useIntl } from 'react-intl';
import LinesEllipsis, { LinesEllipsisLooseProps } from 'react-lines-ellipsis';

import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { FormattedDate } from '_common/components';
import { Setting } from 'Settings/components';

import styles from './RoleGeneralTab.module.scss';

type RoleGeneralTabProps = { role: Role };

const RoleGeneralTab = ({ role }: RoleGeneralTabProps) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(false);
  const [clamped, setClamped] = useState(false);

  const handleChangeRoleName = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: {
          header: intl.formatMessage({ id: 'settings.roles.editRoleName' }),
          label: intl.formatMessage({ id: 'settings.roles.roleName' }),
          value: role.name,
          inputPlaceholder: intl.formatMessage({ id: 'ROLE_NAME_PLACEHOLDER' }),
          inputType: 'text',
          type: role.name ? 'name' : undefined,
          action: 'renameRole',
          actionValues: { id: role.id },
        },
      }),
    );
  };

  const handleChangeRoleDescription = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: {
          header: intl.formatMessage({ id: 'settings.roles.editRoleDescription' }),
          label: intl.formatMessage({ id: 'settings.roles.roleDescription' }),
          value: role.description,
          inputPlaceholder: intl.formatMessage({ id: 'ROLE_DESCRIPTION_PLACEHOLDER' }),
          inputType: 'textarea',
          type: 'description',
          action: 'editRoleDescription',
          actionValues: { id: role.id, type: 'role' },
        },
      }),
    );
  };

  const handleChangeExpanded = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const handleOnReflow: LinesEllipsisLooseProps['onReflow'] = (data) => {
    if (clamped !== data.clamped) {
      setClamped(data.clamped);
    }
  };

  return (
    <div className={styles.root}>
      <Setting
        style={{ alignItems: 'flex-start' }}
        label={intl.formatMessage({ id: 'global.name' })}
        value={role.name}
        onEdit={handleChangeRoleName}
        onEditLabel="global.edit"
        testId="name"
      />
      <Setting
        style={{ alignItems: 'flex-start' }}
        label={intl.formatMessage({ id: 'global.creationDate' })}
        value={<FormattedDate date={role.time.creation} />}
        testId="creation-date"
      />
      <Setting
        isDescription
        clamped={clamped}
        expanded={isExpanded}
        changeExpanded={handleChangeExpanded}
        style={{ alignItems: 'flex-start' }}
        label={intl.formatMessage({ id: 'global.description' })}
        value={
          <LinesEllipsis
            // ref={content}
            text={
              role && role.description
                ? role.description
                : intl.formatMessage({ id: 'NO_DESCRIPTION' })
            }
            maxLine={isExpanded ? 20 : 3}
            ellipsis="..."
            trimRight
            basedOn="letters"
            onReflow={handleOnReflow}
          />
        }
        onEdit={handleChangeRoleDescription}
        onEditLabel="global.edit"
        testId="description"
      />
    </div>
  );
};

export default RoleGeneralTab;
