import { FormattedMessage } from 'react-intl';
import { setRenamingDocumentValue, setVersionHistoryValue } from 'Editor/redux/EditorStatusSlice';
import EditorManager from 'Editor/services/EditorManager';
import { InteractionController, NotificationsCenter } from '_common/components';
import { useDispatch, useSelector } from '_common/hooks';
import { useSuiteObject } from '_common/suite';
import {
  AvatarList,
  EditableName,
  ExportButton,
  Logo,
  ObjectStatus,
  ShareButton,
  Title,
  UserCenter,
  BackToButton,
  IEnvisionLabel,
} from '_common/suite/components';
import { setSidebarView } from 'Editor/redux/SidebarSlice';

const EditorTitle = () => {
  const dispatch = useDispatch();
  const usersOnline = useSelector((state) => state.editor.status.usersOnline);
  const object = useSuiteObject();
  const renamingDocument = useSelector((state) => state.editor.status.renamingDocument);
  const versionHistory = useSelector((state) => state.editor.status.versionHistory);

  const handleRenamingChange = (renaming: boolean) => {
    dispatch(setRenamingDocumentValue(renaming));
  };

  const closeVersionHistory = () => {
    dispatch(setVersionHistoryValue(false));
    EditorManager.getInstance().backToCurrentVersion();
    dispatch(setSidebarView(null));
  };

  return (
    <Title>
      <InteractionController environment="editor" style={{ width: '100%', alignItems: 'center' }}>
        <Logo object={object} />
        <EditableName
          object={object}
          isRenaming={renamingDocument}
          onRenamingChange={handleRenamingChange}
        />
        <ObjectStatus object={object} />
        <IEnvisionLabel />
        {versionHistory ? (
          <BackToButton onClick={closeVersionHistory} testId="editor">
            <FormattedMessage id="BACK_TO_EDITOR" />
          </BackToButton>
        ) : (
          <>
            <AvatarList users={usersOnline ?? []} />
            <ShareButton object={object} />
            <ExportButton object={object} />
            <NotificationsCenter object={object} margin="0 0 0 2rem" />
            <UserCenter />
          </>
        )}
      </InteractionController>
    </Title>
  );
};

export default EditorTitle;
