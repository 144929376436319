import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import { useSelector } from '_common/hooks';
import { useListNavigationQuery, useListTemplateQuery } from '_common/services/api/realtime';

const useConditionalData = () => {
  const exportDocumentId = useSelector((state) => state.modals.ExportDocumentModal.objectId);
  const isEditor = useSelector((state) => state.editor.status.visible);

  //Navigation
  const editorNavigation = useSelector((state) => state.editor.toc);
  const { data: explorerNavigation } = useListNavigationQuery(exportDocumentId, {
    skip: isEditor,
  });
  const { list: navigationList, data: navigationData } = isEditor
    ? editorNavigation
    : explorerNavigation ?? { list: [], data: {} };

  //Object
  const editorObject = useSelector(getDocumentObject);
  const explorerObject = useSelector((state) => state.app.data[exportDocumentId]);
  const object = isEditor ? editorObject : explorerObject;

  //Template
  const { data: explorerTemplate } = useListTemplateQuery(exportDocumentId, { skip: isEditor });
  const template = isEditor ? object?.template : explorerTemplate?.template;

  return { navigationList, navigationData, template, object };
};

export default useConditionalData;
