import { useState } from 'react';
import { useIntl } from 'react-intl';
import styles from './Note.module.scss';

const Note = () => {
  const intl = useIntl();
  const [value, setValue] = useState('');
  return (
    <textarea
      className={styles.note}
      placeholder={intl.formatMessage({ id: 'CLICK_TO_ADD_NOTES_TO_THIS_SLIDE' })}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

export default Note;
