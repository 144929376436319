import { createSelector, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { InstanceService } from '_common/services';
import { setCreating, setEditingAnnotation } from './PDFAnnotationsSlice';

const ZOOM_FACTOR = 1.33;

export type CursorMode =
  | 'normal'
  | 'pan'
  | 'Note'
  | 'Task'
  | 'Highlight'
  | 'Underline'
  | 'StrikeOut'
  | 'Line'
  | 'Arrow'
  | 'Circle'
  | 'Square'
  | 'Ink'
  | 'FreeText';
export type RightPanel = 'details' | 'annotations' | 'tasks' | 'versionhistory';

type PDFGeneralSliceState = {
  pdfId: ObjectId | null;
  cursorMode: CursorMode;
  leftPanel: string;
  rightPanel?: RightPanel;
  zoom: number;
  usersOnline: UserId[];
  currentPageNumber: number;
  versionHistory: boolean;
  loadedVersion: number | null;
  exporting: string | null;
  annotationToFocus?: {
    objectId: ObjectId;
    documentId: ObjectId;
  };
};

const SLICE_NAME = 'PDF';
const initialState: PDFGeneralSliceState = {
  pdfId: null,
  cursorMode: 'normal',
  leftPanel: '',
  zoom: 1,
  usersOnline: [],
  currentPageNumber: 1,
  versionHistory: false,
  loadedVersion: null,
  exporting: null,
};

// #region AsyncThunks
export const exportPDF = createAsyncThunk(
  `${SLICE_NAME}/exportPDF`,
  async ({ id, options }: { id: ObjectId, options: MyAny }) => {
    const { data } = await new InstanceService().exportPDF(id, options);
    return data;
  },
);

export const getExportPDF = createAsyncThunk(
  `${SLICE_NAME}/getExportPDF`,
  async ({ id }: { id: ObjectId }) => {
    const { data } = await new InstanceService().getExportPDF(id);
    return data;
  },
);

export const setZoomValue = createAsyncThunk('setZoomValue', (value: number, { getState }) => {
  let newZoomValue = value;
  const defaultZoomValue = [
    '0.1',
    '0.25',
    '0.5',
    '0.75',
    '1',
    '1.25',
    '1.5',
    '2',
    '4',
    '8',
    '16',
    '24',
    '32',
    '64',
  ];
  if (isNaN(newZoomValue)) {
    return 1;
  }

  if (value > 64) {
    newZoomValue = 64;
  }
  if (value < 0.1) {
    newZoomValue = 0.1;
  }

  if (
    (newZoomValue <= 64 || newZoomValue >= 0.1) &&
    defaultZoomValue.filter((defaultValue) => Number(defaultValue) === value).length === 0
  ) {
    const multipiedValue = newZoomValue * 100;
    const roundValue = Math.round(multipiedValue / 10) * 10;
    newZoomValue = roundValue / 100;
  }

  return newZoomValue;
});

// #endregion

// #region Selectors
export const selectPDFZoom = createSelector(
  [(state: RootState) => state.pdf.general.zoom],
  (zoom) => zoom * ZOOM_FACTOR,
);
// #endregion

// #region Slice
const PDFSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCursorMode: (state, action: PayloadAction<CursorMode>) => {
      state.cursorMode = action.payload;
    },
    setLeftPanel: (state, action: PayloadAction<string>) => {
      state.leftPanel = action.payload;
    },
    setRightPanel: (state, action: PayloadAction<PDFGeneralSliceState['rightPanel']>) => {
      state.rightPanel = action.payload;
    },
    setUsersOnline: (state, action: PayloadAction<Array<UserId>>) => {
      state.usersOnline = action.payload;
    },
    addUserOnline: (state, action: PayloadAction<PDFGeneralSliceState['usersOnline'][number]>) => {
      state.usersOnline.push(action.payload);
    },
    removeUserOnline: (
      state,
      action: PayloadAction<PDFGeneralSliceState['usersOnline'][number]>,
    ) => {
      const index = state.usersOnline.indexOf(action.payload);
      if (index > -1) {
        state.usersOnline.splice(index, 1);
      }
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPageNumber = action.payload;
    },
    setVersionHistory: (state, action: PayloadAction<PDFGeneralSliceState['versionHistory']>) => {
      state.versionHistory = action.payload;
    },
    setLoadedVersion: (state, action: PayloadAction<PDFGeneralSliceState['loadedVersion']>) => {
      state.loadedVersion = action.payload;
    },
    setPDFId: (state, action: PayloadAction<PDFGeneralSliceState['pdfId']>) => {
      state.pdfId = action.payload;
    },
    setAnnotationToFocus: (
      state,
      action: PayloadAction<PDFGeneralSliceState['annotationToFocus']>,
    ) => {
      state.annotationToFocus = action.payload;
    },
    setExporting: (state, action: PayloadAction<PDFGeneralSliceState['exporting']>) => {
      state.exporting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCreating, (state, action) => {
        if (action.payload && state.rightPanel && state.rightPanel !== 'tasks') {
          state.rightPanel = 'tasks';
        }
      })
      .addCase(setZoomValue.fulfilled, (state, action) => {
        state.zoom = action.payload;
      })
      .addCase(exportPDF.fulfilled, (state, action) => {
        state.exporting = action.payload.id;
      })
      .addCase(getExportPDF.fulfilled, (state, action) => {
        state.exporting = null;
      })
      .addCase(setEditingAnnotation, (state, action) => {
        if (action.payload.id && state.rightPanel) {
          state.rightPanel = action.payload.isTask ? 'tasks' : 'annotations';
        }
      });
  },
});

// #endregion

// #region Actions
export const {
  setCursorMode,
  setLeftPanel,
  setRightPanel,
  setUsersOnline,
  addUserOnline,
  removeUserOnline,
  setCurrentPage,
  setLoadedVersion,
  setPDFId,
  setVersionHistory,
  setAnnotationToFocus,
  setExporting,
} = PDFSlice.actions;
// #endregion

const persistConfig = {
  key: 'general',
  storage,
  whitelist: ['annotationToFocus'],
};

const pdfReducer = persistReducer(persistConfig, PDFSlice.reducer);

export default pdfReducer;
