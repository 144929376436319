import { Logger } from '_common/services';
import { ContentManipulator } from '../ContentManipulator';
import {
  InsertElementOperation,
  InsertTextOperation,
  RemoveContentOperation,
} from '../../Operations';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager';
import { RemoveBlockOperation } from '../../Operations/StructureOperations';

export class NormalManipulator extends ContentManipulator {
  insertText(
    ctx: Editor.Edition.ActionContext,
    path: Editor.Selection.Path,
    text: string,
  ): boolean {
    if (this.debug) {
      Logger.trace('NormalManipulator insertText', ctx, ctx.baseModel, text);
    }

    if (!ctx.baseModel) {
      return false;
    }

    // TODO
    // check if path is valid
    // is selection editable
    // is insertion allowed
    // check styles to apply

    // insert text or create new text element
    const operation = new InsertTextOperation(ctx.baseModel, path, text).apply();

    // adjust selection
    const resultPath = operation.getResultPath();
    if (ctx.range && resultPath) {
      ctx.range.updateRangePositions({
        b: ctx.range.start.b,
        p: resultPath,
      });
    }

    return true;
  }

  insertElement(
    ctx: Editor.Edition.ActionContext,
    path: Editor.Selection.Path,
    newData: Editor.Data.Node.Data,
    options: Editor.Edition.InsertElementOptions = {},
  ): boolean {
    if (this.debug) {
      Logger.trace('NormalManipulator insertElement', ctx.baseModel, path, newData);
    }

    if (!ctx.baseModel || !ctx.baseData) {
      return false;
    }

    const parent = NodeUtils.getParentOfChildInfoByPath(ctx.baseData, path);

    if (!parent) {
      return false;
    }

    // is insertion allowed
    if (!NodeUtils.isAllowedUnder(parent.data.type, newData.type)) {
      throw new Error('Element insertion not allowed!! ' + parent.data.type + ' ' + newData.type);
    }

    if (options.forceBlockSplit) {
      // TODO:
      // force block split
    } else if (options.forceInlineSplit) {
      // TODO:
      // force inline styles split
    }

    // TODO
    // check if path is valid
    // is selection editable
    // check styles to apply

    // insert text or create new text element
    const operation = new InsertElementOperation(ctx.baseModel, path, newData).apply();

    // adjust selection
    const resultPath = operation.getResultPath();
    if (ctx.range && resultPath) {
      ctx.range.updateRangePositions({
        b: ctx.range.start.b,
        p: resultPath,
      });
    }

    return true;
  }

  removeContent(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.editionContext.DataManager) {
      return false;
    }

    if (this.debug) {
      Logger.trace('NormalManipulator removeContent', ctx);
    }

    // TODO:
    // check range positions
    // check if path is valid
    // is selection editable

    let structureModel = this.editionContext.DataManager.structure.structureModel;

    let rangesToRemove: Editor.Selection.JsonRange[] = [];

    if (!structureModel) {
      return false;
    }

    // let operation;

    let startModel = this.editionContext.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    if (!startModel) {
      return false;
    }

    // TODO: check for figures and other elements

    if (ctx.range.start.b === ctx.range.end.b) {
      rangesToRemove.push(ctx.range);

      // operation = new RemoveContentOperation(ctx.baseModel, jsonRange.start.p, jsonRange.end.p);
      // operation.apply();
    } else {
      rangesToRemove = JsonRange.splitRangeByBlocks(this.editionContext.DataManager, ctx.range);
    }

    let operations = [];
    let resultPath: Editor.Selection.Path | undefined;

    let op: Editor.Edition.IOperationBuilder;

    // todo: check whole selection is editable
    // if (!this.editionContext.DataManager.nodes.isNodeEditable(baseModel.id)) {
    //   continue;
    // }

    for (let i = 0; i < rangesToRemove.length; i++) {
      const range = rangesToRemove[i];

      const baseModel = this.editionContext.DataManager.nodes.getNodeModelById(range.start.b);

      const baseData = baseModel?.selectedData();
      if (!baseModel || !baseData) {
        continue;
      }

      if (i === 0) {
        // FIRST ELEMENT
        // TODO: check for suggestion paragraph markers

        const result = NodeUtils.closestOfTypeByPath(baseData, range.start.p, ['p']);
        const textElementData = result?.data;

        if (textElementData && !range.isCollapsed()) {
          op = new RemoveContentOperation(baseModel, range.start.p, range.end.p);
          op.apply();
          operations.push(op);
          resultPath = op.getResultPath();
        }
      } else if (i === rangesToRemove.length - 1) {
        // LAST ELEMENT

        let startData = startModel?.selectedData();
        if (!startData) {
          continue;
        }

        // TODO: check inside containers
        // TODO: check if is mergeable

        const result = NodeUtils.closestOfTypeByPath(baseData, range.start.p, ['p']);
        const textElementData = result?.data;

        if (textElementData) {
          // join content
          let startOffset = startData.childNodes?.length || 0;

          const clonedNodes = NodeUtils.cloneData(baseData, range.end.p, [
            'childNodes',
            baseData.childNodes?.length || 0,
          ]);

          let pathToMerge: Editor.Selection.Path | undefined = ['childNodes', startOffset];

          for (let i = 0; i < clonedNodes.length; i++) {
            if (pathToMerge) {
              op = new InsertElementOperation(startModel, pathToMerge, clonedNodes[i]);
              op.apply();
              operations.push(op);
              pathToMerge = op.getResultPath();
            }
          }

          op = new RemoveBlockOperation(structureModel, baseModel.id);
          op.apply();
          operations.push(op);
        }
      } else {
        // MIDDLE ELEMENTS

        // TODO: check inside containers

        op = new RemoveBlockOperation(structureModel, baseModel.id);
        op.apply();
        operations.push(op);
      }
    }

    if (resultPath) {
      ctx.range.updateRangePositions({
        b: ctx.range.start.b,
        p: resultPath,
      });
    } else {
      ctx.range.collapse(true);
    }

    return true;
  }

  insertBlock(ctx: Editor.Edition.ActionContext, blockData: Editor.Data.Node.Data): boolean {
    return false;
  }

  removeBlock(ctx: Editor.Edition.ActionContext, blockId: string): boolean {
    return false;
  }
}
