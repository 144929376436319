import { usePresentationManager } from '../PresentationContext';
import { useCallback, useSyncExternalStore } from 'react';
import { RealtimeSyncStore } from './RealtimeSyncStore';
import { useParams } from 'react-router';

let store: RealtimeSyncStore;

export function useObjectSync<T extends Presentation.Data.Models.TypeName>(type: T, id: string) {
  const manager = usePresentationManager();
  if (!store) {
    const data = manager.getData();
    store = new RealtimeSyncStore(data);
  }
  const subscribe = useCallback(
    (listener: () => void) => {
      return store.subscribe(type, id, listener);
    },
    [type, id],
  );
  const getSnapshot = useCallback(() => {
    return store.getSnapshot(type, id);
  }, [type, id]);
  return useSyncExternalStore(subscribe, getSnapshot) as Presentation.Data.Models.DataTypes[T];
}

export function useStructureSync() {
  const { id } = useParams<{ id: string }>();
  return useObjectSync('STRUCTURE', `PS${id}`);
}

export function useSlideSync(id: string) {
  return useObjectSync('SLIDE', id);
}

export function useUsersSync(): string[] {
  const manager = usePresentationManager();
  if (!store) {
    const data = manager.getData();
    store = new RealtimeSyncStore(data);
  }
  const subscribe = useCallback((listener: () => void) => {
    return store.subscribe('USERS', 'USERS', listener);
  }, []);
  const getSnapshot = useCallback(() => {
    return store.getSnapshot('USERS', 'USERS');
  }, []);
  return useSyncExternalStore(subscribe, getSnapshot);
}
