import { updateIntl } from 'react-intl-redux';
import type { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { notify } from '_common/components/ToastSystem';

import authority from '_common/services/api/authority';
import authApi from '_common/services/api/authority';
import { paths } from '_types/authority';

import enStrings from 'locales/en/translation.json';
import esStrings from 'locales/es/translation.json';
import frStrings from 'locales/fr/translation.json';
import deStrings from 'locales/de/translation.json';
import zhStrings from 'locales/zh-CN/translation.json';
import jaStrings from 'locales/ja/translation.json';

type APIError = {
  error: { data: any; status: number };
};

export type ServiceList = {
  createAndDelete: paths['/api/authority/authenticator/service/list']['get']['responses']['200']['content']['application/json']['create_and_delete'];
  providers: Provider[];
};

const userSettingsApi = authority.injectEndpoints({
  endpoints: (builder) => ({
    //#region Profile Settings
    changeLanguage: builder.mutation<void, { language: Language['code'] }>({
      query: (params) => ({
        url: `/user/profile/edit`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [{ type: 'User', id: 'Current' }],
      /** Optimistic save mechanism
       *  Source: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      async onQueryStarted({ language }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          authApi.util.updateQueryData('getCurrentUser', undefined, (draft) => {
            //Optimistic save
            draft.profile.language = language;

            //Update Intl
            let messages;
            switch (language) {
              case 'en':
                messages = enStrings;
                break;
              case 'es':
                messages = esStrings;
                break;
              case 'fr':
                messages = frStrings;
                break;
              case 'de':
                messages = deStrings;
                break;
              case 'zh-CN':
                messages = zhStrings;
                break;
              case 'ja':
                messages = jaStrings;
                break;
            }
            dispatch(updateIntl({ locale: language, messages }));
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          //Due to request fail, undo optimistic save
          patchResult.undo();
        }
      },
    }),

    changeEmail: builder.mutation<
      paths['/api/authority/user/email/change']['post']['responses']['200'],
      { email: UserProfile['profile']['email']; userId?: UserId }
    >({
      query: (payload) => {
        const params = { ...payload };
        delete params.userId;

        return {
          url: `/user/email/change`,
          method: 'POST',
          body: params,
          errorsExpected: [400, 403],
        };
      },
      invalidatesTags: (result, error, args) => {
        const tags: TagDescription<'User' | 'PublicProfile'>[] = [{ type: 'User', id: 'Current' }];

        if (args.userId) {
          tags.push({ type: 'PublicProfile', id: args.userId });
        }
        return tags;
      },
      async onQueryStarted({ email }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          authApi.util.updateQueryData('getCurrentUser', undefined, (draft) => {
            //Optimistic save
            draft.profile.email = email;
          }),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          const typedError = (error as APIError).error;
          //Due to request fail, undo optimistic save
          patchResult.undo();

          //Handle request error
          if (typedError.status === 400) {
            if (typedError.data.errors[0].errors[0] === 'same') {
              notify({
                type: 'error',
                title: 'auth.errors.invalidEmail',
                message: 'auth.errors.sameEmail',
              });
            } else if (
              typedError.data.errors[0].errors[0] === 'used' ||
              typedError.data.errors[0].errors[0] === 'in_use'
            ) {
              notify({
                type: 'error',
                title: 'auth.errors.invalidEmail',
                message: 'auth.errors.usedEmail',
              });
            } else if (typedError.data.errors[0].errors[0] === 'invalid') {
              notify({
                type: 'error',
                title: 'global.error',
                message: 'auth.errors.invalidEmail',
              });
            }
          } else if (typedError.status === 403) {
            notify({
              type: 'error',
              title: 'global.error',
              message: 'validation.email.notAllowed',
            });
          }
        }
      },
    }),

    checkEmailStatus: builder.mutation<
      paths['/api/authority/user/email/change/status']['get']['responses']['200'],
      void
    >({
      query: () => ({
        url: `/user/email/change/status`,
      }),
    }),

    resendNewEmail: builder.mutation<void, void>({
      query: () => ({
        url: `/user/email/change/resend/new`,
      }),
    }),

    editProfile: builder.mutation<
      void,
      paths['/api/authority/user/profile/edit']['post']['requestBody']['content']['multipart/form-data'] & {
        userId?: UserId;
      }
    >({
      query: (payload) => {
        const params = { ...payload };
        delete params.userId;

        return {
          url: `/user/profile/edit`,
          method: 'POST',
          body: params,
        };
      },
      invalidatesTags: (result, error, args) => {
        const tags: TagDescription<'User' | 'PublicProfile'>[] = [{ type: 'User', id: 'Current' }];

        if (args.userId) {
          tags.push({ type: 'PublicProfile', id: args.userId });
        }
        return tags;
      },
      /** Optimistic save mechanism
       *  Source: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          authApi.util.updateQueryData('getCurrentUser', undefined, (draft) => {
            //Optimistic save
            Object.assign(draft.profile, patch);
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          //Due to request fail, undo optimistic save
          patchResult.undo();
        }
      },
    }),
    //#endregion
    //#region Sessions
    listSessions: builder.query<Session[], void>({
      query: () => ({
        url: `/token/list`,
      }),
      providesTags: ['Session'],
    }),
    deleteSession: builder.mutation<void, Session['token']>({
      query: (tokenId) => ({
        url: `/token/${tokenId}/delete`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Session'],
      /** Optimistic save mechanism
       *  Source: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      async onQueryStarted(tokenId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userSettingsApi.util.updateQueryData('listSessions', undefined, (draft) => {
            //Optimistic save
            draft.splice(
              draft.findIndex((session) => session.token === tokenId),
              1,
            );
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          //Due to request fail, undo optimistic save
          patchResult.undo();
        }
      },
    }),
    //#endregion
    //#region Devices
    listDevices: builder.query<Device[], void>({
      query: () => ({
        url: `/device/list`,
      }),
      providesTags: ['Device'],
    }),
    deleteDevice: builder.mutation<void, Device['token']>({
      query: (tokenId) => ({
        url: `/device/${tokenId}/delete`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Device'],
      /** Optimistic save mechanism
       *  Source: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      async onQueryStarted(tokenId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userSettingsApi.util.updateQueryData('listDevices', undefined, (draft) => {
            //Optimistic save
            draft.splice(
              draft.findIndex((device) => device.token === tokenId),
              1,
            );
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          //Due to request fail, undo optimistic save
          patchResult.undo();
        }
      },
    }),
    //#endregion
    //#region Connections
    listConnections: builder.query<ConnectionLink[], void>({
      query: () => ({
        url: `/authenticator/connection/list`,
      }),
      providesTags: ['Connection'],
    }),
    deleteConnection: builder.mutation<void, ConnectionLink['pk']>({
      query: (connectionPk) => ({
        url: `/authenticator/connection/${connectionPk}/delete`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Connection'],
      /** Optimistic save mechanism
       *  Source: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      async onQueryStarted(connectionPk, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userSettingsApi.util.updateQueryData('listConnections', undefined, (draft) => {
            //Optimistic save
            draft.splice(
              draft.findIndex((connection) => connection.pk === connectionPk),
              1,
            );
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          //Due to request fail, undo optimistic save
          patchResult.undo();
        }
      },
    }),
    //#endregion
    //#region Services
    listServices: builder.query<ServiceList, void>({
      query: () => ({
        url: `/authenticator/service/list`,
      }),
      providesTags: ['Service'],
      transformResponse: (
        responseData: paths['/api/authority/authenticator/service/list']['get']['responses']['200']['content']['application/json'],
      ) => {
        const { providers, create_and_delete } = responseData;

        const idFilledProviders: Provider[] = [];
        providers?.forEach((provider, index) => {
          idFilledProviders[index] = provider;
          idFilledProviders[index].id = index + '';
        });

        return { createAndDelete: create_and_delete, providers: idFilledProviders };
      },
    }),
    //#endregion
  }),
});

// Export queries and mutations
export const {
  useChangeLanguageMutation,
  useChangeEmailMutation,
  useCheckEmailStatusMutation,
  useResendNewEmailMutation,
  useEditProfileMutation,
  useListSessionsQuery,
  useDeleteSessionMutation,
  useListDevicesQuery,
  useDeleteDeviceMutation,
  useListConnectionsQuery,
  useDeleteConnectionMutation,
  useListServicesQuery,
} = userSettingsApi;

export default userSettingsApi;
