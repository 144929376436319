import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import Footer from './Footer';
import styles from './Footer.module.scss';

const FooterBoundary = () => (
  <div className={styles.footer}>
    <IntlErrorBoundary size="xsmall" margin="auto">
      <Footer />
    </IntlErrorBoundary>
  </div>
);

export default FooterBoundary;
