import { ELEMENTS } from 'Editor/services/consts';
import { Logger } from '_common/services';

export class ViewMapper {
  static getTagForElementType(
    type: Editor.Elements.ElementTypesType,
  ): Editor.Elements.ElementTagsType {
    switch (type) {
      case ELEMENTS.ParagraphElement.ELEMENT_TYPE:
        return ELEMENTS.ParagraphElement.TAG;
      case ELEMENTS.FigureElement.ELEMENT_TYPE:
        return ELEMENTS.FigureElement.TAG;
      case ELEMENTS.ImageElement.LEGACY_TYPE:
      case ELEMENTS.ImageElement.ELEMENT_TYPE:
        return 'IMAGE-ELEMENT';
      case ELEMENTS.HyperlinkElement.ELEMENT_TYPE:
        return ELEMENTS.HyperlinkElement.TAG;
      case 'text':
        return 'TEXT';
      case 'redacted':
        return ELEMENTS.RedactedElement.TAG;
      case ELEMENTS.TableElement.ELEMENT_TYPE:
        return ELEMENTS.TableElement.TAG;
      case ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.ELEMENT_TYPE:
        return ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG;
      case ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE:
        return ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG;
      case ELEMENTS.TableCellElement.ELEMENT_TYPE:
        return ELEMENTS.TableCellElement.TAG;
      case ELEMENTS.CommentElement.ELEMENT_TYPE:
        return ELEMENTS.CommentElement.TAG;
      case 'temp-comment':
        return ELEMENTS.TemporaryComment.TAG;
      case 'tracked-insert':
        return ELEMENTS.TrackInsertElement.TAG;
      case 'tracked-delete':
        return ELEMENTS.TrackDeleteElement.TAG;
      case ELEMENTS.ColumnBreakElement.ELEMENT_TYPE:
        return ELEMENTS.ColumnBreakElement.TAG;
      case ELEMENTS.PageBreakElement.ELEMENT_TYPE:
        return ELEMENTS.PageBreakElement.TAG;
      case ELEMENTS.SectionBreakElement.ELEMENT_TYPE:
        return ELEMENTS.SectionBreakElement.TAG;
      case ELEMENTS.TableOfContentsElement.ELEMENT_TYPE:
        return ELEMENTS.TableOfContentsElement.TAG;
      case ELEMENTS.KeywordsElement.ELEMENT_TYPE:
        return ELEMENTS.KeywordsElement.TAG;
      case ELEMENTS.AuthorsElement.ELEMENT_TYPE:
        return ELEMENTS.AuthorsElement.TAG;
      case ELEMENTS.ListOfFiguresElement.ELEMENT_TYPE:
        return ELEMENTS.ListOfFiguresElement.TAG;
      case ELEMENTS.ListOfTablesElement.ELEMENT_TYPE:
        return ELEMENTS.ListOfTablesElement.TAG;
      case ELEMENTS.FormatElement.ELEMENT_TYPE:
        return ELEMENTS.FormatElement.TAG;
      case ELEMENTS.CitationElement.ELEMENT_TYPE:
        return ELEMENTS.CitationElement.TAG;
      case ELEMENTS.CitationsGroupElement.ELEMENT_TYPE:
        return ELEMENTS.CitationsGroupElement.TAG;
      case 'symbol':
        return 'SYMBOL-ELEMENT';
      case 'note':
        return 'NOTE-ELEMENT';
      case ELEMENTS.CrossReferenceElement.ELEMENT_TYPE:
        return ELEMENTS.CrossReferenceElement.TAG;
      case 'equation':
        return 'EQUATION-ELEMENT';
      case ELEMENTS.PasteMarkerElement.ELEMENT_TYPE:
        return ELEMENTS.PasteMarkerElement.TAG;
      case ELEMENTS.FieldElement.ELEMENT_TYPE:
        return ELEMENTS.FieldElement.TAG;
      case ELEMENTS.ReferencesSectionElement.ELEMENT_TYPE:
        return ELEMENTS.ReferencesSectionElement.TAG;
      case ELEMENTS.TabElement.ELEMENT_TYPE:
        return ELEMENTS.TabElement.TAG;
      case ELEMENTS.LoaderElement.ELEMENT_TYPE:
        return ELEMENTS.LoaderElement.TAG;
      case ELEMENTS.ApprovedElement.ELEMENT_TYPE:
        return ELEMENTS.ApprovedElement.TAG;
      default: {
        Logger.warn(`Invalid element tag for: "${type}"`);
        return ELEMENTS.InvalidElement.TAG;
      }
    }
  }

  static getElementTypeForTag(
    tag: Editor.Elements.SupportedTagNames,
  ): Editor.Elements.ElementTypesType | null {
    switch (tag) {
      case ELEMENTS.ParagraphElement.TAG:
        return ELEMENTS.ParagraphElement.ELEMENT_TYPE;
      case ELEMENTS.TableElement.TAG:
        return ELEMENTS.TableElement.ELEMENT_TYPE;
      case ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG:
        return ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.ELEMENT_TYPE;
      case ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG:
        return ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE;
      case ELEMENTS.TableCellElement.TAG:
        return ELEMENTS.TableCellElement.ELEMENT_TYPE;
      case ELEMENTS.FigureElement.TAG:
        return ELEMENTS.FigureElement.ELEMENT_TYPE;
      case ELEMENTS.ImageElement.TAG:
        return ELEMENTS.ImageElement.ELEMENT_TYPE;
      case ELEMENTS.HyperlinkElement.TAG:
        return ELEMENTS.HyperlinkElement.ELEMENT_TYPE;
      case 'REDACTED-ELEMENT':
        return 'redacted';
      case ELEMENTS.ApprovedElement.TAG:
        return ELEMENTS.ApprovedElement.ELEMENT_TYPE;
      case ELEMENTS.CommentElement.TAG:
        return ELEMENTS.CommentElement.ELEMENT_TYPE;
      case 'TEMP-COMMENT-ELEMENT':
        return 'temp-comment';
      case 'TRACK-INS-ELEMENT':
        return 'tracked-insert';
      case 'TRACK-DEL-ELEMENT':
        return 'tracked-delete';
      case ELEMENTS.ColumnBreakElement.TAG:
        return ELEMENTS.ColumnBreakElement.ELEMENT_TYPE;
      case ELEMENTS.PageBreakElement.TAG:
        return ELEMENTS.PageBreakElement.ELEMENT_TYPE;
      case ELEMENTS.SectionBreakElement.TAG:
        return ELEMENTS.SectionBreakElement.ELEMENT_TYPE;
      case ELEMENTS.TableOfContentsElement.TAG:
        return ELEMENTS.TableOfContentsElement.ELEMENT_TYPE;
      case ELEMENTS.KeywordsElement.TAG:
        return ELEMENTS.KeywordsElement.ELEMENT_TYPE;
      case ELEMENTS.AuthorsElement.TAG:
        return ELEMENTS.AuthorsElement.ELEMENT_TYPE;
      case ELEMENTS.ListOfFiguresElement.TAG:
        return ELEMENTS.ListOfFiguresElement.ELEMENT_TYPE;
      case ELEMENTS.ListOfTablesElement.TAG:
        return ELEMENTS.ListOfTablesElement.ELEMENT_TYPE;
      case ELEMENTS.FormatElement.TAG:
        return ELEMENTS.FormatElement.ELEMENT_TYPE;
      case ELEMENTS.CitationElement.TAG:
        return ELEMENTS.CitationElement.ELEMENT_TYPE;
      case ELEMENTS.CitationsGroupElement.TAG:
        return ELEMENTS.CitationsGroupElement.ELEMENT_TYPE;
      case 'SYMBOL-ELEMENT':
        return 'symbol';
      case 'NOTE-ELEMENT':
        return 'note';
      case ELEMENTS.InvalidElement.TAG:
        return ELEMENTS.InvalidElement.ELEMENT_TYPE;
      case ELEMENTS.CrossReferenceElement.TAG:
        return ELEMENTS.CrossReferenceElement.ELEMENT_TYPE;
      case 'EQUATION-ELEMENT':
        return 'equation';
      case ELEMENTS.PasteMarkerElement.TAG:
        return ELEMENTS.PasteMarkerElement.ELEMENT_TYPE;
      case ELEMENTS.LoaderElement.TAG:
        return ELEMENTS.LoaderElement.ELEMENT_TYPE;
      case ELEMENTS.PlaceholderElement.TAG:
        return ELEMENTS.PlaceholderElement.ELEMENT_TYPE;
      case ELEMENTS.FieldElement.TAG:
        return ELEMENTS.FieldElement.ELEMENT_TYPE;
      case ELEMENTS.ReferencesSectionElement.TAG:
        return ELEMENTS.ReferencesSectionElement.ELEMENT_TYPE;
      case ELEMENTS.TabElement.TAG:
        return ELEMENTS.TabElement.ELEMENT_TYPE;
      case 'TEXT':
        return 'text';
      // frontend only elements
      case ELEMENTS.PermissionViewElement.TAG:
      case ELEMENTS.ApproveViewElement.TAG:
      case ELEMENTS.PageElement.TAG:
      case ELEMENTS.SectionElement.TAG:
      case ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER.TAG:
      case ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER_CELL.TAG:
      case 'STYLE':
      case 'IMG':
      case 'BR':
      case 'DIV':
      case 'SPAN':
        return null;

      default: {
        Logger.warn(`Invalid element type for: "${tag}"`);
        return ELEMENTS.InvalidElement.ELEMENT_TYPE;
      }
    }
  }
}
