import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RightPanel = 'details' | 'annotations' | 'tasks' | 'versionhistory' | null;
type PresentationSliceState = {
  showNav: boolean;
  showNotes: boolean;
  zoom: number;
  rightPanel: RightPanel;
};

const initialState: PresentationSliceState = {
  showNav: false,
  showNotes: false,
  zoom: 1,
  rightPanel: null,
};

const PresentationSlice = createSlice({
  name: 'presentation',
  initialState,
  reducers: {
    setShowNav: (state, action: PayloadAction<boolean>) => {
      state.showNav = action.payload;
    },
    setShowNotes: (state, action: PayloadAction<boolean>) => {
      state.showNotes = action.payload;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      let newZoomValue = action.payload;
      const defaultZoomValue = [
        '0.1',
        '0.25',
        '0.5',
        '0.75',
        '1',
        '1.25',
        '1.5',
        '2',
        '4',
        '8',
        '16',
        '24',
        '32',
        '64',
      ];
      if (isNaN(newZoomValue)) {
        state.zoom = 1;
      } else {
        if (action.payload > 64) {
          newZoomValue = 64;
        }
        if (action.payload < 0.1) {
          newZoomValue = 0.1;
        }

        if (
          defaultZoomValue.filter((defaultValue) => Number(defaultValue) === action.payload)
            .length === 0
        ) {
          const multipiedValue = newZoomValue * 100;
          const roundValue = Math.round(multipiedValue / 10) * 10;
          newZoomValue = roundValue / 100;
        }

        state.zoom = newZoomValue;
      }
    },
    setRightPanel: (state, action: PayloadAction<RightPanel>) => {
      state.rightPanel = action.payload;
    },
  },
});

export default PresentationSlice;

export const { setShowNav, setShowNotes, setZoom, setRightPanel } = PresentationSlice.actions;
