import { ElementNodeBuilder } from 'Editor/services/Model';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, TableCellElement } from '..';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export class TableCellViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<TableCellElement, Editor.Data.Node.TableCellData>
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<
    TableCellElement,
    Editor.Data.Node.TableCellData
  > = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: TableCellElement) => {
        node.setAttribute('element_type', ELEMENTS.TableCellElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // display
    d: {
      parse: (node: TableCellElement, builder: ElementNodeBuilder) => {
        if (node.style.display) {
          builder.addProperty('d', !(node.style.display === 'none'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: TableCellElement) => {
        if (json.properties?.d === false) {
          node.style.display = 'none';
        } else {
          node.style.removeProperty('display');
        }
      },
      remove: (node: TableCellElement) => {
        node.style.removeProperty('display');
      },
    },
    // border
    b: this.GENERIC_ATTRIBUTE_MAPPER.b,
    // padding
    p: this.GENERIC_ATTRIBUTE_MAPPER.p,
    // background
    bg: {
      parse: (node: TableCellElement, builder: ElementNodeBuilder) => {
        if (node.dataset.backgroundColor != null) {
          const bg: any = node.dataset.backgroundColor;
          if (bg != null) {
            if (
              bg === 'rgba(0, 0, 0, 0)' ||
              bg === 'transparent' ||
              bg === false ||
              bg === 'false'
            ) {
              builder.addProperty('bg', false);
            } else if (bg.includes('rgb')) {
              const hex: any = EditorDOMUtils.rgbToHex(bg);
              builder.addProperty('bg', hex.substring(1, bg.length).toUpperCase?.());
            } else if (bg.includes('#')) {
              builder.addProperty('bg', bg.substring(1, bg.length).toUpperCase?.());
            } else {
              builder.addProperty('bg', bg.toUpperCase?.());
            }
          }
        }
      },
      render: (json: Editor.Data.Node.Data, node: TableCellElement) => {
        if (json.properties?.bg != null) {
          const bg = json.properties.bg;
          if (bg != null) {
            node.dataset.backgroundColor = bg;
          }
        }
      },
      remove: (node: TableCellElement) => {
        delete node.dataset.backgroundColor;
      },
    },
    // horizontal align
    a: this.GENERIC_ATTRIBUTE_MAPPER.a,
    // vertical align
    va: {
      parse: (node: TableCellElement, builder: ElementNodeBuilder) => {
        if (node.dataset.verticalAlignment) {
          builder.addProperty(
            'va',
            BaseViewBuilder.verticalAlignmentMapper.parse(node.dataset.verticalAlignment),
          );
        }
      },
      render: (json: Editor.Data.Node.Data, node: TableCellElement) => {
        if (json.properties?.va) {
          node.dataset.verticalAlignment = BaseViewBuilder.verticalAlignmentMapper.render(
            json.properties.va,
          );
        }
      },
      remove: (node: TableCellElement) => {
        delete node.dataset.verticalAlignment;
      },
    },
    // colspan
    cs: {
      parse: (node: TableCellElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('colspan')) {
          builder.addProperty('cs', node.getAttribute('colspan'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: TableCellElement) => {
        if (json.properties?.cs != null) {
          node.setAttribute('colspan', `${json.properties.cs}`);
        }
      },
      remove: (node: TableCellElement) => {
        node.removeAttribute('colspan');
      },
    },
    // rowspan
    rs: {
      parse: (node: TableCellElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('rowspan')) {
          builder.addProperty('rs', node.getAttribute('rowspan'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: TableCellElement) => {
        if (json.properties?.rs != null) {
          node.setAttribute('rowspan', `${json.properties.rs}`);
        }
      },
      remove: (node: TableCellElement) => {
        node.removeAttribute('rowspan');
      },
    },
    'head-id': {
      parse: (node: TableCellElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('head-id')) {
          builder.addProperty('head-id', node.getAttribute('head-id'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: TableCellElement) => {
        if (json.properties?.['head-id'] != null) {
          node.setAttribute('head-id', json.properties['head-id']);
        }
      },
      remove: (node: TableCellElement) => {
        node.removeAttribute('head-id');
      },
    },
    // cell width
    w: {
      parse: (node, builder, attributeName) => {
        const parsedWidth: Partial<Editor.Data.Node.TableWidth> = {};
        if (node.dataset.width != null) {
          const width = node.dataset.width.split(',');
          parsedWidth.t = width[0] as Editor.Data.Node.TableWidthTypes;
          parsedWidth.v = +width[1];
        }
        builder.addProperty('w', parsedWidth);
      },
      render: (json, node) => {
        if (json.properties?.w != null) {
          node.dataset.width = [json.properties.w.t, json.properties.w.v].join(',');
        }
      },
      remove: (node) => {
        delete node.dataset.width;
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.TableCellData | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.TableCellData) {
    const node = DOMElementFactory.createNewTableCellElement();

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    if (node instanceof TableCellElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }
}
