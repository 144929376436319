import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { ELEMENTS } from 'Editor/services/consts';
import styles from './PageElement.module.css';
import { PageViewModel } from 'Editor/services/VisualizerManager/ViewModels';
import { SectionElement } from '../../SectionViewBuilder';

const PageStructure = () => {
  return (
    <div className={classNames(styles.page_sheet)}>
      <div className={classNames(styles.page_bleed, styles['page_bleed-top'])}>
        <div className={classNames(styles['page_marks-crop'])}></div>
        <div className={classNames(styles['page_marks-middle'])}>
          <div className={classNames(styles['page_marks-cross'])}></div>
        </div>
        <div className={classNames(styles['page_marks-crop'])}></div>
      </div>
      <div className={classNames(styles.page_bleed, styles['page_bleed-bottom'])}>
        <div className={classNames(styles['page_marks-crop'])}></div>
        <div className={classNames(styles['page_marks-middle'])}>
          <div className={classNames(styles['page_marks-cross'])}></div>
        </div>
        <div className={classNames(styles['page_marks-crop'])}></div>
      </div>
      <div className={classNames(styles.page_bleed, styles['page_bleed-left'])}>
        <div className={classNames(styles['page_marks-crop'])}></div>
        <div className={classNames(styles['page_marks-middle'])}>
          <div className={classNames(styles['page_marks-cross'])}></div>
        </div>
        <div className={classNames(styles['page_marks-crop'])}></div>
      </div>
      <div className={classNames(styles.page_bleed, styles['page_bleed-right'])}>
        <div className={classNames(styles['page_marks-crop'])}></div>
        <div className={classNames(styles['page_marks-middle'])}>
          <div className={classNames(styles['page_marks-cross'])}></div>
        </div>
        <div className={classNames(styles['page_marks-crop'])}></div>
      </div>
      <div className={classNames(styles['page_pagebox'])}>
        <div className={classNames(styles['page_margin-top-left-corner-holder'])}>
          <div className={classNames(styles.page_margin, styles['page_margin-top-left-corner'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
        </div>
        <div data-header="true" className={classNames(styles['page_margin-top'])}>
          {/* <div className={classNames(styles.page_margin, styles['page_margin-top-left'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
          <div className={classNames(styles.page_margin, styles['page_margin-top-corner'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
          <div className={classNames(styles.page_margin, styles['page_margin-top-right'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div> */}
        </div>
        <div className={classNames(styles['page_margin-top-right-corner-holder'])}>
          <div className={classNames(styles.page_margin, styles['page_margin-top-right-corner'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
        </div>
        <div className={classNames(styles['page_margin-right'])}>
          <div className={classNames(styles.page_margin, styles['page_margin-right-top'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
          <div className={classNames(styles.page_margin, styles['page_margin-right-middle'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
          <div className={classNames(styles.page_margin, styles['page_margin-right-bottom'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
        </div>
        <div className={classNames(styles['page_margin-left'])}>
          <div className={classNames(styles.page_margin, styles['page_margin-left-top'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
          <div className={classNames(styles.page_margin, styles['page_margin-left-middle'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
          <div className={classNames(styles.page_margin, styles['page_margin-left-bottom'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
        </div>
        <div className={classNames(styles['page_margin-bottom-left-corner-holder'])}>
          <div className={classNames(styles.page_margin, styles['page_margin-bottom-left-corner'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
        </div>
        <div data-footer="true" className={classNames(styles['page_margin-bottom'])}>
          {/* <div className={classNames(styles.page_margin, styles['page_margin-bottom-left'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
          <div className={classNames(styles.page_margin, styles['page_margin-bottom-center'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
          <div className={classNames(styles.page_margin, styles['page_margin-bottom-right'])}>
            <div className={classNames(styles['page_margin-content'])}></div>
          </div> */}
        </div>
        <div className={classNames(styles['page_margin-bottom-right-corner-holder'])}>
          <div
            className={classNames(styles.page_margin, styles['page_margin-bottom-right-corner'])}
          >
            <div className={classNames(styles['page_margin-content'])}></div>
          </div>
        </div>
        <div className={classNames(styles['page_area'])}>
          <div id="overflow_container" className={classNames(styles['page_page_content'])}>
            <div
              id="content_container"
              className={classNames(styles['page_content_wrapper'])}
            ></div>
          </div>
          <div className={classNames(styles['page_footnote_area'])}>
            <div
              className={classNames(styles['page_footnote_content'], styles['page_footnote_empty'])}
            >
              <div className={classNames(styles['page_footnote_inner_content'])}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export class PageElement extends HTMLElement {
  protected viewModel?: PageViewModel;
  protected overflowElement: HTMLElement | null;
  protected contentElement: HTMLElement | null;

  constructor() {
    super();
    this.overflowElement = null;
    this.contentElement = null;
  }

  set vm(model: PageViewModel | undefined) {
    if (model != null) {
      this.viewModel = model;
    }
  }

  get vm() {
    return this.viewModel;
  }

  static get observedAttributes() {
    return [];
  }

  connectedCallback() {}

  disconnectedCallback() {}

  attributeChangedCallback(attribute: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
    }
  }

  get section(): string | null {
    return this.getAttribute('section');
  }

  set section(value: string | null) {
    if (value != null) {
      this.setAttribute('section', value);
    } else {
      this.removeAttribute('section');
    }
  }

  sectionContainerAt(index: number = 0) {
    const child = this.contentContainer?.children[index];
    if (child && child instanceof SectionElement) {
      return child;
    }

    return null;
  }

  get lastSectionContainer(): SectionElement | null {
    const sectionContainer = this.contentElement?.lastElementChild;
    if (sectionContainer instanceof SectionElement) {
      return sectionContainer;
    }

    return null;
  }

  sectionContainerById(sectionId: string) {
    return this.contentContainer?.querySelector(`[section="${sectionId}"]`);
  }

  get pagebox(): HTMLElement | null {
    return this.querySelector(`.${classNames(styles['page_pagebox'])}`);
  }

  get contentContainer(): HTMLElement | null {
    return this.contentElement;
  }

  get overflowContainer(): HTMLElement | null {
    return this.overflowElement;
  }

  get headerContainer(): HTMLElement | null {
    return this.querySelector(`.${classNames(styles['page_margin-top'])}`);
  }

  get footerContainer(): HTMLElement | null {
    return this.querySelector(`.${classNames(styles['page_margin-bottom'])}`);
  }

  setupPageStructure() {
    ReactDOM.unmountComponentAtNode(this);
    ReactDOM.render(<PageStructure />, this);

    this.overflowElement = this.querySelector("[id='overflow_container']");
    this.contentElement = this.overflowElement?.firstElementChild as HTMLElement;
    // if (this.overflowElement && this.contentElement) {
    //   //@ts-ignore
    //   this.overflowElement.style.border = '1px solid black';
    // }
  }
}

if (!window.customElements.get(ELEMENTS.PageElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.PageElement.IDENTIFIER, PageElement);
}
