import { Tooltip } from 'dodoc-design-system';
import { useIntl } from 'react-intl';
import { Status } from '_common/components';
import { useDispatch, useIEnvision } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

type ObjectStatusProps = {
  object: doDOC.SuiteObject;
};

const ObjectStatus = ({ object }: ObjectStatusProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isIEnvision = useIEnvision();

  const canChangeStatus = (['admin', 'owner', 'edit'] as const).some((permission) =>
    object.user_permissions.includes(permission),
  );

  const handleChangeStatus = () => {
    if (canChangeStatus && object) {
      dispatch(
        openAndUpdateModal({
          modal: 'ChangeElementStatusModal',
          data: {
            objectId: object.id,
            objectType: object.type,
          },
        }),
      );
    }
  };

  if (!isIEnvision) {
    return (
      <Tooltip
        content={intl.formatMessage({ id: 'CHANGE_DOCUMENT_STATUS' })}
        disabled={!canChangeStatus}
        placement="bottom"
        testId="change-status-tooltip"
      >
        <div
          style={{ cursor: canChangeStatus ? 'pointer' : 'auto' }}
          onClick={handleChangeStatus}
          data-testid="status-value"
        >
          <Status id={object.status} size="small" testId="status-label" />
        </div>
      </Tooltip>
    );
  }
  return null;
};

export default ObjectStatus;
