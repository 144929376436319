import { NodeUtils } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import { JsonRange } from '../../../JsonRange';

type Context = {
  base: {
    data: Editor.Data.Node.Data;
    path: Editor.Selection.Path;
  };
  textElement: {
    data: Editor.Data.Node.Data;
    path: Editor.Selection.Path;
    isAtStart: boolean;
    isAtEnd: boolean;
  };
  child: {
    data: Editor.Data.Node.Data;
    offset: number;
    contentOffset: number | null;
  };
  closestInline: {
    data: Editor.Data.Node.Data | null;
    path: Editor.Selection.Path | null;
    isAtStart: boolean;
    isAtEnd: boolean;
  };
  closestWrap: {
    data: Editor.Data.Node.Data | null;
    path: Editor.Selection.Path | null;
    isAtStart: boolean;
    isAtEnd: boolean;
  };
};

type FixContext = {
  dir: 'FORWARD' | 'BACKWARD';
  path: Editor.Selection.Path;
};

export class JSONSelectionNormalizer {
  protected options: Editor.Selection.FixerOptions = {
    suggestionMode: false,
    forceTextAsWrap: false,
    containerPosition: undefined,
    forceWrapAsText: false,
    forceNonEditableDirection: null,
    isDelete: false, // for selections inside non-editable elements, it should not fix selection, except when it is at the end of that element
    isBackspace: false,
  };

  private nonEditableTypes: Editor.Elements.ElementTypesType[];
  private wrapInlineTypes: Editor.Elements.ElementTypesType[];
  private textInlineTypes: Editor.Elements.ElementTypesType[];
  private possibleParagraphMarkers: Editor.Elements.ElementTypesType[];
  private nonWrapInlineTypes: Editor.Elements.ElementTypesType[];

  constructor(args: Partial<Editor.Selection.FixerOptions>) {
    this.options = {
      ...this.options,
      ...args,
    };

    this.nonEditableTypes = [...NodeUtils.INLINE_NON_EDITABLE_TYPES];
    this.wrapInlineTypes = [...NodeUtils.INLINE_WRAP_TYPES];
    this.textInlineTypes = [...NodeUtils.INLINE_TEXT_TYPES];

    this.possibleParagraphMarkers = [
      ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
      ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
    ];

    if (this.options.suggestionMode) {
      this.nonEditableTypes.push(ELEMENTS.TrackDeleteElement.ELEMENT_TYPE);
      this.textInlineTypes.push(ELEMENTS.TrackInsertElement.ELEMENT_TYPE);
    } else {
      this.wrapInlineTypes.push(ELEMENTS.TrackInsertElement.ELEMENT_TYPE);
      this.textInlineTypes.push(ELEMENTS.TrackDeleteElement.ELEMENT_TYPE);
    }

    if (this.options.forceTextAsWrap) {
      this.wrapInlineTypes = [...this.wrapInlineTypes, ...this.textInlineTypes];
      this.textInlineTypes = [];
    } else if (this.options.forceWrapAsText) {
      this.textInlineTypes = [...this.textInlineTypes, ...this.wrapInlineTypes];
      this.wrapInlineTypes = [];
    }

    this.nonWrapInlineTypes = [...this.nonEditableTypes, ...this.textInlineTypes];
  }

  private initNormalizeContext(
    baseData: Editor.Data.Node.Data,
    basePath: Editor.Selection.Path,
  ): Context | null {
    const result = NodeUtils.closestOfTypeByPath(baseData, basePath, ['p']);

    if (!result) {
      return null;
    }

    let textElementData: Editor.Data.Node.Data = result.data;
    let textElementPath: Editor.Selection.Path = result.path;

    // child data
    const childData = NodeUtils.getChildDataByPath(textElementData, basePath);
    let childOffset: number;
    let contentOffset: number | null = null;

    if (!childData) {
      return null;
    }

    if (basePath[basePath.length - 2] === 'content') {
      contentOffset = Number(basePath[basePath.length - 1]);
      childOffset = Number(basePath[basePath.length - 3]);
    } else {
      childOffset = Number(basePath[basePath.length - 1]);
    }

    let resultInline = NodeUtils.closestOfTypeByPath(
      textElementData,
      basePath,
      this.nonEditableTypes,
    );

    if (!resultInline) {
      resultInline = NodeUtils.closestOfTypeByPath(
        textElementData,
        basePath,
        this.nonWrapInlineTypes,
      );
    }

    const closestInline = resultInline?.data || null;
    const closestInlinePath = resultInline?.path || null;

    const resultWrap = NodeUtils.closestOfTypeByPath(
      textElementData,
      basePath,
      this.wrapInlineTypes,
    );
    const closestWrap = resultWrap?.data || null;
    const closestWrapPath = resultWrap?.path || null;

    let atTextStart = false;
    let atTextEnd = false;
    if (textElementData && textElementPath) {
      if (
        NodeUtils.isPathAtContentStart(
          textElementData,
          basePath.slice(textElementPath.length, basePath.length),
        )
      ) {
        atTextStart = true;
      }
      if (
        NodeUtils.isPathAtContentEnd(
          textElementData,
          basePath.slice(textElementPath.length, basePath.length),
        )
      ) {
        atTextEnd = true;
      }
    }

    let atInlineStart = false;
    let atInlineEnd = false;
    if (closestInline && closestInlinePath) {
      if (
        NodeUtils.isPathAtContentStart(
          closestInline,
          basePath.slice(closestInlinePath.length, basePath.length),
        )
      ) {
        atInlineStart = true;
      }
      if (
        NodeUtils.isPathAtContentEnd(
          closestInline,
          basePath.slice(closestInlinePath.length, basePath.length),
        )
      ) {
        atInlineEnd = true;
      }
    }

    let atWrapStart = false;
    let atWrapEnd = false;
    if (closestWrap && closestWrapPath) {
      if (
        NodeUtils.isPathAtContentStart(
          closestWrap,
          basePath.slice(closestWrapPath.length, basePath.length),
        )
      ) {
        atWrapStart = true;
      }
      if (
        NodeUtils.isPathAtContentEnd(
          closestWrap,
          basePath.slice(closestWrapPath.length, basePath.length),
        )
      ) {
        atWrapEnd = true;
      }
    }

    return {
      base: {
        data: baseData,
        path: basePath,
      },
      textElement: {
        data: textElementData,
        path: textElementPath,
        isAtStart: atTextStart,
        isAtEnd: atTextEnd,
      },
      child: {
        data: childData,
        offset: childOffset,
        contentOffset: contentOffset,
      },
      closestInline: {
        data: closestInline,
        path: closestInlinePath,
        isAtStart: atInlineStart,
        isAtEnd: atInlineEnd,
      },
      closestWrap: {
        data: closestWrap,
        path: closestWrapPath,
        isAtStart: atWrapStart,
        isAtEnd: atWrapEnd,
      },
    };
  }

  private isParagraphMarker(ctx: Context): FixContext | null {
    // fix backwards
    if (!JsonRange.isValidSelectionPath(ctx.base.path)) {
      return null;
    }

    const length = ctx.base.path.length;
    const pathToFix = ctx.base.path.slice(0, length - 2);

    return {
      dir: 'BACKWARD',
      path: pathToFix,
    };
  }

  private isEditableInlineWrap(ctx: Context): FixContext | null {
    if (!JsonRange.isValidSelectionPath(ctx.base.path)) {
      return null;
    }

    if (!ctx.closestWrap.path) {
      return null;
    }

    if (ctx.closestWrap.isAtEnd) {
      // is at the end
      let pathToFix = [...ctx.closestWrap.path];
      let offset = Number(pathToFix[pathToFix.length - 1]);
      if (!isNaN(offset)) {
        offset += 1;
      }
      pathToFix[pathToFix.length - 1] = offset;

      let pathToParent = pathToFix.slice(0, pathToFix.length - 2);
      let parentData = NodeUtils.getChildDataByPath(ctx.base.data, pathToParent);

      while (
        parentData &&
        pathToParent.length > 0 &&
        this.wrapInlineTypes.includes(parentData.type) &&
        offset === parentData.childNodes?.length
      ) {
        pathToFix = pathToParent;
        offset = Number(pathToFix[pathToFix.length - 1]);
        if (!isNaN(offset)) {
          offset += 1;
        }
        pathToFix[pathToFix.length - 1] = offset;

        pathToParent = pathToFix.slice(0, pathToFix.length - 2);
        parentData = NodeUtils.getChildDataByPath(ctx.base.data, pathToParent);
      }

      return {
        dir: 'FORWARD',
        path: pathToFix,
      };
    } else if (ctx.closestWrap.isAtStart) {
      // is at start
      let pathToFix = [...ctx.closestWrap.path];
      let offset = Number(pathToFix[pathToFix.length - 1]);

      let pathToParent = pathToFix.slice(0, pathToFix.length - 2);
      let parentData = NodeUtils.getChildDataByPath(ctx.base.data, pathToParent);

      while (
        parentData &&
        pathToParent.length > 0 &&
        this.wrapInlineTypes.includes(parentData.type) &&
        offset === 0
      ) {
        pathToFix = pathToParent;
        offset = Number(pathToFix[pathToFix.length - 1]);

        pathToParent = pathToFix.slice(0, pathToFix.length - 2);
        parentData = NodeUtils.getChildDataByPath(ctx.base.data, pathToParent);
      }

      return {
        dir: 'BACKWARD',
        path: pathToFix,
      };
    } else if (ctx.closestWrap.data?.id === ctx.child.data.id) {
      return {
        dir: 'BACKWARD',
        path: [...ctx.base.path],
      };
    }

    return null;
  }

  private isNonEditableInline(ctx: Context): FixContext | null {
    if (!ctx.closestInline.path || !ctx.closestInline.data) {
      return null;
    }

    if (
      this.options.forceNonEditableDirection === 'BACKWARD' ||
      ctx.closestInline.isAtStart ||
      NodeUtils.isLastChildElementData(ctx.closestInline.data)
    ) {
      const pathToFix = [...ctx.closestInline.path];

      return {
        dir: 'BACKWARD',
        path: pathToFix,
      };
    } else {
      const pathToFix = [...ctx.closestInline.path];
      let offset = Number(pathToFix[pathToFix.length - 1]);
      if (!isNaN(offset)) {
        offset += 1;
      }
      pathToFix[pathToFix.length - 1] = offset;

      return {
        dir: 'FORWARD',
        path: pathToFix,
      };
    }
  }

  private isAtTextInline(ctx: Context): FixContext | null {
    if (NodeUtils.isTextData(ctx.child.data)) {
      let nextChild = NodeUtils.getNextAncestor(ctx.base.data, ctx.base.path);
      let previousChild = NodeUtils.getPreviousAncertor(ctx.base.data, ctx.base.path);

      if (
        ctx.child.contentOffset === 0 &&
        !ctx.textElement.isAtStart &&
        previousChild?.data &&
        (this.textInlineTypes.includes(previousChild.data.type) ||
          NodeUtils.isTextData(previousChild.data))
      ) {
        const path = previousChild.path;
        const parentOffset = Number(path[path.length - 1]);
        if (!isNaN(parentOffset)) {
          path[path.length - 1] = parentOffset + 1;
        }

        return {
          dir: 'BACKWARD',
          path: path,
        };
      } else if (
        ctx.child.contentOffset === ctx.child.data.content.length &&
        nextChild?.data &&
        (this.textInlineTypes.includes(nextChild.data.type) ||
          NodeUtils.isTextData(nextChild?.data))
      ) {
        return {
          dir: 'FORWARD',
          path: nextChild.path,
        };
      }
    }

    return null;
  }

  private isAtChildNode(ctx: Context): FixContext | null {
    if (!JsonRange.isValidSelectionPath(ctx.base.path)) {
      return null;
    }
    if (ctx.child.offset === 0 && ctx.textElement.isAtStart) {
      return {
        dir: 'FORWARD',
        path: [...ctx.base.path],
      };
    } else {
      let previousChild = NodeUtils.getPreviousAncertor(ctx.base.data, ctx.base.path);

      if (
        previousChild?.data &&
        (this.textInlineTypes.includes(previousChild.data.type) ||
          NodeUtils.isTextData(previousChild.data))
      ) {
        const path = previousChild.path;
        const parentOffset = Number(path[path.length - 1]);
        if (!isNaN(parentOffset)) {
          path[path.length - 1] = parentOffset + 1;
        }

        return {
          dir: 'BACKWARD',
          path: path,
        };
      } else if (previousChild && NodeUtils.isLastChildElementData(previousChild.data)) {
        return {
          dir: 'BACKWARD',
          path: previousChild.path,
        };
      } else {
        return {
          dir: 'FORWARD',
          path: [...ctx.base.path],
        };
      }
    }
  }

  private drillDownBackward(data: Editor.Data.Node.Data, path: Editor.Selection.Path) {
    let pathToFix: Editor.Selection.Path = [...path];
    if (!path.includes('content')) {
      let offset = Number(pathToFix[pathToFix.length - 1]);
      if (!isNaN(offset)) {
        let elementPath = pathToFix.slice(0, pathToFix.length - 2);
        let elementData: Editor.Data.Node.Data | undefined = NodeUtils.getChildDataByPath(
          data,
          elementPath,
        );

        while (
          !NodeUtils.isTextData(elementData) &&
          elementData?.childNodes &&
          elementData.childNodes.length > 0
        ) {
          let childData: Editor.Data.Node.Data = elementData.childNodes[offset - 1];
          if (
            NodeUtils.isSupportedInlineData(childData) /* && check if is editable */ &&
            !this.nonEditableTypes.includes(childData.type) &&
            !this.wrapInlineTypes.includes(childData.type) &&
            childData.childNodes
          ) {
            // fix path to previous child
            pathToFix[pathToFix.length - 1] = offset - 1;

            elementData = childData;
            offset = childData.childNodes.length;
            pathToFix.push('childNodes');
            pathToFix.push(offset);
          } else if (
            NodeUtils.isLastChildElementData(childData) &&
            !this.options.isDelete &&
            !this.options.isBackspace
          ) {
            // fix path to previous child
            pathToFix[pathToFix.length - 1] = offset - 1;

            offset -= 1;
          } else if (NodeUtils.isTextData(childData)) {
            // fix path to previous child
            pathToFix[pathToFix.length - 1] = offset - 1;

            elementData = childData;
            pathToFix.push('content');
            pathToFix.push(childData.content.length);
            break;
          } else {
            break;
          }
        }
      }
    }

    return pathToFix;
  }

  private drillDownForward(data: Editor.Data.Node.Data, path: Editor.Selection.Path) {
    let pathToFix: Editor.Selection.Path = [...path];
    if (!path.includes('content')) {
      let offset = Number(pathToFix[pathToFix.length - 1]);
      if (!isNaN(offset)) {
        let elementPath = pathToFix.slice(0, pathToFix.length - 2);
        let elementData: Editor.Data.Node.Data | undefined = NodeUtils.getChildDataByPath(
          data,
          elementPath,
        );

        while (
          !NodeUtils.isTextData(elementData) &&
          elementData?.childNodes &&
          elementData.childNodes.length > 0
        ) {
          let childData: Editor.Data.Node.Data = elementData.childNodes[offset];
          if (
            NodeUtils.isSupportedInlineData(childData) /* && check if is editable */ &&
            !this.nonEditableTypes.includes(childData.type) &&
            !this.wrapInlineTypes.includes(childData.type) &&
            childData.childNodes
          ) {
            elementData = childData;
            offset = 0;
            pathToFix.push('childNodes');
            pathToFix.push(offset);
          } else if (NodeUtils.isTextData(childData)) {
            elementData = childData;
            pathToFix.push('content');
            pathToFix.push(0);
            break;
          } else {
            break;
          }
        }
      }
    }

    return pathToFix;
  }

  normalize(
    baseData: Editor.Data.Node.Data,
    path: Realtime.Core.RealtimePath,
  ): Editor.Selection.Path | null {
    if (!JsonRange.isValidSelectionPath(path)) {
      return null;
    }

    const ctx: Context | null = this.initNormalizeContext(baseData, path);

    if (ctx == null) {
      return null;
    }

    let fixCtx: FixContext | null = null;

    // check elements

    if (
      NodeUtils.isParagraphMarker(ctx.child.data) ||
      NodeUtils.isParagraphMarker(ctx.closestWrap.data) ||
      NodeUtils.isParagraphMarker(ctx.closestInline.data)
    ) {
      // is paragraph marker
      fixCtx = this.isParagraphMarker(ctx);
    } else if (
      ctx.closestWrap.data &&
      ctx.closestWrap.data !== baseData &&
      (!ctx.closestInline.data || !this.nonEditableTypes.includes(ctx.closestInline.data.type)) &&
      (ctx.closestWrap.isAtStart || ctx.closestWrap.isAtEnd)
    ) {
      // has a closest inline wrap and it is editable
      fixCtx = this.isEditableInlineWrap(ctx);
    } else if (
      ctx.closestInline.data &&
      this.nonEditableTypes.includes(
        ctx.closestInline.data.type,
      ) /*|| check if it's not editable */ &&
      (!this.options.isDelete || ctx.closestInline.isAtEnd) &&
      (!this.options.isBackspace || ctx.closestInline.isAtStart)
    ) {
      // has a closest non editable element
      fixCtx = this.isNonEditableInline(ctx);
    } else if (!this.options.isBackspace && !this.options.isDelete) {
      if (ctx.base.path.includes('content') && NodeUtils.isTextData(ctx.child.data)) {
        // is at start of child element
        fixCtx = this.isAtTextInline(ctx);
      } else {
        fixCtx = this.isAtChildNode(ctx);
      }
    }

    if (fixCtx) {
      if (fixCtx.dir === 'BACKWARD') {
        return this.drillDownBackward(ctx.base.data, fixCtx.path);
      } else if (fixCtx.dir === 'FORWARD') {
        return this.drillDownForward(ctx.base.data, fixCtx.path);
      }
    }

    return [...path];
  }
}
