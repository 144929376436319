import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storageSession from 'redux-persist/lib/storage/session';
import authApi from '_common/services/api/authority';
import onboardingApi from './onboardingApi';

export type OnboardingEnvironments = 'explorer' | 'editor' | 'dopdf';

export type ExplorerActions =
  | 'explorer_myFiles_new_open'
  | 'explorer_document_name'
  | 'explorer_my_files_document_option_click'
  | 'explorer_my_files_document_created'
  | 'explorer_document_share_opened'
  | 'explorer_spaces_space_created'
  | 'explorer_space_share'
  | 'explorer_share_user'
  | 'explorer_share_role'
  | 'explorer_share_finish'
  | 'explorer_space_opened'
  | 'explorer_share_closed'
  | 'explorer_open_space'
  | 'explorer_tenant_settings_click';
export type EditorActions =
  | 'editor_writing_selectFont'
  | 'editor_writing_font_open'
  | 'editor_writing_font_select'
  | 'editor_writing_selectHeading'
  | 'editor_writing_heading_open'
  | 'editor_writing_heading_select'
  | 'editor_suggestions_addText'
  | 'editor_suggestions_deleteText'
  | 'editor_suggestions_openSidePanel'
  | 'editor_comments_openSidePanel'
  | 'editor_comments_startCommentCreation'
  | 'editor_comments_createComment'
  | 'editor_comments_openReplies'
  | 'editor_comments_mentionInCommentReply'
  | 'editor_tasks_openSidePanel'
  | 'editor_tasks_startTaskCreation'
  | 'editor_tasks_assignee'
  | 'editor_tasks_dueDate'
  | 'editor_tasks_createTask';
export type PDFActions =
  | 'pdf_annotations_highlight'
  | 'pdf_annotations_openAnnotation'
  | 'pdf_annotations_commentHighlight'
  | 'pdf_comments_openComment'
  | 'pdf_comments_createComment'
  | 'pdf_comments_mentionInCommentReply'
  | 'pdf_tasks_openTask'
  | 'pdf_tasks_assignee'
  | 'pdf_tasks_dueDate'
  | 'pdf_tasks_createTask';

export type ExplorerInteractions =
  | 'explorer_my_files_action_bar_new'
  | 'explorer_document_option'
  | 'explorer_document_create'
  | 'explorer_my_files_action_bar_share'
  | 'explorer_share'
  | 'explorer_spaces_action_bar_new'
  | 'explorer_space_create'
  | 'explorer_spaces_action_bar_share'
  | 'explorer_spaces_space_click'
  | 'explorer_share_done'
  | 'explorer_tenant_settings';
export type EditorInteractions =
  | 'editor_toolbar_font'
  | 'editor_toolbar_heading'
  | 'editor_sidepanel_review_suggestions'
  | 'editor_sidepanel_review_comments'
  | 'editor_sidepanel_newComment'
  | 'editor_sidepanel_mentionComment'
  | 'editor_sidepanel_tasks'
  | 'editor_contextMenu_createComment'
  | 'editor_contextMenu_createTask'
  | 'editor_tabmenu_comments'
  | 'editor_mainComponent_selectFont'
  | 'editor_mainComponent_selectHeading'
  | 'editor_mainComponent_addText'
  | 'editor_mainComponent_deleteText'
  | 'editor_mainComponent_addComment'
  | 'editor_mainComponent_addTask'
  | 'editor_tasks_tempCard';
export type PDFInteractions =
  | 'pdf_toolbar_highlight'
  | 'pdf_annotation_edit'
  | 'pdf_toolbar_comment'
  | 'pdf_comment_reply'
  | 'pdf_toolbar_task'
  | 'pdf_task_fill'
  | 'pdf_annotation_save'
  | 'pdf_editable_content'
  | 'pdf_allow_zoom'
  | 'pdf_text_layer'
  | 'pdf_toggles_layer';

type Rect = {
  top: number;
  left: number;
  height: number;
  width: number;
};

type Journey = {
  explorer: ExplorerOnboardingJourney;
  editor: EditorOnboardingJourney;
  dopdf: DopdfOnboardingJourney;
};

type OnboardingSliceState = {
  active: { [key in OnboardingEnvironments]: boolean };
  started: { [key in OnboardingEnvironments]: boolean };
  journey?: { [key in OnboardingEnvironments]: string };
  actionsCompleted: { [key in ExplorerActions | EditorActions | PDFActions]?: boolean };
  interactions: { [key in ExplorerInteractions | EditorInteractions | PDFInteractions]?: boolean };
  initialPhase: {
    [key in OnboardingEnvironments]?: OnboardingPhase;
  };
  initialJourney: {
    [key in OnboardingEnvironments]?: Journey[key];
  };
  currentDocument: {
    [key in OnboardingEnvironments]?: { id: ObjectId; zoom: number; isOnboarding?: boolean };
  };
  pulseData: {
    annotationId?: PDF.Annotation['id'];
    commentId?: PDF.Annotation['id'];
    taskId?: string;
    contextMenuTaskItemRect?: Rect;
    contextMenuCommentItemRect?: Rect;
    annotationCardCtaRect?: Rect;
    annotationCardReplyRect?: Rect;
    taskInputRect?: Rect;
    taskButtonRect?: Rect;
    statusLabelRect?: Rect;
    taskHasDescription?: boolean;
    taskHasAssignee?: boolean;
    taskHasDueDate?: boolean;
    annotationRepliesToggled?: boolean;
    documentNameInputRect?: Rect;
    documentNameButtonRect?: Rect;
    documentInputRect?: Rect;
    documentButtonRect?: Rect;
    shareButtonRect?: Rect;
    shareInputRect?: Rect;
    spaceInputRect?: Rect;
    spaceButtonRect?: Rect;
    documentOptionRect?: Rect;
    importDocumentOptionRect?: Rect;
    shareDoneButtonRect?: Rect;
  };
  initialTaskValues?: {
    description: string;
    assignee: UserId;
    dueDate: string;
  };
  renderAbove: boolean;
  explorerFiles: string[];
};

/**
 * Relates to bug DDC-9454
 * Avoid changing action state when not necessary
 * Bug would be caused by editor selection affecting actionsCompleted state,
 *  and onboarding using scrollInto on a useEffect
 *  which will rerender with state change
 */
const skipActionChange = (
  interactions: OnboardingSliceState['interactions'],
  action: keyof OnboardingSliceState['actionsCompleted'],
) => {
  switch (action) {
    case 'editor_writing_selectFont':
      return !interactions.editor_mainComponent_selectFont;
    case 'editor_writing_selectHeading':
      return !interactions.editor_mainComponent_selectHeading;
    default:
      return false;
  }
};

const SLICE_NAME = 'ONBOARDING';
const INITIAL_STATE: OnboardingSliceState = {
  active: {
    explorer: false,
    editor: false,
    dopdf: false,
  },
  started: {
    explorer: false,
    editor: false,
    dopdf: false,
  },
  journey: {
    explorer: '',
    editor: '',
    dopdf: '',
  },
  actionsCompleted: {},
  interactions: {},
  initialPhase: {},
  initialJourney: {},
  currentDocument: {
    editor: { id: '', zoom: 1 },
    dopdf: { id: '', zoom: 1 },
  },
  pulseData: {},
  renderAbove: false,
  explorerFiles: [],
};

// #endregion

// #region Slice
const OnboardingSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    activateOnboarding: (state, action: PayloadAction<OnboardingEnvironments>) => {
      state.active[action.payload] = true;
    },
    startOnboarding: (state, action: PayloadAction<OnboardingEnvironments>) => {
      state.started[action.payload] = true;
    },
    stopOnboarding: (state, action: PayloadAction<OnboardingEnvironments>) => {
      state.started[action.payload] = false;
    },
    pauseOnboarding: (state, action: PayloadAction<OnboardingEnvironments>) => {
      state.started[action.payload] = false;
    },
    completeAction: (
      state,
      action: PayloadAction<keyof OnboardingSliceState['actionsCompleted']>,
    ) => {
      if (!skipActionChange(state.interactions, action.payload)) {
        state.actionsCompleted[action.payload] = true;
      }
    },
    resetAction: (state, action: PayloadAction<keyof OnboardingSliceState['actionsCompleted']>) => {
      if (!skipActionChange(state.interactions, action.payload)) {
        state.actionsCompleted[action.payload] = false;
      }
    },
    completeActionList: (
      state,
      action: PayloadAction<(keyof OnboardingSliceState['actionsCompleted'])[]>,
    ) => {
      action.payload.forEach((action) => {
        if (!skipActionChange(state.interactions, action)) {
          state.actionsCompleted[action] = true;
        }
      });
    },
    resetCompletedActions: (state) => {
      state.actionsCompleted = {};
    },
    setInteractions: (
      state,
      action: PayloadAction<(keyof OnboardingSliceState['interactions'])[]>,
    ) => {
      const newInteractions: OnboardingSliceState['interactions'] = {};

      action.payload.forEach((interaction) => {
        newInteractions[interaction] = true;
      });

      state.interactions = newInteractions;
    },
    setCurrentDocument: (
      state,
      action: PayloadAction<{
        target: OnboardingEnvironments;
        id?: ObjectId;
        zoom?: number;
        isOnboarding?: boolean;
      }>,
    ) => {
      if (!action.payload.id && !action.payload.isOnboarding) {
        state.currentDocument = { ...state.currentDocument, [action.payload.target]: undefined };
      } else {
        state.currentDocument = {
          [action.payload.target]: {
            id: action.payload.id ?? state.currentDocument[action.payload.target]?.id,
            zoom: action.payload.zoom,
            isOnboarding: !!action.payload.isOnboarding,
          },
        };
      }
    },
    setPulseData: (state, action: PayloadAction<OnboardingSliceState['pulseData']>) => {
      state.pulseData = { ...state.pulseData, ...action.payload };
    },
    setInitialTaskValues: (
      state,
      action: PayloadAction<NonNullable<OnboardingSliceState['initialTaskValues']>>,
    ) => {
      state.initialTaskValues = action.payload;
    },
    setRenderAbove: (state, action: PayloadAction<OnboardingSliceState['renderAbove']>) => {
      state.renderAbove = action.payload;
    },
    setExplorerFiles: (state, action: PayloadAction<string>) => {
      state.explorerFiles = [...state.explorerFiles, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(authApi.endpoints.getCurrentUser.matchFulfilled),
      (state, action) => {
        const inactiveStates = ['beginning', 'ending'];

        state.active = {
          explorer: action.payload.onboarding.explorer === 'beginning',
          editor: action.payload.onboarding.editor === 'beginning',
          dopdf: action.payload.onboarding.dopdf === 'beginning',
        };
        state.started = {
          explorer: !inactiveStates.includes(action.payload.onboarding.explorer),
          editor: !inactiveStates.includes(action.payload.onboarding.editor),
          dopdf: !inactiveStates.includes(action.payload.onboarding.dopdf),
        };
        state.journey = {
          explorer: action.payload.onboarding.explorer_journey,
          editor: action.payload.onboarding.editor_journey,
          dopdf: action.payload.onboarding.dopdf_journey,
        };
        state.initialPhase = {
          explorer: !inactiveStates.includes(action.payload.onboarding.explorer)
            ? action.payload.onboarding.explorer
            : undefined,
          editor: !inactiveStates.includes(action.payload.onboarding.editor)
            ? action.payload.onboarding.editor
            : undefined,
          dopdf: !inactiveStates.includes(action.payload.onboarding.dopdf)
            ? action.payload.onboarding.dopdf
            : undefined,
        };
        state.initialJourney = {
          explorer: action.payload.onboarding.explorer_journey,
          editor: action.payload.onboarding.editor_journey,
          dopdf: action.payload.onboarding.dopdf_journey,
        };
      },
    );
    //Setup state for onboarding reset
    builder.addMatcher(
      isAnyOf(onboardingApi.endpoints.onboardingStatus.matchFulfilled),
      (state, action) => {
        if (action.meta.arg.originalArgs.step === 'beginning') {
          state.actionsCompleted = {};
          state.interactions = {};
          state.initialPhase[action.meta.arg.originalArgs.target] = undefined;
          state.explorerFiles = [];
        }
        if (action.meta.arg.originalArgs.step === 'ending') {
          state.actionsCompleted = {};
          state.interactions = {};
          state.initialPhase[action.meta.arg.originalArgs.target] = undefined;
          state.explorerFiles = [];
        }
      },
    );
  },
});

const persistConfig = {
  key: 'onboarding',
  storage: storageSession,
  whitelist: ['currentDocument'],
};

const onboardingReducer = persistReducer(persistConfig, OnboardingSlice.reducer);

export default onboardingReducer;
// #endregion

// #region Actions
export const {
  activateOnboarding,
  startOnboarding,
  stopOnboarding,
  completeAction,
  resetAction,
  completeActionList,
  setInteractions,
  setCurrentDocument,
  setPulseData,
  setInitialTaskValues,
  setRenderAbove,
  resetCompletedActions,
  setExplorerFiles,
} = OnboardingSlice.actions;
// #endregion
