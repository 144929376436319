import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, Checkbox } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { Logger } from '_common/services';

import { setFilter } from '../../FilterSlice';
import {
  DateRange,
  FilterIdentity,
  FilterName,
  OptionByType,
  TRANSLATIONS,
} from '../../FilterController';
import styles from './CheckboxHandler.module.scss';

const CheckboxHandler = ({
  identity,
  filter,
  filterQuantity,
  options,
  testId,
}: {
  identity: FilterIdentity;
  filter: FilterName;
  filterQuantity: number;
  options: OptionByType['checkbox'];
  testId: string;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const currentValue = useSelector((state) => {
    const value = state.filters[identity]?.[filter];
    return Array.isArray(value) ? (value as Exclude<typeof value, DateRange>) : null; //Cast to avoid typescript false calls due to DateRange
  });

  const translatedOptions = useMemo(
    () =>
      options?.map((option) => ({
        ...option,
        label: intl.formatMessage({ id: `${option.label}` }),
      })),
    [options],
  );

  const activeValues = useMemo(() => {
    if (!currentValue || !translatedOptions || !Array.isArray(currentValue)) {
      return [];
    }

    return translatedOptions
      .filter((option) =>
        currentValue.map((filterValue) => filterValue.value).includes(option.value),
      )
      .map((option) => option.value);
  }, [currentValue]);

  if (!translatedOptions) {
    Logger.error('[CheckboxHandler] - No options provided, unable to render filter: ', filter);
    return null;
  }

  const handleToggleValue = (value: string) => {
    if (activeValues.includes(value)) {
      dispatch(
        setFilter({
          identity,
          filter,
          value: currentValue
            ? currentValue.filter((filterValue) => filterValue.value !== value)
            : null,
        }),
      );
    } else {
      const newValue = {
        value,
        label: translatedOptions?.find((option) => option.value === value)?.label,
      };

      dispatch(
        setFilter({
          identity,
          filter,
          value: currentValue ? [...currentValue, newValue] : [newValue],
        }),
      );
    }
  };

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: TRANSLATIONS[filter] })}
      initialCollapsed={activeValues.length <= 0}
      collapsable={filterQuantity > 1}
      testId={`${testId}-accordion`}
    >
      {translatedOptions?.map((option, index) => (
        <Checkbox
          key={`${filter}-${option.value}`}
          checked={activeValues.includes(option.value) ? 'checked' : 'unchecked'}
          onChange={() => handleToggleValue(option.value)}
          margin={index > 0 ? '1rem 0 0 0' : undefined}
          testId={`${testId}-accordion-${index}`}
        >
          {option.label}
          {option.labelNumber !== undefined && <span className={styles.checkboxText}>{`(${option.labelNumber})`}</span>}
        </Checkbox>
      ))}
    </Accordion>
  );
};

export default CheckboxHandler;
