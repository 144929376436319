import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSelector, useDispatch } from '_common/hooks/redux';
import { removeUserActiveSession } from 'Settings/pages/TenantSettingsPage/Users/redux/userManagementSlice';

import { BaseRow, RowCell } from '_common/components';

import { Icon, Tooltip } from 'dodoc-design-system';
import styles from './TokenList.module.scss';

import type { BaseRowProps } from '_common/components/Table/BaseRow/BaseRow';

export type RowProps = Pick<BaseRowProps, 'objectId' | 'selectable' | 'clickHandles'>;

const Row = ({ objectId, selectable, clickHandles }: RowProps) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const object = useSelector((state) => state.userManagement.tokens[objectId]);
  const user = useSelector((state) => state.modals.UserTokensModal.user);
  const selectedTab = useSelector((state) => state.tabMenu.userTokenHistory);

  const handleRemoveSession = () => {
    dispatch(removeUserActiveSession({ userId: user.id, tokenId: object.pk }));
  };

  return (
    <BaseRow
      objectId={objectId}
      selectable={selectable}
      clickHandles={clickHandles}
      dataTestId={{ root: `token-row-${objectId}` }}
    >
      <RowCell testId="browser-column">
        {object.browser || (
          <div className={styles.unkown}>
            <FormattedMessage id="UNKOWN_BROWSER" />
          </div>
        )}
      </RowCell>
      <RowCell testId="expires-column">
        {dayjs(object.expires).format('DD/MM/YYYY hh:mm A')}
      </RowCell>
      <RowCell size="6" testId="action-column">
        <Tooltip
          content={intl.formatMessage({
            id: 'REMOVE_ACTIVE_SESSION',
          })}
          placement="bottom"
          disabled={selectedTab !== 'activeSession'}
          testId={`token-row-${objectId}-remove-session`}
        >
          <div style={{ width: '3rem' }}>
            {selectedTab === 'activeSession' && (
              <div
                className={styles.icon}
                onClick={handleRemoveSession}
                data-testid={'remove-session-icon'}
              >
                <Icon icon="Delete" size={24} />
              </div>
            )}
          </div>
        </Tooltip>
      </RowCell>
    </BaseRow>
  );
};

export default Row;
