import { useContext, useEffect, useState } from 'react';
import { useIntl, FormattedDate } from 'react-intl';
import LinesEllipsis, { CommonReactLinesEllipsisProps } from 'react-lines-ellipsis';

import { useDispatch, usePublicGroup } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { Setting, SettingTags } from 'Settings/components';
import GroupSettingsContext from './GroupSettingsContext';

const GeneralView = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { groupId } = useContext(GroupSettingsContext);

  const { group } = usePublicGroup(groupId || '');

  const [isExpanded, setIsExpanded] = useState(false);
  const [clamped, setClamped] = useState(false);
  const userPermissions = group.user_permissions;

  useEffect(() => {
    setIsExpanded(false);
  }, [group.description]);

  // ------------------------------------
  // -------------- Modals --------------

  const showModalChangeName = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: {
          header: intl.formatMessage({
            id: 'settings.group.headerChangeName',
          }),
          label: intl.formatMessage({ id: 'settings.group.label' }),
          value: group.name,
          inputType: 'text',
          type: group.name ? 'name' : undefined,
          action: 'rename',
          data: group,
          actionValues: { objectId: group.id, objectType: 'group' },
        },
      }),
    );
  };

  const showModalChangeDescription = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: {
          header: intl.formatMessage({
            id: 'settings.group.headerChangeDescription',
          }),
          label: intl.formatMessage({ id: 'settings.group.label' }),
          value: group.description,
          inputType: 'textarea',
          type: 'description',
          action: 'editDescription',
          data: group,
          actionValues: { objectId: group.id, objectType: 'group' },
        },
      }),
    );
  };

  const handleChangeExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOnReflow: CommonReactLinesEllipsisProps['onReflow'] = (data) => {
    if (clamped !== data.clamped) {
      setClamped(data.clamped);
    }
  };

  return (
    <>
      <div>
        {/* Name */}
        <Setting
          style={{ alignItems: 'flex-start' }}
          label={intl.formatMessage({ id: 'settings.general.name' })}
          value={group?.name}
          onEdit={
            (userPermissions.includes('admin') || userPermissions.includes('owner')) &&
            showModalChangeName
          }
          onEditLabel="global.edit"
          testId="name"
        />

        {/* Created */}
        <Setting
          style={{ alignItems: 'flex-start' }}
          label={intl.formatMessage({ id: 'settings.general.created' })}
          value={
            group.time && (
              <FormattedDate
                value={group.time.creation}
                year="numeric"
                month="long"
                day="2-digit"
              />
            )
          }
          testId="created"
        />

        {/* Description */}
        <Setting
          isDescription
          clamped={clamped}
          expanded={isExpanded}
          changeExpanded={handleChangeExpanded}
          style={{ alignItems: 'flex-start' }}
          label={intl.formatMessage({ id: 'settings.general.description' })}
          value={
            <LinesEllipsis
              text={group?.description || intl.formatMessage({ id: 'NO_DESCRIPTION' })}
              maxLine={isExpanded ? 20 : 3}
              ellipsis="..."
              trimRight
              basedOn="letters"
              onReflow={handleOnReflow}
            />
          }
          onEdit={
            (userPermissions.includes('admin') || userPermissions.includes('owner')) &&
            showModalChangeDescription
          }
          onEditLabel="global.edit"
          testId="description"
        />

        {/* Tags */}
        <SettingTags groupId={groupId} testId="group" />
      </div>
    </>
  );
};

export default GeneralView;
