import { TypedEmitter, ListenerSignature } from 'tiny-typed-emitter';

export abstract class BaseTypedEmitter<K extends ListenerSignature<K> = ListenerSignature<unknown>>
  extends TypedEmitter<K>
  implements Realtime.Core.IBaseTypedEmitter<K>
{
  constructor() {
    super();
    this.setMaxListeners(30);
  }

  destroy() {
    this.removeAllEventEmitterListeners();
  }

  protected removeAllEventEmitterListeners() {
    const eventNames: (keyof K)[] = this.eventNames();
    if (eventNames?.length) {
      for (let i = 0; i < eventNames.length; i++) {
        const event = eventNames[i];
        this.removeAllListeners(event);
      }
    }
  }
}
