export type FilterOption =
  | 'content'
  | 'authors.affiliation'
  | 'authors.name'
  | 'description'
  | 'name'
  | 'tags'
  | 'keywords'
  | 'mime_type'
  | 'metadata'
  | 'status'
  | 'reference_priority'
  | 'creation'
  | 'modification'
  | 'deadline';

export type AdvancedFilter = {
  id: Uuid;
  option: FilterOption;
  operator?: string;
  metadataOption?: string;
  value?: string;
  auxValue?: string;
  query?: string;
  unprocessed?: boolean;
};

//TODO: Check if this type is created somewhere global and use it instead
export type SelectOption = {
  value: string;
  label: string;
  //Allows specific field id if needed
  overrideField?: string;
};
export type SelectOptionTyped = {
  value: FilterOption;
  label: string;
  //Allows specific field id if needed
  overrideField?: string;
};

export type FilterByObject = {
  selectedOption: SelectOptionTyped;
  sequence: SequenceOptions;
};

export type HandlerProps = {
  sequence: SequenceOptions;
};

export type SequenceTypes = 'string' | 'date' | 'dynamic' | 'user' | 'text_user';

export type SequenceOptions = {
  // Label preceding select
  preceding?: string;
  // Type of the value following select
  type: SequenceTypes;
  options: SelectOption[];
};

//#region Behaviour Templates
type BehaviourFunction = (params: {
  field: string;
  value: string;
  //Used currently only for 'in between' date type
  auxValue?: string;
}) => string;

const GENERIC_BEHAVIOUR: Record<SequenceTypes, Record<string, BehaviourFunction>> = {
  string: {
    contains: ({ field, value }) => `${field}.contains("${value}")`,
    not_contains: ({ field, value }) => `${field}.contains("${value}")`,
    begins_with: ({ field, value }) => `${field}.begins_with("${value}")`,
    ends_with: ({ field, value }) => `${field}.ends_with("${value}")`,
    eq: ({ field, value }) => `${field} = "${value}"`,
    ne: ({ field, value }) => `${field} != "${value}"`,
  },
  date: {
    lt: ({ field, value }) => `${field} < "${value}"`,
    gt: ({ field, value }) => `${field} > "${value}"`,
    eq: ({ field, value }) => `${field} = "${value}"`,
    in: ({ field, value, auxValue }) => `${field} >= "${value}" and ${field} <= "${auxValue}"`,
  },
  dynamic: {},
  user: {},
  text_user: {},
};

const MIMETYPE_BEHAVIOUR: BehaviourFunction = ({ field, value }) => `${field} = "${value}"`;
const METADATA_BEHAVIOUR: BehaviourFunction = ({ field, value }) => `${field} = "${value}"`;
const USER_BEHAVIOUR: BehaviourFunction = ({ field, value }) => `${field} = "${value}"`;
const STATUS_BEHAVIOUR: BehaviourFunction = ({ field, value }) => `${field} = "${value}"`;
const REF_PRIORITY_BEHAVIOUR: BehaviourFunction = ({ field, value, auxValue }) => {
  return `${field}_${!auxValue ? 'not_' : ''}inserted_in_document = "${value}"`;
};
//#endregion

//#region Sequence options types
const STRING_CONTAINS: SequenceOptions = {
  type: 'string',
  options: [
    {
      value: 'contains',
      label: 'storage.actionBar.search.rules.conditions.contains',
    },
    {
      value: 'not_contain',
      label: 'storage.actionBar.search.rules.conditions.not_contain',
    },
  ],
};

const STRING_COMPARE: SequenceOptions = {
  type: 'string',
  options: [
    {
      value: 'contains',
      label: 'storage.actionBar.search.rules.conditions.contains',
    },
    {
      value: 'begins_with',
      label: 'storage.actionBar.search.rules.conditions.begins_with',
    },
    {
      value: 'ends_with',
      label: 'storage.actionBar.search.rules.conditions.ends_with',
    },
    {
      value: 'eq',
      label: 'storage.actionBar.search.rules.conditions.is',
    },
    {
      value: 'ne',
      label: 'storage.actionBar.search.rules.conditions.is_not',
    },
  ],
};

const STRING_EQUALITY: SequenceOptions = {
  type: 'string',
  options: [
    {
      value: 'eq',
      label: 'storage.actionBar.search.rules.conditions.is',
    },
    {
      value: 'ne',
      label: 'storage.actionBar.search.rules.conditions.is_not',
    },
  ],
};

const MIME_TYPES: SequenceOptions = {
  preceding: 'storage.actionBar.search.rules.conditions.is',
  type: 'dynamic',
  options: [
    {
      value: 'document',
      label: 'storage.actionBar.search.rules.fileTypes.dodoc_document',
      overrideField: 'type',
    },
    {
      value: 'file',
      label: 'storage.actionBar.search.rules.fileTypes.file',
      overrideField: 'type',
    },
    {
      value: 'archive',
      label: 'storage.actionBar.search.rules.fileTypes.archive',
    },
    {
      value: 'executable',
      label: 'storage.actionBar.search.rules.fileTypes.executable',
    },
    {
      value: 'folder',
      label: 'storage.actionBar.search.rules.fileTypes.folder',
      overrideField: 'type',
    },
    {
      value: 'image',
      label: 'storage.actionBar.search.rules.fileTypes.image',
    },
    {
      value: 'video',
      label: 'storage.actionBar.search.rules.fileTypes.video',
    },
    {
      value: 'audio',
      label: 'storage.actionBar.search.rules.fileTypes.audio',
    },
    { value: 'pdf', label: 'PDF' },
    {
      value: 'presentation',
      label: 'storage.actionBar.search.rules.fileTypes.presentation',
    },
    {
      value: 'dopdf',
      label: 'doPDF',
      overrideField: 'type',
    },
  ],
};

const METADATA_TYPES: SequenceOptions = {
  type: 'dynamic',
  options: [],
};

const USER_TYPES: SequenceOptions = {
  type: 'user',
  options: [],
};

const TEXT_USER_TYPES: SequenceOptions = {
  type: 'text_user',
  options: [],
};

const STATUS_TYPES: SequenceOptions = {
  preceding: 'storage.actionBar.search.rules.conditions.is',
  type: 'dynamic',
  options: [],
};

const REFERENCE_PRIORITY_TYPES: SequenceOptions = {
  preceding: 'storage.actionBar.search.rules.conditions.is',
  type: 'dynamic',
  options: [
    {
      value: 'low',
      label: 'editor.sidebar.review.filter.priority.low',
    },
    {
      value: 'medium',
      label: 'editor.sidebar.review.filter.priority.medium',
    },
    {
      value: 'high',
      label: 'editor.sidebar.review.filter.priority.high',
    },
  ],
};

const DATE_COMPARE: SequenceOptions = {
  type: 'date',
  preceding: 'storage.actionBar.search.rules.conditions.is',
  options: [
    {
      value: 'lt',
      label: 'storage.actionBar.search.rules.conditions.before',
    },
    {
      value: 'gt',
      label: 'storage.actionBar.search.rules.conditions.after',
    },
    {
      value: 'eq',
      label: 'storage.actionBar.search.rules.conditions.exactly',
    },
    {
      value: 'in',
      label: 'storage.actionBar.search.rules.conditions.between',
    },
  ],
};
//#endregion

const OPTION_SEQUENCE: Record<FilterOption, SequenceOptions> = {
  //Contains
  content: STRING_CONTAINS,
  //Compare
  'authors.affiliation': STRING_COMPARE,
  'authors.name': STRING_COMPARE,
  description: STRING_COMPARE,
  name: STRING_COMPARE,
  //Equality
  tags: STRING_EQUALITY,
  keywords: STRING_EQUALITY,
  //Mime types
  mime_type: MIME_TYPES,
  //Metadata types
  metadata: METADATA_TYPES,
  //Status types
  status: STATUS_TYPES,
  //Reference Priority types
  reference_priority: REFERENCE_PRIORITY_TYPES,
  //Date
  creation: DATE_COMPARE,
  modification: DATE_COMPARE,
  deadline: DATE_COMPARE,
};

const FILTER_OPTIONS = [
  // Disabled for now because RT isn't ready to handle this filter
  // {
  //   value: 'content',
  //   label: 'global.content',
  // },
  //Compare
  {
    value: 'authors.affiliation',
    label: 'storage.actionBar.search.rules.properties.affiliation',
  },
  {
    value: 'authors.name',
    label: 'storage.actionBar.search.rules.properties.author',
  },
  {
    value: 'description',
    label: 'storage.actionBar.search.rules.properties.description',
  },
  {
    value: 'name',
    label: 'storage.actionBar.search.rules.filters.file_name',
  },
  //Equality
  {
    value: 'tags',
    label: 'storage.actionBar.search.rules.filters.tag',
  },
  {
    value: 'keywords',
    label: 'storage.actionBar.search.rules.properties.keyword',
  },
  // MIME
  {
    value: 'mime_type',
    label: 'storage.actionBar.search.rules.filters.file_type',
  },
  //METADATA
  {
    value: 'metadata',
    label: 'Document metadata',
  },
  //STATUS
  {
    value: 'status',
    label: 'CONTENT_STATUS',
  },
  //REFERENCE_PRIORITY
  {
    value: 'reference_priority',
    label: 'REFERENCE_PRIORITY',
  },
  // DATE
  {
    value: 'creation',
    label: 'storage.actionBar.search.rules.filters.created_date',
  },
  {
    value: 'modification',
    label: 'storage.actionBar.search.rules.filters.last_modified',
  },
  {
    value: 'deadline',
    label: 'storage.actionBar.search.rules.filters.deadline',
  },
] as const;

const CONDITION_TYPES: SelectOption[] = [
  { value: 'and', label: 'AND' },
  { value: 'or', label: 'OR' },
  { value: 'not', label: 'NOT' },
];

const IMAGE_TYPES = [
  {
    value: 'jpeg',
    label: 'JPEG',
  },
  {
    value: 'png',
    label: 'PNG',
  },
  {
    value: 'tiff',
    label: 'TIFF',
  },
  {
    value: 'gif',
    label: 'GIF',
  },
  {
    value: 'bmp',
    label: 'BMP',
  },
];

export const SEQUENCES = { STRING_CONTAINS, STRING_COMPARE, STRING_EQUALITY, DATE_COMPARE };

export {
  FILTER_OPTIONS,
  OPTION_SEQUENCE,
  METADATA_TYPES,
  USER_TYPES,
  TEXT_USER_TYPES,
  CONDITION_TYPES,
  GENERIC_BEHAVIOUR,
  MIMETYPE_BEHAVIOUR,
  METADATA_BEHAVIOUR,
  USER_BEHAVIOUR,
  STATUS_BEHAVIOUR,
  REF_PRIORITY_BEHAVIOUR,
  IMAGE_TYPES,
};
