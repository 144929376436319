import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { useParams } from 'react-router';
import LinesEllipsis from 'react-lines-ellipsis';

import UserCard from '_common/components/UserCard/UserCard';
import TagSystem from '_common/components/TagSystem/TagSystem';

import { Setting } from 'Settings/components';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import styles from './SharedSpaceSettings.module.scss';

type OpenGenericSettingModalProps = {
  header: string;
  label: string;
  value: string;
  inputType: string;
  type: string | undefined;
  action: string;
  actionValues: { objectId: string; objectType: string };
};

const SharedSpaceSettings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id: objectId }: { id: ObjectId } = useParams();

  const object = useSelector((state) => state.app.data[objectId]);
  const userPermissions = object.user_permissions;

  const [isExpanded, setIsExpanded] = useState(false);
  const [clamped, setClamped] = useState(false);

  useEffect(() => {
    setIsExpanded(false);
  }, [object.description]);

  const openChangeManagerModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ChangeManagerModal',
        data: { name: object.name, data: object },
      }),
    );
  };

  const handleOpenGenericSettingModal = ({
    header,
    label,
    value,
    inputType,
    type,
    action,
    actionValues,
  }: OpenGenericSettingModalProps) => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: { header, label, value, inputType, type, action, actionValues },
      }),
    );
  };

  const handleUpdateName = () => {
    handleOpenGenericSettingModal({
      header: intl.formatMessage({
        id: 'spaces.editName',
      }),
      label: intl.formatMessage({
        id: 'spaces.newValue',
      }),
      value: object.name,
      type: object.name ? 'name' : undefined,
      inputType: 'text',
      action: 'rename',
      actionValues: { objectId: object.id, objectType: object.type },
    });
  };

  const handleUpdateDescription = () => {
    handleOpenGenericSettingModal({
      header: intl.formatMessage({
        id: 'spaces.editDescription',
      }),
      label: intl.formatMessage({
        id: 'spaces.newValue',
      }),
      value: object.description,
      type: 'description',
      inputType: 'textarea',
      action: 'editDescription',
      actionValues: { objectId: object.id, objectType: object.type },
    });
  };

  const handleChangeExpanded = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const handleOnReflow = (object: { clamped: boolean; text: string }) => {
    if (clamped !== object.clamped) {
      setClamped(object.clamped);
    }
  };

  return (
    <>
      <div className={styles.root}>
        {/* Title */}
        <Setting
          value={object.name}
          onEdit={
            (userPermissions.includes('admin') || userPermissions.includes('owner')) &&
            handleUpdateName
          }
          label={intl.formatMessage({
            id: 'global.name',
          })}
          onEditLabel="global.edit"
          testId="name"
        />
        {/* Creation */}
        <Setting
          value={<UserCard userId={object.creator} />}
          label={intl.formatMessage({
            id: 'global.creator',
          })}
          testId="creator"
        />
        {/* Owner */}
        <Setting
          manager
          value={<UserCard userId={object.owner} />}
          label={intl.formatMessage({
            id: 'global.owner',
          })}
          onEdit={
            (userPermissions.includes('admin') || userPermissions.includes('owner')) &&
            openChangeManagerModal
          }
          onEditLabel="global.edit"
          testId="owner"
        />
        {/* Description */}
        <Setting
          isDescription
          clamped={clamped}
          expanded={isExpanded}
          changeExpanded={handleChangeExpanded}
          label={intl.formatMessage({
            id: 'settings.general.description',
          })}
          onEdit={
            (userPermissions.includes('admin') || userPermissions.includes('owner')) &&
            handleUpdateDescription
          }
          value={
            <LinesEllipsis
              text={
                object.description ||
                intl.formatMessage({
                  id: 'NO_DESCRIPTION',
                })
              }
              maxLine={isExpanded ? 20 : 3}
              ellipsis="..."
              trimRight
              basedOn="letters"
              onReflow={handleOnReflow}
              data-testid="description-value-content"
            />
          }
          onEditLabel="global.edit"
          testId="description"
        />
        {/* Tags */}
        <div className={styles.setting} data-testid="tags-row-container">
          <span className={styles.label}>
            <FormattedMessage id="settings.general.tags" />
          </span>
          <div className={styles.value}>
            <TagSystem
              objectId={objectId}
              placeholder={intl.formatMessage({ id: 'SELECT_TAG' })}
              testId="space-settings"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default SharedSpaceSettings;
