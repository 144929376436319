import { Divider, Input, Toggle, Tooltip } from 'dodoc-design-system';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { PageNavigator, Toolbar } from '_common/suite/components';
import { useDispatch, useIEnvision, useSelector } from '_common/hooks';
import { setShowNav } from './PresentationSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { useSuiteObject } from '_common/suite';

const PresentationToolbar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isIEnvision = useIEnvision();
  const object = useSuiteObject();
  const showNav = useSelector((state) => state.presentation.showNav);

  const versionHistory = false;
  const canAccessAuditLog = true;
  const canSaveVersion = true;

  const currentPageNumber = 1;
  const totalPageNumber = 10;

  const canCreateAnnotation = true;

  let cursorMode = 'normal';

  const openAuditLogModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'DocumentAuditLogModal',
        data: { objectId: object.id, objectType: object.type },
      }),
    );
  };

  const openSaveVersionModal = (index?: number | null) => {
    const versions = object.lifecycle.versions;
    dispatch(
      openAndUpdateModal({
        modal: 'SaveVersionModal',
        data: {
          type: index == null ? 'saveVersion' : 'editDescription',
          index: index ?? null,
          description: index == null ? undefined : versions?.[index]?.description,
        },
      }),
    );
  };

  const handleCursorMode = (mode: 'normal' | 'pan' | 'comment' | 'task') => {
    console.log('TBI');
  };

  return (
    <Toolbar>
      <Toolbar.Left>
        <Tooltip
          content={intl.formatMessage({ id: 'SIDEBAR' })}
          placement="bottom"
          testId="thumbnails-tooltip"
        >
          <Toggle
            variant="ghost"
            isToggled={showNav}
            onClick={() => dispatch(setShowNav(!showNav))}
            size="medium"
            icon={showNav ? 'SidepanelBlue' : 'SidepanelGrey'}
            testId="thumbnails-toggle"
          />
        </Tooltip>
        <Divider vertical margin="0 1rem" />
        {!isIEnvision && canAccessAuditLog && !versionHistory && (
          <Tooltip
            content={intl.formatMessage({ id: 'DOCUMENT_AUDIT_LOG' })}
            placement="bottom"
            testId="audit-log-tooltip"
          >
            <Toggle
              margin="0 1rem 0 0"
              variant="ghost"
              size="medium"
              onClick={openAuditLogModal}
              icon="PDFAuditLog"
              testId="audit-log-toggle"
            />
          </Tooltip>
        )}
        {!isIEnvision && canSaveVersion && (
          <Tooltip
            content={intl.formatMessage({ id: 'SAVE_VERSION' })}
            placement="bottom"
            testId="save-version-tooltip"
          >
            <Toggle
              variant="ghost"
              size="medium"
              onClick={() => {
                openSaveVersionModal();
              }}
              icon="SaveGrey"
              testId="save-version-toggle"
            />
          </Tooltip>
        )}
        <Divider vertical margin="0 1rem" />
        <PageNavigator
          currentPageNumber={currentPageNumber}
          totalPageNumber={totalPageNumber}
          goToPage={() => console.warn('TBI')}
          onPageDown={() => console.warn('TBI')}
          onPageUp={() => console.warn('TBI')}
        />
      </Toolbar.Left>
      <Toolbar.MiddleLeft>
        <Tooltip
          placement="bottom"
          content={intl.formatMessage({ id: 'MOUSE' })}
          testId="menu-mouse-tooltip"
        >
          <Toggle
            variant="ghost"
            isToggled={cursorMode === 'normal'}
            onClick={() => handleCursorMode('normal')}
            size="medium"
            icon={cursorMode === 'normal' ? 'CursorBlue' : 'CursorGrey'}
            testId="menu-mouse-toggle"
          />
        </Tooltip>
        <Tooltip
          placement="bottom"
          content={intl.formatMessage({ id: 'PAN' })}
          testId="menu-pan-tooltip"
        >
          <Toggle
            margin="0 0 0 1rem"
            variant="ghost"
            isToggled={cursorMode === 'pan'}
            onClick={() => handleCursorMode('pan')}
            size="medium"
            icon={cursorMode === 'pan' ? 'HandBlue' : 'HandGrey'}
            testId="menu-pan-toggle"
          />
        </Tooltip>
      </Toolbar.MiddleLeft>
      <Toolbar.Middle>
        <Divider vertical margin="0 1rem" />
      </Toolbar.Middle>
      <Toolbar.MiddleRight>
        <Tooltip
          content={
            canCreateAnnotation
              ? intl.formatMessage({ id: 'ADD_COMMENT' })
              : intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
          }
          placement="bottom"
          testId="menu-comment-tooltip"
        >
          <Toggle
            margin="0 0 0 1rem"
            variant="ghost"
            isToggled={cursorMode === 'comment'}
            onClick={() => handleCursorMode('comment')}
            size="medium"
            icon={cursorMode === 'comment' ? 'PDFCommentBlue' : 'PDFCommentGrey'}
            disabled={!canCreateAnnotation}
            testId="menu-comment-toggle"
          />
        </Tooltip>
        <Tooltip
          content={
            canCreateAnnotation
              ? intl.formatMessage({ id: 'ADD_TASK' })
              : intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
          }
          placement="bottom"
          testId="menu-task-tooltip"
        >
          <Toggle
            margin="0 0 0 1rem"
            variant="ghost"
            isToggled={cursorMode === 'task'}
            onClick={() => handleCursorMode('task')}
            size="medium"
            icon={cursorMode === 'task' ? 'TaskBlue' : 'TaskGrey'}
            disabled={!canCreateAnnotation}
            testId="menu-task-toggle"
          />
        </Tooltip>
      </Toolbar.MiddleRight>
      <Toolbar.Right>
        <Input
          placeholder={intl.formatMessage({ id: 'SEARCH_TEXT' })}
          testId="find-text-search-input"
          prefix="NavSearchGrey"
          size="medium"
        />
      </Toolbar.Right>
    </Toolbar>
  );
};

export default memo(PresentationToolbar);
