import TokenList from '_common/modals/UserManagement/UserTokensModal/TokenList/TokenList';

const List = () => {
  return (
    <TokenList
      emptyState={{
        title: 'NO_TOKEN_HISTORY',
        body: 'USER_DOES_NOT_HAVE_TOKEN_HISTORY',
      }}
      loadingState={{ message: 'LOADING_ACTIVE_SESSION' }}
      filters={{
        filter_fields: [],
        filter_values: [],
      }}
    />
  );
};

export default List;
