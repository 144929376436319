import { Mixin } from 'mixwith';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import ReduxInterface from 'Editor/services/ReduxInterface';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class DeleteHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       * handle delete on multi selection
       * @param {ActionContext} actionContext
       */
      handleDeleteOnMultiSelection(actionContext, level0Node, options = {}) {
        const { fixTextSelection = true } = options;

        actionContext.selectionType = ActionContext.SELECTION.RANGE;
        if (fixTextSelection) {
          EditorSelectionUtils.fixNonCollapsedTextSelection({
            suggestionMode: true,
            forceTextAsWrap: true,
            // forceNonEditableDirection: 'FORWARD', // avoid these properties here with multiselection
            // isDelete: true,
          });
        }
        this.removeSelectionContent(actionContext);
      }

      /**
       * selects a function to handle delete based on baseNode
       * @param {ActionContext} actionContext
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleDeleteOnCollpasedSelection(actionContext, baseNode, anchorNode, anchorOffset) {
        if (!baseNode || EditorDOMElements.isNodeContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        if (baseNode) {
          if (anchorNode) {
            if (
              baseNode.tagName === ELEMENTS.ParagraphElement.TAG &&
              this.dataManager.numbering.isListElement(baseNode.id)
            ) {
              // SELECTION IS A LIST
              this.handleDeleteOnListElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (EditorDOMUtils.isClosestTextElementEditable(baseNode)) {
              EditorSelectionUtils.fixCollapsedTextSelection({
                suggestionMode: true,
                forceNonEditableDirection: 'FORWARD',
                isDelete: true,
              });
              const selection = EditorSelectionUtils.getSelection();
              anchorNode = selection.anchorNode;
              anchorOffset = selection.anchorOffset;
              // SELECTION IS A DEFAULT TEXT ELEMENT
              const selectionCheck = EditorDOMUtils.closest(
                anchorNode,
                EditorDOMElements.BLOCK_TEXT_ELEMENTS,
              );
              this.handleDeleteOnTextElement(
                actionContext,
                baseNode,
                selectionCheck,
                anchorNode,
                anchorOffset,
              );
            } else if (!EditorDOMUtils.isClosestBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              this.handleNonEditableElementDelete(actionContext, baseNode);
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              this.handleDeleteOnFigureElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this.handleDeleteOnTableElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (EditorDOMElements.isNodeContainerElement(baseNode)) {
              this.handleDeleteOnContainerElement(
                actionContext,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            }
          }
        }
      }

      handleDeleteOnContainerElement(actionContext, baseNode, anchorNode, anchorOffset) {
        if (baseNode === anchorNode) {
          if (anchorOffset === 0) {
            EditorSelectionUtils.selectNode(anchorNode);
            return;
          } else if (anchorOffset === anchorNode.childNodes.length) {
            if (baseNode.nextSibling) {
              EditorSelectionUtils.setCaret(baseNode.nextSibling, 'INSIDE_START');
            }
            return;
          } else {
            if (EditorSelectionUtils.fixSelection()) {
              const selection = EditorSelectionUtils.getSelection();
              anchorNode = selection.anchorNode;
              anchorOffset = selection.anchorOffset;
            }
          }
        } else if (baseNode.tagName === ELEMENTS.TrackDeleteElement.TAG) {
          if (baseNode.nextSibling) {
            EditorSelectionUtils.setCaret(baseNode.nextSibling, 'INSIDE_START');
          }
          return;
        }

        const subLevel0Node = EditorDOMUtils.findFirstLevelChildNode(baseNode, anchorNode);
        if (subLevel0Node) {
          this.handleDeleteOnCollpasedSelection(
            actionContext,
            subLevel0Node,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       * handle delete event on list elements
       * @param {ActionContext} actionContext
       * @param {Node} anchorNode
       */
      handleDeleteOnListElement(actionContext, listNode, anchorNode, anchorOffset) {
        if (EditorDOMUtils.isEmptyElement(listNode)) {
          this.stylesHandler.removeListStyleFromBlock(actionContext, listNode);
        }
        this.handleDeleteOnTextElement(actionContext, listNode, listNode, anchorNode, anchorOffset);
      }

      /**
       * handle delete event on a figure element
       * @param {ActionContext} actionContext
       * @param {Node} figureNode
       * @param {Node} anchorNode
       */
      handleDeleteOnFigureElement(actionContext, figureNode, anchorNode, anchorOffset) {
        const closest = EditorDOMUtils.closest(anchorNode, ['FIGCAPTION', 'IMAGE-ELEMENT']);
        if (closest) {
          if (closest.tagName === 'IMAGE-ELEMENT') {
            if (
              anchorNode.nodeType === Node.TEXT_NODE &&
              anchorNode.nextSibling &&
              anchorNode.nextSibling.tagName === 'DIV'
            ) {
              EditorSelectionUtils.selectLevel0Node(figureNode);
            } else if (
              (closest === anchorNode && anchorOffset === 0) ||
              (closest !== anchorNode && !anchorNode.previousSibling)
            ) {
              EditorSelectionUtils.selectLevel0Node(figureNode);
            }
          }
        } else if (
          anchorNode.tagName === ELEMENTS.FigureElement.TAG ||
          anchorNode.tagName === 'IMAGE-ELEMENT' ||
          anchorNode.tagName === 'IMG'
        ) {
          if (anchorOffset === 0) {
            EditorSelectionUtils.selectLevel0Node(figureNode);
          }
        }
      }

      /**
       * handle delete event on table element
       * @param {ActionContext} actionContext
       * @param {Node} anchorNode
       */
      handleDeleteOnTableElement(actionContext, tableNode, anchorNode, anchorOffset) {
        const closest = EditorDOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        actionContext.nodeContext = ActionContext.NODE_CONTEXT.TABLE;
        if (anchorNode.tagName === ELEMENTS.TableElement.TAG) {
          if (anchorOffset === 0) {
            EditorSelectionUtils.selectLevel0Node(anchorNode);
          }
        } else if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            const tdLevel0Node = EditorDOMUtils.findFirstLevelChildNode(closest, anchorNode);
            if (tdLevel0Node) {
              if (tdLevel0Node.tagName !== ELEMENTS.TableElement.TAG) {
                this.handleDeleteOnCollpasedSelection(
                  actionContext,
                  tdLevel0Node,
                  anchorNode,
                  anchorOffset,
                );
              } else if (tdLevel0Node !== tableNode) {
                EditorSelectionUtils.selectNode(tdLevel0Node);
              }
            }
          }
        }
      }

      /**
       * handle delete event on a default text element
       * @param {ActionContext} actionContext
       */
      handleDeleteOnTextElement(
        actionContext,
        level0Node,
        textElementNode,
        anchorNode,
        anchorOffset,
      ) {
        if (textElementNode) {
          if (EditorSelectionUtils.isSelectionAtEnd(textElementNode)) {
            // selection is at end
            let nextSibling;

            if (textElementNode?.nextSibling || level0Node.nextSibling) {
              nextSibling = textElementNode ? textElementNode.nextSibling : level0Node.nextSibling;
            } else if (
              EditorDOMElements.isNodeContainerElement(level0Node.parentNode) &&
              level0Node.parentNode.tagName !== ELEMENTS.TableCellElement.TAG
            ) {
              nextSibling = EditorDOMUtils.findNextAncestorSibling(level0Node);
            }

            if (nextSibling) {
              if (nextSibling.tagName === 'TRACK-DEL-ELEMENT') {
                // TODO : set caret previousSibling.previousSibling
                EditorSelectionUtils.setCaret(nextSibling, 'PRE');
              } else if (nextSibling.tagName === 'TRACK-INS-ELEMENT') {
                EditorSelectionUtils.selectNode(nextSibling);
              } else if (!EditorDOMUtils.isBlockNodeDeletable(nextSibling)) {
                // previous sibling is non-deletable
                if (EditorDOMUtils.isEmptyElement(level0Node) && nextSibling.selectableContent) {
                  EditorSelectionUtils.selectNode(level0Node);
                  const range = EditorSelectionUtils.getRange();
                  this.addDeleteSuggestionOnRange(actionContext, range);
                  EditorSelectionUtils.setCaret(nextSibling.selectableContent, 'INSIDE_START');
                } else {
                  EditorSelectionUtils.selectNode(level0Node);
                }
              } else if (!EditorDOMUtils.isClosestBlockNodeEditable(nextSibling)) {
                if (EditorDOMElements.BLOCK_INVALID_ELEMENTS.includes(nextSibling.tagName)) {
                  EditorSelectionUtils.setCaret(textElementNode, 'INSIDE_START');

                  // remove element
                  actionContext.addChange(
                    ActionContext.CHANGE_TYPE.REMOVED,
                    nextSibling.getAttribute('id'),
                  );
                  nextSibling.remove();
                } else {
                  EditorSelectionUtils.selectNode(nextSibling);
                  const range = EditorSelectionUtils.getRange();
                  this.addDeleteSuggestionOnRange(actionContext, range);
                  // EditorSelectionUtils.setCaret(textElementNode, 'INSIDE_START');
                }
              } else if (
                nextSibling.tagName === ELEMENTS.FigureElement.TAG ||
                nextSibling.tagName === ELEMENTS.TableElement.TAG ||
                EditorDOMElements.isNodeContainerElement(nextSibling)
              ) {
                EditorSelectionUtils.selectNode(nextSibling);
              } else if (EditorDOMUtils.isClosestTextElementEditable(nextSibling)) {
                // next sibling is a default text element

                this.selectionManager.setRangeStart(
                  textElementNode,
                  'INDEX',
                  textElementNode.childNodes.length,
                );

                this.selectionManager.setRangeEnd(nextSibling, 'INDEX', 0);

                this.handleDeleteOnMultiSelection(actionContext, level0Node, {
                  fixTextSelection: false,
                });
                if (nextSibling && nextSibling.parentNode) {
                  EditorSelectionUtils.setCaret(nextSibling, 'INDEX', 0);
                }
              }
            }
          } else {
            // selection is at start or mid

            const closest = EditorDOMUtils.closest(anchorNode, [
              ...EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
              ELEMENTS.TrackDeleteElement.TAG,
            ]);

            let nextClosest;
            if (!closest) {
              let nextSibling;

              if (EditorSelectionUtils.isSelectionAtEnd(anchorNode)) {
                nextSibling = anchorNode.nextSibling;
              } else if (!(anchorNode instanceof Text)) {
                nextSibling = textElementNode.childNodes[anchorOffset];
              }

              if (nextSibling) {
                if (
                  nextSibling.nodeType === Node.ELEMENT_NODE &&
                  (nextSibling.tagName === 'TRACK-INS-ELEMENT' ||
                    nextSibling.tagName === 'TRACK-DEL-ELEMENT') &&
                  nextSibling.childNodes.length > 0
                ) {
                  nextClosest = EditorDOMUtils.closest(nextSibling.firstChild, [
                    ...EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
                    ELEMENTS.TrackDeleteElement.TAG,
                    ELEMENTS.FieldElement.TAG,
                  ]);
                } else {
                  nextClosest = EditorDOMUtils.closest(nextSibling, [
                    ...EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
                    ELEMENTS.TrackDeleteElement.TAG,
                    ELEMENTS.FieldElement.TAG,
                  ]);
                }
              }
            }

            const nonEditable = closest || nextClosest;
            // handle non-editable text elements
            if (nonEditable) {
              if (nonEditable.tagName === ELEMENTS.CitationElement.TAG) {
                // WARN: legacy code
                if (!closest && nextClosest) {
                  EditorSelectionUtils.setCaret(nextClosest, 'INSIDE_START');
                  this.removeCitations(actionContext, nextClosest, 0, nonEditable);
                } else {
                  this.removeCitations(actionContext, anchorNode, anchorOffset, nonEditable);
                }
              } else if (nonEditable.tagName === ELEMENTS.CitationsGroupElement.TAG) {
                EditorSelectionUtils.selectNode(nonEditable);
              } else if (
                nonEditable.tagName === ELEMENTS.EquationElement.TAG ||
                nonEditable.tagName === ELEMENTS.FieldElement.TAG
              ) {
                nonEditable.selected = true;
                EditorSelectionUtils.selectNode(nonEditable);

                // trigger confirmation modal
                if (nonEditable.isTypeCaption()) {
                  ReduxInterface.openDeleteCaptionConfirmationModal();
                }
              } else if (nonEditable.tagName === 'TRACK-DEL-ELEMENT') {
                this.setCursorPosition(
                  nonEditable.getAttribute('element_reference'),
                  actionContext.collapseDirection,
                );
              } else {
                EditorSelectionUtils.selectNode(nonEditable);
                this.handleDeleteOnMultiSelection(actionContext);
              }
            } else {
              // handle normal characters

              this.selectionManager.modifySelection('expand', 'forward', 'character');

              this.handleDeleteOnMultiSelection(actionContext);
            }
          }
        }
      }

      /**
       * handle delete on a non-editable element
       * @param {ActionContext} actionContext
       */
      handleNonEditableElementDelete(actionContext, level0Node) {
        // delete
        if (this.hasEditPermissions()) {
          const contentEditable = level0Node && level0Node.selectableContent;

          if (contentEditable) {
            if (EditorSelectionUtils.isSelectionAtEnd(contentEditable)) {
              const nextSibling =
                level0Node.parentNode === EditorDOMUtils.getContentContainer()
                  ? level0Node.nextSibling
                  : EditorDOMUtils.findFirstLevelChildNode(
                      EditorDOMUtils.getContentContainer(),
                      level0Node,
                    ).nextSibling;

              if (nextSibling) {
                if (EditorDOMUtils.isClosestBlockNodeEditable(nextSibling)) {
                  if (EditorDOMUtils.isEmptyElement(nextSibling)) {
                    EditorSelectionUtils.setCaret(nextSibling, 'INSIDE_START');
                  } else {
                    EditorSelectionUtils.selectNode(nextSibling);
                  }
                } else {
                  const nodeToSelect = nextSibling.selectableContent
                    ? nextSibling.selectableContent
                    : nextSibling;
                  EditorSelectionUtils.setCaret(nodeToSelect, 'INSIDE_START');
                }
              }
            } else if (EditorSelectionUtils.isSelectionAtStart(contentEditable)) {
              if (EditorDOMUtils.isBlockNodeDeletable(level0Node)) {
                if (EditorDOMElements.BLOCK_INVALID_ELEMENTS.includes(level0Node.tagName)) {
                  const nodeToSelect = level0Node.nextSibling.selectableContent
                    ? level0Node.nextSibling.selectableContent
                    : level0Node.nextSibling;
                  EditorSelectionUtils.setCaret(nodeToSelect, 'INSIDE_START');

                  // remove element
                  actionContext.addChange(
                    ActionContext.CHANGE_TYPE.REMOVED,
                    level0Node.getAttribute('id'),
                  );
                  level0Node.remove();
                } else if (level0Node.parentNode === EditorDOMUtils.getContentContainer()) {
                  let nodeToSelect;
                  if (level0Node.nextSibling) {
                    nodeToSelect = level0Node.nextSibling.selectableContent
                      ? level0Node.nextSibling.selectableContent
                      : level0Node.nextSibling;
                  } else {
                    const p = DOMElementFactory.createNewParagraphElement();
                    const suggestionInsert = this.getTrackInsElement(actionContext.id);
                    suggestionInsert.appendChild(p);

                    const data = NodeDataBuilder.build({
                      ...this.documentParser.parse(suggestionInsert),
                      parent_id: level0Node.parentNode.id,
                    });
                    this.inserBlockNodeOperation(actionContext, data, level0Node, 'AFTER');
                    nodeToSelect = EditorDOMUtils.getBlockNode(data.id).lastChild;
                  }
                  const suggestionDelete = this.getTrackDelElement(actionContext.id);
                  suggestionDelete.appendChild(level0Node.cloneNode(true));

                  const data = NodeDataBuilder.build({
                    ...this.documentParser.parse(suggestionDelete),
                    parent_id: level0Node.parentNode.id,
                  });
                  this.inserBlockNodeOperation(actionContext, data, level0Node, 'BEFORE');

                  this.removeBlockElementFromDOM(actionContext, level0Node);

                  EditorSelectionUtils.setCaret(nodeToSelect, 'INSIDE_START');
                } else if (level0Node.parentNode.tagName === ELEMENTS.TrackInsertElement.TAG) {
                  EditorSelectionUtils.selectNodeContents(level0Node.parentNode);
                }
              }
            }
          }
        }
      }
    },
);
