import { ChangeEventHandler, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Select, InputField, TextArea, Tag } from 'dodoc-design-system';
import type { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

import { useDispatch, usePublicProfile } from '_common/hooks';
import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';
import SearchUser, { UserOption } from '_common/components/SearchUser/SearchUser';
import { editMetadata } from 'App/redux/appSlice';

import styles from './MetadataFields.module.scss';

type MetadataFieldsProps = {
  validations?: string;
  type: string;
  metadataList: MetadataList;
  id: ObjectId;
  element: Objekt;
  setValidations: (value: string) => void;
};

const MetadataFields = ({
  validations,
  type,
  metadataList,
  id,
  element,
  setValidations,
}: MetadataFieldsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isRequired = useMemo(() => {
    return metadataList[id].required;
  }, [metadataList, id]);

  const [contentName, setContentName] = useState(
    metadataList[id].data_type === 'text' ? element?.metadata?.[id] : '',
  );
  const [errors, setErrors] = useState(
    isRequired && !contentName
      ? intl.formatMessage({ id: 'REQUIRED_FIELD_IS_EMPTY_PLEASE_ENTER_VALUE' })
      : false,
  );
  const handleContentNameChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const value = e.target.value;
    setContentName(value);
    if (isRequired && value.length < 1) {
      setErrors(intl.formatMessage({ id: 'REQUIRED_FIELD_IS_EMPTY_PLEASE_ENTER_VALUE' }));
    } else if (value?.length > metadataList[id].char_limit) {
      setValidations(
        intl.formatMessage(
          { id: 'EXCEEDED_CHARACTER_LIMIT' },
          { value: metadataList[id].char_limit },
        ),
      );
      setErrors(
        intl.formatMessage(
          { id: 'EXCEEDED_CHARACTER_LIMIT' },
          { value: metadataList[id].char_limit },
        ),
      );
    } else {
      setValidations('');
      setErrors(false);
    }
  };

  const profile = usePublicProfile(
    element?.metadata?.[id],
    '',
    metadataList[id].data_type !== 'user',
  );

  const [selectedOption, setSelectedOption] = useState<SelectOption>({
    value: element?.metadata?.[id] || '',
    label: element?.metadata?.[id] ? (
      type === 'user' ? (
        <UsernameLabel userId={element?.metadata?.[id]} />
      ) : type === 'text_user' ? (
        (profile?.type !== 'removed' && profile?.name) || element.metadata[id]
      ) : (
        element.metadata[id]
      )
    ) : (
      ''
    ),
  });

  const [selectedOptions, setSelectedOptions] = useState(
    element?.metadata && Array.isArray(element.metadata[id])
      ? element?.metadata[id].map((value: string) => {
          return { label: value, value };
        })
      : [],
  );
  const handleUpdateField = (id: ObjectId, value: { value: string }) => {
    if (!validations)
      dispatch(
        editMetadata({
          objectId: element.id,
          parameters: { field: id, value: value ? value.value : '' },
        }),
      );
  };

  const handleOnChange = (value: { value: string } | null) => {
    setSelectedOptions([...selectedOptions, value]);
    dispatch(
      editMetadata({
        objectId: element.id,
        parameters: {
          field: id,
          value: value ? value.value : '',
          add: !!value,
        },
      }),
    );
  };

  const handleRemove = (value: { value: string; label: string }) => {
    const options = selectedOptions
      .map((option: { value: string; label: string }) => option)
      .filter((option: { value: string; label: string }) => option.label !== value.label);
    setSelectedOptions(options);
    dispatch(
      editMetadata({
        objectId: element.id,
        parameters: {
          field: id,
          value: value ? value.value : '',
          add: false,
        },
      }),
    );
  };

  const fieldName = metadataList[id]?.name.replace(' ', '-').toLowerCase();
  const fieldTestId = `edit-metadata-${fieldName}`;
  switch (type) {
    case 'user':
    case 'text_user':
      return (
        <InputField
          size="large"
          label={metadataList[id]?.name}
          required={isRequired && intl.formatMessage({ id: 'REQUIRED' })}
          feedback={
            isRequired &&
            !selectedOption?.value &&
            intl.formatMessage({ id: 'REQUIRED_FIELD_IS_EMPTY_PLEASE_SELECT_OPTION' })
          }
          testId={fieldTestId}
        >
          <SearchUser
            width="100%"
            size="large"
            menuPosition="fixed"
            placeholder={intl.formatMessage({ id: 'SELECT_A_DODOC_USER' })}
            hideSelectedOptions={false}
            value={selectedOption?.value ? selectedOption : null}
            onChange={(value) => {
              setSelectedOption(value);
              if (value) {
                if (type === 'user') {
                  handleUpdateField(id, value);
                } else {
                  handleUpdateField(id, {
                    value: (value as UserOption).name || (value.label as string),
                  });
                }
              } else {
                handleUpdateField(id, { value: '' });
              }
            }}
            error={isRequired && !selectedOption?.value}
            creatable={type === 'text_user'}
            testId={fieldTestId}
            menuLabel={intl.formatMessage({ id: 'START_TYPING_TO_SEE_THE_LIST_OF_USERS' })}
          />
        </InputField>
      );

    case 'select':
      return (
        <InputField
          size="large"
          label={metadataList[id]?.name}
          required={isRequired && intl.formatMessage({ id: 'REQUIRED' })}
          feedback={
            isRequired &&
            (!selectedOption || !selectedOption.value) &&
            intl.formatMessage({ id: 'REQUIRED_FIELD_IS_EMPTY_PLEASE_SELECT_OPTION' })
          }
          testId={fieldTestId}
        >
          <Select
            clearable={false}
            menuPosition="fixed"
            searchable
            size="large"
            width="100%"
            showOptionTooltip
            value={selectedOption?.value ? selectedOption : null}
            options={metadataList[id]?.values.map((option) => ({ label: option, value: option }))}
            onChange={(value) => {
              setSelectedOption(value ?? { label: '', value: '' });
              handleUpdateField(id, value);
            }}
            placeholder={intl.formatMessage({
              id: `SELECT_${metadataList[id]?.name.split(' ')[0].toUpperCase()}`,
            })}
            error={isRequired && !selectedOption?.value}
            testId={fieldTestId}
          />
        </InputField>
      );
    case 'text':
      return (
        <InputField
          size="large"
          label={metadataList[id]?.name}
          required={isRequired && intl.formatMessage({ id: 'REQUIRED' })}
          feedback={errors}
          testId={fieldTestId}
        >
          <TextArea
            size="large"
            placeholder={intl.formatMessage(
              {
                id: 'INSERT_HERE',
              },
              {
                value: metadataList[id]?.name,
              },
            )}
            onChange={handleContentNameChange}
            value={contentName}
            error={!!errors}
            onBlur={() => handleUpdateField(id, { value: contentName })}
            testId={`${fieldTestId}-textarea`}
          />
        </InputField>
      );

    default:
      return (
        <InputField
          size="large"
          label={metadataList[id]?.name}
          required={isRequired && intl.formatMessage({ id: 'REQUIRED' })}
          feedback={
            isRequired &&
            selectedOptions?.length < 1 &&
            intl.formatMessage({ id: 'REQUIRED_FIELD_IS_EMPTY_PLEASE_SELECT_OPTION' })
          }
          testId={fieldTestId}
        >
          <>
            <Select
              clearable={false}
              menuPosition="fixed"
              searchable
              showOptionTooltip
              size="large"
              width="100%"
              options={metadataList[id]?.values
                .map((option) => ({ label: option, value: option }))
                .filter((option) => {
                  return (
                    selectedOptions &&
                    !selectedOptions
                      .map((value: { label: string }) => value.label)
                      .includes(option.label)
                  );
                })}
              onChange={handleOnChange}
              placeholder={intl.formatMessage({
                id: `SELECT_${
                  metadataList[id]?.name === 'Product-Molecule-RO#'
                    ? metadataList[id]?.name.split('-')[0].toUpperCase()
                    : metadataList[id]?.name.split(' ')[0].toUpperCase()
                }`,
              })}
              value={null}
              error={isRequired && selectedOptions?.length < 1}
              testId={fieldTestId}
            />
            {selectedOptions && (
              <div className={styles.multiSelect}>
                {selectedOptions.map((option: { label: string; value: string }, i: number) => (
                  <Tag
                    key={option.label}
                    title={option.label}
                    onClick={() => handleRemove(option)}
                    margin="1rem 1rem 0 0"
                    testId={`${fieldTestId}-${i}`}
                  >
                    {option.label}
                  </Tag>
                ))}
              </div>
            )}
          </>
        </InputField>
      );
  }
};

export default MetadataFields;
