import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from 'router/PrivateRoute/PrivateRoute';

import Auth from 'Auth/AuthContainer';
import Editor from 'Editor/Editor';
import Storage from 'Storage/StorageContainer';
import Settings from 'Settings/SettingsContainer';
import Groups from 'Groups/GroupsContainer';
import Public from 'Public/PublicContainer';
import Shared from 'Shared/SharedContainer';
import SearchContainer from 'Search/SearchContainer';
import Extensions from 'Extensions/ExtensionsContainer';
import File from 'File/FileContainer';

import { Error404, Error403, ProductGate } from '_common/components';
import PDF from 'PDF/PDF';
import Presentation from 'Presentation/Presentation';
import PresentationProvider from 'Presentation/PresentationContext';

/**
 * ROUTER
 * doDOC's route configuration
 */
const Routes = () => (
  <Switch>
    <PrivateRoute exact path="/">
      <Redirect to="/storage" />
    </PrivateRoute>
    <PrivateRoute path="/editor/:id">
      <Editor />
    </PrivateRoute>
    <PrivateRoute path="/storage">
      <Storage />
    </PrivateRoute>
    <PrivateRoute path="/settings">
      <Settings />
    </PrivateRoute>
    <PrivateRoute path="/groups">
      <Groups />
    </PrivateRoute>
    <PrivateRoute path="/shared">
      <Shared />
    </PrivateRoute>
    <PrivateRoute path="/search">
      <SearchContainer />
    </PrivateRoute>
    <Route path="/auth">
      <Auth />
    </Route>
    <Route path="/public">
      <Public />
    </Route>
    <PrivateRoute path="/extensions">
      <Extensions />
    </PrivateRoute>
    <PrivateRoute path="/file">
      <File />
    </PrivateRoute>
    <PrivateRoute path="/pdf/:id">
      <ProductGate product="product.pdf_reviewer">
        <PDF />
      </ProductGate>
    </PrivateRoute>
    <PrivateRoute path="/presentation/:id">
      <ProductGate product="product.presentation_reviewer">
        <PresentationProvider>
          <Presentation />
        </PresentationProvider>
      </ProductGate>
    </PrivateRoute>
    <PrivateRoute path="/404">
      <Error404 />
    </PrivateRoute>
    <PrivateRoute path="/403">
      <Error403 />
    </PrivateRoute>
    <PrivateRoute path="*">
      <Redirect to="/404" />
    </PrivateRoute>
  </Switch>
);

export default Routes;
