import converter from 'number-to-words';

class NumberingConverter {
  static MINVALUE = 0;
  static MAXVALUE = 32767;

  static isValidNumber(index) {
    return (
      Number.isInteger(Number(index)) &&
      index >= NumberingConverter.MINVALUE &&
      index <= NumberingConverter.MAXVALUE
    );
  }

  static convert(index) {
    if (!NumberingConverter.isValidNumber(index)) {
      return NaN;
    }

    return index;
  }

  static convertFrom(rep) {
    if (!NumberingConverter.isValidNumber(rep)) {
      return NaN;
    }

    return rep;
  }
}

class NumberInDashConverter {
  static MINVALUE = 0;
  static MAXVALUE = 32767;

  static isValidNumber(index) {
    return (
      Number.isInteger(Number(index)) &&
      index >= NumberingConverter.MINVALUE &&
      index <= NumberingConverter.MAXVALUE
    );
  }

  static convert(index) {
    if (!NumberingConverter.isValidNumber(index)) {
      return NaN;
    }

    return `-${index}-`;
  }

  static convertFrom(rep) {
    if (!NumberingConverter.isValidNumber(rep)) {
      return NaN;
    }

    return rep;
  }
}

class LeadingZeroConverter extends NumberingConverter {
  static convert(val, quant) {
    if (!NumberingConverter.isValidNumber(val)) {
      return NaN;
    }

    const strValue = val.toString();
    const lex = strValue.split('');
    if (lex.length > quant) {
      return val;
    }

    const initialValues = ['0', '00', '000', '0000'];
    const ind = quant - lex.length;
    const newVal = `${initialValues[ind]}${Number(val) || ''}`;

    return newVal;
  }
}

class DecimalWordConverter extends NumberingConverter {
  static convert(n) {
    if (!NumberingConverter.isValidNumber(n)) {
      return NaN;
    }

    return `${converter.toWords(n).charAt(0).toUpperCase()}${converter.toWords(n).slice(1)}`;
  }
}

class RomanConverter extends NumberingConverter {
  static ROMANS = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I'];
  static INTEGERS = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  static MINVALUE = 0;
  static MAXVALUE = 32767;

  static hasValidRoman(userInput, isLower) {
    if (typeof userInput !== 'string') {
      return false;
    }
    // Accept values up to (31999)
    let isValidVal = false;
    if (isLower) {
      isValidVal = new RegExp(/^m{0,31}(cm|cd|d?c{0,3})(xc|xl|l?x{0,3})(ix|iv|v?i{0,3})$/).test(
        userInput,
      );
    } else {
      isValidVal = new RegExp(/^M{0,31}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/).test(
        userInput,
      );
    }

    return isValidVal;
  }

  static convert({ val, isLower }) {
    if (!val) return NaN;

    if (Number(val)) {
      if (val < RomanConverter.MINVALUE || val > RomanConverter.MAXVALUE) return NaN;

      let result = '';
      for (let index = 0; index < RomanConverter.INTEGERS.length; index++) {
        while (val % RomanConverter.INTEGERS[index] < val) {
          result += RomanConverter.ROMANS[index];
          val -= RomanConverter.INTEGERS[index];
        }
      }
      return isLower ? result.toLowerCase() : result;
    }

    if (RomanConverter.hasValidRoman(val, isLower)) {
      const value = RomanConverter.convertFrom(val);
      if (value < RomanConverter.MINVALUE || value > RomanConverter.MAXVALUE) {
        return NaN;
      }
      return val;
    }

    return NaN;
  }

  static convertFrom(roman) {
    let result = 0;
    roman = String(roman).toUpperCase();
    for (let index = 0; index < RomanConverter.INTEGERS.length; index++) {
      while (roman.indexOf(RomanConverter.ROMANS[index]) === 0) {
        result += RomanConverter.INTEGERS[index];
        roman = roman.replace(RomanConverter.ROMANS[index], '');
      }
    }
    return result;
  }
}

class AlphaConverter extends NumberingConverter {
  static ALPHABET = ' ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  static MINVALUE = 0;
  static MAXVALUE = 780;

  static hasValidLetter(value, isLower) {
    if (typeof value !== 'string') {
      return false;
    }
    const lex = value.split('');
    let isValidAlpha = false;

    if (isLower) {
      isValidAlpha = lex.every((i) => AlphaConverter.ALPHABET.toLowerCase().indexOf(i) !== -1);
    } else {
      isValidAlpha = lex.every((i) => AlphaConverter.ALPHABET.indexOf(i) !== -1);
    }

    return isValidAlpha;
  }

  static convert({ index, isLower }) {
    if (Number(index)) {
      if (index < AlphaConverter.MINVALUE || index > AlphaConverter.MAXVALUE) return NaN;

      let result = '';
      const mod = index % AlphaConverter.ALPHABET.length;
      const length = parseInt(index / AlphaConverter.ALPHABET.length, 10);
      for (let i = 0; i < length; i++) {
        result += AlphaConverter.ALPHABET[AlphaConverter.ALPHABET.length - 1];
      }

      const letter = `${result}${AlphaConverter.ALPHABET[mod]}`;
      return isLower ? letter.toLowerCase() : letter;
    }
    if (AlphaConverter.hasValidLetter(index, isLower)) {
      const value = AlphaConverter.convertFrom(index);
      if (value < 0 || value > 780) {
        return NaN;
      }
      return index;
    }
    return NaN;
  }

  static convertFrom(rep) {
    if (!rep) {
      return false;
    }
    const lex = rep.split('');
    let result = 0;
    let hasInvalidValue = false;
    for (let i = 0; i < lex.length; i++) {
      if (AlphaConverter.ALPHABET.indexOf(lex[i].toUpperCase()) === -1) {
        hasInvalidValue = true;
        break;
      }
      result += AlphaConverter.ALPHABET.indexOf(lex[i].toUpperCase());
    }

    return hasInvalidValue ? NaN : result;
  }
}

class OrdinalNumberConverter extends NumberingConverter {
  static convert(index) {
    if (!NumberingConverter.isValidNumber(index)) {
      return NaN;
    }
    return converter.toOrdinal(index);
  }
}

class OrdinalWordConverter extends NumberingConverter {
  static convert(index) {
    if (!NumberingConverter.isValidNumber(index)) {
      return NaN;
    }

    return `${converter.toWordsOrdinal(index).charAt(0).toUpperCase()}${converter
      .toWordsOrdinal(index)
      .slice(1)}`;
  }
}

export default class NumberingUtils {
  static TYPE = {
    BULLET: 'b',
    DECIMAL: 'd',
    ALPHA_DECIMAL: 'ad',
    LOWER_ROMAN: 'lr',
    UPPER_ROMAN: 'ur',
    LOWER_ALPHA: 'la',
    UPPER_ALPHA: 'ua',
    ORDINAL_NUMBER: 'on',
    ORDINAL_WORD: 'ow',
    ONE_LEADING_ZERO: '1lz',
    TWO_LEADING_ZERO: '2lz',
    THREE_LEADING_ZERO: '3lz',
    FOUR_LEADING_ZERO: '4lz',
    SPECIAL_CHAR: 'specialChars',
    NUMBER_IN_DASH: 'nid',
    NONE: 'n',
  };

  static parseOfficeType(officeType) {
    // TODO: complete formats
    switch (officeType) {
      case 'bullet':
        return NumberingUtils.TYPE.BULLET;
      case 'roman-upper':
        return NumberingUtils.TYPE.UPPER_ROMAN;
      case 'roman-lower':
        return NumberingUtils.TYPE.LOWER_ROMAN;
      case 'alpha-lower':
        return NumberingUtils.TYPE.LOWER_ALPHA;
      case 'alpha-upper':
        return NumberingUtils.TYPE.UPPER_ALPHA;
      case 'ordinal':
        return NumberingUtils.TYPE.ORDINAL_NUMBER;
      case 'ordinal-text':
        return NumberingUtils.TYPE.ORDINAL_WORD;
      case 'arabic-leading-zero':
        return NumberingUtils.TYPE.ONE_LEADING_ZERO;
      case 'arabic-leading-zero2':
        return NumberingUtils.TYPE.TWO_LEADING_ZERO;
      case 'arabic-leading-zero3':
        return NumberingUtils.TYPE.THREE_LEADING_ZERO;
      case 'arabic-leading-zero4':
        return NumberingUtils.TYPE.FOUR_LEADING_ZERO;
      //NumberingUtils.TYPE.DECIMAL
      //NumberingUtils.TYPE.ALPHA_DECIMAL
      //NumberingUtils.TYPE.SPECIAL_CHAR
      case 'none':
        return NumberingUtils.TYPE.NONE;
      default:
        return NumberingUtils.TYPE.DECIMAL;
    }
  }

  static represent(type, index) {
    switch (type) {
      case NumberingUtils.TYPE.DECIMAL:
        return NumberingConverter.convert(index);
      case NumberingUtils.TYPE.ALPHA_DECIMAL:
        return DecimalWordConverter.convert(index);
      case NumberingUtils.TYPE.LOWER_ROMAN:
        return RomanConverter.convert({ val: index, isLower: true });
      case NumberingUtils.TYPE.UPPER_ROMAN:
        return RomanConverter.convert({ val: index, isLower: false });
      case NumberingUtils.TYPE.LOWER_ALPHA:
        return AlphaConverter.convert({ index, isLower: true });
      case NumberingUtils.TYPE.UPPER_ALPHA:
        return AlphaConverter.convert({ index, isLower: false });
      case NumberingUtils.TYPE.ORDINAL_NUMBER:
        return OrdinalNumberConverter.convert(index);
      case NumberingUtils.TYPE.ORDINAL_WORD:
        return OrdinalWordConverter.convert(index);
      case NumberingUtils.TYPE.ONE_LEADING_ZERO:
        return LeadingZeroConverter.convert(index, 1);
      case NumberingUtils.TYPE.TWO_LEADING_ZERO:
        return LeadingZeroConverter.convert(index, 2);
      case NumberingUtils.TYPE.THREE_LEADING_ZERO:
        return LeadingZeroConverter.convert(index, 3);
      case NumberingUtils.TYPE.FOUR_LEADING_ZERO:
        return LeadingZeroConverter.convert(index, 4);
      case NumberingUtils.TYPE.SPECIAL_CHAR:
        return NumberingConverter.convert(index);
      case NumberingUtils.TYPE.NUMBER_IN_DASH:
        return NumberInDashConverter.convert(index);
      default:
        return NumberingConverter.convert(index);
    }
  }

  static fromRepresentation(type, rep) {
    switch (type) {
      case NumberingUtils.TYPE.DECIMAL:
        return NumberingConverter.convertFrom(rep);
      case NumberingUtils.TYPE.ALPHA_DECIMAL:
        return DecimalWordConverter.convertFrom(rep);
      case NumberingUtils.TYPE.LOWER_ROMAN:
        return RomanConverter.convertFrom(rep);
      case NumberingUtils.TYPE.UPPER_ROMAN:
        return RomanConverter.convertFrom(rep);
      case NumberingUtils.TYPE.LOWER_ALPHA:
        return AlphaConverter.convertFrom(rep);
      case NumberingUtils.TYPE.UPPER_ALPHA:
        return AlphaConverter.convertFrom(rep);
      case NumberingUtils.TYPE.ORDINAL_NUMBER:
        return OrdinalNumberConverter.convertFrom(rep);
      case NumberingUtils.TYPE.ORDINAL_WORD:
        return OrdinalWordConverter.convertFrom(rep);
      case NumberingUtils.TYPE.ONE_LEADING_ZERO:
        return LeadingZeroConverter.convertFrom(rep, 1);
      case NumberingUtils.TYPE.TWO_LEADING_ZERO:
        return LeadingZeroConverter.convertFrom(rep, 2);
      case NumberingUtils.TYPE.THREE_LEADING_ZERO:
        return LeadingZeroConverter.convertFrom(rep, 3);
      case NumberingUtils.TYPE.FOUR_LEADING_ZERO:
        return LeadingZeroConverter.convertFrom(rep, 4);
      case NumberingUtils.TYPE.SPECIAL_CHAR:
        return NumberingConverter.convertFrom(rep);
      case NumberingUtils.TYPE.NUMBER_IN_DASH:
        return NumberInDashConverter.convertFrom(rep);
      default:
        return NumberingConverter.convertFrom(rep);
    }
  }
}
