import { JsonRange } from '../../JsonRange';

export abstract class BasePositionIterator
  implements DoDOCSelection.IIterator<Editor.Selection.Path | null>
{
  protected startPath: Editor.Selection.Path;
  protected workingPath: Editor.Selection.Path;

  protected iteratorData: Editor.Data.Node.NavigationData[];

  protected workingOffsets: {
    iterator: number;
    info: number;
    content: number;
  };

  constructor(iteratorData: Editor.Data.Node.NavigationData[], startPath: Editor.Selection.Path) {
    this.startPath = startPath;
    this.workingPath = startPath;

    this.iteratorData = iteratorData;
    this.workingOffsets = this.findWorkingOffsets(this.startPath);
  }

  private findWorkingOffsets(basePath: Editor.Selection.Path) {
    const workingOffsets = {
      iterator: -1,
      info: -1,
      content: -1,
    };

    if (this.iteratorData.length === 1 && this.iteratorData[0].content === null) {
      workingOffsets.iterator = 0;
      workingOffsets.info = 0;

      if (basePath[basePath.length - 2] === 'childNodes') {
        workingOffsets.content =
          this.iteratorData[0].info[0].contentOffsets.start + +basePath[basePath.length - 1];
      } else {
        workingOffsets.content = this.iteratorData[0].info[0].contentOffsets.start;
      }
    } else {
      for (let i = 0; i < this.iteratorData.length; i++) {
        const iteratorData = this.iteratorData[i];
        for (let j = 0; j < iteratorData.info.length; j++) {
          const info = iteratorData.info[j];

          if (JsonRange.isChildPath(info.path, basePath)) {
            workingOffsets.iterator = i;
            workingOffsets.info = j;

            if (basePath[basePath.length - 2] === 'content') {
              workingOffsets.content = info.contentOffsets.start + +basePath[basePath.length - 1];
            } else {
              workingOffsets.content = info.contentOffsets.start;
            }

            break;
          } else if (JsonRange.isChildPath(basePath, info.path)) {
            workingOffsets.iterator = i;
            workingOffsets.info = j;
            workingOffsets.content = info.contentOffsets.start;
            break;
          } else if (
            JsonRange.comparePath(info.path, basePath) < 0 &&
            j === iteratorData.info.length - 1
          ) {
            // condition needed when selection is at the end of the block
            workingOffsets.iterator = i;
            workingOffsets.info = j;
            workingOffsets.content = info.contentOffsets.end;
          }
        }
      }
    }

    return workingOffsets;
  }

  reset(): void {
    this.workingOffsets = this.findWorkingOffsets(this.startPath);
  }

  protected abstract getOffset(forward: boolean): number;

  protected getNextPath(
    iteratorOffset: number,
    offsetValidator: number,
    offset: number,
  ): Editor.Selection.Path | null {
    const iteratorData: Editor.Data.Node.NavigationData = this.iteratorData[iteratorOffset];

    if (iteratorData?.content === null && iteratorData.info.length > offsetValidator) {
      const info = iteratorData.info[0];
      if (info) {
        offset = info.contentOffsets.end;
        this.workingOffsets.info = 0;
        this.workingOffsets.content = offset;

        const pathOffset = offset - info.contentOffsets.start;
        if (info.path.length > 0) {
          const path = [...info.path];
          path[path.length - 1] = +path[path.length - 1] + pathOffset;
          return path;
        } else {
          return ['childNodes', pathOffset];
        }
      }
    } else if (
      iteratorData?.content?.length === 0 &&
      iteratorOffset !== this.workingOffsets.iterator &&
      iteratorData.info[0] &&
      iteratorData.info[0].contentOffsets.end >= offsetValidator
    ) {
      const info = iteratorData.info[0];
      if (info) {
        this.workingOffsets.info = 0;
        this.workingOffsets.content = 0;

        const path: Editor.Selection.Path = [...info.path, 'childNodes', info.contentOffsets.start];
        return path;
      }
    } else if (iteratorData?.content && iteratorData.content.length > offsetValidator) {
      for (let j = this.workingOffsets.info; j < iteratorData.info.length; j++) {
        const info = iteratorData.info[j];

        if (info && info.contentOffsets.start <= offset && offset <= info.contentOffsets.end) {
          this.workingOffsets.info = j;
          this.workingOffsets.content = offset;

          const pathOffset = offset - info.contentOffsets.start;
          const path: Editor.Selection.Path = [...info.path, 'content', pathOffset];
          return path;
        }
      }
    }

    return null;
  }

  hasNext(): boolean {
    let iteratorData = this.iteratorData[this.workingOffsets.iterator];

    if (iteratorData?.content && iteratorData.content.length > this.workingOffsets.content) {
      return true;
    } else if (iteratorData?.content === null && iteratorData.info.length > 0) {
      return true;
    } else {
      for (let i = this.workingOffsets.iterator; i < this.iteratorData.length; i++) {
        const iteratorData = this.iteratorData[i];

        if (iteratorData?.content && iteratorData.content.length > 0) {
          return true;
        } else if (iteratorData?.content === null && iteratorData.info.length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  next(): Editor.Selection.Path | null {
    const path = this.getNextPath(
      this.workingOffsets.iterator,
      this.workingOffsets.content,
      this.getOffset(true),
    );
    if (path != null) {
      this.workingPath = path;
      return this.workingPath;
    } else {
      if (this.workingOffsets.iterator + 1 < this.iteratorData.length) {
        // next iterator data if exists
        for (let i = this.workingOffsets.iterator + 1; i < this.iteratorData.length; i++) {
          const path = this.getNextPath(i, 0, 0);
          if (path != null) {
            this.workingOffsets.iterator = i;
            this.workingPath = path;
            return this.workingPath;
          }
        }
      } else {
        const info =
          this.iteratorData[this.workingOffsets.iterator]?.info[this.workingOffsets.info];
        if (info) {
          let path;
          if (info.path.length > 0) {
            path = [...info.path];
            path[path.length - 1] = +path[path.length - 1] + 1;
            this.workingPath = path;
          } else {
            this.workingPath = ['childNodes', info.contentOffsets.end];
          }

          return this.workingPath;
        }
      }
    }

    // return original path
    return this.workingPath;
  }

  hasPrevious(): boolean {
    let iteratorData = this.iteratorData[this.workingOffsets.iterator];

    if (
      iteratorData?.content &&
      iteratorData.content.length > 0 &&
      this.workingOffsets.content > 0
    ) {
      return true;
    } else if (
      iteratorData?.content === null &&
      iteratorData.info.length > 0 &&
      this.workingOffsets.content > 0
    ) {
      return true;
    } else {
      for (let i = this.workingOffsets.iterator - 1; i >= 0; i--) {
        const iteratorData = this.iteratorData[i];

        if (iteratorData?.content && iteratorData.content.length > 0) {
          return true;
        } else if (iteratorData?.content === null && iteratorData.info.length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  previous(): Editor.Selection.Path | null {
    let previousIteratorData = this.iteratorData[this.workingOffsets.iterator - 1];
    let iteratorData = this.iteratorData[this.workingOffsets.iterator];

    let offset = this.getOffset(false);

    // if (iteratorData?.content === null && this.workingOffsets.content > 0) {
    //   const info = iteratorData.info[this.workingOffsets.info];
    //   if (info && info.contentOffsets.start <= offset && info.contentOffsets.end >= offset) {
    //     this.workingOffsets.content = offset;

    //     const pathOffset = offset - info.contentOffsets.start;
    //     if (info.path.length > 0) {
    //       const path = [...info.path];
    //       path[path.length - 1] = +path[path.length - 1] + pathOffset;
    //       this.workingPath = path;
    //       return this.workingPath;
    //     } else {
    //       this.workingPath = ['childNodes', pathOffset];
    //       return this.workingPath;
    //     }
    //   }
    // } else
    if (iteratorData?.content && this.workingOffsets.content >= 1) {
      for (let i = this.workingOffsets.info; i >= 0; i--) {
        const info = iteratorData.info[i];

        let buildPath = false;

        if (
          info &&
          offset <= info.contentOffsets.end &&
          ((i === 0 && info.contentOffsets.start <= offset) || info.contentOffsets.start < offset)
        ) {
          buildPath = true;
        }

        if (buildPath) {
          this.workingOffsets.info = i;
          this.workingOffsets.content = offset;

          const pathOffset = offset - info.contentOffsets.start;
          const path: Editor.Selection.Path = [...info.path, 'content', pathOffset];
          this.workingPath = path;
          return this.workingPath;
        }
      }
    }
    if (
      (!previousIteratorData || previousIteratorData.content === null) &&
      this.workingOffsets.content === 1
    ) {
      offset = 0;
      const info = iteratorData.info[this.workingOffsets.info];

      if (info) {
        this.workingOffsets.content = offset;

        let path: Editor.Selection.Path;
        const pathOffset = offset - info.contentOffsets.start;
        if (info.path.length) {
          path = [...info.path, 'content', pathOffset];
        } else {
          path = ['childNodes', pathOffset];
        }

        this.workingPath = path;
        return this.workingPath;
      }
    } else {
      if (this.workingOffsets.iterator - 1 >= 0) {
        // next iterator data if exists
        for (let i = this.workingOffsets.iterator - 1; i >= 0; i--) {
          const iteratorData = this.iteratorData[i];
          if (iteratorData?.content === null && iteratorData.info.length > 0) {
            if (i > 0) {
              continue; // jump over to previous !?
            } else {
              const info = iteratorData.info[0];
              offset = info.contentOffsets.start;
              if (info) {
                this.workingOffsets.info = 0;
                this.workingOffsets.iterator = i;
                this.workingOffsets.content = offset;
                // const pathOffset = offset - info.contentOffsets.start;
                // const path = [...info.path];
                // path[path.length - 1] = +path[path.length - 1] + pathOffset;
                this.workingPath = [...info.path];
                return this.workingPath;
              }
            }
          } else if (iteratorData?.content?.length === 0 && iteratorData.info[0]) {
            const info = iteratorData.info[0];
            if (info) {
              this.workingOffsets.iterator = i;
              this.workingOffsets.info = 0;
              this.workingOffsets.content = 0;

              const path: Editor.Selection.Path = [
                ...info.path,
                'childNodes',
                info.contentOffsets.end,
              ];
              this.workingPath = path;
              return this.workingPath;
            }
          } else if (iteratorData?.content && iteratorData.content.length > 0) {
            offset = iteratorData.content.length;

            for (let j = iteratorData.info.length - 1; j >= 0; j--) {
              const info = iteratorData.info[j];

              let buildPath = false;

              if (
                info &&
                offset <= info.contentOffsets.end &&
                ((j === 0 && info.contentOffsets.start <= offset) ||
                  info.contentOffsets.start < offset)
              ) {
                buildPath = true;
              }

              if (buildPath) {
                this.workingOffsets.info = j;
                this.workingOffsets.iterator = i;
                this.workingOffsets.content = offset;

                const pathOffset = offset - info.contentOffsets.start;
                const path: Editor.Selection.Path = [...info.path, 'content', pathOffset];
                this.workingPath = path;
                return this.workingPath;
              }
            }
          }
        }
      } else {
        const info =
          this.iteratorData[this.workingOffsets.iterator]?.info[this.workingOffsets.info];
        if (info) {
          let path;
          if (info.path.length > 0) {
            path = [...info.path];
            offset = +path[path.length - 1] - 1;
            if (offset < 0) {
              offset = 0;
            }

            path[path.length - 1] = offset;
            this.workingPath = path;
          } else {
            this.workingPath = ['childNodes', info.contentOffsets.start];
          }
          return this.workingPath;
        }
      }
    }

    return this.workingPath;
  }
}
