import { Mixin } from 'mixwith';

import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class SplitContentHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       * @description split content on selection
       * @param {ActionContext} actionContext
       * @param {Array} extraBlocksToSplit
       * @param {Boolean} insertAfterNode
       */
      splitSelectionContent(
        actionContext,
        extraBlocksToSplit = [],
        onlyBaseLevel = false,
        insertAfterValidator = () => true,
      ) {
        // TODO: carefull with node permissions on split nodes

        let splitAllowed = false;

        const blocksToSplit = [
          ...EditorDOMElements.DEFAULT_SPLITABLE_LEVEL0_ELEMENTS,
          ...extraBlocksToSplit,
        ];

        const selection = EditorSelectionUtils.getSelection();

        let anchorNode = selection.anchorNode;
        const anchorOffset = selection.anchorOffset;
        let focusNode = selection.focusNode;
        const focusOffset = selection.focusOffset;

        const beforeClosest = EditorDOMUtils.closest(anchorNode, blocksToSplit);
        const afterClosest = EditorDOMUtils.closest(focusNode, blocksToSplit);

        // fix anchor node
        if (anchorNode === beforeClosest) {
          if (anchorOffset < anchorNode.childNodes.length) {
            anchorNode = anchorNode.childNodes[anchorOffset];
          }
        }

        // fix focus node
        if (focusNode === afterClosest) {
          if (focusOffset < focusNode.childNodes.length) {
            focusNode = focusNode.childNodes[focusOffset];
          }
        }

        const closestAnchorSuggestion = EditorDOMUtils.closest(anchorNode, [
          ELEMENTS.TrackInsertElement.TAG,
          ELEMENTS.TrackDeleteElement.TAG,
        ]);

        const closestFocusSuggestion = EditorDOMUtils.closest(focusNode, [
          ELEMENTS.TrackInsertElement.TAG,
          ELEMENTS.TrackDeleteElement.TAG,
        ]);

        // TODO: should be just one remove
        if (beforeClosest !== afterClosest) {
          // selection is across several nodes, join first to split after

          if (this.joinSelectionContent(actionContext)) {
            splitAllowed = true;
          }
        } else if (this.removeSelectionContent(actionContext)) {
          splitAllowed = true;
        }

        // splits selection when in same level0 node
        if (splitAllowed) {
          const { before, after } = this.splitCollapsedSelection(
            actionContext,
            extraBlocksToSplit,
            onlyBaseLevel,
          );

          if (before) {
            DOMNormalizer.normalizeTree(before, before.parentNode.id);

            if (EditorDOMUtils.isEmptyElement(before)) {
              // inserts <br /> if the elements are empty after split
              if (
                (!before.previousSibling ||
                  before.previousSibling.getAttribute('task') !== before.getAttribute('task')) &&
                after &&
                !EditorDOMUtils.isEmptyElement(after)
              ) {
                before.removeAttribute('task');
              }

              if (EditorDOMUtils.isEmptyElement(before) && !before.querySelector('br')) {
                let deepestChild = before;
                while (
                  deepestChild.lastChild &&
                  deepestChild.firstChild === deepestChild.lastChild &&
                  EditorDOMElements.INLINE_EDITABLE_ELEMENTS.includes(
                    deepestChild.firstChild.tagName,
                  )
                ) {
                  deepestChild = deepestChild.lastChild;
                }

                const br = DOMElementFactory.buildElement('br');
                EditorDOMUtils.appendNode(deepestChild, br);
              }
            }
          }

          if (after) {
            let nonRepeatable = false;
            if (
              EditorDOMUtils.isEmptyElement(after) ||
              (after.firstChild === after.lastChild &&
                (this.isAddParagraphMarker(after.lastChild) ||
                  this.isDeleteParagraphMarker(after.firstChild)))
            ) {
              const baseStyleId = this.dataManager.styles.getBaseStyleId(after.styleId);

              // check for non repeatable paragraph types
              if (
                after.tagName === ELEMENTS.ParagraphElement.TAG &&
                ELEMENTS.ParagraphElement.NON_REPEATABLE_STYLES.includes(baseStyleId)
              ) {
                nonRepeatable = true;

                after.dataset.styleId = ELEMENTS.ParagraphElement.ELEMENT_TYPE;

                after.clearFormatting();

                const afterChildNodes = after.childNodes;
                let i;
                for (i = 0; i < afterChildNodes.length; i++) {
                  if (
                    !this.isAddParagraphMarker(afterChildNodes[i]) &&
                    !this.isDeleteParagraphMarker(afterChildNodes[i])
                  ) {
                    after.removeChild(afterChildNodes[i]);
                  }
                }
              }

              if (
                !before.nextSibling ||
                before.nextSibling.getAttribute('task') !== after.getAttribute('task')
              ) {
                after.removeAttribute('task');
              }

              after.checkEmptyContent();
            }

            // insert after node and set caret
            if (insertAfterValidator(before, after)) {
              // generate id
              DOMNormalizer.normalizeTree(after, before.parentNode.id);

              if (
                this.dataManager.numbering.isListElement(before.id) &&
                !this.dataManager.numbering.isBlockInOutlineList(before.id) &&
                !nonRepeatable
              ) {
                const listId = this.dataManager.numbering.getListIdFromBlock(before.id);
                const listLevel = this.dataManager.numbering.getListLevelFromBlock(before.id);
                this.dataManager.numbering.addBlocksToList(
                  actionContext,
                  [after.id],
                  listId,
                  listLevel,
                  before.id,
                );
              }

              const closestContainer = EditorDOMUtils.closest(
                before,
                EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS,
              );
              if (closestContainer) {
                EditorDOMUtils.insertNodeAfter(before.parentNode, after, before);
                actionContext.addChangeAddedNode(after);
                EditorSelectionUtils.setCaret(after, 'INSIDE_START');
              } else {
                const data = NodeDataBuilder.build({
                  ...this.documentParser.parse(after),
                  parent_id: before.parentNode.id,
                });
                this.inserBlockNodeOperation(actionContext, data, before, 'AFTER');

                EditorSelectionUtils.setCaret(EditorDOMUtils.getBlockNode(data.id), 'INSIDE_START');
              }

              // if split was inside a track delete or a track insert
              // add a tracked paragraph marker of the same suggestion type to the end of anchor closest
              if (
                closestAnchorSuggestion &&
                closestFocusSuggestion &&
                closestAnchorSuggestion.elementReference ===
                  closestFocusSuggestion.elementReference &&
                closestAnchorSuggestion.parentNode !==
                  EditorDOMUtils.getContentContainer(closestAnchorSuggestion)
              ) {
                if (closestAnchorSuggestion.tagName === ELEMENTS.TrackInsertElement.TAG) {
                  const markParagraph = this.getTrackInsElement(actionContext.id);
                  markParagraph.setAttribute('replacewith', after.id);
                  EditorDOMUtils.appendNode(before, markParagraph);
                  markParagraph.setAttribute(
                    'element_reference',
                    this.getProperSuggestionRef(actionContext, markParagraph),
                  );
                } else if (closestAnchorSuggestion.tagName === ELEMENTS.TrackDeleteElement.TAG) {
                  const markParagraph = this.getTrackDelElement(actionContext.id);
                  markParagraph.setAttribute('replacewith', after.id);
                  EditorDOMUtils.appendNode(before, markParagraph);
                  markParagraph.setAttribute(
                    'element_reference',
                    this.getProperSuggestionRef(actionContext, markParagraph),
                  );
                }
              }
            }
          }

          return { before, after };
        }
      }
    },
);
