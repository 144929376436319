import { Logger } from '_common/services';
import { ActionContext } from '../ActionContext';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { InsertElementOperation, RemoveContentOperation } from '../Operations';

export abstract class ContentManipulator implements Editor.Edition.IContentManipulator {
  protected editionContext: Editor.Context;

  protected debug: boolean = true;

  constructor(ctx: Editor.Context) {
    this.editionContext = ctx;
  }

  isUserAuthor(data: Editor.Data.Node.TrackedData): boolean {
    try {
      const author = data.properties.author;

      if (author === this.editionContext.DataManager?.users.loggedUserId) {
        return true;
      }
    } catch (error) {
      Logger.captureException(error);
    }
    return false;
  }

  wrapContent(
    model: Editor.Data.Node.Model,
    wrapElement: Editor.Data.Node.Data,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
  ): Editor.Selection.Path | undefined {
    const baseData = model.selectedData();

    if (!baseData) {
      return undefined;
    }

    const dataBuilder = new NodeDataBuilder();
    dataBuilder.setData(wrapElement);

    dataBuilder.childNodes = NodeUtils.cloneData(baseData, startPath, endPath);

    const removeOp = new RemoveContentOperation(model, startPath, endPath).apply();
    let resultPath = removeOp.getResultPath();
    if (!resultPath) {
      resultPath = startPath;
    }

    const dataToInsert = dataBuilder.buildData();

    if (dataToInsert) {
      const insertOp = new InsertElementOperation(model, resultPath, dataToInsert).apply();

      return insertOp.getResultPath();
    }

    return undefined;
  }

  unwrapContent(
    model: Editor.Data.Node.Model,
    pathToElementToUnwrap: Editor.Selection.Path,
  ): Editor.Selection.Path | undefined {
    // for delete hyperlink !?

    const baseData = model.selectedData();

    if (!baseData) {
      return undefined;
    }

    const elementToUnwrap = NodeUtils.getChildDataByPath(baseData, pathToElementToUnwrap);

    const childNodes: Editor.Data.Node.Data[] = JSON.parse(
      JSON.stringify(elementToUnwrap?.childNodes || []),
    );

    const childOffset = Number(pathToElementToUnwrap[pathToElementToUnwrap.length - 1]);

    let resultPath: Editor.Selection.Path | undefined = undefined;

    if (!isNaN(childOffset)) {
      let endPath: Editor.Selection.Path = [...pathToElementToUnwrap];
      endPath[endPath.length - 1] = childOffset + 1;

      const removeOp = new RemoveContentOperation(model, pathToElementToUnwrap, endPath, {
        mergeText: false,
      }).apply();

      let resultPath = removeOp.getResultPath();

      if (!resultPath) {
        resultPath = pathToElementToUnwrap;
      }

      if (childNodes.length) {
        for (let i = 0; i < childNodes.length; i++) {
          if (resultPath) {
            const insertOp = new InsertElementOperation(model, resultPath, childNodes[i]).apply();
            resultPath = insertOp.getResultPath();
          }
        }
      }
    }

    return resultPath;
  }

  splitInlineContent(
    model: Editor.Data.Node.Model,
    textDataPath: Editor.Selection.Path,
    textData: Editor.Data.Node.Data,
    pathToSplit: Editor.Selection.Path,
  ) {
    // TODO:
    // split inline styles only
    // for copy paste
    //
    //
    // if (!baseData) {
    //   return this;
    // }
    // const endPath: Editor.Selection.Path = ['childNodes', baseData.childNodes?.length || 0];
    // const childNodes = NodeUtils.cloneData(baseData, this.pathToSplit, endPath);
    // const removeOp = new RemoveContentOperation(this.model, this.pathToSplit, endPath);
    // this.ops.push(...removeOp.getOps());
    // const resultPath = removeOp.getResultPath();
    // if (resultPath) {
    //   this.resultPath = resultPath;
    // } else {
    //   this.resultPath = this.pathToSplit;
    // }
  }

  abstract insertText(
    ctx: Editor.Edition.ActionContext,
    path: Editor.Selection.Path,
    text: string,
  ): boolean;
  abstract insertElement(
    ctx: Editor.Edition.ActionContext,
    path: Editor.Selection.Path,
    newData: Editor.Data.Node.Data,
    options?: Editor.Edition.InsertElementOptions,
  ): boolean;
  abstract removeContent(ctx: Editor.Edition.ActionContext): boolean;

  abstract insertBlock(ctx: ActionContext, blockData: Editor.Data.Node.Data): boolean;
  abstract removeBlock(ctx: ActionContext, blockId: string): boolean;
}
