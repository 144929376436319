import { forwardRef, useState, Ref, useRef, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { stringToRichText, richTextToString } from 'utils';
import { usePDFContext } from 'PDF/PDFContext';
import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';

import {
  DEFAULT_CARD_STYLES_PANEL,
  selectAnnotation,
  setEditingAnnotation,
} from 'PDF/redux/PDFAnnotationsSlice';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';

import { InteractionController, RichTextEditor } from '_common/components';

import { UserPresentation } from '_common/suite/components/Card';
import { Card } from '_common/suite/components';
import usePDFCollaborators from 'PDF/hooks/usePDFCollaborators';

export type EditableAnnotationCardProps = {
  annotation: PDF.Annotation;
  sidebar: boolean;
  testId: string;
};

const EditableAnnotationCard = forwardRef(
  ({ annotation, sidebar, testId }: EditableAnnotationCardProps, ref: Ref<HTMLDivElement>) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const pdfManager = usePDFContext();
    const collaborators = usePDFCollaborators();

    const isSelected = useSelector((state) => state.pdf.annotations.selected === annotation.id);

    const [newContent, setNewContent] = useState<string>(
      richTextToString(annotation.content?.content),
    );

    const initialValue = useMemo(() => {
      if (newContent) {
        return newContent;
      }
      if (annotation.content && annotation.content.content.length > 0) {
        return JSON.stringify(annotation.content.content);
      }
    }, [annotation, newContent]);

    const ctaRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
      if (ctaRef?.current) {
        dispatch(
          setPulseData({
            annotationCardCtaRect: {
              top: ctaRef.current.offsetTop,
              left: ctaRef.current.offsetLeft,
              height: ctaRef.current.offsetHeight,
              width: ctaRef.current.offsetWidth,
            },
          }),
        );
      }

      return () => {
        dispatch(
          setPulseData({
            annotationCardCtaRect: undefined,
          }),
        );
      };
    }, []);

    const handleCancelEdition = () => {
      dispatch(setEditingAnnotation({ id: null, isTask: false }));
    };

    const handleEditContent = async () => {
      try {
        await pdfManager.editAnnotationContent(
          annotation.pageNumber,
          annotation.id,
          stringToRichText(newContent),
        );
        notify({
          type: 'success',
          title: intl.formatMessage({ id: 'COMMENT_EDITED' }),
          message: intl.formatMessage({ id: 'THE_COMMENT_WAS_SUCCESSFULLY_EDITED' }),
        });
        dispatch(selectAnnotation(annotation.id));
        if (annotation.subtype === 'Highlight') {
          dispatch(completeAction('pdf_annotations_commentHighlight'));
        } else if (annotation.subtype === 'Note') {
          dispatch(completeAction('pdf_comments_createComment'));
        }
      } catch (e) {
        throw e;
      }
    };

    return (
      <Card
        id={`annotation-card-${annotation.id}`}
        sidebar={sidebar}
        selected={isSelected}
        testId={testId}
        ref={ref}
      >
        <Card.Header>
          <Card.Header.Left>
            <UserPresentation
              userId={annotation.authorId}
              fixedName={!annotation.authorId ? annotation.title : undefined}
              //@ts-expect-error PDF.Annotation.Reply.creationDate is typed as Date but is string
              creationDate={annotation.creationDate}
            />
          </Card.Header.Left>
        </Card.Header>
        <Card.Body>
          <InteractionController
            environment="dopdf"
            rules={[{ interaction: 'pdf_annotation_save' }]}
            style={{ flexDirection: 'column' }}
          >
            <RichTextEditor
              initialValue={initialValue}
              onChange={setNewContent}
              placeholder={intl.formatMessage({ id: 'storage.modals.approve.placeholder' })}
              mentionableUsers={annotation.subtype === 'FreeText' ? undefined : collaborators}
              overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
              testId={`${testId}-rich-text-editor`}
            />
          </InteractionController>
        </Card.Body>
        <Card.Footer>
          <Card.Footer.Right>
            <InteractionController environment="dopdf">
              <Button
                variant="link"
                size="small"
                margin="0 1rem 0 0"
                onClick={handleCancelEdition}
                testId="annotation-cancel-button"
                id={`${testId}-cancel-button`}
              >
                <FormattedMessage id="global.cancel" />
              </Button>
            </InteractionController>
            <InteractionController
              environment="dopdf"
              rules={[{ interaction: 'pdf_annotation_save' }]}
            >
              <Button
                variant="primary"
                size="small"
                onClick={handleEditContent}
                testId="annotation-save-button"
                ref={ctaRef}
                id={`annotation-${annotation.id}-cta-button`}
              >
                <FormattedMessage id="global.save" />
              </Button>
            </InteractionController>
          </Card.Footer.Right>
        </Card.Footer>
      </Card>
    );
  },
);

export default EditableAnnotationCard;
