import { createContext, useContext } from 'react';

const GroupSettingsContext = createContext<{
  groupId?: ObjectId;
}>({
  groupId: undefined,
});

export const useGroupContext = () => {
  return useContext(GroupSettingsContext);
};

export default GroupSettingsContext;
