import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Upload } from 'dodoc-design-system';
import type { UploadProps } from 'dodoc-design-system/build/types/Components/Upload/Upload';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import {
  validateUsersCSV,
  addUsersCSV,
} from 'Settings/pages/TenantSettingsPage/Users/redux/userManagementSlice';

import Loading from 'Settings/pages/TenantSettingsPage/Users/Loading/Loading';
import ErrorReport from './ErrorReport/ErrorReport';
import Information from './Information/Information';
import type { ErrorReportProps } from './ErrorReport/ErrorReport';

import style from './UploadUsersModal.module.scss';

type UploadState =
  | 'initial'
  | 'loading'
  | 'error'
  | 'uploaded_all_valid'
  | 'uploaded_some_valid'
  | 'uploaded_none_valid';

export const TEST_IDS = {
  allValid: { msg: 'settings.users.upload.allValid.msg' },
  someValid: { msg: 'settings.users.upload.someValid.msg' },
  noneValid: { msg: 'settings.users.upload.noneValid.msg' },
};

const MODAL = 'UploadUsersModal';
const UPLOAD_STATE: Record<string, UploadState> = {
  INITIAL: 'initial',
  LOADING: 'loading',
  ERROR: 'error',
  UPLOADED_ALL_VALID: 'uploaded_all_valid',
  UPLOADED_SOME_VALID: 'uploaded_some_valid',
  UPLOADED_NONE_VALID: 'uploaded_none_valid',
};

type EntriesData = {
  validEntries: Pick<ErrorReportProps['entries'][number], 'row'>[];
  invalidEntries: ErrorReportProps['entries'];
};

const UploadUsersModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // redux
  const isOpen = useSelector((state) => state.modals.open[MODAL]);

  //local
  // State to control what should be rendered accordingly to the state of the upload action
  const [currentState, setCurrentState] = useState(UPLOAD_STATE.INITIAL);
  // Uploaded file data
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // Data related to the uploaded users' entries
  const [data, setData] = useState<EntriesData | null>(null);

  useEffect(() => {
    if (data && data?.validEntries?.length > 0) {
      if (data?.invalidEntries?.length < 1) {
        setCurrentState(UPLOAD_STATE.UPLOADED_ALL_VALID);
      } else {
        setCurrentState(UPLOAD_STATE.UPLOADED_SOME_VALID);
      }
    } else if (data && data?.invalidEntries?.length > 0) {
      setCurrentState(UPLOAD_STATE.UPLOADED_NONE_VALID);
    } else {
      setCurrentState(UPLOAD_STATE.INITIAL);
    }
  }, [data]);

  const close = () => {
    setCurrentState(UPLOAD_STATE.INITIAL);
    setSelectedFile(null);
    setData(null);
    dispatch(closeAndResetModal(MODAL));
  };

  const handleUpload: UploadProps['onDrop'] = async (_, file: File[]) => {
    setCurrentState(UPLOAD_STATE.LOADING);

    if (!file || !file[0] || (file[0].type && file[0].name.indexOf('csv')) === -1) {
      setCurrentState(UPLOAD_STATE.ERROR);
      return;
    }

    if (file.length < 1) {
      return;
    }

    // enpoint response
    try {
      const { response } = await dispatch(validateUsersCSV(file[0])).unwrap();
      const validEntries: EntriesData['validEntries'] = [];
      const invalidEntries: EntriesData['invalidEntries'] = [];

      if (response.data) {
        const entries = response.data;

        Object.keys(entries).forEach((row) => {
          //@ts-expect-error Missing endpoint type "/api/authority/user/validate/csv"
          const entry = entries[row];

          if (entry.valid) {
            validEntries.push({ row });
          } else {
            const errors = entry?.errors;

            if (errors) {
              invalidEntries.push({ row, errors });
            }
          }
        });

        setSelectedFile(file[0]);
        setData({ validEntries, invalidEntries });
      }
    } catch (e) {
      setCurrentState(UPLOAD_STATE.ERROR);
    }
  };

  // Handles the form submition
  const handleSubmit = async () => {
    if (selectedFile) {
      setCurrentState(UPLOAD_STATE.LOADING);
      const { users } = await dispatch(addUsersCSV(selectedFile)).unwrap();

      notify({
        type: 'success',
        title: 'NEW_USERS_ADDED_TO_THE_TENANT',
        message: 'NEW_USERS_WERE_SUCCESSFULLY_ADDED',
        messageValues: {
          total: `${users.length}`,
        },
      });

      setCurrentState(UPLOAD_STATE.INITIAL);
      close();
    }
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="75rem" testId="upload-users-modal">
      <div className={style.form}>
        <Modal.Header onClose={close}>
          <FormattedMessage id="UPLOAD_USERS" />
        </Modal.Header>
        <Modal.Body>
          <div className={style.body}>
            <div className={style.content}>
              {(currentState === UPLOAD_STATE.INITIAL || currentState === UPLOAD_STATE.ERROR) && (
                <div className={style.inputUpload}>
                  <Upload
                    testId="upload-input"
                    accept=".csv"
                    label={intl.formatMessage({ id: 'BROWSE_YOUR_COMPUTER' })}
                    onDrop={handleUpload}
                    text={intl.formatMessage({ id: 'DRAG_AND_DROP_CSV_USERS' })}
                    error={currentState === UPLOAD_STATE.ERROR}
                    errorLabel={intl.formatMessage({ id: 'UPLOAD_INVALID_FILE_FORMAT' })}
                  />
                  <Information />
                </div>
              )}
              {currentState === UPLOAD_STATE.LOADING && (
                <div className={style.loading}>
                  <Loading message={{ id: 'UPLOADING_FILE' }} icon="File" />
                </div>
              )}

              {data && currentState === UPLOAD_STATE.UPLOADED_ALL_VALID && (
                <div className={style.message} data-testid={TEST_IDS.allValid.msg}>
                  <FormattedMessage
                    id="FOUND_ALL_USERS_ENTRIES_VALID"
                    values={{ entries: data.validEntries.length }}
                  />
                </div>
              )}
              {data && currentState === UPLOAD_STATE.UPLOADED_SOME_VALID && (
                <div className={style.message}>
                  <div data-testid={TEST_IDS.someValid.msg}>
                    <FormattedMessage
                      id="FOUND_SOME_USERS_ENTRIES_VALID"
                      values={{
                        entries: data.validEntries.length + data.invalidEntries.length,
                        valid: data.validEntries.length,
                      }}
                    />
                    .
                  </div>
                  <ErrorReport entries={data.invalidEntries} />
                  <div className={style.messageFooter}>
                    <FormattedMessage id="ADD_VALID_USERS_OR_CANCEL" />
                  </div>
                </div>
              )}
              {data && currentState === UPLOAD_STATE.UPLOADED_NONE_VALID && (
                <div className={style.message}>
                  <div data-testid={TEST_IDS.noneValid.msg}>
                    <FormattedMessage
                      id="FOUND_NONE_USERS_ENTRIES_VALID"
                      values={{ entries: data.validEntries.length + data.invalidEntries.length }}
                    />
                    .
                  </div>
                  <ErrorReport entries={data.invalidEntries} />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer alignment="flex-end">
          <Button size="medium" onClick={close} testId="cancel-button">
            <FormattedMessage id="global.cancel" />
          </Button>
          {(currentState === UPLOAD_STATE.UPLOADED_ALL_VALID ||
            currentState === UPLOAD_STATE.UPLOADED_SOME_VALID) && (
            <Button size="medium" variant="primary" onClick={handleSubmit} testId="cta-button">
              {currentState === UPLOAD_STATE.UPLOADED_ALL_VALID && (
                <FormattedMessage id="ADD_USERS_TO_THE_TENANT" />
              )}
              {currentState === UPLOAD_STATE.UPLOADED_SOME_VALID && (
                <FormattedMessage id="ADD_VALID_USERS_TO_THE_TENANT" />
              )}
            </Button>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default UploadUsersModal;
