import { useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import ToolbarDropdown from 'Editor/components/ToolbarDropdown/ToolbarDropdown';

const SIZES = [
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '14', value: '14' },
  { label: '18', value: '18' },
  { label: '24', value: '24' },
  { label: '30', value: '30' },
  { label: '36', value: '36' },
  { label: '48', value: '48' },
  { label: '60', value: '60' },
  { label: '72', value: '72' },
  { label: '96', value: '96' },
];

type FontSizeProps = { isReadOnlyMode?: boolean };

const FontSize = ({ isReadOnlyMode }: FontSizeProps) => {
  const fontSize = useSelector((state) => state.editor.toolbar.fontSize);

  return (
    <ToolbarDropdown
      isDisabled={isReadOnlyMode}
      creatable
      options={SIZES}
      onChange={(option) => {
        setTimeout(() => {
          EditorManager.getInstance().toggleSelectionStyle(
            StylesUtils.STYLES.FONTSIZE,
            option.value,
          );
        }, 0);
      }}
      value={{ label: `${fontSize}`, value: `${fontSize}` }}
      width={'9'}
      createOptionPosition="first"
      formatCreateLabel={(label) => label}
      isValidNewOption={(option) => {
        if (
          option.match(/^\d+(\.\d{0,2})?$/g) &&
          !SIZES.map((size) => size.value).includes(option)
        ) {
          return true;
        }
        return false;
      }}
      customStyles={{
        menu: (provided) => ({
          ...provided,
          width: '15rem',
          zIndex: 3,
        }),
        menuList: (provided) => ({
          ...provided,
          width: '15rem',
          maxHeight: '59rem',
        }),
        option: (provided) => ({
          ...provided,
          height: '4rem',
        }),
      }}
      testId="font-size"
    />
  );
};

export default FontSize;
