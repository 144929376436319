import { FormattedMessage } from 'react-intl';
import { Dropdown, Toggle, Icon, usePopper } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { Parser } from 'Editor/services/EditionManager/Clipboard';
import EditorManager from 'Editor/services/EditorManager';

const PasteOptionsOverlay = () => {
  const offsets = useSelector((state) => state.editor.pasteOptions.offsets);
  const zoom = useSelector((state) => state.editor.status.zoom);
  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-end',
  });

  if (offsets === null) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: offsets.left * zoom + offsets.width + 20 * zoom,
        top: offsets.top * zoom + offsets.height + 5,
      }}
    >
      <>
        <Toggle
          size="medium"
          variant="standard"
          icon="PasteOptions"
          isToggled={isOpen}
          {...referenceProps}
          testId="paste-options-toggle"
        >
          <div style={{ marginLeft: '0.5rem' }}>
            <Icon icon="ArrowDown" size={16} />
          </div>
        </Toggle>
        <Dropdown {...popperProps} testId="paste-options-dropdown">
          <Dropdown.Item
            onClick={() => {
              EditorManager.getInstance().handlePasteOptions(Parser.MATCH_DESTINATION);
            }}
            testId="paste-options-match-style-dropdown-item"
          >
            <FormattedMessage id="MATCH_STYLE" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              EditorManager.getInstance().handlePasteOptions(Parser.ORIGINAL_STYLES);
            }}
            testId="paste-options-keep-original-style-dropdown-item"
          >
            <FormattedMessage id="KEEP_ORIGINAL_STYLE" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              EditorManager.getInstance().handlePasteOptions(Parser.PLAIN_TEXT);
            }}
            testId="paste-options-keep-text-only-dropdown-item"
          >
            <FormattedMessage id="KEEP_TEXT_ONLY" />
          </Dropdown.Item>
        </Dropdown>
      </>
    </div>
  );
};

export default PasteOptionsOverlay;
