import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import { EditorService, InstanceService } from '_common/services';

import { editMetadata } from 'App/redux/appSlice';
import { closeAndResetModal, openAndUpdateModal } from '_common/modals/ModalsSlice';
import { getDocumentInfo } from 'Editor/redux/EditorStatusSlice';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import PublishVeevaForm from './PublishVeevaForm';
import PublishVeevaLoading from './PublishVeevaLoading';
import ValidMetadataWarning from './ValidMetadataWarning';

const PublishVeevaModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.PublishVeevaModal);
  const document = useSelector((state) => state.app.data[state.modals.PublishVeevaModal.id]);
  const id = useSelector((state) => state.modals.PublishVeevaModal.id);

  const { data: metadata, isLoading } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });
  const timeoutRef = useRef<number>();

  const [comment, setComment] = useState('');
  const [changeType, setChangeType] = useState<{ value: string; label: string; id: string }>({
    value: '',
    label: '',
    id: '',
  });
  const [contentFileType, setContentFileType] = useState([]);
  const [channel, setChannel] = useState([]);
  const [synapseLibrary, setSynapseLibrary] = useState<{
    value: string;
    label: string;
    id: string;
  }>({ value: '', label: '', id: '' });
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(true);
  const [jobId, setJobId] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [isOpen]);

  useEffect(() => {
    if (jobId) {
      timeoutRef.current = window.setInterval(async () => {
        const { data } = await new InstanceService().checkJobStatus({ jobs: [jobId] });
        //@ts-expect-error API not fully typed yet
        switch (data[jobId].status) {
          case 'queued':
          case 'started':
            return;
          case 'finished':
            //@ts-expect-error API not fully typed yet
            if (+data[jobId].result[0] === 492) {
              notify({
                type: 'error',
                title: 'ERROR_EXPORTING_TIMEOUT_VEEVA',
                message: 'ERROR_EXPORTING_TIMEOUT_VEEVA_MESSAGE',
              });
              //@ts-expect-error API not fully typed yet
            } else if (+data[jobId].result[0] === 493) {
              notify({
                type: 'error',
                title: 'DOCUMENT_ALREADY_ARCHIVED_VEEVA',
                message: 'THIS_DOCUMENT_HAS_ALREADY_BEEN_ARCHIVED_VEEVA',
              });
              //@ts-expect-error API not fully typed yet
            } else if (+data[jobId].result[0] === 491) {
              notify({
                type: 'error',
                title: 'MISSING_FIRST_AND_LAST_NAME',
                message: 'MISSING_FIRST_AND_LAST_NAME_MESSAGE',
              });
              //@ts-expect-error API not fully typed yet
            } else if (+data[jobId].result[0] === 200) {
              if (
                //@ts-expect-error API not fully typed yet
                data[jobId].result[1].errors &&
                //@ts-expect-error API not fully typed yet
                Object.keys(data[jobId].result[1].errors).length > 0
              ) {
                dispatch(
                  openAndUpdateModal({
                    modal: 'MetadataInconsistenciesModal',
                    //@ts-expect-error API not fully typed yet
                    data: { errors: data[jobId].result[1].errors, id },
                  }),
                );
                notify({
                  type: 'warning',
                  title: 'METADATA_VALUE_NOT_COMPLIABLE',
                  message: 'ISSUES_EXPORTED_METADATA_VEEVA_MESSAGE',
                });
              } else {
                dispatch(getDocumentInfo({ objectId: document.id }));
                notify({
                  type: 'success',
                  title: 'DOCUMENT_EXPORTED_TO_VEEVA',
                  message: 'A new version of the document was successfully published to Veeva',
                });
              }
              //@ts-expect-error API not fully typed yet
            } else if (+data[jobId].result[0] >= 400) {
              //Unhandled error code
              notify({
                type: 'error',
                title: 'ERROR_EXPORTING_VEEVA',
                message: 'ERROR_EXPORTING_VEEVA_MESSAGE',
              });
            }
            close();
            return;
          case 'failed':
            setJobId(null);
            return;
          default:
            setJobId(null);
        }
      }, 2500);
    }
    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [jobId]);

  useEffect(() => {
    if (document && !isLoading) {
      const ids = Object.keys(metadata);
      const valid = ids.every((id) => {
        if (metadata[id].required && metadata[id].data_type !== 'dynamic') {
          const value = document.metadata[id];
          // Empty array or no value (null || '')
          if (!value || (Array.isArray(value) && value.length === 0)) {
            return false;
          }
        }
        return true;
      });
      setValid(valid);
    }
  }, [document, metadata]);

  const METADATA = useMemo(() => {
    if (!isLoading && isOpen) {
      const keys = Object.values(metadata);
      const versionComment = keys.find((m) => m.name === 'Version comment');
      const changeType = keys.find((m) => m.name === 'Change Type');
      const contentFileType = keys.find((m) => m.name === 'Content file type');
      const channel = keys.find((m) => m.name === 'Channel');
      const library = keys.find((m) => m.name === 'Library');

      return {
        comment: {
          id: !versionComment ? '' : versionComment.id,
        },
        change_type: {
          id: !changeType ? '' : changeType.id,
          options: !changeType
            ? []
            : changeType.values.map((v) => ({ label: v, value: v, id: '' })),
        },
        content_file_type: {
          id: !contentFileType ? '' : contentFileType.id,
          options: !contentFileType
            ? []
            : contentFileType.values.map((v) => ({ label: v, value: v, id: '' })),
        },
        channel: {
          id: !channel ? '' : channel.id,
          options: !channel ? [] : channel.values.map((v) => ({ label: v, value: v, id: '' })),
        },
        synapse_library: {
          id: !library ? '' : library.id,
          options: !library ? [] : library.values.map((v) => ({ label: v, value: v, id: '' })),
        },
      };
    }
    return null;
  }, [metadata, isOpen]);

  useEffect(() => {
    if (isOpen && METADATA && document) {
      if (document.metadata[METADATA.comment.id]) {
        setComment(document.metadata[METADATA.comment.id]);
      }
      if (document.metadata[METADATA.change_type.id]) {
        setChangeType({
          label: document.metadata[METADATA.change_type.id],
          value: document.metadata[METADATA.change_type.id],
          id: METADATA.change_type.id,
        });
      }
      if (document.metadata[METADATA.content_file_type.id]?.length > 0) {
        setContentFileType(
          document.metadata[METADATA.content_file_type.id].map((value: string) => ({
            label: value,
            value,
            id: METADATA.content_file_type.id,
          })),
        );
      }
      if (document.metadata[METADATA.channel.id]?.length > 0) {
        setChannel(
          document.metadata[METADATA.channel.id].map((value: string) => ({
            label: value,
            value,
            id: METADATA.channel.id,
          })),
        );
      }
      if (document.metadata[METADATA.synapse_library.id]) {
        setSynapseLibrary({
          label: document.metadata[METADATA.synapse_library.id],
          value: document.metadata[METADATA.synapse_library.id],
          id: METADATA.synapse_library.id,
        });
      }
    }
  }, [document, METADATA, isOpen]);

  const handleEditMetadata = (payload: {
    field: string | undefined;
    value: string;
    add?: boolean;
  }) => {
    dispatch(editMetadata({ objectId: document.id, parameters: payload }));
  };

  const submit = async () => {
    setLoading(true);
    try {
      const { data } = await new EditorService({ errorsExpected: [400] }).exportDocument(
        document.id,
        {
          connector: 'veeva',
          format: 'docx',
        },
      );
      //@ts-expect-error API not fully typed yet
      setJobId(data.id);
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          if (error?.response?.data?.status?.includes('not_allowed')) {
            setError(true);
          } else {
            close();
          }
        } else {
          close();
        }
      }
    }
  };

  const reset = () => {
    setLoading(false);
    setError(false);
  };

  const close = () => {
    dispatch(closeAndResetModal('PublishVeevaModal'));
    setComment('');
    setChangeType({ value: '', label: '', id: '' });
    setChannel([]);
    setContentFileType([]);
    setSynapseLibrary({ value: '', label: '', id: '' });
    setJobId(null);
  };

  return (
    <Modal
      width="60rem"
      open={!!isOpen}
      onClose={close}
      type={error ? 'error' : 'information'}
      testId="publish-veeva"
    >
      <Modal.Header onClose={close}>
        <FormattedMessage id="Publish document to Veeva" />
      </Modal.Header>

      <Modal.Body>
        {error ? (
          <FormattedMessage id="The document could not be exported because the current document status does not allow exporting to Veeva." />
        ) : null}
        {!error && loading ? <PublishVeevaLoading /> : null}
        {!error && !loading ? (
          <>
            <PublishVeevaForm
              comment={comment}
              setComment={setComment}
              changeType={changeType}
              contentFileType={contentFileType}
              channel={channel}
              synapseLibrary={synapseLibrary}
              METADATA={METADATA}
              editMetadata={handleEditMetadata}
            />
            {!valid && <ValidMetadataWarning />}
          </>
        ) : null}
      </Modal.Body>

      <Modal.Footer>
        <Button size="medium" onClick={close} testId="publish-veeva-modal-cancel-button">
          <FormattedMessage id={error ? 'global.close' : 'global.cancel'} />
        </Button>
        {!error && !loading && (
          <Button
            size="medium"
            variant="primary"
            onClick={submit}
            disabled={
              !valid ||
              comment === '' ||
              changeType === null ||
              contentFileType.length === 0 ||
              channel.length === 0 ||
              synapseLibrary === null
            }
            testId="publish-veeva-modal-submit-button"
          >
            <FormattedMessage id="Publish" />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PublishVeevaModal;
