import { ListData } from 'Editor/services/DataManager';
import List from 'Editor/services/DataManager/controllers/Numbering/List';
import { BaseViewModel } from '../BaseViewModel';

export class ListViewModel extends BaseViewModel {
  typeName = 'ListViewModel';

  protected model?: List;
  view?: HTMLStyleElement;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State, id: string) {
    super(Data, Visualizer, id);

    this.model = this.Data.numbering.lists.list(this.id);
    this.handleModelUpdate = this.handleModelUpdate.bind(this);
    this.handleModelLoad = this.handleModelLoad.bind(this);
    if (this.model) {
      this.model.on('LOADED', this.handleModelLoad);
      this.model.on('UPDATED', this.handleModelUpdate);
    }
    this.buildView();
  }

  private append(newView: HTMLStyleElement) {
    if (this.view && this.view.parentNode) {
      this.view.parentNode.replaceChild(newView, this.view);
    }
    this.view = newView;
  }

  private getCssSelector(nodeId: string) {
    let cssSelector = '';
    if (
      this.Data.styles.listStyles.style(this.model?.style)?.isMultiLevelList() ||
      this.Data.styles.documentStyles.getStylesForList(this.id).length
    ) {
      cssSelector = `paragraph-element[element_type="p"][id="${nodeId}"]`;
    } else {
      cssSelector = `paragraph-element#${nodeId}`;
    }
    return cssSelector;
  }

  getCssRule(nodeId: string, listLevel: number = 0) {
    try {
      if (this.model && this.Visualizer.numberingCSSApplier) {
        const nodeNumbering = this.model.getNodeNumbering(nodeId);
        return this.Visualizer.numberingCSSApplier.getCssRule(
          nodeNumbering,
          this.getCssSelector(nodeId),
        );
      }
    } catch (error) {
      logger.captureException(error);
    }
    return '';
  }

  private buildView() {
    let newView: HTMLStyleElement;
    newView = document.createElement('style');
    newView.id = `${this.id}`;
    newView.dataset.name = `list style `;
    if (this.model) {
      newView.dataset.style_id = this.model.style;
      const listNodes = this.model.nodes;

      for (let index = 0; index < listNodes.length; index++) {
        const nodeId = listNodes[index];
        newView.appendChild(document.createTextNode(this.getCssRule(nodeId)));
      }
    }
    this.append(newView);
  }

  private handleModelUpdate(data: Partial<ListData> | null) {
    this.buildView();
  }

  private handleModelLoad() {
    this.buildView();
  }

  render() {
    this.buildView();
  }

  dispose() {
    if (this.model) {
      // unbind model
      this.model.off('UPDATED', this.handleModelUpdate);
      this.model.off('LOADED', this.handleModelLoad);
    }
    if (this.view && this.view.parentNode) {
      this.view.parentNode.removeChild(this.view);
      delete this.view;
    }
  }
}
