import { ReactNode } from 'react';
import cx from 'classnames';

import { useSuiteApp } from '_common/suite/SuiteContext/SuiteContext';

import styles from './Footer.module.scss';

type FooterProps = {
  children: ReactNode;
};

const Footer = ({ children }: FooterProps) => {
  const suiteApp = useSuiteApp();

  return (
    <div className={cx(styles.footer, { [styles.editor]: suiteApp === 'document' })}>
      {children}
    </div>
  );
};

const Left = ({ children }: { children: ReactNode }) => {
  return <div className={styles.left}>{children}</div>;
};
const Right = ({ children }: { children: ReactNode }) => {
  return <div className={styles.right}>{children}</div>;
};

export default Object.assign(Footer, {
  Left,
  Right,
});
