import { useIntl } from 'react-intl';
import { SectionHeading } from 'dodoc-design-system';

type MetadataHeadingProps = {
  option: {
    label: string;
    metadataOptions: {
      label: string;
      value: string;
    }[];
    id: string;
  };
  testId: string;
};

const MetadataHeading = ({ option, testId }: MetadataHeadingProps) => {
  const intl = useIntl();

  return (
    <div style={{ marginTop: '4rem' }}>
      <SectionHeading
        title={intl.formatMessage({ id: option.label })}
        size="large"
        testId={testId}
      />
    </div>
  );
};

export default MetadataHeading;
