import { useState, useEffect, ChangeEventHandler } from 'react';
import { useDispatch, useSelector } from '_common/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, InputField, Input, Popover, usePopper } from 'dodoc-design-system';
import { navigateToMyFiles } from 'router/history';
import { PasswordCheck, SupportButton } from '_common/components';
import { AuthService } from '_common/services';
import { selectAccounts } from 'Auth/redux/localStorageSlice';
import { setAuthenticated, setFinishedFirstLogin } from 'Auth/redux/authSlice';
import AuthHero from 'Auth/components/AuthHero/AuthHero';
import styles from './WelcomePage.module.scss';
import { SessionStorage } from '_common/utils';

export const TEST_IDS = {
  INPUT: {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    NEW_PASSWORD: 'newPassword',
    CONFIRM_PASSWORD: 'confirmPassword',
  },
  BUTTON: 'button',
};

const WelcomePage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.userId);
  const accounts = useSelector(selectAccounts);

  const [loadingBtn, setLoadingBtn] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [department, setDepartment] = useState('');
  const [company, setCompany] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
  const [confirmType, setConfirmType] = useState<'password' | 'text'>('password');
  const [feedback, setFeedback] = useState({ error: '', value: '' });
  const [disabled, setDisabled] = useState(true);

  const rules = (accounts[userId] && accounts[userId].password_validators) || [];
  const { popperProps, referenceProps } = usePopper({
    trigger: 'focus',
    placement: 'left-start',
    skidding: 5,
    distance: 30,
    disabled: rules.length === 0,
  });
  useEffect(() => {
    if (!rules || rules.length === 0) {
      setDisabled(false);
    }
  }, [rules]);

  // Translations
  const VALUES_FOR_FEEDBACK: { [key: string]: string } = {
    username: intl.formatMessage({ id: 'settings.user.username' }),
    first_name: intl.formatMessage({ id: 'settings.name.firstName' }),
    last_name: intl.formatMessage({ id: 'settings.name.lastName' }),
    email_address: intl.formatMessage({ id: 'editor.templatePreview.properties.authors.email' }),
  };

  const onClickNext = () => {
    setLoadingBtn('loadingSpinner');
    const token = SessionStorage.getToken();
    const params = {
      first_name: firstName,
      last_name: lastName,
      password,
      token: token ? token : '',
      company,
      department,
    };

    new AuthService({ errorsExpected: [400] })
      .firstLogin(params)
      .then(() => {
        dispatch(setFinishedFirstLogin());
        dispatch(setAuthenticated(true));
        navigateToMyFiles();
      })
      .catch((error) => {
        if (AuthService.isAxiosError(error)) {
          if (error?.response?.status === 400) {
            const errors = error?.response?.data.errors[0];
            const key = errors.errors[0];
            setFeedback({ error: key, value: errors[key] });
          }
        }
        setLoadingBtn('');
      });
  };

  const getFeedbackMessage = () => {
    if (feedback.error) {
      let msg = '';
      switch (feedback.error) {
        case 'password_too_similar':
          msg = intl.formatMessage(
            { id: feedback.error.toUpperCase() },
            { name: VALUES_FOR_FEEDBACK[feedback.value] },
          );
          break;
        case 'password_in_history':
          msg = intl.formatMessage({ id: 'PASSWORD_RECENTLY_USED' });
          break;
        case 'password_too_common':
          msg = intl.formatMessage({ id: 'PASSWORD_TOO_COMMON' });
          break;
        default:
          msg = intl.formatMessage({ id: 'ERROR_SCREEN_HEADER' });
          break;
      }
      return msg;
    }
    return '';
  };

  const handleSetPassword: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPassword(e.target.value);
    setFeedback({ error: '', value: '' });
  };

  const getFormContent = () => {
    return (
      <>
        <div className={styles.name}>
          <div className={styles.inputs} style={{ marginRight: '2rem' }}>
            <InputField
              label={intl.formatMessage({ id: 'settings.name.firstName' })}
              size="large"
              required="required"
              testId="firstName-field"
            >
              <span data-testid={TEST_IDS.INPUT.FIRST_NAME}>
                <Input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoCompleteOff
                  placeholder={intl.formatMessage({ id: 'YOUR_FIRST_NAME' })}
                  size="large"
                  testId="first-name"
                />
              </span>
            </InputField>
          </div>
          <div className={styles.inputs}>
            <InputField
              label={intl.formatMessage({ id: 'settings.name.lastName' })}
              size="large"
              required="required"
              testId="lastName-field"
            >
              <span data-testid={TEST_IDS.INPUT.LAST_NAME}>
                <Input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  autoCompleteOff
                  placeholder={intl.formatMessage({ id: 'YOUR_LAST_NAME' })}
                  size="large"
                  testId="last-name"
                />
              </span>
            </InputField>
          </div>
        </div>
        <div>
          <InputField
            label={intl.formatMessage({ id: 'settings.password.newPassword' })}
            size="large"
            required="required"
            feedback={getFeedbackMessage()}
            testId="firstName-field"
          >
            <>
              <div className={styles.password} data-testid={TEST_IDS.INPUT.NEW_PASSWORD}>
                <Input
                  error={!!feedback.error}
                  value={password}
                  onChange={handleSetPassword}
                  onSuffixClick={() =>
                    setPasswordType(passwordType === 'password' ? 'text' : 'password')
                  }
                  autoCompleteOff
                  type={passwordType}
                  suffix="PasswordShow"
                  placeholder={intl.formatMessage({ id: 'WRITE_YOUR_NEW_PASSWORD' })}
                  size="large"
                  testId={TEST_IDS.INPUT.NEW_PASSWORD}
                  {...referenceProps}
                />
              </div>
              <Popover {...popperProps} testId="password-checker-popper">
                <span data-testid="plata">
                  <PasswordCheck rules={rules} password={password} setDisabled={setDisabled} />
                </span>
              </Popover>
            </>
          </InputField>
        </div>
        <div>
          <InputField
            label={intl.formatMessage({ id: 'settings.password.confirmNewPassword' })}
            size="large"
            required="required"
            testId="newPassword-field"
          >
            <div className={styles.password} data-testid={TEST_IDS.INPUT.CONFIRM_PASSWORD}>
              <Input
                error={!!feedback.error}
                placeholder={intl.formatMessage({ id: 'CONFIRM_YOUR_NEW_PASSWORD' })}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onSuffixClick={() =>
                  setConfirmType(confirmType === 'password' ? 'text' : 'password')
                }
                autoCompleteOff
                type={confirmType}
                suffix="PasswordShow"
                size="large"
                testId={TEST_IDS.INPUT.CONFIRM_PASSWORD}
              />
            </div>
          </InputField>
        </div>
        <div className={styles.name}>
          <div style={{ marginRight: '2rem' }}>
            <InputField
              label={intl.formatMessage({ id: 'settings.user.company' })}
              size="large"
              testId="company-field"
            >
              <Input
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                autoCompleteOff
                placeholder={intl.formatMessage({ id: 'YOUR_COMPANY' })}
                size="large"
                testId="company"
              />
            </InputField>
          </div>
          <div>
            <InputField
              label={intl.formatMessage({ id: 'settings.user.department' })}
              size="large"
              testId="department-field"
            >
              <Input
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                autoCompleteOff
                placeholder={intl.formatMessage({ id: 'YOUR_DEPARTMENT' })}
                size="large"
                testId="department"
              />
            </InputField>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={styles.root}>
      <AuthHero>
        <div className={styles.title} data-testid="welcome-page-title">
          <FormattedMessage id="ALMOST_THERE" />
        </div>
        <div className={styles.description} data-testid="welcome-page-description">
          <FormattedMessage id="FIRST_LOGIN_DESCRIPTION" />
        </div>
      </AuthHero>
      <div className={styles.onBoardingRoot}>
        <div className={styles.title}>
          <FormattedMessage id="SET_UP_YOUR_PROFILE" />
        </div>
        <div className={styles.form}>{getFormContent()}</div>
        <div className={styles.footer}>
          <Button
            variant="primary"
            size="large"
            fullWidth
            disabled={
              firstName === '' ||
              lastName === '' ||
              password === '' ||
              disabled ||
              password !== confirmPassword
            }
            loading={!!loadingBtn}
            onClick={onClickNext}
            testId={TEST_IDS.BUTTON}
          >
            <FormattedMessage id="BEGIN_ONBOARDING" />
          </Button>
        </div>
      </div>
      <SupportButton />
    </div>
  );
};

export default WelcomePage;
