import getConfig from 'dodoc.config';
import { BaseTypedEmitter } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { Logger } from '_common/services';
import { DataManager } from './DataManager';

export class PresentationManager
  extends BaseTypedEmitter<Presentation.Events>
  implements Presentation.IPresentationManager
{
  private context: Presentation.Context;
  protected _reloadTimeout: NodeJS.Timeout | null = null;

  constructor() {
    super();
    this.context = {
      status: 'NOT_READY',
    };

    /* develblock:start */
    //@ts-expect-error
    global.presentation = this;
    /* develblock:end */
  }

  getData(): Presentation.Data.API | undefined {
    return this.context.data;
  }

  get status(): Presentation.Status {
    return this.context.status;
  }

  private async initializeCoreServices() {
    try {
      await this.initializeDataManager();
      // this.initializeNavigation();
      // this.initializeSelectionManager();

      this.context.status = 'READY';
      this.emit('STATUS_CHANGED', null, 'READY');
    } catch (error) {
      this.context.status = 'DESTROYING';
      this.emit('STATUS_CHANGED', error, 'DESTROYING');
    }
  }

  private async initializeDataManager() {
    if (this.context.transport && this.context.document?.id) {
      this.context.data = DataManager(this.context.transport);
      this.context.data.on('FORCE_REMOTE_RELOAD', () => {
        // this.reloadPDFManager();
      });
      this.context.data.on('LOAD_USERS_ONLINE', (users: any) => {
        // ReduxInterface.usersOnline(users);
      });
      this.context.data.on('USER_JOINED', (user: any) => {
        // ReduxInterface.userJoined(user);
      });
      this.context.data.on('USER_LEFT', (user: any) => {
        // ReduxInterface.userLeft(user);
      });
      // this.context.data.on('LOAD_FIND_HITS', (data: any) => {
      //   // console.log(' found : ', data);
      //   // ReduxInterface.findData(data);
      // });
      // this.context.data.on('LOAD_ANNOTATIONS', ReduxInterface.loadAnnotations);
      // this.context.data.on('DOCUMENT_UPDATED', () => {
      //   ReduxInterface.invalidateApiTags([{ type: 'Object', id: this.context.document?.id }]);
      // });
      // this.context.data.on('UPDATE_OBJECT_STATUS', () => {
      //   ReduxInterface.invalidateApiTags([{ type: 'Object', id: this.context.document?.id }]);
      // });
      await this.context.data.start(this.context.document.id, this.context.user);
    }
  }

  connect(documentId: string, user: Realtime.Core.User) {
    if (this.context.transport != null && this.context.transport.isConnected()) {
      // transport is already connected
      this.context.transport.checkConnectionStrength().then((result: number) => {
        this.context.status = 'INITIALIZED';
        this.emit('STATUS_CHANGED', null, 'INITIALIZED');
        // Logger.debug(`TRANSPORT ALREADY CONNECTED : ${result}`);
        // this.initializeCoreServices();
      });
    } else {
      this.context.document = {
        id: documentId,
      };
      this.context.user = user;
      this.context.transport = new Transport(getConfig());
      this.context.status = 'INITIALIZING';
      this.emit('STATUS_CHANGED', null, 'INITIALIZING');
      this.context.transport
        .on('TS_CONNECTED', () => {
          this.context.transport?.checkConnectionStrength().then((result: number) => {
            this.context.status = 'INITIALIZED';
            this.emit('STATUS_CHANGED', null, 'INITIALIZED');
            Logger.debug(`TRANSPORT CONNECTION : ${result}`);
            this.initializeCoreServices();
          });
        })
        .on('TS_DESTROYED', () => {
          this.destroy();
        })
        .on('TS_DISCONNECTED', () => {
          this.destroy(new Error('DISCONNECTED'));
        })
        .on('TS_ERROR', (event: any, error: any) => {
          this.destroy(new Error(event));
        });
      if (this.context.document.id && this.context.user.token) {
        this.context.transport.connect(this.context.document.id, this.context.user.token);
      }
    }
  }

  destroy(error?: Error): void {
    if (this.context.status !== 'DESTROYING' && this.context.status !== 'DESTROYED') {
      console.trace('DESTROY PresentationManager');
      this.context.status = 'DESTROYING';
      this.emit('STATUS_CHANGED', error, 'DESTROYING');
      super.destroy();
      this.context.status = 'DESTROYED';
      this.emit('STATUS_CHANGED', error, 'DESTROYED');
    }
  }
}
