import { PageViewBuilder, SectionViewBuilder } from './PageBuilders';
import {
  AuthorsViewBuilder,
  CitationGroupViewBuilder,
  CitationViewBuilder,
  ColumnBreakViewBuilder,
  CommentsViewBuilder,
  EquationViewBuilder,
  FieldViewBuilder,
  FigureViewBuilder,
  FormatViewBuilder,
  GenericViewBuilder,
  HyperlinkViewBuilder,
  ImageViewBuilder,
  InvalidViewBuilder,
  KeywordsViewBuilder,
  ListOfFigureViewBuilder,
  ListOfTablesViewBuilder,
  LoaderViewBuilder,
  NoteViewBuilder,
  PageBreakViewBuilder,
  ParagraphViewBuilder,
  PlaceholderViewBuilder,
  RedactedViewBuilder,
  ReferencesSectionViewBuilder,
  SectionBreakViewBuilder,
  SymbolViewBuilder,
  TableCellViewBuilder,
  TableOfContentsViewBuilder,
  TableRowViewBuilder,
  TableViewBuilder,
  TabViewBuilder,
  TextViewBuilder,
  TrackDeleteViewBuilder,
  TrackInsertViewBuilder,
} from './ViewBuilders';
import {
  ApproveModeDecorator,
  BaseDecorator,
  BasicModeDecorator,
  PermissionModeDecorator,
} from './ViewDecorator';
import { ViewMapper } from './ViewMapper';

export class ViewFactory {
  private Data: Editor.Data.API;
  private Visualizer: Editor.Visualizer.State;
  private builders: Editor.Visualizer.ViewFactory.BuildersObjectType;
  private decorators: Editor.Visualizer.ViewFactory.DecoratorObjectType;
  private pageBuilders: Editor.Visualizer.ViewFactory.PageBuildersType;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State) {
    this.Data = Data;
    this.Visualizer = Visualizer;

    this.builders = {
      // text
      text: new TextViewBuilder(this.Data, this.Visualizer),

      // reference section
      rs: new ReferencesSectionViewBuilder(this.Data, this.Visualizer),

      // paragraph
      p: new ParagraphViewBuilder(this.Data, this.Visualizer),

      // placeholder
      ph: new PlaceholderViewBuilder(this.Data, this.Visualizer),

      // non-editable elements
      cb: new ColumnBreakViewBuilder(this.Data, this.Visualizer),
      pb: new PageBreakViewBuilder(this.Data, this.Visualizer),
      sb: new SectionBreakViewBuilder(this.Data, this.Visualizer),
      toc: new TableOfContentsViewBuilder(this.Data, this.Visualizer),
      tof: new ListOfFigureViewBuilder(this.Data, this.Visualizer),
      tot: new ListOfTablesViewBuilder(this.Data, this.Visualizer),
      k: new KeywordsViewBuilder(this.Data, this.Visualizer),
      a: new AuthorsViewBuilder(this.Data, this.Visualizer),

      // table
      tbl: new TableViewBuilder(this.Data, this.Visualizer),
      tblb: new GenericViewBuilder(this.Data, this.Visualizer),
      tblr: new TableRowViewBuilder(this.Data, this.Visualizer),
      tblc: new TableCellViewBuilder(this.Data, this.Visualizer),

      // images
      figure: new FigureViewBuilder(this.Data, this.Visualizer),
      'image-element': new ImageViewBuilder(this.Data, this.Visualizer),
      img: new ImageViewBuilder(this.Data, this.Visualizer),

      // inline elements
      format: new FormatViewBuilder(this.Data, this.Visualizer),
      equation: new EquationViewBuilder(this.Data, this.Visualizer),
      // 'cross-reference': (json) => document.createElement(''), // render to field element
      note: new NoteViewBuilder(this.Data, this.Visualizer),
      f: new FieldViewBuilder(this.Data, this.Visualizer),
      link: new HyperlinkViewBuilder(this.Data, this.Visualizer),
      'tracked-insert': new TrackInsertViewBuilder(this.Data, this.Visualizer),
      'tracked-delete': new TrackDeleteViewBuilder(this.Data, this.Visualizer),
      tab: new TabViewBuilder(this.Data, this.Visualizer),
      //citations
      'citations-group': new CitationGroupViewBuilder(this.Data, this.Visualizer),
      citation: new CitationViewBuilder(this.Data, this.Visualizer),
      // comments
      comment: new CommentsViewBuilder(this.Data, this.Visualizer),
      'temp-comment': new CommentsViewBuilder(this.Data, this.Visualizer),

      symbol: new SymbolViewBuilder(this.Data, this.Visualizer),

      // invalid\
      invalid: new InvalidViewBuilder(this.Data, this.Visualizer),
      // loader
      loader: new LoaderViewBuilder(this.Data, this.Visualizer),
      //redacted
      redacted: new RedactedViewBuilder(this.Data, this.Visualizer),
    };

    this.decorators = {
      APPROVALS: new ApproveModeDecorator(this.Data, this.Visualizer),
      PERMISSIONS: new PermissionModeDecorator(this.Data, this.Visualizer),
      BASIC: new BasicModeDecorator(this.Data, this.Visualizer),
    };

    this.pageBuilders = {
      page: new PageViewBuilder(),
      section: new SectionViewBuilder(),
    };
  }

  private isSupportedView(
    type: string,
  ): type is Editor.Visualizer.ViewFactory.SupportedElementType {
    return Object.keys(this.builders).includes(type);
  }

  private getViewCategory(type: string): Editor.Visualizer.ViewFactory.SupportedElementType {
    if (this.isSupportedView(type)) {
      return type;
    }
    return 'invalid';
  }

  shouldRenderChildren(type: Editor.Visualizer.ViewFactory.SupportedElementType, json: never) {
    return this.getViewBuilder(type).shouldRenderChildren(json);
  }

  getViewBuilder(type: string) {
    const categoryType = this.getViewCategory(type);
    return this.builders[categoryType];
  }

  getDecoratorBuilder(mode: Editor.Visualizer.RenderMode): BaseDecorator {
    return this.decorators[mode];
  }

  getAttributeMapper(type: Editor.Visualizer.ViewFactory.SupportedElementType) {
    return this.getViewBuilder(type).attributeMapper;
  }

  get(json: Editor.Data.Node.Data, model?: Editor.Data.Node.Model) {
    const queue: {
      parent: HTMLElement | Text | null;
      data: Editor.Data.Node.Data;
    }[] = [
      {
        parent: null,
        data: json,
      },
    ];

    // TODO: move render children to blockViewModel or respective parent
    let root = null;
    while (queue.length) {
      const item = queue.shift();

      if (item) {
        let builder = this.getViewBuilder(item.data.type);
        //@ts-expect-error fix Data types
        let view = builder.build(item.data, model);

        if (!root) {
          root = view;
        }

        if (item.parent) {
          item.parent.appendChild(view);
        }

        //@ts-expect-error fix Data types
        if (item.data.childNodes?.length && builder.shouldRenderChildren(item.data)) {
          for (let index = 0; index < item.data.childNodes.length; index++) {
            queue.push({
              parent: view,
              data: item.data.childNodes[index],
            });
          }
        }
      }
    }
    return root;
  }

  decorate(
    mode: Editor.Visualizer.RenderMode,
    json: Editor.Data.Node.Data,
    view: Editor.Visualizer.BaseView,
  ) {
    // check permissions
    switch (mode) {
      case 'APPROVALS':
        if (json.id && this.Data.permissions.canUserPerform(json.id, 'approve')) {
          return this.getDecoratorBuilder('APPROVALS').decorator(json, view);
        }
        return this.getDecoratorBuilder('BASIC').decorator(json, view);
      case 'PERMISSIONS':
        if (
          json.id &&
          this.Data.permissions.canUserPerform(json.id, 'access') &&
          (this.Data.permissions.canUserPerform(json.id, 'add_permission') ||
            this.Data.permissions.canUserPerform(json.id, 'remove_permission'))
        ) {
          return this.getDecoratorBuilder('PERMISSIONS').decorator(json, view);
        }
        return this.getDecoratorBuilder('BASIC').decorator(json, view);

      default:
        return this.getDecoratorBuilder(mode).decorator(json, view);
    }
  }

  getPageView(lastSectionId?: string) {
    const pageElement = this.pageBuilders.page.build(lastSectionId);
    pageElement.contentContainer?.appendChild(this.getSectionView(lastSectionId));

    return pageElement;
  }

  getSectionView(sectionId?: string) {
    return this.pageBuilders.section.build(sectionId);
  }

  getViewByType(type: Editor.Visualizer.ViewFactory.SupportedElementType) {
    return this.get({ type: type });
  }

  getViewByTag(tag: Editor.Elements.SupportedTagNames, options?: ElementCreationOptions) {
    const type = ViewMapper.getElementTypeForTag(tag);

    if (type != null && this.isSupportedView(type)) {
      return this.get({ type: type });
    } else {
      return this.createDOMElement(tag, options);
    }
  }

  private createDOMElement(
    tag: Editor.Elements.SupportedTagNames,
    options?: ElementCreationOptions,
  ) {
    return document.createElement(tag, options);
  }
}
