import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

import { InstanceService } from '_common/services';
import { notify } from '_common/components/ToastSystem';

import { deletedObject, addData } from 'App/redux/appSlice';
import { addToList, removeFromList, clearSelection } from '_common/components/Table/TableSlice';
import { navigateToSettings } from 'router/history';

type SliceState = {
  personals: boolean;
  infoPanelOpen: boolean;
};

const SLICE_NAME = 'spaces';

// #region State

export const INITIAL_STATE: SliceState = {
  personals: false,
  infoPanelOpen: false,
};

// #endregion

// #region AsyncThunks

type createSpaceProps = {
  name: string;
  description: string;
};

export const createSpace = createAsyncThunk(
  `${SLICE_NAME}/createSpace`,

  async ({ name, description }: createSpaceProps, { dispatch }) => {
    try {
      const { data } = await new InstanceService({ errorsExpected: [400] }).createSpace(
        name,
        description,
      );
      dispatch(addData({ [data.id]: data }));
      dispatch(addToList({ identity: 'spaces', objectId: data.id }));
      navigateToSettings('space', data.id, '');
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          notify({
            type: 'error',
            title: 'INCORRECT_NAME',
            message: 'SPACE_INCORRECT_NAME_MESSAGE',
          });
        }
      }
    }
  },
);

export const deleteSpace = createAsyncThunk(
  `${SLICE_NAME}/deleteSpace`,
  async (
    {
      identity,
      objectId,
    }: {
      objectId: ObjectId;
      identity: Table.Identity;
    },
    { dispatch },
  ) => {
    new InstanceService().deleteSpace(objectId).then(() => {
      dispatch(removeFromList({ identity, objectId }));
      dispatch(clearSelection());
      dispatch(deletedObject({ objectId }));
    });
  },
);
// #endregion

// #region Slice

const spacesPageSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    viewPersonalSpaces: (state, action: PayloadAction<boolean>) => {
      state.personals = action.payload;
    },
    toggleInfoPanel: (state) => {
      state.infoPanelOpen = !state.infoPanelOpen;
    },
    setInfoPanelOpenValue: (state, action: PayloadAction<SliceState['infoPanelOpen']>) => {
      state.infoPanelOpen = !!action.payload;
    },
  },
});

// Actions

export const { viewPersonalSpaces, toggleInfoPanel, setInfoPanelOpenValue } =
  spacesPageSlice.actions;

// Persistence
const persistConfig = {
  key: 'spaces',
  storage: localStorage,
  whitelist: ['infoPanelOpen'],
};

const reducer = persistReducer(persistConfig, spacesPageSlice.reducer);

export default reducer;

// #endregion
